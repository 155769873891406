import type { Id } from '@jane/shared/models';

import type {
  BrandDiscountStatuses,
  UnreviewedProductsProps,
} from './user.types';

export const userUrls = {
  janeUser: () => '/whoami',
  userBrandDiscounts: (status: BrandDiscountStatuses) =>
    `/users/brand_discounts?status=${status}`,
  users: () => '/users',
  unreviewedProducts: (storeId: Id) =>
    `/users/unreviewed_products/?store_id=${storeId}`,
};

const BASE_KEY = 'jane';

export const userQueryKeys = {
  janeUser: () => [BASE_KEY, 'janeUser'],
  userBrandDiscounts: (
    userId: Id | undefined,
    status: BrandDiscountStatuses
  ) => [BASE_KEY, userId, 'brandDiscounts', status],
  unreviewedProducts: ({ userId, storeId }: UnreviewedProductsProps) => [
    BASE_KEY,
    userId,
    'unreviewedProducts',
    storeId,
  ],
};
