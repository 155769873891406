/**
 * DO NOT MODIFY THIS FILE!
 * It was created by the reefer-icons generator.
 * Please update & re-run the generator if need be.
 */
import type { SVGProps } from 'react';

import type { DynamicIconProps } from '../../../icon/icon.types';
import { Icon } from '../../icon';

const Grow24 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="100%"
    height="100%"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <g className="Grow 24" clipPath="url(#a)">
      <path
        fill="#4BA279"
        fillRule="evenodd"
        d="M9.5 8c0-5 1.25-8 2.5-8s2.5 3 2.5 8v.174C18.639 6.24 24 6.843 24 8.001c0 .585-.98.921-2.426 1.417-1.023.35-2.279.781-3.586 1.436-.563.282-1.126.616-1.688.949-.575.34-1.15.681-1.726.967 2.61 1.196 3.916 3.433 3.257 4.103-.374.379-1.21-.149-2.163-.75-.88-.556-1.861-1.174-2.668-1.198v3.118c3.98.339 7.352 2.68 9 5.958a31.69 31.69 0 0 0-10-1.594 31.69 31.69 0 0 0-10 1.594c1.647-3.278 5.02-5.62 9-5.958v-3.118c-.807.024-1.787.642-2.668 1.198-.953.601-1.79 1.129-2.163.75-.66-.67.647-2.907 3.257-4.103-.575-.286-1.15-.627-1.726-.967a27.891 27.891 0 0 0-1.688-.949C4.705 10.2 3.45 9.77 2.426 9.418.98 8.922 0 8.586 0 8.001c0-1.158 5.361-1.76 9.5.173v-.173Z"
        className="Main"
        clipRule="evenodd"
      />
      <path
        fill="#000"
        fillOpacity={0.5}
        d="M11.048 14.924H11v3.119c-3.98.339-7.353 2.68-9 5.958a31.69 31.69 0 0 1 10-1.594c3.536 0 6.911.566 10 1.594-1.648-3.278-5.02-5.62-9-5.958v-3.118l-.048-.001c-.388 0-.7-.065-.952-.185-.252.12-.564.185-.952.185Z"
        className="Details Mono-Details"
      />
    </g>
    <defs>
      <clipPath>
        <path fill="#fff" d="M0 0h24v24H0z" />
      </clipPath>
    </defs>
  </svg>
);

const SIZE_MAP = {
  md: Grow24,
  sm: Grow24,
  lg: Grow24,
  xl: Grow24,
  xxl: Grow24,
  xxxl: Grow24,
};

export const GrowIcon = ({
  size = 'md',
  'data-testid': testId,
  ...props
}: DynamicIconProps) => {
  return (
    <Icon
      data-testid={testId || 'grow-icon'}
      icon={SIZE_MAP[size]}
      {...props}
      size={size}
    />
  );
};
