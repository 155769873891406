/**
 * DO NOT MODIFY THIS FILE!
 * It was created by the reefer-icons generator.
 * Please update & re-run the generator if need be.
 */
import type { SVGProps } from 'react';

import type { StaticIconProps } from '../../../icon/icon.types';
import { Icon } from '../../icon';

const NotHigh24 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="100%"
    height="100%"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <g className="Not high 24">
      <circle cx={12} cy={12} r={12} fill="#DADADA" className="Fill-Main" />
      <path
        fill="#000"
        fillOpacity={0.5}
        fillRule="evenodd"
        d="M10 9a2 2 0 1 1-4 0 2 2 0 0 1 4 0Zm8 0a2 2 0 1 1-4 0 2 2 0 0 1 4 0ZM6 17h12v-2H6v2Z"
        className="Fill-Secondary"
        clipRule="evenodd"
      />
    </g>
  </svg>
);

const SIZE_MAP = {
  md: NotHigh24,
  sm: NotHigh24,
  lg: NotHigh24,
  xl: NotHigh24,
  xxl: NotHigh24,
  xxxl: NotHigh24,
};

export const NotHighIcon = ({
  size = 'md',
  'data-testid': testId,
  ...props
}: StaticIconProps) => {
  return (
    <Icon
      data-testid={testId || 'not-high-icon'}
      icon={SIZE_MAP[size]}
      {...props}
      size={size}
      color={undefined}
    />
  );
};
