let trackingId: string;

export function initGoogleAnalytics(gaTrackingId: string) {
  trackingId = gaTrackingId;
}

export function recordPageView(path: string) {
  if (typeof globalThis.gtag !== 'undefined' && trackingId) {
    globalThis.gtag('config', trackingId, {
      page_path: path,
    });
  }
}
