import groupBy from 'lodash/groupBy';

import type {
  ArrayFilterValue,
  FilterKeys,
  FilterSelectItem,
} from '@jane/search/types';
import {
  List,
  Modal,
  Typography,
  useMobileMediaQuery,
} from '@jane/shared/reefer';

import { FilterCheckbox } from '../filterCheckbox';
import { ItemDivider } from './filterModal.styles';
import { CONTENT_DIVIDER_PROPS } from './modalSection';

const findFirstChar = ({ label }: { label: string }) => {
  const firstCharacter = label[0].toLowerCase();

  return isNaN(parseInt(firstCharacter)) ? firstCharacter : '0';
};

interface TypeaheadListProps {
  filterKey: FilterKeys;
  hideCounts?: boolean;
  items: FilterSelectItem[];
  onChange: (key: FilterKeys, label: ArrayFilterValue) => void;
  query: string;
}

const ListGroup = ({
  filterKey,
  hideCounts,
  items,
  onChange,
  query,
}: TypeaheadListProps) => {
  const isMobile = useMobileMediaQuery({});
  const showDivider =
    items.filter((item) => item.count !== 0).length > 0 && !isMobile;

  return (
    <>
      {items.map((item) => {
        return (
          item.count > 0 && (
            <ItemDivider key={item.label}>
              <FilterCheckbox
                filterKey={filterKey}
                hideCounts={hideCounts}
                item={item}
                onChange={onChange}
                query={query}
              />
            </ItemDivider>
          )
        );
      })}
      {showDivider && <Modal.ContentDivider {...CONTENT_DIVIDER_PROPS} />}
    </>
  );
};

export const TypeaheadList = ({
  filterKey,
  hideCounts,
  items,
  onChange,
  query,
}: TypeaheadListProps) => {
  const listItems = items.filter((item: FilterSelectItem) =>
    item.label.toLowerCase().includes(query.toLowerCase())
  );

  const itemGroups = groupBy<FilterSelectItem>(listItems, findFirstChar);

  const renderedList = Object.entries(itemGroups).map(
    ([groupName, listItems]) => (
      <List label={groupName} key={groupName}>
        <ListGroup
          filterKey={filterKey}
          hideCounts={hideCounts}
          key={groupName}
          items={listItems}
          onChange={onChange}
          query={query}
        />
      </List>
    )
  );

  return renderedList.length === 0 ? (
    <Typography m={24} mb={0}>
      No results
    </Typography>
  ) : (
    <>{renderedList}</>
  );
};
