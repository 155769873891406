const countryCodeCurrencyCodeMap: {
  [key: string]: string | undefined;
} = {
  US: 'USD',
  CA: 'CAD',
};

export const currencyCodeFromCountryCode = (countryCode: string) => {
  return countryCodeCurrencyCodeMap[countryCode];
};
