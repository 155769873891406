import styled from '@emotion/styled';

import { Flex, Link } from '@jane/shared/reefer';

export const StyledLink = styled(Link)({
  display: 'block',
  width: '100%',
  height: '100%',
});

export const StyledFlex = styled(Flex)(({ theme }) => ({
  '&:hover': { borderColor: theme.colors.transparent.black.twenty },
}));
