import throttle from 'lodash/throttle';
import { useEffect, useState } from 'react';

import { config } from '@jane/shared/config';

// https://developer.mozilla.org/en-US/docs/Web/API/Page_Visibility_API
const getVisibilityProps = (): [string, string] | [undefined, undefined] => {
  if (typeof document.hidden !== 'undefined') {
    // Opera 12.10 and Firefox 18 and later support
    return ['hidden', 'visibilitychange'];
  }

  if (typeof (document as any).msHidden !== 'undefined') {
    return ['msHidden', 'msvisibilitychange'];
  }

  if (typeof (document as any).webkitHidden !== 'undefined') {
    return ['webkitHidden', 'webkitvisibilitychange'];
  }

  return [undefined, undefined];
};

const [hiddenProp, visibilityChangeEvent] = getVisibilityProps();

export const getIsDocumentVisible = () =>
  hiddenProp ? !(document as any)[hiddenProp] : true;

export const useDocumentVisible = () => {
  const [visible, setVisible] = useState(getIsDocumentVisible());

  useEffect(() => {
    if (!visibilityChangeEvent) {
      return;
    }
    const handleEvent = throttle(
      () => {
        setVisible(getIsDocumentVisible());
      },
      config.nodeEnv === 'test' ? 0 : 1000
    );

    document.addEventListener(visibilityChangeEvent, handleEvent, false);

    return () => {
      document.removeEventListener(visibilityChangeEvent, handleEvent);
    };
  }, []);

  return visible;
};
