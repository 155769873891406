import qs from 'qs';
import type { IStringifyOptions } from 'qs';

export const parseSearch = <
  T extends Record<string, any> = Record<string, any>
>(
  search: string
): T => qs.parse(search, { ignoreQueryPrefix: true, comma: true }) as any;

export const encodeQuery = (
  base: string,
  query?: Record<string, any>,
  options?: IStringifyOptions
) => {
  const opts = options || { arrayFormat: 'brackets' };
  return [base, query && qs.stringify(query, opts)].filter(Boolean).join('?');
};
