import type { Reducer } from 'redux';

import { getGoogleAddresses } from '@jane/shared-ecomm/util';
import type { DeepReadonly } from '@jane/shared/models';

import { createSimpleAction, createStandardAction } from '../../redux-util';
import type { CustomerThunkAction } from '../redux';
import type { CustomerAction } from './types';

export const RESET_ADDRESS_AUTOCOMPLETE =
  'address-autocomplete/reset-address-autocomplete';
export const resetAddressAutocomplete = createSimpleAction(
  RESET_ADDRESS_AUTOCOMPLETE
);

export const CHOOSE_ADDRESS_FROM_LIST =
  'address-autocomplete/choose-address-from-list';
export const chooseAddressFromList = createStandardAction(
  CHOOSE_ADDRESS_FROM_LIST
)<Address>();

export const handleAddressAutocompleteChange =
  (value: string | null, countries?: string[]): CustomerThunkAction =>
  (dispatch) => {
    if (!value) {
      dispatch(resetAddressAutocomplete());
      return;
    }

    return getGoogleAddresses(value, countries).then((result) =>
      dispatch(setAddresses(result))
    );
  };

export const SET_ADDRESSES = 'address-autocomplete/set-addresses';
export const setAddresses =
  createStandardAction(SET_ADDRESSES)<AddressAutocompleteState['addresses']>();

export type AddressAutocompleteActions =
  | ReturnType<typeof resetAddressAutocomplete>
  | ReturnType<typeof chooseAddressFromList>
  | ReturnType<typeof setAddresses>;

type Address = { label: string; value: string };
export type AddressAutocompleteState = DeepReadonly<{
  addresses: Address[];
  chosenAddress: Address | string | null;
}>;

const getInitialState = (): AddressAutocompleteState => ({
  addresses: [],
  chosenAddress: null,
});

export const addressAutocompleteReducer: Reducer<
  AddressAutocompleteState,
  CustomerAction
> = (state = getInitialState(), action) => {
  switch (action.type) {
    case SET_ADDRESSES:
      return { ...state, addresses: action.payload };
    case CHOOSE_ADDRESS_FROM_LIST:
      return { ...state, chosenAddress: action.payload, addresses: [] };
    case RESET_ADDRESS_AUTOCOMPLETE:
      return getInitialState();
  }
  return state;
};
