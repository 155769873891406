import compact from 'lodash/compact';

type PluralizeParams = {
  kilo?: boolean;
  noun: string;
  number: number;
  returnValue?: 'both' | 'word' | 'count';
};

export const pluralize = ({
  kilo = false,
  number,
  noun,
  returnValue = 'both',
}: PluralizeParams) => {
  const thousandUp = kilo ? '1K+' : '1,000+';
  const count = number >= 1000 ? thousandUp : `${number}`;
  let finalNoun = noun;

  if (noun === 'dispensary') {
    finalNoun = number === 1 ? noun : 'dispensaries';
  } else {
    finalNoun = number === 1 ? noun : `${noun}s`;
  }

  if (returnValue === 'word') return finalNoun;
  if (returnValue === 'count') return count;

  return compact([count, finalNoun]).join(' ');
};
