/**
 * DO NOT MODIFY THIS FILE!
 * It was created by the reefer-icons generator.
 * Please update & re-run the generator if need be.
 */
import type { SVGProps } from 'react';

import type { DynamicIconProps } from '../../../icon/icon.types';
import { Icon } from '../../icon';

const CheckCircled64 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="100%"
    height="100%"
    fill="none"
    viewBox="0 0 64 64"
    {...props}
  >
    <g className="Check circled 64">
      <g className="Union">
        <path
          fill="#0E0E0E"
          d="M23.414 30.586 29 36.172l11.586-11.586a2 2 0 1 1 2.828 2.828l-13 13a2 2 0 0 1-2.828 0l-7-7a2 2 0 1 1 2.828-2.828Z"
        />
        <path
          fill="#0E0E0E"
          fillRule="evenodd"
          d="M64 32c0 17.673-14.327 32-32 32C14.327 64 0 49.673 0 32 0 14.327 14.327 0 32 0c17.673 0 32 14.327 32 32Zm-4 0c0 15.464-12.536 28-28 28S4 47.464 4 32 16.536 4 32 4s28 12.536 28 28Z"
          clipRule="evenodd"
        />
      </g>
    </g>
  </svg>
);

const SIZE_MAP = {
  xxl: CheckCircled64,
  xl: CheckCircled64,
  lg: CheckCircled64,
  md: CheckCircled64,
  sm: CheckCircled64,
  xxxl: CheckCircled64,
};

export const CheckCircledIcon = ({
  size = 'md',
  'data-testid': testId,
  ...props
}: DynamicIconProps) => {
  return (
    <Icon
      data-testid={testId || 'check-circled-icon'}
      icon={SIZE_MAP[size]}
      {...props}
      size={size}
    />
  );
};
