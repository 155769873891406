/**
 * DO NOT MODIFY THIS FILE!
 * It was created by the reefer-icons generator.
 * Please update & re-run the generator if need be.
 */
import type { SVGProps } from 'react';

import type { StaticIconProps } from '../../../icon/icon.types';
import { Icon } from '../../icon';

const Hop24 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="100%"
    height="100%"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <g className="Hop 24">
      <g className="Fill-Accent">
        <path
          fill="#F90"
          fillRule="evenodd"
          d="M13.38 1.988c-.328.233-.589.744-.589 2.496H11.21c0-1.7.199-3.035 1.253-3.785.5-.357 1.111-.518 1.77-.6C14.89.014 15.684 0 16.602 0a.791.791 0 0 1 0 1.583c-.923 0-1.625.016-2.173.085-.551.07-.86.184-1.05.32Z"
          clipRule="evenodd"
        />
        <path
          fill="#000"
          fillOpacity={0.2}
          fillRule="evenodd"
          d="M13.38 1.988c-.328.233-.589.744-.589 2.496H11.21c0-1.7.199-3.035 1.253-3.785.5-.357 1.111-.518 1.77-.6C14.89.014 15.684 0 16.602 0a.791.791 0 0 1 0 1.583c-.923 0-1.625.016-2.173.085-.551.07-.86.184-1.05.32Z"
          clipRule="evenodd"
        />
      </g>
      <path
        fill="#94B200"
        fillRule="evenodd"
        d="M7.396 6.738c0-3.453 2.302-4.604 4.604-4.604s4.603 1.007 4.603 4.604c0 3.596-3.069 6.186-4.603 6.905-1.535-.72-4.604-3.453-4.604-6.905Zm9.403 3.71c2.109.907 4.16.897 5.323.892h.236c0-1.534-.506-4.923-3.036-7.157-1.06-.936-2.114-1.447-3.133-1.724 1.004.97 1.565 2.397 1.565 4.279 0 1.394-.388 2.642-.955 3.71ZM6.246 6.737c0 1.372.402 2.623.983 3.697-2.12.92-4.184.91-5.352.905h-.235c0-1.534.506-4.923 3.036-7.157 1.09-.962 2.172-1.475 3.217-1.747-1.047.977-1.65 2.417-1.65 4.302Zm5.265 7.947.489.229.488-.23c.534-.25 1.182-.663 1.838-1.216l.038.071c.293.532.602 1.003.92 1.421-.55 2.456-2.32 3.563-3.284 4.165-.959-.599-2.716-1.797-3.276-4.176a11.498 11.498 0 0 0 .96-1.498c.647.559 1.29.982 1.827 1.234Zm-8.386-2.21c1.264-.053 2.971-.264 4.716-1.053a10.682 10.682 0 0 0 .978 1.2c-.06.118-.124.239-.192.361-1.783 3.237-4.094 3.82-5.25 4.112-.36-.817-.966-2.386-.252-4.62Zm12.07.174c.056.11.115.222.177.334 1.784 3.237 4.095 3.82 5.25 4.112.36-.817.967-2.386.253-4.62-1.257-.053-2.95-.261-4.684-1.038a10.15 10.15 0 0 1-.996 1.212Zm-5.573 7.394c.224-.216.444-.455.653-.721.423.338.816.592 1.115.78l.61.38.633-.395c.288-.18.679-.424 1.102-.753a6.976 6.976 0 0 0 1.42 1.355C14.495 22.596 12.898 23.495 12 24c-.891-.5-2.472-1.468-3.14-3.321.25-.182.508-.392.762-.636Zm-4.405-2.327c.3 1.413 1.08 2.316 1.56 2.832.664-.316 1.751-.834 2.645-2.005a7.179 7.179 0 0 1-1.597-2.569 8.063 8.063 0 0 1-2.608 1.741Zm12.409-.595a8.303 8.303 0 0 1-1.438-1.133 6.833 6.833 0 0 1-1.592 2.58c.89 1.153 1.967 1.666 2.626 1.98.481-.516 1.261-1.42 1.56-2.832a8.069 8.069 0 0 1-1.156-.595Z"
        className="Fill-Main"
        clipRule="evenodd"
      />
    </g>
  </svg>
);

const SIZE_MAP = {
  md: Hop24,
  sm: Hop24,
  lg: Hop24,
  xl: Hop24,
  xxl: Hop24,
  xxxl: Hop24,
};

export const HopIcon = ({
  size = 'md',
  'data-testid': testId,
  ...props
}: StaticIconProps) => {
  return (
    <Icon
      data-testid={testId || 'hop-icon'}
      icon={SIZE_MAP[size]}
      {...props}
      size={size}
      color={undefined}
    />
  );
};
