import { Box, Drawer, Loading } from '@jane/shared/reefer';

import { CartDrawerStatesWrapper } from './cartDrawer.styles';

export const CartDrawerLoading = () => {
  return (
    <>
      <Drawer.Header title="Bag" />
      <Drawer.Content noPadding>
        <CartDrawerStatesWrapper
          flexDirection="column"
          alignItems="center"
          p={40}
        >
          <Box my={48}>
            <Loading />
          </Box>
        </CartDrawerStatesWrapper>
      </Drawer.Content>
    </>
  );
};
