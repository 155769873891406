import styled from '@emotion/styled';

const OffscreenLabel = styled.label({
  position: 'absolute',
  left: '-10000px',
  top: 'auto',
  width: '1px',
  height: '1px',
  overflow: 'hidden',
});

export default OffscreenLabel;
