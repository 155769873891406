import {
  Flex,
  Form,
  Typography,
  emailRegex,
  telRegex,
} from '@jane/shared/reefer';

import { ContinueWith } from '../components/continueWith';

interface CreateAccountProps {
  errors: string[] | null;

  /** function to open Apple login when button is clicked */
  onAppleClick(): void;

  /** function to open Google login when button is clicked */
  onGoogleClick(): void;

  /** Function passed to determine what happens when sign in issubmitted */
  onSubmit(user: CreateAccountData): void;

  /** Boolean values checking Boost menu configuration */
  showAppleLogin: boolean;
  showGoogleLogin: boolean;
}

export type CreateAccountData = {
  email: string;
  password: string;
  phone: string;
};

export const CreateAccount = ({
  errors,
  onSubmit,
  onAppleClick,
  onGoogleClick,
  showAppleLogin,
  showGoogleLogin,
}: CreateAccountProps) => {
  return (
    <Form name="sign-in" onSubmit={onSubmit}>
      <Flex width="100%" flexDirection="column" alignItems="center">
        {(showAppleLogin || showGoogleLogin) && (
          <ContinueWith
            onAppleClick={onAppleClick}
            onGoogleClick={onGoogleClick}
            showAppleLogin={showAppleLogin}
            showGoogleLogin={showGoogleLogin}
          />
        )}
        <Form.TextField
          type="email"
          autocomplete="off"
          label="Email"
          name="email"
          required
          errorMessage="Please enter a valid email address."
          errorOnBlur
          pattern={emailRegex}
          disableMobileInputStyling
          my={24}
        />
        <Form.NumberField
          data-testid="phone-number-field"
          width="100%"
          disableMobileInputStyling
          allowLeadingZeros
          autocomplete="off"
          label="Phone number"
          helperText="Used only for reservation notices"
          maskFormat="(###) ###-####"
          name="phone"
          pattern={telRegex}
          errorMessage={'Phone is an invalid number'}
          errorOnBlur
          required
          mb={24}
        />
        <Form.TextField
          type="password"
          autocomplete="off"
          label="Password"
          name="password"
          required
          disableMobileInputStyling
          mb={24}
        />

        {errors && (
          <Flex flexDirection="column" justifyContent="center" mt={-12}>
            {Array.isArray(errors) ? (
              errors.map((error) => (
                <Typography color="error" mb={12} mx="auto">
                  {error}
                </Typography>
              ))
            ) : (
              <Typography color="error" mb={12} mx="auto">
                There was an error creating your account.
              </Typography>
            )}
          </Flex>
        )}

        <Form.SubmitButton
          full
          variant="primary"
          label="Sign up"
          mb={24}
          data-testid="login-submit-btn"
        />
        <Typography variant="body" textAlign="center" color="grays-mid">
          Jane keeps personal information safe and secure.
        </Typography>
      </Flex>
    </Form>
  );
};
