import reduce from 'lodash/reduce';

import type { StoreSpecial } from '@jane/shared/models';
import type { StoreSpecial as ZodStoreSpecial } from '@jane/shared/types';

export const getSpecialsLabels = <
  T extends StoreSpecial | ZodStoreSpecial = StoreSpecial
>(
  specials: T[]
) => {
  return reduce<T, Record<string, string>>(
    specials,
    (acc, special) => ({
      ...acc,
      [special.id.toString()]: special.title,
    }),
    {}
  );
};
