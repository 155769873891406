export interface FormError {
  /** Error message to be displayed */
  message: string;

  /** Name of error, must match name of field the error is being applied to, or in case of form level errors, the `formErrorName` prop of the form */
  name: string;
}

/**
 * Class for applying form and field level errors to form onSubmit
 * @extends Error
 */
export class FormValidationError extends Error {
  errors: FormError[];

  /**
   * Owner details
   * @param {String} formName Name prop of form where errors are being applied
   * @param {Array} errors Array of `FormError` objects, documented above
   */
  constructor(formName: string, errors: FormError[]) {
    super(`There has been error validating form ${formName}`);

    /**
     * @property {string} name Name of the error
     */
    this.name = 'FormValidationError';

    /**
     * @property {string} errors Array of `FormError` objects, documented above
     */
    this.errors = errors;
  }
}
