import { useIsRecommendedSortEnabled } from '@jane/dm/internal';
import type { CurrentSort } from '@jane/search/types';
import { FilterSortId } from '@jane/search/types';
import type { Store } from '@jane/shared/models';
import { useEcommApp } from '@jane/shared/providers';

interface Props {
  currentSort: CurrentSort | undefined;
  isTable: boolean;
  store: Store;
}
export const useIsCurrentSortRecommended = ({
  currentSort,
  isTable,
  store,
}: Props): boolean => {
  const { appMode } = useEcommApp();
  const isRecommendedSortEnabled = useIsRecommendedSortEnabled(store, appMode);
  if (!isTable) {
    return false;
  }
  if (!isRecommendedSortEnabled) {
    return false;
  }
  if (currentSort?.id === FilterSortId.Recommended) {
    return true;
  }
  const isCurrentSortDefault = !currentSort || currentSort.isDefault;
  return Boolean(isTable && isCurrentSortDefault);
};
