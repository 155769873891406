import type { SVGProps } from 'react';

import { Icon } from '../../icon/icon';
import type { StaticIconProps } from '../../icon/icon.types';

const Lion = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="64"
    height="64"
    viewBox="0 0 64 64"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <circle cx={32} cy={32} r={32} fill="#FF9900" />
    <circle cx={32} cy={32} r={32} fill="white" fillOpacity={0.8} />
    <circle cx={32} cy={32} r={28} fill="#FF9900" />
    <circle cx={32} cy={32} r={28} fill="black" fillOpacity={0.2} />
    <circle cx={18} cy={18} r={6} fill="#FF9900" />
    <circle cx={46} cy={18} r={6} fill="#FF9900" />
    <circle cx={18} cy={18} r={4} fill="#FFC220" />
    <circle cx={46} cy={18} r={4} fill="#FFC220" />
    <circle cx={32} cy={32} r={20} fill="#FF9900" />
    <circle cx={32} cy={44} r={8} fill="white" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M25.7361 46.5753C25.9707 46.4296 26.279 46.5016 26.4247 46.7361C27.1922 47.9714 28.1376 48.5 29 48.5C29.8624 48.5 30.8078 47.9714 31.5753 46.7361C31.721 46.5016 32.0293 46.4296 32.2639 46.5753C32.4984 46.721 32.5704 47.0293 32.4247 47.2639C31.5354 48.6953 30.3092 49.5 29 49.5C27.6908 49.5 26.4647 48.6953 25.5753 47.2639C25.4296 47.0293 25.5016 46.721 25.7361 46.5753Z"
      fill="#0E0E0E"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M31.7361 46.5753C31.9707 46.4296 32.279 46.5016 32.4247 46.7361C33.1922 47.9714 34.1376 48.5 35 48.5C35.8624 48.5 36.8078 47.9714 37.5753 46.7361C37.721 46.5016 38.0293 46.4296 38.2639 46.5753C38.4984 46.721 38.5704 47.0293 38.4247 47.2639C37.5354 48.6953 36.3092 49.5 35 49.5C33.6908 49.5 32.4647 48.6953 31.5753 47.2639C31.4296 47.0293 31.5016 46.721 31.7361 46.5753Z"
      fill="#0E0E0E"
    />
    <circle cx={22} cy={30} r={6} fill="white" />
    <circle cx={42} cy={30} r={6} fill="white" />
    <circle cx={22} cy={30} r={4} fill="#FFC220" />
    <circle cx={42} cy={30} r={4} fill="#FFC220" />
    <circle cx={22} cy={30} r={2} fill="#0E0E0E" />
    <circle cx={42} cy={30} r={2} fill="#0E0E0E" />
    <circle cx={23} cy={29} r={1} fill="white" />
    <circle cx={43} cy={29} r={1} fill="white" />
    <path
      d="M32.7071 42.2929C32.3166 42.6834 31.6834 42.6834 31.2929 42.2929L29.7071 40.7071C29.0772 40.0771 29.5233 39 30.4142 39L33.5858 39C34.4767 39 34.9229 40.0771 34.2929 40.7071L32.7071 42.2929Z"
      fill="#0E0E0E"
    />
    <path
      d="M33.4105 22.0469C32.6303 22.823 31.3697 22.823 30.5895 22.0469L22.5 13.9999L32 8.99994L41.5 13.9999L33.4105 22.0469Z"
      fill="#FF9900"
    />
    <path
      d="M33.4105 22.0469C32.6303 22.823 31.3697 22.823 30.5895 22.0469L22.5 13.9999L32 8.99994L41.5 13.9999L33.4105 22.0469Z"
      fill="black"
      fillOpacity={0.2}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M36.5 40C36.5 39.7239 36.7239 39.5 37 39.5H51C51.2761 39.5 51.5 39.7239 51.5 40C51.5 40.2761 51.2761 40.5 51 40.5H37C36.7239 40.5 36.5 40.2761 36.5 40Z"
      fill="#FF9900"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M36.5 40C36.5 39.7239 36.7239 39.5 37 39.5H51C51.2761 39.5 51.5 39.7239 51.5 40C51.5 40.2761 51.2761 40.5 51 40.5H37C36.7239 40.5 36.5 40.2761 36.5 40Z"
      fill="black"
      fillOpacity={0.2}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M27.5 40C27.5 39.7239 27.2761 39.5 27 39.5H13C12.7239 39.5 12.5 39.7239 12.5 40C12.5 40.2761 12.7239 40.5 13 40.5H27C27.2761 40.5 27.5 40.2761 27.5 40Z"
      fill="#FF9900"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M27.5 40C27.5 39.7239 27.2761 39.5 27 39.5H13C12.7239 39.5 12.5 39.7239 12.5 40C12.5 40.2761 12.7239 40.5 13 40.5H27C27.2761 40.5 27.5 40.2761 27.5 40Z"
      fill="black"
      fillOpacity={0.2}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M36.5 42C36.5 41.7239 36.7239 41.5 37 41.5H51C51.2761 41.5 51.5 41.7239 51.5 42C51.5 42.2761 51.2761 42.5 51 42.5H37C36.7239 42.5 36.5 42.2761 36.5 42Z"
      fill="#FF9900"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M36.5 42C36.5 41.7239 36.7239 41.5 37 41.5H51C51.2761 41.5 51.5 41.7239 51.5 42C51.5 42.2761 51.2761 42.5 51 42.5H37C36.7239 42.5 36.5 42.2761 36.5 42Z"
      fill="black"
      fillOpacity={0.2}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M27.5 42C27.5 41.7239 27.2761 41.5 27 41.5H13C12.7239 41.5 12.5 41.7239 12.5 42C12.5 42.2761 12.7239 42.5 13 42.5H27C27.2761 42.5 27.5 42.2761 27.5 42Z"
      fill="#FF9900"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M27.5 42C27.5 41.7239 27.2761 41.5 27 41.5H13C12.7239 41.5 12.5 41.7239 12.5 42C12.5 42.2761 12.7239 42.5 13 42.5H27C27.2761 42.5 27.5 42.2761 27.5 42Z"
      fill="black"
      fillOpacity={0.2}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M36.5 44C36.5 43.7239 36.7239 43.5 37 43.5H51C51.2761 43.5 51.5 43.7239 51.5 44C51.5 44.2761 51.2761 44.5 51 44.5H37C36.7239 44.5 36.5 44.2761 36.5 44Z"
      fill="#FF9900"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M36.5 44C36.5 43.7239 36.7239 43.5 37 43.5H51C51.2761 43.5 51.5 43.7239 51.5 44C51.5 44.2761 51.2761 44.5 51 44.5H37C36.7239 44.5 36.5 44.2761 36.5 44Z"
      fill="black"
      fillOpacity={0.2}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M27.5 44C27.5 43.7239 27.2761 43.5 27 43.5H13C12.7239 43.5 12.5 43.7239 12.5 44C12.5 44.2761 12.7239 44.5 13 44.5H27C27.2761 44.5 27.5 44.2761 27.5 44Z"
      fill="#FF9900"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M27.5 44C27.5 43.7239 27.2761 43.5 27 43.5H13C12.7239 43.5 12.5 43.7239 12.5 44C12.5 44.2761 12.7239 44.5 13 44.5H27C27.2761 44.5 27.5 44.2761 27.5 44Z"
      fill="black"
      fillOpacity={0.2}
    />
  </svg>
);
export const LionAvatar = ({ size = 'xl', ...props }: StaticIconProps) => {
  return <Icon icon={Lion} {...props} size={size} color={undefined} />;
};
