import * as t from 'io-ts';

const tProductBrand = t.intersection([
  t.interface({ id: t.number }),
  t.partial({ name: t.string }),
]);

export type FlightProductBrand = t.TypeOf<typeof tProductBrand>;

const tAdData = t.intersection([
  t.interface({
    flight: t.intersection([
      t.interface({
        ad_unit: t.string,
        creative_ids: t.array(t.number),

        custom_title: t.string,

        // data_1 is the base64 encoded revenue of the impression. Revenue per impression is meaningful for CPM.
        // For non CPM model, e.g. CPC, revenue per impression would always be 0.
        // Because tAdData is returned to the browser, we hide the revenue behind non-obvious name "data_1" and encoding.
        data_1: t.string,

        flight_type: t.string,

        id: t.number,
        kevel_token: t.string,
        model: t.union([t.literal('cpc'), t.literal('cpm')]),
        product_brand: tProductBrand,
      }),
      t.partial({
        keywords: t.array(t.string),
      }),
    ]),
    product_ids: t.union([t.array(t.number), t.null]),
    products: t.array(
      t.intersection([
        t.interface({ ad_token: t.string, product_id: t.number }),
        t.partial({ my_high_d: t.string }),
      ])
    ),
  }),
  t.partial({
    filter_brands: t.union([t.array(tProductBrand), t.null]),
  }),
]);

export type AdData = t.TypeOf<typeof tAdData>;

const tAdError = t.partial({
  error: t.string,
  message: t.string,
});

type AdError = t.TypeOf<typeof tAdError>;

export type AdType = AdData | AdError;

export type AdFlight = AdData['flight'];

export type DmMeta = {
  adToken?: string;
  myHighD?: string;
};

export const ZoneCart = 'cart';
export const ZoneStoreMenu = 'storeMenu';
export const ZoneStoreMenuInline = 'storeMenuInline';
export const ZoneStorePdp = 'storePDP';
export const ZoneMagicRow = 'magicRow';
export type Zone =
  | typeof ZoneCart
  | typeof ZoneStoreMenu
  | typeof ZoneStoreMenuInline
  | typeof ZoneStorePdp
  | typeof ZoneMagicRow;

export type ClickKind = 'product_click' | 'see_all';

export const SurfaceEcomm = 'ecomm';
export const SurfaceKiosk = 'kiosk';
export const SurfaceMarketplace = 'marketplace';

export type Surface =
  | typeof SurfaceEcomm
  | typeof SurfaceKiosk
  | typeof SurfaceMarketplace;
