import { useState } from 'react';

import type { SelectBankAccount } from '@jane/shared-ecomm/data-access';
import {
  Box,
  Button,
  Flex,
  Link,
  List,
  Modal,
  Typography,
} from '@jane/shared/reefer';

import { BankAccountCard, FakeRadio } from './selectBankAccountModal.styles';

export interface SelectBankAccountModalProps {
  bankAccounts: {
    account_last_4: string;
    bank_account_id: string;
    bank_name: string;
    is_selected: boolean;
    name: string;
  }[];
  onAddNewAccount: () => void;
  onRequestClose: () => void;
  onSave: SelectBankAccount;
  open: boolean;
}

export const SelectBankAccountModal = ({
  bankAccounts,
  onRequestClose,
  onSave,
  open,
  onAddNewAccount,
}: SelectBankAccountModalProps) => {
  const [bankAccountsList, setBankAccountsList] = useState(bankAccounts);

  const updateIsSelected = (name: string) => {
    setBankAccountsList((prevBankAccountsList) =>
      prevBankAccountsList.map((bankAccount) => ({
        ...bankAccount,
        is_selected: bankAccount.name === name,
      }))
    );
  };

  const findIsSelected = () => {
    const selected = bankAccountsList.find(
      (bankAccount) => bankAccount.is_selected === true
    );
    return selected && selected.bank_account_id;
  };

  return (
    <Modal open={open} onRequestClose={onRequestClose} variant="dialogue">
      <Modal.Content>
        <Box maxWidth="279px">
          <Flex flexDirection="column" alignItems="center" gap={16}>
            <Typography variant="header-bold" branded textAlign="center">
              Change your primary linked bank account
            </Typography>
            <List label="Bank Accounts">
              {bankAccountsList.map((bankAccount) => (
                <List.Item key={bankAccount.name} px={0} py={8}>
                  <BankAccountCard
                    borderWidth="2px"
                    border={
                      bankAccount.is_selected ? 'primary' : 'grays-ultralight'
                    }
                    width="100%"
                    flat
                  >
                    <Flex
                      ariaLabel={bankAccount.name}
                      alignItems="center"
                      onClick={() => updateIsSelected(bankAccount.name)}
                    >
                      <Box height={24} position="relative">
                        <FakeRadio
                          position="relative"
                          height={24}
                          width={24}
                          selected={bankAccount.is_selected}
                        >
                          <Box
                            height={12}
                            width={12}
                            left={4}
                            top={4}
                            position="absolute"
                            background="grays-white"
                          />
                        </FakeRadio>
                      </Box>
                      <Box ml={16}>
                        <Typography>{bankAccount.name}</Typography>
                      </Box>
                    </Flex>
                  </BankAccountCard>
                </List.Item>
              ))}
            </List>
            <Flex
              flexDirection="column"
              alignItems="center"
              gap={16}
              width="100%"
            >
              <Button
                variant="primary"
                branded
                label="Save"
                full
                onClick={() => {
                  onSave({
                    bankAccountId: findIsSelected(),
                  });
                  onRequestClose();
                }}
              />
              <Button
                variant="secondary"
                branded
                label="Cancel"
                full
                onClick={() => {
                  onRequestClose();
                }}
              />
              <Link
                onClick={() => {
                  onAddNewAccount();
                }}
              >
                Add new bank account
              </Link>
            </Flex>
          </Flex>
        </Box>
      </Modal.Content>
    </Modal>
  );
};
