import { useEffect, useState } from 'react';

import { AuthStorage } from './authStorage';
import { getJwt } from './jwt';

export const useJWT = () => {
  const [jwt, setJwt] = useState<string | undefined | null>(getJwt());

  useEffect(() => {
    return AuthStorage.subscribeToKey('jwt', setJwt);
  }, []);

  return jwt;
};
