// global is set in server html generated in
// api/app/lib/embed/html/build.rb
export type JaneWindow = {
  ___JANE___?: {
    runtimeConfig: ServerRuntimeConfig;
  };
};

// Runtime config is the type exposed to the
// rest of the application
export type RuntimeConfig = {
  customProductDetailTitleSuffix?: string; // in frameless embed mode, specifies suffix to be used on product detail page titles (i.e. Sour OG | Resin - Little Green Pot Shop, San Francisco, CA).
  disableJaneTitleAndDescriptionMeta: boolean; // in frameless embed mode, disables Jane specific page meta data (in menu title and for Open Graph).
  embedConfigId?: number;
  isBoostForAll: boolean;
  isFramelessEmbedMode: boolean;
  isPartnerHosted: boolean;
  partnerHostedPath?: string;
  partnerHostedStoreId?: number;
  showAgeGate: boolean;
  ssoCredentials: { [id: string]: string };
};

// Server config is how the server sets the config.
export type ServerRuntimeConfig = {
  customProductDetailTitleSuffix?: string;
  disableJaneTitleAndDescriptionMeta?: boolean;
  embedConfigId: number;
  isBoostForAll?: boolean;
  isFramelessEmbedMode?: boolean;
  partnerHostedPath?: string;
  showAgeGate: boolean;
  ssoCredentials: { [id: string]: string };
  storeId: number;
};

// value that will be used if server config is not present
export const defaultRuntimeConfig: RuntimeConfig = {
  disableJaneTitleAndDescriptionMeta: false,
  isBoostForAll: false,
  isFramelessEmbedMode: false,
  isPartnerHosted: false,
  showAgeGate: false,
  ssoCredentials: {},
};

// returns either a runtime config based on a server config,
// or the defaultRuntimeConfig if the server config is not present
export function getRuntimeConfig(): RuntimeConfig {
  let currentConfig = getCurrentConfig();

  if (currentConfig) {
    return currentConfig;
  }

  const serverConfig = getServerConfig();

  if (!serverConfig) {
    return defaultRuntimeConfig;
  }

  currentConfig = {
    customProductDetailTitleSuffix: serverConfig.customProductDetailTitleSuffix,
    disableJaneTitleAndDescriptionMeta:
      serverConfig.disableJaneTitleAndDescriptionMeta || false,
    embedConfigId: serverConfig.embedConfigId,
    isBoostForAll: serverConfig.isBoostForAll || false,
    isFramelessEmbedMode: serverConfig.isFramelessEmbedMode || false,
    isPartnerHosted: true,
    partnerHostedPath: serverConfig.partnerHostedPath || '',
    partnerHostedStoreId: serverConfig.storeId,
    showAgeGate: serverConfig.showAgeGate,
    ssoCredentials: serverConfig.ssoCredentials,
  };

  setCurrentConfig(currentConfig);

  return currentConfig;
}

// the runtime config is set on the server in
// api/app/lib/embed/html/build.rb
// this config will not map 1:1 with what is used on the client side,
// and will only be present on partner hosted sites.
export function getServerConfig(): ServerRuntimeConfig | null {
  const janeWindow = window as JaneWindow;
  return janeWindow.___JANE___?.runtimeConfig || null;
}

// cache of current config. This value is immutable, and
// will not change without a full page reload.
let currentConfig: RuntimeConfig | null = null;

export function getCurrentConfig(): RuntimeConfig | null {
  return currentConfig;
}

export function setCurrentConfig(config: RuntimeConfig | null) {
  currentConfig = config;
}
