import type { PendingCartProduct, StoreSpecial } from '@jane/shared/models';
import { getActualUnitPrice, getUnitPrice } from '@jane/shared/util';

import { CartDrawerCard } from './cartDrawerCard';

interface CartDrawerItemsProps {
  bloomUserExperience?: boolean;
  headless?: boolean;
  product: PendingCartProduct;
  productDetailLink: string;
  specials: StoreSpecial[];
}

export const CartDrawerItem = ({
  headless,
  product,
  productDetailLink,
  specials,
  bloomUserExperience,
}: CartDrawerItemsProps) => {
  const applicableSpecial = specials?.find(
    (special: StoreSpecial) => product.special_id === special.id
  );

  const productPrice = getActualUnitPrice(
    product,
    product.price_id,
    applicableSpecial
  ) as number;

  const productFullPrice = getUnitPrice(product, product.price_id);

  return (
    <CartDrawerCard
      bloomUserExperience={bloomUserExperience}
      headless={headless}
      productPrice={productPrice}
      product={product}
      productDetailLink={productDetailLink}
      productFullPrice={productFullPrice}
    />
  );
};
