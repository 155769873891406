/**
 * DO NOT MODIFY THIS FILE!
 * It was created by the reefer-icons generator.
 * Please update & re-run the generator if need be.
 */
import type { SVGProps } from 'react';

import type { StaticIconProps } from '../../../icon/icon.types';
import { Icon } from '../../icon';

const Lgbtq24 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="100%"
    height="100%"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <g className="LGBTQ+ 24">
      <path
        fill="#4BA279"
        fillRule="evenodd"
        d="M22 8C14.268 8 8 14.268 8 22h2c0-6.627 5.373-12 12-12V8Z"
        className="Fill-Special-06"
        clipRule="evenodd"
      />
      <path
        fill="#F33"
        fillRule="evenodd"
        d="M22 2C10.954 2 2 10.954 2 22h2c0-9.941 8.059-18 18-18V2Z"
        className="Fill-Special-05"
        clipRule="evenodd"
      />
      <path
        fill="#F90"
        fillRule="evenodd"
        d="M22 4C12.059 4 4 12.059 4 22h2c0-8.837 7.163-16 16-16V4Z"
        className="Fill-Special-04"
        clipRule="evenodd"
      />
      <path
        fill="#FFC220"
        fillRule="evenodd"
        d="M22 6C13.163 6 6 13.163 6 22h2c0-7.732 6.268-14 14-14V6Z"
        className="Fill-Special-03"
        clipRule="evenodd"
      />
      <path
        fill="#4485DA"
        fillRule="evenodd"
        d="M22 10c-6.627 0-12 5.373-12 12h2c0-5.523 4.477-10 10-10v-2Z"
        className="Fill-Special-02"
        clipRule="evenodd"
      />
      <path
        fill="#8E00E6"
        fillRule="evenodd"
        d="M22 12c-5.523 0-10 4.477-10 10h2a8 8 0 0 1 8-8v-2Z"
        className="Fill-Special-01"
        clipRule="evenodd"
      />
    </g>
  </svg>
);

const SIZE_MAP = {
  md: Lgbtq24,
  sm: Lgbtq24,
  lg: Lgbtq24,
  xl: Lgbtq24,
  xxl: Lgbtq24,
  xxxl: Lgbtq24,
};

export const LgbtqIcon = ({
  size = 'md',
  'data-testid': testId,
  ...props
}: StaticIconProps) => {
  return (
    <Icon
      data-testid={testId || 'lgbtq-icon'}
      icon={SIZE_MAP[size]}
      {...props}
      size={size}
      color={undefined}
    />
  );
};
