import { forwardRef } from 'react';

import type { DoubleSliderValue, SliderFieldProps } from '../field.types';
import { DoubleSliderFieldInternal } from './doubleSliderField';
import { SingleSliderFieldInternal } from './singleSliderField';

export interface SliderFieldPropsInternal extends SliderFieldProps {
  onUpdate?: (value: unknown) => void;
  value?: DoubleSliderValue | number;
}

export const SliderFieldInternal = forwardRef<
  HTMLInputElement,
  SliderFieldPropsInternal
>(
  (
    {
      autoFocus = false,
      children,
      className,
      defaultValue,
      disabled = false,
      endUnit,
      id,
      label,
      labelHidden = false,
      max,
      min,
      name,
      onAfterChange,
      onBlur,
      onChange,
      onUpdate,
      startUnit,
      step = 1,
      isDouble = true,
      'data-testid': testId,
      value,
      variant = 'primary',
      width = 'auto',
      ...props
    },
    ref
  ) => {
    if (!isDouble) {
      return (
        <SingleSliderFieldInternal
          autoFocus={autoFocus}
          className={className}
          defaultValue={
            typeof defaultValue === 'number' ? defaultValue : undefined
          }
          disabled={disabled}
          endUnit={endUnit}
          id={id}
          label={label}
          labelHidden={labelHidden}
          max={max}
          min={min}
          name={name}
          onAfterChange={onAfterChange}
          onBlur={onBlur}
          onChange={onChange}
          onUpdate={onUpdate}
          ref={ref}
          isDouble={false}
          startUnit={startUnit}
          step={step}
          data-testid={testId}
          value={typeof value === 'number' ? value : undefined}
          variant={variant}
          width={width}
          {...props}
        >
          {children}
        </SingleSliderFieldInternal>
      );
    }

    return (
      <DoubleSliderFieldInternal
        autoFocus={autoFocus}
        className={className}
        defaultValue={
          typeof defaultValue === 'object' ? defaultValue : undefined
        }
        disabled={disabled}
        endUnit={endUnit}
        id={id}
        label={label}
        labelHidden={labelHidden}
        max={max}
        min={min}
        name={name}
        onAfterChange={onAfterChange}
        onBlur={onBlur}
        onChange={onChange}
        onUpdate={onUpdate}
        ref={ref}
        startUnit={startUnit}
        step={step}
        data-testid={testId}
        value={typeof value === 'object' ? value : undefined}
        variant={variant}
        width={width}
        {...props}
      >
        {children}
      </DoubleSliderFieldInternal>
    );
  }
);

/**
 * Slider fields use a `range` slider to allow users let the user specify numeric values
 * which must be no less than a given value, and no more than another given value.
 *
 * Two types of sliders are supported:
 * - `single` - set 1 one numeric value, has a single handle.
 * - `double` - set 2 numberic values, has 2 handles.
 *
 * Use this component *outside forms*.
 *
 * **NOTE: DO NOT USE THIS COMPONENT WITHIN FORMS**
 *
 * *For a similar component for use within forms, see [`Form.SliderField`](/story/components-forms-form-sliderfield--default).*
 */
export const SliderField = forwardRef<HTMLInputElement, SliderFieldProps>(
  (props, ref) => <SliderFieldInternal ref={ref} {...props} />
);
