import type { InitialQuery } from '@jane/search/types';
import type { BrandPartner } from '@jane/shared/models';

const brandFilters = (brandPartner: BrandPartner): string[] =>
  brandPartner.product_brands.reduce((acc: string[], brand) => {
    acc.push(`brand:"${brand.name}"`);
    return acc;
  }, []);

const brandSubtypeFilters = (brandPartner: BrandPartner): string[] =>
  brandPartner.brand_subtypes.reduce((acc: string[], brandSubtype) => {
    acc.push(`brand_subtype:"${brandSubtype}"`);
    return acc;
  }, []);

const initialQueryBrandFilters = (initialQuery: InitialQuery) =>
  initialQuery?.brand?.value.reduce((acc: string[], b: string) => {
    acc.push(`brand:"${b}"`);

    return acc;
  }, []);

export const buildBrandFilterClause = (
  brandPartner?: BrandPartner,
  initialQuery?: InitialQuery
): string => {
  if (!brandPartner) return '';

  const subTypeFilters = brandSubtypeFilters(brandPartner);
  const filters =
    subTypeFilters.length > 0
      ? subTypeFilters
      : initialQuery?.brand
      ? initialQueryBrandFilters(initialQuery)
      : brandFilters(brandPartner);

  if (!filters || filters.length === 0) {
    return '';
  }

  return ['(', filters.join(' OR '), ')'].join('');
};
