/**
 * DO NOT MODIFY THIS FILE!
 * It was created by the reefer-icons generator.
 * Please update & re-run the generator if need be.
 */
import type { SVGProps } from 'react';

import type { DynamicIconProps } from '../../../icon/icon.types';
import { Icon } from '../../icon';

const LockSquare24 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="100%"
    height="100%"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <g className="Lock square 24">
      <path
        fill="#0E0E0E"
        fillRule="evenodd"
        d="M8 7a4 4 0 1 1 8 0v1a3 3 0 0 1 3 3v7a3 3 0 0 1-3 3H8a3 3 0 0 1-3-3v-7a3 3 0 0 1 3-3V7Zm6 0v1h-4V7a2 2 0 1 1 4 0Zm-7 4a1 1 0 0 1 1-1h8a1 1 0 0 1 1 1v7a1 1 0 0 1-1 1H8a1 1 0 0 1-1-1v-7Zm6 2a1 1 0 1 0-2 0v3a1 1 0 1 0 2 0v-3Z"
        className="SVG"
        clipRule="evenodd"
      />
    </g>
  </svg>
);

const SIZE_MAP = {
  md: LockSquare24,
  sm: LockSquare24,
  lg: LockSquare24,
  xl: LockSquare24,
  xxl: LockSquare24,
  xxxl: LockSquare24,
};

export const LockSquareIcon = ({
  size = 'md',
  'data-testid': testId,
  ...props
}: DynamicIconProps) => {
  return (
    <Icon
      data-testid={testId || 'lock-square-icon'}
      icon={SIZE_MAP[size]}
      {...props}
      size={size}
    />
  );
};
