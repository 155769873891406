import type {
  FilterGroup,
  FilterSortOption,
  SelectFilterGroup,
} from '@jane/search/types';
import { FilterSortId } from '@jane/search/types';
import {
  BlissfulIcon,
  CbdIcon,
  CrossIcon,
  FlowerIcon,
  GummyIcon,
  HybridIcon,
  IndicaIcon,
  LightningIcon,
  PencilIcon,
  PizzaIcon,
  RelaxedIcon,
  RelaxedWaveIcon,
  SativaIcon,
  SleepIcon,
  StarFilledIcon,
  TagFilledIcon,
  TinctureIcon,
  VapeIcon,
} from '@jane/shared/reefer';

export const productSortOptions: FilterSortOption[] = [
  {
    id: FilterSortId.AZ,
    isDefault: true,
    label: 'A-Z',
    value: 'a-z',
  },
  {
    id: FilterSortId.PriceAsc,
    isDefault: false,
    label: 'Price Low to High',
    value: 'price-low-to-high',
  },
  {
    id: FilterSortId.PriceDesc,
    isDefault: false,
    label: 'Price High to Low',
    value: 'price-high-to-low',
  },
  {
    id: FilterSortId.ThcAsc,
    isDefault: false,
    label: 'THC% Low to High',
    value: 'thc-low-to-high',
  },
  {
    id: FilterSortId.ThcDesc,
    isDefault: false,
    label: 'THC% High to Low',
    value: 'thc-high-to-low',
  },
  {
    id: FilterSortId.Rating,
    isDefault: false,
    label: 'Rating',
    value: 'rating',
  },
];

export const emptyProductFilter: SelectFilterGroup[] = [
  {
    key: 'root_types',
    label: 'Empty Categories',
    nested: false,
    showInFilterBar: true,
    type: 'multiselect',
    items: [],
  },
];

export const productFilters: FilterGroup[] = [
  {
    key: 'root_types',
    label: 'Categories',
    nested: true,
    showInFilterBar: true,
    type: 'multiselect',
    items: [
      {
        count: 30,
        icon: <FlowerIcon />,
        label: 'flower',
        subItems: [
          {
            count: 10,
            label: 'ground flower',
            value: 'ground flower',
          },
          {
            count: 10,
            label: 'indoor',
            value: 'indoor',
          },
          {
            count: 10,
            label: 'sun grown',
            value: 'sun grown',
          },
        ],
        value: 'flower',
      },
      {
        count: 20,
        icon: <VapeIcon />,
        label: 'vape',
        subItems: [
          {
            count: 10,
            label: 'cartridges',
            value: 'cartridges',
          },
          {
            count: 10,
            label: 'disposables',
            value: 'disposables',
          },
        ],
        value: 'vape',
      },
      {
        count: 10,
        icon: <GummyIcon />,
        label: 'edibles',
        subItems: [
          {
            count: 5,
            label: 'drinks',
            value: 'drinks',
          },
          {
            count: 5,
            label: 'candies',
            value: 'candies',
          },
        ],
        value: 'edibles',
      },
      {
        count: 10,
        icon: <TinctureIcon />,
        label: 'Tinctures',
        value: 'tinctures',
      },
    ],
  },
  {
    items: [
      {
        count: 10,
        label: 'My Happy Brand',
        value: 'My Happy Brand',
      },
      {
        count: 10,
        label: 'mew-mew',
        value: 'mew-mew',
      },
      {
        count: 10,
        label: 'mew-mew ii',
        value: 'mew-mew ii',
      },
      {
        count: 10,
        label: 'mew-mew iii',
        value: 'mew-mew iii',
      },
      {
        count: 10,
        label: 'Kiva Confections',
        value: 'Kiva Confections',
      },
      {
        count: 10,
        label: 'Kiva Confections ii',
        value: 'Kiva Confections ii',
      },
      {
        count: 10,
        label: 'Kiva Confections iii',
        value: 'Kiva Confections iii',
      },
      {
        count: 10,
        label: 'Cheeba Chews',
        value: 'Cheeba Chews',
      },
      {
        count: 10,
        label: 'Cheeba Chews ii',
        value: 'Cheeba Chews ii',
      },
      {
        count: 10,
        label: 'Cheeba Chews iii',
        value: 'Cheeba Chews iii',
      },
      {
        count: 10,
        label: "Jane's Brew",
        value: "Jane's Brew",
      },
      {
        count: 1000,
        label: 'zany 🤪',
        value: 'so zany',
      },
    ],
    key: 'brand',
    label: 'Brands',
    showInFilterBar: true,
    type: 'multiselect',
    typeahead: true,
  },
  {
    key: 'category',
    label: 'Lineage',
    showInFilterBar: true,
    type: 'multiselect',
    items: [
      {
        count: 10,
        icon: <IndicaIcon />,
        label: 'indica',
        value: 'indica',
      },
      {
        count: 10,
        icon: <SativaIcon />,
        label: 'Sativa',
        value: 'Sativa',
      },
      {
        count: 10,
        icon: <HybridIcon />,
        label: 'Hybrid',
        value: 'Hybrid',
      },
      {
        count: 10,
        icon: <CbdIcon />,
        label: 'Cbd',
        value: 'Cbd',
      },
    ],
  },
  {
    key: 'feelings',
    label: 'Feelings',
    showInFilterBar: true,
    type: 'multiselect',
    items: [
      {
        count: 10,
        icon: <LightningIcon />,
        label: 'Energetic',
        value: 'Energetic',
      },
      {
        count: 10,
        icon: <RelaxedIcon />,
        label: 'Relaxed',
        value: 'Relaxed',
      },
      {
        count: 10,
        icon: <BlissfulIcon />,
        label: 'Blissful',
        value: 'Blissful',
      },
      {
        count: 10,
        icon: <PizzaIcon />,
        label: 'Hungry',
        value: 'Hungry',
      },
      {
        count: 10,
        icon: <PencilIcon />,
        label: 'Creative',
        value: 'Creative',
      },
    ],
  },
  {
    key: 'activities',
    label: 'Activities',
    showInFilterBar: false,
    type: 'multiselect',
    items: [
      {
        count: 5,
        icon: <RelaxedWaveIcon />,
        label: 'Ease my mind',
        value: 'ease my mind',
      },
      {
        count: 5,
        icon: <CrossIcon />,
        label: 'Get relief',
        value: 'Get relief',
      },
      {
        count: 5,
        icon: <SleepIcon />,
        label: 'Get some sleep',
        value: 'Get some sleep',
      },
    ],
  },
  {
    key: 'applicable_special_ids',
    label: 'Specials',
    showInFilterBar: true,
    type: 'multiselect',
    items: [
      {
        count: 5,
        icon: <TagFilledIcon />,
        label: 'Special 1',
        value: 'Special 1',
      },
      {
        count: 5,
        icon: <TagFilledIcon />,
        label: 'Special 2',
        value: 'Special 2',
      },
      {
        count: 5,
        icon: <TagFilledIcon />,
        label:
          'Special 3 with a really long name let the ppl know what we have going on today',
        value: 'Special 3',
      },
    ],
  },
  {
    key: 'aggregate_rating',
    label: 'Rating',
    modalItemsPerRow: 4,
    showInFilterBar: false,
    type: 'multiselect',
    items: [
      {
        count: 5,
        icon: <StarFilledIcon size="sm" />,
        label: '1+',
        value: '>=1',
      },
      {
        count: 5,
        icon: (
          <>
            <StarFilledIcon size="sm" />
            <StarFilledIcon size="sm" ml={4} />
          </>
        ),
        label: '2+',
        value: '>=2',
      },
      {
        count: 5,
        icon: (
          <>
            <StarFilledIcon size="sm" />
            <StarFilledIcon size="sm" ml={4} />
            <StarFilledIcon size="sm" ml={4} />
          </>
        ),
        label: '3+',
        value: '>=3',
      },
      {
        count: 5,
        icon: (
          <>
            <StarFilledIcon size="sm" />
            <StarFilledIcon size="sm" ml={4} />
            <StarFilledIcon size="sm" ml={4} />
            <StarFilledIcon size="sm" ml={4} />
          </>
        ),
        label: '4+',
        value: '>=4',
      },
    ],
  },
  {
    items: [
      {
        key: 'percent_cbd',
        label: 'CBD Potency',
        max: 15,
        min: 1,
      },
      {
        key: 'percent_thc',
        label: 'THC Potency',
        max: 30,
        min: 5,
      },
    ],
    key: 'percent_thc',
    label: 'Potencies',
    nested: true,
    showInFilterBar: false,
    type: 'range',
  },
  {
    key: 'bucket_price',
    label: 'Price',
    modalItemsPerRow: 5,
    showInFilterBar: false,
    type: 'multiselect',
    items: [
      {
        label: 'Under 20$',
        value: ':19.99',
        count: 5,
      },
      {
        label: '20$-40$',
        value: '20:39.99',
        count: 5,
      },
      {
        label: '40$-60$',
        value: '40:59.99',
        count: 5,
      },
      {
        label: '60$-80$',
        value: '60:79.99',
        count: 5,
      },
      {
        label: '80$ and above',
        value: '80:',
        count: 5,
      },
    ],
  },
  {
    key: 'available_weights',
    label: 'Weights',
    modalItemsPerRow: 6,
    showInFilterBar: false,
    type: 'multiselect',
    items: [
      {
        label: '1/2g',
        value: '0.5',
        count: 5,
      },
      {
        label: '1g',
        value: '1',
        count: 5,
      },
      {
        label: '3.5g',
        value: '3.5',
        count: 5,
      },
      {
        label: '7g',
        value: '7',
        count: 5,
      },
      {
        label: '14g',
        value: '14',
        count: 5,
      },
      {
        label: '28g',
        value: '28',
        count: 5,
      },
    ],
  },
];

export const typeaheadFilters: SelectFilterGroup[] = [
  {
    items: [
      {
        count: 10,
        label: 'My Happy Brand',
        value: 'My Happy Brand',
      },
      {
        count: 10,
        label: 'mew-mew',
        value: 'mew-mew',
      },
      {
        count: 10,
        label: 'mew-mew ii',
        value: 'mew-mew ii',
      },
      {
        count: 10,
        label: 'mew-mew iii',
        value: 'mew-mew iii',
      },
      {
        count: 10,
        label: 'Kiva Confections',
        value: 'Kiva Confections',
      },
      {
        count: 10,
        label: 'Kiva Confections ii',
        value: 'Kiva Confections ii',
      },
      {
        count: 10,
        label: 'Kiva Confections iii',
        value: 'Kiva Confections iii',
      },
      {
        count: 10,
        label: 'Cheeba Chews',
        value: 'Cheeba Chews',
      },
      {
        count: 10,
        label: 'Cheeba Chews ii',
        value: 'Cheeba Chews ii',
      },
      {
        count: 10,
        label: 'Cheeba Chews iii',
        value: 'Cheeba Chews iii',
      },
      {
        count: 10,
        label: "Jane's Brew",
        value: "Jane's Brew",
      },
      {
        count: 1000,
        label: 'zany 🤪',
        value: 'so zany',
      },
    ],
    key: 'brand',
    label: 'Brands',
    showInFilterBar: true,
    type: 'multiselect',
    typeahead: true,
  },
  {
    items: [
      {
        count: 10,
        label: 'My Happy Brand',
        value: 'My Happy Brand',
      },
      {
        count: 10,
        label: 'mew-mew',
        value: 'mew-mew',
      },
      {
        count: 10,
        label: 'mew-mew ii',
        value: 'mew-mew ii',
      },
      {
        count: 10,
        label: 'mew-mew iii',
        value: 'mew-mew iii',
      },
      {
        count: 10,
        label: 'Kiva Confections',
        value: 'Kiva Confections',
      },
      {
        count: 10,
        label: 'Kiva Confections ii',
        value: 'Kiva Confections ii',
      },
      {
        count: 10,
        label: 'Kiva Confections iii',
        value: 'Kiva Confections iii',
      },
      {
        count: 10,
        label: 'Cheeba Chews',
        value: 'Cheeba Chews',
      },
      {
        count: 10,
        label: 'Cheeba Chews ii',
        value: 'Cheeba Chews ii',
      },
      {
        count: 10,
        label: 'Cheeba Chews iii',
        value: 'Cheeba Chews iii',
      },
      {
        count: 10,
        label: "Jane's Brew",
        value: "Jane's Brew",
      },
      {
        count: 1000,
        label: 'zany 🤪',
        value: 'so zany',
      },
    ],
    key: 'category',
    label: 'Products',
    showInFilterBar: true,
    type: 'multiselect',
    typeahead: true,
  },
];
