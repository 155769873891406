import { Box, Flex, Typography } from '@jane/shared/reefer';

import type { MenuProductCarouselProps } from './menuProductCarousel.types';

export const MenuProductCarouselTitle = ({
  name,
  variant,
}: MenuProductCarouselProps) => {
  if (variant === 'sponsored-inline') {
    return (
      <Flex>
        <Typography variant="mini" as="h2">
          {name}
          <Typography variant="mini" color="text-light" as="span" ml={4}>
            (Sponsored)
          </Typography>
        </Typography>
      </Flex>
    );
  }

  return (
    <Box>
      {variant === 'sponsored' && (
        <Typography variant="mini" color="text-light">
          Sponsored
        </Typography>
      )}
      <Typography
        as="h2"
        branded
        variant="header-bold"
        data-janedm-tag={variant === 'magic-row' ? 'row-title' : undefined}
      >
        {name}
      </Typography>
    </Box>
  );
};
