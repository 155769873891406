import clsx from 'clsx';
import React from 'react';

import type { ColorKey } from '../../theme';
import { marginCSSVars } from '../../utils';
import { DismissIcon } from '../icon';
import { Typography } from '../typography';
import styles from './banner.module.css';
import type { BannerProps } from './banner.types';

/**
 * Banner displays a short, important message with different variants depending on the type of alert.
 */

export function Banner({
  actions,
  className,
  'data-testid': testId,
  full = false,
  icon,
  id,
  label,
  onDismiss,
  style,
  typography,
  variant = 'info',
  ...marginProps
}: BannerProps) {
  const isText = typeof label === 'string';

  const colorMap: { [key: string]: ColorKey } = {
    error: 'error-dark',
    info: 'grays-black',
    success: 'success-dark',
  };

  return (
    <div
      className={clsx(className, styles.banner, styles[`banner__${variant}`], {
        [styles.banner__full]: full,
      })}
      data-testid={testId}
      id={id}
      style={{ ...style, ...marginCSSVars(marginProps) }}
    >
      <div className={styles.banner_content}>
        {icon && (
          <div>
            {React.cloneElement(icon, {
              ...icon.props,
              mr: 16,
              color: colorMap[variant],
            })}
          </div>
        )}
        <Typography
          as={isText ? 'p' : 'div'}
          color={colorMap[variant]}
          variant={
            typography ? typography : variant === 'info' ? 'body' : 'body-bold'
          }
        >
          {label}
        </Typography>
      </div>
      {actions}
      {onDismiss && (
        <div>
          <DismissIcon
            ml={16}
            onClick={onDismiss}
            color={colorMap[variant]}
            altText="dismiss banner"
          />
        </div>
      )}
    </div>
  );
}
