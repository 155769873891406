/**
 * DO NOT MODIFY THIS FILE!
 * It was created by the reefer-icons generator.
 * Please update & re-run the generator if need be.
 */
import type { SVGProps } from 'react';

import type { StaticIconProps } from '../../../icon/icon.types';
import { Icon } from '../../icon';

const Pencil24 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="100%"
    height="100%"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <g className="Pencil 24">
      <path
        fill="#FFC220"
        d="M2 16 12 6l6 6L8 22l-6-6Z"
        className="Fill-Main"
      />
      <path
        fill="#DADADA"
        d="m12 6 3-3 6 6-3 3-6-6Z"
        className="Fill-Accent-Light"
      />
      <path
        fill="#FF6334"
        d="m15 3 1.586-1.586a2 2 0 0 1 2.828 0l3.172 3.172a2 2 0 0 1 0 2.828L21 9l-6-6Z"
        className="Fill-Accent"
      />
      <g className="Fill-Secondary">
        <path fill="#FFC220" d="m0 24 2-8h2v2h2v2h2v2l-8 2Z" />
        <path fill="#fff" fillOpacity={0.8} d="m0 24 2-8h2v2h2v2h2v2l-8 2Z" />
      </g>
      <path
        fill="#0E0E0E"
        d="m0 24 1-4 3 3-4 1Z"
        className="Fill-Accent-Dark"
      />
    </g>
  </svg>
);

const SIZE_MAP = {
  md: Pencil24,
  sm: Pencil24,
  lg: Pencil24,
  xl: Pencil24,
  xxl: Pencil24,
  xxxl: Pencil24,
};

export const PencilIcon = ({
  size = 'md',
  'data-testid': testId,
  ...props
}: StaticIconProps) => {
  return (
    <Icon
      data-testid={testId || 'pencil-icon'}
      icon={SIZE_MAP[size]}
      {...props}
      size={size}
      color={undefined}
    />
  );
};
