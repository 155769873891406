import * as t from 'io-ts';

const tMemberOffer = t.interface({
  name: t.string,
  description: t.union([t.null, t.string]),
});

export enum CrmProvider {
  alpineiq = 'alpineiq',
  data_owl = 'data_owl',
  springbig = 'springbig',
}

export enum CrmIntegrationType {
  email = 'email',
  phone = 'phone',
}

const tReward = t.interface({
  amount: t.union([t.null, t.number]),
  type: t.union([t.null, t.string]),
});

export const tCrmReward = t.interface({
  crm_provider: t.keyof(CrmProvider),
  crm_reward_id: t.string,
  reward_name: t.string,
  description: t.union([t.null, t.string]),
  point_value: t.number,
  cash_reward: t.union([t.undefined, t.boolean]),
  reward: tReward,
  max_count: t.union([t.null, t.number]),
  redemption_payload: t.union([
    t.partial({
      url: t.string,
      checksum: t.string,
    }),
    t.undefined,
  ]),
});

export const tCrmIntegration = t.interface({
  signed_up: t.boolean,
  points: t.number,
  offers: t.union([t.array(tMemberOffer), t.undefined]),
  jane_redeemable_rewards: t.array(tCrmReward),
  store_redeemable_rewards: t.array(tCrmReward),
  automatic_redemption: t.boolean,
  crm_provider: t.keyof(CrmProvider),
  wallet_state: t.union([t.null, t.literal('locked'), t.literal('unlocked')]),
  max_redemption_count: t.union([t.null, t.number]),
});

export const tCrmRedemption = t.intersection([
  t.interface({
    cart_uuid: t.string,
    crm_reward_id: t.union([t.number, t.string]),
    crm_provider: t.keyof(CrmProvider),
    point_value: t.number,
    reward: tReward,
    reward_name: t.string,
  }),
  t.partial({
    bypass_redemption: t.boolean,
    id: t.union([t.number, t.string]),
    crm_redemption_id: t.union([t.null, t.string]),
    redemption_failed: t.union([t.null, t.boolean]),
    redemption_url: t.string,
    redemption_checksum: t.string,
    redeemed: t.boolean,
  }),
]);

export const tStoreCrmIntegration = t.interface({
  id: t.union([t.number, t.undefined]),
  token: t.union([t.string, t.null]),
  enabled: t.union([t.boolean, t.null]),
  automatic_redemption: t.union([t.boolean, t.null]),
  bypass_redemption: t.union([t.boolean, t.null, t.undefined]),
  crm_provider: t.keyof(CrmProvider),
  max_redemption_count: t.union([t.number, t.undefined, t.null]),
  _destroy: t.union([t.boolean, t.undefined]),
});

export const tCrmDetails = t.interface({
  crmId: t.union([t.string, t.undefined]),
  integration_type: t.union([t.literal('phone'), t.literal('email'), t.null]),
});

export type CrmReward = t.TypeOf<typeof tCrmReward>;
export type CrmDetails = t.TypeOf<typeof tCrmDetails>;
export type CrmIntegration = t.TypeOf<typeof tCrmIntegration>;
export type CrmProviderType = keyof typeof CrmProvider;
export type CrmRedemption = t.TypeOf<typeof tCrmRedemption>;
export type StoreCrmIntegration = t.TypeOf<typeof tStoreCrmIntegration>;
