import type { SVGProps } from 'react';

import { Icon } from '../../icon/icon';
import type { StaticIconProps } from '../../icon/icon.types';

const Monkey = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="64"
    height="64"
    viewBox="0 0 64 64"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <circle cx={32} cy={32} r={32} fill="#FFC220" />
    <circle cx={32} cy={32} r={32} fill="white" fillOpacity={0.8} />
    <circle cx={12} cy={32} r={8} fill="#FFC220" />
    <circle cx={12} cy={32} r={4} fill="#CE349A" />
    <circle cx={52} cy={32} r={8} fill="#FFC220" />
    <circle cx={52} cy={32} r={4} fill="#CE349A" />
    <circle cx={32} cy={32} r={20} fill="#FFC220" />
    <circle cx={24} cy={32} r={12} fill="#FFC220" />
    <circle cx={24} cy={32} r={12} fill="black" fillOpacity={0.2} />
    <ellipse
      cx={31.732}
      cy={11.4641}
      rx={2}
      ry={4}
      transform="rotate(30 31.732 11.4641)"
      fill="#FFC220"
    />
    <ellipse
      cx={33.7321}
      cy={13.4641}
      rx={2}
      ry={4}
      transform="rotate(30 33.7321 13.4641)"
      fill="#FFC220"
    />
    <circle cx={40} cy={32} r={12} fill="#FFC220" />
    <circle cx={40} cy={32} r={12} fill="black" fillOpacity={0.2} />
    <circle cx={32} cy={44} r={12} fill="#FFC220" />
    <circle cx={32} cy={44} r={12} fill="black" fillOpacity={0.2} />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M26.6403 49.6527C26.8322 49.454 27.1487 49.4485 27.3473 49.6403C29.915 52.1199 34.085 52.1199 36.6527 49.6403C36.8513 49.4485 37.1678 49.454 37.3597 49.6527C37.5515 49.8513 37.546 50.1678 37.3473 50.3597C34.3921 53.2134 29.6079 53.2134 26.6527 50.3597C26.454 50.1678 26.4485 49.8513 26.6403 49.6527Z"
      fill="#0E0E0E"
    />
    <circle cx={22} cy={32} r={6} fill="white" />
    <circle cx={42} cy={32} r={6} fill="white" />
    <circle cx={22} cy={32} r={4} fill="#CE349A" />
    <circle cx={42} cy={32} r={4} fill="#CE349A" />
    <circle cx={22} cy={32} r={2} fill="#0E0E0E" />
    <circle cx={29} cy={44} r={2} fill="#0E0E0E" />
    <circle cx={35} cy={44} r={2} fill="#0E0E0E" />
    <circle cx={42} cy={32} r={2} fill="#0E0E0E" />
    <circle cx={23} cy={31} r={1} fill="white" />
    <circle cx={43} cy={31} r={1} fill="white" />
  </svg>
);
export const MonkeyAvatar = ({ size = 'xl', ...props }: StaticIconProps) => {
  return <Icon icon={Monkey} {...props} size={size} color={undefined} />;
};
