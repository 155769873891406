import type {
  DefinedUseQueryResult,
  UseQueryResult,
} from '@tanstack/react-query';
import { useQuery } from '@tanstack/react-query';

import { config } from '@jane/shared/config';
import type { Store } from '@jane/shared/models';
import type { Store as ZodStore } from '@jane/shared/types';

import { api } from '../api';
import type { QueryBody } from '../types/queryBody';
import { queryKeys } from './stores.util';

type StoreId = Store['id'] | ZodStore['id'];
type GetStoreQueryBody<TStore> = QueryBody<
  TStore,
  unknown,
  TStore,
  (string | number)[]
>;
type QueryOptions<TStore> = Pick<
  GetStoreQueryBody<TStore>,
  'initialData' | 'onSuccess'
>;

const storeUrl = (storeId: StoreId) => `${config.apiPath}/stores/${storeId}`;

export const getStore = async (id: StoreId): Promise<Store> => {
  return api.get<{ store: Store }>(storeUrl(id)).then((result) => result.store);
};

export function getStoreQueryBody<TStore = Store>(
  id: StoreId,
  queryOptions?: QueryOptions<TStore>
): GetStoreQueryBody<TStore>;

export function getStoreQueryBody<TStore = Store>(
  id: StoreId,
  queryOptions?: Omit<QueryOptions<TStore>, 'initialData'> & {
    initialData: Store | (() => Store);
  }
): GetStoreQueryBody<TStore>;

export function getStoreQueryBody(id: StoreId, queryOptions: object = {}) {
  return {
    enabled: !!id,
    queryFn: () => getStore(id),
    queryKey: queryKeys.store(id),
    ...queryOptions,
  };
}

export function useGetStore<TStore = Store>(
  id: StoreId,
  queryOptions?: Omit<QueryOptions<TStore>, 'initialData'>
): UseQueryResult<TStore, unknown>;

export function useGetStore<TStore = Store>(
  id: StoreId,
  queryOptions?: Omit<QueryOptions<TStore>, 'initialData'> & {
    initialData: TStore | (() => TStore);
  }
): DefinedUseQueryResult<TStore, unknown>;

export function useGetStore(id: StoreId, queryOptions?: object) {
  return useQuery(getStoreQueryBody(id, queryOptions));
}
