import { isSortedByPrice } from '@jane/search/util';
import { FLAGS, useFlag } from '@jane/shared/feature-flags';
import { useMobileMediaQuery } from '@jane/shared/reefer';

import {
  DisplayMode,
  useProductCardContext,
} from '../productCardProvider/productCardProvider';
import { MenuProductConfirmationView } from './confirmationView';
import { MenuProductCardDefaultView } from './defaultView';
import { MenuProductCardEditView } from './editView';
import { StyledCardContent } from './legacyMenuProductCard.styles';
import type { RenderProps } from './listViewStateContainer';
import ListViewStateContainer from './listViewStateContainer';
import {
  MENU_PRODUCT_ITEM_HEIGHT,
  MENU_PRODUCT_MOBILE_ITEM_HEIGHT,
} from './utils/productCardHelper';

export const MenuProductCardContainer = () => {
  const {
    displayMode,
    hideActions,
    menuProduct,
    searchState,
    maxWeightOption,
    showOnlyWeights,
  } = useProductCardContext();
  const isMobile = useMobileMediaQuery({});
  const myHighMenu = useFlag(FLAGS.myHighMenu);
  const baseHeight = isMobile
    ? MENU_PRODUCT_MOBILE_ITEM_HEIGHT
    : MENU_PRODUCT_ITEM_HEIGHT;
  const menuProductCardHeight = hideActions ? baseHeight - 48 : baseHeight;

  return (
    <ListViewStateContainer sortedByPrice={isSortedByPrice(searchState)}>
      {(props: RenderProps) => {
        const { shoppingDisabled, selectedQuantity } = props;
        return (
          <>
            {displayMode === DisplayMode.Confirmation && (
              <StyledCardContent
                key={`${menuProduct?.id}-confirm`}
                focused
                myHighMenu={myHighMenu}
              >
                <MenuProductConfirmationView
                  {...props}
                  height={menuProductCardHeight}
                />
              </StyledCardContent>
            )}
            {displayMode === DisplayMode.Edit && (
              <StyledCardContent
                key={`${menuProduct?.id}-edit`}
                focused
                myHighMenu={myHighMenu}
              >
                <MenuProductCardEditView
                  {...props}
                  showOnlyWeights={showOnlyWeights}
                  maxWeightOption={maxWeightOption}
                  height={menuProductCardHeight}
                  selectedQuantity={selectedQuantity}
                  shoppingDisabled={shoppingDisabled}
                />
              </StyledCardContent>
            )}
            {displayMode === DisplayMode.Product && (
              <StyledCardContent
                key={`${menuProduct?.id}-default`}
                myHighMenu={myHighMenu}
              >
                <MenuProductCardDefaultView
                  height={menuProductCardHeight}
                  selectedQuantity={selectedQuantity}
                  shoppingDisabled={shoppingDisabled}
                />
              </StyledCardContent>
            )}
          </>
        );
      }}
    </ListViewStateContainer>
  );
};
