import { z } from 'zod';

const FacetSchema = z.record(z.string(), z.number());

export type AlgoliaFacet = z.infer<typeof FacetSchema>;

export const AlgoliaFacetsSchema = z.object({
  activities: FacetSchema.optional(),
  aggregate_rating: FacetSchema.optional(),
  applicable_special_ids: FacetSchema.optional(),
  available_weights: FacetSchema.optional(),
  brand_subtype: FacetSchema.optional(),
  brand: FacetSchema.optional(),
  bucket_price: FacetSchema.optional(),
  category: FacetSchema.optional(),
  feelings: FacetSchema.optional(),
  has_brand_discount: FacetSchema.optional(),
  kind: FacetSchema.optional(),
  percent_cbd: FacetSchema.optional(),
  percent_thc: FacetSchema.optional(),
  percent_thca: FacetSchema.optional(),
  root_types: FacetSchema.optional(),
  store_id: FacetSchema.optional(),
});

export const AlgoliaGoldFacetsSchema = z.object({
  has_brand_discount: FacetSchema.optional(),
});

export type AlgoliaFacets = z.infer<typeof AlgoliaFacetsSchema>;

export type ProductFilterKeys =
  | 'activities'
  | 'aggregate_rating'
  | 'applicable_special_ids'
  | 'available_weights'
  | 'brand_subtype'
  | 'brand'
  | 'bucket_price'
  | 'category'
  | 'feelings'
  | 'has_brand_discount'
  | 'percent_cbd'
  | 'percent_thc'
  | 'percent_thca'
  | 'root_types';

export const AlgoliaDispensaryFacetsSchema = z.object({
  current_deals: z.record(z.string(), z.number()).optional(),
  ownership_identification: z.record(z.string(), z.number()).optional(),
  store_options: z.record(z.string(), z.number()).optional(),
});

export type AlgoliaDispensaryFacets = z.infer<
  typeof AlgoliaDispensaryFacetsSchema
>;

export type DispensaryFilterKeys =
  | 'store_options'
  | 'current_deals'
  | 'ownership_identification'
  | 'open_now'
  | 'type';

export type ProductReviewFilterKeys = 'rating' | 'activities' | 'feelings';
