import { config } from '@jane/shared/config';
import type { Id } from '@jane/shared/models';

import { request } from '../lib/request';

export const MenuProductsSource = {
  get: (storeId: Id, productId: Id) => {
    const url = `${config.apiPath}/stores/${storeId}/products/${productId}`;
    return request(url, { method: 'GET' });
  },
};
