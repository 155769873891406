import * as t from 'io-ts';
import qs from 'qs';

import { config } from '@jane/shared/config';
import type { Address } from '@jane/shared/models';
import { tPosCart } from '@jane/shared/models';

import { request } from '../lib/request';

const buildUrl = (url = '') => `${config.apiPath}/pos_cart${url}`;

export const PosCartSource = {
  get(deliveryAddress: Address | null | undefined, cart_uuid: string) {
    const address = deliveryAddress || {};
    return request(buildUrl(), { method: 'GET' }, { ...address, cart_uuid });
  },
};

export const getPosCartSource = (
  cartUuid: string,
  deliveryAddress: Address | null | undefined
) => {
  const queryParams = qs.stringify({
    ...deliveryAddress,
    cart_uuid: cartUuid,
  });
  return {
    url: buildUrl(`?${queryParams}`),
    type: t.interface({ pos_cart: tPosCart }),
  };
};
