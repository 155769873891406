import type { FilterSelectItem } from '@jane/search/types';
import type { Store } from '@jane/shared/models';

import { PRODUCT_ROOT_TYPES } from './constants';

/**
 * This method accepts filters, with a store's custom rows, menu tabs arrangement, custom product rankings, and storeId.
 *
 * If a filter is not included in the custom rows, or product root types, it means the row
 * is hidden and the filter shouldn't be rendered.
 *
 * The storeId is required to remove it from the value to check against customRow items,
 * ex value will be BASE-68, while customRow value will be just BASE
 *  */

export const sortAndFilterCategories = (
  filters: FilterSelectItem[],
  customRanking: Store['custom_product_type_ranking'] = [],
  customRows: Store['custom_rows'] = [],
  menuTabs: Store['menu_tabs'] = [],
  storeId?: Store['id']
): FilterSelectItem[] => {
  const sortByArray = [
    ...menuTabs,
    ...PRODUCT_ROOT_TYPES,
    ...(customRanking ?? []),
  ];

  return storeId
    ? filters
        .sort((a, b) => {
          const indexA = sortByArray.indexOf(
            a.value.replace(`-${storeId}`, '')
          );
          const indexB = sortByArray.indexOf(
            b.value.replace(`-${storeId}`, '')
          );

          // Handle case when index is -1 (item not found in sortByArray)
          const effectiveIndexA = indexA !== -1 ? indexA : Infinity;
          const effectiveIndexB = indexB !== -1 ? indexB : Infinity;

          return effectiveIndexA - effectiveIndexB;
        })
        .filter(
          ({ value }) =>
            PRODUCT_ROOT_TYPES.includes(value) ||
            (customRows ?? []).includes(value.replace(`-${storeId}`, ''))
        )
    : filters;
};
