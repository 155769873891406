/**
 * DO NOT MODIFY THIS FILE!
 * It was created by the reefer-icons generator.
 * Please update & re-run the generator if need be.
 */
import type { SVGProps } from 'react';

import type { StaticIconProps } from '../../../icon/icon.types';
import { Icon } from '../../icon';

const Friends24 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="100%"
    height="100%"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <g className="Friends 24">
      <g className="SVG">
        <g className="Fill-Accent">
          <circle cx={16} cy={8} r={8} fill="#CE349A" />
          <circle cx={16} cy={8} r={8} fill="#fff" fillOpacity={0.8} />
        </g>
        <path
          fill="#000"
          fillOpacity={0.5}
          d="M14.667 6A1.333 1.333 0 1 1 12 6a1.333 1.333 0 0 1 2.667 0Zm-3.374 3.96a1 1 0 0 1 1.414 0 4.657 4.657 0 0 0 6.586 0 1 1 0 1 1 1.414 1.414 6.657 6.657 0 0 1-9.414 0 1 1 0 0 1 0-1.414Zm7.374-2.627a1.333 1.333 0 1 0 0-2.666 1.333 1.333 0 0 0 0 2.666Z"
          className="Fill-Secondary"
        />
        <circle cx={8} cy={16} r={8} fill="#FFC220" className="Fill-Main" />
        <path
          fill="#000"
          fillOpacity={0.5}
          d="M6.667 14A1.333 1.333 0 1 1 4 14a1.333 1.333 0 0 1 2.667 0Zm-3.374 3.96a1 1 0 0 1 1.414 0 4.657 4.657 0 0 0 6.586 0 1 1 0 0 1 1.414 1.414 6.657 6.657 0 0 1-9.414 0 1 1 0 0 1 0-1.414Zm7.374-2.627a1.333 1.333 0 1 0 0-2.666 1.333 1.333 0 0 0 0 2.666Z"
          className="Fill-Secondary"
        />
      </g>
    </g>
  </svg>
);

const SIZE_MAP = {
  md: Friends24,
  sm: Friends24,
  lg: Friends24,
  xl: Friends24,
  xxl: Friends24,
  xxxl: Friends24,
};

export const FriendsIcon = ({
  size = 'md',
  'data-testid': testId,
  ...props
}: StaticIconProps) => {
  return (
    <Icon
      data-testid={testId || 'friends-icon'}
      icon={SIZE_MAP[size]}
      {...props}
      size={size}
      color={undefined}
    />
  );
};
