import get from 'lodash/get';

import type { MenuProduct, PriceId } from '@jane/shared/models';

export function firstAvailablePriceIdFromFilters({
  menuProduct,
  appliedFilters,
  defaultValue,
}: {
  appliedFilters: string[];
  defaultValue: PriceId;
  menuProduct: MenuProduct;
}): PriceId {
  const applicablePriceId = appliedFilters
    ?.find((priceId) => get(menuProduct, `price_${priceId.replace(' ', '_')}`))
    ?.replace(' ', '_');
  return applicablePriceId ? (applicablePriceId as PriceId) : defaultValue;
}
