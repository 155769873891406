import { useIframeScroll, useIsInIframe } from '@jane/shared-ecomm/hooks';
import type { CalloutVariants } from '@jane/shared-ecomm/types';
import { Box, Modal } from '@jane/shared/reefer';

import type {
  JaneAuthenticationProps,
  SignInFormData,
} from '../authentication';
import { JaneAuthentication } from '../authentication';

export interface LogInModalProps
  extends Omit<
    JaneAuthenticationProps,
    'screen' | 'setScreen' | 'onAppleClick' | 'onGoogleClick' | 'onSignInSubmit'
  > {
  /** Location where the modal was opened, used for embedded modal styling */
  location?: CalloutVariants | 'header' | 'header prompt';

  onAppleClick: (source: string) => void;

  onGoogleClick: (source: string) => void;

  /** Function called when modal is closed */
  onRequestClose(): void;

  onSignInSubmit: (data: SignInFormData, source: string) => void;

  /** Boolean for whether or not the modal is open */
  open: boolean;
}

export const LogInModal = ({
  location,
  open,
  onRequestClose,
  ...authenticationProps
}: LogInModalProps) => {
  const isInIframe = useIsInIframe();

  const useScroll =
    isInIframe &&
    open &&
    !!location &&
    ['header', 'cart', 'checkout'].includes(location);

  useIframeScroll(useScroll);

  const { onAppleClick, onGoogleClick, onSignInSubmit } = authenticationProps;

  const loginFunctions = {
    onAppleClick: () => onAppleClick(location || 'login modal'),
    onGoogleClick: () => onGoogleClick(location || 'login modal'),
    onSignInSubmit: (data: SignInFormData) =>
      onSignInSubmit(data, location || 'login modal'),
  };

  return (
    <Modal
      variant="dialogue"
      open={open}
      overlayClose
      onRequestClose={onRequestClose}
      data-testid="log-in-modal"
      topOverride={isInIframe ? (useScroll ? '160px' : '350px') : undefined}
    >
      <Modal.Content>
        <Box maxWidth="297px">
          <JaneAuthentication {...authenticationProps} {...loginFunctions} />
        </Box>
      </Modal.Content>
    </Modal>
  );
};
