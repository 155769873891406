import styled from '@emotion/styled';

import { ChevronRightIcon, Typography } from '@jane/shared/reefer';
import { mediaQueries } from '@jane/shared/reefer-emotion';

import type {
  CarouselPaginationProps,
  StyledPaginationProps,
} from '../carousel.types';

const StyledCarouselPagination = styled.span<StyledPaginationProps>(
  ({ forceMobile, isInIframe, isLightbox, theme }) => ({
    position: 'absolute',
    bottom: 16,
    right: isInIframe && !isLightbox ? 34 : 16,
    background: 'rgba(0,0,0,0.5)',
    borderRadius: theme.borderRadius.lg,
    padding: '3px 8px 3px 8px',
    ...(!forceMobile && {
      [mediaQueries.tablet('min')]: {
        display: 'none',
      },
    }),
  })
);

// show pagination with controls on small screens for non-touch devices only
const StyledButtonCarouselPagination = styled.div<StyledPaginationProps>(
  ({ forceMobile, isInIframe, isLightbox, theme }) => ({
    position: 'absolute',
    right: isInIframe && !isLightbox ? 34 : 16,
    bottom: 16,
    background: 'rgba(0,0,0,0.5)',
    borderRadius: theme.borderRadius.lg,
    padding: '3px 8px 3px 8px',
    display: 'flex',
    alignItems: 'center',
    ...(!forceMobile && {
      [mediaQueries.tablet('min')]: {
        display: 'none',
      },
    }),
  })
);

interface ButtonWrapperProp {
  disabled: boolean;
  forceMobile?: boolean;
}

const ButtonWrapper = styled.div<ButtonWrapperProp>(
  ({ disabled, forceMobile }) => ({
    display: 'block',
    ...(disabled && {
      '> div': {
        cursor: 'auto',
        pointerEvents: 'none',
      },
    }),
    ...(!forceMobile && {
      [mediaQueries.tablet('min')]: {
        display: 'none',
      },
    }),
  })
);

export const CarouselPagination = ({
  activeIndex,
  forceMobile,
  isInIframe,
  isLightbox,
  totalItems,
  updateIndex,
}: CarouselPaginationProps) => {
  const isTouchscreen = navigator.maxTouchPoints > 0;
  const leftDisabled = activeIndex === 0;
  const rightDisabled = activeIndex === totalItems - 1;

  return isTouchscreen ? (
    <StyledCarouselPagination
      isInIframe={isInIframe}
      isLightbox={isLightbox}
      forceMobile={forceMobile}
    >
      <Typography variant="mini" color="grays-white">
        {activeIndex + 1}&#47;{totalItems}
      </Typography>
    </StyledCarouselPagination>
  ) : (
    <StyledButtonCarouselPagination forceMobile={forceMobile}>
      <ButtonWrapper disabled={leftDisabled} forceMobile={forceMobile}>
        <ChevronRightIcon
          altText="View previous item"
          color={leftDisabled ? 'grays-mid' : 'grays-white'}
          onClick={() => updateIndex && updateIndex(activeIndex - 1)}
          rotate="down"
          mr={8}
        />
      </ButtonWrapper>
      <Typography variant="mini" color="grays-white">
        {activeIndex + 1}&#47;{totalItems}
      </Typography>
      <ButtonWrapper disabled={rightDisabled} forceMobile={forceMobile}>
        <ChevronRightIcon
          altText="View next item"
          color={rightDisabled ? 'grays-mid' : 'grays-white'}
          onClick={() => updateIndex && updateIndex(activeIndex + 1)}
          ml={8}
        />
      </ButtonWrapper>
    </StyledButtonCarouselPagination>
  );
};
