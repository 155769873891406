import { JaneGoldIcon, Link, Tag, Typography } from '@jane/shared/reefer';
import type { MarginProperties } from '@jane/shared/reefer';

import {
  StyledButton,
  StyledContentContainer,
  StyledDescriptionContainer,
  StyledProfileSection,
  StyledTitle,
} from './janeGoldAccountStatusSection.styles';

export interface JaneGoldAccountStatusSectionProps extends MarginProperties {
  isEmbeddedMode?: boolean;
  isJaneGoldMember?: boolean;
  janeGoldMembershipStartDate?: string;
  onJoinJaneGold: () => void;
}

/**
 * The `JaneGoldAccountStatusSection` is for displaying a user's Jane gold status within the Account page.
 */
export function JaneGoldAccountStatusSection({
  isEmbeddedMode = false,
  isJaneGoldMember = false,
  janeGoldMembershipStartDate,
  onJoinJaneGold,
  ...marginProps
}: JaneGoldAccountStatusSectionProps) {
  const title = isJaneGoldMember ? 'Jane Gold' : 'Basic';
  let description = `You are eligible for upgrading to Jane Gold for free. Join the
  community to get the very best of Jane and cash back rewards from
  your favorite brands.`;
  if (isJaneGoldMember) {
    description = janeGoldMembershipStartDate
      ? `You've been a Jane Gold member since ${janeGoldMembershipStartDate}.`
      : `You're a Jane Gold member.`;
  }
  return (
    <StyledProfileSection
      background="grays-white"
      border="grays-light"
      borderRadius="lg"
      position="relative"
      maxWidth={1088}
      {...marginProps}
    >
      <StyledTitle branded variant="header-bold">
        Status
      </StyledTitle>
      <StyledContentContainer
        alignItems="center"
        gap={16}
        justifyContent="space-between"
      >
        <StyledContentContainer alignItems="center" gap={16}>
          {isJaneGoldMember && <JaneGoldIcon />}
          <StyledDescriptionContainer
            alignItems="center"
            flexDirection="column"
          >
            <Typography variant="body-bold">{title}</Typography>
            <Typography>
              {description}{' '}
              <Link href="https://customer-support.iheartjane.com/s/article/Jane-Gold-FAQs">
                Learn more
              </Link>
            </Typography>
          </StyledDescriptionContainer>
        </StyledContentContainer>
        {!isJaneGoldMember && (
          <StyledButton
            ml={4}
            mt={24}
            label="Join Jane Gold for free"
            onClick={onJoinJaneGold}
          />
        )}
        {isJaneGoldMember && <Tag background="grays-ultralight" label="Free" />}
      </StyledContentContainer>
    </StyledProfileSection>
  );
}
