/**
 * DO NOT MODIFY THIS FILE!
 * It was created by the reefer-icons generator.
 * Please update & re-run the generator if need be.
 */
import type { SVGProps } from 'react';

import type { DynamicIconProps } from '../../../icon/icon.types';
import { Icon } from '../../icon';

const ChevronLeft16 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="100%"
    height="100%"
    fill="none"
    viewBox="0 0 16 16"
    {...props}
  >
    <g className="Chevron Left 16">
      <path
        fill="#0E0E0E"
        fillRule="evenodd"
        d="M10.707 12.707a1 1 0 0 1-1.414 0l-4-4a1 1 0 0 1 0-1.414l4-4a1 1 0 0 1 1.414 1.414L7.414 8l3.293 3.293a1 1 0 0 1 0 1.414Z"
        className="SVG"
        clipRule="evenodd"
      />
    </g>
  </svg>
);

const ChevronLeft24 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="100%"
    height="100%"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <g className="Chevron Left 24">
      <path
        fill="#0E0E0E"
        fillRule="evenodd"
        d="M15.707 19.707a1 1 0 0 1-1.414 0l-7-7a1 1 0 0 1 0-1.414l7-7a1 1 0 1 1 1.414 1.414L9.414 12l6.293 6.293a1 1 0 0 1 0 1.414Z"
        className="SVG"
        clipRule="evenodd"
      />
    </g>
  </svg>
);

const SIZE_MAP = {
  sm: ChevronLeft16,
  md: ChevronLeft24,
  lg: ChevronLeft24,
  xl: ChevronLeft24,
  xxl: ChevronLeft24,
  xxxl: ChevronLeft24,
};

export const ChevronLeftIcon = ({
  size = 'md',
  'data-testid': testId,
  ...props
}: DynamicIconProps) => {
  return (
    <Icon
      data-testid={testId || 'chevron-left-icon'}
      icon={SIZE_MAP[size]}
      {...props}
      size={size}
    />
  );
};
