import isEqual from 'lodash/isEqual';

import type {
  ArrayFilterValue,
  FilterKeys,
  JaneSearchState,
  RangeFilterValue,
} from '@jane/search/types';

import { bucketFilterKeys, rangeFilterKeys } from './constants';
import { updateSortSuffixOnChange } from './updateSortSuffix';

export const handleFilterChange = (
  filterKey: FilterKeys,
  filterValue: ArrayFilterValue | RangeFilterValue,
  searchState: JaneSearchState<any>
): JaneSearchState<any> => {
  const { bucketFilters, currentSort, filters, rangeFilters, ...restOfState } =
    searchState;

  let updatedFilters = filters;
  let updatedBucketFilters = bucketFilters;
  let updatedRangeFilters = rangeFilters;
  let updatedSortSuffix = currentSort?.suffix;

  if (rangeFilterKeys.includes(filterKey)) {
    if (!rangeFilters) {
      updatedRangeFilters = {
        [filterKey]: filterValue as RangeFilterValue,
      };
    } else {
      const value = filterValue as RangeFilterValue;

      if (filterKey in rangeFilters) {
        const currentRange = rangeFilters[filterKey];
        if (isEqual(currentRange, filterValue)) {
          updatedRangeFilters = {
            ...rangeFilters,
            [filterKey]: {},
          };
        } else {
          updatedRangeFilters = {
            ...rangeFilters,
            [filterKey]: value,
          };
        }
      }

      if (filterKey in rangeFilters === false) {
        updatedRangeFilters = {
          ...rangeFilters,
          [filterKey]: value,
        };
      }
    }
  } else if (bucketFilterKeys.includes(filterKey)) {
    const value = filterValue as ArrayFilterValue;

    if (!bucketFilters) {
      updatedBucketFilters = {
        [filterKey]: [value],
      };
    } else {
      if (filterKey in bucketFilters) {
        const currentFilters = bucketFilters[filterKey] as ArrayFilterValue[];

        updatedBucketFilters = {
          ...bucketFilters,
          [filterKey]: [value, ...currentFilters],
        };

        if (currentFilters.includes(value)) {
          currentFilters.splice(currentFilters.indexOf(value), 1);
          updatedBucketFilters = {
            ...bucketFilters,
            [filterKey]: [...currentFilters],
          };
        }
      }

      if (filterKey in bucketFilters === false) {
        updatedBucketFilters = {
          ...bucketFilters,
          [filterKey]: [value],
        };
      }
    }
  } else {
    const value = filterValue as ArrayFilterValue;

    if (!filters) {
      updatedFilters = {
        [filterKey]: [value],
      };

      if (filterKey === 'available_weights') {
        updatedSortSuffix = updateSortSuffixOnChange(
          updatedSortSuffix || '',
          value,
          true
        );
      }
    } else {
      if (filterKey in filters) {
        const currentFilters = filters[filterKey] as ArrayFilterValue[];
        const isRemovingFilter = currentFilters.includes(value);

        updatedFilters = {
          ...filters,
          [filterKey]: [value, ...currentFilters],
        };

        if (isRemovingFilter) {
          currentFilters.splice(currentFilters.indexOf(value), 1);
          updatedFilters = {
            ...filters,
            [filterKey]: [...currentFilters],
          };
        }

        if (filterKey === 'available_weights') {
          const currentWeightFilters = filters[filterKey] as ArrayFilterValue[];

          updatedSortSuffix = updateSortSuffixOnChange(
            updatedSortSuffix || '',
            value,
            !isRemovingFilter,
            currentWeightFilters
          );
        }
      }

      if (!(filterKey in filters)) {
        updatedFilters = {
          ...filters,
          [filterKey]: [value],
        };

        if (filterKey === 'available_weights') {
          updatedSortSuffix = updateSortSuffixOnChange(
            updatedSortSuffix || '',
            value,
            true
          );
        }
      }
    }
  }

  const updatedSort = currentSort;
  if (updatedSort && updatedSortSuffix) {
    updatedSort.suffix = updatedSortSuffix;
  }

  return {
    ...restOfState,
    bucketFilters: updatedBucketFilters,
    currentSort: updatedSort,
    filters: updatedFilters,
    rangeFilters: updatedRangeFilters,
  };
};
