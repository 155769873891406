import { config } from '@jane/shared/config';
import { getWebpackConfigUtils } from '@jane/shared/runtime-config';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
declare let __webpack_public_path__: string;

export const maybeSetPublicPath = (deployPublicPathSuffix = '') => {
  if (getWebpackConfigUtils().shouldSetPublicPath) {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    __webpack_public_path__ = `${config.deployPublicPath}${deployPublicPathSuffix}`;
  }
};
