import type { HTMLAttributes } from 'react';

import { Typography } from '@jane/shared/reefer';

export const Kind: React.FC<HTMLAttributes<HTMLDivElement>> = ({
  children,
  color: _,
  ...props
}) => (
  <Typography
    as="div"
    color="text-light"
    truncateAt="auto"
    variant="body"
    {...props}
  >
    {children}
  </Typography>
);
