import { useQuery } from '@tanstack/react-query';
import type {
  QueryKey,
  UseQueryOptions,
  UseQueryResult,
} from '@tanstack/react-query';

/*
 * The createUseQueryHook is used to generate a standardized custom hook for calling useQuery.
 * This allows consistent use of query keys while also exposing the built in useQuery props (enabled, onError, etc.).
 * To use this utility, you'll create the useCustomQuery hook and have it equal this function:
 * createUseEntityHook<CustomHookProps, ReturnType>(apiCallFunction, queryKeyFunction, enabledFunction?).
 * React query expects query keys to contain all the values that the api call utilizes,
 * so both functions utilize the same props.
 * To control whether or not the query is enabled from the custom hook level,
 * you can pass a callback function that utilizes any of the hook's props.
 * (When calling the custom hook, the enabled prop can still be used in tandem with defaultEnabled)
 * Refer to aeropay.queries.ts to see it in action.
 * Refer to this doc for more info: https://www.notion.so/iheartjane/React-Query-Utils-d564ecd4e2db42a1bf271a93ab5eff19?pvs=4
 */

export type QueryOptions<
  TQueryFnData = unknown,
  TError = unknown,
  TData = TQueryFnData,
  TQueryKey extends QueryKey = QueryKey
> = Omit<
  UseQueryOptions<TQueryFnData, TError, TData, TQueryKey>,
  'queryKey' | 'queryFn'
>;

export const createUseQueryHook =
  <TProps, TEntity, TError = Error>(
    fetchEntity: (props: TProps) => Promise<TEntity>,
    queryKey: (props: TProps) => QueryKey,
    defaultEnabled?: (props: TProps) => boolean,
    defaultQueryOptions: QueryOptions<TEntity, TError> = {}
  ) =>
  (
    props: TProps,
    queryOptions: QueryOptions<TEntity, TError> = {}
  ): UseQueryResult<TEntity, TError> => {
    const mergedOptions = { ...defaultQueryOptions, ...queryOptions };
    const { enabled = true, ...options } = mergedOptions;
    const isEnabled = Boolean(
      enabled && (defaultEnabled ? defaultEnabled(props) : true)
    );

    const key = queryKey(props);
    return useQuery<TEntity, TError>({
      queryKey: key,
      queryFn: () => fetchEntity(props),
      ...options,
      enabled: isEnabled,
    });
  };
