import {
  Button,
  Flex,
  JaneGoldIcon,
  Link,
  PoweredByAeropayLogo,
  Typography,
} from '@jane/shared/reefer';

export interface JaneGoldPromptScreenProps {
  onContinue: () => void;
  onRequestClose: () => void;
}

export const JaneGoldPromptScreen = ({
  onContinue,
  onRequestClose,
}: JaneGoldPromptScreenProps) => {
  return (
    <Flex flexDirection="column" alignItems="center" gap={24}>
      <JaneGoldIcon size="xxl" />
      <Flex flexDirection="column" alignItems="center" gap={8} width="100%">
        <Typography variant="header-bold" branded textAlign="center">
          Earn cash back and shop faster
        </Typography>
        <Typography textAlign="center">
          Linking your bank account will let you join Jane Gold, Jane's cash
          back rewards program, and checkout quickly with Jane Pay.
        </Typography>
      </Flex>
      <Flex flexDirection="column" alignItems="center" gap={16} width="100%">
        <Button
          variant="primary"
          branded
          label="Continue"
          full
          onClick={() => {
            onContinue();
          }}
        />
        <Button
          variant="secondary"
          branded
          label="Not now"
          full
          onClick={() => {
            onRequestClose();
          }}
        />
      </Flex>
      <Flex flexDirection="column" alignItems="center" gap={8} width="100%">
        <Typography variant="mini" color="grays-mid" textAlign="center" mb={8}>
          By agreeing to link your bank, you agree to Aeropay's{' '}
          <Link
            ariaLabel="link for Aeropay terms of service"
            href="https://www.aeropay.com/legal/terms-of-service"
            color="grays-mid"
          >
            Terms of Service
          </Link>{' '}
          and{' '}
          <Link
            ariaLabel="link for Aeropay privacy policy"
            href="https://www.aeropay.com/legal/privacy-policy"
            color="grays-mid"
          >
            Privacy Policy
          </Link>
        </Typography>
        <PoweredByAeropayLogo height={24} />
      </Flex>
    </Flex>
  );
};
