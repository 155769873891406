import { Suspense, lazy } from 'react';

import { LoadingWrapper } from '@jane/shared/components';

import { useCustomerSelector } from '../../customer/selectors';
import { get } from '../../redux-util/selectors';
import type { IdentificationProps } from './identification.types';

/** Code splitting to load convertIfPDF/pdfjs only when called */
const DeluxeIdentificationUploader = lazy(() =>
  import('./idUploader').then(({ DeluxeIdentificationUploader }) => ({
    default: DeluxeIdentificationUploader,
  }))
);

const GovernmentAssistanceIdentification = ({
  showValidationErrors,
  valid,
  onIdUploadAttempt,
  onRemove,
}: IdentificationProps) => {
  const { document, loading_government_photo } = useCustomerSelector(
    get('identification')
  );

  if (!document) return null;

  return (
    <Suspense fallback={<LoadingWrapper isLoading />}>
      <DeluxeIdentificationUploader
        imageKey="government_assistance_photo"
        inputName="government-assistance-id"
        label="Upload Government Assistance ID"
        document={document}
        isLoading={loading_government_photo}
        valid={valid}
        showValidationErrors={showValidationErrors}
        onIdUploadAttempt={onIdUploadAttempt}
        onRemove={onRemove}
      />
    </Suspense>
  );
};

export default GovernmentAssistanceIdentification;
