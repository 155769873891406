import type { SVGProps } from 'react';

import { Icon } from '../../icon';
import type { LogoProps } from '../logo.types';

const Boa = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 96 96"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M40.746 39.9659C42.6453 40.7047 44.5445 41.4966 46.3914 42.3931C40.4765 44.7658 34.7602 47.6064 29.2969 50.888C27.45 49.9376 25.5508 48.9888 23.6516 48.1438C29.1384 44.9787 34.8368 42.2353 40.746 39.9659ZM33.6755 37.4865C27.5601 39.4414 21.6422 41.9674 16.0001 45.031C17.7938 45.7175 19.5876 46.4031 21.3291 47.1419C26.7713 43.9189 32.5142 41.2331 38.4767 39.1226C36.8937 38.5415 35.3116 37.9613 33.6755 37.4865Z"
      fill="#2267AD"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M53.5673 39.7029C48.7221 37.3876 43.7139 35.43 38.5829 33.8459C36.736 34.373 34.9422 34.9533 33.2008 35.5343C38.4824 37.1213 43.6334 39.1144 48.6077 41.4959C50.2437 40.8625 51.8782 40.2831 53.5673 39.7029ZM61.4289 37.3812C56.5215 35.218 51.4034 33.4242 46.1797 31.9998C44.4914 32.3161 42.8561 32.6855 41.2209 33.1072C46.3914 34.6378 51.4557 36.537 56.3107 38.8587C57.9983 38.3308 59.7397 37.8037 61.4289 37.3812ZM74.0908 41.6021C61.6913 45.7707 50.0836 51.7322 39.5848 59.1721C42.2228 60.8074 44.7553 62.4958 47.2348 64.3419C57.2243 56.426 68.2479 49.9112 80.0001 44.978C78.0716 43.782 76.1005 42.656 74.0908 41.6021ZM65.5436 37.592C52.6692 40.9687 40.5875 46.4563 29.6655 53.7369C32.0926 54.9504 34.5197 56.2171 36.8938 57.5884C47.6049 50.2016 59.4766 44.3978 72.1385 40.5986C69.9761 39.5443 67.8122 38.4893 65.5436 37.592Z"
      fill="#CD2E29"
    />
  </svg>
);
export const BoaLogo = ({
  height = 48,
  width = 'auto',
  ...props
}: LogoProps) => {
  return (
    <Icon
      height={height}
      width={width}
      {...props}
      icon={Boa}
      color={undefined}
      rotate={undefined}
      size={undefined}
    />
  );
};
