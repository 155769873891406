import styled from '@emotion/styled';
import type { CSSProperties } from 'react';

import { Box } from '@jane/shared/reefer';
import type { BoxProps } from '@jane/shared/reefer';

export interface PageLayoutProps
  extends BoxProps,
    Omit<React.HTMLProps<HTMLDivElement>, 'as' | 'onClick' | 'role'> {
  children: React.ReactNode;

  // For pages that ignore the max width and expand inline with the header.
  fullScreen?: boolean;

  /**
   For accessibility purposes, every page is expected to have a heading (h1).
   If a page does not contain an actual heading, this will add one and hide it.
  **/
  offscreenTitle?: string;

  /** By default, overflow-x is hidden. Optionally override it. */
  overflowX?: CSSProperties['overflowX'];
}

export const StyledPageLayout = styled(Box)<Pick<PageLayoutProps, 'overflowX'>>(
  ({ overflowX }) => ({
    overflowX: overflowX,
  })
);

const OffscreenTitle = styled.h1({
  position: 'absolute',
  left: '-10000px',
  top: 'auto',
  width: '1px',
  height: '1px',
  overflow: 'hidden',
});

/**
 * Page content should have a max width of '1440px'
 * This width accounts for 24px left/right padding on child elements
 * Padding can be applied directly to the Layout, or to child elements
 */

export const LAYOUT_MAX_WIDTH = '1488px';

export const PageLayout = ({
  children,
  fullScreen = false,
  offscreenTitle,
  overflowX = 'hidden',
  ...props
}: PageLayoutProps) => {
  return (
    <StyledPageLayout
      width="100%"
      height="100%"
      maxWidth={fullScreen ? 'auto' : LAYOUT_MAX_WIDTH}
      mx="auto"
      position="relative"
      overflowX={overflowX}
      {...props}
    >
      {offscreenTitle && <OffscreenTitle>{offscreenTitle}</OffscreenTitle>}
      {children}
    </StyledPageLayout>
  );
};
