import * as t from 'io-ts';

export const tStoreSeoResponse = t.interface({
  structured_data: t.UnknownRecord,
  page_title: t.union([t.string, t.null]),
  disable_meta: t.union([t.boolean, t.null]),
});

export type StoreSeoResponse = t.TypeOf<typeof tStoreSeoResponse>;

export interface StoreSeo {
  disableMeta?: boolean | null;
  pageTitle?: string | null;
  structuredData?: Record<string, unknown>;
}
