import isFinite from 'lodash/isFinite';

export function formatCurrency(price?: number | string | null) {
  if (typeof price === 'undefined' || price === null) {
    return '-';
  }

  const parsed = typeof price === 'string' ? parseFloat(price) : price;
  const displayPrice = Math.abs(parsed).toFixed(2);
  const negative = parsed < 0;
  return isFinite(parsed) ? `${negative ? '-' : ''}$${displayPrice}` : '-';
}
