import compact from 'lodash/compact';

import type {
  ActiveFilters,
  AlgoliaProduct,
  DispensaryFilterKeys,
  JaneSearchState,
  MenuOptions,
  ProductFilterKeys,
  ProductReviewFilterKeys,
} from '@jane/search/types';
import type { Store, StoreSpecial } from '@jane/shared/models';
import type {
  Store as ZodStore,
  StoreSpecial as ZodStoreSpecial,
} from '@jane/shared/types';

import {
  formatActiveFilterLabel,
  formatActivePotencyFilterLabel,
} from './formatActiveFilterLabel';
import { getSpecialsLabels } from './getSpecialsLabels';

const arrayFilterKeys: ProductFilterKeys[] = [
  'activities',
  'aggregate_rating',
  'applicable_special_ids',
  'available_weights',
  'brand',
  'bucket_price',
  'category',
  'feelings',
  'has_brand_discount',
  'root_types',
];

const rangeFilterKeys: ProductFilterKeys[] = ['percent_cbd', 'percent_thc'];

/**
 * Translates product search state into array of active filter items:
 * @param {JaneSearchState<AlgoliaProduct>} [searchState] - The current search state.
 * @param {MenuOptions} [menuOptions] - Menu options encompass all customization options for store menus. Active filters take specials, customLabels and storeId into account.
 */

export const formatActiveFilters = <
  T extends Store | ZodStore = Store,
  U extends StoreSpecial | ZodStoreSpecial = StoreSpecial
>(
  searchState: JaneSearchState<AlgoliaProduct>,
  menuOptions?: MenuOptions<T, U>
): ActiveFilters[] => {
  const { customLabels, specials, storeId } = menuOptions || {};
  const activeFilters = {
    ...searchState.bucketFilters,
    ...searchState.filters,
    ...searchState.rangeFilters,
  };

  const specialsLabels = specials ? getSpecialsLabels<U>(specials) : undefined;

  // we use flatMap because formatActiveFilterLabel accepts and returns an array of items
  // ex: root_types, ['flower', 'vape'] ==> [{ label: 'Flower', value: 'flower', key: 'root_types' },
  // { label: 'Vape', value: 'vape', key: 'root_types' }]
  const formattedArrayFilters = arrayFilterKeys.flatMap((key) => {
    const filters = activeFilters[key];
    let customMenuLabels = undefined;

    if (key === 'category' || key === 'root_types') {
      customMenuLabels = customLabels ? customLabels[key] : undefined;
    }

    const presentedLabels =
      key === 'applicable_special_ids'
        ? specialsLabels
        : customMenuLabels
        ? customMenuLabels
        : undefined;

    return Array.isArray(filters) && filters.length
      ? formatActiveFilterLabel(
          key,
          filters.map((value) => value.toString()),
          {
            storeId,
            customLabels: presentedLabels,
          }
        )
      : [];
  });

  const formattedPotencyFilters = rangeFilterKeys.map((key) => {
    const filter = activeFilters[key];

    return (
      filter &&
      !Array.isArray(filter) &&
      formatActivePotencyFilterLabel(key, filter)
    );
  });

  return compact([...formattedArrayFilters, ...formattedPotencyFilters]);
};

const dispensaryArrayFilterKeys: DispensaryFilterKeys[] = [
  'store_options',
  'current_deals',
  'ownership_identification',
];

export const formatActiveDispensaryFilters = (
  searchState: JaneSearchState<any>
): ActiveFilters[] => {
  const activeFilters = {
    ...searchState.filters,
  };

  const formattedArrayFilters = dispensaryArrayFilterKeys.flatMap((key) => {
    const filters = activeFilters[key];

    return Array.isArray(filters) && filters.length
      ? formatActiveFilterLabel(
          key,
          filters.map((value) => value.toString())
        )
      : [];
  });

  return compact([...formattedArrayFilters]);
};

const productReviewArrayFilterKeys: ProductReviewFilterKeys[] = [
  'activities',
  'feelings',
  'rating',
];

export const formatActiveProductReviewFilters = (
  searchState: JaneSearchState<any>
): ActiveFilters[] => {
  const activeFilters = {
    ...searchState.filters,
  };

  const formattedArrayFilters = productReviewArrayFilterKeys.flatMap((key) => {
    const filters = activeFilters[key];

    return Array.isArray(filters) && filters.length
      ? formatActiveFilterLabel(
          key,
          filters.map((value) => value.toString())
        )
      : [];
  });

  return compact([...formattedArrayFilters]);
};
