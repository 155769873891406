import store from 'store';

export class Storage {
  static set(key: string, data: any) {
    store.set(key, data);
  }

  static get<T = any>(key: string): T | undefined {
    return store.get(key);
  }

  static remove(key: string) {
    store.remove(key);
  }

  static clear() {
    store.clearAll();
  }
}

export class SessionStorage {
  static set(key: string, value: string) {
    sessionStorage.setItem(key, value);
  }

  static get(key: string) {
    return sessionStorage.getItem(key);
  }

  static remove(key: string) {
    sessionStorage.removeItem(key);
  }

  static clear() {
    sessionStorage.clear();
  }
}

// sessionStorage & localStorage are not available on iframes in incognito sessions, and attempting
// to use the storage apis will throw an error.
// https://stackoverflow.com/a/16427747
const testStorage = 't';
function isSessionStorageAvailable() {
  try {
    sessionStorage.setItem(testStorage, testStorage);
    sessionStorage.removeItem(testStorage);
    return true;
  } catch (e) {
    return false;
  }
}

function isLocalStorageAvailable() {
  try {
    localStorage.setItem(testStorage, testStorage);
    localStorage.removeItem(testStorage);
    return true;
  } catch (e) {
    return false;
  }
}

export function isBrowserStorageAvailable() {
  return isSessionStorageAvailable() && isLocalStorageAvailable();
}
