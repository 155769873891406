import ReactGA4 from 'react-ga4';

import type {
  AddedOrUpdatedProductInCart,
  CheckoutSuccess,
  RemovedProductFromCart,
  ViewedProduct,
} from './eventNames';

declare global {
  interface Window {
    JANE_GOOGLE_ANALYTICS_ENHANCED_ECOMMERCE_ENABLED: boolean;
  }
}

export const gaTrackProductClick = (event: ViewedProduct) => {
  if (!window.JANE_GOOGLE_ANALYTICS_ENHANCED_ECOMMERCE_ENABLED) return;

  GA4ViewProduct(event);
};

export const gaTrackAddedProductToCart = (
  event: AddedOrUpdatedProductInCart
) => {
  if (!window.JANE_GOOGLE_ANALYTICS_ENHANCED_ECOMMERCE_ENABLED) return;

  GA4AddToCart(event);
};

export const gaTrackRemovedProductFromCart = (
  event: RemovedProductFromCart
) => {
  if (!window.JANE_GOOGLE_ANALYTICS_ENHANCED_ECOMMERCE_ENABLED) return;

  GA4RemoveFromCart(event);
};

export const gaTrackCheckoutSuccess = (event: CheckoutSuccess) => {
  if (!window.JANE_GOOGLE_ANALYTICS_ENHANCED_ECOMMERCE_ENABLED) return;

  GA4Checkout(event);
};

function GA4ViewProduct(event: ViewedProduct) {
  const {
    productId,
    productName,
    pricePerUnit,
    priceUnit,
    productBrand,
    productKind,
    productBrandSubtype,
    productLineage,
    storeName,
    storeCurrency = 'USD',
  } = event;

  ReactGA4.event('view_item', {
    currency: storeCurrency,
    value: pricePerUnit,
    items: [
      {
        item_id: productId,
        item_name: productName,
        affiliation: storeName,
        item_brand: productBrand,
        item_category: productKind,
        item_category2: productBrandSubtype,
        item_category3: productLineage,
        item_variant: priceUnit,
        price: pricePerUnit,
        quantity: 1,
      },
    ],
  });
}

function GA4AddToCart(event: AddedOrUpdatedProductInCart) {
  const {
    productId,
    productName,
    priceUnit,
    pricePerUnit,
    quantity,
    priceTotal,
    productBrand,
    productKind,
    productBrandSubtype,
    specialDiscountPerUnit,
    productLineage,
    storeName,
    storeCurrency = 'USD',
  } = event;

  ReactGA4.event('add_to_cart', {
    currency: storeCurrency,
    value: priceTotal,
    items: [
      {
        item_id: productId,
        item_name: productName,
        affiliation: storeName,
        discount: specialDiscountPerUnit,
        item_brand: productBrand,
        item_category: productKind,
        item_category2: productBrandSubtype,
        item_category3: productLineage,
        item_variant: priceUnit,
        price: pricePerUnit,
        quantity,
      },
    ],
  });
}

function GA4RemoveFromCart(event: RemovedProductFromCart) {
  const {
    productId,
    productName,
    productPrice,
    quantity,
    productBrand,
    productKind,
    storeCurrency = 'USD',
  } = event;

  const nonNullPrice = productPrice || 0;
  const total = nonNullPrice * (quantity || 1);

  ReactGA4.event('remove_from_cart', {
    currency: storeCurrency,
    value: total,
    items: [
      {
        item_id: productId,
        item_name: productName,
        item_brand: productBrand,
        item_category: productKind,
        price: nonNullPrice,
        quantity,
      },
    ],
  });
}

function GA4Checkout(event: CheckoutSuccess) {
  const items = event.products.map((product) => {
    const {
      productId,
      productName,
      productKind,
      productBrand,
      priceUnit,
      quantity,
    } = product;

    return {
      item_id: productId,
      item_name: productName,
      item_category: productKind,
      item_brand: productBrand,
      price: priceUnit,
      quantity: quantity,
    };
  });

  const { priceTotal, cartId, storeCurrency = 'USD' } = event;

  ReactGA4.event('purchase', {
    transaction_id: cartId,
    value: priceTotal,
    tax: 0,
    shipping: 0,
    currency: storeCurrency,
    items,
  });
}
