import type { Store } from '@jane/shared/models';
import { titleCase } from '@jane/shared/util';

export const getReservationModeLabel = (
  customLabels: Store['reservation_mode_labels'],
  reservationMode: 'pickup' | 'curbside' | 'delivery'
): string => {
  const customLabel = customLabels && customLabels[reservationMode];
  const defaultLabel = titleCase(reservationMode);

  return customLabel || defaultLabel;
};

export const getReservationModeLabels = (
  customLabels: Store['reservation_mode_labels']
) => ({
  pickupLabel: getReservationModeLabel(customLabels, 'pickup'),
  deliveryLabel: getReservationModeLabel(customLabels, 'delivery'),
  curbsideLabel: getReservationModeLabel(customLabels, 'curbside'),
});
