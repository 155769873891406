import { useMfa } from '@jane/shared/providers';
import { Modal } from '@jane/shared/reefer';
import { inIframe } from '@jane/shared/util';

import { MultifactorAuthentication } from './multifactorAuthentication';

// The MFA modal must exist within the MfaProvider.
export const MfaModal = () => {
  const { mfaCancel, open } = useMfa();
  const isInIframe = inIframe();

  return (
    <Modal
      variant="dialogue"
      open={open}
      onRequestClose={mfaCancel}
      topOverride={isInIframe ? (open ? '160px' : '350px') : undefined}
    >
      <Modal.Content>
        <MultifactorAuthentication />
      </Modal.Content>
    </Modal>
  );
};
