import styled from '@emotion/styled';
import type { HTMLAttributes } from 'react';

import { Typography } from '@jane/shared/reefer';

const StyledTypography = styled(Typography)({
  '@supports (-webkit-line-clamp: 2)': {
    display: '-webkit-box',
    WebkitLineClamp: 2,
    WebkitBoxOrient: 'vertical',
    overflow: 'hidden',
  },
});

export const Name: React.FC<HTMLAttributes<HTMLDivElement>> = ({
  children,
  color: _,
  ...props
}) => (
  <StyledTypography as="div" variant="body-bold" {...props}>
    {children}
  </StyledTypography>
);
