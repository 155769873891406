import debounce from 'lodash/debounce';
import type { MutableRefObject } from 'react';
import { useCallback, useEffect, useState } from 'react';

import type { GridData } from '@jane/shared/types';

interface UseMenuProductGridData {
  (
    gridRef: MutableRefObject<Element | null>,
    itemCount: number
  ): GridData | null;
}

/**
 * Allows tracking the number of rows and columns in a grid.
 * Useful for maintaining item position across resizes.
 * @param gridRef ref to an element with the display: grid property
 * @param itemCount number of items in grid
 */
export const useMenuProductGridData: UseMenuProductGridData = (
  gridRef,
  itemCount
) => {
  const current = gridRef.current;

  const [gridData, setGridData] = useState<{
    gridColumnCount: number;
    gridRowCount: number;
  } | null>(null);

  const getGridData = useCallback(() => {
    if (!current) return null;
    const gridComputedStyle = window.getComputedStyle(current);
    return {
      gridRowCount: gridComputedStyle
        .getPropertyValue('grid-template-rows')
        .split(' ').length,
      gridColumnCount: gridComputedStyle
        .getPropertyValue('grid-template-columns')
        .split(' ').length,
    };
  }, [current]);

  const onResize = useCallback(() => {
    setGridData(getGridData());
  }, [getGridData]);

  useEffect(() => {
    window.addEventListener('resize', debounce(onResize, 200));
    return () => window.removeEventListener('resize', onResize);
  }, [onResize]);

  useEffect(() => {
    const gridData = getGridData();
    if (!gridData) return;
    setGridData(gridData);
  }, [current, onResize, itemCount, getGridData]);

  return gridData;
};
