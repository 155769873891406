import type { Reducer } from 'redux';

import type { Brand, DeepReadonly, Id } from '@jane/shared/models';

import { createStandardAction } from '../../redux-util';
import { BrandSource } from '../../sources/brand';
import type { CustomerThunkActionCreator } from '../redux';
import type { CustomerAction } from './types';

export const GET_BRAND = 'brand/get';
export const getBrand: CustomerThunkActionCreator<{
  id: Id;
  latest?: boolean;
}> =
  ({ id, latest }) =>
  (dispatch) => {
    dispatch({ type: GET_BRAND });
    BrandSource.get(id, { latest }).then(
      (result) => dispatch(getBrandSuccess(result)),
      (err) => dispatch(getBrandError(err))
    );
  };

export const GET_BRAND_SUCCESS = 'brand/get-success';
export const getBrandSuccess = createStandardAction(GET_BRAND_SUCCESS)<{
  brand: Brand;
}>();

export const GET_BRAND_ERROR = 'brand/get-error';
export const getBrandError = createStandardAction(GET_BRAND_ERROR)<unknown>();

export type BrandActions =
  | { type: typeof GET_BRAND }
  | { type: typeof GET_BRAND_ERROR }
  | ReturnType<typeof getBrandSuccess>
  | ReturnType<typeof getBrandError>;

export type BrandState = DeepReadonly<{
  brand: Brand | null;
  isLoadingBrand: boolean;
}>;

const initialState: BrandState = {
  brand: null,
  isLoadingBrand: false,
};

export const brandReducer: Reducer<BrandState, CustomerAction> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case GET_BRAND:
      return { ...state, isLoadingBrand: true, brand: null };
    case GET_BRAND_SUCCESS: {
      const { brand } = action.payload;
      return {
        ...state,
        brand,
        isLoadingBrand: false,
      };
    }
    case GET_BRAND_ERROR:
      return { ...state, isLoadingBrand: false, brand: null };
  }

  return state;
};
