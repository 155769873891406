import type {
  AlgoliaProduct,
  JaneSearchState,
  KindFacet,
} from '@jane/search/types';
import { MagicRowKind, SpecialsKind } from '@jane/search/types';

export const isVisibleFacet =
  (searchState: JaneSearchState<AlgoliaProduct>) => (kindFacet: KindFacet) => {
    const rootTypes = searchState?.filters?.root_types || [];
    return (
      rootTypes.length === 0 ||
      rootTypes
        .map((type) => `${type}`.split(':')[0])
        .includes(kindFacet.kind) ||
      kindFacet.kind === MagicRowKind ||
      kindFacet.kind === SpecialsKind
    );
  };
