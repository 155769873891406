import type { ReservationCartProduct } from '@jane/shared/models';

const DEFAULT_QUANTITY = 1;

export function initialCountForProduct(cartProduct?: ReservationCartProduct[]) {
  return (
    (cartProduct && cartProduct.reduce((prev, curr) => prev + curr.count, 0)) ||
    DEFAULT_QUANTITY
  );
}
