import { openWidget } from 'aerosync-web-sdk';
import type { widget } from 'aerosync-web-sdk';
import { useEffect, useRef, useState } from 'react';

import {
  useAeropayBankConnection,
  useAeropayUser,
  useLinkAeropayBankAccount,
} from '@jane/shared-ecomm/data-access';
import { config } from '@jane/shared/config';
import { useJaneUserId } from '@jane/shared/data-access';
import { trackError } from '@jane/shared/util';

interface UseAerosyncProps {
  /** Function called when the user exits the widget */
  onClose?(): void;

  /** Function called when bank account fails to link */
  onError(err: unknown): void;

  /** Function called when the widget finishes loading */
  onLoad?(): void;

  /** Function called when bank account is successfully linked */
  onSuccess?(): void;

  widgetContainerId: string;
}

export type AerosyncWidget = widget;

interface SuccessPayload {
  ClientName: string;
  FILoginAcctId: string;
  user_id: string;
  user_password: string;
}

export const useAerosync = ({
  onClose,
  onError,
  onLoad,
  onSuccess,
  widgetContainerId,
}: UseAerosyncProps) => {
  const shouldClose = useRef(true);
  const [linkingBankAccount, setLinkingBankAccount] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const janeUserId = useJaneUserId();
  const { data: aeropayUserData } = useAeropayUser({
    janeUserId: janeUserId || null,
  });

  const { data: aeropayBankConnection } = useAeropayBankConnection(
    {
      aeropayUserId: aeropayUserData?.user_id,
    },
    { onError: (err) => onError(err) }
  );

  const { mutate: linkBankAccounts } = useLinkAeropayBankAccount({
    janeUserId: janeUserId as number,
  });
  const token = aeropayBankConnection?.token;

  const widgetRef = useRef<AerosyncWidget | null>(null);

  // The aerosync widget invokes its own onClose function when successfully linking a bank account.
  // We pass onClose the close modal function for when a user hits the X button.
  // However, we do not want to close the modal upon linking a bank account.
  const handleClose = () => {
    if (shouldClose.current) {
      onClose && onClose();
    }
  };

  const shadowRoot = document.querySelector('#shadow-host')?.shadowRoot;

  useEffect(() => {
    if (token) {
      const environment: 'staging' | 'production' =
        config.aeropayEnv === 'production' ? 'production' : 'staging';

      const sharedProps = {
        token: token,
        id: widgetContainerId,
        iframeTitle: 'Connect',
        style: {
          width: '100%',
          height: '100%',
        },
        environment,
        deeplink: '',
        consumerId: config.aeropayConsumerId,
        onEvent: function () {
          return null;
        },
        onLoad: function () {
          onLoad && onLoad();
        },
        onSuccess: async function (e: object) {
          shouldClose.current = false;
          const event = e as SuccessPayload;
          setLinkingBankAccount(true);
          try {
            linkBankAccounts(
              {
                aeropayUserId: event.user_id,
                aeropayUserPassword: event.user_password,
              },
              {
                onError: (err) => onError(err),
                onSuccess,
                onSettled: () => {
                  setLinkingBankAccount(false);
                },
              }
            );
          } catch (e) {
            setLinkingBankAccount(false);
            onError(e);
          }
        },
        onClose: handleClose,
        onError: (e: unknown) => {
          trackError(`Aerosync Error`, { error: e });
          onError && onError(e);
        },
      };
      const ref = openWidget({
        ...sharedProps,
        targetDocument: shadowRoot || undefined,
      });

      widgetRef.current = ref;
      setIsLoading(false);
    }
  }, [token, widgetContainerId]);

  return { isLoading, linkingBankAccount, widgetRef };
};
