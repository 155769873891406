import * as t from 'io-ts';

import type { DeepReadonly } from './deepReadonly';
import { tProductKind } from './product';

export const PRODUCT_TYPE = 'product';
export const EQUIVALENCY_TYPE = 'equivalency';

export const tCartLimitRuleType = t.union([
  t.literal(PRODUCT_TYPE),
  t.literal(EQUIVALENCY_TYPE),
]);

export type CartLimitRuleType = t.TypeOf<typeof tCartLimitRuleType>;
export const EQUIVALENCY_UNIT = 'g';

//TODO: Remove all references to legacy cartLimits
export const tCartLimit = t.interface({
  store_id: t.number,
  enabled: t.boolean,
  flower_limit_grams: t.union([t.number, t.null]),
  extract_limit_grams: t.union([t.number, t.null]),
  dosage_limit_milligrams: t.union([t.number, t.null]),
});
export type CartLimit = t.TypeOf<typeof tCartLimit>;

// nu stuff

const tId = t.union([t.string, t.number]);

const tProductSubType = t.string;

export const tNuProductTypeRequired = t.interface({
  id: tId,
  product_type: tProductKind,
  product_subtype: t.union([t.null, tProductSubType]),
});

const tNuProductTypeOptional = t.partial({ _destroy: t.boolean });
export const tNuProductType = t.intersection([
  tNuProductTypeRequired,
  tNuProductTypeOptional,
]);

export const tRuleProduct = t.intersection([
  tNuProductType,
  t.interface({ cart_limit_rule_product_type_id: tId }),
]);

const tCartLimitRuleRequired = t.interface({
  product_group_name: t.string,
  limit_value: t.number,
  limit_unit: t.string,
  product_types: t.array(tRuleProduct),
  rule_type: t.union([t.literal(PRODUCT_TYPE), t.literal(EQUIVALENCY_TYPE)]),
});
const tCartLimitRuleOptional = t.partial({ id: tId, _destroy: t.boolean });
const tCartLimitRule = t.intersection([
  tCartLimitRuleRequired,
  tCartLimitRuleOptional,
]);

export const tCartLimitPolicy = t.interface({
  id: tId,
  name: t.string,
  cart_limit_rules: t.array(tCartLimitRule),
});

export const tCartLimitPolicies = t.interface({
  cart_limit_policies: t.array(tCartLimitPolicy),
});

export type NuProductType = t.TypeOf<typeof tNuProductType>;
export type NuProductTypeRequired = t.TypeOf<typeof tNuProductTypeRequired>;
export type RuleProduct = t.TypeOf<typeof tRuleProduct>;
export type CartLimitRule = DeepReadonly<t.TypeOf<typeof tCartLimitRule>>;
export type CartLimitPolicy = t.TypeOf<typeof tCartLimitPolicy>;
export type CartLimitPolicies = t.TypeOf<typeof tCartLimitPolicies>;
