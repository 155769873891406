import { Suspense, lazy } from 'react';

import { LoadingWrapper } from '@jane/shared/components';

import { useCustomerSelector } from '../../customer/selectors';
import { get } from '../../redux-util/selectors';
import type { IdentificationProps } from './identification.types';
import { WarningMessage } from './warningMessage';

/** Code splitting to load convertIfPDF/pdfjs only when called */
const DeluxeIdentificationUploader = lazy(() =>
  import('./idUploader').then(({ DeluxeIdentificationUploader }) => ({
    default: DeluxeIdentificationUploader,
  }))
);

const StudentIdentification = ({
  showValidationErrors,
  valid,
  onIdUploadAttempt,
}: IdentificationProps) => {
  const { document, loading_student_photo } = useCustomerSelector(
    get('identification')
  );

  if (!document) return null;

  return (
    <Suspense fallback={<LoadingWrapper isLoading />}>
      <DeluxeIdentificationUploader
        imageKey="student_photo"
        inputName="government-id"
        label="Upload Student ID"
        document={document}
        isLoading={loading_student_photo}
        valid={valid}
        showValidationErrors={showValidationErrors}
        onIdUploadAttempt={onIdUploadAttempt}
      />
      {!document.student_photo && (
        <WarningMessage>ID required for discount</WarningMessage>
      )}
    </Suspense>
  );
};

export default StudentIdentification;
