/**
 * DO NOT MODIFY THIS FILE!
 * It was created by the reefer-icons generator.
 * Please update & re-run the generator if need be.
 */
import type { SVGProps } from 'react';

import type { DynamicIconProps } from '../../../icon/icon.types';
import { Icon } from '../../icon';

const ArrowRight24 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="100%"
    height="100%"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <g className="Arrow Right 24" clipPath="url(#a)">
      <path
        fill="#0E0E0E"
        fillRule="evenodd"
        d="M15.395 4.288a1 1 0 0 1 1.415.01l6.902 7a1 1 0 0 1 0 1.404l-6.902 7a1 1 0 0 1-1.424-1.404L20.61 13H1a1 1 0 1 1 0-2h19.61l-5.224-5.298a1 1 0 0 1 .01-1.414Z"
        className="SVG"
        clipRule="evenodd"
      />
    </g>
    <defs>
      <clipPath>
        <path fill="#fff" d="M0 0h24v24H0z" />
      </clipPath>
    </defs>
  </svg>
);

const SIZE_MAP = {
  md: ArrowRight24,
  sm: ArrowRight24,
  lg: ArrowRight24,
  xl: ArrowRight24,
  xxl: ArrowRight24,
  xxxl: ArrowRight24,
};

export const ArrowRightIcon = ({
  size = 'md',
  'data-testid': testId,
  ...props
}: DynamicIconProps) => {
  return (
    <Icon
      data-testid={testId || 'arrow-right-icon'}
      icon={SIZE_MAP[size]}
      {...props}
      size={size}
    />
  );
};
