import type { Cart, CartLineItems, PosCart } from '@jane/shared/models';

export type { CartLineItems };
interface GetCartLineItemsProps {
  cart: Cart;
  posCartTotals?: PosCart['totals'];
  roundingAmount?: number;
  tipAmount: number;
}

export const getCartLineItems = ({
  cart,
  posCartTotals,
  tipAmount,
  roundingAmount = 0,
}: GetCartLineItemsProps): CartLineItems => {
  const {
    all_discounts_total: discountTotal,
    cart_discount_amount: cartLevelDiscountTotal,
    delivery_fee_amount: deliveryFee,
    discounted_product_total: discountedProductTotal,
    discounted_subtotal: discountedSubtotal,
    item_total: preDiscountSubtotal,
    order_total,
    product_discounts_total: productDiscountTotal,
    sales_tax_amount,
    service_fee_amount: serviceFee,
    store_tax_amount,
  } = cart;

  if (posCartTotals) {
    return {
      preDiscountSubtotal,
      discountedProductTotal,
      discountedSubtotal,
      productDiscountTotal,
      cartLevelDiscountTotal,
      discountTotal,
      deliveryFee,
      roundingAmount,
      serviceFee,
      salesTax: posCartTotals.sales_tax,
      storeTax: posCartTotals.store_tax,
      tipAmount,
      total: posCartTotals?.total + tipAmount,
    };
  }

  return {
    preDiscountSubtotal,
    discountedProductTotal,
    discountedSubtotal,
    productDiscountTotal,
    cartLevelDiscountTotal,
    discountTotal,
    deliveryFee,
    serviceFee,
    salesTax: sales_tax_amount,
    storeTax: store_tax_amount,
    tipAmount,
    roundingAmount,
    total: order_total + tipAmount,
  };
};
