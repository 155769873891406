import styled from '@emotion/styled';

import type { MenuProduct, PriceId, Product } from '@jane/shared/models';
import { Typography } from '@jane/shared/reefer';

interface Props {
  product: MenuProduct | Product;
  selectedWeight?: PriceId;
  showLess: boolean;
}

export const nonCannabisKinds = ['gear', 'merch'] as const;

export const formatPotency = (potency?: number | string) => {
  if (typeof potency === 'string') {
    return potency;
  }
  return potency ? potency?.toFixed(2) : null;
};

export const primaryPotencies = (
  product: MenuProduct | Product,
  selectedWeight?: PriceId
) => {
  const { primaryPercentCBD, primaryPercentTHC } = product;
  const fallbackPercentTAC =
    'percentTAC' in product ? product.percentTAC : undefined;

  const inventoryPotencies =
    'inventory_potencies' in product && selectedWeight
      ? product.inventory_potencies
      : [];

  const inventoryPotency = (inventoryPotencies || []).find(
    (potency) => potency.price_id === selectedWeight
  );

  const percentTAC = inventoryPotency?.tac_potency || fallbackPercentTAC;
  const percentTHC = inventoryPotency?.thc_potency || primaryPercentTHC;
  const percentCBD = inventoryPotency?.cbd_potency || primaryPercentCBD;

  return { percentCBD, percentTAC, percentTHC };
};

export const getPackTotal = (product: MenuProduct | Product) => {
  const shouldAppendPackTotal =
    product.kind === 'pre-roll' &&
    product.root_subtype?.toLowerCase()?.includes('packs');
  if (!shouldAppendPackTotal) return;
  if (
    product &&
    product.quantity_value &&
    product.quantity_units &&
    product.quantity_value > 0
  ) {
    const standardizedUnits = product.quantity_units.replace('_', ' ');
    return `/ ${product.quantity_value}${standardizedUnits} per pack`;
  }
  if (product && product.net_weight_grams && product.net_weight_grams > 0)
    return `/ ${product.net_weight_grams}g per pack`;

  return '';
};

export const getLabResults = (
  product: MenuProduct | Product,
  selectedWeight?: PriceId
) => {
  const { percentTHCA, percentCBDA } = product;

  const { percentTAC, percentTHC, percentCBD } = primaryPotencies(
    product,
    selectedWeight
  );

  if (percentTHC || percentCBD || percentTHCA || percentCBDA || percentTAC) {
    return [
      percentTAC && `TAC ${formatPotency(percentTAC)}%`,
      percentTHC && `THC ${formatPotency(percentTHC)}%`,
      percentCBD && `CBD ${formatPotency(percentCBD)}%`,
      percentTHCA && `THCa ${formatPotency(percentTHCA)}%`,
      percentCBDA && `CBDa ${formatPotency(percentCBDA)}%`,
    ]
      .filter(Boolean)
      .join(' ');
  }

  return '';
};

export const getDosageAmountPackInfo = (product: MenuProduct | Product) => {
  const { amount, dosage } = product;

  if (dosage || amount) {
    const packTotal = getPackTotal(product);

    return [dosage, amount, packTotal].filter(Boolean).join(' ');
  }

  return '';
};

export const potencyInfo = (
  product: MenuProduct | Product,
  selectedWeight?: PriceId
) => {
  if (
    nonCannabisKinds.some((nonCannabisKind) => nonCannabisKind === product.kind)
  ) {
    return null;
  }
  const labResults = getLabResults(product, selectedWeight);

  if (labResults) {
    return labResults;
  }

  const dosageAmountInfo = getDosageAmountPackInfo(product);

  if (dosageAmountInfo) {
    return dosageAmountInfo;
  }

  return null;
};

const StyledPotencyWrapper = styled.div(
  ({ showLess }: { showLess: boolean }) => ({
    '> div': {
      display: '-webkit-box',
      WebkitLineClamp: showLess ? '1' : '2',
      WebkitBoxOrient: 'vertical',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
    },
  })
);

const ProductPotency = ({ product, selectedWeight, showLess }: Props) => (
  <StyledPotencyWrapper showLess={showLess}>
    <Typography color="text-light" as="div">
      {potencyInfo(product, selectedWeight)}
    </Typography>
  </StyledPotencyWrapper>
);

export default ProductPotency;
