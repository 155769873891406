import styled from '@emotion/styled';

import { Flex } from '@jane/shared/reefer';
import { mediaQueries, spacing } from '@jane/shared/reefer-emotion';

export const CarouselHeaderContainer = styled(Flex)({
  ...spacing({ mb: 16 }),
  [mediaQueries.tablet('min')]: {
    ...spacing({ mb: 24 }),
  },
});
