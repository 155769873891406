/**
 * DO NOT MODIFY THIS FILE!
 * It was created by the reefer-icons generator.
 * Please update & re-run the generator if need be.
 */
import type { SVGProps } from 'react';

import type { DynamicIconProps } from '../../../icon/icon.types';
import { Icon } from '../../icon';

const Sativa16 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="100%"
    height="100%"
    fill="none"
    viewBox="0 0 16 16"
    {...props}
  >
    <g className="Sativa 16">
      <g className="SVG">
        <path
          fill="#666"
          fillRule="evenodd"
          d="M12 1H4a3 3 0 0 0-3 3v8a3 3 0 0 0 3 3h8a3 3 0 0 0 3-3V4a3 3 0 0 0-3-3ZM4 0a4 4 0 0 0-4 4v8a4 4 0 0 0 4 4h8a4 4 0 0 0 4-4V4a4 4 0 0 0-4-4H4Z"
          clipRule="evenodd"
        />
        <path
          fill="#666"
          d="M8.02 12.814a4.41 4.41 0 0 1-1.664-.325 4.342 4.342 0 0 1-1.404-.923l.884-1.027c.303.286.65.52 1.04.702.39.173.78.26 1.17.26.485 0 .854-.1 1.105-.299a.962.962 0 0 0 .377-.793c0-.355-.126-.611-.377-.767a6.588 6.588 0 0 0-.936-.481l-1.17-.507a4.608 4.608 0 0 1-.819-.468 2.395 2.395 0 0 1-.65-.728c-.165-.295-.247-.65-.247-1.066 0-.45.121-.854.364-1.209a2.59 2.59 0 0 1 1.014-.858C7.14 4.108 7.634 4 8.189 4c.494 0 .97.1 1.43.299.46.19.854.45 1.183.78l-.767.962a3.444 3.444 0 0 0-.858-.533 2.422 2.422 0 0 0-.988-.195c-.399 0-.724.091-.975.273a.843.843 0 0 0-.364.728c0 .217.06.399.182.546.13.139.299.26.507.364.208.095.429.19.663.286l1.157.481a3.5 3.5 0 0 1 1.235.858c.32.347.481.828.481 1.443 0 .46-.121.88-.364 1.261-.243.381-.594.689-1.053.923-.45.225-.997.338-1.638.338Z"
        />
      </g>
    </g>
  </svg>
);

const Sativa24 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="100%"
    height="100%"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <g className="Sativa 24">
      <rect
        width={22}
        height={22}
        x={1}
        y={1}
        stroke="#0E0E0E"
        strokeWidth={2}
        className="Rectangle 644"
        rx={3}
      />
      <path
        fill="#0E0E0E"
        d="M12.523 17.18c-.66 0-1.3-.125-1.92-.375a5.011 5.011 0 0 1-1.62-1.065l1.02-1.185c.35.33.75.6 1.2.81.45.2.9.3 1.35.3.56 0 .985-.115 1.275-.345.29-.23.435-.535.435-.915 0-.41-.145-.705-.435-.885a7.6 7.6 0 0 0-1.08-.555l-1.35-.585c-.32-.14-.635-.32-.945-.54-.3-.22-.55-.5-.75-.84-.19-.34-.285-.75-.285-1.23 0-.52.14-.985.42-1.395.29-.42.68-.75 1.17-.99.5-.25 1.07-.375 1.71-.375.57 0 1.12.115 1.65.345.53.22.985.52 1.365.9l-.885 1.11c-.31-.26-.64-.465-.99-.615-.34-.15-.72-.225-1.14-.225-.46 0-.835.105-1.125.315-.28.2-.42.48-.42.84 0 .25.07.46.21.63.15.16.345.3.585.42.24.11.495.22.765.33l1.335.555c.58.25 1.055.58 1.425.99.37.4.555.955.555 1.665 0 .53-.14 1.015-.42 1.455-.28.44-.685.795-1.215 1.065-.52.26-1.15.39-1.89.39Z"
        className="S"
      />
    </g>
  </svg>
);

const SIZE_MAP = {
  sm: Sativa16,
  md: Sativa24,
  lg: Sativa24,
  xl: Sativa24,
  xxl: Sativa24,
  xxxl: Sativa24,
};

export const SativaIcon = ({
  size = 'md',
  'data-testid': testId,
  ...props
}: DynamicIconProps) => {
  return (
    <Icon
      data-testid={testId || 'sativa-icon'}
      icon={SIZE_MAP[size]}
      {...props}
      size={size}
    />
  );
};
