import type { FreeDeliveryConfig } from '@jane/shared/models';

export const isEligibleForFreeDeliveryConfig = ({
  freeDeliveryConfig,
  discountedSubtotal,
}: {
  discountedSubtotal: number;
  freeDeliveryConfig: FreeDeliveryConfig | undefined;
}): boolean => {
  const subtotalMeetsCartMinimum = freeDeliveryConfig?.cart_minimum
    ? discountedSubtotal >= freeDeliveryConfig?.cart_minimum
    : true;

  return subtotalMeetsCartMinimum;
};

export const applicableFreeDeliveryConfig = ({
  freeDeliveryConfigs,
  discountedSubtotal,
}: {
  discountedSubtotal: number;
  freeDeliveryConfigs: Readonly<FreeDeliveryConfig[]>;
}) =>
  freeDeliveryConfigs.find((freeDeliveryConfig) =>
    isEligibleForFreeDeliveryConfig({
      freeDeliveryConfig,
      discountedSubtotal,
    })
  );
