import { fold } from 'fp-ts/lib/Either';
import { pipe } from 'fp-ts/lib/pipeable';

import { config } from '@jane/shared/config';
import { tCommunicationBannerMessageSettings } from '@jane/shared/models';
import type { Id } from '@jane/shared/models';

import { decodeType } from '../lib/loadable';
import { request } from '../lib/request';

const urlRoot = (storeId: Id) =>
  `${config.apiPath}/stores/${storeId}/banner_message_settings`;

export const getCurrentStoreCommunicationBanner = (storeId: Id) =>
  request(urlRoot(storeId), { method: 'GET' })
    .then((r) =>
      decodeType({
        data: r,
        type: tCommunicationBannerMessageSettings,
        source: { name: 'StoreCommunicationBanner' },
      })
    )
    .then((r) =>
      pipe(
        r,
        fold(
          (error) => {
            throw error;
          },
          (response) => response
        )
      )
    );
