import isEmpty from 'lodash/isEmpty';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { useQuery } from '@jane/shared-ecomm/hooks';

import { paths } from '../../lib/routes';
import SearchForm from '../search/form';

interface Props {
  onSearch: () => void;
}

export const GlobalSearchForm = ({ onSearch }: Props) => {
  const location = useLocation();
  const queryParams = useQuery<{ query?: string }>();
  const navigate = useNavigate();
  const params = useParams();

  const getDefaultValue = () => {
    if (location.pathname.match(/^\/products/)) {
      return queryParams.query || '';
    }

    return '';
  };

  const fullSearchParamsWithoutQuery = () => {
    const { query, ...restOfQuery } = queryParams;
    return restOfQuery;
  };
  const onClear = () => {
    const performSearchOnClear =
      location.pathname === '/products' && isEmpty(params);
    if (performSearchOnClear) {
      navigate(paths.products(fullSearchParamsWithoutQuery()));
    }
  };

  const onSubmit = (value: string) => {
    onSearch && onSearch();

    const searchParams: any = fullSearchParamsWithoutQuery();
    searchParams.query = value;
    navigate(paths.products(searchParams));
  };

  return (
    <SearchForm
      id="global-search"
      label="global-search"
      onClear={onClear}
      onSubmit={onSubmit}
      placeholder="Search products"
      defaultValue={getDefaultValue()}
      mode="header"
    />
  );
};

export default GlobalSearchForm;
