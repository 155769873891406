import styled from '@emotion/styled';

import type { PoweredByJaneProps } from './poweredByJane';

export const StyledWrapper = styled.div<PoweredByJaneProps>(
  ({ alignment, position }) => ({
    ...(position === 'absolute' && {
      position: 'absolute',
      ...(alignment === 'left' && {
        bottom: '10px',
        left: '10px',
      }),
      ...(alignment === 'center' && {
        margin: '0 auto',
        bottom: '48px',
      }),
    }),
    ...(position === 'static' && {
      padding: '0 8px 8px',
    }),
  })
);
