import { fold } from 'fp-ts/lib/Either';
import { pipe } from 'fp-ts/lib/pipeable';

import { config } from '@jane/shared/config';
import type { Id } from '@jane/shared/models';
import { tStoreSeoResponse } from '@jane/shared/models';

import { decodeType } from '../lib/loadable';
import { request } from '../lib/request';

export const StoreSeoSource = {
  get: (storeId: Id) =>
    request(`${config.apiPath}/stores/${storeId}/seo`, {
      method: 'GET',
    })
      .then((r) =>
        decodeType({
          data: r,
          type: tStoreSeoResponse,
          source: { name: 'StoreSeo' },
        })
      )
      .then((r) =>
        pipe(
          r,
          fold(
            (error) => {
              throw error;
            },
            (response) => response
          )
        )
      ),
};
