import { createContext, useContext, useMemo } from 'react';

import type { AccordionProps } from './accordion.types';
import { AccordionContent } from './accordionContent';
import { AccordionHeading } from './accordionHeading';
import { AccordionIndicator } from './accordionIndicator';
import { AccordionItem } from './accordionItem';
import { AccordionSummary } from './accordionSummary';

const AccordionAutoScrollContext = createContext<{ autoScrollMargin?: string }>(
  {
    autoScrollMargin: undefined,
  }
);

const BaseAccordion = ({ autoScrollMargin, children, id }: AccordionProps) => {
  const value = useMemo(() => ({ autoScrollMargin }), [autoScrollMargin]);

  return (
    <AccordionAutoScrollContext.Provider value={value}>
      <div id={id} data-testid={id}>
        {children}
      </div>
    </AccordionAutoScrollContext.Provider>
  );
};

export const useAutoScroll = () => {
  const context = useContext(AccordionAutoScrollContext);
  if (context === undefined) {
    throw new Error('useAutoScroll must be used within an Accordion');
  }

  return context;
};

/**
 *  Note: when using long duration animation in iframe, it can lead to laggy UI and low framerate
 *  due to the iframe getting resize as the content changes. This can be reduced by decreasing
 *  transition duration
 */
export const Accordion = Object.assign(BaseAccordion, {
  Content: AccordionContent,
  Heading: AccordionHeading,
  Indicator: AccordionIndicator,
  Item: AccordionItem,
  Summary: AccordionSummary,
});
