import type {
  AppMode,
  MenuProduct,
  PriceId,
  StoreSpecial,
  WeightCondition,
} from '@jane/shared/models';

const doesSpecialApply = (
  appMode: AppMode,
  special: StoreSpecial,
  menuProduct: MenuProduct,
  weight?: PriceId
) => {
  const notKiosk = appMode !== 'kiosk' || special.reservation_modes?.kiosk;
  const isProductSpecial = special.special_type === 'product';

  // safeguard against group special
  if (!isProductSpecial) return false;

  if (!weight) {
    // whitelist special based on the BE endpoint as the source of truth
    return menuProduct.special_id === special.id && notKiosk;
  }

  const specialWeights = special?.conditions?.product?.weights || [];
  return (
    menuProduct.applicable_special_ids?.includes(special.id) &&
    (specialWeights.length === 0 ||
      specialWeights.includes(weight as WeightCondition))
  );
};

export const findSpecialForProduct = (
  menuProduct: MenuProduct,
  specials: StoreSpecial[] = [],
  appMode: AppMode
) =>
  specials
    .filter(Boolean)
    .find((special: StoreSpecial) =>
      doesSpecialApply(appMode, special, menuProduct)
    );
