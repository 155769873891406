/**
 * DO NOT MODIFY THIS FILE!
 * It was created by the reefer-icons generator.
 * Please update & re-run the generator if need be.
 */
import type { SVGProps } from 'react';

import type { DynamicIconProps } from '../../../icon/icon.types';
import { Icon } from '../../icon';

const ArrowLeft24 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="100%"
    height="100%"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <g className="Arrow Left 24" clipPath="url(#a)">
      <path
        fill="#0E0E0E"
        fillRule="evenodd"
        d="M8.605 4.288a1 1 0 0 1 .01 1.414L3.39 11H23a1 1 0 1 1 0 2H3.39l5.224 5.298a1 1 0 1 1-1.424 1.404l-6.902-7a1 1 0 0 1 0-1.404l6.902-7a1 1 0 0 1 1.415-.01Z"
        className="SVG"
        clipRule="evenodd"
      />
    </g>
    <defs>
      <clipPath>
        <path fill="#fff" d="M0 0h24v24H0z" />
      </clipPath>
    </defs>
  </svg>
);

const SIZE_MAP = {
  md: ArrowLeft24,
  sm: ArrowLeft24,
  lg: ArrowLeft24,
  xl: ArrowLeft24,
  xxl: ArrowLeft24,
  xxxl: ArrowLeft24,
};

export const ArrowLeftIcon = ({
  size = 'md',
  'data-testid': testId,
  ...props
}: DynamicIconProps) => {
  return (
    <Icon
      data-testid={testId || 'arrow-left-icon'}
      icon={SIZE_MAP[size]}
      {...props}
      size={size}
    />
  );
};
