import { CheckboxFieldInternal } from '../fields/checkboxField/checkboxField';
import type { CheckboxFieldProps } from '../fields/field.types';
import type { FormFieldProps } from '../formField.types';
import { FormFieldWrapper } from '../formFieldWrapper/formFieldWrapper';

export interface FormCheckboxFieldProps
  extends Omit<CheckboxFieldProps, 'children'>,
    Pick<FormFieldProps, 'required' | 'shouldUnregister' | 'validate'> {}

/**
 * Checkbox fields allow users to choose an option, or not.
 *
 * Use this component *within forms* for inputs of `type`:
 * `checkbox`
 *
 * **NOTE: DO NOT USE THIS COMPONENT OUTSIDE FORMS**
 *
 * *For a similar component for use outside forms, see [`CheckboxField`](/story/components-forms-fields-checkboxfield--default).*
 */
export function FormCheckboxField({
  autoFocus = false,
  checked,
  defaultChecked = false,
  disabled = false,
  helperText,
  indeterminate,
  label,
  labelHidden = false,
  name,
  onBlur,
  onChange,
  onChangeIndeterminate,
  onClick,
  required = false,
  'data-testid': testId,
  shouldUnregister,
  validate,
  width = 'auto',
  ...props
}: FormCheckboxFieldProps) {
  return (
    <FormFieldWrapper
      defaultValue={defaultChecked}
      name={name}
      render={({ children, field }) => (
        <CheckboxFieldInternal
          autoFocus={autoFocus}
          checked={checked}
          defaultChecked={defaultChecked}
          disabled={disabled}
          helperText={helperText}
          indeterminate={indeterminate}
          label={label}
          labelHidden={labelHidden}
          name={field.name}
          onBlur={(value) => {
            onBlur && onBlur(value);
            field.onBlur();
          }}
          onChange={(value) => {
            onChange && onChange(value);
            field.onChange(value);
          }}
          onChangeIndeterminate={(value) => {
            onChangeIndeterminate && onChangeIndeterminate(value);
            field.onChange(value ? 'indeterminate' : false);
          }}
          onClick={onClick}
          onUpdate={onChange}
          ref={field.ref}
          data-testid={testId}
          width={width}
          externalChecked={field.value}
          {...props}
        >
          {children}
        </CheckboxFieldInternal>
      )}
      required={required}
      shouldUnregister={shouldUnregister}
      validate={validate}
    />
  );
}
