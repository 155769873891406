import { z } from 'zod';

export const AlgoliaBrandPartnerStoreSchema = z.object({
  brand_partner_id: z.number().optional(),
  current_deals: z.array(z.string()).optional(),
  open_now: z.string().optional(),
  type: z.array(z.string()).optional(),
});

export type AlgoliaBrandPartnerStore = z.infer<
  typeof AlgoliaBrandPartnerStoreSchema
>;

export const AlgoliaBrandPartnerStoreFacetsSchema = z.object({
  current_deals: z.record(z.string(), z.number()).optional(),
  open_now: z.record(z.string(), z.number()).optional(),
  type: z.record(z.string(), z.number()).optional(),
});

export type AlgoliaBrandPartnerStoreFacets = z.infer<
  typeof AlgoliaBrandPartnerStoreFacetsSchema
>;
