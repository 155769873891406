import styled from '@emotion/styled';

import { Card, Typography } from '@jane/shared/reefer';
import { spacing } from '@jane/shared/reefer-emotion';

export const cardFocusBoxShadow = '0 2px 11px 0 rgba(108, 108, 108, 0.5)';

export const EllipsesTypography = styled(Typography)<{ lines: number }>(
  ({ lines }) => ({
    overflow: 'hidden',
    display: '-webkit-box',
    WebkitLineClamp: lines,
    WebkitBoxOrient: 'vertical',
  })
);

export const StyledCard = styled(Card)({
  '&:hover': {
    boxShadow: cardFocusBoxShadow,
  },
  '.card-content': {
    ...spacing({
      pt: 8,
      pb: 48,
    }),
  },
});
