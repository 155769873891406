import isEqual from 'lodash/isEqual';
import { memo, useEffect, useState } from 'react';

import type { StoreCommunicationBannerState } from '@jane/shared/models';
import {
  AddIcon,
  Box,
  Button,
  SubtractIcon,
  Typography,
} from '@jane/shared/reefer';
import { Storage } from '@jane/shared/util';

import { Accordion } from '../accordion';
import {
  StyledContainer,
  StyledContent,
  StyledContentContainer,
  StyledHeadingContainer,
} from './styles';
import { getExpandDefaultState } from './utils';

interface Props {
  communicationBanner: StoreCommunicationBannerState;
  onAccordionTrack?: (id: any) => void;
  onTrack?: (trackingData: any) => void;
}

const StoreCommunicationBannerBase = ({
  communicationBanner,
  onTrack,
  onAccordionTrack,
}: Props) => {
  const [isExpanded, setIsExpanded] = useState<boolean>(
    getExpandDefaultState(communicationBanner)
  );

  useEffect(() => {
    if (onTrack) {
      onTrack({
        storeId: communicationBanner.store_id,
        bannerEnabled: communicationBanner.enabled,
        bannerMessage: communicationBanner.message,
        isExpanded: communicationBanner.is_expanded,
      });
    }
  }, []);

  if (!communicationBanner?.enabled) return null;

  const onHeadingChange = () => {
    setIsExpanded(!isExpanded);
    const communicationBannerState = Storage.get('communication-banner');
    if (
      !communicationBannerState ||
      communicationBannerState?.updated_at !== communicationBanner.updated_at
    ) {
      Storage.set('communication-banner', {
        updated_at: communicationBanner.updated_at,
      });
    }
  };

  return (
    <StyledContainer
      id="communicationBannerContainer"
      role="group"
      aria-label="communication banner"
    >
      <Accordion>
        <Accordion.Item
          id="communicationBanner"
          onTrack={() =>
            onAccordionTrack && onAccordionTrack('communicationBanner')
          }
          expanded={isExpanded}
        >
          <Accordion.Heading onClick={onHeadingChange}>
            <StyledHeadingContainer
              id="communicationBannerHeader"
              role="group"
              aria-label="communication banner header"
            >
              <Typography branded as="span" variant="body-bold">
                {communicationBanner.title}
              </Typography>
              {isExpanded ? (
                <SubtractIcon color="grays-mid" />
              ) : (
                <AddIcon color="grays-mid" />
              )}
            </StyledHeadingContainer>
          </Accordion.Heading>
          <Accordion.Content destroyOnClose>
            <StyledContentContainer
              id="communicationBannerDescriptionContainer"
              role="group"
              aria-label="communication banner description container"
            >
              <StyledContent
                id="communicationBannerDescription"
                aria-label="communication banner description"
                dangerouslySetInnerHTML={{
                  __html: communicationBanner?.message || '',
                }}
              />
              <Box pt={24}>
                <Button
                  variant="primary"
                  onClick={onHeadingChange}
                  label="Got it"
                  full
                />
              </Box>
            </StyledContentContainer>
          </Accordion.Content>
        </Accordion.Item>
      </Accordion>
    </StyledContainer>
  );
};

const StoreCommunicationBanner = memo(StoreCommunicationBannerBase, isEqual);

export default StoreCommunicationBanner;
