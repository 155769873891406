import { Accordion } from '@jane/shared/components';
import type { Cart } from '@jane/shared/models';
import {
  Box,
  Flex,
  StarCircledIcon,
  Typography,
  useMobileMediaQuery,
} from '@jane/shared/reefer';

import { BoxWrapper } from '../cartDrawerSpecials';
import { LoginProtected } from '../loginProtected';

interface CustomerLoyaltyPointsGuestProps {
  isHeadless?: boolean;
  reservationMode: Cart['reservation_mode'];
  storeRewardLabel: string | null;
}

export const CustomerLoyaltyPointsGuest = ({
  isHeadless,
  reservationMode,
  storeRewardLabel,
}: CustomerLoyaltyPointsGuestProps) => {
  const isMobile = useMobileMediaQuery({});
  const rewardsTitle = `Use store ${
    storeRewardLabel ? storeRewardLabel : 'rewards'
  }`;

  if (isHeadless) {
    return (
      <BoxWrapper>
        <Typography
          variant="header-bold"
          mb={24}
          css={{ wordBreak: 'break-word' }}
        >
          {rewardsTitle}
        </Typography>
        <LoginProtected
          reservationMode={reservationMode}
          rewardsLabel={storeRewardLabel}
          isRewards
        />
      </BoxWrapper>
    );
  }

  return (
    <Accordion.Item id="store-rewards">
      <Accordion.Heading px={isMobile ? 24 : 40} py={20}>
        <Flex alignItems="center" justifyContent="space-between">
          <Flex alignItems="center">
            <div>
              <StarCircledIcon />
            </div>
            <Typography variant="body-bold" ml={16}>
              {rewardsTitle}
            </Typography>
          </Flex>
          <Flex pl={16}>
            <Accordion.Indicator iconSize="md" />
          </Flex>
        </Flex>
      </Accordion.Heading>
      <Accordion.Content px={isMobile ? 24 : 40} destroyOnClose>
        <Box pl={40}>
          <LoginProtected
            reservationMode={reservationMode}
            rewardsLabel={storeRewardLabel}
            isRewards
          />
        </Box>
      </Accordion.Content>
    </Accordion.Item>
  );
};
