import { useCallback } from 'react';

import type { PriceId } from '@jane/shared/models';
import {
  CheckIcon,
  Flex,
  Link,
  Typography,
  useMobileMediaQuery,
} from '@jane/shared/reefer';
import { formatCurrency } from '@jane/shared/util';

import {
  DisplayMode,
  useProductCardContext,
} from '../../productCardProvider/productCardProvider';

interface ConfirmationProps {
  discountedPrice: number;
  onWeightSelected: (selectedWeight: PriceId) => void;
  originalPrice: number;
}
const Confirmation = ({
  originalPrice,
  discountedPrice,
  onWeightSelected,
}: ConfirmationProps) => {
  const { cartProduct, onDeleteFromCart, setDisplayMode, trackListViewClick } =
    useProductCardContext();
  const isMobile = useMobileMediaQuery({});

  const onRemove = useCallback(() => {
    if (cartProduct) {
      onDeleteFromCart &&
        onDeleteFromCart({
          itemId: cartProduct[0].id,
          selectedWeight: cartProduct[0].price_id,
        });
    }
    trackListViewClick && trackListViewClick();
  }, [cartProduct, trackListViewClick]);

  const onlyOneWeightInCart = cartProduct?.length === 1;

  const handleEditExisting = () => {
    if (cartProduct) {
      onWeightSelected(cartProduct[0].price_id);
      setDisplayMode(DisplayMode.Edit);
    }
  };

  return (
    <Flex
      flexDirection={isMobile ? 'row' : 'column'}
      justifyContent={isMobile ? 'space-between' : 'center'}
      width={isMobile ? '100%' : undefined}
      alignItems="center"
    >
      <Flex
        flexDirection="column"
        justifyContent="center"
        alignItems={isMobile ? 'flex-start' : 'center'}
        pt={isMobile ? 24 : 0}
      >
        <CheckIcon color="text-main" />
        {originalPrice && (
          <Typography variant="header-bold" branded>
            {discountedPrice
              ? formatCurrency(discountedPrice)
              : formatCurrency(originalPrice)}
          </Typography>
        )}
        <Typography variant="header-bold" branded>
          Added to Bag
        </Typography>
      </Flex>
      <Flex
        width={isMobile ? undefined : '100%'}
        alignItems={isMobile ? 'flex-end' : undefined}
        justifyContent={isMobile ? undefined : 'space-around'}
        flexDirection={isMobile ? 'column' : 'row'}
      >
        <Link onClick={handleEditExisting}>Edit</Link>
        {onlyOneWeightInCart && <Link onClick={onRemove}>Remove</Link>}
      </Flex>
    </Flex>
  );
};

export default Confirmation;
