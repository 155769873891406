import { useEffect, useRef } from 'react';
import { useNavigationType } from 'react-router-dom';

import { useScrollRestoration } from '@jane/shared/providers';

import { useDoneResizing } from '../useDoneResizing';

export const useRestoreScrollPosition = () => {
  const doneResizing = useDoneResizing();
  const navigationType = useNavigationType();

  const hasRestoredScroll = useRef<boolean>(false);

  const { restoreScrollPosition, isIframe } = useScrollRestoration();

  const restoreScroll =
    (isIframe || doneResizing) && !hasRestoredScroll.current;

  useEffect(() => {
    if (restoreScroll) {
      hasRestoredScroll.current = true;
      restoreScrollPosition(window.location.href, navigationType);
    }
  }, [restoreScroll]);
};
