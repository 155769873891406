import styled from '@emotion/styled';

import { Box, Flex } from '@jane/shared/reefer';
import { mediaQueries } from '@jane/shared/reefer-emotion';

export const StyledPdpContainer = styled(Flex)({
  flexDirection: 'column',
  width: '100%',

  [mediaQueries.tablet('min')]: {
    textAlign: 'left',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
});

export const StyledPdpInnerContainer = styled(Flex)({
  flexDirection: 'column',
  width: '100%',

  [mediaQueries.tablet('min')]: {
    flexDirection: 'row',
    marginRight: 16,
  },
});

export const StyledPdpTitleDescriptionWrapper = styled(Flex)<{
  userHasLinkedBank: boolean | undefined;
}>(({ userHasLinkedBank }) => ({
  marginBottom: userHasLinkedBank ? 0 : 16,
  width: '100%',

  [mediaQueries.tablet('min')]: {
    alignItems: 'flex-start',
    marginBottom: 0,
    marginTop: 0,
  },
}));

export const StyledPdpDescriptionWrapper = styled(Box)({
  [mediaQueries.tablet('min')]: {
    maxWidth: '100%',
  },
});
