import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { recordPageView } from '@jane/shared/util';

export const useRecordPageView = () => {
  const location = useLocation();

  useEffect(() => {
    recordPageView([location.pathname, location.search].join(''));
  }, [location]);
};
