import * as t from 'io-ts';

import { config } from '@jane/shared/config';
import type { Id } from '@jane/shared/models';
import { tStore } from '@jane/shared/models';

import { request } from '../lib/request';

const storeUrl = (storeId: Id) => `${config.apiPath}/stores/${storeId}`;

export class StoreSource {
  static get(id: Id, params: any = {}) {
    return request(
      storeUrl(id),
      {
        method: 'GET',
      },
      params
    );
  }
}

export const getStoreSource = (id: Id) => ({
  url: storeUrl(id),
  requireAuth: true,
  type: t.interface({
    store: tStore,
  }),
});
