import { useEffect } from 'react';

import { EventNames, track } from '@jane/shared-ecomm/tracking';

import { useCustomerDispatch } from '../../customer/dispatch';
import { getJaneDeviceId } from '../../lib/getJaneDeviceId';
import { setJaneDeviceId } from '../redux/application';

export const useSetJaneDeviceId = () => {
  const dispatch = useCustomerDispatch();

  useEffect(() => {
    const getJaneId = async () => {
      const janeDeviceId = (await getJaneDeviceId()) || '';
      const anyWindow = window;
      dispatch(setJaneDeviceId({ janeDeviceId }));
      anyWindow['janeDeviceId'] = janeDeviceId;
    };

    getJaneId().catch(() => track({ event: EventNames.TimedGetJaneDeviceId }));
  }, []);
};
