import type { AlgoliaProduct, JaneSearchState } from '@jane/search/types';
import { ObjectKeys } from '@jane/shared/util';

import {
  buildBucketFilter,
  buildFilter,
  buildRangeFilter,
  composeFilters,
} from './buildFilter';

interface BuildSearchFilterProps {
  initialStaticFilters?: string;
  searchState: JaneSearchState<AlgoliaProduct>;
  staticFilters?: string;
}

export const buildSearchFilter = ({
  initialStaticFilters,
  searchState,
  staticFilters,
}: BuildSearchFilterProps) => {
  const { filters, bucketFilters, rangeFilters } = searchState;

  const composedFilters = composeFilters(
    ...(staticFilters ? [staticFilters] : []),
    ...(initialStaticFilters ? [initialStaticFilters] : []),
    ...(filters
      ? ObjectKeys(filters).map((attribute) =>
          buildFilter(attribute, filters[attribute])
        )
      : []),
    ...(rangeFilters
      ? ObjectKeys(rangeFilters).map((attribute) => {
          const value = rangeFilters[attribute];

          return buildRangeFilter(attribute, value?.min, value?.max);
        })
      : []),
    ...(bucketFilters
      ? ObjectKeys(bucketFilters).map((attribute) => {
          const value = bucketFilters[attribute];

          return buildBucketFilter(attribute, value);
        })
      : [])
  );

  return composedFilters;
};
