import flatMap from 'lodash/flatMap';
import max from 'lodash/max';
import min from 'lodash/min';

import { FilterSortId } from '@jane/search/types';
import type { AlgoliaProduct, JaneSearchState } from '@jane/search/types';

const formatFilters = (
  filters:
    | JaneSearchState<AlgoliaProduct>['filters']
    | JaneSearchState<AlgoliaProduct>['bucketFilters']
) =>
  filters
    ? flatMap(
        Object.entries(filters).map(([attribute, filters]) =>
          filters.map((filter) => [attribute, filter].join(':'))
        )
      )
    : [];

const formatRangeFilters = (
  rangeFilters: JaneSearchState<AlgoliaProduct>['rangeFilters']
) =>
  rangeFilters
    ? flatMap(
        Object.entries(rangeFilters).map(([attribute, range]) => {
          const { min, max } = range;
          const minRange = min && ['min', min].join('_');
          const maxRange = max && ['max', max].join('_');
          return minRange || maxRange
            ? [attribute, minRange, maxRange].join(':')
            : [];
        })
      )
    : [];

export const getFilters = (searchState: JaneSearchState<AlgoliaProduct>) => {
  const { filters, rangeFilters, bucketFilters } = searchState || {};

  const appliedFilters = formatFilters(filters);
  const appliedBucketFilters = formatFilters(bucketFilters);
  const appliedRangeFilters = formatRangeFilters(rangeFilters);

  return appliedFilters
    .concat(appliedRangeFilters)
    .concat(appliedBucketFilters);
};

export const getSort = (
  index: JaneSearchState<AlgoliaProduct>['currentSort']
) => (!index?.id ? FilterSortId.AZ : index.id);

export const getRating = (
  bucketFilters: JaneSearchState<AlgoliaProduct>['bucketFilters']
) =>
  bucketFilters
    ? Object.entries(bucketFilters).map(([attribute, filters]) => {
        if (attribute === 'aggregate_rating') {
          const ratings = filters.map((rating) =>
            parseInt(rating.split(':')[0])
          );

          return min(ratings);
        }
        return undefined;
      })[0]
    : undefined;

export const getPriceRange = (
  bucketFilters: JaneSearchState<AlgoliaProduct>['bucketFilters']
) =>
  bucketFilters
    ? Object.entries(bucketFilters)
        .map(([attribute, filters]) => {
          if (attribute === 'bucket_price') {
            const prices = filters
              .map((priceRange) => {
                const range = priceRange.split(':');
                // for :19.99, 80: price buckets
                if (range[0] === '') {
                  range[0] = '0';
                }

                if (range[1] === '') {
                  range[1] = 'Infinity';
                }
                return range;
              })
              .flat()
              .map(parseFloat);

            return prices?.length > 0
              ? [min(prices), max(prices)].join('-')
              : undefined;
          }
          return undefined;
        })
        .filter((filters) => filters !== undefined)[0]
    : undefined;
