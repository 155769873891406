// These are the timezones used in the rails backend.
// The converted strings work with both dayjs and moment.
const railsTimeZones: Record<string, string> = Object.freeze({
  Alaska: 'America/Juneau',
  Arizona: 'America/Phoenix',
  'Central Time (US & Canada)': 'America/Chicago',
  'Eastern Time (US & Canada)': 'America/New_York',
  Hawaii: 'Pacific/Honolulu',
  'Indiana (East)': 'America/Indiana/Indianapolis',
  'Mountain Time (US & Canada)': 'America/Denver',
  'Pacific Time (US & Canada)': 'America/Los_Angeles',
  'Puerto Rico': 'America/Puerto_Rico',
});

/**
 * Translates an ActiveSupport TimeWiteZone into an IANA time zone id
 */
export const parseApiTimezones = (timezone: string) => {
  if (Object.keys(railsTimeZones).includes(timezone)) {
    return railsTimeZones[timezone];
  }

  return timezone;
};
