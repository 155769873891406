import cloneDeep from 'lodash/cloneDeep';

import type { AlgoliaProduct, JaneSearchState } from '@jane/search/types';
import { PRODUCT_ROOT_TYPES } from '@jane/search/util';
import type { BrandPartner } from '@jane/shared/models';

export const addStaticFiltersToSearchState = ({
  brandPartner,
  searchState,
  staticFilters,
}: {
  brandPartner?: BrandPartner;
  searchState: JaneSearchState<AlgoliaProduct>;
  staticFilters?: string[];
}): JaneSearchState<AlgoliaProduct> => {
  const updated: JaneSearchState<AlgoliaProduct> = cloneDeep(searchState);
  const { brands, brand_subtypes } = buildBrandFilters(brandPartner);

  if (!updated.filters) {
    updated.filters = {};
  }

  if (staticFilters && staticFilters.length > 0) {
    const newFilters = (updated.filters?.root_types as string[]) || [];
    staticFilters
      .filter((filter) => PRODUCT_ROOT_TYPES.includes(filter))
      .forEach((filter) => newFilters.push(filter));
    updated.filters.root_types = newFilters;
  }

  if (brands && brands.length > 0) {
    const newBrands = (updated.filters.brand as string[]) || [];
    brands.forEach((brand) => newBrands.push(brand));
    updated.filters.brand = newBrands;
  }

  if (brand_subtypes && brand_subtypes.length > 0) {
    const newBrandSubtypes = (updated.filters.brand_subtype as string[]) || [];
    brand_subtypes.forEach((subtype) => newBrandSubtypes.push(subtype));
    updated.filters.brand_subtype = newBrandSubtypes;
  }

  return updated;
};

const buildBrandFilters = (
  brandPartner: BrandPartner | undefined
): { brand_subtypes?: string[]; brands?: string[] } => {
  if (!brandPartner) {
    return {};
  }

  if (brandPartner?.brand_subtypes?.length > 0) {
    return { brand_subtypes: brandPartner.brand_subtypes };
  }

  if (brandPartner.product_brands?.length > 0) {
    return {
      brands: brandPartner.product_brands.map(
        (productBrand) => productBrand.name
      ),
    };
  }
  return {};
};
