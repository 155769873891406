import clsx from 'clsx';
import type { ElementType, PropsWithChildren } from 'react';

import type { ReeferBaseProps } from '../../utils/baseProps.types';
import styles from './hide.module.css';

interface InnerHideProps extends ReeferBaseProps {
  /** Render the `Hide` component as this HTML element. Defaults to `div`. */
  as?: keyof JSX.IntrinsicElements;

  /** Hides the children of this component */
  isHidden?: boolean;
}

export type HideProps = PropsWithChildren<InnerHideProps>;

/**
 * Hide is a simple helper component that can be used to programmatically hide content.
 * This can be useful for accessibility purposes, as well as many other things.
 */
export function Hide({
  as = 'div',
  children,
  className,
  'data-testid': testId,
  id,
  isHidden = false,
  style,
}: HideProps) {
  const RenderAs: ElementType = as;
  return (
    <RenderAs
      className={clsx(className, {
        [styles.isHidden]: isHidden,
      })}
      data-testid={testId}
      id={id}
      style={style}
    >
      {children}
    </RenderAs>
  );
}
