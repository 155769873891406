import type { SVGProps } from 'react';

import { Icon } from '../../icon/icon';
import type { StaticIconProps } from '../../icon/icon.types';

const Pineapple = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="64"
    height="64"
    viewBox="0 0 64 64"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <circle cx={32} cy={32} r={32} fill="#FFC220" />
    <circle cx={32} cy={32} r={32} fill="white" fillOpacity={0.8} />
    <path
      d="M49 40C49 48.8366 49 56 32 56C15 56 15 48.8366 15 40C15 31.1634 22.6112 16 32 16C41.3888 16 49 31.1634 49 40Z"
      fill="#FFC220"
    />
    <path d="M20 31L18 29H22L20 31Z" fill="#FFC220" />
    <path d="M20 31L18 29H22L20 31Z" fill="black" fillOpacity={0.2} />
    <path d="M20 47L18 45H22L20 47Z" fill="#FFC220" />
    <path d="M20 47L18 45H22L20 47Z" fill="black" fillOpacity={0.2} />
    <path d="M20 39L18 37H22L20 39Z" fill="#FFC220" />
    <path d="M20 39L18 37H22L20 39Z" fill="black" fillOpacity={0.2} />
    <path d="M24 27L22 25L26 25L24 27Z" fill="#FFC220" />
    <path d="M24 27L22 25L26 25L24 27Z" fill="black" fillOpacity={0.2} />
    <path d="M24 43L22 41H26L24 43Z" fill="#FFC220" />
    <path d="M24 43L22 41H26L24 43Z" fill="black" fillOpacity={0.2} />
    <path d="M24 35L22 33H26L24 35Z" fill="#FFC220" />
    <path d="M24 35L22 33H26L24 35Z" fill="black" fillOpacity={0.2} />
    <path d="M24 51L22 49H26L24 51Z" fill="#FFC220" />
    <path d="M24 51L22 49H26L24 51Z" fill="black" fillOpacity={0.2} />
    <path d="M28 31L26 29H30L28 31Z" fill="#FFC220" />
    <path d="M28 31L26 29H30L28 31Z" fill="black" fillOpacity={0.2} />
    <path d="M28 47L26 45H30L28 47Z" fill="#FFC220" />
    <path d="M28 47L26 45H30L28 47Z" fill="black" fillOpacity={0.2} />
    <path d="M28 39L26 37H30L28 39Z" fill="#FFC220" />
    <path d="M28 39L26 37H30L28 39Z" fill="black" fillOpacity={0.2} />
    <path d="M28 55L26 53H30L28 55Z" fill="#FFC220" />
    <path d="M28 55L26 53H30L28 55Z" fill="black" fillOpacity={0.2} />
    <path d="M36 31L34 29H38L36 31Z" fill="#FFC220" />
    <path d="M36 31L34 29H38L36 31Z" fill="black" fillOpacity={0.2} />
    <path d="M36 47L34 45H38L36 47Z" fill="#FFC220" />
    <path d="M36 47L34 45H38L36 47Z" fill="black" fillOpacity={0.2} />
    <path d="M44 31L42 29H46L44 31Z" fill="#FFC220" />
    <path d="M44 31L42 29H46L44 31Z" fill="black" fillOpacity={0.2} />
    <path d="M44 47L42 45H46L44 47Z" fill="#FFC220" />
    <path d="M44 47L42 45H46L44 47Z" fill="black" fillOpacity={0.2} />
    <path d="M36 39L34 37H38L36 39Z" fill="#FFC220" />
    <path d="M36 39L34 37H38L36 39Z" fill="black" fillOpacity={0.2} />
    <path d="M36 55L34 53H38L36 55Z" fill="#FFC220" />
    <path d="M36 55L34 53H38L36 55Z" fill="black" fillOpacity={0.2} />
    <path d="M44 39L42 37H46L44 39Z" fill="#FFC220" />
    <path d="M44 39L42 37H46L44 39Z" fill="black" fillOpacity={0.2} />
    <path d="M28 23L26 21L30 21L28 23Z" fill="#FFC220" />
    <path d="M28 23L26 21L30 21L28 23Z" fill="black" fillOpacity={0.2} />
    <path d="M36 23L34 21L38 21L36 23Z" fill="#FFC220" />
    <path d="M36 23L34 21L38 21L36 23Z" fill="black" fillOpacity={0.2} />
    <path d="M32 27L30 25L34 25L32 27Z" fill="#FFC220" />
    <path d="M32 27L30 25L34 25L32 27Z" fill="black" fillOpacity={0.2} />
    <path d="M32 43L30 41H34L32 43Z" fill="#FFC220" />
    <path d="M32 43L30 41H34L32 43Z" fill="black" fillOpacity={0.2} />
    <path d="M32 35L30 33H34L32 35Z" fill="#FFC220" />
    <path d="M32 35L30 33H34L32 35Z" fill="black" fillOpacity={0.2} />
    <path d="M32 51L30 49H34L32 51Z" fill="#FFC220" />
    <path d="M32 51L30 49H34L32 51Z" fill="black" fillOpacity={0.2} />
    <path d="M40 27L38 25L42 25L40 27Z" fill="#FFC220" />
    <path d="M40 27L38 25L42 25L40 27Z" fill="black" fillOpacity={0.2} />
    <path d="M40 43L38 41H42L40 43Z" fill="#FFC220" />
    <path d="M40 43L38 41H42L40 43Z" fill="black" fillOpacity={0.2} />
    <path d="M40 35L38 33H42L40 35Z" fill="#FFC220" />
    <path d="M40 35L38 33H42L40 35Z" fill="black" fillOpacity={0.2} />
    <path d="M40 51L38 49H42L40 51Z" fill="#FFC220" />
    <path d="M40 51L38 49H42L40 51Z" fill="black" fillOpacity={0.2} />
    <circle cx={23} cy={42} r={6} fill="white" />
    <circle cx={41} cy={42} r={6} fill="white" />
    <circle cx={23} cy={42} r={4} fill="#0E0E0E" />
    <circle cx={41} cy={42} r={4} fill="#0E0E0E" />
    <circle cx={23} cy={42} r={2} fill="#0E0E0E" />
    <circle cx={41} cy={42} r={2} fill="#0E0E0E" />
    <circle cx={25} cy={40} r={2} fill="white" />
    <circle cx={43} cy={40} r={2} fill="white" />
    <path
      d="M39.9366 16.7889C36.8031 19.9037 31.8458 19.9972 31.8458 19.9972C31.8458 19.9972 31.9688 15.0405 35.1023 11.9257C38.2359 8.81082 43.1932 8.71732 43.1932 8.71732C43.1932 8.71732 43.0701 13.674 39.9366 16.7889Z"
      fill="#94B200"
    />
    <path
      d="M27.7732 21.3098C29.7673 19.3275 32.9221 19.268 32.9221 19.268C32.9221 19.268 32.8438 22.4225 30.8497 24.4047C28.8555 26.387 25.7007 26.4465 25.7007 26.4465C25.7007 26.4465 25.779 23.2921 27.7732 21.3098Z"
      fill="#94B200"
    />
    <path
      d="M35.3333 12C35.3333 16.4183 31.6667 20 31.6667 20C31.6667 20 28 16.4183 28 12C28 7.58172 31.6667 4 31.6667 4C31.6667 4 35.3333 7.58172 35.3333 12Z"
      fill="#94B200"
    />
    <path
      d="M38 22C34.6863 22 32 20 32 20C32 20 34.6863 18 38 18C41.3137 18 44 20 44 20C44 20 41.3137 22 38 22Z"
      fill="#94B200"
    />
    <path
      d="M25.6667 22C22.1689 22 19.3333 20 19.3333 20C19.3333 20 22.1689 18 25.6667 18C29.1645 18 32 20 32 20C32 20 29.1645 22 25.6667 22Z"
      fill="#94B200"
    />
    <path
      d="M28.5056 11.9329C31.6298 15.0571 31.7381 20.0142 31.7381 20.0142C31.7381 20.0142 26.7811 19.9059 23.6569 16.7817C20.5327 13.6575 20.4244 8.70043 20.4244 8.70043C20.4244 8.70043 25.3814 8.80872 28.5056 11.9329Z"
      fill="#94B200"
    />
    <path
      d="M32.7621 24.4C30.7739 22.4118 30.7049 19.2572 30.7049 19.2572C30.7049 19.2572 33.8596 19.3261 35.8478 21.3143C37.836 23.3025 37.9049 26.4571 37.9049 26.4571C37.9049 26.4571 34.7503 26.3882 32.7621 24.4Z"
      fill="#94B200"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M26.6403 48.6527C26.8322 48.454 27.1487 48.4485 27.3473 48.6403C29.915 51.1199 34.085 51.1199 36.6527 48.6403C36.8513 48.4485 37.1678 48.454 37.3597 48.6527C37.5515 48.8513 37.546 49.1678 37.3473 49.3597C34.3921 52.2134 29.6079 52.2134 26.6527 49.3597C26.454 49.1678 26.4485 48.8513 26.6403 48.6527Z"
      fill="#0E0E0E"
    />
  </svg>
);
export const PineappleAvatar = ({ size = 'xl', ...props }: StaticIconProps) => {
  return <Icon icon={Pineapple} {...props} size={size} color={undefined} />;
};
