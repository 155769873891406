import { useNavigate } from 'react-router-dom';

import { useCheckAuthTimer, useJWT } from '@jane/shared/auth';

import { handleInvalidLoginToken } from '../common/redux/login';
import { useCustomerDispatch } from '../customer/dispatch';

const CheckAuthTimer = () => {
  const bearerToken = useJWT();
  const navigate = useNavigate();
  const dispatch = useCustomerDispatch();
  useCheckAuthTimer(bearerToken, () =>
    dispatch(handleInvalidLoginToken(false, navigate))
  );

  return null;
};

export default CheckAuthTimer;
