import { AppleIcon, Button } from '@jane/shared/reefer';
import type { ButtonProps } from '@jane/shared/reefer';

export interface AppleButtonProps extends ButtonProps {
  label: string;
  loaded: boolean | undefined;
  onClick: () => void;
}
const AppleSignInButton = ({
  onClick,
  loaded,
  label,
  ...buttonProps
}: AppleButtonProps) => {
  return (
    <Button
      disabled={!loaded}
      full
      label={label}
      onClick={onClick}
      startIcon={<AppleIcon size="sm" />}
      variant="tertiary"
      {...buttonProps}
    />
  );
};

export default AppleSignInButton;
