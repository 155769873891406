import clsx from 'clsx';
import { forwardRef, useEffect, useState } from 'react';
import type { ChangeEvent, FocusEvent } from 'react';

import type { DateTimeFieldProps } from '../field.types';
import { FieldWrapper } from '../fieldWrapper/fieldWrapper';
import styles from './dateTimeField.module.css';

export interface DateTimeFieldPropsInternal extends DateTimeFieldProps {
  onUpdate?: (value: string) => void;
  value?: string;
}

export const DateTimeFieldInternal = forwardRef<
  HTMLInputElement,
  DateTimeFieldPropsInternal
>(
  (
    {
      autoFocus = false,
      borderRadius = 'sm',
      disableMobileInputStyling = false,
      children,
      className,
      defaultValue,
      disabled,
      autocomplete,
      helperText,
      id,
      label,
      labelHidden,
      max,
      min,
      name,
      onBlur,
      onChange,
      onUpdate,
      readOnly,
      step,
      'data-testid': testId,
      type = 'date',
      value: externalValue,
      width,
      ...props
    },
    ref
  ) => {
    const [value, setValue] = useState(
      defaultValue ? defaultValue.toString() : ''
    );

    useEffect(() => {
      if (externalValue !== undefined && externalValue !== value) {
        setValue(externalValue);
        onUpdate && onUpdate(externalValue);
      }
    }, [externalValue, onUpdate, value]);

    const handleBlur = (event: FocusEvent<HTMLInputElement>) => {
      setValue(event.target.value);
      onBlur && onBlur(event.target.value);
    };

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
      setValue(event.target.value);
      onChange && onChange(event.target.value);
    };
    return (
      <FieldWrapper
        className={className}
        disabled={disabled}
        disableMobileInputStyling={disableMobileInputStyling}
        helperText={helperText}
        id={id}
        label={label}
        labelHidden={labelHidden}
        labelPosition="t"
        name={name}
        isWrappingTextInput
        render={(renderProps) => (
          <input
            autoFocus={autoFocus}
            className={clsx(
              styles.dateTimeField_input,
              styles[`dateTimeField_input__borderRadius_${borderRadius}`],
              {
                [styles.dateTimeField_input__mobile]:
                  !disableMobileInputStyling,
              }
            )}
            disabled={disabled}
            autoComplete={autocomplete}
            id={name}
            name={name}
            onBlur={handleBlur}
            onChange={handleChange}
            min={min}
            max={max}
            readOnly={readOnly}
            step={step}
            ref={ref}
            type={type}
            value={value}
            {...renderProps}
          />
        )}
        data-testid={testId}
        width={width}
        {...props}
      >
        {children}
      </FieldWrapper>
    );
  }
);

/**
 * DateTime fields allow users to specify the date, time or both values into a UI.
 *
 * Use this component *outside forms* for inputs of `type`: `date`, `time`, or `datetime-local`.
 *
 * **NOTE: DO NOT USE THIS COMPONENT WITHIN FORMS**
 *
 * For a similar component for use within forms, see [`Form.DateTimeField`](/story/components-forms-form-datetimefield--default).
 */
export const DateTimeField = forwardRef<HTMLInputElement, DateTimeFieldProps>(
  (props, ref) => <DateTimeFieldInternal ref={ref} {...props} />
);
