import compact from 'lodash/compact';
import uniqBy from 'lodash/uniqBy';

import type {
  Cart,
  CrmRedemption,
  CrmReward,
  Id,
  StoreSpecial,
} from '@jane/shared/models';

export const crmDiscountAmount = (
  price: number,
  discount: CrmReward | CrmRedemption
) => {
  if (discount.reward.type === 'percent') {
    return (price * Number(discount.reward.amount)) / 100;
  } else {
    return Number(discount.reward.amount);
  }
};

export const applicableProductSpecials = (
  cart: Cart,
  specials: StoreSpecial[]
) => {
  return uniqBy(
    compact(
      cart.products.map((cartProduct) =>
        specials.find((s) => s.id === cartProduct.special_id)
      )
    ),
    'id'
  );
};

export const applicableCartTotalSpecial = (
  specials: readonly StoreSpecial[],
  cartSpecialId: Id | undefined
) => specials.find((special) => special.id === cartSpecialId);

export const getUnavailableProductIds = (
  unavailableProducts: { product_id: Id }[]
) => unavailableProducts.map((product) => product.product_id);

export const calcNumberOfCartItems = (cart: Cart) =>
  cart.products.reduce((acc, cartProduct) => {
    acc += cartProduct.count;
    return acc;
  }, 0);
