import type {
  Dispatch,
  MutableRefObject,
  ReactNode,
  SetStateAction,
} from 'react';
import React, { createContext, useContext, useRef, useState } from 'react';

import type { Variant } from './text-input';

const InputRefContext =
  createContext<MutableRefObject<HTMLInputElement | null> | null>(null);
const SetShrinkContext = createContext<
  Dispatch<SetStateAction<boolean>> | undefined
>(undefined);
const SetFocusedContext = createContext<
  Dispatch<SetStateAction<boolean>> | undefined
>(undefined);

type StateContextT = {
  error: boolean;
  focused: boolean;
  shrink: boolean;
  success: boolean;
  variant: Variant;
};
const StateContext = createContext<StateContextT | undefined>(undefined);

interface Props {
  children: ReactNode;
  error?: boolean;
  isFocused: boolean;
  isShrunk: boolean;
  success?: boolean;
  variant: Variant;
}

const TextFieldProvider = ({
  children,
  isShrunk,
  variant = 'material',
  isFocused = false,
  error = false,
  success = false,
}: Props) => {
  const [shrink, setShrink] = useState<boolean>(isShrunk);
  const [focused, setFocused] = useState<boolean>(isFocused);
  const inputRef = useRef<HTMLInputElement>(null);

  const stateContext: StateContextT = {
    shrink,
    focused,
    error,
    success,
    variant,
  };

  return (
    <StateContext.Provider value={stateContext}>
      <InputRefContext.Provider value={inputRef}>
        <SetShrinkContext.Provider value={setShrink}>
          <SetFocusedContext.Provider value={setFocused}>
            {children}
          </SetFocusedContext.Provider>
        </SetShrinkContext.Provider>
      </InputRefContext.Provider>
    </StateContext.Provider>
  );
};

export const useTextField = (): {
  error: boolean;
  focused: boolean;
  inputRef: MutableRefObject<HTMLInputElement | null> | null;
  setFocused: Dispatch<SetStateAction<boolean>>;
  setShrink: Dispatch<SetStateAction<boolean>>;
  shrink: boolean;
  success: boolean;
  variant: Variant;
} => {
  const state = useContext(StateContext);
  const inputRef = useContext(InputRefContext);
  const setShrink = useContext(SetShrinkContext);
  const setFocused = useContext(SetFocusedContext);

  if (
    state === undefined ||
    inputRef === undefined ||
    setShrink === undefined ||
    setFocused === undefined
  ) {
    throw new Error('useTextField must be used within a TextFieldProvider');
  }

  const { shrink, focused, variant, error, success } = state;

  return {
    shrink,
    inputRef,
    setShrink,
    focused,
    variant,
    setFocused,
    error,
    success,
  };
};

export default TextFieldProvider;
