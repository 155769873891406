import styled from '@emotion/styled';

import { TERPENE_COLORS } from '@jane/search/util';
import { Box, Flex, InfoIcon, Popover, Typography } from '@jane/shared/reefer';
import type { TerpeneEnum } from '@jane/shared/types';

import { TERPENE_DETAILS } from './details';

export interface TerpeneBarProps {
  actualPercentage: number;
  terpene: TerpeneEnum;
  unit: string;
  variant?: 'row' | 'column';
  widthPercentage: number;
}

const ColumnTerpeneContainer = styled(Box)<{ flexBasis?: string }>(
  ({ flexBasis }) => ({ flexBasis })
);

export const TerpeneBar = ({
  actualPercentage,
  terpene,
  unit,
  variant = 'row',
  widthPercentage,
}: TerpeneBarProps) => {
  const backgroundColor = TERPENE_COLORS[terpene];
  const details = TERPENE_DETAILS[terpene];
  const formattedTerpene = terpene.charAt(0).toUpperCase() + terpene.slice(1);
  const formattedUnit =
    unit.toLowerCase() === 'mgpg' || unit.toLowerCase() === 'mg/g'
      ? ' mg/g'
      : '%';

  if (variant === 'column') {
    return (
      <ColumnTerpeneContainer flexBasis={`${widthPercentage}%`}>
        <Flex flexDirection="column">
          <Box
            borderRadius="lg"
            height={8}
            background={backgroundColor}
            width="100%"
          />
          <Typography truncateAt="100%">{formattedTerpene}</Typography>
        </Flex>
      </ColumnTerpeneContainer>
    );
  }

  const popoverContent = details
    ? () => (
        <Popover.Content label={details}>
          <Typography>{details}</Typography>
        </Popover.Content>
      )
    : undefined;

  const terpeneBar = (
    <Flex width="100%" flexDirection="column">
      <Box
        background="grays-ultralight"
        width="100%"
        borderRadius="lg"
        height={8}
      >
        <Box
          borderRadius="lg"
          height={8}
          background={backgroundColor}
          width={`${widthPercentage}%`}
        />
      </Box>
      <Flex width="100%" justifyContent="space-between" flexWrap="wrap">
        <Typography>{formattedTerpene}</Typography>
        <Flex alignItems="center" gap={4}>
          <Typography>
            {actualPercentage}
            {formattedUnit}
          </Typography>
          {details && <InfoIcon size="sm" />}
        </Flex>
      </Flex>
    </Flex>
  );

  if (details) {
    return (
      <Popover
        closeOnTargetClick
        openOn="hover"
        label={details}
        target={terpeneBar}
        variant="info"
        targetWidth="100%"
      >
        {popoverContent}
      </Popover>
    );
  }

  return terpeneBar;
};
