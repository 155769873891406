/**
 * DO NOT MODIFY THIS FILE!
 * It was created by the reefer-icons generator.
 * Please update & re-run the generator if need be.
 */
import type { SVGProps } from 'react';

import type { DynamicIconProps } from '../../../icon/icon.types';
import { Icon } from '../../icon';

const TagFilled16 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="100%"
    height="100%"
    fill="none"
    viewBox="0 0 16 16"
    {...props}
  >
    <g className="Tag filled 16">
      <path
        fill="#0E0E0E"
        fillRule="evenodd"
        d="M.414 2.414v5.172A2 2 0 0 0 1 9l6 6a2 2 0 0 0 2.829 0L15 9.83A2 2 0 0 0 15 7L9 1A2 2 0 0 0 7.586.414H2.414a2 2 0 0 0-2 2Zm4 4a2 2 0 1 0 0-4 2 2 0 0 0 0 4Z"
        className="SVG"
        clipRule="evenodd"
      />
    </g>
  </svg>
);

const SIZE_MAP = {
  sm: TagFilled16,
  md: TagFilled16,
  lg: TagFilled16,
  xl: TagFilled16,
  xxl: TagFilled16,
  xxxl: TagFilled16,
};

export const TagFilledIcon = ({
  size = 'md',
  'data-testid': testId,
  ...props
}: DynamicIconProps) => {
  return (
    <Icon
      data-testid={testId || 'tag-filled-icon'}
      icon={SIZE_MAP[size]}
      {...props}
      size={size}
    />
  );
};
