import throttle from 'lodash/throttle';
import { useCallback, useEffect, useRef, useState } from 'react';

import { config } from '@jane/shared/config';

/**
 * useDetectIdle will detect if a user has not interacted with the page after a specified time.
 *
 * @param idleTime - The duration in milliseconds after which the user is considered idle.
 * @param onIdle - A callback function that is fired when an idle state is detected.
 * @param waitForFirstInteraction - Wait until the user interacts with the page before starting idle detection.
 * @return - A boolean state indicating if the user is idle.
 */

export const EVENTS = [
  'scroll',
  'keydown',
  'keypress',
  'touchstart',
  'touchmove',
];

export const useDetectIdle = (
  idleTime: number,
  onIdle: () => void,
  waitForFirstInteraction = false
): boolean => {
  const [isIdle, setIsIdle] = useState(false);
  const idleTimerRef = useRef<ReturnType<typeof setTimeout>>();

  // This function will clear the timeout (if it exists), and then re-create it.
  const resetTimer = useCallback(() => {
    if (idleTimerRef.current) clearTimeout(idleTimerRef.current);

    idleTimerRef.current = setTimeout(() => {
      onIdle();
      setIsIdle(true);
    }, idleTime);
  }, [idleTime, onIdle]);

  // Triggered when a user interacts with the page.
  const handleInteraction = useCallback(
    throttle(
      () => {
        setIsIdle(false);
        resetTimer();
      },
      config.nodeEnv === 'test' ? 0 : 1000
    ),
    [resetTimer]
  );

  useEffect(() => {
    // Start the timer upon first render unless waitForFirstInteraction is true.
    if (!waitForFirstInteraction) {
      resetTimer();
    }

    EVENTS.forEach((event) =>
      window.addEventListener(event, handleInteraction)
    );

    return () => {
      if (idleTimerRef.current) clearTimeout(idleTimerRef.current);
      EVENTS.forEach((event) =>
        window.removeEventListener(event, handleInteraction)
      );
    };
  }, [handleInteraction]);

  return isIdle;
};
