import type { Reducer } from 'redux';

import type {
  FetchInlineAdParams,
  FetchInlineAdResponse,
} from '@jane/dm/internal';
import { fetchInlineAd } from '@jane/dm/internal';
import type { MenuProduct } from '@jane/shared/models';

import { fetchMenuProducts } from '../../hooks/useAd/state/client/fetchMenuProducts';
import { createSimpleAction, createStandardAction } from '../../redux-util';
import type { CustomerThunkActionCreator } from '../redux';
import type { CustomerAction } from './types';

const FETCH_MENU_PRODUCT_HITS = 200;

export const getAdMenuProducts: CustomerThunkActionCreator<{
  filters: string;
}> =
  ({ filters }) =>
  (dispatch) => {
    fetchMenuProducts({
      filters,
      hitsPerPage: FETCH_MENU_PRODUCT_HITS,
    }).then((result) => {
      dispatch(getAdMenuProductSuccess(result));
    });
  };

const GET_AD_MENU_PRODUCTS_SUCCESS =
  'ad-candidates/get-ad-menu-products-success';
const getAdMenuProductSuccess = createStandardAction(
  GET_AD_MENU_PRODUCTS_SUCCESS
)<MenuProduct[]>();

const SET_KEYWORD_MATCHED_ADS = 'ad-candidates/set-keyword-matched-ads';
export const setKeywordMatchedAds = createStandardAction(
  SET_KEYWORD_MATCHED_ADS
)<KeywordMatchedAds>();

const RESET_AD_CANDIDATES = 'ad-candidates/reset';
export const resetAdCandidates = createSimpleAction(RESET_AD_CANDIDATES);

const GET_INLINE_ADS = 'ad-candidates/post';
const GET_INLINE_ADS_SUCCESS = 'ad-candidates/post-success';
const getInlineAdsSuccess = createStandardAction(GET_INLINE_ADS_SUCCESS)<{
  adDecisions: FetchInlineAdResponse;
}>();
const GET_INLINE_ADS_ERROR = 'ads-candidates/get-error';
const getInlineAdsError = createSimpleAction(GET_INLINE_ADS_ERROR);

export type AdCandidatesActions =
  | { type: typeof GET_INLINE_ADS }
  | { type: typeof GET_INLINE_ADS_ERROR }
  | { type: typeof RESET_AD_CANDIDATES }
  | ReturnType<typeof getInlineAdsSuccess>
  | ReturnType<typeof getAdMenuProductSuccess>
  | ReturnType<typeof setKeywordMatchedAds>;

export type KeywordMatchedAds = Record<string, MenuProduct[]>;

export type AdCandidatesState = {
  adDecisions: FetchInlineAdResponse;
  isFetchingAds: boolean;
  keywordMatchedAds: KeywordMatchedAds;
  menuProducts: MenuProduct[];
};

const getInitialState = (): AdCandidatesState => ({
  adDecisions: { flights: null, products_details: null, rows: null },
  isFetchingAds: false,
  keywordMatchedAds: {},
  menuProducts: [],
});

export const adCandidatesReducer: Reducer<AdCandidatesState, CustomerAction> = (
  state = getInitialState(),
  action
) => {
  switch (action.type) {
    case GET_INLINE_ADS: {
      return {
        ...state,
        isFetchingAds: true,
      };
    }

    case GET_INLINE_ADS_SUCCESS: {
      const { adDecisions } = action.payload;
      return {
        ...state,
        adDecisions,
        isFetchingAds: false,
      };
    }

    case GET_INLINE_ADS_ERROR: {
      return {
        ...state,
        isFetchingAds: false,
      };
    }

    case GET_AD_MENU_PRODUCTS_SUCCESS: {
      return {
        ...state,
        menuProducts: action.payload || [],
      };
    }

    case SET_KEYWORD_MATCHED_ADS: {
      return {
        ...state,
        keywordMatchedAds: action.payload,
      };
    }

    case RESET_AD_CANDIDATES: {
      return {
        ...getInitialState(),
      };
    }
  }

  return state;
};

export const getInlineAds: CustomerThunkActionCreator<FetchInlineAdParams> =
  (body) => (dispatch) => {
    dispatch({ type: GET_INLINE_ADS });
    fetchInlineAd(body).then(
      (result) => {
        dispatch(getInlineAdsSuccess({ adDecisions: result }));
      },
      () => {
        dispatch(getInlineAdsError());
      }
    );
  };
