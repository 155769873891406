import { nanoid } from 'nanoid';
import { useState } from 'react';

import { config } from '@jane/shared/config';
import { getAnonymousUserId } from '@jane/shared/util';

const getAppleAuthRedirectURL = () => {
  if (config.nodeEnv === 'development')
    return `${config.apiPath}/users/id_tokens/apple`; // local dev
  if (config.deployPublicPath) {
    // deployed environment
    const API_PATH = config.apiPath.replace('/', '');
    return `${config.deployPublicPath}${API_PATH}/users/id_tokens/apple`;
  }
  return `${config.apiPath}/users/id_tokens/apple`;
};
const APPLE_AUTH_REDIRECT_URL = getAppleAuthRedirectURL();

declare global {
  interface Window {
    AppleID: {
      auth: {
        init: (configs: AppleAuthConfig) => void;
        signIn: () => AppleAuthResponse | AppleAuthError;
      };
    };
  }
}

export interface AppleAuthResponse {
  authorization: {
    // A single-use authentication code that expires after five minutes.
    code: string;
    // A JSON web token containing the user’s identification information.
    id_token: string;
    // The state passed by the init function.
    state: string;
  };
  user?: {
    email: string;
    name: {
      firstName: string;
      lastName: string;
    };
  };
}

export interface AppleAuthError {
  // The returned error code.
  error: string;
  shouldLogError: boolean;
}

interface AppleAuthConfig {
  // The developer’s client identifier, as provided by WWDR.
  clientId: string;
  // The value that associates a client session and an ID token.
  nonce: string;
  // The URI to which the authorization redirects.
  redirectURI: string;
  // The amount of user information requested from Apple.
  scope: string;
  // The current state of the request.
  state: string;
  // A Boolean that enables showing the flow in a popup.
  usePopup: boolean;
}

type AppleSDK = () => {
  initAuth: () => () => void;
  isLoaded: boolean;
  signIn: () => Promise<string | AppleAuthResponse | AppleAuthError>;
};

const ACCEPTABLE_ERROR_CODES = [
  'user_cancelled_authorize',
  'popup_closed_by_user',
  'popup_blocked_by_browser',
  'user_trigger_new_signin_flow',
];

export const useAppleSDK: AppleSDK = () => {
  const [isLoaded, setIsLoaded] = useState<boolean>(false);

  const initAuth = () => {
    const loadClient = () => {
      if (window.AppleID) {
        const customerIdentifier = getAnonymousUserId() || nanoid();
        window.AppleID.auth.init({
          clientId: config.appleClientId,
          redirectURI: APPLE_AUTH_REDIRECT_URL,
          scope: 'name email',
          state: 'Initial user authentication request',
          nonce: customerIdentifier,
          usePopup: true,
        });
        setIsLoaded(true);
      }
    };

    const script = document.createElement('script');
    script.onload = () => loadClient();
    script.src =
      'https://appleid.cdn-apple.com/appleauth/static/jsapi/appleid/1/en_US/appleid.auth.js';
    script.crossOrigin = 'anonymous';
    script.async = true;
    document.body.appendChild(script);
    return () => {
      document.body.removeChild(script);
    };
  };

  const signIn = async () => {
    if (window.AppleID) {
      try {
        return await window.AppleID.auth.signIn();
      } catch (error) {
        const shouldLogError = !ACCEPTABLE_ERROR_CODES.includes(error?.error);
        return {
          error: `Apple login failed: error=${error?.error}`,
          shouldLogError,
        } as AppleAuthError;
      }
    }
  };

  return {
    isLoaded,
    initAuth,
    signIn,
  };
};
