import { config } from '@jane/shared/config';
import type { LoginCredentials } from '@jane/shared/models';

import { loginRequest, request } from '../lib/request';

interface AppleAuthParams {
  first_name?: string;
  id_token: string;
  last_name?: string;
}

export const CustomerSource = {
  async whoami({ ref }: { ref?: string } = {}) {
    const janeUrl = `${config.apiPath}/whoami`;
    const queryParams: Record<string, string | undefined> = ref ? { ref } : {};

    return request(
      janeUrl,
      {
        method: 'GET',
      },
      queryParams
    );
  },

  logIn(credentials: LoginCredentials) {
    const url = `${config.apiPath}/sessions`;
    return loginRequest(url, {
      body: JSON.stringify({ user: credentials }),
      method: 'POST',
    });
  },

  logInWithCognitoToken(cognitoToken: string) {
    const url = `${config.apiPath}/sessions`;
    return loginRequest(url, {
      body: JSON.stringify({ cognito_token: cognitoToken }),
      method: 'POST',
    });
  },

  authenticateGoogleCode(auth: unknown) {
    const url = `${config.apiPath}/auth/google_oauth2/callback`;
    return loginRequest(url, {
      body: JSON.stringify(auth),
      method: 'POST',
    });
  },

  authenticateAppleCode(auth: AppleAuthParams) {
    const url = `${config.apiPath}/users/id_tokens/apple`;
    return loginRequest(url, {
      body: JSON.stringify(auth),
      method: 'POST',
    });
  },

  logOut() {
    const url = `${config.apiPath}/sessions`;
    return request(url, {
      method: 'DELETE',
    });
  },
};
