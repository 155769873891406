import type {
  DispensaryFilterKeys,
  JaneSearchState,
  ProductFilterKeys,
} from '@jane/search/types';

import { bucketFilterKeys, rangeFilterKeys } from './constants';
import { updateSortSuffix } from './updateSortSuffix';

export const handleFilterDeselect = <T>(
  filterKey: ProductFilterKeys | DispensaryFilterKeys | 'all',
  searchState: JaneSearchState<T>
): JaneSearchState<T> => {
  const { bucketFilters, currentSort, filters, rangeFilters, ...restOfState } =
    searchState;

  let updatedFilters = filters;
  let updatedBucketFilters = bucketFilters;
  let updatedRangeFilters = rangeFilters;
  let updatedSortSuffix = currentSort?.suffix;

  if (rangeFilterKeys.includes(filterKey)) {
    updatedRangeFilters = {
      ...(rangeFilters || {}),
      [filterKey]: {},
    };
  } else if (bucketFilterKeys.includes(filterKey)) {
    updatedBucketFilters = {
      ...(bucketFilters || {}),
      [filterKey]: [],
    };
  } else {
    updatedFilters = {
      ...(filters || {}),
      [filterKey]: [],
    };

    if (updatedSortSuffix) {
      if (filterKey === 'available_weights') {
        updatedSortSuffix = updateSortSuffix(updatedSortSuffix, []);
      }
    }
  }

  if (filterKey === 'all') {
    updatedBucketFilters = undefined;
    updatedFilters = undefined;
    updatedRangeFilters = undefined;

    if (updatedSortSuffix) {
      updatedSortSuffix = updateSortSuffix(updatedSortSuffix, []);
    }
  }

  const updatedSort = currentSort;
  if (updatedSort && updatedSortSuffix) {
    updatedSort.suffix = updatedSortSuffix;
  }

  return {
    ...restOfState,
    bucketFilters: updatedBucketFilters,
    currentSort: updatedSort,
    filters: updatedFilters,
    rangeFilters: updatedRangeFilters,
  };
};
