/**
 * DO NOT MODIFY THIS FILE!
 * It was created by the reefer-icons generator.
 * Please update & re-run the generator if need be.
 */
import type { SVGProps } from 'react';

import type { StaticIconProps } from '../../../icon/icon.types';
import { Icon } from '../../icon';

const Pepper24 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="100%"
    height="100%"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <g className="Pepper 24" clipPath="url(#a)">
      <g className="Fill-Main">
        <path
          fill="#F33"
          fillRule="evenodd"
          d="M8.892 6.158c.024.19.126.284.308.284.174 0 .277-.095.308-.284.08-.474.154-.846.225-1.114.071-.277.174-.486.308-.629.135-.142.344-.252.629-.331.284-.087.68-.178 1.185-.273.19-.032.284-.134.284-.308s-.094-.277-.284-.308a20.85 20.85 0 0 1-1.197-.25c-.277-.078-.486-.189-.628-.331-.135-.142-.237-.352-.308-.628A19.658 19.658 0 0 1 9.508.848C9.477.658 9.374.563 9.2.563c-.182 0-.284.1-.308.297-.087.474-.162.845-.225 1.114-.064.269-.166.474-.309.616-.134.142-.343.253-.628.332-.284.08-.68.17-1.185.273-.19.031-.284.134-.284.308s.098.276.296.308a9.51 9.51 0 0 1 1.173.26c.285.08.494.19.628.333.143.142.245.351.309.628.063.268.138.644.225 1.126Zm4.42 4.053c1.143-3.835 2.344-6.464 5.947-5.594 2.42.585 3.814 3.795-.186 11.097-6.01 10.971-20.075 8.66-18.45 2.87 6.128.387 10.165.104 12.69-8.373Zm-9.956 2.97c.025.246.152.369.38.369A.38.38 0 0 0 4 13.455c.07-.07.11-.155.123-.256.082-.62.164-1.103.247-1.45.088-.354.224-.62.407-.797.184-.177.458-.313.825-.408.367-.1.879-.211 1.536-.331.227-.044.341-.174.341-.389a.36.36 0 0 0-.104-.265.386.386 0 0 0-.247-.124 16.125 16.125 0 0 1-1.526-.265c-.367-.089-.641-.225-.825-.408-.183-.183-.319-.455-.407-.815a16.33 16.33 0 0 1-.247-1.498c-.025-.24-.155-.36-.389-.36-.227 0-.354.123-.379.37a12.212 12.212 0 0 1-.246 1.46c-.083.347-.218.61-.408.786-.183.17-.461.307-.834.408-.367.095-.876.202-1.527.322-.227.038-.341.168-.341.389s.13.35.389.389c.638.1 1.137.199 1.498.294.366.088.641.224.824.407.184.177.316.446.399.806.088.354.17.84.246 1.46Z"
          clipRule="evenodd"
        />
        <path
          fill="#000"
          fillOpacity={0.2}
          fillRule="evenodd"
          d="M8.892 6.158c.024.19.126.284.308.284.174 0 .277-.095.308-.284.08-.474.154-.846.225-1.114.071-.277.174-.486.308-.629.135-.142.344-.252.629-.331.284-.087.68-.178 1.185-.273.19-.032.284-.134.284-.308s-.094-.277-.284-.308a20.85 20.85 0 0 1-1.197-.25c-.277-.078-.486-.189-.628-.331-.135-.142-.237-.352-.308-.628A19.658 19.658 0 0 1 9.508.848C9.477.658 9.374.563 9.2.563c-.182 0-.284.1-.308.297-.087.474-.162.845-.225 1.114-.064.269-.166.474-.309.616-.134.142-.343.253-.628.332-.284.08-.68.17-1.185.273-.19.031-.284.134-.284.308s.098.276.296.308a9.51 9.51 0 0 1 1.173.26c.285.08.494.19.628.333.143.142.245.351.309.628.063.268.138.644.225 1.126Zm4.42 4.053c1.143-3.835 2.344-6.464 5.947-5.594 2.42.585 3.814 3.795-.186 11.097-6.01 10.971-20.075 8.66-18.45 2.87 6.128.387 10.165.104 12.69-8.373Zm-9.956 2.97c.025.246.152.369.38.369A.38.38 0 0 0 4 13.455c.07-.07.11-.155.123-.256.082-.62.164-1.103.247-1.45.088-.354.224-.62.407-.797.184-.177.458-.313.825-.408.367-.1.879-.211 1.536-.331.227-.044.341-.174.341-.389a.36.36 0 0 0-.104-.265.386.386 0 0 0-.247-.124 16.125 16.125 0 0 1-1.526-.265c-.367-.089-.641-.225-.825-.408-.183-.183-.319-.455-.407-.815a16.33 16.33 0 0 1-.247-1.498c-.025-.24-.155-.36-.389-.36-.227 0-.354.123-.379.37a12.212 12.212 0 0 1-.246 1.46c-.083.347-.218.61-.408.786-.183.17-.461.307-.834.408-.367.095-.876.202-1.527.322-.227.038-.341.168-.341.389s.13.35.389.389c.638.1 1.137.199 1.498.294.366.088.641.224.824.407.184.177.316.446.399.806.088.354.17.84.246 1.46Z"
          clipRule="evenodd"
        />
      </g>
      <path
        fill="#94B200"
        fillRule="evenodd"
        d="M19.826 4.796c.24-.377.498-.62.766-.774.614-.353 1.452-.33 2.736.016a.533.533 0 0 0 .277-1.029c-1.314-.354-2.53-.493-3.544.088-.522.3-.947.765-1.298 1.403-1.77-.285-2.913.36-3.763 1.605 1.446 1.3 4.028 1.984 6.595 1.984-.014-1.773-.746-2.824-1.769-3.293Z"
        className="Fill-Accent"
        clipRule="evenodd"
      />
    </g>
    <defs>
      <clipPath>
        <path fill="#fff" d="M0 0h24v24H0z" />
      </clipPath>
    </defs>
  </svg>
);

const SIZE_MAP = {
  md: Pepper24,
  sm: Pepper24,
  lg: Pepper24,
  xl: Pepper24,
  xxl: Pepper24,
  xxxl: Pepper24,
};

export const PepperIcon = ({
  size = 'md',
  'data-testid': testId,
  ...props
}: StaticIconProps) => {
  return (
    <Icon
      data-testid={testId || 'pepper-icon'}
      icon={SIZE_MAP[size]}
      {...props}
      size={size}
      color={undefined}
    />
  );
};
