import { config } from '@jane/shared/config';

const getAlgoliaEnv = () => config.algoliaEnv;

export const PRODUCTS_DEFAULT = `products-${getAlgoliaEnv()}`;

export const MENU_PRODUCTS_DEFAULT = `menu-products-${getAlgoliaEnv()}`;
export const MENU_PRODUCTS_BY_PRICE_ASC = `menu-products-by-price-${getAlgoliaEnv()}`;

export const STORE_DEFAULT = `stores-${getAlgoliaEnv()}`;
