import storeEngine from 'store/src/store-engine';
import cookieStore from 'store/storages/cookieStorage';
import localStore from 'store/storages/localStorage';
import memoryStore from 'store/storages/memoryStorage';

const store = storeEngine.createStore([cookieStore, localStore, memoryStore]);

interface Listener<T = any> {
  (value: T | undefined): void;
}

const listeners: Record<string, Listener[]> = {};

const keyChange = (key: string, data: any) => {
  const callbacks = key in listeners ? listeners[key] : [];
  callbacks.forEach((fn) => fn(data));
};

const subscribeToKey = (key: string, fn: Listener) => {
  if (!(key in listeners)) {
    listeners[key] = [];
  }

  listeners[key].push(fn);

  fn(store.get(key));

  const unsubscribe = () => {
    listeners[key] = listeners[key].filter((o) => o !== fn);
  };

  return unsubscribe;
};

const set = (key: string, data: any) => {
  store.set(key, data);
  keyChange(key, data);
};

const get = <T = any>(key: string): T | undefined => {
  return store.get(key);
};

const remove = (key: string) => {
  store.remove(key);
  keyChange(key, undefined);
};

const refreshSubscriptions = () => {
  Object.keys(listeners).forEach((key) => keyChange(key, store.get(key)));
};

const clear = () => {
  store.clearAll();
  refreshSubscriptions();
};

export const AuthStorage = {
  subscribeToKey,
  set,
  get,
  remove,
  clear,
  refreshSubscriptions,
};
