import { FLAGS, useFlag } from '@jane/shared/feature-flags';

export const DEFAULT_SPONSORED_DESKTOP_POSITIONS = [0, 1, 5, 9, 11, 17, 21];
export const DEFAULT_SPONSORED_MOBILE_POSITIONS = [0, 1, 4, 7, 11, 15, 19];
const MAX_PRODUCTS = 1000;

export const useSponsoredPositions = (isMobile: boolean): number[] => {
  const flagName = isMobile
    ? FLAGS.dmSponsoredMobilePositions
    : FLAGS.dmSponsoredDesktopPositions;
  const defaultValue = isMobile
    ? DEFAULT_SPONSORED_MOBILE_POSITIONS
    : DEFAULT_SPONSORED_DESKTOP_POSITIONS;

  return useFlag<number[]>(flagName, defaultValue);
};

interface Item extends Record<string, unknown> {
  product_id: number;
}
interface Props {
  organic: Item[];
  sponsored: Item[];
  sponsoredPositions: number[];
}
export const promoteSponsoredItems = ({
  sponsored: sponsoredItems,
  organic,
  sponsoredPositions,
}: Props) => {
  const organicItems = removeMissingIds(organic);
  const itemsSeen: Record<number, boolean> = {};

  const output = [];
  let organicIndex = 0;
  let sponsoredIndex = 0;
  for (let outputIndex = 0; outputIndex < MAX_PRODUCTS; outputIndex += 1) {
    if (sponsoredPositions.includes(outputIndex)) {
      const { item, index } = getNextUnseenItem(
        sponsoredItems,
        sponsoredIndex,
        itemsSeen
      );
      if (item) {
        sponsoredIndex = index;
        output.push(item);
        continue;
      }
    }
    const { item, index } = getNextUnseenItem(
      organicItems,
      organicIndex,
      itemsSeen
    );
    if (!item) {
      break;
    }
    organicIndex = index;
    output.push(item);
  }
  return output;
};

const getNextUnseenItem = (
  items: Item[],
  index: number,
  itemsSeen: Record<number, boolean>
): { index: number; item: Item | null } => {
  for (; index < items.length; index += 1) {
    const item = items[index];
    if (!itemsSeen[item.product_id]) {
      itemsSeen[item.product_id] = true;
      return { item, index };
    }
  }
  return { item: null, index };
};

const removeMissingIds = (items: Item[]) =>
  items.filter((item) => item.product_id != null);
