import type { CSSProperties } from 'react';

import type { BaseColors, ColorConfig, ThemeColors } from './themeColors.types';
import type { ComponentsConfig } from './themeComponents.types';

type DeepPartial<T> = T extends object
  ? {
      [P in keyof T]?: DeepPartial<T[P]>;
    }
  : T;

/** Border Radius Types */

export const BORDER_RADIUS_KEYS = ['xs', 'sm', 'lg', 'xl'] as const;

export type BorderRadiusKey = typeof BORDER_RADIUS_KEYS[number];

export type BorderRadiusConfig = {
  [key in BorderRadiusKey]?: CSSProperties['borderRadius'];
};

/** Theme Types */

// BaseThemeConfig represents all the theming properties that can be customized
export interface BaseThemeConfig<T extends string = string> {
  borderRadius: BorderRadiusConfig;
  colors: ColorConfig;
  components: ComponentsConfig<T>;
}

// CustomThemeConfig is what external users will actually provide.
// Not everything is required because Reefer provides a fully-defiend
// BaseThemeConfig as the default.
export type CustomThemeConfig = DeepPartial<BaseThemeConfig>;

// StaticThemeConfig represents theming properties that canNOT be customized
export interface StaticThemeConfig {
  colors: BaseColors;
}

// ReeferTheme represents the final theme object
// This final object has all colors mapped to their light/main/dark ColorGroups
export interface ReeferTheme<T extends string = string>
  extends Omit<BaseThemeConfig<T>, 'colors'>,
    Omit<StaticThemeConfig, 'colors'> {
  colors: BaseColors & ThemeColors;
}
