import { COLORS } from './colors';
import type { BaseThemeConfig } from './theme.types';
import type {
  TypographyVariant,
  TypographyVariantConfig,
} from './themeComponents.types';

const LETTER_SPACING_NORMAL = 'normal';
const WEIGHT_REGULAR = 400;
const WEIGHT_SEMIBOLD = 600;
const TEXT_TRANSFORM_NONE = 'none';

const baseVariantConfig: Partial<TypographyVariantConfig> = {
  body: {
    desktopStyles: {
      fontSize: '16px',
      lineHeight: '24px',
    },
    fontSize: '15px',
    fontWeight: WEIGHT_REGULAR,
    letterSpacing: LETTER_SPACING_NORMAL,
    lineHeight: '24px',
    textTransform: TEXT_TRANSFORM_NONE,
  },
  caps: {
    desktopStyles: {
      fontSize: '14px',
      lineHeight: '24px',
    },
    fontSize: '13px',
    fontWeight: WEIGHT_REGULAR,
    letterSpacing: '0.1em',
    lineHeight: '16px',
    textTransform: 'uppercase',
  },
  header: {
    desktopStyles: {
      fontSize: '24px',
      lineHeight: '32px',
    },
    fontWeight: WEIGHT_REGULAR,
    fontSize: '20px',
    letterSpacing: LETTER_SPACING_NORMAL,
    lineHeight: '24px',
    textTransform: TEXT_TRANSFORM_NONE,
  },
  mini: {
    desktopStyles: {
      fontSize: '14px',
      lineHeight: '24px',
    },
    fontSize: '13px',
    fontWeight: WEIGHT_REGULAR,
    letterSpacing: LETTER_SPACING_NORMAL,
    lineHeight: '16px',
    textTransform: TEXT_TRANSFORM_NONE,
  },
  title: {
    branded: true,
    desktopStyles: {
      fontSize: '36px',
      lineHeight: '40px',
    },
    fontSize: '28px',
    fontWeight: WEIGHT_REGULAR,
    letterSpacing: LETTER_SPACING_NORMAL,
    lineHeight: '32px',
    textTransform: TEXT_TRANSFORM_NONE,
  },
};

const boldVariantConfig = Object.keys(baseVariantConfig)
  .map((value) => ({
    [`${value}-bold`]: {
      ...baseVariantConfig[value as TypographyVariant],
      fontWeight: WEIGHT_SEMIBOLD,
    },
  }))
  .reduce((prev, curr) => ({
    ...prev,
    ...curr,
  })) as Partial<TypographyVariantConfig>;

/**
 * All Reefer theme configuration that can be customized
 */
export const BASE_CONFIG: BaseThemeConfig = {
  borderRadius: {
    xs: '12px',
    sm: '16px',
    lg: '24px',
    xl: '36px',
  },

  colors: {
    primary: COLORS.brand.purple,
    secondary: COLORS.brand.gold,
    info: COLORS.brand.sunset,
    success: COLORS.system.positive,
    error: COLORS.system.negative,
    background: COLORS.grays.white,

    text: {
      main: COLORS.grays.black,
      inverse: COLORS.grays.white,
      info: COLORS.grays.white,
      light: COLORS.grays.mid,
    },
  },

  components: {
    Button: {
      icons: {
        sizes: {
          default: {
            height: '48px',
          },
          jumbo: {
            height: '48px',
          },
          large: {
            height: '48px',
          },
          small: {
            height: '48px',
          },
        },
      },
      shared: {
        borderRadius: 'lg',
        borderWidth: '2px',
        typographyVariant: 'body-bold',
      },
      sizes: {
        default: {
          height: '48px',
          paddingX: 16,
        },
        jumbo: {
          height: '72px',
          paddingX: 16,
        },
        large: {
          height: '72px',
          paddingX: 16,
        },
        small: {
          height: '32px',
          paddingX: 16,
          typographyVariant: 'body',
        },
      },
      variants: {
        destructive: {
          backgroundColor: 'error',
          borderColor: 'error',
          typographyColor: 'grays-white',
        },
        'destructive-secondary': {
          backgroundColor: 'error-light',
          borderColor: 'error-light',
          typographyColor: 'error-dark',
        },
        minimal: {
          backgroundColor: 'transparent',
          borderColor: 'transparent',
          typographyColor: 'grays-black',
        },
        'minimal-inverse': {
          backgroundColor: 'grays-white',
          borderColor: 'grays-white',
          typographyColor: 'grays-black',
        },
        primary: {
          backgroundColor: 'primary',
          borderColor: 'primary',
          typographyColor: 'text-inverse',
        },
        'primary-inverse': {
          backgroundColor: 'grays-white',
          borderColor: 'grays-white',
          typographyColor: 'primary',
        },
        secondary: {
          backgroundColor: 'primary-light',
          borderColor: 'primary-light',
          typographyColor: 'primary',
        },
        tertiary: {
          backgroundColor: 'grays-ultralight',
          borderColor: 'grays-ultralight',
          typographyColor: 'grays-black',
        },
        'tertiary-selected': {
          backgroundColor: 'grays-ultralight',
          borderColor: 'primary',
          typographyColor: 'grays-black',
        },
      },
    },

    Card: {
      styles: {
        borderRadius: 'sm',
      },
    },

    Icon: {
      sizes: {
        sm: '16px',
        md: '24px',
        lg: '32px',
        xl: '48px',
        xxl: '64px',
        xxxl: '96px',
      },
    },

    Typography: {
      componentMapping: {
        title: 'h1',
        'title-bold': 'h1',
        header: 'h2',
        'header-bold': 'h2',
        body: 'p',
        'body-bold': 'p',
        mini: 'p',
        'mini-bold': 'p',
        caps: 'p',
        'caps-bold': 'p',
      },
      fonts: {
        branded: {
          fontFamily: 'Euclid Circular B',
        },
        default: {
          fontFamily: 'Jane Default',
        },
      },
      variants: {
        ...baseVariantConfig,
        ...boldVariantConfig,
      } as TypographyVariantConfig,
    },
  },
};
