/**
 * DO NOT MODIFY THIS FILE!
 * It was created by the reefer-icons generator.
 * Please update & re-run the generator if need be.
 */
import type { SVGProps } from 'react';

import type { DynamicIconProps } from '../../../icon/icon.types';
import { Icon } from '../../icon';

const Account24 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="100%"
    height="100%"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <g className="Account 24" clipPath="url(#a)">
      <path
        fill="#5E1FF0"
        fillRule="evenodd"
        d="M12 24.001c6.627 0 12-5.373 12-12s-5.373-12-12-12-12 5.373-12 12 5.373 12 12 12Zm-6.707-8.707a1 1 0 0 0 0 1.414 9.485 9.485 0 0 0 13.414 0 1 1 0 0 0-1.414-1.414 7.485 7.485 0 0 1-10.586 0 1 1 0 0 0-1.414 0ZM10 9a2 2 0 1 1-4 0 2 2 0 0 1 4 0Zm6 2a2 2 0 1 0 0-4 2 2 0 0 0 0 4Z"
        className="Fill-Main"
        clipRule="evenodd"
      />
    </g>
    <defs>
      <clipPath>
        <path fill="#fff" d="M0 .001h24v24H0z" />
      </clipPath>
    </defs>
  </svg>
);

const SIZE_MAP = {
  md: Account24,
  sm: Account24,
  lg: Account24,
  xl: Account24,
  xxl: Account24,
  xxxl: Account24,
};

export const AccountIcon = ({
  size = 'md',
  'data-testid': testId,
  ...props
}: DynamicIconProps) => {
  return (
    <Icon
      data-testid={testId || 'account-icon'}
      icon={SIZE_MAP[size]}
      {...props}
      size={size}
    />
  );
};
