import { config } from '@jane/shared/config';
import type { Id } from '@jane/shared/models';

import { request } from '../lib/request';

interface GetProductsParams {
  force_without_location?: boolean;
  lat?: number | undefined;
  long?: number | undefined;
  max_search_radius?: number | undefined;
  product_ids: Id[];
}

export class ProductsSource {
  static buildUrl(url = '') {
    return `${config.apiPath}/products${url}`;
  }

  static getProduct(id: Id, store_id?: Id) {
    return request(
      this.buildUrl(`/${id}`),
      {
        method: 'GET',
      },
      { store_id }
    );
  }

  static getProducts(params: GetProductsParams) {
    return request(this.buildUrl(), { method: 'GET' }, params);
  }
}
