import { useState } from 'react';

import { Modal } from '@jane/shared/reefer';

import type { FilterModalTypeaheadProps } from './filterModal.types';
import { FilterModalTypeaheadHeader } from './filterModalTypeaheadHeader';
import { TypeaheadList } from './typeaheadList';

export const FilterModalTypeahead = ({
  closeTypeahead,
  filterGroup,
  hideCounts,
  onChange,
  onDeselect,
}: FilterModalTypeaheadProps) => {
  const { label, items, key } = filterGroup;
  const [query, setQuery] = useState('');

  const changeHandler = (value: string) => {
    setQuery(value);
  };

  return (
    <>
      <FilterModalTypeaheadHeader
        isNested
        onClose={closeTypeahead}
        onDeselect={() => onDeselect(key)}
        onSearchChange={changeHandler}
        searchQuery={query}
        title={label}
      />
      <Modal.Content padding={false} data-testid="filter-modal-typeahead">
        <TypeaheadList
          filterKey={filterGroup.key}
          hideCounts={hideCounts}
          items={items}
          onChange={onChange}
          query={query}
        />
      </Modal.Content>
    </>
  );
};
