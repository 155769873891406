/**
 * DO NOT MODIFY THIS FILE!
 * It was created by the reefer-icons generator.
 * Please update & re-run the generator if need be.
 */
import type { SVGProps } from 'react';

import type { StaticIconProps } from '../../../icon/icon.types';
import { Icon } from '../../icon';

const HalfStar24 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="100%"
    height="100%"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <g className="Half Star 24">
      <mask
        width={24}
        height={25}
        x={0}
        y={0}
        maskUnits="userSpaceOnUse"
        style={{
          maskType: 'alpha',
        }}
      >
        <path
          fill="#FFC220"
          d="M10.713.84a1.408 1.408 0 0 1 2.574 0l2.596 5.498c.21.442.613.749 1.08.82l5.807.882c1.177.178 1.647 1.69.795 2.557l-4.201 4.28c-.338.345-.493.84-.413 1.327l.992 6.043c.2 1.225-1.03 2.16-2.082 1.581l-5.193-2.853a1.38 1.38 0 0 0-1.336 0L6.14 23.828c-1.053.578-2.283-.356-2.082-1.58l.992-6.044a1.542 1.542 0 0 0-.413-1.327l-4.201-4.28C-.417 9.73.053 8.218 1.23 8.04l5.806-.882a1.441 1.441 0 0 0 1.08-.82L10.714.84Z"
          className="SVG"
        />
      </mask>
      <g mask="url(#a)">
        <path
          fill="#FFC220"
          d="M10.713.836a1.408 1.408 0 0 1 2.574 0l2.596 5.498c.21.443.613.75 1.08.82l5.807.882c1.177.179 1.647 1.69.795 2.558l-4.201 4.28a1.54 1.54 0 0 0-.413 1.327l.992 6.043c.2 1.225-1.03 2.159-2.082 1.58l-5.193-2.853a1.38 1.38 0 0 0-1.336 0L6.14 23.824c-1.053.579-2.283-.355-2.082-1.58L5.05 16.2a1.542 1.542 0 0 0-.413-1.328l-4.201-4.28c-.852-.867-.382-2.378.795-2.557l5.806-.882a1.441 1.441 0 0 0 1.08-.82L10.714.836Z"
          className="Fill-Main"
        />
        <path
          fill="#DADADA"
          d="M15.883 6.334 13.287.836A1.413 1.413 0 0 0 12 0v21s.25-.258.668-.029l5.193 2.854c1.052.578 2.283-.356 2.082-1.581L18.95 16.2c-.08-.487.075-.983.413-1.328l4.201-4.28c.852-.867.382-2.378-.795-2.557l-5.806-.882a1.441 1.441 0 0 1-1.08-.82Z"
          className="Fill-Secondary"
        />
      </g>
    </g>
  </svg>
);

const SIZE_MAP = {
  md: HalfStar24,
  sm: HalfStar24,
  lg: HalfStar24,
  xl: HalfStar24,
  xxl: HalfStar24,
  xxxl: HalfStar24,
};

export const HalfStarIcon = ({
  size = 'md',
  'data-testid': testId,
  ...props
}: StaticIconProps) => {
  return (
    <Icon
      data-testid={testId || 'half-star-icon'}
      icon={SIZE_MAP[size]}
      {...props}
      size={size}
      color={undefined}
    />
  );
};
