import styled from '@emotion/styled';

import { useJaneUser, useUserBrandDiscounts } from '@jane/shared/data-access';
import { useShouldShowGold } from '@jane/shared/hooks';
import type {
  Cart,
  CustomerReservationHistory,
  Id,
  RouteAppMode,
  _DeepReadonlyArray,
} from '@jane/shared/models';
import { AccountIcon, Flex } from '@jane/shared/reefer';
import { calcNumberOfCartItems, monolithPaths } from '@jane/shared/util';
import type { PartnerHostedConfig } from '@jane/shared/util';

import { CountDot } from '../countDot';
import { EmbeddedCart } from '../embeddedCart/embeddedCart';
import { EmbeddedButton } from './embeddedButton';

const AccountContainer = styled.div({
  lineHeight: 0,
  position: 'relative',
});

const Container = styled.div({
  position: 'relative',
});
const UpdatedUserIcon = ({
  hasUser,
  inProgressReservations,
  pendingCashBack,
}: {
  hasUser: boolean;
  inProgressReservations: number;
  pendingCashBack?: number | string;
}) => {
  return !hasUser ? (
    <>Log in</>
  ) : (
    <Container>
      <AccountIcon color="secondary" />
      {!!inProgressReservations && (
        <CountDot
          ariaLabel="active reservations"
          count={inProgressReservations}
        />
      )}
      {!inProgressReservations && !!pendingCashBack && (
        <CountDot ariaLabel="pending cash back" gold count={'$'} />
      )}
    </Container>
  );
};

interface Props {
  cart: Cart & { status: string | undefined };
  disableAuthFeatures: boolean;
  disableInteraction?: boolean;
  hidePrices: boolean;
  inProgressReservations: _DeepReadonlyArray<CustomerReservationHistory>;
  onCartClick: () => void;
  partnerId: Id;
  routeAppMode?: RouteAppMode;
  routePartnerHostedConfig: PartnerHostedConfig;
}

export const UserActions = ({
  cart,
  disableAuthFeatures,
  disableInteraction,
  hidePrices,
  inProgressReservations,
  onCartClick,
  partnerId,
  routeAppMode,
  routePartnerHostedConfig,
}: Props) => {
  const { data: userData } = useJaneUser();
  const showJaneGold = useShouldShowGold({ store: { id: partnerId } });

  const { data: brandDiscounts } = useUserBrandDiscounts({
    enabled: showJaneGold && !!userData?.user?.authenticated,
    janeUserId: userData?.user?.id,
    status: 'awaiting_registration',
  });

  if (!routeAppMode || hidePrices) return null;

  const accountPath = () =>
    userData?.user?.authenticated
      ? monolithPaths.embeddedProfile(routeAppMode, routePartnerHostedConfig)
      : monolithPaths.embeddedLogin(routeAppMode, routePartnerHostedConfig);

  const handleCartClick = () => {
    if (disableInteraction) return;

    onCartClick();
  };

  return (
    <Flex>
      <EmbeddedButton
        inGroup={!disableAuthFeatures}
        onClick={handleCartClick}
        ariaLabel="cart"
      >
        <EmbeddedCart
          productCount={(cart && calcNumberOfCartItems(cart)) || 0}
        />
      </EmbeddedButton>
      {disableAuthFeatures ? null : (
        <EmbeddedButton
          inGroup
          to={disableInteraction ? undefined : accountPath()}
        >
          <AccountContainer>
            <UpdatedUserIcon
              hasUser={!!userData?.user?.authenticated}
              pendingCashBack={brandDiscounts?.total || 0}
              inProgressReservations={inProgressReservations?.length}
            />
          </AccountContainer>
        </EmbeddedButton>
      )}
    </Flex>
  );
};
