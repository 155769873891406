import type { SearchOptions } from '@algolia/client-search';
import type { RequestOptions } from '@algolia/transporter';

import { AlgoliaReviewsSchema } from '@jane/search/types';
import type { AlgoliaReview, CurrentSort } from '@jane/search/types';

import { search } from './client';

interface SortOptions {
  currentSort?: CurrentSort;
}

interface SearchProductReviewParams {
  searchOptions: SearchOptions & RequestOptions & SortOptions;
}

export const searchProductReviews = async ({
  searchOptions,
}: SearchProductReviewParams) => {
  const { currentSort, ...options } = searchOptions;

  return await search<AlgoliaReview>(
    {
      indexPrefix: 'product-reviews-',
      options,
      currentSort,
    },
    AlgoliaReviewsSchema
  );
};
