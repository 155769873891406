import clsx from 'clsx';
import type { CSSProperties, ReactElement } from 'react';

import type { AccessibilityProps } from '../../internal/utils';
import type { ColorKey } from '../../theme';
import type { MarginProperties } from '../../utils';
import {
  getColorCSSVar,
  getColorForBackgroundCSSVar,
  spacingCSSVars,
} from '../../utils';
import type { ReeferBaseProps } from '../../utils/baseProps.types';
import type { IconProps } from '../icon';
import { Typography } from '../typography';
import styles from './tag.module.css';

type TagSizes = 'sm' | 'lg';

export interface TagProps
  extends Pick<AccessibilityProps, 'ariaLabel'>,
    ReeferBaseProps,
    MarginProperties {
  /** Background changes the background color, defaults to 'grays-mid'  */
  background?: ColorKey;

  /** Color changes the text color, defaults to black with light backgrounds and white with dark backgrounds */
  color?: ColorKey;

  /* Icon that will show up on the right side of the tag */
  endIcon?: ReactElement<IconProps>;

  /* Icon that will show up on the left side of the tag */
  icon?: ReactElement<IconProps>;

  /** Label is the text content of the tag */
  label: string;

  /** Size of the tag */
  size?: TagSizes;
}

/**
 * Small colored tag with some text in it, used to add labels or context to other elements
 */
export function Tag({
  ariaLabel,
  background = 'grays-mid',
  className,
  color,
  'data-testid': testId,
  endIcon,
  icon,
  id,
  label,
  size = 'sm',
  style,
  ...marginProps
}: TagProps) {
  return (
    <div
      aria-label={ariaLabel}
      className={clsx(className, styles.tag, {
        [styles.tag__small]: size === 'sm',
      })}
      data-testid={testId}
      id={id}
      style={
        {
          '--tag-background-color': getColorCSSVar(background),
          '--tag-color': color
            ? getColorCSSVar(color)
            : getColorForBackgroundCSSVar(background),
          ...spacingCSSVars({
            pr: ((size === 'sm' ? 8 : 16) / (endIcon ? 2 : 1)) as 16 | 8 | 4,
            pl: ((size === 'sm' ? 8 : 16) / (icon ? 2 : 1)) as 16 | 8 | 4,
            py: size === 'sm' ? 0 : 8,
            ...marginProps,
          }),
          ...style,
        } as CSSProperties
      }
    >
      {icon && icon}
      <Typography
        style={{
          // Set line-height inline (vs via className)
          // so it properly overrides Typography's line-height
          lineHeight: '24px',
        }}
        color="inherit"
        variant={
          size === 'lg' || label?.length === 1 ? 'body-bold' : 'mini-bold'
        }
      >
        {label}
      </Typography>
      {endIcon && endIcon}
    </div>
  );
}
