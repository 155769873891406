/**
 * DO NOT MODIFY THIS FILE!
 * It was created by the reefer-icons generator.
 * Please update & re-run the generator if need be.
 */
import type { SVGProps } from 'react';

import { Icon } from '../../icon';
import type { DynamicIconProps } from '../../icon.types';

const DefaultCategory48 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M18.1684 7.6926L39.5688 13.2344C41.5386 13.7445 42.7076 15.7013 42.1798 17.605L38.9254 29.3431V36.3831C40.3482 35.4547 41.4445 34.0452 41.9237 32.3167L45.7465 18.5286C46.8022 14.7211 44.4642 10.8075 40.5245 9.78733L19.1241 4.24558C15.1844 3.22537 11.1349 5.48489 10.0792 9.29236L8.35237 15.5208C8.6898 15.4752 9.03454 15.4516 9.38497 15.4516H12.1944L13.646 10.216C14.1738 8.31225 16.1986 7.18249 18.1684 7.6926Z"
      fill="black"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M31.5404 19.0204H9.3851C7.34576 19.0204 5.69255 20.6181 5.69255 22.589V36.8635C5.69255 38.8344 7.34576 40.4321 9.3851 40.4321H31.5404C33.5797 40.4321 35.2329 38.8344 35.2329 36.8635V22.589C35.2329 20.6181 33.5797 19.0204 31.5404 19.0204ZM9.3851 15.4518C5.30642 15.4518 2 18.6472 2 22.589V36.8635C2 40.8053 5.30642 44.0007 9.3851 44.0007H31.5404C35.6191 44.0007 38.9255 40.8053 38.9255 36.8635V22.589C38.9255 18.6472 35.6191 15.4518 31.5404 15.4518H9.3851Z"
      fill="black"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20.4629 26.176L13.9154 33.2947H27.0104L20.4629 26.176ZM11.1556 30.9239L17.7031 23.8052C19.1719 22.2082 21.7539 22.2082 23.2228 23.8052L29.7702 30.9239C31.887 33.2253 30.1965 36.8633 27.0104 36.8633H13.9154C10.7293 36.8633 9.03887 33.2253 11.1556 30.9239Z"
      fill="black"
    />
  </svg>
);

const SIZE_MAP = {
  xl: DefaultCategory48,
  lg: DefaultCategory48,
  md: DefaultCategory48,
  sm: DefaultCategory48,
  xxl: DefaultCategory48,
  xxxl: DefaultCategory48,
};

export const DefaultCategoryIcon = ({
  size = 'md',
  'data-testid': testId,
  ...props
}: DynamicIconProps) => {
  return (
    <Icon
      data-testid={testId || 'default-category-icon'}
      icon={SIZE_MAP[size]}
      {...props}
      size={size}
    />
  );
};
