import { getGoogleMaps } from './googleMaps';

interface Prediction {
  description: string;
}

export interface AutocompleteAddress {
  label: string;
  value: string;
}

const LOCATION_SEARCH_COUNTRIES = ['us', 'pr', 'ca'];

const getPlacePredictions = (input: string, countries: string[]) => {
  const getPlacesService = getGoogleMaps.load().then(({ maps }) => ({
    placesService: new maps.places.AutocompleteService(),
    maps,
  }));

  return new Promise((resolve, reject) => {
    const request = {
      input,
      componentRestrictions: { country: countries },
    };

    getPlacesService.then(({ placesService, maps }) =>
      placesService.getPlacePredictions(request, (predictions, status) => {
        if (status !== maps.places.PlacesServiceStatus.OK) {
          reject();
        }

        resolve(predictions);
      })
    );
  });
};

export const getGoogleAddresses = (
  input: string,
  countries: string[] = LOCATION_SEARCH_COUNTRIES
) => {
  return new Promise<AutocompleteAddress[]>((resolve) => {
    getPlacePredictions(input, countries)
      .then((result) => {
        const predictions = result as Prediction[];

        const addresses = predictions.map((prediction) => {
          const value = prediction.description
            .split(', ')
            .slice(0, -1)
            .join(', ');
          return {
            value,
            label: value,
          };
        });

        resolve(addresses);
      })
      .catch(() => resolve([]));
  });
};
