var el;

// eslint-disable-next-line no-extra-boolean-cast
if (!!document) {
  el = document.createElement('div');
}

var prefixes = ['ms', 'Moz', 'Webkit', 'O'];
var properties = [
  'userSelect',
  'transform',
  'transition',
  'transformOrigin',
  'transformStyle',
  'transitionProperty',
  'transitionDuration',
  'transitionTimingFunction',
  'transitionDelay',
  'borderImage',
  'borderImageSlice',
  'boxShadow',
  'backgroundClip',
  'backfaceVisibility',
  'perspective',
  'perspectiveOrigin',
  'animation',
  'animationDuration',
  'animationName',
  'animationDelay',
  'animationDirection',
  'animationIterationCount',
  'animationTimingFunction',
  'animationPlayState',
  'animationFillMode',
  'appearance',
];

function GetVendorPrefix(property) {
  if (
    // eslint-disable-next-line eqeqeq
    properties.indexOf(property) == -1 ||
    !document ||
    typeof el.style[property] !== 'undefined'
  ) {
    return property;
  }

  property = property[0].toUpperCase() + property.slice(1);
  var temp;

  for (var i = 0; i < prefixes.length; i++) {
    temp = prefixes[i] + property;
    if (typeof el.style[temp] !== 'undefined') {
      prefixes = [prefixes[i]]; // we only need to check this one prefix from now on.
      return temp;
    }
  }
  return property[0].toLowerCase() + property.slice(1);
}

const prefixer = (function () {
  var cache = {};
  return function (obj) {
    if (!document) {
      return obj;
    }

    var result = {};

    for (var key in obj) {
      if (cache[key] === undefined) {
        cache[key] = GetVendorPrefix(key);
      }
      result[cache[key]] = obj[key];
    }

    return result;
  };
})();

export default prefixer;
