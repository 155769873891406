import kebabCase from 'lodash/kebabCase';
import trimEnd from 'lodash/trimEnd';
import trimStart from 'lodash/trimStart';
import values from 'lodash/values';
import qs from 'qs';

import { placeToParam } from '@jane/search/util';
import { config } from '@jane/shared/config';
import type {
  Id,
  RouteAppMode,
  StoreSpecial,
  TermsOfServiceVariants,
} from '@jane/shared/models';

import type { Categories } from '../components/category/category.types';

const includeStoreSlug = () => appMode.type === 'default';
const STORE_EMBED_APP_MODES = ['framelessEmbed', 'embedded', 'whiteLabel'];
let appMode: RouteAppMode = { type: 'default' };

export const setAppMode = (newAppMode: RouteAppMode) => {
  appMode = newAppMode;
};

export type PartnerHostedConfig = {
  isBoostForAll?: boolean;
  isPartnerHosted: boolean;
  partnerHostedPath?: string;
};
let partnerHostedConfig: PartnerHostedConfig = { isPartnerHosted: false };

export const setPartnerHostedConfig = (
  newPartnerHostedConfig: PartnerHostedConfig
) => {
  partnerHostedConfig = newPartnerHostedConfig;
};

const optionalQueryStringPath = (path: string, query?: {}) => {
  if (values(query).filter(Boolean).length === 0) return path;
  return `${path}?${qs.stringify(query)}`;
};

export const absolutePath = (relativePath: string) =>
  [trimEnd(config.deployPublicPath, '/'), trimStart(relativePath, '/')].join(
    '/'
  );

export const objectToGetParams = (obj: object) =>
  `?${Object.entries(obj)
    .map(([key, value]) => `${key}=${encodeURIComponent(value)}`)
    .join('&')}`;

interface NameAndSlugAndId {
  id: Id;
  name?: string;
  slug?: string;
}

interface ProductNameAndSlugAndId extends NameAndSlugAndId {
  brand?: string | null;
}

const getUrlBase = (path: string): string => {
  switch (appMode.type) {
    case 'default':
      return '';
    case 'framelessEmbed': {
      if (partnerHostedConfig.isBoostForAll) return '';

      if (partnerHostedConfig.isPartnerHosted) {
        return partnerHostedConfig.partnerHostedPath;
      }

      return path.startsWith(`/stores/${appMode.partnerId}`)
        ? '/embed'
        : `/embed/stores/${appMode.partnerId}`;
    }
    case 'embedded':
    case 'whiteLabel': {
      if (partnerHostedConfig.isPartnerHosted)
        return `/${partnerHostedConfig.partnerHostedPath}`;
      return path.startsWith(`/stores/${appMode.partnerId}`)
        ? '/embed'
        : `/embed/stores/${appMode.partnerId}`;
    }
    case 'operatorEmbed':
      return path.startsWith(`/stores/${appMode.storeId}`)
        ? `/operator_embed/operators/${appMode.operatorId}`
        : `/operator_embed/operators/${appMode.operatorId}/stores/${appMode.storeId}`;
    case 'brandEmbed':
      return `/brand_embed/brand_partners/${appMode.partnerId}`;
    case 'headless': {
      return `/headless`;
    }
  }
};

export const addUrlBase = (path: string, query?: object | string) => {
  const base = getUrlBase(path);

  return optionalQueryStringPath(`${base}${path}`, query);
};

export const paths = {
  getAppMode() {
    return appMode;
  },
  getPartnerHostedConfig() {
    return partnerHostedConfig;
  },
  root() {
    return '/';
  },
  embeddedMenu(query?: any) {
    return addUrlBase('/menu', query);
  },
  embeddedProduct(query?: any) {
    return addUrlBase('/', query);
  },
  embeddedProductDetail({
    product,
    fromMenu,
    bundleSpecialId,
    fromAllSpecials,
    fromSpecialId,
  }: {
    bundleSpecialId?: Id | null;
    fromAllSpecials?: boolean | null;
    fromMenu?: boolean;
    fromSpecialId?: Id | null;
    product: ProductNameAndSlugAndId;
  }) {
    const parameters = {
      fromMenu,
      bundleSpecialId,
      fromSpecialId,
      fromAllSpecials,
    };

    if (!fromAllSpecials) {
      delete parameters['fromAllSpecials'];
    }

    return addUrlBase(`/products/${product.id}/${product.slug}`, parameters);
  },
  embeddedLogin() {
    return addUrlBase('/login');
  },
  embeddedLogout() {
    return addUrlBase('/logout');
  },
  embeddedForgotPassword() {
    return addUrlBase('/forgot-password');
  },
  embeddedRegistration() {
    return addUrlBase('/register');
  },
  embeddedProfile() {
    return addUrlBase('/profile');
  },
  embeddedProfileEdit() {
    return addUrlBase('/profile/edit');
  },
  embeddedReservations() {
    return addUrlBase('/profile/reservations');
  },
  embeddedReservation(id: Id, query?: Record<string, unknown>) {
    return addUrlBase(`/profile/reservations/${id}`, query);
  },
  embeddedReservationReceipt(id: Id) {
    return addUrlBase(`/profile/reservations/${id}/receipt`);
  },
  embeddedReservationStore(id: Id) {
    return addUrlBase(`/profile/reservations/${id}/store`);
  },
  embeddedIdentification() {
    return addUrlBase('/profile/identification');
  },
  embeddedSettings() {
    return addUrlBase('/settings');
  },
  embeddedPassword() {
    return addUrlBase('/password');
  },
  embeddedReservationReview(reservationData?: any) {
    return addUrlBase(`/reservationReview?${qs.stringify(reservationData)}`);
  },
  embeddedReplaceCart() {
    return addUrlBase('/replaceCart');
  },
  embeddedCartAlerts() {
    return addUrlBase('/cartAlerts');
  },
  menu(store: NameAndSlugAndId, query?: any) {
    if (STORE_EMBED_APP_MODES.includes(appMode.type)) {
      return addUrlBase('/menu', query);
    } else if (['operatorEmbed', 'brandEmbed'].includes(appMode.type)) {
      return addUrlBase(`/stores/${store.id}/menu`, query);
    } else if (appMode.type === 'default') {
      return addUrlBase(
        `/stores/${store.id}/${store.slug ? store.slug : kebabCase(store.name)}`
      );
    }
  },
  product({
    product,
    slug,
    fromMenu,
    bundleSpecialId,
  }: {
    bundleSpecialId?: Id | null;
    fromMenu?: boolean;
    product: ProductNameAndSlugAndId;
    slug?: string;
  }) {
    // slug comes from server and used for canonical links for now
    // eventually, product links and canonicals should be the same
    return optionalQueryStringPath(
      `/products/${product.id}/${
        slug
          ? slug
          : kebabCase(`${product.brand ? product.brand : ''}${product.name}`)
      }`,
      { fromMenu, bundleSpecialId }
    );
  },
  products(query?: {}) {
    return addUrlBase(`/products`, query);
  },
  stores(query: any = '') {
    return addUrlBase('/stores', query);
  },
  store(
    store: NameAndSlugAndId,
    query: string | {} = '',
    slug?: string,
    isEmbeddedMode = false
  ) {
    if (isEmbeddedMode) {
      return addUrlBase(`/stores/${store.id}`, query);
    }
    return addUrlBase(
      `/stores/${store.id}/${slug ? slug : kebabCase(store.name)}`,
      query
    );
  },
  storeAbout(store: NameAndSlugAndId) {
    return `/stores/${store.id}/${kebabCase(store.name)}/about`;
  },
  storeReviews(store: NameAndSlugAndId, slug?: string) {
    return `/stores/${store.id}/${slug ? slug : kebabCase(store.name)}/reviews`;
  },
  storeMenu(store: NameAndSlugAndId, name: string) {
    return `/stores/${store.id}/${kebabCase(store.name)}/menu/${name}`;
  },
  storeProduct({
    store,
    product,
    fromMenu,
    bundleSpecialId,
    fromAllSpecials,
    fromSpecialId,
  }: {
    bundleSpecialId?: Id | null;
    fromAllSpecials?: boolean | null;
    fromMenu?: boolean;
    fromSpecialId?: Id | null;
    product: ProductNameAndSlugAndId;
    store: NameAndSlugAndId;
  }) {
    const parameters = {
      fromMenu,
      bundleSpecialId,
      fromSpecialId,
      fromAllSpecials,
    };

    if (!fromAllSpecials) {
      delete parameters['fromAllSpecials'];
    }

    return optionalQueryStringPath(
      `/stores/${store.id}/${kebabCase(store.name)}/products/${
        product.id
      }/${kebabCase(`${product.brand ? product.brand : ''}${product.name}`)}`,
      parameters
    );
  },
  brandProduct({
    brand,
    product,
    fromMenu,
    bundleSpecialId,
  }: {
    brand: NameAndSlugAndId;
    bundleSpecialId?: Id | null;
    fromMenu?: boolean;
    product: ProductNameAndSlugAndId;
  }) {
    return optionalQueryStringPath(
      `/brands/${brand.id}/${kebabCase(brand.name)}/products/${
        product.id
      }/${kebabCase(`${product.brand ? product.brand : ''}${product.name}`)}`,
      { fromMenu, bundleSpecialId }
    );
  },

  brandJaneGold() {
    return '/brands/janegold';
  },

  category(category: Categories, query?: {}) {
    return addUrlBase(`/categories/${category}`, query);
  },

  collections() {
    return '/collections';
  },

  cart() {
    return addUrlBase('/cart');
  },
  checkout() {
    return addUrlBase('/cart/checkout');
  },
  directory() {
    return addUrlBase('/directory');
  },
  dispensaries({
    country,
    state,
    city,
    coordinates,
    query,
  }: {
    city?: string;
    coordinates?: { lat: string; long: string; zoom: number };
    country?: string;
    query?: {};
    state?: string;
  }) {
    const cityParam = city && placeToParam(city);
    const stateParam = state && placeToParam(state);
    const countryParam = country && placeToParam(country);

    if (city) {
      return addUrlBase(
        `/dispensaries/${countryParam}/${stateParam}/${cityParam}`,
        query
      );
    }
    if (state) {
      return addUrlBase(`/dispensaries/${countryParam}/${stateParam}`, query);
    }
    if (country) {
      return addUrlBase(`/dispensaries/${countryParam}`, query);
    }

    if (coordinates) {
      return addUrlBase(
        `/dispensaries/coordinates/${coordinates.lat}/${coordinates.long}/${coordinates.zoom}`,
        query
      );
    }

    return addUrlBase('/dispensaries', query);
  },
  guestCheckout() {
    return addUrlBase('/cart/guest_checkout');
  },
  partnerDispensaries() {
    return 'https://dispensaries.iheartjane.com';
  },
  partnerBrands() {
    return `${this.partnerDispensaries()}/brands`;
  },
  brandsLanding(query?: {}) {
    return addUrlBase(`/brands`, query);
  },
  brandProducts(brandId: Id, brandName: string, query?: {}) {
    return addUrlBase(`/brands/${brandId}/${kebabCase(brandName)}`, query);
  },
  invoice(invoiceId: Id) {
    return addUrlBase(`/invoice/${invoiceId}`);
  },
  profile() {
    return addUrlBase('/profile');
  },
  profileSettings() {
    return addUrlBase('/profile/settings');
  },
  profileEdit() {
    return addUrlBase('/profile/edit');
  },
  profilePassword() {
    return addUrlBase('/profile/password');
  },
  guestReservationReceipt(cart_uuid: string, query?: {}) {
    return addUrlBase(`/reservation_receipt/${cart_uuid}`, query);
  },
  reservations() {
    return '/profile/reservations';
  },
  reservation(id: Id, query?: Record<string, unknown>) {
    return addUrlBase(`/profile/reservations/${id}`, query);
  },
  reservationStore(id: Id) {
    return `/profile/reservations/${id}/store`;
  },
  reservationReceipt(id: Id) {
    return `/profile/reservations/${id}/receipt`;
  },
  special(
    special: Pick<StoreSpecial, 'id' | 'title'>,
    store: NameAndSlugAndId
  ) {
    const specialsPath = `/specials/${special.id}/${kebabCase(special.title)}`;

    if (STORE_EMBED_APP_MODES.includes(appMode.type)) {
      return addUrlBase(specialsPath);
    } else {
      let storePath = `stores/${store.id}`;
      if (includeStoreSlug()) {
        storePath += `/${store?.slug || kebabCase(store?.name)}`;
      }
      return addUrlBase(`/${storePath}${specialsPath}`);
    }
  },
  specials(store: NameAndSlugAndId) {
    if (STORE_EMBED_APP_MODES.includes(appMode.type)) {
      return addUrlBase('/specials');
    } else {
      let storePath = `stores/${store.id}`;
      if (includeStoreSlug()) {
        storePath += `/${store?.slug || kebabCase(store?.name)}`;
      }
      return addUrlBase(`/${storePath}/specials`);
    }
  },
  newProductReview({
    productId,
    productName,
    storeId,
    storeName,
    rating,
  }: {
    productId: Id;
    productName: string;
    rating?: number;
    storeId?: Id;
    storeName?: string;
  }) {
    return addUrlBase(
      `/products/${productId}/${encodeURIComponent(productName)}/reviews/new/`,
      { storeId, storeName, rating }
    );
  },
  identification() {
    return addUrlBase('/profile/identification');
  },
  termsOfUse(termsType: TermsOfServiceVariants = 'customer') {
    switch (termsType) {
      case 'customer':
        return '/terms';
      case 'brands':
        return '/brands-terms';
      case 'data':
        return '/data-terms';
      case 'business':
        return '/dispensary-terms';
      default:
        return '/terms';
    }
  },
  accessibility() {
    return '/ada';
  },
  privacyPolicy() {
    return '/privacy-policy';
  },
  doNotSellMyInformation() {
    return '/do-not-sell-my-information';
  },
  contactUs() {
    return '/profile/contactUs';
  },
  forgotPassword() {
    return '/forgot-password';
  },
  resetPassword() {
    return '/reset-password';
  },
  login() {
    return '/login';
  },
  maintenance() {
    return '/maintenance';
  },
  brandEmbedProducts(query: string | {} = '') {
    return addUrlBase('/products', query);
  },
  brandEmbedStores(query: string | {} = '') {
    return addUrlBase('/stores', query);
  },
  brandEmbedStore(store: NameAndSlugAndId, query: string | {} = '') {
    return addUrlBase(`/stores/${store.id}/${kebabCase(store.name)}`, query);
  },
  brandEmbedStoreProduct(
    store: NameAndSlugAndId,
    product: ProductNameAndSlugAndId,
    fromMenu?: boolean,
    bundleSpecialId?: Id | null,
    fromAllSpecials?: boolean | null,
    fromSpecialId?: Id | null
  ) {
    const parameters = { fromSpecialId, fromAllSpecials };

    if (!fromAllSpecials) {
      delete parameters['fromAllSpecials'];
    }

    return addUrlBase(
      `/stores/${store.id}/${kebabCase(store.name)}/products/${
        product.id
      }/${kebabCase(`${product.brand ? product.brand : ''}${product.name}`)}`,
      parameters
    );
  },
} as const;
