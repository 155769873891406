import { AlgoliaBrandsSchema } from '@jane/search/types';
import type {
  AlgoliaBrand,
  SearchAlgoliaBrandsParams,
} from '@jane/search/types';

import { search } from './client';

export async function searchAlgoliaBrands({
  ids,
  hitsPerPage,
  page,
  currentSort,
  searchText = '',
}: SearchAlgoliaBrandsParams) {
  return await search<AlgoliaBrand>(
    {
      indexPrefix: 'product-brand-',
      hitsPerPage,
      page,
      currentSort,
      searchText,
      filters: {
        objectID: ids,
      },
    },
    AlgoliaBrandsSchema
  );
}
