import styled from '@emotion/styled';

import { getResponsiveImageAttributes } from '@jane/shared/reefer';

const StyledImage = styled.img({
  transition: '0.3s opacity',
  width: '100%',
  height: '100%',
});

interface Props {
  alt: string;
  fallbackWidth?: number;
  src: string;
}

export const ProductImage = ({
  src,
  alt,
  fallbackWidth = 200,
}: {
  alt: string;
  fallbackWidth?: number;
  src: string;
}) => {
  return <Image src={src} alt={alt} fallbackWidth={fallbackWidth} />;
};

const Image = ({ src, fallbackWidth, alt }: Props) => {
  const { srcSet, imageSrc } = getResponsiveImageAttributes({
    src,
    fallbackWidth,
  });

  return (
    <StyledImage src={imageSrc} srcSet={srcSet} alt={alt} loading="lazy" />
  );
};
