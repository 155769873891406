import type { SVGProps } from 'react';

import { Icon } from '../../icon/icon';
import type { StaticIconProps } from '../../icon/icon.types';

const HoundDog = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="64"
    height="64"
    viewBox="0 0 64 64"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <circle cx={32} cy={32} r={32} fill="#5E1FF0" />
    <circle cx={32} cy={32} r={32} fill="white" fillOpacity={0.8} />
    <ellipse
      cx={15.9282}
      cy={23.8564}
      rx={8}
      ry={16}
      transform="rotate(30 15.9282 23.8564)"
      fill="#5E1FF0"
    />
    <ellipse
      cx={15.9282}
      cy={23.8564}
      rx={8}
      ry={16}
      transform="rotate(30 15.9282 23.8564)"
      fill="black"
      fillOpacity={0.2}
    />
    <ellipse
      cx={47.9282}
      cy={23.8564}
      rx={8}
      ry={16}
      transform="rotate(-30 47.9282 23.8564)"
      fill="#5E1FF0"
    />
    <ellipse
      cx={47.9282}
      cy={23.8564}
      rx={8}
      ry={16}
      transform="rotate(-30 47.9282 23.8564)"
      fill="black"
      fillOpacity={0.2}
    />
    <path
      d="M40 29C40 40.0457 34.6274 49 28 49C21.3726 49 16 40.0457 16 29C16 17.9543 21.3726 9 28 9C34.6274 9 40 17.9543 40 29Z"
      fill="#5E1FF0"
    />
    <path
      d="M48 29C48 40.0457 42.6274 49 36 49C29.3726 49 24 40.0457 24 29C24 17.9543 29.3726 9 36 9C42.6274 9 48 17.9543 48 29Z"
      fill="#5E1FF0"
    />
    <path
      d="M42 45C42 50.5228 37.5228 55 32 55C26.4772 55 22 50.5228 22 45C22 39.4772 26.4772 35 32 35C37.5228 35 42 39.4772 42 45Z"
      fill="#5E1FF0"
    />
    <path
      d="M42 45C42 50.5228 37.5228 55 32 55C26.4772 55 22 50.5228 22 45C22 39.4772 26.4772 35 32 35C37.5228 35 42 39.4772 42 45Z"
      fill="black"
      fillOpacity={0.2}
    />
    <rect x={28} y={42} width={8} height={16} rx={4} fill="#FF92E0" />
    <path
      d="M33.4209 43.934C31.7736 49.9135 27.1158 53.8949 23.0174 52.8267C18.9189 51.7586 16.9318 46.0454 18.5791 40.066C20.2264 34.0865 24.8842 30.1051 28.9826 31.1733C33.0811 32.2414 35.0682 37.9546 33.4209 43.934Z"
      fill="#5E1FF0"
    />
    <path
      d="M30.5791 43.934C32.2264 49.9135 36.8842 53.8949 40.9826 52.8267C45.0811 51.7586 47.0682 46.0454 45.4209 40.066C43.7736 34.0865 39.1158 30.1051 35.0174 31.1733C30.9189 32.2414 28.9318 37.9546 30.5791 43.934Z"
      fill="#5E1FF0"
    />
    <circle cx={24} cy={23} r={6} fill="white" />
    <circle cx={40} cy={23} r={6} fill="white" />
    <circle cx={24} cy={23} r={4} fill="#FF92E0" />
    <circle cx={40} cy={23} r={4} fill="#FF92E0" />
    <circle cx={24} cy={23} r={2} fill="#0E0E0E" />
    <circle cx={32} cy={38} r={6} fill="#0E0E0E" />
    <circle cx={40} cy={23} r={2} fill="#0E0E0E" />
    <circle cx={25} cy={23} r={1} fill="white" />
    <circle cx={41} cy={23} r={1} fill="white" />
    <rect x={18} y={17} width={28} height={6} fill="#5E1FF0" />
  </svg>
);
export const HoundDogAvatar = ({ size = 'xl', ...props }: StaticIconProps) => {
  return <Icon icon={HoundDog} {...props} size={size} color={undefined} />;
};
