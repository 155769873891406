import isEqual from 'lodash/isEqual';
import { useMemo, useRef } from 'react';

export const useDeepCompareMemoize = (value: unknown) => {
  const ref = useRef<any>();

  if (!isEqual(value, ref.current)) {
    ref.current = value;
  }

  return ref.current;
};

export const useDeepCompareMemo = <T>(
  callback: () => T,
  dependencies: unknown[]
) => {
  return useMemo<T>(callback, useDeepCompareMemoize(dependencies));
};
