import { useJaneGoldData } from '@jane/shared-ecomm/hooks';
import { Accordion } from '@jane/shared/components';
import {
  Box,
  Button,
  Flex,
  JaneGoldIcon,
  Skeleton,
  Typography,
  useMobileMediaQuery,
} from '@jane/shared/reefer';
import { getJaneGoldFormattedLabel } from '@jane/shared/util';

import { BankAccountDetails } from '../../bankAccountDetails';
import type { JaneGoldCartCalloutProps } from '../janeGoldCallout.types';
import { JaneGoldFeeBreakdown } from '../janeGoldFeeBreakdown/janeGoldFeeBreakdown';
import { JaneGoldLearnMoreLink } from '../janeGoldLearnMoreLink';
import { JaneGoldTermsLink } from '../janeGoldTermsLink';
import {
  StyledCartButtonWrapper,
  StyledCartContainer,
  StyledCartDescriptionWrapper,
  StyledCartInnerContainer,
} from './janeGoldCartCallout.styles';

/**
 * Cart variant of the Jane Gold callout action component. If the user doesn't have a linked bank, it will display a `Learn more` link and `Link bank account` button. If the user has a linked bank, it will list the linked bank information and hide the `Link bank account` button.
 */
export const JaneGoldCartCallout = ({
  buttonLabel,
  description,
  discountLabel,
  feeLabel,
  hasBreakdown,
  headlessLoginUrl,
  onLinkAccountClick,
  totalLabel,
  title,
}: JaneGoldCartCalloutProps) => {
  const { isGuestUser, isLoading, selectedBankAccount, userHasLinkedBank } =
    useJaneGoldData();
  const isMobile = useMobileMediaQuery({});
  const formattedLabel =
    typeof discountLabel === 'number'
      ? getJaneGoldFormattedLabel(discountLabel)
      : discountLabel;
  const shouldShowHeadlessLogin = isGuestUser && headlessLoginUrl;

  return (
    <Box background="grays-white">
      {hasBreakdown ? (
        <Accordion.Item id="jane-gold">
          <Accordion.Heading px={isMobile ? 24 : 40} py={20}>
            <Flex alignItems="center">
              <Flex alignItems="center">
                <div>
                  <JaneGoldIcon mr={16} />
                </div>
                <Typography variant="body-bold">{title}</Typography>
              </Flex>
              <Flex ml="auto">
                <Typography variant="body-bold" mx={16}>
                  {formattedLabel}
                </Typography>
                <Accordion.Indicator iconSize="md" />
              </Flex>
            </Flex>
          </Accordion.Heading>
          <Accordion.Content px={isMobile ? 24 : 40} pb={24} destroyOnClose>
            <Box pl={40}>
              <JaneGoldFeeBreakdown
                buttonLabel={buttonLabel}
                description={description}
                discountLabel={discountLabel}
                feeLabel={feeLabel}
                onLinkAccountClick={onLinkAccountClick}
                totalLabel={totalLabel}
                variant="cart"
              />
            </Box>
          </Accordion.Content>
        </Accordion.Item>
      ) : (
        <StyledCartContainer>
          <Flex
            mb={20}
            flexDirection="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <Flex alignItems="center">
              <JaneGoldIcon mr={16} altText="jane-gold-icon" size="md" />
              <Typography variant="body-bold">{title}</Typography>
            </Flex>
            <Typography variant="body-bold">{formattedLabel}</Typography>
          </Flex>

          <StyledCartInnerContainer>
            <StyledCartDescriptionWrapper userHasLinkedBank={userHasLinkedBank}>
              {!isLoading && (
                <>
                  <Typography variant="body" as="span">
                    {description}
                    &nbsp;
                    {!userHasLinkedBank ? (
                      <JaneGoldTermsLink />
                    ) : (
                      <JaneGoldLearnMoreLink userHasLinkedBank />
                    )}
                  </Typography>
                  {userHasLinkedBank && selectedBankAccount && (
                    <BankAccountDetails
                      bankAccount={selectedBankAccount}
                      key={selectedBankAccount.bank_account_id}
                      mt={24}
                    />
                  )}
                </>
              )}

              {isLoading && (
                <Box>
                  <Skeleton animate>
                    <Skeleton.Bone height="45px" />
                    <Skeleton.Bone height="96px" mt={24} />
                  </Skeleton>
                </Box>
              )}
            </StyledCartDescriptionWrapper>

            {!isLoading && shouldShowHeadlessLogin && (
              <StyledCartButtonWrapper>
                <Button
                  full
                  href={headlessLoginUrl}
                  target="_parent"
                  variant="secondary"
                  label="Sign in to link bank account"
                />
              </StyledCartButtonWrapper>
            )}

            {!(isLoading || userHasLinkedBank || shouldShowHeadlessLogin) && (
              <StyledCartButtonWrapper>
                <Button
                  full
                  onClick={onLinkAccountClick}
                  variant="secondary"
                  label="Link bank account"
                />
              </StyledCartButtonWrapper>
            )}
          </StyledCartInnerContainer>
        </StyledCartContainer>
      )}
    </Box>
  );
};
