import { useEffect } from 'react';

import { useUserPreferences } from '@jane/shared-ecomm/providers';
import type { StoreFulfillmentTypes } from '@jane/shared/models';

import { AddressAutocomplete } from '../addressAutocomplete';
import { AddressInputWrapper } from './userPreferencesModal.styles';

export const UserPreferencesLocation = ({
  handleSetAddress,
  fulfillmentType,
}: {
  fulfillmentType?: StoreFulfillmentTypes;
  handleSetAddress: (address: string) => void;
}) => {
  const { userLocation } = useUserPreferences();

  const initialAddress = [
    userLocation?.street,
    userLocation?.cityState,
    userLocation?.zipcode,
  ]
    .filter(Boolean)
    .join(', ');

  const handleOnSelection = (address: string) => {
    handleSetAddress(address);
  };

  useEffect(() => {
    if (initialAddress) {
      handleSetAddress(initialAddress);
    }
  }, []);

  return (
    <AddressInputWrapper flexDirection="column">
      <AddressAutocomplete
        clearIcon
        defaultValue={userLocation.cityState ? initialAddress : ''}
        disableMobileInputStyling
        locationIcon
        name="user-location"
        onSelection={handleOnSelection}
        placeholder={
          fulfillmentType === 'pickup'
            ? 'Enter city, address, etc.'
            : 'Enter delivery address'
        }
      />
    </AddressInputWrapper>
  );
};
