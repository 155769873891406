import type {
  RadioFieldGroupProps,
  RadioFieldOption,
} from '../fields/field.types';
import { RadioFieldGroupInternal } from '../fields/radioFieldGroup/radioFieldGroup';
import type { FormFieldProps } from '../formField.types';
import { FormFieldWrapper } from '../formFieldWrapper/formFieldWrapper';

export interface FormRadioFieldGroupProps
  extends Omit<RadioFieldGroupProps, 'children'>,
    Pick<FormFieldProps, 'required' | 'shouldUnregister' | 'validate'> {}

export type FormRadioFieldGroupOption = RadioFieldOption;

/**
 * Radio fields allow users to choose one option from a set of options.
 *
 * Use this component *inside forms* for inputs of `type`:
 * `radio`
 *
 * `Form.RadioFieldGroup` options support a `wrapper` attribute for displaying individual inputs in a more complex UI if needed.
 * An example illustrating the usage of `options` with `wrappers` can be seen [here](/story/components-table-examples-radio-column--default).
 *
 * **NOTE: DO NOT USE THIS COMPONENT OUTSIDE FORMS**
 *
 * *For a similar component for use outside forms, see [`RadioFieldGroup`](/story/components-forms-fields-radiofieldgroup--default).*
 */
export function FormRadioFieldGroup({
  autoFocus = false,
  defaultChecked,
  disabled = false,
  helperText,
  labelHidden = false,
  name,
  onBlur,
  onChange,
  required = false,
  shouldUnregister,
  'data-testid': testId,
  validate,
  options,
  optionWidth = 'auto',
  row = false,
  ...props
}: FormRadioFieldGroupProps) {
  return (
    <FormFieldWrapper
      defaultValue={defaultChecked}
      name={name}
      render={({ children, field }) => (
        <RadioFieldGroupInternal
          options={options}
          autoFocus={autoFocus}
          defaultChecked={defaultChecked}
          disabled={disabled}
          helperText={helperText}
          labelHidden={labelHidden}
          name={field.name}
          onBlur={(fieldValue) => {
            onBlur && onBlur(fieldValue);
            field.onBlur();
          }}
          onChange={(fieldValue) => {
            onChange && onChange(fieldValue);
            field.onChange(fieldValue);
          }}
          onUpdate={onChange}
          ref={field.ref}
          data-testid={testId}
          optionWidth={optionWidth}
          externalValue={field.value}
          row={row}
          {...props}
        >
          {children}
        </RadioFieldGroupInternal>
      )}
      requiredAllowFalse={required}
      shouldUnregister={shouldUnregister}
      validate={validate}
    />
  );
}
