import type { SelectFilterGroup } from '@jane/search/types';
import { FlowerIcon, SativaIcon, TinctureIcon } from '@jane/shared/reefer';

export const nestedFilterGroup: SelectFilterGroup = {
  key: 'root_types',
  label: 'Categories',
  nested: true,
  showInFilterBar: true,
  type: 'multiselect',
  items: [
    {
      count: 30,
      icon: <FlowerIcon />,
      label: 'flower',
      subItems: [
        {
          count: 10,
          label: 'ground flower',
          value: 'ground flower',
        },
        {
          count: 10,
          label: 'indoor',
          value: 'indoor',
        },
        {
          count: 10,
          label: 'sun grown',
          value: 'sun grown',
        },
      ],
      value: 'flower',
    },
    {
      count: 10,
      icon: <TinctureIcon />,
      label: 'Tinctures',
      value: 'tinctures',
    },
  ],
};

export const filterGroup: SelectFilterGroup = {
  key: 'category',
  label: 'Lineage',
  showInFilterBar: true,
  type: 'multiselect',
  items: [
    {
      count: 10,
      label: 'indica',
      value: 'indica',
    },
    {
      count: 10,
      icon: <SativaIcon color="ember" />,
      label: 'Sativa',
      value: 'Sativa',
    },
  ],
};
