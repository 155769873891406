import branch from 'branch-sdk';

import { trackBranchIoError } from '@jane/dm/internal';
import { config } from '@jane/shared/config';

let isBranchIoInitialized = false;

export const initializeBranchIo = () => {
  const { branchIoKey } = config;
  branch.init(branchIoKey, {}, (branchError) => {
    if (branchError) {
      trackBranchIoError({ message: branchError });
    }
  });
  isBranchIoInitialized = true;
};

interface CheckoutData {
  transaction_id: string;
}

export const trackBranchCheckout = (cartId: string): boolean => {
  if (!isBranchIoInitialized) return false;
  const eventData: CheckoutData = { transaction_id: cartId };
  branch.logEvent(
    'PURCHASE',
    eventData,
    undefined,
    undefined,
    (branchError) => {
      if (branchError) {
        trackBranchIoError({ message: branchError });
      }
    }
  );
  return true;
};
