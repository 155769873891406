import isEqual from 'lodash/isEqual';
import pick from 'lodash/pick';

import { identify as identifyOnErrorTracker } from '@jane/shared/util';

function identifyBusinessUser({ id }) {
  const businessId = `business:${id}`;

  identifyOnErrorTracker({ userId: businessId });
}

function identifyCustomerUser({ id }) {
  const customerId = `customer:${id}`;

  identifyOnErrorTracker({ userId: customerId });
}

export default function identifyAtFirstOpportunity() {
  let lastUser = {};

  return (user) => {
    if (isEqual(user, lastUser)) return;

    lastUser = user;

    if (user.id && user.nickname) {
      identifyCustomerUser(user);
    } else if (user.id) {
      identifyBusinessUser(user);
    }
  };
}

export const identifyBusinessUserAtFirstOpportunity = (store) => {
  const identify = identifyAtFirstOpportunity();

  store.subscribe(() => {
    const user = store.getState().businessUser;
    identify(user);
  });
};

export const identifyCustomerUserAtFirstOpportunity = (store) => {
  const identify = identifyAtFirstOpportunity();

  store.subscribe(() => {
    const user = pick(store.getState().customer, ['id', 'email', 'nickname']);
    identify(user);
  });
};
