import styled from '@emotion/styled';

import { mediaQueries, shadows, spacing } from '@jane/shared/reefer-emotion';

export const StyledFilterList = styled.ul(
  ({ query, subList }: { query?: boolean; subList?: boolean }) => ({
    maxHeight: '360px',
    overflow: 'auto',
    position: 'relative',
    '::-webkit-scrollbar': {
      display: 'none',
    },
    [mediaQueries.tablet('min')]: {
      maxHeight: '420px',
    },
    // ensures hover effect is seamless on all list items
    '> li:first-of-type > div > div > div': {
      // less padding top for sublists/typeahead items
      ...spacing({ pt: subList || query ? 0 : 12 }),
    },
    '> li:first-of-type > div > span': {
      // center the subcategory toggle arrow if there is one
      top: '24px',
    },
    '> li:first-of-type > button': {
      ...spacing({ pt: 20 }),
    },
    '> li:last-of-type > button': {
      ...spacing({ pb: 20 }),
    },
  })
);

export const FilterListItem = styled.li(({ theme }) => ({
  listStyle: 'none',
  transition: 'all 250ms',
  mark: {
    color: theme.colors.primary.main,
    backgroundColor: 'transparent',
  },
}));

export const FilterListButton = styled.button({
  outline: 'none',
  borderWidth: 0,
  textDecoration: 'none',
  backgroundColor: 'transparent',
  width: '100%',
  height: '100%',
  textAlign: 'left',
  ...spacing({ py: 12, px: 24, m: 0 }),
  display: 'block',
  color: 'inherit',
  '&:hover, &:focus': {
    backgroundColor: 'rgba(0, 0, 0, 0.05)',
  },
  cursor: 'pointer',
  '> span, p': {
    cursor: 'pointer',
  },
});

export const FilterListButtonLabels = styled.span({
  display: 'flex',
  justifyContent: 'space-between',
  pointerEvents: 'none',
});

export const FilterListCheckbox = styled.div(
  ({ subItem, hasSubItems }: { hasSubItems?: boolean; subItem?: boolean }) => ({
    position: 'relative',
    width: '100%',
    '> div > div': {
      cursor: 'pointer',
      '&:hover, &:focus': {
        backgroundColor: 'rgba(0, 0, 0, 0.05)',
      },
    },
    // * label wrapper --> overrides ensure anywhere is clickable
    '> div > div > div:first-of-type': {
      height: '100%',
      width: '100%',
      label: {
        position: 'relative',
        width: '100%',
        height: '100%',
        cursor: 'pointer',
        color: 'inherit',
        ...spacing({ py: 12, m: 0, pl: 16, pr: subItem ? 24 : 16 }),
        ...(hasSubItems && {
          maxWidth: 'calc(100% - 56px)',
        }),
      },
    },
    // * checkbox wrapper ^^
    '> div > div > div:nth-of-type(2)': {
      width: '100%',
      maxWidth: subItem ? '72px' : '48px',
      height: 'auto',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
      input: {
        position: 'relative',
        width: '100%',
        height: '100%',
        cursor: 'pointer',
        ...spacing({ py: 12 }),
      },
      '> div': {
        top: '50%',
        transform: 'translateY(-50%)',
      },
    },
  })
);

export const MobileFilterWrapper = styled.div({
  '> li': {
    boxShadow: shadows.dividerTop,
  },
  boxShadow: shadows.divider,
});

export const FilterListCheckboxArrow = styled.span({
  position: 'absolute',
  top: '12px',
  right: '20px',
  'div:after': {
    content: '""',
    position: 'absolute',
    top: '-12px',
    left: '-12px',
    height: '48px',
    width: '56px',
  },
});

export const StyledButtonWrapper = styled.div(({ theme }) => ({
  position: 'sticky',
  bottom: 0,
  background: theme.colors.grays.white,
}));

interface FilterButtonGridProps {
  itemsPerRow: number;
  mobileItemsPerRow: number;
}

export const FilterButtonGrid = styled.div<FilterButtonGridProps>(
  {
    display: 'grid',
    gridGap: 16,
    gridAutoRows: '1fr',
    width: '100%',
  },
  ({ itemsPerRow, mobileItemsPerRow }) => ({
    gridTemplateColumns: `repeat(1, minmax(0, 1fr))`,
    [mediaQueries.mobile('md', 'min')]: {
      gridTemplateColumns: `repeat(${mobileItemsPerRow}, minmax(0, 1fr))`,
    },
    [mediaQueries.tablet('min')]: {
      gridTemplateColumns: `repeat(${itemsPerRow}, minmax(0, 1fr))`,
    },
  })
);
