import type { Reducer } from 'redux';

import type { DeepReadonly, Id } from '@jane/shared/models';

import { fetchItems } from '../../common/redux/pagination';
import { createStandardAction } from '../../redux-util';
import { NotificationsService } from '../../services/notifications';
import { ReviewsSource } from '../../sources/reviews';
import type { CustomerThunkActionCreator } from '../redux';
import type { CustomerAction } from './types';

const PAGE_SIZE = 25;

export const GET_FIRST_PAGE = 'reviews/get-first-page';

export const getFirstPage: CustomerThunkActionCreator<{
  date: number;
  id: Id;
}> = (params) => (dispatch) => {
  dispatch({ type: GET_FIRST_PAGE });

  dispatch(
    fetchItems({
      source: ReviewsSource,
      params,
      batchSize: PAGE_SIZE,
    })
  ).then(
    (result: { reviews: Review[] }) => dispatch(getFirstPageSuccess(result)),
    (err: string) => dispatch(getError(err))
  );
};

export const GET_NEXT_PAGE = 'reviews/get-next-page';
export const getNextPage: CustomerThunkActionCreator<{
  date: number;
  id: Id;
}> = (params) => (dispatch) => {
  dispatch({ type: GET_NEXT_PAGE });

  dispatch(
    fetchItems({
      source: ReviewsSource,
      params,
      batchSize: PAGE_SIZE,
    })
  ).then(
    (result: { reviews: Review[] }) => dispatch(getNextPageSuccess(result)),
    (err: string) => dispatch(getError(err))
  );
};

export const GET_ERROR = 'reviews/get-error';
export const getError: CustomerThunkActionCreator<string> =
  (err) => (dispatch) => {
    dispatch({ type: GET_ERROR, payload: err });
    NotificationsService.error(err);
  };

export interface GetPageSuccessProps {
  reviews: Review[];
}

export const GET_FIRST_PAGE_SUCCESS = 'reviews/get-first-page-success';
export const getFirstPageSuccess = createStandardAction(
  GET_FIRST_PAGE_SUCCESS
)<GetPageSuccessProps>();

export const GET_NEXT_PAGE_SUCCESS = 'reviews/get-next-page-success';
export const getNextPageSuccess = createStandardAction(
  GET_NEXT_PAGE_SUCCESS
)<GetPageSuccessProps>();

export type ReviewsActions =
  | { type: typeof GET_FIRST_PAGE }
  | ReturnType<typeof getFirstPageSuccess>
  | { type: typeof GET_NEXT_PAGE }
  | ReturnType<typeof getNextPageSuccess>
  | { payload: string; type: typeof GET_ERROR };

export type Review = {
  avatar: string;
  body: string;
  created_at: number;
  id: Id;
  username: string;
  value: string;
};

export type ReviewsState = DeepReadonly<{
  areReviewsLoading: boolean;
  reviews: Review[];
}>;

const getInitialState = (): ReviewsState => ({
  areReviewsLoading: false,
  reviews: [],
});

export const reviewsReducer: Reducer<ReviewsState, CustomerAction> = (
  state = getInitialState(),
  action
) => {
  switch (action.type) {
    case GET_ERROR:
      return { ...state, areReviewsLoading: false };

    case GET_FIRST_PAGE:
      return {
        ...state,
        areReviewsLoading: true,
      };

    case GET_FIRST_PAGE_SUCCESS:
      return {
        ...state,
        areReviewsLoading: false,
        reviews: action.payload.reviews,
      };
    case GET_NEXT_PAGE_SUCCESS:
      return {
        ...state,
        areReviewsLoading: false,
        reviews: [...state.reviews, ...action.payload.reviews],
      };
  }

  return state;
};
