import { useEffect } from 'react';
import { useLocation, useNavigationType } from 'react-router-dom';

export const useScrollToApp = (rootElementId = 'app') => {
  const navigationType = useNavigationType();
  const location = useLocation();

  useEffect(() => {
    const element = document.getElementById(rootElementId);

    if (element && navigationType === 'PUSH') {
      requestAnimationFrame(() => {
        element.scrollIntoView();
      });
    }
  }, [location.pathname]);
};
