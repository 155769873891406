import omit from 'lodash/omit';

export const deserializeCannabinoids = (
  data: any,
  mode: 'product' | 'productSearchWithLocation' | 'menuProduct'
): any => {
  if (mode === 'menuProduct') {
    const deserialized = {
      ...data,
      primaryPercentCBD: data['primaryPercentCBD'] || data['percent_cbd'],
      primaryPercentTHC: data['primaryPercentTHC'] || data['percent_thc'],
      secondaryPercentCBD:
        data['secondaryPercentCBD'] || data['product_percent_cbd'],
      secondaryPercentTHC:
        data['secondaryPercentTHC'] || data['product_percent_thc'],
      percentTHCA: data['percentTHCA'] || data['percent_thca'],
      percentCBDA: data['percentCBDA'] || data['percent_cbda'],
      percentTAC: data['percentTAC'] || data['percent_tac'],
    };

    if (!data.id) {
      deserialized.id = data.product_id;
    }

    return omit(deserialized, [
      'percent_cbd',
      'percent_thc',
      'percent_tac',
      'product_percent_cbd',
      'product_percent_thc',
    ]);
  } else if (mode === 'product') {
    const deserialized = {
      ...data,
      secondaryPercentCBD: data['secondaryPercentCBD'] || data['percent_cbd'],
      secondaryPercentTHC: data['secondaryPercentTHC'] || data['percent_thc'],
    };

    return omit(deserialized, ['percent_cbd', 'percent_thc', 'percent_tac']);
  } else if (mode === 'productSearchWithLocation') {
    const deserialized = {
      ...data,
      secondaryPercentCBD:
        data['secondaryPercentCBD'] || data['product_percent_cbd'],
      secondaryPercentTHC:
        data['secondaryPercentTHC'] || data['product_percent_thc'],
    };

    return omit(deserialized, [
      'percent_cbd',
      'percent_thc',
      'percent_tac',
      'product_percent_cbd',
      'product_percent_thc',
    ]);
  }

  throw new ReferenceError(
    'mode of "product", "menuProduct", or "productSearchWithLocation" is required'
  );
};
