import { z } from 'zod';

import { zBrandSpecialUserSegmentIds } from './brandSpecials';
import { zId } from './id';

export const zProductLineageEnum = z.enum([
  'cbd',
  'hybrid',
  'indica',
  'sativa',
]);

export const zProductKindEnum = z.enum([
  'edible',
  'extract',
  'flower',
  'gear',
  'grow',
  'merch',
  'pre-roll',
  'tincture',
  'topical',
  'vape',
]);

export const zProductKindSoldByWeightEnum = z.enum([
  'extract',
  'flower',
  'vape',
]);

export const zProductTypeEnum = z.enum([
  'cbd',
  'edible',
  'extract',
  'flower',
  'gear',
  'grow',
  'hybrid',
  'indica',
  'merch',
  'pre-roll',
  'sativa',
  'tincture',
  'topical',
  'vape',
]);

/** Taken from `api/app/models/cart_limit_rule.rb` */
export const zProductUnitsEnum = z.enum(['fl_oz', 'g', 'mg', 'ml', 'oz']);

export const zEffect = z.object({
  id: zId,
  title: z.string(),
  value: z.string(),
});

export const zFlavorEnum = z.enum([
  'citrusy',
  'spicy',
  'floral',
  'hoppy',
  'earthy',
  'herbal',
]);

export const zTerpeneEnum = z.enum([
  'limonene',
  'nerolidol',
  'valencene',
  'caryophyllene',
  'caryophyllene oxide',
  'sabinene',
  'linalool',
  'geraniol',
  'geranyl acetate',
  'humulene',
  'myrcene',
  'pinene',
  'camphene',
  'camphor',
  'carene',
  'cedrene',
  'guaiol',
  'eucalyptol',
  'terpineol',
  'terpinolene',
  'phellandrene',
  'bisabolol',
  'borneol',
  'cymene',
  'fenchol',
  'menthol',
  'isoborneol',
  'isopulegol',
  'pulegone',
  'ocimene',
  'phytol',
]);

export const zFlavor = z.object({
  id: zId,
  title: z.string(),
  value: z.string(),
});

const zAlias = z.object({
  id: zId,
  name: z.string(),
});

export const zBusinessLicense = z.object({
  id: zId,
  business_name: z.string(),
  license_number: z.string(),
  state: z.string(),
});

export const zPhoto = z.object({
  id: zId,
  position: z.number(),
  urls: z.object({
    extraLarge: z.string(),
    medium: z.string(),
    original: z.string(),
    small: z.string(),
  }),
});

const zLocalizedContent = z
  .object({
    brand_subtype: z.string().nullable(),
    country: z.string().nullable(),
    description: z.string().nullable(),
    image_urls: z.array(z.string()),
    lineage: zProductLineageEnum.nullable(),
    name: z.string().nullable(),
    photos: z.array(zPhoto),
    subdivision: z.string().nullable(),
  })
  .partial();

const zBrandPriceIdSpecials = z
  .object({
    brand_special_id: z.number(),
    discount_label: z.string(),
    price: z.string(),
  })
  .partial();

export const zBrandSpecialPrices = z
  .object({
    each: zBrandPriceIdSpecials.nullable(),
    eighth_ounce: zBrandPriceIdSpecials.nullable(),
    gram: zBrandPriceIdSpecials.nullable(),
    half_gram: zBrandPriceIdSpecials.nullable(),
    half_ounce: zBrandPriceIdSpecials.nullable(),
    ounce: zBrandPriceIdSpecials.nullable(),
    quarter_ounce: zBrandPriceIdSpecials.nullable(),
    two_gram: zBrandPriceIdSpecials.nullable(),
  })
  .partial();

const zProductOptionalProperties = z
  .object({
    aggregate_rating: z.number().nullable(),
    aliases: z.array(zAlias),
    allergens: z.array(z.string()),
    amount: z.string().nullable(),
    applicable_brand_special_ids: z.array(z.number()),
    applicable_brand_specials_included_user_segments:
      zBrandSpecialUserSegmentIds,
    applicable_brand_specials_excluded_user_segments:
      zBrandSpecialUserSegmentIds,
    brand_special_prices: zBrandSpecialPrices.nullable(),
    brand_subtype: z.string().nullable(),
    business_licenses: z.array(zBusinessLicense).nullable(),
    category: zProductLineageEnum.nullable(),
    cbd_dosage_milligrams: z.number().nullish(),
    description: z.string(),
    dosage: z.string().nullable(),
    effects: z.array(zEffect),
    flavors: z.array(zFlavor),
    has_brand_discount: z.boolean().nullable(),
    image_urls: z.array(z.string()),
    ingredients: z.array(z.string()),
    localized_content: z.array(zLocalizedContent),
    lowest_available_price: z.object({
      found: z.literal(true),
      val: z.string().optional(),
      price: z.number().nullish().optional(),
    }),
    net_weight_grams: z.number().nullable(),
    percent_cbd: z.number().nullable(),
    percent_cbda: z.number().nullable(),
    percent_thc: z.number().nullable(),
    percent_thca: z.number().nullable(),
    percentCBDA: z.number().nullable(),
    percentTHCA: z.number().nullable(),
    photos: z.array(zPhoto),
    primaryPercentCBD: z.number(),
    primaryPercentTHC: z.number(),
    product_percent_cbd: z.number().nullable(),
    product_percent_thc: z.number().nullable(),
    quantity_units: zProductUnitsEnum.nullable(),
    quantity_value: z.number().nullable(),
    recommended: z.boolean(),
    review_count: z.number().nullable(),
    root_subtype: z.string().nullable(),
    secondaryPercentCBD: z.number(),
    secondaryPercentTHC: z.number(),
    terpenes: z.array(z.record(z.string())),
    thc_dosage_milligrams: z.number().nullish(),
    url_slug: z.string(),
  })
  .partial();

export const zProduct = z.intersection(
  z.object({
    id: zId,
    brand: z.string().nullable(),
    kind: zProductKindEnum,
    name: z.string(),
    product_brand_id: z.number().nullish(),
    type: zProductTypeEnum,
  }),
  zProductOptionalProperties
);

export type BrandPriceIdSpecials = z.infer<typeof zBrandPriceIdSpecials>;
export type BrandSpecialPrices = z.infer<typeof zBrandSpecialPrices>;
export type BusinessLicense = z.infer<typeof zBusinessLicense>;
export type Effect = z.infer<typeof zEffect>;
export type Flavor = z.infer<typeof zFlavor>;
export type FlavorEnum = z.infer<typeof zFlavorEnum>;
export type Photo = z.infer<typeof zPhoto>;
export type Product = z.infer<typeof zProduct>;
export type ProductKind = z.infer<typeof zProductKindEnum>;
export type ProductKindSoldByWeight = z.infer<
  typeof zProductKindSoldByWeightEnum
>;
export type ProductLineage = z.infer<typeof zProductLineageEnum>;
export type ProductType = z.infer<typeof zProductTypeEnum>;
export type ProductUnits = z.infer<typeof zProductUnitsEnum>;
export type TerpeneEnum = z.infer<typeof zTerpeneEnum>;
