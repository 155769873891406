const get = (key: string) => {
  const item = localStorage.getItem(key);
  if (item !== null) {
    return trimQuotes(item);
  }
  return item;
};

const set = (key: string, value: string) =>
  localStorage.setItem(key, addQuotes(value));

const remove = (key: string) => localStorage.removeItem(key);

export const Storage = { get, remove, set } as const;

/**
 * JDID values created by the monolith are wrapped in quotes ('"') because they use the `store` lib.
 * We don't want to use the `store` lib in the dm sdk and will instead directly access localStorage.
 * We need to add and remove quotes to ensure compatibility with monolith code.
 *
 * `store` documentation: https://github.com/marcuswestin/store.js
 */
const trimQuotes = (jdid: string) => {
  if (getIsQuoted(jdid)) {
    return jdid.substring(1, jdid.length - 1);
  }
  return jdid;
};

const addQuotes = (jdid: string) => {
  if (!getIsQuoted(jdid)) {
    return `"${jdid}"`;
  }
  return jdid;
};

const getIsQuoted = (jdid: string) => {
  const start = 0;
  const end = jdid.length - 1;
  const isQuoted = jdid[start] === '"' && jdid[end] === '"';
  return isQuoted;
};
