import { config } from '@jane/shared/config';
import type { Id } from '@jane/shared/models';

import { request } from '../lib/request';

interface User {
  app_mode_at_registration?: string;
  current_password?: string;
  password?: string;
  password_confirmation?: string;
  store_id_at_registration?: Id;
}

type UserProps = {
  promotions_opt_out?: boolean;
  user: User;
};

export class UsersSource {
  static urlRoot = `${config.apiPath}/users`;

  static update(user: UserProps) {
    return request(this.urlRoot, {
      body: JSON.stringify(user),
      method: 'PUT',
    });
  }
}
