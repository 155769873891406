import startCase from 'lodash/startCase';
import sumBy from 'lodash/sumBy';
import { nanoid } from 'nanoid';

import { config } from '@jane/shared/config';
import type { Id } from '@jane/shared/models';

import { getAnonymousUserId } from '../getAnonymousUserId';
import { Storage } from '../storage';
import type { Tests, Variation, WeightedVariation } from './abTesting.types';

/* eslint-disable no-bitwise */
const hash = (text: string) => {
  let hash = 5381;
  let index = text.length;

  while (index) {
    hash = (hash * 33) ^ text.charCodeAt(--index);
  }

  return hash >>> 0;
};

// Variations can be a string for a 50/50 AB test
// or an object for a weighted test
export const TESTS: { [key in keyof Tests]: Variation<Tests[key]>[] } = {
  example: ['a', 'b', 'c'],
  weightedExample: [
    { name: 2, weight: 2 },
    { name: 3, weight: 3 },
  ],
  feeLabel: [{ name: 'pinkFreeSymbol', weight: 1 }],
  persistCheckoutData: ['off', 'on'],
};

// To track results in mixpanel, add active tests here
export const LIVE_TESTS: (keyof Tests)[] = ['feeLabel', 'persistCheckoutData'];

export const getLiveTests = <Test extends keyof Tests>(
  customerId?: Id | null
): { [key: string]: Tests[Test] } =>
  LIVE_TESTS.reduce(
    (acc, test) => ({
      ...acc,
      [`Test: ${startCase(test)}`]: getVariation(test, customerId),
    }),
    {}
  );

// To force a variation
// set a queryParameter like test:example=a
const getVariationOverride = (test: keyof Tests) => {
  if ('URLSearchParams' in window) {
    return new URLSearchParams(window.location.search).get(`test:${test}`);
  }
  return undefined;
};

export const getWeightedVariation = <Test extends keyof Tests>(
  test: Test,
  seed: number
) => {
  const weightedVariations = (TESTS[test] as any).map((v: Variation<Test>) =>
    typeof v === 'string' ? { name: v, weight: 1 } : v
  );

  const totalWeight = sumBy(weightedVariations, 'weight');
  let threshold = 0;
  let selectedVariation: WeightedVariation<Tests[Test]> = weightedVariations[0];
  const randomNumber = seed % totalWeight;
  for (const variation of weightedVariations) {
    threshold += variation.weight;

    if (threshold > randomNumber) {
      selectedVariation = variation;
      break;
    }
  }

  return selectedVariation.name;
};

const variations: { [key in keyof Tests]?: Tests[key] } = {};

const getVariations = <Test extends keyof Tests>(test: Test): Tests[Test][] =>
  (TESTS[test] as any).map((variation: Variation<Test>) =>
    typeof variation === 'string' ? variation : variation.name
  );

export const isValidVariation = <Test extends keyof Tests>(
  test: Test,
  variation: unknown
): variation is Tests[Test] => {
  return Boolean(
    (typeof variation === 'string' || typeof variation === 'number') &&
      getVariations(test).includes(variation as any)
  );
};

export const getVariation = <Test extends keyof Tests>(
  test: Test,
  customerId?: Id | null
): Tests[Test] => {
  if (config.dev && !LIVE_TESTS.includes(test)) {
    // eslint-disable-next-line no-console
    console.error(
      `Warning: Test "${test}" will not be tracked. Please add "${test}" to LIVE_TESTS or remove unused variation.`
    );
  }

  const customerIdentifier = customerId || getAnonymousUserId() || nanoid();
  const possibleVariation: unknown =
    getVariationOverride(test) ||
    variations[test] ||
    Storage.get(`test:${test}`);

  if (isValidVariation(test, possibleVariation)) {
    variations[test] = possibleVariation;
    return possibleVariation;
  }

  const variation = getWeightedVariation(test, hash(test + customerIdentifier));
  variations[test] = variation;
  Storage.set(`test:${test}`, variation);

  return variation;
};
