import type { StoreSpecial } from '@jane/shared/models';
import { Flex, Typography } from '@jane/shared/reefer';

import GovernmentAssistanceIdentification from '../../../../identification/governmentAssistanceIdentification';
import IDIdentification from '../../../../identification/idIdentification';
import MMJIdentification from '../../../../identification/mmjIdentification';
import PediatricIdentification from '../../../../identification/pediatricIdentification';
import SeniorIdentification from '../../../../identification/seniorIdentification';
import StudentIdentification from '../../../../identification/studentIdentification';
import VeteranIdentification from '../../../../identification/veteranIdentification';

const renderUploader = ({
  groupType,
  timezone,
  requiredAge,
}: {
  groupType: string;
  requiredAge?: number | null;
  timezone: string;
}) => {
  return groupType === 'student' ? (
    <StudentIdentification />
  ) : groupType === 'medical' ? (
    <MMJIdentification />
  ) : groupType === 'senior' ? (
    <SeniorIdentification requiredAge={requiredAge || 1} timezone={timezone} />
  ) : groupType === 'veteran' ? (
    <VeteranIdentification />
  ) : groupType === 'pediatric' ? (
    <PediatricIdentification
      requiredAge={requiredAge || 1}
      timezone={timezone}
    />
  ) : groupType === 'ssi' ? (
    <IDIdentification />
  ) : groupType === 'snap' ? (
    <IDIdentification />
  ) : groupType === 'medicare' ? (
    <IDIdentification />
  ) : groupType === 'pace' ? (
    <GovernmentAssistanceIdentification />
  ) : groupType === 'pacenet' ? (
    <GovernmentAssistanceIdentification />
  ) : groupType === 'chip' ? (
    <GovernmentAssistanceIdentification />
  ) : groupType === 'wic' ? (
    <GovernmentAssistanceIdentification />
  ) : groupType === 'PA Gov' ? (
    <GovernmentAssistanceIdentification />
  ) : groupType === 'medicaid' ? (
    <IDIdentification />
  ) : groupType === 'locals' ? (
    <IDIdentification />
  ) : groupType === 'industry' ? (
    <IDIdentification />
  ) : null;
};

export const GroupDiscountDetails = ({
  special,
  timezone,
}: {
  special?: StoreSpecial;
  timezone: string;
}) => {
  if (!special || special.special_type !== 'qualified_group') return null;
  const { conditions } = special;
  const { qualified_group: groupConditions } = conditions;
  if (!groupConditions) return null;

  return (
    <Flex flexDirection="column" width="100%" pb={24} alignItems="center">
      {special.terms && (
        <Typography mt={4} ml={24} mb={12} variant="mini">
          {special.terms}
        </Typography>
      )}
      {renderUploader({
        groupType: groupConditions?.type,
        timezone,
        requiredAge:
          'required_age' in groupConditions
            ? groupConditions?.required_age
            : null,
      })}
    </Flex>
  );
};
