import uniq from 'lodash/uniq';

import type { BrandSpecial, PendingCartProduct } from '@jane/shared/models';
import type { UserSegmentIds } from '@jane/shared/types';

import type {
  AlgoliaUserSegmentIds,
  ProductWithBrandSpecialSegments,
} from './shouldShowSpecial';

export const brandSpecialHasSegments = ({
  brandSpecial,
  cartProduct,
  product,
}: {
  brandSpecial?: Pick<BrandSpecial, 'rules'>;
  cartProduct?: Pick<PendingCartProduct, 'brand_special_has_segment_rules'>;
  product?: Pick<
    ProductWithBrandSpecialSegments,
    | 'applicable_brand_specials_excluded_user_segments'
    | 'applicable_brand_specials_included_user_segments'
  >;
}) => {
  if (brandSpecial) {
    const includedSegments = uniq(
      brandSpecial.rules?.includes?.flatMap(
        (rule) => rule.user_segment_ids || []
      )
    );
    const excludedSegments = uniq(
      brandSpecial.rules?.excludes?.flatMap(
        (rule) => rule.user_segment_ids || []
      )
    );
    const noIncludedSegments =
      !includedSegments || !(includedSegments as UserSegmentIds).length;
    const noExcludedSegments =
      !excludedSegments || !(excludedSegments as UserSegmentIds).length;
    const needsSegments = !noIncludedSegments || !noExcludedSegments;

    return needsSegments;
  }

  if (
    product &&
    'applicable_brand_specials_included_user_segments' in product &&
    'applicable_brand_specials_excluded_user_segments' in product
  ) {
    const includedSegments =
      product.applicable_brand_specials_included_user_segments as AlgoliaUserSegmentIds;
    const excludedSegments =
      product.applicable_brand_specials_excluded_user_segments as AlgoliaUserSegmentIds;
    const noIncludedSegments =
      !includedSegments ||
      includedSegments === -1 ||
      !(includedSegments as UserSegmentIds).length;
    const noExcludedSegments =
      !excludedSegments ||
      excludedSegments === -1 ||
      !(excludedSegments as UserSegmentIds).length;
    const needsSegments = !noIncludedSegments || !noExcludedSegments;

    return needsSegments;
  }

  if (cartProduct) {
    return Boolean(cartProduct.brand_special_has_segment_rules);
  }

  return false;
};
