import { Box, Flex, Loading, Typography } from '@jane/shared/reefer';

export const AeropayLoading = () => {
  return (
    <Flex width="100%" flexDirection="column" alignItems="center">
      <Box position="relative" mb={24}>
        <Loading color="black" size="lg" />
      </Box>
      <Typography branded variant="header-bold" textAlign="center" mb={8}>
        Hang tight
      </Typography>
      <Typography textAlign="center">This may take a few seconds.</Typography>
    </Flex>
  );
};
