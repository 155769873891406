import type { Reducer } from 'redux';

import type { DeepReadonly, Id, MenuProduct } from '@jane/shared/models';

import { createSimpleAction, createStandardAction } from '../../redux-util';
import { MenuProductsSource } from '../../sources/menuProducts';
import type { CustomerThunkAction } from '../redux';
import type { CustomerAction } from './types';

export const GET_MENU_PRODUCT = 'embedded-app/get-menu-product';
export const getMenuProduct =
  ({
    storeId,
    productId,
  }: {
    productId: Id;
    storeId: Id;
  }): CustomerThunkAction =>
  (dispatch) => {
    dispatch({ type: GET_MENU_PRODUCT });

    return MenuProductsSource.get(storeId, productId).then(
      (result) => dispatch(getMenuProductSuccess(result)),
      (err) => dispatch(getMenuProductError(err))
    );
  };

export const RESET_MENU_PRODUCT = 'embedded-app/reset-menu-product';
export const resetMenuProduct = createSimpleAction(RESET_MENU_PRODUCT);

export const GET_MENU_PRODUCT_SUCCESS = 'embedded-app/get-menu-product-success';
export const getMenuProductSuccess = createStandardAction(
  GET_MENU_PRODUCT_SUCCESS
)<{ product: MenuProduct }>();

export const GET_MENU_PRODUCT_ERROR = 'embedded-app/get-menu-product-error';
export const getMenuProductError = createStandardAction(
  GET_MENU_PRODUCT_ERROR
)<unknown>();

export const SET_MENU_PRODUCT = 'embedded-app/set-menu-product';
export const setMenuProduct =
  createStandardAction(SET_MENU_PRODUCT)<MenuProduct>();

export type MenuProductActions =
  | { type: typeof GET_MENU_PRODUCT }
  | ReturnType<typeof getMenuProductError>
  | ReturnType<typeof resetMenuProduct>
  | ReturnType<typeof getMenuProductSuccess>
  | { payload: MenuProduct; type: typeof SET_MENU_PRODUCT };

export type MenuProductState = DeepReadonly<{
  isLoading: boolean;
  menuProduct: MenuProduct | undefined;
}>;

const getInitialState = (): MenuProductState => ({
  isLoading: false,
  menuProduct: undefined,
});

export const menuProductReducer: Reducer<MenuProductState, CustomerAction> = (
  state = getInitialState(),
  action
) => {
  switch (action.type) {
    case GET_MENU_PRODUCT:
      return { ...state, isLoading: true };
    case GET_MENU_PRODUCT_ERROR:
      return { ...state, isLoading: false };
    case GET_MENU_PRODUCT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        menuProduct: action.payload.product,
      };
    case RESET_MENU_PRODUCT:
      return { ...state, menuProduct: undefined };
    case SET_MENU_PRODUCT:
      return { ...state, isLoading: false, menuProduct: action.payload };
  }

  return state;
};
