import type { CurrentSort, FilterSortOption } from '@jane/search/types';
import { FilterSortId, makeCurrentSort } from '@jane/search/types';

const byAToZ: FilterSortOption = {
  id: FilterSortId.AZ,
  isDefault: false,
  label: 'A-Z',
  value: '',
};
export const byAToZDefault: FilterSortOption = { ...byAToZ, isDefault: true };
const byPriceLowToHigh: FilterSortOption = {
  id: FilterSortId.PriceAsc,
  isDefault: false,
  label: 'Price Low to High',
  value: 'by-price-',
};
const byPriceHighToLow: FilterSortOption = {
  id: FilterSortId.PriceDesc,
  isDefault: false,
  label: 'Price High to Low',
  value: 'by-price-desc-',
};
const byThcLowToHigh: FilterSortOption = {
  id: FilterSortId.ThcAsc,
  isDefault: false,
  label: 'THC% Low to High',
  value: 'by-thc-potency-asc-',
};
const byThcHighToLow: FilterSortOption = {
  id: FilterSortId.ThcDesc,
  isDefault: false,
  label: 'THC% High to Low',
  value: 'by-thc-potency-desc-',
};
const byRating: FilterSortOption = {
  id: FilterSortId.Rating,
  isDefault: false,
  label: 'Rating',
  value: 'by-rating-',
};
const byRecommended: FilterSortOption = {
  id: FilterSortId.Recommended,
  isDefault: false,
  label: 'Recommended',
  value: 'by-recommendation-ordinal-asc-',
};
export const byRecommendedDefault: FilterSortOption = {
  ...byRecommended,
  isDefault: true,
};

export const sortOptions: FilterSortOption[] = [
  byAToZDefault,
  byPriceLowToHigh,
  byPriceHighToLow,
  byThcLowToHigh,
  byThcHighToLow,
  byRating,
];

export const sortOptionsWithRecommended: FilterSortOption[] = [
  byRecommendedDefault,
  byAToZ,
  byPriceLowToHigh,
  byPriceHighToLow,
  byThcLowToHigh,
  byThcHighToLow,
  byRating,
];

export const limitedSortOptions: FilterSortOption[] = [byAToZDefault, byRating];

/**
 * When recommended sort is enabled, the default sort for carousel is AZ and for Table is Recommended.
 * Returns a new sort if currentSort is a default sort but not the correct default for the current carousel/table view.
 */
export const maybeGetDefaultStoreMenuSort = ({
  currentSort,
  shouldShowRecommendedSort,
}: {
  currentSort: CurrentSort | undefined;
  shouldShowRecommendedSort: boolean;
}): CurrentSort | null => {
  if (currentSort && !currentSort.isDefault) {
    // non-default sorts should not be overridden
    return null;
  }
  const isAToZ = currentSort?.id === FilterSortId.AZ;
  const isRecommended = currentSort?.id === FilterSortId.Recommended;
  if (shouldShowRecommendedSort && (!currentSort || isAToZ)) {
    return makeCurrentSort(byRecommendedDefault);
  }
  if (!shouldShowRecommendedSort && (!currentSort || isRecommended)) {
    return makeCurrentSort(byAToZDefault);
  }

  // don't need to override if current default sort matches the desired default sort
  return null;
};

export const getShouldShowRecommendedSort = ({
  isRecommendedSortEnabled,
  isTable,
}: {
  isRecommendedSortEnabled: boolean;
  isTable: boolean;
}) => {
  return isRecommendedSortEnabled && isTable;
};
