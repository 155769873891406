import type { Reducer } from 'redux';

import type { AppMode, DeepReadonly, Id, Store } from '@jane/shared/models';

import { createStandardAction } from '../../redux-util';
import { NotificationsService } from '../../services/notifications';
import { StoreSource } from '../../sources/store';
import type { CustomerThunkAction } from '../redux';
import { getCart } from './cart';
import type { CustomerAction } from './types';

export const SET_APP_MODE = 'embedded-app/set-app-mode';
export const setAppMode = createStandardAction(SET_APP_MODE)<AppMode>();

export const SET_DISABLE_AUTH_FEATURES =
  'embedded-app/set-disable-auth-features';
export const setDisableAuthFeatures = createStandardAction(
  SET_DISABLE_AUTH_FEATURES
)<boolean>();

export const SET_PARTNER = 'embedded-app/set-partner';
export const setPartner = createStandardAction(SET_PARTNER)<{
  id: Id;
  name: string;
}>();

export const VERIFY_STORE = 'embedded-app/verify-store';

type VerifyStoreOptions = {
  forceEmbed?: boolean;
  framelessEmbed?: boolean;
  isPartnerHosted?: boolean;
  operatorEmbed?: boolean;
};
export const verifyStore =
  ({
    storeId,
    options = {},
  }: {
    options?: VerifyStoreOptions;
    storeId: Id;
  }): CustomerThunkAction =>
  (dispatch) => {
    dispatch({ type: VERIFY_STORE, payload: { storeId } });

    const {
      isPartnerHosted = false,
      forceEmbed,
      operatorEmbed,
      framelessEmbed,
    } = options;

    const disableShopping =
      window.location.search.includes('disableShopping=1');

    return StoreSource.get(storeId).then(
      ({ store }) => {
        const overriddenStore = {
          ...store,
          white_label_enabled: store.white_label_enabled && !forceEmbed,
          hide_prices: store.hide_prices || disableShopping,
        };

        let appMode: AppMode = 'embedded';

        if (operatorEmbed) {
          appMode = 'operatorEmbed';
        } else if (framelessEmbed) {
          appMode = 'framelessEmbed';
        } else if (overriddenStore.white_label_enabled) {
          appMode = 'whiteLabel';
        }

        dispatch(
          verifyStoreSuccess({
            store: overriddenStore,
            appMode,
            isPartnerHosted,
          })
        );

        dispatch(getCart());
      },
      (err) => dispatch(verifyStoreError(err))
    );
  };

export const VERIFY_STORE_SUCCESS = 'embedded-app/verify-store-success';
export const verifyStoreSuccess = createStandardAction(VERIFY_STORE_SUCCESS)<{
  appMode: AppMode;
  isPartnerHosted: boolean;
  store: Store;
}>();

export const VERIFY_STORE_ERROR = 'embedded-app/verify-store-error';
export const verifyStoreError = (error: string): CustomerThunkAction => {
  return (dispatch) => {
    dispatch({ type: VERIFY_STORE_ERROR });
    return NotificationsService.error(error);
  };
};

export type EmbeddedAppActions =
  | ReturnType<typeof setAppMode>
  | ReturnType<typeof setDisableAuthFeatures>
  | ReturnType<typeof setPartner>
  | {
      payload: {
        storeId: Id;
      };
      type: typeof VERIFY_STORE;
    }
  | ReturnType<typeof verifyStoreSuccess>
  | { type: typeof VERIFY_STORE_ERROR };

export type EmbeddedAppState = DeepReadonly<{
  appMode: AppMode;
  disableAuthFeatures: boolean;
  hasVerified: boolean;
  isPartnerHosted: boolean;
  isVerifying: boolean;
  partnerId: Id | undefined;
  partnerName: string | undefined;
}>;

const getInitialState = (): EmbeddedAppState => ({
  appMode: 'default',
  partnerId: undefined,
  partnerName: undefined,
  hasVerified: false,
  isVerifying: false,
  isPartnerHosted: false,
  disableAuthFeatures: false,
});

export const embeddedAppReducer: Reducer<EmbeddedAppState, CustomerAction> = (
  state = getInitialState(),
  action
) => {
  switch (action.type) {
    case SET_APP_MODE:
      return { ...state, appMode: action.payload };
    case SET_DISABLE_AUTH_FEATURES:
      return { ...state, disableAuthFeatures: action.payload };
    case SET_PARTNER:
      return {
        ...state,
        partnerId: action.payload.id,
        partnerName: action.payload.name,
      };
    case VERIFY_STORE:
      return { ...state, isVerifying: true, partnerId: action.payload.storeId };
    case VERIFY_STORE_SUCCESS: {
      const { store, appMode, isPartnerHosted } = action.payload;
      if (state.partnerId !== store.id) {
        return {
          ...state,
          isVerifying: false,
          partnerId: undefined,
        };
      }

      return {
        ...state,
        partnerName: store.name,
        isVerifying: false,
        hasVerified: true,
        appMode,
        isPartnerHosted,
      };
    }
    case VERIFY_STORE_ERROR:
      return { ...state, isVerifying: false, partnerId: undefined };
  }

  return state;
};
