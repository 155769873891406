import styled from '@emotion/styled';

import { Link, Modal } from '@jane/shared/reefer';
import { mediaQueries, spacing } from '@jane/shared/reefer-emotion';

export const StyledDiv = styled.div({
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  flexGrow: 1,
  [mediaQueries.desktop('sm', 'max')]: {
    whiteSpace: 'unset',
    display: '-webkit-box',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: 2,
  },
});

export const StyledLink = styled(Link)({
  flexShrink: 0,
});

export const StyledModalContent = styled(Modal.Content)({
  ...spacing({ p: 24 }),
  // These styles are for the inserted HTML to properly match the HTML editor from the business dashboard.
  '.ql-size-large': {
    fontFamily: 'inherit',
    fontSize: '1.5em',
  },
  '.ql-size-small': {
    fontFamily: 'inherit',
    fontSize: '0.75em',
  },
  'ul, ol': {
    paddingLeft: '1.5em',
  },
  'ul li': {
    listStyle: 'disc inside',
    paddingLeft: 0,
  },
  'ol li': {
    listStyle: 'decimal inside',
  },
  a: {
    color: 'blue',
    textDecoration: 'underline',
    '&:hover': {
      color: 'darkBlue',
    },
  },
  's, del': {
    textDecoration: 'line-through',
  },
});
