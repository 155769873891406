import styled from '@emotion/styled';

import { Card, Flex, Link } from '@jane/shared/reefer';
import { mediaQueries } from '@jane/shared/reefer-emotion';

import {
  CATEGORY_CARD_HEIGHT,
  CATEGORY_CARD_HEIGHT_MOBILE,
  CATEGORY_CARD_WIDTH_MOBILE,
} from './categoryCard';

interface StyledCardProps {
  width?: string | number;
}

export const StyledCard = styled(Card)<StyledCardProps>(({ width }) => ({
  height: CATEGORY_CARD_HEIGHT_MOBILE,
  scrollSnapAlign: 'start',
  width: CATEGORY_CARD_WIDTH_MOBILE,
  [mediaQueries.tablet('min')]: {
    height: CATEGORY_CARD_HEIGHT,
    minWidth: 155,
    width,
  },
}));

export const StyledContentContainer = styled(Flex)({
  flexDirection: 'row',
  [mediaQueries.tablet('min')]: {
    flexDirection: 'column',
  },
});

export const StyledLink = styled(Link)(({ theme }) => ({
  display: 'block',
  height: '100%',
  textDecoration: 'none',
  width: '100%',
  ':focus-visible:before': {
    backgroundColor: theme.colors.grays.hover,
    borderRadius: 'inherit',
    content: '""',
    height: '100%',
    position: 'absolute',
    width: '100%',
  },
}));
