import { config } from '@jane/shared/config';

import { withAuthHeader } from '../utils';
import { api } from './api';

const buildUrl = (url: string) => `${config.apiPath}${url}`;

export const janeApi = {
  delete: <T>(
    url: string,
    authToken?: string | null,
    params?: object
  ): Promise<T | null> => {
    return api.delete(buildUrl(url), withAuthHeader(params, authToken));
  },
  get: <T>(
    url: string,
    authToken?: string | null,
    params?: object
  ): Promise<T> => {
    return api.get(buildUrl(url), withAuthHeader(params, authToken));
  },
  patch: <T>(
    url: string,
    data: unknown,
    authToken?: string | null,
    params?: object
  ): Promise<T> => {
    return api.patch(buildUrl(url), data, withAuthHeader(params, authToken));
  },
  post: <T>(
    url: string,
    data: unknown,
    authToken?: string | null,
    params?: object,
    returnWholeResponse?: boolean
  ): Promise<T> => {
    return api.post(
      buildUrl(url),
      data,
      withAuthHeader(params, authToken),
      returnWholeResponse
    );
  },
};
