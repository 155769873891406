import { Button, Flex, Typography } from '@jane/shared/reefer';

export interface DeleteBankAccountScreenProps {
  /** Indicator if the user is trying to remove the last bank account, displaying a different description message of the outcome */
  lastBankAccount?: boolean;

  /** Function called when 'Cancel removing' button is clicked */
  onCancelClick(): void;

  /** Function called when 'Remove bank' button is clicked, deleting the selected bank account */
  onRemoveClick: () => void;
}

export const DeleteBankAccountScreen = ({
  lastBankAccount,
  onCancelClick,
  onRemoveClick,
}: DeleteBankAccountScreenProps) => {
  return (
    <Flex width="100%" flexDirection="column" alignItems="center">
      <Typography variant="header-bold" textAlign="center" mb={8} branded>
        Confirm removing bank
      </Typography>
      <Typography textAlign="center" mb={24}>
        {lastBankAccount
          ? 'Are you sure you want to remove your bank account? You will no longer be able to earn cash back rewards from your favorite brands.'
          : 'Are you sure you want to remove your bank account?'}
      </Typography>
      <Button
        full
        variant="primary"
        label="Remove bank"
        mb={16}
        onClick={onRemoveClick}
      />
      <Button
        full
        variant="secondary"
        label="Cancel removing"
        onClick={onCancelClick}
      />
    </Flex>
  );
};
