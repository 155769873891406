import FingerprintJS from '@fingerprintjs/fingerprintjs-pro';
import { nanoid } from 'nanoid';

import { config } from '@jane/shared/config';
import { Storage, debugLog } from '@jane/shared/util';

function setLocalStorageWithTTLInDays(
  key: string,
  value: string,
  ttlInDays: number
) {
  if (!value) {
    debugLog('Trying to set an empty value!');
    return;
  }
  const expiry = Date.now() + ttlInDays * 24 * 60 * 60 * 1000;
  Storage.set(key, JSON.stringify({ value, expiry }));
}

export function getLocalStorageWithTTL(key: string) {
  const itemStr = Storage.get(key);
  if (!itemStr) return null;
  try {
    const { value, expiry } = JSON.parse(itemStr);
    return Date.now() > expiry ? null : value;
  } catch {
    Storage.remove(key);
    return null;
  }
}

export function getLocalStorageIgnoreTTL(key: string) {
  const itemStr = Storage.get(key);
  if (!itemStr) return null;
  try {
    return JSON.parse(itemStr).value;
  } catch {
    Storage.remove(key);
    return null;
  }
}

export const getFingerprintId = async () => {
  const legacyFpKey = 'j_eyeD';
  const newFpKey = 'cachedJdid';
  try {
    const fp = await FingerprintJS.load({
      apiKey: config.fingerprintApiKey,
      endpoint: [config.fingerprintEndpoint, FingerprintJS.defaultEndpoint],
      scriptUrlPattern: [
        `${config.fingerprintScriptPath}?apiKey=<apiKey>&version=<version>&loaderVersion=<loaderVersion>`,
        FingerprintJS.defaultScriptUrlPattern,
      ],
      storageKey: 'eyeD',
    });
    const result = await fp.get();
    if (result?.visitorId) {
      const msg = 'Received VID from FP';
      console.debug(msg);
      debugLog(msg);
      setLocalStorageWithTTLInDays(legacyFpKey, result.visitorId, 1);
      setLocalStorageWithTTLInDays(newFpKey, result.visitorId, 1);
      return result.visitorId;
    }
    const msg = 'Did not receive VID from FP';
    console.debug(msg);
    debugLog(msg);
    return null;
  } catch (error) {
    console.debug('FP Fetch Error: ', error);
    return null;
  }
};

export const generateFallbackJdid = () => {
  return nanoid(21);
};

export const useGetJaneDeviceId = () => {
  // TODO: change this to not return as { data: string }
  const jdidKey = 'jdid';
  const availableId = Storage.get('jdid');
  if (availableId) return { data: availableId };
  const cookie = getCookie('jdid');
  if (cookie) {
    Storage.set(jdidKey, cookie);
    return { data: cookie };
  }
  debugLog(
    'JDID not available was not available in useGetJaneDeviceId. No reason for alarm.'
  );
  const newId = generateFallbackJdid();
  Storage.set(jdidKey, newId);
  return { data: newId };
};

export const getCookie = (name: string): string | null => {
  const cookieValue = document.cookie
    .split(';')
    .map((cookie) => cookie.trim())
    .find((cookie) => cookie.startsWith(`${name}=`));
  return cookieValue ? decodeURIComponent(cookieValue.split('=')[1]) : null;
};
