/**
 * DO NOT MODIFY THIS FILE!
 * It was created by the reefer-icons generator.
 * Please update & re-run the generator if need be.
 */
import type { SVGProps } from 'react';

import type { DynamicIconProps } from '../../../icon/icon.types';
import { Icon } from '../../icon';

const StarCircled24 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="100%"
    height="100%"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <g className="Star circled 24">
      <path
        fill="#0E0E0E"
        d="M11.35 6.418a.75.75 0 0 1 1.345 0l1.357 2.75a.75.75 0 0 0 .564.41l3.035.441a.75.75 0 0 1 .416 1.28l-2.196 2.14a.75.75 0 0 0-.216.664l.519 3.022a.75.75 0 0 1-1.089.79l-2.714-1.426a.75.75 0 0 0-.698 0l-2.714 1.427a.75.75 0 0 1-1.088-.79l.518-3.023a.75.75 0 0 0-.216-.664l-2.196-2.14a.75.75 0 0 1 .416-1.28l3.035-.44a.75.75 0 0 0 .564-.411l1.357-2.75Z"
        className="SVG"
      />
      <circle
        cx={12}
        cy={12}
        r={11}
        stroke="#0E0E0E"
        strokeWidth={2}
        className="Ellipse 731"
      />
    </g>
  </svg>
);

const SIZE_MAP = {
  md: StarCircled24,
  sm: StarCircled24,
  lg: StarCircled24,
  xl: StarCircled24,
  xxl: StarCircled24,
  xxxl: StarCircled24,
};

export const StarCircledIcon = ({
  size = 'md',
  'data-testid': testId,
  ...props
}: DynamicIconProps) => {
  return (
    <Icon
      data-testid={testId || 'star-circled-icon'}
      icon={SIZE_MAP[size]}
      {...props}
      size={size}
    />
  );
};
