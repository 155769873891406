import isEmpty from 'lodash/isEmpty';

import type { Id } from '@jane/shared/models';

export const buildDiscountableProductsFilter = (
  filterDiscountableProducts = false,
  discountableProductIds: Id[]
) => {
  if (!isEmpty(discountableProductIds) && filterDiscountableProducts) {
    return `(${discountableProductIds
      .map((productId) => `product_id:${productId}`)
      .join(' OR ')})`;
  } else {
    return '';
  }
};
