import { useHandleIframeModal } from '@jane/shared-ecomm/hooks';
import { Button, DismissIcon } from '@jane/shared/reefer';

import {
  StyledButtonWrapper,
  StyledLightboxContent,
  StyledLightboxModal,
  StyledModalContent,
} from './lightbox.styles';
import type { LightboxProps } from './lightbox.types';

/**
 * Lightbox modal for product and brand images and videos, used in the Carousel
 */

export const Lightbox = ({
  appId = 'app',
  children,
  'data-testid': testId,
  isOpen,
  onRequestClose,
}: LightboxProps) => {
  useHandleIframeModal(isOpen);

  return (
    <StyledLightboxModal
      appElement={document.getElementById(appId) as HTMLElement}
      closeTimeoutMS={200}
      contentLabel="lightbox"
      data-testid={testId}
      isOpen={isOpen}
      onAfterOpen={() => (document.body.style.overflow = 'hidden')}
      onAfterClose={() => (document.body.style.overflow = 'unset')}
      onRequestClose={onRequestClose}
      shouldCloseOnOverlayClick={true}
    >
      <StyledButtonWrapper>
        <Button.Icon
          icon={<DismissIcon />}
          label="Close lightbox"
          variant="minimal-inverse"
          onClick={() => onRequestClose()}
        />
      </StyledButtonWrapper>
      <StyledModalContent>
        <StyledLightboxContent>{children}</StyledLightboxContent>
      </StyledModalContent>
    </StyledLightboxModal>
  );
};
