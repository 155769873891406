import { useCallback, useState } from 'react';

import {
  sendMultifactorCode,
  verifyMultifactorCode,
} from '@jane/shared/data-access';
import { trackError } from '@jane/shared/util';

interface UseMultifactorAuthenticationProps {
  newPhone?: string;
  onCodeResent?: () => void;
  onCodeSentSuccess?: () => void;
  onVerifySuccess?: () => void;
}

/**
 * This hook handles all the logic for sending and resending mfa codes.
 * When used in tandem with the MfaProvider and useMfa, you can utilize a single MfaModal
 * that tracks codes being sent and mfa being completed.
 */

export const useMultifactorAuthentication = ({
  newPhone,
  onCodeSentSuccess,
  onCodeResent,
  onVerifySuccess,
}: UseMultifactorAuthenticationProps) => {
  const [sendingCode, setSendingCode] = useState(false);
  const [codeSent, setCodeSent] = useState(false);
  const [codeResent, setCodeResent] = useState(false);
  const [verifyingCode, setVerifyingCode] = useState(false);
  const [errorSendingCode, setErrorSendingCode] = useState<null | string>(null);
  const [errorVerifyingCode, setErrorVerifyingCode] = useState<null | string>(
    null
  );

  const sendCode = useCallback(async () => {
    setSendingCode(true);
    setErrorSendingCode(null);
    setErrorVerifyingCode(null);

    try {
      const response = await sendMultifactorCode(newPhone);
      if (response.success) {
        setCodeSent(true);
        onCodeSentSuccess && onCodeSentSuccess();
      } else {
        setErrorSendingCode(response.error || 'Error sending code');
        trackError(response.error);
      }
    } catch (e) {
      setErrorSendingCode(e as string);
    }

    setSendingCode(false);
  }, []);

  const resendCode = useCallback(() => {
    sendCode();
    setCodeResent(true);
    onCodeResent && onCodeResent();
  }, []);

  const verifyCode = useCallback(async (code: string) => {
    setErrorVerifyingCode(null);
    setVerifyingCode(true);

    try {
      const response = await verifyMultifactorCode(code);

      if (response.success) {
        onVerifySuccess && onVerifySuccess();
      } else {
        setErrorVerifyingCode(
          response.error
            ? 'There was an error processing this request. Please try again.'
            : 'This code is incorrect, check your code and try again.'
        );
      }
    } catch (e) {
      setErrorVerifyingCode('Something went wrong. Please try again.');
    }

    setVerifyingCode(false);
  }, []);

  return {
    codeResent,
    codeSent,
    errorSendingCode,
    errorVerifyingCode,
    sendCode,
    resendCode,
    sendingCode,
    verifyCode,
    verifyingCode,
  };
};
