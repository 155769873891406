/**
 * DO NOT MODIFY THIS FILE!
 * It was created by the reefer-icons generator.
 * Please update & re-run the generator if need be.
 */
import type { SVGProps } from 'react';

import type { StaticIconProps } from '../../../icon/icon.types';
import { Icon } from '../../icon';

const Relaxed24 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="100%"
    height="100%"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <g className="Relaxed 24">
      <g className="SVG">
        <path
          fill="#FFC220"
          d="M24 12c0 6.627-5.373 12-12 12S0 18.627 0 12 5.373 0 12 0s12 5.373 12 12Z"
          className="Fill-Main"
        />
        <path
          fill="#000"
          fillOpacity={0.5}
          fillRule="evenodd"
          d="M5.293 8.293a1 1 0 0 1 1.414 0 1.828 1.828 0 0 0 2.586 0 1 1 0 0 1 1.414 1.414 3.828 3.828 0 0 1-5.414 0 1 1 0 0 1 0-1.414Zm2 7a1 1 0 0 1 1.414 0 4.657 4.657 0 0 0 6.586 0 1 1 0 0 1 1.414 1.414 6.657 6.657 0 0 1-9.414 0 1 1 0 0 1 0-1.414Zm7.414-7a1 1 0 1 0-1.414 1.414 3.828 3.828 0 0 0 5.414 0 1 1 0 0 0-1.414-1.414 1.828 1.828 0 0 1-2.586 0Z"
          className="Fill-Secondary"
          clipRule="evenodd"
        />
      </g>
    </g>
  </svg>
);

const SIZE_MAP = {
  md: Relaxed24,
  sm: Relaxed24,
  lg: Relaxed24,
  xl: Relaxed24,
  xxl: Relaxed24,
  xxxl: Relaxed24,
};

export const RelaxedIcon = ({
  size = 'md',
  'data-testid': testId,
  ...props
}: StaticIconProps) => {
  return (
    <Icon
      data-testid={testId || 'relaxed-icon'}
      icon={SIZE_MAP[size]}
      {...props}
      size={size}
      color={undefined}
    />
  );
};
