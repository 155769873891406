import type { ErrorInfo } from 'react';
import React from 'react';

import { trackError } from '@jane/shared/util';

/**
 * Can be used to wrap components and prevent errors from bubbling up the tree.
 * More info here: https://reactjs.org/docs/error-boundaries.html
 *
 */
export class ErrorBoundary extends React.Component<any, { hasError: boolean }> {
  constructor(props: any) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  override componentDidCatch(error: any, _: ErrorInfo) {
    trackError(error);
  }

  override render() {
    return this.state.hasError ? null : this.props['children'];
  }
}
