import { JaneGoldIcon, Tag } from '@jane/shared/reefer';
import type { MarginProperties } from '@jane/shared/reefer';

export interface JaneGoldTagProps extends MarginProperties {
  label: string;
}
export const JaneGoldTag = ({ label, ...marginProps }: JaneGoldTagProps) => {
  return (
    <Tag
      label={label}
      color="grays-black"
      background="gold"
      icon={<JaneGoldIcon altText="jane-gold-icon" size="sm" />}
      {...marginProps}
    />
  );
};
