import { useLocation } from 'react-router-dom';

import { useEcommApp } from '@jane/shared/providers';
import type { PaddingProperties } from '@jane/shared/reefer';
import {
  Box,
  Breadcrumbs,
  Link,
  Typography,
  useMobileMediaQuery,
} from '@jane/shared/reefer';
import type { Breadcrumb } from '@jane/shared/types';

/*
The MenuBreadcrumbs component will generate the necessary breadcrumbs for your page.
Pass the component the expected breadcrumbs as an array as well as the page title.
It will conditionally append those breadcrumbs with the breadcrumbs array stored in location.state.
If a component can't easily figure out where the user navigated from (like PDPs),
pass a state prop to the <Link /> or navigate() that navigated to that page. 
<Link to={url} state={{ breadcrumbs: Breadcrumb[] }} />
navigate(url, { state: { breadcrumbs: Breadcrumb[] }})
*/

interface MenuBreadcrumbsProps extends PaddingProperties {
  breadcrumbs: Breadcrumb[];
  title: string;
}

const addPreviousPaths = (
  breadcrumbs: Breadcrumb[],
  previousPaths?: Breadcrumb[]
) => {
  if (!previousPaths) return breadcrumbs;

  const withoutDuplicates = breadcrumbs.filter(
    (crumb) => !previousPaths.some((prev) => crumb.url === prev.url)
  );

  return [...withoutDuplicates, ...previousPaths];
};

export const MenuBreadcrumbs = ({
  breadcrumbs,
  title,
  ...paddingProps
}: MenuBreadcrumbsProps) => {
  const { appMode } = useEcommApp();
  const isMobile = useMobileMediaQuery({});
  const { state } = useLocation();

  const filterHomeCrumb =
    appMode !== 'default'
      ? breadcrumbs.filter((crumb) => crumb.url !== '/')
      : breadcrumbs;

  const newBreadcrumbs = addPreviousPaths(filterHomeCrumb, state?.breadcrumbs);

  if (isMobile) return null;

  return (
    <Box {...paddingProps}>
      <Breadcrumbs>
        {newBreadcrumbs.map((breadcrumb) => (
          <Link key={breadcrumb.title} to={breadcrumb.url}>
            {breadcrumb.title}
          </Link>
        ))}
        <Typography>{title}</Typography>
      </Breadcrumbs>
    </Box>
  );
};
