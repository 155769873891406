import type { SearchResponse } from '@algolia/client-search';
import { useQuery } from '@tanstack/react-query';
import { useEffect, useState } from 'react';

import { getAlgoliaIndex } from '@jane/search/data-access';
import type { AlgoliaProduct } from '@jane/search/types';
import { buildMenuProductFilterString } from '@jane/search/util';
import type { Id, MenuProduct } from '@jane/shared/models';
import { deserializeCannabinoids } from '@jane/shared/util';

export const DEFAULT_HITS_PER_PAGE = 16;

type UseAlgoliaMenuProductsProps = {
  callback?: () => void;
  hitsPerPage?: number;
  productIds: number[];
  storeId: Id;
};

export const useAlgoliaMenuProducts = ({
  productIds,
  storeId,
  hitsPerPage = DEFAULT_HITS_PER_PAGE,
  callback,
}: UseAlgoliaMenuProductsProps): MenuProduct[] | undefined => {
  const [menuProducts, setMenuProducts] = useState<MenuProduct[]>();
  const filters = buildMenuProductFilterString(storeId, productIds);

  useEffect(() => {
    if (!productIds || productIds.length === 0) return;

    fetchAlgoliaMenuProducts({ filters, hitsPerPage })
      .then((menuProducts: MenuProduct[]) => {
        setMenuProducts(menuProducts);
      })
      .catch((e) => {
        // do nothing
      })
      .finally(() => {
        if (callback) {
          callback();
        }
      });
  }, [productIds, storeId, hitsPerPage]);

  return menuProducts;
};

interface FetchProps {
  filters: string | undefined;
  hitsPerPage: number;
}
const fetchAlgoliaMenuProducts = async ({
  filters,
  hitsPerPage,
}: FetchProps): Promise<MenuProduct[]> => {
  const index = getAlgoliaIndex('menu-products-');
  const searchResponse: SearchResponse<AlgoliaProduct> = await index.search(
    '',
    {
      filters,
      hitsPerPage,
    }
  );
  const hits = searchResponse.hits;
  return hits.map((hit) => deserializeCannabinoids(hit, 'menuProduct'));
};

export const useAlgoliaMenuProductsQuery = (
  props: UseAlgoliaMenuProductsProps
) => {
  const { storeId, productIds, hitsPerPage = DEFAULT_HITS_PER_PAGE } = props;
  const filters = buildMenuProductFilterString(storeId, productIds);

  return useQuery({
    enabled: !!productIds.length,
    queryFn: async () =>
      await fetchAlgoliaMenuProducts({ filters, hitsPerPage }),
    queryKey: ['algolia-menu-products', filters, hitsPerPage],
  });
};
