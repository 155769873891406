export const zoneCart = 'cart';
export const zoneStoreMenu = 'storeMenu';
export const zoneStoreMenuInline = 'storeMenuInline';
export const zoneStorePdp = 'storePDP';
export const zoneRecommendedRow = 'magicRow';
export const zones = [
  zoneCart,
  zoneStoreMenu,
  zoneStoreMenuInline,
  zoneStorePdp,
  zoneRecommendedRow,
] as const;
export type Zone = typeof zones[number];
