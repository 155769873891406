import type { ReactNode } from 'react';

import { Box, Typography, VerifiedIcon } from '@jane/shared/reefer';

import {
  ApplyableDiscountItem,
  ApplyableDiscountWrapper,
} from './customerLoyaltyPoints.styles';

interface ApplyableDiscountProps {
  applied: boolean;
  children?: ReactNode;
  enabled: boolean;
  onClick?: (e) => void;
  text: string;
}

export const ApplyableDiscount = ({
  applied,
  enabled,
  onClick,
  text,
  children,
}: ApplyableDiscountProps) => {
  return (
    <ApplyableDiscountWrapper enabled={enabled} onClick={onClick} role="button">
      <Box mr={8}>
        <VerifiedIcon mt={2} color={applied ? 'success' : 'grape-light'} />
      </Box>
      <ApplyableDiscountItem>
        <Typography color="grays-black" variant="body">
          {text}
          {enabled && children}
        </Typography>
      </ApplyableDiscountItem>
    </ApplyableDiscountWrapper>
  );
};
