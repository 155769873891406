import { COLORS } from './colors';
import type { StaticThemeConfig } from './theme.types';
import { calculateColorGroup } from './utils';

/**
 * All Reefer theme configuration that cannot be customized
 */
export const STATIC_CONFIG: StaticThemeConfig = {
  colors: {
    brand: {
      gold: calculateColorGroup(COLORS.brand.gold),
      tangerine: calculateColorGroup(COLORS.brand.tangerine),
      ember: calculateColorGroup(COLORS.brand.ember),
      sunset: calculateColorGroup(COLORS.brand.sunset),
      grape: calculateColorGroup(COLORS.brand.grape),
      purple: calculateColorGroup(COLORS.brand.purple),
    },

    tertiary: {
      emerald: calculateColorGroup(COLORS.tertiary.emerald),
      palm: calculateColorGroup(COLORS.tertiary.palm),
      seafoam: calculateColorGroup(COLORS.tertiary.seafoam),
      pacific: calculateColorGroup(COLORS.tertiary.pacific),
    },

    system: {
      positive: calculateColorGroup(COLORS.system.positive),
      negative: calculateColorGroup(COLORS.system.negative),
    },

    grays: {
      white: COLORS.grays.white,
      ultralight: COLORS.grays.ultralight,
      light: COLORS.grays.light,
      mid: COLORS.grays.mid,
      dark: COLORS.grays.dark,
      black: COLORS.grays.black,
      hover: COLORS.grays.hover,
    },

    transparent: {
      black: {
        five: COLORS.transparent.black.five,
        ten: COLORS.transparent.black.ten,
        twenty: COLORS.transparent.black.twenty,
        fifty: COLORS.transparent.black.fifty,
      },
      white: {
        ten: COLORS.transparent.white.ten,
      },
    },
  },
};
