import type { AdFlight } from '@jane/shared/models';
import type { Nullable } from '@jane/shared/types';

interface GenerateCustomMenuRowTitleProps {
  flight?: Nullable<AdFlight>;
  storeState: string;
}

export const generateCustomMenuRowTitle = ({
  flight,
  storeState,
}: GenerateCustomMenuRowTitleProps) =>
  (storeState === 'Pennsylvania'
    ? flight?.product_brand.name
    : flight?.custom_title) || flight?.product_brand.name;
