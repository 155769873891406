import {
  Box,
  Card,
  Flex,
  JaneGoldLogo,
  Typography,
  useMobileMediaQuery,
} from '@jane/shared/reefer';

import {
  JaneGoldLearnMoreLink,
  JaneGoldTermsLink,
} from '../../janeGoldCallout';

export const JaneGoldSubheader = ({
  brandName,
  isGuestUser,
  isJaneGoldMember,
}: {
  brandName: string;
  isGuestUser?: boolean;
  isJaneGoldMember?: boolean;
}) => {
  const isMobile = useMobileMediaQuery({ size: 'legacy' });

  if (isMobile)
    return (
      <Card mt={24} flat border="grays-light" width="100%">
        <Card.Content>
          <Flex flexDirection="column" px={4}>
            <JaneGoldLogo height={24} width={60} />
            <Typography as="h3">
              Earn exclusive cash back rewards from {brandName} with Jane
              Gold.&nbsp;
              <Box as="span" mr={4}>
                <JaneGoldLearnMoreLink userHasLinkedBank={isJaneGoldMember} />
              </Box>
              {isGuestUser && <JaneGoldTermsLink />}
            </Typography>
          </Flex>
        </Card.Content>
      </Card>
    );

  return (
    <Flex flexDirection="column" mt={16}>
      <JaneGoldLogo height={24} width={60} />
      <Typography as="span">
        Earn exclusive cash back rewards from {brandName} with Jane Gold.&nbsp;
        <Box as="span" mr={4}>
          <JaneGoldLearnMoreLink userHasLinkedBank={isJaneGoldMember} />
        </Box>
        {isGuestUser && <JaneGoldTermsLink />}
      </Typography>
    </Flex>
  );
};
