import mixpanelBrowser from 'mixpanel-browser';
import type { Config, Dict, Mixpanel, RequestOptions } from 'mixpanel-browser';

import { config } from '@jane/shared/config';

declare module 'mixpanel-browser' {
  export interface Config {
    api_payload_format: string;
    record_sessions_percent: number;
  }
}

export const initMixpanel = (
  token: string | undefined,
  {
    userSessionHost,
    useLocalStorage,
  }: {
    useLocalStorage?: boolean;
    userSessionHost?: string;
  } = {}
) => {
  if (useLocalStorage) {
    globalThis.janeMixpanel = new MixpanelClass({
      token,
      mpConfig: {
        persistence: 'localStorage',
        api_host: config.mixpanelDomain,
      },
    });
  } else {
    globalThis.janeMixpanel = new MixpanelClass({
      token,
      mpConfig: {
        api_host: config.mixpanelDomain,
      },
    });
  }

  if (userSessionHost) {
    globalThis.userSessionMixpanel = new MixpanelClass({
      token: token + '_usersession',
      mpConfig: {
        api_host: userSessionHost,
        api_payload_format: 'json',
        persistence: 'localStorage',
        batch_flush_interval_ms: 4000,
      },
      projectName: 'user-session',
    });
  } else {
    globalThis.userSessionMixpanel = new MixpanelClass({
      token: '',
      projectName: 'user-session',
    });
  }
};

export const initCrmMixpanel = (token: string | undefined) => {
  if (!token) return;
  global.crmMixpanel = new MixpanelClass({
    token,
    mpConfig: {
      api_host: config.mixpanelDomain,
    },
  });
};

/**
 * Allows calling track and then waiting for the callback to complete using async/await.
 */
export const trackWithCallbackAsync = (
  eventName: string,
  params: Dict,
  options: RequestOptions
) => {
  return new Promise((res) => {
    janeMixpanel.track(eventName, params, options, () => {
      res(null);
    });
  });
};

/**
 * This prevents infinite loop errors that crash the app if the Mixpanel
 * token is undefined. The "normal" mixpanel script either sets the global object
 * or not, but this npm package either initializes or crashes the app.
 */
export class MixpanelClass {
  private readonly enableLogging: boolean = false;

  private readonly mp: Mixpanel | undefined;

  constructor({
    mpConfig,
    projectName,
    token,
  }: {
    mpConfig?: Partial<Config>;
    projectName?: string;
    token: string | undefined;
  }) {
    if (token) {
      const finalMpConfig = {
        ...(mpConfig || {}),
        record_sessions_percent: 1,
      };
      if (config.nodeEnv !== 'production') {
        finalMpConfig.debug = true;
      }
      this.mp = mixpanelBrowser.init(
        token,
        finalMpConfig,
        projectName ?? 'default'
      );
    } else {
      // eslint-disable-next-line no-console
      console.warn('Mixpanel was not initialized, no token was provided.');
    }

    if (config.logMixpanelToConsole === 'true') {
      this.enableLogging = true;
    }
  }

  private maybeLog = (log: unknown[]) => {
    // eslint-disable-next-line no-console
    return this.enableLogging ? console.log(...log) : null;
  };

  public alias = (...args: Parameters<Mixpanel['alias']>) => {
    return this.mp
      ? this.mp.alias(...args)
      : this.maybeLog(['mixpanel.alias', ...args]);
  };

  public get_distinct_id = () => {
    return this.mp
      ? this.mp.get_distinct_id()
      : this.maybeLog(['mixpanel.get_distinct_id']);
  };

  public identify = (...args: Parameters<Mixpanel['identify']>) => {
    return this.mp
      ? this.mp.identify(...args)
      : this.maybeLog(['mixpanel.identify', ...args]);
  };

  public register = (...args: Parameters<Mixpanel['register']>) => {
    return this.mp
      ? this.mp.register(...args)
      : this.maybeLog(['mixpanel.register', ...args]);
  };

  public people = {
    set: (...args: Parameters<Mixpanel['people']['set']>) => {
      return this.mp
        ? this.mp.people.set(...args)
        : this.maybeLog(['mixpanel.people:set', ...args]);
    },
    set_once: (...args: Parameters<Mixpanel['people']['set_once']>) => {
      return this.mp
        ? this.mp.people.set_once(...args)
        : this.maybeLog(['mixpanel.people:set_once', ...args]);
    },
  };

  public track = (...args: Parameters<Mixpanel['track']>) => {
    return this.mp
      ? this.mp.track(...args)
      : this.maybeLog(['mixpanel.track', args[0], args[1]]);
  };

  public reset = () => {
    return this.mp ? this.mp.reset() : this.maybeLog(['mixpanel.reset']);
  };

  public unregister = (...args: Parameters<Mixpanel['unregister']>) => {
    return this.mp
      ? this.mp.unregister(...args)
      : this.maybeLog(['mixpanel.unregister', ...args]);
  };
}
