import styled from '@emotion/styled';

import { mediaQueries, shadows, spacing } from '@jane/shared/reefer-emotion';

export const NestedRangeWrapper = styled.div({
  display: 'flex',
  flexDirection: 'column',
  [mediaQueries.tablet('min')]: {
    '> div:first-of-type > div': {
      ...spacing({ pr: 8 }),
    },
    '> div:last-of-type > div': {
      ...spacing({ pl: 8 }),
    },
    flexDirection: 'row',
  },
});

export const ModalSectionButton = styled.div({
  alignItems: 'center',
  cursor: 'pointer',
  display: 'flex',
  justifyContent: 'space-between',
  ...spacing({ px: 24, py: 20 }),
  [mediaQueries.tablet('min')]: {
    ...spacing({ px: 40 }),
  },
});

export const ItemDivider = styled.span({
  [mediaQueries.tablet('max')]: { boxShadow: shadows.divider },
});

export const StyledModalTypeaheadHeader = styled.div({
  width: '100%',
  display: 'flex',
  justifyContent: 'space-between',
  flexWrap: 'wrap',
  boxShadow: shadows.divider,
  ...spacing({
    px: 24,
    py: 16,
  }),
});

export const IconContainer = styled.div({
  zIndex: 1,
  position: 'relative',
});
