import type { SVGProps } from 'react';

import { Icon } from '../../icon';
import type { LogoProps } from '../logo.types';

const JanePayWhite = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 98 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M21.4287 11.465L21.4182 0.0357L18.8134 0.0380983L18.8237 11.2454C18.8253 12.9543 17.7796 13.6432 16.5996 13.6443C15.3974 13.6454 14.3281 12.8918 14.3267 11.4271L11.7442 11.4295C11.7471 14.5365 13.9971 16.0657 16.6019 16.0633C19.1399 16.061 21.4318 14.7273 21.4287 11.465Z"
      fill="#FFFFFF"
    />
    <path
      d="M23.2158 10.2427C23.2188 13.4163 25.5365 16.0107 28.5866 16.0079C30.1896 16.0064 31.5246 15.184 32.1472 14.2958L32.1485 15.7827L34.5975 15.7804L34.5872 4.68405L32.1383 4.68631L32.1396 6.17322C31.5154 5.28608 30.1789 4.46618 28.5759 4.46766C25.5258 4.47047 23.2129 7.06915 23.2158 10.2427ZM32.3438 10.2343C32.3456 12.2316 31.0113 13.7864 29.0298 13.7882C27.0484 13.79 25.7112 12.2378 25.7093 10.2404C25.7074 8.24307 27.0418 6.68835 29.0232 6.68652C31.0047 6.6847 32.3419 8.23696 32.3438 10.2343Z"
      fill="#FFFFFF"
    />
    <path
      d="M37.2473 15.778L39.6963 15.7757L39.6913 10.3163C39.6891 7.94169 40.9346 6.67555 42.5598 6.67406C43.9847 6.67275 44.8762 7.73718 44.8778 9.4904L44.8837 15.7709L47.3326 15.7687L47.3264 9.08868C47.3238 6.2702 45.6524 4.45194 43.0698 4.45431C41.6227 4.45565 40.3543 5.07821 39.6874 6.18846L39.686 4.67936L37.2371 4.68161L37.2473 15.778Z"
      fill="#FFFFFF"
    />
    <path
      d="M49.0139 10.219C49.017 13.5922 51.446 15.9868 54.83 15.9837C57.1676 15.9816 58.9032 14.9813 59.9036 13.4047L58.0323 12.0748C57.4543 13.0519 56.4086 13.763 54.8502 13.7644C53.0023 13.7661 51.5319 12.5691 51.4188 10.7494L60.1683 10.7413C60.1903 10.3862 60.1901 10.1865 60.1898 9.94235C60.1865 6.36932 57.7358 4.44081 54.8193 4.4435C51.4353 4.44661 49.0109 6.95662 49.0139 10.219ZM54.7322 6.5075C56.157 6.50619 57.4491 7.37052 57.7177 8.96815L51.5285 8.97385C51.8609 7.33128 53.3073 6.50881 54.7322 6.5075Z"
      fill="#FFFFFF"
    />
    <path
      d="M6.85455 1.82808C6.32617 1.13337 5.30444 0.168944 3.69928 0.170422C1.63682 0.172321 0.00163178 2.03372 0.00333578 3.87274C0.0050403 5.71231 0.925588 7.15182 2.76613 8.80739C4.60031 10.458 5.22982 10.6238 6.85542 11.4932C8.49615 10.6208 9.12593 10.4539 10.9565 8.79985C12.7939 7.14089 13.7113 5.69969 13.7095 3.86012C13.7078 2.0211 12.0698 0.162715 10.0067 0.164615C8.40217 0.166092 7.38165 1.13239 6.85455 1.82808Z"
      fill="#FFC220"
    />
    <path
      d="M73.1428 5.43506C73.1453 8.17833 71.0956 10.2871 67.8084 10.2901L64.8301 10.2928L64.8356 16.1525L63.1589 16.154L63.1445 0.572205L67.7994 0.567919C71.0866 0.564892 73.1402 2.66984 73.1428 5.43506ZM71.4441 5.43662C71.4423 3.54925 70.1835 2.1239 67.8009 2.1261L64.8226 2.12884L64.8287 8.73465L67.807 8.7319C70.1896 8.72971 71.4458 7.30205 71.4441 5.43662Z"
      fill="#FFFFFF"
    />
    <path
      d="M79.3806 16.3585C76.2479 16.3614 73.885 13.7959 73.882 10.6576C73.8791 7.51927 76.2373 4.94939 79.3701 4.94651C81.0688 4.94494 82.658 5.82133 83.4533 7.00569L83.4516 5.16221L84.9959 5.16079L85.0061 16.1339L83.4618 16.1353L83.4601 14.2918C82.667 15.4777 81.0794 16.357 79.3806 16.3585ZM79.5337 14.9319C81.9384 14.9297 83.5913 13.0188 83.5891 10.6486C83.5869 8.27845 81.9305 6.37065 79.5258 6.37287C77.1211 6.37508 75.4683 8.28593 75.4705 10.6561C75.4727 13.0263 77.129 14.9341 79.5337 14.9319Z"
      fill="#FFFFFF"
    />
    <path
      d="M90.6755 20.7154L88.9326 20.717L91.3546 15.5794L86.4473 5.15946L88.1681 5.15787L92.2134 13.8668L96.2426 5.15044L97.9855 5.14883L90.6755 20.7154Z"
      fill="#FFFFFF"
    />
  </svg>
);
export const JanePayLogoWhite = ({
  height = 48,
  width = 'auto',
  ...props
}: LogoProps) => {
  return (
    <Icon
      height={height}
      width={width}
      {...props}
      icon={JanePayWhite}
      color={undefined}
      rotate={undefined}
      size={undefined}
    />
  );
};
