import poweredByGoogle from 'shared/assets/powered_by_google_on_white.png';
import poweredByGoogleHdpi from 'shared/assets/powered_by_google_on_white_hdpi.png';

import { Flex, Image } from '@jane/shared/reefer';

export const GoogleFooter = () => {
  return (
    <Flex p={4} justifyContent="flex-end">
      <Image
        width="120px"
        objectFit="scale-down"
        srcSet={`${poweredByGoogle}, ${poweredByGoogleHdpi} 2x`}
        src={poweredByGoogle}
        altText="Powered by Google"
      />
    </Flex>
  );
};
