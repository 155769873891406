import type {
  MenuProduct,
  PriceId,
  ReservationCartProduct,
  WeightSelectorWeight,
} from '@jane/shared/models';
import { Flex, useMobileMediaQuery } from '@jane/shared/reefer';
import {
  getTotalOriginalAndDiscountedPrices,
  isSoldByWeight,
} from '@jane/shared/util';

import {
  DisplayMode,
  useProductCardContext,
} from '../../productCardProvider/productCardProvider';
import Confirmation from './confirmation';
import { StyledActionCardContent } from './listViewProductCard.styles';
import WeightSelectorContainer from './weightSelectorContainer';

interface ActionCardContentProps {
  noSelectedWeightDefault: boolean;
  onWeightSelected: (selectedWeight: PriceId) => void;
  selectedWeight: PriceId;
  weights: WeightSelectorWeight[];
}

const ActionCardContent = ({
  noSelectedWeightDefault,
  onWeightSelected,
  selectedWeight,
  weights,
}: ActionCardContentProps) => {
  const { cartProduct, displayMode, menuProduct } = useProductCardContext();
  const isAd = !!menuProduct?.flight;
  const isMobile = useMobileMediaQuery({});
  const { totalOriginalPrice, totalDiscountedPrice } =
    getTotalOriginalAndDiscountedPrices({
      menuProduct: menuProduct as MenuProduct,
      cartProduct: cartProduct as ReservationCartProduct[],
    });

  return (
    <StyledActionCardContent
      background={
        displayMode === DisplayMode.Confirmation
          ? 'grays-ultralight'
          : 'grays-white'
      }
    >
      <Flex
        flexDirection="column"
        alignItems={
          displayMode === DisplayMode.Confirmation ? 'center' : 'flex-end'
        }
        justifyContent="center"
        grow={2}
        height="100%"
        pt={
          isAd && !isMobile && displayMode !== DisplayMode.Confirmation ? 40 : 0
        }
      >
        {displayMode === DisplayMode.Confirmation && (
          <Confirmation
            discountedPrice={totalDiscountedPrice}
            onWeightSelected={onWeightSelected}
            originalPrice={totalOriginalPrice}
          />
        )}
        {(displayMode === DisplayMode.Product ||
          displayMode === DisplayMode.Edit) && (
          <WeightSelectorContainer
            isSoldByWeight={
              menuProduct ? isSoldByWeight(menuProduct.kind) : false
            }
            noSelectedWeightDefault={noSelectedWeightDefault}
            onWeightSelected={onWeightSelected}
            selectedWeight={selectedWeight}
            weights={weights}
          />
        )}
      </Flex>
    </StyledActionCardContent>
  );
};

export default ActionCardContent;
