/**
 * DO NOT MODIFY THIS FILE!
 * It was created by the reefer-icons generator.
 * Please update & re-run the generator if need be.
 */
import type { SVGProps } from 'react';

import type { DynamicIconProps } from '../../../icon/icon.types';
import { Icon } from '../../icon';

const BankCircled64 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="100%"
    height="100%"
    fill="none"
    viewBox="0 0 64 64"
    {...props}
  >
    <g className="Bank circled 64">
      <path
        fill="#0E0E0E"
        fillRule="evenodd"
        d="M64 32c0 17.673-14.327 32-32 32C14.327 64 0 49.673 0 32 0 14.327 14.327 0 32 0c17.673 0 32 14.327 32 32Zm-4 0c0 15.464-12.536 28-28 28S4 47.464 4 32 16.536 4 32 4s28 12.536 28 28Z"
        className="Union"
        clipRule="evenodd"
      />
      <path
        fill="#0E0E0E"
        d="M30.137 16.98a4 4 0 0 1 3.726 0l11.765 6.193c1.386.729.867 2.827-.699 2.827H19.071c-1.566 0-2.085-2.098-.699-2.827l11.765-6.192Z"
        className="Polygon 5"
      />
      <path
        fill="#0E0E0E"
        fillRule="evenodd"
        d="M29 28a2 2 0 0 1 2 2v8a2 2 0 1 1-4 0v-8a2 2 0 0 1 2-2Z"
        className="Vector 168 (Stroke)"
        clipRule="evenodd"
      />
      <path
        fill="#0E0E0E"
        fillRule="evenodd"
        d="M35 28a2 2 0 0 1 2 2v8a2 2 0 1 1-4 0v-8a2 2 0 0 1 2-2Z"
        className="Vector 172 (Stroke)"
        clipRule="evenodd"
      />
      <path
        fill="#0E0E0E"
        fillRule="evenodd"
        d="M46 44a2 2 0 0 1-2 2H20a2 2 0 1 1 0-4h24a2 2 0 0 1 2 2Z"
        className="Vector 171 (Stroke)"
        clipRule="evenodd"
      />
      <path
        fill="#0E0E0E"
        fillRule="evenodd"
        d="M23 28a2 2 0 0 1 2 2v8a2 2 0 1 1-4 0v-8a2 2 0 0 1 2-2Z"
        className="Vector 169 (Stroke)"
        clipRule="evenodd"
      />
      <path
        fill="#0E0E0E"
        fillRule="evenodd"
        d="M41 28a2 2 0 0 1 2 2v8a2 2 0 1 1-4 0v-8a2 2 0 0 1 2-2Z"
        className="Vector 170 (Stroke)"
        clipRule="evenodd"
      />
    </g>
  </svg>
);

const SIZE_MAP = {
  xxl: BankCircled64,
  xl: BankCircled64,
  lg: BankCircled64,
  md: BankCircled64,
  sm: BankCircled64,
  xxxl: BankCircled64,
};

export const BankCircledIcon = ({
  size = 'md',
  'data-testid': testId,
  ...props
}: DynamicIconProps) => {
  return (
    <Icon
      data-testid={testId || 'bank-circled-icon'}
      icon={SIZE_MAP[size]}
      {...props}
      size={size}
    />
  );
};
