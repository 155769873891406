import { Card as BaseCard } from './card';
import type { CardProps } from './card';
import { CardAction } from './cardAction';
import type { CardActionProps } from './cardAction';
import { CardContent } from './cardContent';
import type { CardContentProps } from './cardContent';
import { CardGroup } from './cardGroup';
import type { CardGroupProps } from './cardGroup';
import { CardImage } from './cardImage';
import type { CardImageProps } from './cardImage';

export type {
  CardActionProps,
  CardContentProps,
  CardGroupProps,
  CardImageProps,
  CardProps,
};

export const Card = Object.assign(BaseCard, {
  Image: CardImage,
  Action: CardAction,
  Content: CardContent,
  Group: CardGroup,
});
