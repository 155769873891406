/* eslint-disable-next-line no-restricted-imports */
import { useFlags as useLDFlags } from 'launchdarkly-react-client-sdk';
import { useMemo } from 'react';

export type FlagsType = Record<string, boolean | string>;

export function useFlag<T = boolean>(flagName: string, defaultValue?: T) {
  const flags = useFlags();

  return (flags[flagName] ?? defaultValue) as T;
}

export const useFlags = () => {
  const flagsLD = useLDFlags();
  const params = new URL(document.location.toString()).searchParams;
  const featureFlagsOn = params.get('featureFlagsOn')?.split(',');
  const featureFlagsOff = params.get('featureFlagsOff')?.split(',');

  const flags: FlagsType = useMemo(() => {
    const flagsBase: FlagsType = {};

    if (!flagsLD) return flagsBase;

    Object.keys(flagsLD).forEach((flag) => {
      if (featureFlagsOn?.includes(flag)) {
        flagsBase[flag] = true;
      } else if (featureFlagsOff?.includes(flag)) {
        flagsBase[flag] = false;
      } else {
        flagsBase[flag] = flagsLD[flag];
      }
    });
    return flagsBase;
  }, [flagsLD, featureFlagsOn, featureFlagsOff]);

  return flags;
};
