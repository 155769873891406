/**
 * DO NOT MODIFY THIS FILE!
 * It was created by the reefer-icons generator.
 * Please update & re-run the generator if need be.
 */
import type { SVGProps } from 'react';

import type { DynamicIconProps } from '../../../icon/icon.types';
import { Icon } from '../../icon';

const ProfileCircled24 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="100%"
    height="100%"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <g className="Profile circled 24">
      <path
        fill="#0E0E0E"
        fillRule="evenodd"
        d="M9 10a3 3 0 1 1 6 0 3 3 0 0 1-6 0Zm6 4a5 5 0 1 0-6 0 4.992 4.992 0 0 0-2 4h2a3 3 0 1 1 6 0h2a4.992 4.992 0 0 0-2-4Z"
        className="SVG"
        clipRule="evenodd"
      />
      <circle
        cx={12}
        cy={12}
        r={11}
        stroke="#0E0E0E"
        strokeWidth={2}
        className="Ellipse 731"
      />
    </g>
  </svg>
);

const SIZE_MAP = {
  md: ProfileCircled24,
  sm: ProfileCircled24,
  lg: ProfileCircled24,
  xl: ProfileCircled24,
  xxl: ProfileCircled24,
  xxxl: ProfileCircled24,
};

export const ProfileCircledIcon = ({
  size = 'md',
  'data-testid': testId,
  ...props
}: DynamicIconProps) => {
  return (
    <Icon
      data-testid={testId || 'profile-circled-icon'}
      icon={SIZE_MAP[size]}
      {...props}
      size={size}
    />
  );
};
