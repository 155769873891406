/**
 * DO NOT MODIFY THIS FILE!
 * It was created by the reefer-icons generator.
 * Please update & re-run the generator if need be.
 */
import type { SVGProps } from 'react';

import type { StaticIconProps } from '../../../icon/icon.types';
import { Icon } from '../../icon';

const Bipoc24 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="100%"
    height="100%"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <g className="Bipoc 24">
      <g className="Fill-Main">
        <path
          fill="#F90"
          d="M20.485 3.93a6 6 0 0 0-8.485 0l-1.414 1.413 8.485 8.486 1.414-1.415a6 6 0 0 0 0-8.485Z"
        />
        <path
          fill="#000"
          fillOpacity={0.5}
          d="M20.485 3.93a6 6 0 0 0-8.485 0l-1.414 1.413 8.485 8.486 1.414-1.415a6 6 0 0 0 0-8.485Z"
        />
      </g>
      <g className="Fill-Main">
        <path
          fill="#F90"
          d="M2.807 15.95a2 2 0 0 0 2.829 0l8.485-8.485-2.828-2.829-8.486 8.485a2 2 0 0 0 0 2.829Z"
        />
        <path
          fill="#000"
          fillOpacity={0.5}
          d="M2.807 15.95a2 2 0 0 0 2.829 0l8.485-8.485-2.828-2.829-8.486 8.485a2 2 0 0 0 0 2.829Z"
        />
      </g>
      <g className="Fill-Main">
        <path
          fill="#F90"
          d="M8.464 21.607a2 2 0 0 0 2.829 0l8.485-8.486-2.828-2.828-8.486 8.485a2 2 0 0 0 0 2.829Z"
        />
        <path
          fill="#000"
          fillOpacity={0.5}
          d="M8.464 21.607a2 2 0 0 0 2.829 0l8.485-8.486-2.828-2.828-8.486 8.485a2 2 0 0 0 0 2.829Z"
        />
      </g>
      <g className="Fill-Main">
        <path
          fill="#F90"
          d="M4.93 19.485a2 2 0 0 0 2.827 0L16.243 11l-2.829-2.83-8.484 8.487a2 2 0 0 0 0 2.828Z"
        />
        <path
          fill="#000"
          fillOpacity={0.5}
          d="M4.93 19.485a2 2 0 0 0 2.827 0L16.243 11l-2.829-2.83-8.484 8.487a2 2 0 0 0 0 2.828Z"
        />
      </g>
      <g className="Fill-Secondary">
        <path
          fill="#F90"
          d="M3.515 3.929a6 6 0 0 1 8.485 0l1.414 1.414-8.485 8.485-1.414-1.414a6 6 0 0 1 0-8.485Z"
        />
        <path
          fill="#000"
          fillOpacity={0.2}
          d="M3.515 3.929a6 6 0 0 1 8.485 0l1.414 1.414-8.485 8.485-1.414-1.414a6 6 0 0 1 0-8.485Z"
        />
      </g>
      <g className="Fill-Secondary">
        <path
          fill="#F90"
          d="M21.192 15.95a2 2 0 0 1-2.828 0L9.88 7.465l2.828-2.829 8.485 8.485a2 2 0 0 1 0 2.829Z"
        />
        <path
          fill="#000"
          fillOpacity={0.2}
          d="M21.192 15.95a2 2 0 0 1-2.828 0L9.88 7.465l2.828-2.829 8.485 8.485a2 2 0 0 1 0 2.829Z"
        />
      </g>
      <g className="Fill-Secondary">
        <path
          fill="#F90"
          d="M15.536 21.607a2 2 0 0 1-2.829 0L4.222 13.12l2.828-2.828 8.486 8.485a2 2 0 0 1 0 2.829Z"
        />
        <path
          fill="#000"
          fillOpacity={0.2}
          d="M15.536 21.607a2 2 0 0 1-2.829 0L4.222 13.12l2.828-2.828 8.486 8.485a2 2 0 0 1 0 2.829Z"
        />
      </g>
      <g className="Fill-Secondary">
        <path
          fill="#F90"
          d="M19.071 19.485a2 2 0 0 1-2.829 0L7.758 11l2.829-2.829 8.485 8.486a2 2 0 0 1 0 2.828Z"
        />
        <path
          fill="#000"
          fillOpacity={0.2}
          d="M19.071 19.485a2 2 0 0 1-2.829 0L7.758 11l2.829-2.829 8.485 8.486a2 2 0 0 1 0 2.828Z"
        />
      </g>
      <g className="Fill-Main">
        <path
          fill="#F90"
          d="M7.757 11a2 2 0 0 0 2.829 0l4.242-4.243L12 3.93 7.757 8.172a2 2 0 0 0 0 2.828Z"
        />
        <path
          fill="#000"
          fillOpacity={0.5}
          d="M7.757 11a2 2 0 0 0 2.829 0l4.242-4.243L12 3.93 7.757 8.172a2 2 0 0 0 0 2.828Z"
        />
      </g>
      <g className="Fill-Main">
        <path
          fill="#F90"
          d="M16.243 8.172a2 2 0 0 0-2.829 0l1.415-1.415 1.414 1.415Z"
        />
        <path
          fill="#000"
          fillOpacity={0.5}
          d="M16.243 8.172a2 2 0 0 0-2.829 0l1.415-1.415 1.414 1.415Z"
        />
      </g>
    </g>
  </svg>
);

const SIZE_MAP = {
  md: Bipoc24,
  sm: Bipoc24,
  lg: Bipoc24,
  xl: Bipoc24,
  xxl: Bipoc24,
  xxxl: Bipoc24,
};

export const BipocIcon = ({
  size = 'md',
  'data-testid': testId,
  ...props
}: StaticIconProps) => {
  return (
    <Icon
      data-testid={testId || 'bipoc-icon'}
      icon={SIZE_MAP[size]}
      {...props}
      size={size}
      color={undefined}
    />
  );
};
