/**
 * DO NOT MODIFY THIS FILE!
 * It was created by the reefer-icons generator.
 * Please update & re-run the generator if need be.
 */
import type { SVGProps } from 'react';

import type { StaticIconProps } from '../../../icon/icon.types';
import { Icon } from '../../icon';

const RelaxedWave24 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="100%"
    height="100%"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <g className="Relaxed wave">
      <g className="SVG">
        <path
          fill="#8E00E6"
          d="M3 1a1 1 0 0 1 1-1c1.044 0 2.043.484 2.77 1.362C7.148 1.818 7.597 2 8 2s.853-.182 1.23-.638C9.957.484 10.956 0 12 0c1.045 0 2.043.484 2.77 1.362.378.456.827.638 1.23.638.404 0 .853-.182 1.23-.638C17.957.484 18.956 0 20 0a1 1 0 0 1 0 2c-.403 0-.852.183-1.23.638C18.044 3.516 17.046 4 16 4c-1.044 0-2.042-.484-2.77-1.362C12.853 2.182 12.404 2 12 2c-.403 0-.852.182-1.23.638C10.043 3.516 9.045 4 8 4c-1.044 0-2.043-.484-2.77-1.362C4.853 2.182 4.403 2 4 2a1 1 0 0 1-1-1Zm9 5C5.373 6 0 11.373 0 18v6h24v-6c0-6.627-5.373-12-12-12Z"
          className="Fill-Main"
        />
        <path
          fill="#0E0E0E"
          d="M5.362 12.23a1 1 0 0 1 1.408.132c.377.456.827.638 1.23.638s.853-.182 1.23-.638a1 1 0 1 1 1.54 1.276C10.043 14.516 9.044 15 8 15s-2.043-.484-2.77-1.362a1 1 0 0 1 .132-1.408Zm1.782 6.253a1 1 0 0 1 1.373-.34C9.43 18.696 10.688 19 12 19c1.312 0 2.57-.305 3.483-.856a1 1 0 0 1 1.034 1.712C15.22 20.638 13.584 21 12 21c-1.584 0-3.221-.362-4.517-1.144a1 1 0 0 1-.34-1.373Zm7.626-6.121a1 1 0 1 0-1.54 1.276C13.957 14.516 14.956 15 16 15s2.043-.484 2.77-1.362a1 1 0 1 0-1.54-1.276c-.377.456-.827.638-1.23.638s-.853-.182-1.23-.638Z"
          className="Fill-Secondary"
        />
      </g>
    </g>
  </svg>
);

const SIZE_MAP = {
  md: RelaxedWave24,
  sm: RelaxedWave24,
  lg: RelaxedWave24,
  xl: RelaxedWave24,
  xxl: RelaxedWave24,
  xxxl: RelaxedWave24,
};

export const RelaxedWaveIcon = ({
  size = 'md',
  'data-testid': testId,
  ...props
}: StaticIconProps) => {
  return (
    <Icon
      data-testid={testId || 'relaxed-wave-icon'}
      icon={SIZE_MAP[size]}
      {...props}
      size={size}
      color={undefined}
    />
  );
};
