import type { BrandPartner, Store } from '@jane/shared/models';

export const buildOptionalFilters = ({
  brandPartner,
  store,
}: {
  brandPartner?: BrandPartner;
  store?: Store;
}): string | undefined => {
  const optionalFilters =
    brandPartner?.optional_filters || store?.optional_filters;
  return optionalFilters
    ? Object.entries(optionalFilters).reduce(buildOptionalFilterString, '')
    : undefined;
};

export const buildOptionalFilterString = (
  accumulator: string,
  filterEntry: [string, readonly (number | string)[]],
  index: number
): string => {
  const facetKey = filterEntry[0];
  const facetValues = filterEntry[1];

  if (Array.isArray(facetValues)) {
    const stringPerFacetKey = facetValues.reduce(
      (facetAcc, facetValue, index) =>
        facetAcc + (index === 0 ? '' : ',') + `${facetKey}:${facetValue}`,
      ''
    );

    return (accumulator += (index === 0 ? '' : ',') + `${stringPerFacetKey}`);
  }

  return (accumulator += '');
};
