/**
 * DO NOT MODIFY THIS FILE!
 * It was created by the reefer-icons generator.
 * Please update & re-run the generator if need be.
 */
import type { SVGProps } from 'react';

import type { DynamicIconProps } from '../../../icon/icon.types';
import { Icon } from '../../icon';

const Gear24 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="100%"
    height="100%"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <g className="Beaker 24">
      <path
        fill="#63B8D2"
        d="M6.076 1.617C6 1.801 6 2.034 6 2.5s0 .699.076.883a1 1 0 0 0 .541.54C6.801 4 7.034 4 7.5 4H9v8l-2.799 3.16c-2.127 2.505-3.191 3.758-3.2 4.814-.01.918.42 1.79 1.162 2.364.855.662 2.535.662 5.895.662h3.884c3.36 0 5.04 0 5.895-.662A2.959 2.959 0 0 0 21 19.974c-.01-1.056-1.073-2.309-3.201-4.813L15 12V4h1.5c.466 0 .699 0 .883-.076a1 1 0 0 0 .54-.541C18 3.199 18 2.966 18 2.5s0-.699-.076-.883a1 1 0 0 0-.541-.54C17.199 1 16.966 1 16.5 1h-9c-.466 0-.699 0-.883.076a1 1 0 0 0-.54.541Z"
        className="Main"
      />
      <path
        fill="#000"
        fillOpacity={0.5}
        d="M9 4h2a1 1 0 1 1 0 2H9V4Zm0 3h2a1 1 0 1 1 0 2H9V7Zm0 3h2a1 1 0 1 1 0 2H9v-2Z"
        className="Details"
      />
    </g>
  </svg>
);

const SIZE_MAP = {
  md: Gear24,
  sm: Gear24,
  lg: Gear24,
  xl: Gear24,
  xxl: Gear24,
  xxxl: Gear24,
};

export const GearIcon = ({
  size = 'md',
  'data-testid': testId,
  ...props
}: DynamicIconProps) => {
  return (
    <Icon
      data-testid={testId || 'gear-icon'}
      icon={SIZE_MAP[size]}
      {...props}
      size={size}
    />
  );
};
