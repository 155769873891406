import get from 'lodash/get';
import pick from 'lodash/pick';

import type {
  Cart,
  Id,
  MenuProduct,
  PendingCartProduct,
  PriceId,
  Product,
  ProductKind,
  ReservationMode,
  _DeepReadonlyArray,
  _DeepReadonlyObject,
} from '@jane/shared/models';
import { postMessageToIframeParent } from '@jane/shared/util';

import type { CartLineItems } from './getCartLineItems';

type EventNames =
  | 'checkout'
  | 'menuLoad'
  | 'productView'
  | 'cartItemAdd'
  | 'cartItemRemoval'
  | 'cartEmptied'
  | 'cartPageLoad'
  | 'checkoutPageLoad'
  | 'reportCartDisabled'
  | 'reportCheckoutDisabled'
  | 'cartItemClickedInCheckout';

interface AnalyticsPayload<Name extends EventNames, T> {
  eventName: Name;
  eventPayload?: T;
}

export interface AnalyticsDeliveryAddress {
  city?: string | null;
  country_code?: string | null;
  state_code?: string | null;
  street?: string | null;
  street2?: string | null;
  zipcode?: string | null;
}

export interface AnalyticsCheckoutPayload {
  cartId: Id;
  customerBirthDate?: string | null;
  customerEmail?: string | null;
  customerFirstName?: string | null;
  customerLastName?: string | null;
  customerPhone?: string | null;
  deliveryAddress?: AnalyticsDeliveryAddress | null;
  deliveryFee?: number;
  discountTotal?: number;
  estimatedTotal: number;
  preDiscountSubtotal?: number;
  products: Partial<PendingCartProduct>[];
  reservationMode: ReservationMode;
  salesTax?: number;
  serviceFee?: number;
  storeTax?: number;
  uuid: string;
}

interface PageLoadedWithCartPayload {
  cartProducts: Cart['products'];
  cartUuid: Cart['uuid'];
  storeId?: Id;
}

export type AnalyticsEvent =
  | AnalyticsPayload<
      'productView',
      {
        product: Partial<_DeepReadonlyObject<Product>>;
        productId: Id;
        productKind: ProductKind;
      }
    >
  | AnalyticsPayload<'checkout', AnalyticsCheckoutPayload>
  | AnalyticsPayload<
      'cartItemAdd',
      { product: Partial<_DeepReadonlyObject<MenuProduct>>; productId: Id }
    >
  | AnalyticsPayload<'cartEmptied', {}>
  | AnalyticsPayload<'cartPageLoad', PageLoadedWithCartPayload>
  | AnalyticsPayload<'checkoutPageLoad', PageLoadedWithCartPayload>
  | AnalyticsPayload<'reportCartDisabled', { isCheckoutDisabled: boolean }>
  | AnalyticsPayload<'reportCheckoutDisabled', { isCheckoutDisabled: boolean }>
  | AnalyticsPayload<'cartItemRemoval', { priceId: PriceId; productId: Id }>
  | AnalyticsPayload<
      'menuLoad',
      {
        customerEmail: Id | null;
        storeId: Id | undefined;
      }
    >
  | AnalyticsPayload<
      'cartItemClickedInCheckout',
      {
        price: number;
        priceId: PriceId;
        productCount: number;
        productId: Id;
      }
    >;

export const postAnalyticsEvent = ({
  eventName,
  eventPayload,
}: AnalyticsEvent) => {
  postMessageToIframeParent({
    messageType: 'analyticsEvent',
    payload: {
      name: eventName,
      properties: eventPayload,
    },
  });
};

export const pickAnalyticsProductProperties = <T>(
  product: T,
  properties: string[]
) => pick(product, properties);

export const cartProductList = (
  cartProducts: _DeepReadonlyArray<PendingCartProduct>
) =>
  cartProducts.map((product) => {
    const analyticsProperties =
      pickAnalyticsProductProperties<PendingCartProduct>(product, [
        'product_id',
        'name',
        'brand',
        'category',
        'kind',
        'count',
        'special_id',
        'special_title',
        'price_id',
      ]);
    const unit_price = Number(
      get(product, `discounted_price_${product.price_id}`) ||
        get(product, `price_${product.price_id}`)
    );

    return {
      ...analyticsProperties,
      unit_price,
    };
  });

const cartBrandNamesAndIds = (
  cartProducts: _DeepReadonlyArray<PendingCartProduct>
) => {
  const brandNames = cartProducts
    .filter((p) => !!p.brand)
    .map((p) => p.brand.trim())
    .sort();

  const brandIds = cartProducts
    .filter((p) => !!p.product_brand_id)
    .map((p) => p.product_brand_id)
    .sort();

  return {
    names: Array.from(new Set(brandNames)).join(','),
    ids: Array.from(new Set(brandIds)).join(','),
  };
};

interface PostCheckoutEventArgs {
  cart: Cart;
  cartLineItems: Partial<CartLineItems> & { total: CartLineItems['total'] };
  deliveryAddress?: AnalyticsDeliveryAddress | null;
  orderCustomer: {
    birthDate?: string | null;
    email: string | null;
    firstName: string | null;
    lastName: string | null;
    phone: string | null;
  };
}

export const postCheckoutAnalyticsEvent = ({
  cart,
  cartLineItems,
  deliveryAddress,
  orderCustomer,
}: PostCheckoutEventArgs) => {
  const cartProducts = cartProductList(cart.products);
  const cartBrands = cartBrandNamesAndIds(cart.products);

  const eventPayload = {
    estimatedTotal: cartLineItems.total,
    deliveryFee: cartLineItems.deliveryFee,
    discountTotal: cartLineItems.discountTotal,
    preDiscountSubtotal: cartLineItems.preDiscountSubtotal,
    salesTax: cartLineItems.salesTax,
    serviceFee: cartLineItems.serviceFee,
    storeId: cart.store.id,
    storeTax: cartLineItems.storeTax,
    cartId: cart.id,
    paymentMethod: cart.payment_method,
    products: cartProducts,
    brandNames: cartBrands.names,
    brandIds: cartBrands.ids,
    reservationMode: cart.reservation_mode,
    customerFirstName: orderCustomer.firstName,
    customerLastName: orderCustomer.lastName,
    customerEmail: orderCustomer.email,
    customerPhone: orderCustomer.phone,
    customerBirthDate: orderCustomer.birthDate,
    deliveryWindowStartTime: cart.delivery_started_at,
    deliveryWindowEndTime: cart.delivery_finished_at,
    storeNotes: cart.message,
    deliveryAddress,
    uuid: cart.uuid,
  };
  postAnalyticsEvent({
    eventName: 'checkout',
    eventPayload,
  });
};
