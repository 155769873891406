import { Link, Typography } from '@jane/shared/reefer';

import { FilterListButton, FilterListItem } from '../filter.styles';

export const DeselectAllListItem = ({ onClick }: { onClick: () => void }) => (
  <FilterListItem>
    <FilterListButton onClick={onClick}>
      <Link>
        <Typography>Deselect all</Typography>
      </Link>
    </FilterListButton>
  </FilterListItem>
);
