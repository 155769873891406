import clsx from 'clsx';
import type { CSSProperties, MouseEventHandler } from 'react';

import { getAccessibilityProps } from '../../../internal/utils';
import type { AccessibilityProps } from '../../../internal/utils';
import { fakeButtonProps, handleEnterKey } from '../../../utils';
import type { ReeferBaseProps } from '../../../utils/baseProps.types';
import { Image } from '../../image';
import styles from './cardImage.module.css';

export interface CardImageProps extends AccessibilityProps, ReeferBaseProps {
  /** Sets `alt` text for the image */
  alt: string;

  /** Optional preferred aspect ratio, which applies when the browser calculates a dimension with 'auto'. */
  aspectRatio?: CSSProperties['aspectRatio'];

  /** A fallback image for when the image src is invalid */
  fallbackSrc?: string;

  /** Set a fixed height for the `Card.Image` component, accepts any valid `height` value */
  height?: string;

  /** eager or lazy load image **/
  loading?: 'eager' | 'lazy';

  /** Sets `onClick` handler for the `Card.Image` component */
  onClick?: MouseEventHandler;

  /** Optional boolean to generate a srcset for the image */
  responsive?: boolean;

  /** A set of source sizes, ex (max-width: 600px) 480px, 800px. Each variant provides a string of sizes for that image type. */
  sizes?: 'default' | 'thumbnail' | 'card' | string;

  /** Sets `src` of the image */
  src: string;

  /** Sets `srcSet` of the image */
  srcSet?: string;

  /** Set a fixed width for the `Card.Image` component, accepts any valid `width` value */
  width?: string;
}

/**
 * The `Card.Image` component can be included as a child of a `Card` component to add an image
 * that spans the full `Card` width. Should be used outside `Card.Content`.
 * */

export function CardImage({
  alt,
  ariaLabel,
  ariaLabelledBy,
  aspectRatio,
  className,
  fallbackSrc,
  id,
  loading = 'eager',
  height,
  width,
  onClick,
  responsive = false,
  role,
  sizes,
  src,
  srcSet,
  'data-testid': testId,
}: CardImageProps) {
  const a11yProps = getAccessibilityProps(
    { ariaLabel, ariaLabelledBy, role, onClick },
    'Flex'
  );

  return (
    // eslint-disable-next-line jsx-a11y/no-static-element-interactions
    <div
      className={clsx(className, {
        [styles.cardImage__clickable]: !!onClick,
      })}
      id={id}
      data-testid={testId}
      {...a11yProps}
      onClick={onClick}
      onKeyUp={(event) => handleEnterKey(event, onClick)}
      {...(onClick && fakeButtonProps)}
    >
      <Image
        altText={alt}
        aspectRatio={aspectRatio}
        fallbackSrc={fallbackSrc}
        height={height}
        width={width || 'auto'}
        sizes={sizes}
        src={src}
        loading={loading}
        responsive={responsive}
        srcSet={srcSet}
      />
    </div>
  );
}
