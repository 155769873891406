import type { FunctionComponent } from 'react';
import { useEffect, useState } from 'react';

import { LogInModal } from '@jane/shared-ecomm/components';
import { useAuthentication } from '@jane/shared-ecomm/providers';

import { useCustomerSelector } from '../../customer/selectors';
import { get } from '../../redux-util/selectors';

const LoginModal: FunctionComponent = () => {
  const { authenticated: isLoggedIn } = useCustomerSelector(get('customer'));
  const { defaultLogin, register, resetError, resetPassword, ...authProps } =
    useAuthentication();
  const [showLoginModal, setShowLoginModal] = useState(false);

  useEffect(() => {
    if (!isLoggedIn) {
      setShowLoginModal(true);
    } else {
      setShowLoginModal(false);
    }
  }, [isLoggedIn]);

  return (
    <LogInModal
      {...authProps}
      location="header"
      onSignInSubmit={defaultLogin}
      onCreateAccountSubmit={register}
      onForgotPasswordSubmit={resetPassword}
      open={showLoginModal}
      onRequestClose={() => setShowLoginModal(false)}
    />
  );
};

export default LoginModal;
