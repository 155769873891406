import isEmpty from 'lodash/isEmpty';
import pickBy from 'lodash/pickBy';

const ANALYTICS_TAGS = [
  'og_ref',
  'utm_medium',
  'utm_source',
  'utm_campaign',
  'utm_content',
  'utm_term',
];

export const extractTrackingTags = (queryParams?: Record<string, any>) => {
  if (isEmpty(queryParams)) return {};
  return pickBy(queryParams, (_, key) => ANALYTICS_TAGS.includes(key));
};
