/**
 * DO NOT MODIFY THIS FILE!
 * It was created by the reefer-icons generator.
 * Please update & re-run the generator if need be.
 */
import type { SVGProps } from 'react';

import type { DynamicIconProps } from '../../../icon/icon.types';
import { Icon } from '../../icon';

const Cbd16 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="100%"
    height="100%"
    fill="none"
    viewBox="0 0 16 16"
    {...props}
  >
    <g className="CBD 16">
      <g className="SVG">
        <path
          fill="#666"
          d="M8.727 12.814a3.78 3.78 0 0 1-1.937-.507c-.58-.338-1.044-.832-1.391-1.482-.338-.659-.507-1.456-.507-2.392 0-.927.173-1.72.52-2.379.355-.667.828-1.174 1.417-1.521A3.705 3.705 0 0 1 8.779 4c.537 0 1.014.108 1.43.325.416.217.758.468 1.027.754l-.806.975a2.542 2.542 0 0 0-.728-.533 1.849 1.849 0 0 0-.884-.208c-.46 0-.871.126-1.235.377-.355.243-.637.594-.845 1.053-.2.46-.3 1.01-.3 1.651 0 .97.213 1.733.638 2.288.425.546.992.819 1.703.819.381 0 .72-.078 1.014-.234.295-.165.563-.377.806-.637l.806.949a3.704 3.704 0 0 1-1.196.923 3.438 3.438 0 0 1-1.482.312Z"
        />
        <path
          fill="#666"
          fillRule="evenodd"
          d="M12 1H4a3 3 0 0 0-3 3v8a3 3 0 0 0 3 3h8a3 3 0 0 0 3-3V4a3 3 0 0 0-3-3ZM4 0a4 4 0 0 0-4 4v8a4 4 0 0 0 4 4h8a4 4 0 0 0 4-4V4a4 4 0 0 0-4-4H4Z"
          clipRule="evenodd"
        />
      </g>
    </g>
  </svg>
);

const SIZE_MAP = {
  sm: Cbd16,
  md: Cbd16,
  lg: Cbd16,
  xl: Cbd16,
  xxl: Cbd16,
  xxxl: Cbd16,
};

export const CbdIcon = ({
  size = 'md',
  'data-testid': testId,
  ...props
}: DynamicIconProps) => {
  return (
    <Icon
      data-testid={testId || 'cbd-icon'}
      icon={SIZE_MAP[size]}
      {...props}
      size={size}
    />
  );
};
