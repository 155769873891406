import type { Reducer } from 'redux';

import type {
  DeepReadonly,
  Id,
  MaterializedMenuProduct,
  MaterializedMenuProduct as MenuProduct,
} from '@jane/shared/models';

import { createSimpleAction, createStandardAction } from '../../redux-util';
import { MaterializedMenuProductsSource } from '../../sources/materializedMenuProducts';
import type { CustomerThunkAction } from '../redux';
import type { CustomerAction } from './types';

export const GET_MATERIALIZED_MENU_PRODUCT = 'get-materialized-menu-product';

export const getMaterializedMenuProduct =
  ({
    storeId,
    productId,
  }: {
    productId: Id;
    storeId: Id;
  }): CustomerThunkAction =>
  (dispatch) => {
    dispatch({ type: GET_MATERIALIZED_MENU_PRODUCT });

    return MaterializedMenuProductsSource.get(storeId, productId).then(
      (result) => dispatch(getMaterializedMenuProductSuccess(result)),
      (err) => dispatch(getMaterializedMenuProductError(err))
    );
  };

export const RESET_MATERIALIZED_MENU_PRODUCT =
  'reset-materialized-menu-product';
export const resetMaterializedMenuProduct = createSimpleAction(
  RESET_MATERIALIZED_MENU_PRODUCT
);

export const GET_MATERIALIZED_MENU_PRODUCT_SUCCESS =
  'get-materialized-menu-product-success';
export const getMaterializedMenuProductSuccess = createStandardAction(
  GET_MATERIALIZED_MENU_PRODUCT_SUCCESS
)<MaterializedMenuProduct>();

export const GET_MATERIALIZED_MENU_PRODUCT_ERROR =
  'get-materialized-menu-product-error';
export const getMaterializedMenuProductError = createStandardAction(
  GET_MATERIALIZED_MENU_PRODUCT_ERROR
)<unknown>();

export const SET_MATERIALIZED_MENU_PRODUCT = 'set-materialized-menu-product';
export const setMenuProduct = createStandardAction(
  SET_MATERIALIZED_MENU_PRODUCT
)<MenuProduct>();

export type MaterializedMenuProductActions =
  | { type: typeof GET_MATERIALIZED_MENU_PRODUCT }
  | ReturnType<typeof getMaterializedMenuProductError>
  | ReturnType<typeof resetMaterializedMenuProduct>
  | ReturnType<typeof getMaterializedMenuProductSuccess>
  | { payload: MenuProduct; type: typeof SET_MATERIALIZED_MENU_PRODUCT };

export type MaterializedMenuProductState = DeepReadonly<{
  isLoading: boolean;
  materializedMenuProduct: MenuProduct | undefined;
}>;

const getInitialState = (): MaterializedMenuProductState => ({
  isLoading: false,
  materializedMenuProduct: undefined,
});

export const materializedMenuProductReducer: Reducer<
  MaterializedMenuProductState,
  CustomerAction
> = (state = getInitialState(), action) => {
  switch (action.type) {
    case GET_MATERIALIZED_MENU_PRODUCT:
      return { ...state, isLoading: true };
    case GET_MATERIALIZED_MENU_PRODUCT_ERROR:
      return { ...state, isLoading: false };
    case GET_MATERIALIZED_MENU_PRODUCT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        materializedMenuProduct: action.payload,
      };
    case RESET_MATERIALIZED_MENU_PRODUCT:
      return { ...state, materializedMenuProduct: undefined };
    case SET_MATERIALIZED_MENU_PRODUCT:
      return {
        ...state,
        isLoading: false,
        materializedMenuProduct: action.payload,
      };
  }

  return state;
};
