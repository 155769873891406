/**
 * DO NOT MODIFY THIS FILE!
 * It was created by the reefer-icons generator.
 * Please update & re-run the generator if need be.
 */
import type { SVGProps } from 'react';

import type { DynamicIconProps } from '../../../icon/icon.types';
import { Icon } from '../../icon';

const Verified16 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="100%"
    height="100%"
    fill="none"
    viewBox="0 0 16 16"
    {...props}
  >
    <g className="Verified 16">
      <path
        fill="#0E0E0E"
        fillRule="evenodd"
        d="m16 7.883-1.609 2.076.081 2.626-2.522.734-1.478 2.172L8 14.603l-2.472.888L4.05 13.32l-2.522-.735.08-2.626L0 7.883l1.609-2.077-.081-2.625 2.522-.735L5.528.274 8 1.163l2.472-.889 1.478 2.172 2.522.735-.08 2.625L16 7.883Zm-4.381-1.264A.875.875 0 0 0 10.38 5.38L7 8.763 5.619 7.38A.875.875 0 1 0 4.38 8.62l2 2a.875.875 0 0 0 1.238 0l4-4Z"
        className="SVG"
        clipRule="evenodd"
      />
    </g>
  </svg>
);

const Verified24 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="100%"
    height="100%"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <g className="Verified 24">
      <path
        fill="#5E1FF0"
        fillRule="evenodd"
        d="M21.587 14.94 24 11.825 21.587 8.71l.121-3.938-3.783-1.102L15.708.413 12 1.745 8.292.413 6.075 3.67 2.292 4.772l.121 3.938L0 11.825l2.413 3.115-.121 3.939 3.783 1.101 2.217 3.258L12 21.905l3.708 1.333 2.217-3.258 3.783-1.101-.121-3.939Zm-3.884-5.404a1 1 0 1 0-1.406-1.422L9.9 14.42l-2.197-2.15a1 1 0 0 0-1.406 1.421l2.9 2.846a1 1 0 0 0 1.406 0l7.1-7Z"
        className="Fill-Main"
        clipRule="evenodd"
      />
    </g>
  </svg>
);

const SIZE_MAP = {
  sm: Verified16,
  md: Verified24,
  lg: Verified24,
  xl: Verified24,
  xxl: Verified24,
  xxxl: Verified24,
};

export const VerifiedIcon = ({
  size = 'md',
  'data-testid': testId,
  ...props
}: DynamicIconProps) => {
  return (
    <Icon
      data-testid={testId || 'verified-icon'}
      icon={SIZE_MAP[size]}
      {...props}
      size={size}
    />
  );
};
