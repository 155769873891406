import type { AppMode, Store } from '@jane/shared/models';

import type { NoStore } from '../customer/redux/store';
import { isNoStore } from '../customer/redux/store';
import { useCustomerSelector } from '../customer/selectors';
import { get } from '../redux-util/selectors';

export const isGuestCheckoutEnabled = ({
  store,
  appMode,
  disableAuthFeatures,
}: {
  appMode: AppMode;
  disableAuthFeatures: boolean;
  store?: Store | NoStore;
}) =>
  Boolean(
    appMode === 'headless' ||
      disableAuthFeatures ||
      !store ||
      isNoStore(store) ||
      !store?.guest_checkout_setting?.disabled
  );

export const useGuestCheckoutEnabled = () => {
  const { store } = useCustomerSelector(get('store'));
  const { appMode, disableAuthFeatures } = useCustomerSelector(
    get('embeddedApp')
  );

  return isGuestCheckoutEnabled({
    store,
    appMode,
    disableAuthFeatures,
  });
};
