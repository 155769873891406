import clsx from 'clsx';

import type { DrawerFooterProps } from '../drawer.types';
import styles from './drawerFooter.module.css';

export function DrawerFooter({
  children,
  className,
  'data-testid': testId,
  id,
  noPadding = false,
  style,
}: DrawerFooterProps) {
  return (
    <div
      className={clsx(className, styles.footer, {
        [styles.footer__withPadding]: !noPadding,
      })}
      data-testid={testId}
      id={id}
      style={style}
    >
      {children}
    </div>
  );
}
