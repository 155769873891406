import forEach from 'lodash/forEach';
import { useEffect, useRef } from 'react';

import { initDmMixpanel } from '@jane/dm/internal';
import { useAeropayUser } from '@jane/shared-ecomm/data-access';
import { useUserPreferences } from '@jane/shared-ecomm/providers';
import { extractTrackingTags } from '@jane/shared-ecomm/tracking';
import { config } from '@jane/shared/config';
import type { FlagsType } from '@jane/shared/feature-flags';
import {
  getFlagContext,
  useFlagClient,
  useFlags,
} from '@jane/shared/feature-flags';
import type { AppMode, Store } from '@jane/shared/models';
import { Storage, getLiveTests, statePostalCode } from '@jane/shared/util';

import {
  isEmbeddedModeSelector,
  useCustomerSelector,
} from '../customer/selectors';
import { get } from '../redux-util/selectors';

export interface IdentifyCustomerProps {
  app: AppMode;
  appStoreId?: number | string;
  brandPartnerId?: number | string;
  operatorId?: number | string;
  queryParams?: Record<string, string>;
}

const IdentifyCustomer = ({
  app,
  appStoreId,
  brandPartnerId,
  operatorId,
  queryParams,
}: IdentifyCustomerProps) => {
  const flagClient = useFlagClient();
  const { store } = useCustomerSelector(get('store'));
  const customer = useCustomerSelector(get('customer'));
  const { janeDeviceId } = useCustomerSelector(get('application'));
  const isEmbeddedMode = useCustomerSelector(isEmbeddedModeSelector);
  const { userLocation } = useUserPreferences();
  const signedIn = useRef(false);
  const featureFlags = useFlags();
  const storeId = (store as Store)?.id || '';
  const state =
    statePostalCode((store as Store)?.state) || Storage.get('state') || '';
  const { data: aeropayData } = useAeropayUser({
    janeUserId: customer.id,
  });

  const aeropayAccountVerified = !!aeropayData?.bank_accounts.length;
  const firstBankAccountLinked = aeropayData?.bank_accounts.length === 1;
  const linkedAccountDate = Storage.get('aeropayLinkDate') || null;

  useEffect(() => {
    if (customer.id) {
      Storage.set('isJaneGoldMember', aeropayAccountVerified);
    }
  }, [aeropayAccountVerified, customer]);

  useEffect(() => {
    if (firstBankAccountLinked) {
      if (!linkedAccountDate) {
        Storage.set('aeropayLinkDate', new Date());
      }
    }
  }, [firstBankAccountLinked, linkedAccountDate]);

  useEffect(() => {
    /**
     * NOTE(Kyrsten): This code is duplicated in components/application/customerAppContainer.tsx
     * in order to allow us to wait for the flagClient.identify call before rendering the app.
     *
     * Eventually we should move this out of IdentifyCustomer and into all the various app containers,
     * and remove the call here.
     */
    if (!janeDeviceId) return;
    flagClient?.identify(
      getFlagContext({
        extraAttributes: {
          appMode: app,
          storeId: storeId,
          state,
        },
        janeDeviceId,
        user: customer,
      })
    );
  }, [app, flagClient, janeDeviceId, customer, storeId, state]);

  useEffect(() => {
    const trackingTags = extractTrackingTags(queryParams);
    const mappedFeatureFlags: FlagsType = {};
    Object.keys(featureFlags).forEach((flag) => {
      mappedFeatureFlags[`Flag_${flag}`] = featureFlags[flag];
    });

    if (customer.id) {
      signedIn.current = true;
      janeMixpanel.identify(customer.id.toString());
      janeMixpanel.people.set({
        aeropayLinkDate: linkedAccountDate,
        aeropayAccountVerified,
        $email: customer.email,
        $last_login: new Date(),
        $first_name: customer.firstName || customer.nickname,
        $last_name: customer.lastName,
        Nickname: customer.nickname,
        Phone: customer.phone,
        'User Id': customer.id,
        cityState: userLocation.cityState,
        coordinates: userLocation.coordinates,
        featureFlags: { ...mappedFeatureFlags },
        ...trackingTags,
      });

      // add first touch attribution tags to user profile
      const ogTrackingTags: Record<string, unknown> = {};
      forEach(
        trackingTags,
        (value, key) => (ogTrackingTags[key + ' [first touch]'] = value)
      );
      janeMixpanel.people.set_once(ogTrackingTags);
    } else if (signedIn.current) {
      janeMixpanel.reset();
    }

    // set utm tags as super properties to enable last touch attribution
    // https://help.mixpanel.com/hc/en-us/articles/360001337103-Last-Touch-UTM-Tags
    janeMixpanel.register({
      app,
      janeDeviceId,
      appStoreId: appStoreId || null,
      brandPartnerId: brandPartnerId || null,
      operatorId: operatorId || null,
      userCityState: userLocation.cityState,
      userCoordinates: userLocation.coordinates,
      build: config.buildVersion?.substring(0, 7),
      featureFlags: { ...mappedFeatureFlags },
      aeropayAccountVerified,
      aeropayLinkDate: linkedAccountDate,
      ...getLiveTests(customer.id),
      ...trackingTags,
    });
    userSessionMixpanel.register({
      app,
      janeDeviceId,
    });
    initDmMixpanel({
      appMode: app,
      janeDeviceId,
      storeId: appStoreId ? Number(appStoreId) : null,
    });
  }, [
    aeropayAccountVerified,
    app,
    appStoreId,
    brandPartnerId,
    customer.email,
    customer.firstName,
    customer.id,
    customer.lastName,
    customer.nickname,
    customer.phone,
    featureFlags,
    isEmbeddedMode,
    janeDeviceId,
    linkedAccountDate,
    operatorId,
    queryParams,
    userLocation.cityState,
    userLocation.coordinates,
  ]);

  return null;
};

export default IdentifyCustomer;
