import styled from '@emotion/styled';

import { Box, Flex, Link, Skeleton } from '@jane/shared/reefer';
import { hidden, mediaQueries, spacing } from '@jane/shared/reefer-emotion';

export const CarouselControlsContainer = styled(Flex)({
  [mediaQueries.tablet('max')]: {
    ...hidden(true),
  },
});

export const CarouselControlsContainerMobile = styled(Flex)({
  [mediaQueries.tablet('min')]: {
    ...hidden(true),
  },
});

interface CarouselCardScrollAreaProps {
  disableScroll: boolean;
}

export const CarouselCardScrollArea = styled.div<CarouselCardScrollAreaProps>(
  ({ disableScroll, theme }) => ({
    borderBottom: '1px solid transparent',
    borderTop: '1px solid transparent',
    overflowX: disableScroll ? 'hidden' : 'scroll',
    position: 'relative',
    scrollbarWidth: 'none',
    MsOverflowStyle: 'none',
    WebkitOverflowScrolling: 'touch',
    '&::-webkit-scrollbar': {
      width: 0,
    },
    ...spacing({ mx: -24, my: -12, pl: 24 }),
    ':focus-visible': {
      outline: 'none',
      borderBottom: `1px solid ${theme.colors.primary.main}`,
      borderTop: `1px solid ${theme.colors.primary.main}`,
    },
    [mediaQueries.tablet('min')]: {
      ...spacing({ mx: 0, pl: 0 }),
      overflowX: 'hidden',
      scrollSnapType: 'x mandatory',
    },
  })
);

interface CarouselCardGridProps {
  cardGap: number;
  cardGapMobile: number;
  cardWidth: number;
  cardWidthMobile: number;
  rowCountMobile: number;
}

export const CarouselCardGrid = styled(Box)(
  ({
    cardGap,
    cardGapMobile,
    cardWidth,
    cardWidthMobile,
    rowCountMobile,
  }: CarouselCardGridProps) => {
    return {
      display: 'grid',
      gridAutoColumns: cardWidthMobile + cardGapMobile,
      gridAutoFlow: 'column',
      gridTemplateRows: Array(rowCountMobile).join('auto ') + 'auto',
      rowGap: cardGapMobile,
      ...spacing({ py: 12 }),
      [mediaQueries.tablet('min')]: {
        columnGap: cardGap,
        gridAutoColumns: cardWidth,
        gridTemplateRows: 'auto',
        rowGap: cardGap,
      },
    };
  }
);

interface StyledSkeletonBoneProps {
  height: string | number;
  heightMobile: string | number;
}

export const StyledSkeletonBone = styled(Skeleton.Bone)(
  ({ height, heightMobile }: StyledSkeletonBoneProps) => ({
    height: heightMobile,
    [mediaQueries.tablet('min')]: {
      height,
    },
  })
);

export const StyledLink = styled(Link)({
  whiteSpace: 'nowrap',
});
