import styled from '@emotion/styled';

import { Flex } from '@jane/shared/reefer';
import { mediaQueries, shadows, spacing } from '@jane/shared/reefer-emotion';

import {
  DESKTOP_PADDING_HORIZONTAL,
  MOBILE_PADDING_HORIZONTAL,
  PADDING_VERTICAL,
} from '../pendingCartDrawer.styles';

export const CartDrawerStatesWrapper = styled(Flex)(({ theme }) => ({
  backgroundColor: theme.colors.grays.white,
  height: '100%',
}));

export const FinePrintWrapper = styled(Flex)({
  backgroundColor: 'inherit',
  flexGrow: 1,
  ...spacing({ px: MOBILE_PADDING_HORIZONTAL, py: PADDING_VERTICAL }),
  [mediaQueries.tablet('min')]: {
    ...spacing({ px: DESKTOP_PADDING_HORIZONTAL }),
  },
});

export const CartReferenceContainer = styled.div<{ height: string }>(
  ({ theme, height }) => ({
    height,
    minHeight: '500px',
    [mediaQueries.tablet('min')]: {
      // When resizing itself, the size differences between states (specifically loading) was jarring.
      // This minHeight is approximately the height of a drawer with one item in it.
      minHeight: '800px',
    },
    backgroundColor: theme.colors.background,
  })
);

export const Divider = styled.div({
  boxShadow: shadows.divider,
  height: '1px',
});
