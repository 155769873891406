import styled from '@emotion/styled';

import { mediaQueries } from '@jane/shared/reefer-emotion';

interface ProductsGridProps {
  desktopCardWidth: number;
  mobileCardWidth: number;
}

export const ProductsGrid = styled.div<ProductsGridProps>(
  ({ desktopCardWidth = 200, mobileCardWidth = 150 }) => ({
    display: 'grid',
    gridGap: '24px 24px',
    gridAutoRows: '1fr',
    [mediaQueries.mobile('md', 'min')]: {
      overflowX: 'auto',
      gridTemplateColumns: `repeat(auto-fill, minmax(${mobileCardWidth}px, 1fr))`,
    },
    [mediaQueries.tablet('min')]: {
      gridTemplateColumns: `repeat(auto-fill, minmax(${desktopCardWidth}px, 1fr))`,
    },
  })
);

export const RowWrapper = styled.div({
  display: 'flex',
  gap: 24,
  width: '100%',
  overflowX: 'scroll',
  '::-webkit-scrollbar': {
    display: 'none',
  },
  scrollbarWidth: 'none',
});

export const FilterBarWrapper = styled.div({
  display: 'flex',
  justifyContent: 'space-between',
  overflowX: 'scroll',
  '::-webkit-scrollbar': {
    display: 'none',
  },
  scrollbarWidth: 'none',
  width: '100%',
});
