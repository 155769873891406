import type {
  ProductKind,
  ProductLineage,
  ProductType,
} from '@jane/shared/models';

const urlRoot = 'https://product-assets.iheartjane.com/default-photos/';

const rootSubtypePaths: Record<string, Record<string, boolean>> = {
  edible: {
    bakedgoods: true,
    bars: true,
    breathstrips: true,
    candies: true,
    capsules: true,
    chocolates: true,
    cooking: true,
    driedfruit: true,
    drinkmixes: true,
    drinks: true,
    honeys: true,
    icecreams: true,
    mints: true,
    other: true,
    pets: true,
    sauces: true,
    seasoningsugar: true,
    smallbites: true,
    snacks: true,
    spreads: true,
    teascoffee: true,
  },
  extract: {
    diamondscrystals: true,
    distillates: true,
    hash: true,
    inhalation: true,
    isolates: true,
    kief: true,
    moonrocks: true,
    oils: true,
    ricksimpsonoilrso: true,
    rosins: true,
    sauces: true,
    shatters: true,
    syringestankers: true,
    vapekits: true,
    waxes: true,
  },
  flower: {
    cbd: true,
    hybrid: true,
    indica: true,
    sativa: true,
    shake: true,
  },
  gear: {
    accessories: true,
    clothing: true,
    cones: true,
    grinders: true,
    papers: true,
    paraphernalia: true,
    tips: true,
    vaporizers: true,
    wraps: true,
  },
  general: {
    cbd: true,
    edible: true,
    extract: true,
    gear: true,
    grow: true,
    hybrid: true,
    indica: true,
    merch: true,
    preroll: true,
    sativa: true,
    tincture: true,
    topical: true,
  },
  grow: {
    clones: true,
    seeds: true,
  },
  merch: {
    clothing: true,
    jewelry: true,
    kits: true,
    other: true,
  },
  preroll: {
    bluntpacks: true,
    blunts: true,
    cannagars: true,
    infused: true,
    infusedblunts: true,
    infusedpacks: true,
    prerollpacks: true,
    prerolls: true,
    thaisticks: true,
  },
  tincture: {
    companions: true,
    pets: true,
    sprays: true,
    sublinguals: true,
    tinctures: true,
  },
  topical: {
    balms: true,
    bath: true,
    companions: true,
    cosmetics: true,
    creams: true,
    gels: true,
    intimates: true,
    lipbalms: true,
    lotions: true,
    oils: true,
    ointments: true,
    patches: true,
    pets: true,
    rollons: true,
    salves: true,
    scrubs: true,
    soaps: true,
    sprays: true,
    suppositories: true,
  },
  vape: {
    cartridges: true,
    disposables: true,
    specialtypods: true,
  },
};

interface Args {
  category: ProductLineage | '' | null | undefined;
  kind: ProductKind;
  productRootSubtype: string | '' | null | undefined;
  productType: ProductType | '' | null | undefined;
}

export const defaultProductPhotoUrl = ({
  category,
  kind,
  productRootSubtype,
  productType,
}: Args) => {
  const strippedProductType =
    kind === 'flower'
      ? 'flower'
      : productType
          ?.toString()
          .replace(/[^0-9a-zA-Z]/g, '')
          .toLowerCase();

  const strippedProductRootType = productRootSubtype
    ?.replace(/[^0-9a-zA-Z]/g, '')
    .toLowerCase();

  /**
   * finalRootType is to account for the fact that all flower products have productType of category (see product.rb)
   * when the flower[strippedProductRootType] path doesn't exist in rootSubtypePaths, we use the flower's category as its rootType
   */
  const finalRootType =
    strippedProductType === 'flower' &&
    !rootSubtypePaths[strippedProductType][strippedProductRootType ?? '']
      ? category
      : strippedProductRootType;

  if (
    strippedProductType &&
    finalRootType &&
    rootSubtypePaths[strippedProductType] &&
    rootSubtypePaths[strippedProductType][finalRootType]
  ) {
    return `${urlRoot}${strippedProductType}/${finalRootType}.png`;
  }

  return `${urlRoot}general/${strippedProductType}.png`;
};
