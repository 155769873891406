import styled from '@emotion/styled';
import { useState } from 'react';

import { Flex, StarFilledIcon } from '@jane/shared/reefer';
import type { IconSize, Spacing } from '@jane/shared/reefer';

export interface StarRatingInputProps {
  /* The initial rating when the component is rendered.
  This will be ignored if passed a value prop **/
  initialRating?: number;

  onChange?: (value: number) => void;
  size?: IconSize;

  /* If you need to treat this input as a controlled input, pass the value here,
  otherwise, it controls itself and will pass the value back when changed **/
  value?: number;
}

const MARGINS: { [key in IconSize]: Spacing } = {
  sm: 4,
  md: 8,
  lg: 12,
  xl: 16,
  xxl: 24,
  xxxl: 48,
};

const StyledButton = styled.button({
  background: 'none',
  border: 'none',
  cursor: 'pointer',
});

export const StarRatingInput = ({
  initialRating = 0,
  value,
  onChange,
  size = 'sm',
}: StarRatingInputProps) => {
  const [hovered, setHovered] = useState(0);
  const [rating, setRating] = useState(value || initialRating);

  const ratingToShow = value !== undefined ? value : rating;

  return (
    <Flex flexDirection="row">
      {Array.from(new Array(5)).map((_, index) => {
        const star = index + 1;
        return (
          <StyledButton
            key={index}
            onMouseEnter={() => setHovered(star)}
            onMouseLeave={() => setHovered(0)}
            onClick={(e) => {
              e.preventDefault();
              onChange && onChange(star);
              setHovered(0);
              setRating(star);
            }}
          >
            <StarFilledIcon
              mr={star !== 5 ? MARGINS[size] : 0}
              size={size}
              color={(hovered || ratingToShow) >= star ? 'gold' : 'grays-light'}
            />
          </StyledButton>
        );
      })}
    </Flex>
  );
};
