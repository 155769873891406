import { useCallback } from 'react';

import { trackCustomerRegistration } from '@jane/shared-ecomm/tracking';
import { setJwt } from '@jane/shared/auth';
import { useEcommApp } from '@jane/shared/providers';

import { setLoginValue } from '../../common/redux/login';
import { useCustomerDispatch } from '../../customer/dispatch';
import { registerSuccess } from '../../customer/redux/customer';
import { isNoStore } from '../../customer/redux/store';
import { useCustomerSelector } from '../../customer/selectors';
import { validationsToErrors } from '../../lib/form';
import { get } from '../../redux-util/selectors';
import type {
  RegisterResponse,
  RegisterSuccessResponse,
  RegisterValidationErrorResponse,
} from '../../sources/registration';
import { RegistrationSource } from '../../sources/registration';
import type { RegistrationFormProps } from './form';
import RegistrationForm from './form';

interface Props {
  onLoginSuccess?: () => void;
  setShowLogin: (arg: boolean) => void;
}

const isErrorResponse = (
  x: RegisterResponse
): x is RegisterValidationErrorResponse => 'errors' in x.body;

const RegistrationContainer = ({ setShowLogin, onLoginSuccess }: Props) => {
  const dispatch = useCustomerDispatch();
  const { appMode } = useEcommApp();
  const { partnerId, partnerName } = useCustomerSelector(get('embeddedApp'));
  const { store } = useCustomerSelector(get('store'));

  const onRegisterSuccess = useCallback(
    (response: RegisterSuccessResponse) => {
      const { headers, body } = response;

      setJwt(headers['Authorization']);
      trackCustomerRegistration({
        customer: body.user,
        partnerChannel: appMode,
        partnerId,
        partnerName,
      });
      dispatch(registerSuccess(body.user));
      onLoginSuccess && onLoginSuccess();
    },
    [appMode]
  );

  const onRegister: RegistrationFormProps['onSubmit'] = (values) => {
    const storeId = isNoStore(store) ? undefined : store.id;
    const registrationData = {
      ...values,
      store_id_at_registration: storeId,
      app_mode_at_registration: appMode,
    };
    return RegistrationSource.register(registrationData).then((response) => {
      if (isErrorResponse(response)) {
        dispatch(setLoginValue({ name: 'email', value: values.email }));

        return validationsToErrors(response.body.errors.validations);
      }

      return onRegisterSuccess(response);
    });
  };

  return <RegistrationForm setShowLogin={setShowLogin} onSubmit={onRegister} />;
};

export default RegistrationContainer;
