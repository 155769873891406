import { z } from 'zod';

export const AlgoliaBrandImageSchema = z.object({
  cta_text: z.string().nullable().optional(),
  cta_url: z.string().nullable().optional(),
  description: z.string().nullable().optional(),
  image_url: z.string().nullable().optional(),
  position: z.number().nullable().optional(),
});

export const AlgoliaBrandVideoSchema = z.object({
  overlay_image_url: z.string().nullable().optional(),
  overlay_text: z.string().nullable().optional(),
  video_url: z.string().nullable().optional(),
});

export type AlgoliaBrandImage = z.infer<typeof AlgoliaBrandImageSchema>;
export type AlgoliaBrandVideo = z.infer<typeof AlgoliaBrandVideoSchema>;

export const AlgoliaBrandSchema = z.object({
  color_hex: z.string().nullable().optional(),
  custom_image_url: z.string().nullable().optional(),
  custom_images: z.array(AlgoliaBrandImageSchema).nullable().optional(),
  custom_video: AlgoliaBrandVideoSchema.nullable().optional(),
  description: z.string().nullable().optional(),
  has_active_brand_special: z.boolean().optional(),
  id: z.number(),
  logo_url: z.string().nullable().optional(),
  name: z.string(),
  objectID: z.string(),
  productCount: z.number().nullable().optional(),
  rank: z.number().nullable().optional(),
  uuid: z.string(),
});

export type AlgoliaBrand = z.infer<typeof AlgoliaBrandSchema>;

export const AlgoliaBrandsSchema = z.array(AlgoliaBrandSchema);

export type AlgoliaBrands = z.infer<typeof AlgoliaBrandsSchema>;
