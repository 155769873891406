import type { PriceId, ProductWeightSpecial } from '@jane/shared/models';
import {
  Box,
  Flex,
  Typography,
  useDesktopMediaQuery,
} from '@jane/shared/reefer';
import { formatCurrency, labelForWeightId } from '@jane/shared/util';

import { LegacyButton } from '../../legacyButton/legacyButton';
import { StrikeThroughPrice } from '../../legacyProductCard';
import { useProductCardContext } from '../../productCardProvider/productCardProvider';

const formatLabel = (label: string) => 1 * Number(parseFloat(label).toFixed(2));

const getPriceLabel = (
  price: string,
  weight: PriceId,
  amount?: string | null
) => {
  const priceLabel = formatCurrency(price);

  if (weight !== 'each') {
    return `${priceLabel}/${labelForWeightId(weight, amount)}`;
  }

  return priceLabel;
};

const getSpecialLabel = (special: ProductWeightSpecial | null) => {
  if (!special) {
    return null;
  }

  const { discount_type, discount_percent, discount_amount } = special;

  if (discount_type === 'percent') {
    return `-${formatLabel(discount_percent || '')}%`;
  } else {
    return `-$${formatLabel(discount_amount || '')}`;
  }
};

interface VariantSelectionButtonProps {
  onChange?: () => void;
  selected: boolean;
  weight: PriceId;
}

export const VariantSelectionButton = ({
  onChange,
  selected,
  weight,
}: VariantSelectionButtonProps) => {
  const { currentSpecial, menuProduct } = useProductCardContext();
  const smallerThanDesktop = useDesktopMediaQuery({ size: 'sm', width: 'max' });

  if (!menuProduct) return null;

  const { amount, special_id } = menuProduct;
  // if there is no current_special(server request), don't use the discount price (Algolia might have stale data)
  const price = menuProduct[`price_${weight}` as keyof typeof menuProduct] as
    | string
    | null
    | undefined;
  const discountPrice =
    currentSpecial &&
    (menuProduct[`discounted_price_${weight}` as keyof typeof menuProduct] as
      | string
      | null
      | undefined);
  const special = menuProduct[
    `special_price_${weight}` as keyof typeof menuProduct
  ] as ProductWeightSpecial | null;
  const priceLabel = discountPrice
    ? getPriceLabel(discountPrice, weight, amount)
    : price
    ? getPriceLabel(price, weight, amount)
    : '';
  const specialLabel = currentSpecial && getSpecialLabel(special);

  const showDiscountPrice =
    currentSpecial && special_id && discountPrice && discountPrice !== price;

  return (
    <LegacyButton
      ariaLabel={priceLabel}
      block
      variant={selected ? 'tertiary-selected' : 'tertiary'}
      onClick={() => onChange && onChange()}
    >
      <Flex flexDirection="column" justifyContent="center" height="100%">
        <Flex justifyContent="center" height="100%" alignItems="center">
          <Typography variant="body-bold" as="span">
            {priceLabel}
          </Typography>
          {showDiscountPrice && !smallerThanDesktop && (
            <Box ml={4}>
              <StrikeThroughPrice>{formatCurrency(price)}</StrikeThroughPrice>
            </Box>
          )}
        </Flex>
        <Flex justifyContent="center">
          {showDiscountPrice && smallerThanDesktop && (
            <Box mr={4}>
              <StrikeThroughPrice>{formatCurrency(price)}</StrikeThroughPrice>
            </Box>
          )}
          {showDiscountPrice && specialLabel && (
            <Flex height="100%" alignItems="center">
              <Typography variant="mini-bold" color="sunset">
                {specialLabel}
              </Typography>
            </Flex>
          )}
        </Flex>
      </Flex>
    </LegacyButton>
  );
};
