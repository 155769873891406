import type { Id } from '@jane/shared/models';

export const urls = {
  aeropayAccounts: '/users/aeropay_accounts',
  bankConnection: '/users/aeropay_accounts/bank_connection',
  deleteBankAccount: '/users/aeropay_accounts/delete_bank_account',
  selectBankAccount: '/users/aeropay_accounts/select_bank_account',
};

const queryKeyBase = ['aeropay'];

export const queryKeys = {
  aeropayUser: ({ janeUserId }: { janeUserId: Id | null }) => [
    ...queryKeyBase,
    'user',
    janeUserId,
  ],
  bankConnection: () => [...queryKeyBase, 'bankConnection'],
};
