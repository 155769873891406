import type { BrandSpecial } from '@jane/shared/models';
import type {
  AppMode,
  GoldConfig,
  Store,
  UserSegmentIds,
} from '@jane/shared/types';
import type { ProductWithBrandSpecialSegments } from '@jane/shared/util';
import {
  Storage,
  shouldBrandShowSpecials,
  shouldBrandSpecialShowForUser,
  shouldProductShowSpecial,
  statePostalCode,
} from '@jane/shared/util';

export interface CheckUserSegmentsProps {
  brandSpecial?: Pick<BrandSpecial, 'rules'>;
  brandSpecials?: Pick<BrandSpecial, 'rules'>[];
  product?: ProductWithBrandSpecialSegments;
  store?: {
    id?: string | number;
    state?: string | null;
  };
  userSegments: UserSegmentIds;
}

export const checkUserSegments = ({
  brandSpecial,
  brandSpecials,
  product,
  userSegments,
}: CheckUserSegmentsProps): boolean => {
  if (product) {
    return shouldProductShowSpecial({ product, userSegments });
  }

  if (brandSpecial) {
    return shouldBrandSpecialShowForUser({
      brandSpecial,
      userSegments,
    });
  }

  if (brandSpecials) {
    return shouldBrandShowSpecials({
      brandSpecials,
      userSegments,
    });
  }

  return true;
};

export const checkLocationAndStoreId = (
  goldConfig: GoldConfig,
  appMode: AppMode,
  store?: {
    id?: string | number;
    state?: string | null;
  }
): boolean => {
  const id = store?.id;
  const state =
    statePostalCode((store as Store)?.state) || Storage.get('state') || '';

  if (appMode === 'default' && !!state) {
    return goldConfig.states.includes(state);
  }

  // For all other app modes, check the store ID
  if (id) {
    return goldConfig.store_ids.includes(Number(id));
  }

  return false;
};
