import { useEffect, useState } from 'react';

import type { AccordionItemProps } from './accordion.types';
import {
  AccordionItemExpandedContext,
  AccordionItemIdContext,
  AccordionItemSetExpandedContext,
} from './accordionItemContext';

export const AccordionItem = ({
  expanded,
  children,
  id,
  defaultExpanded = false,
  onTrack,
}: AccordionItemProps) => {
  const [itemExpanded, setItemExpanded] = useState(defaultExpanded);

  useEffect(() => {
    if ((expanded || itemExpanded) && onTrack) {
      onTrack();
    }
  }, [expanded, itemExpanded]);

  return (
    <AccordionItemExpandedContext.Provider
      value={expanded === undefined ? itemExpanded : expanded}
    >
      <AccordionItemSetExpandedContext.Provider value={setItemExpanded}>
        <AccordionItemIdContext.Provider value={id}>
          <div id={`accordion-item-${id}`} data-testid={`accordion-item-${id}`}>
            {children}
          </div>
        </AccordionItemIdContext.Provider>
      </AccordionItemSetExpandedContext.Provider>
    </AccordionItemExpandedContext.Provider>
  );
};
