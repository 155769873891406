import styled from '@emotion/styled';

import { Link } from '@jane/shared/reefer';

export const LinkStyled = styled(Link)({
  display: 'block',
  textDecoration: 'none',
  ':focus-visible': {
    outline: 'none',
    '[role=img]:before': {
      backgroundColor: 'rgba(0, 0, 0, 0.1)',
      borderRadius: 'inherit',
      content: '""',
      height: '180px',
      position: 'absolute',
      width: '180px',
    },
  },
});

export const GoldTagWrapper = styled.div(
  ({ allBrands }: { allBrands: boolean }) => ({
    position: 'relative',
    // fix for stacking context issue on all brands page, showing over popover overlay
    zIndex: allBrands ? '-1' : '1',
  })
);
