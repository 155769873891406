/**
 * DO NOT MODIFY THIS FILE!
 * It was created by the reefer-icons generator.
 * Please update & re-run the generator if need be.
 */
import type { SVGProps } from 'react';

import type { DynamicIconProps } from '../../../icon/icon.types';
import { Icon } from '../../icon';

const StarFilled16 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="100%"
    height="100%"
    fill="none"
    viewBox="0 0 16 16"
    {...props}
  >
    <g className="Star filled 16">
      <path
        fill="#FFC220"
        d="M7.142.56a.939.939 0 0 1 1.716 0l1.73 3.665c.14.295.41.5.721.547l3.87.588c.785.119 1.099 1.127.531 1.705l-2.8 2.853c-.226.23-.33.56-.276.885l.661 4.028c.134.817-.686 1.44-1.388 1.054l-3.462-1.902a.92.92 0 0 0-.89 0l-3.462 1.902c-.702.386-1.522-.237-1.388-1.053l.66-4.03a1.028 1.028 0 0 0-.274-.884L.29 7.065C-.278 6.487.035 5.479.82 5.36l3.87-.588a.96.96 0 0 0 .721-.547L7.142.56Z"
        className="SVG"
      />
    </g>
  </svg>
);

const SIZE_MAP = {
  sm: StarFilled16,
  md: StarFilled16,
  lg: StarFilled16,
  xl: StarFilled16,
  xxl: StarFilled16,
  xxxl: StarFilled16,
};

export const StarFilledIcon = ({
  size = 'md',
  'data-testid': testId,
  ...props
}: DynamicIconProps) => {
  return (
    <Icon
      data-testid={testId || 'star-filled-icon'}
      icon={SIZE_MAP[size]}
      {...props}
      size={size}
    />
  );
};
