import styled from '@emotion/styled';

import { Box } from '@jane/shared/reefer';
import { mediaQueries, spacing } from '@jane/shared/reefer-emotion';

import type { MenuProductCarouselVariant } from './menuProductCarousel.types';

export const StyledCarouselWrapper = styled(Box)<{
  variant?: MenuProductCarouselVariant;
}>(({ variant }) => ({
  ...(variant === 'sponsored-inline' && {
    borderWidth: '1px 0 1px 0',
    ...spacing({ py: 24 }),
    [mediaQueries.tablet('min')]: {
      ...spacing({ pb: 24, pt: 0 }),
      '> section, div:first-of-type': {
        ...spacing({ mb: 0 }),
      },
    },
  }),
}));
