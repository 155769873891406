import { api, generateUrl } from '../api';
import type { RequiredConfig } from '../types/config';
import type { PosCheckoutBody, PosCheckoutParams } from './posCheckout.types';

const externalCheckoutPath = '/v2/event/3e' as const;

export const sendPosCheckout = async (
  props: PosCheckoutParams & RequiredConfig
): Promise<void> => {
  const {
    endpoint,
    apiKey,
    identifier,
    storeId,
    source,
    version,
    ...restOfProps
  } = props;

  const url = generateUrl(endpoint, externalCheckoutPath, {
    apiKey,
    source,
    version,
  });

  const requiredParams: PosCheckoutBody = {
    janeDeviceId: identifier.jdid,
    posOrderId: restOfProps.posOrderId,
    posUserId: restOfProps.posUserId,
    products: restOfProps.products,
    storeId,
  };

  return await api.post<PosCheckoutBody>(url, requiredParams);
};
