import { useEffect } from 'react';

export const useWindowListener = <Event extends keyof WindowEventMap>(
  event: Event,
  listener: (event: WindowEventMap[Event]) => any
) => {
  useEffect(() => {
    window.addEventListener(event, listener, false);
    return () => window.removeEventListener(event, listener);
  }, [listener]);
};
