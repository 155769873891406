import { css } from '@emotion/react';
import type { CSSObject } from '@emotion/react';

import {
  AidMyFurryFriendIcon,
  AlphabeticalSortIcon,
  AngleDoubleUpIcon,
  ApplyTopicallyIcon,
  BadgeCheckIcon,
  BarsIcon,
  BellIcon,
  BellSlashIcon,
  BipocIcon,
  BlissfulIcon,
  CalendarIcon,
  CarIcon,
  CaretIcon,
  CaretRightIcon,
  ChartBarIcon,
  CheckIcon,
  ChevronFatIcon,
  ChevronLightIcon,
  CircleCheckFilledIcon,
  CircleCheckIcon,
  ClockIcon,
  CloseIcon,
  CommentAltIcon as CommentIcon,
  CopyIcon,
  CopyOutlineIcon,
  CreativeIcon,
  CreditCardIcon,
  DatabaseIcon,
  DollarSignIcon,
  DollarSignRegularIcon,
  DollarSortIcon,
  DotIcon,
  EaseMyMindIcon,
  EatIcon,
  EditIcon,
  EnergeticIcon,
  ExclamationTriangleIcon,
  ExternalLinkIcon,
  FacebookIcon,
  FilterIcon,
  FiltersIcon,
  GetActiveIcon,
  GetIntimateIcon,
  GetReliefIcon,
  GetSomeSleepIcon,
  GridViewIcon,
  GridViewSolidIcon,
  HandHoldingHeartIcon,
  HangWithFriendsIcon,
  HeartIcon,
  HomeIcon,
  HungryIcon,
  IdCardIcon,
  InfoCircleIcon,
  InfoSquareIcon,
  LeafIcon,
  LgbtqIcon,
  LocationArrowIcon,
  MapMarkerAltIcon as MapMarkerIcon,
  MapMarkerInvertedIcon,
  MinusIcon,
  MoneyIcon,
  NotHighIcon,
  NotSureIcon,
  PainFreeIcon,
  PanIcon,
  ParkingCircleIcon,
  PaytenderIcon,
  PercentageIcon,
  PlusIcon,
  PolygonIcon,
  PrideHeartIcon,
  ProductsIcon,
  QuantityMinusIcon,
  QuantityPlusIcon,
  RelaxedIcon,
  SMSIcon,
  SearchBoldIcon,
  SearchIcon,
  ShieldCheckIcon,
  ShoppingBagIcon,
  ShoppingCartIcon,
  ShoppingCartSolidIcon,
  SleepyIcon,
  SmokeIcon,
  SortAlphaDownIcon,
  SpecialBannerIcon,
  SpinnerIcon,
  StarEmptyIcon,
  StarFilledIcon,
  StarHalfIcon,
  StarIcon,
  StimulateMyMindIcon,
  StoreAltIcon,
  StoreIcon,
  SyncIcon,
  ThumbsUpFilledIcon,
  ThumbsUpIcon,
  TimesCircleIcon,
  TimesIcon,
  TrackingLeafIcon,
  TrashIcon,
  TwinkleStarIcon,
  TwitterIcon,
  UsdCircleIcon,
  UsdSquareIcon,
  UserCircleIcon,
  UserPlusIcon,
  VapeIcon,
  VerticalDragIcon,
  VeteranIcon,
  WarningTriangleIcon,
  WheelChairIcon,
  WomanIcon,
} from '../icons';

export interface Icons {
  [key: string]: any;
  aidMyFurryFriend: any;
  alphabeticalSort: any;
  angleDoubleUp: any;
  applyTopically: any;
  badgeCheck: any;
  bars: any;
  bell: any;
  bellSlash: any;
  bipoc: any;
  blissful: any;
  calendar: any;
  car: any;
  caret: any;
  caretFilled: any;
  chartBar: any;
  checkmark: any;
  chevron: any;
  chevronFat: any;
  circleCheck: any;
  circleCheckFilled: any;
  clock: any;
  close: any;
  comment: any;
  copy: any;
  creative: any;
  creditCard: any;
  database: any;
  dollarSign: any;
  dollarSignRegular: any;
  dollarSort: any;
  dot: any;
  easeMyMind: any;
  eat: any;
  edit: any;
  energetic: any;
  exclamationTriangle: any;
  externalLink: any;
  facebook: any;
  filter: any;
  filters: any;
  getActive: any;
  getIntimate: any;
  getRelief: any;
  getSomeSleep: any;
  gridView: any;
  gridViewSolid: any;
  handHoldingHeart: any;
  hangWithFriends: any;
  heart: any;
  home: any;
  hungry: any;
  iDontKnow: any;
  idCard: any;
  infoCircle: any;
  infoSquare: any;
  leaf: any;
  lgbtq: any;
  locationArrow: any;
  mapMarker: any;
  mapMarkerInverted: any;
  medStorePlus: any;
  minus: any;
  money: any;
  notHigh: any;
  painFree: any;
  pan: any;
  parkingCircle: any;
  paytender: any;
  percentage: any;
  plus: any;
  polygon: any;
  prideHeart: any;
  products: any;
  quantityMinus: any;
  quantityPlus: any;
  relaxed: any;
  search: any;
  searchBold: any;
  shieldCheck: any;
  shoppingBag: any;
  shoppingCart: any;
  shoppingCartSolid: any;
  sleepy: any;
  smoke: any;
  sms: any;
  sortAlphaDown: any;
  specialBanner: any;
  star: any;
  starEmpty: any;
  starFilled: any;
  starHalf: any;
  stimulateMyMind: any;
  store: any;
  storeAlt: any;
  sync: any;
  thumbsUp: any;
  thumbsUpFilled: any;
  times: any;
  timesCircle: any;
  trackingLeaf: any;
  trash: any;
  twinkleStar: any;
  twitter: any;
  usdCircle: any;
  usdSquare: any;
  userCircle: any;
  userPlus: any;
  vape: any;
  verticalDrag: any;
  veteran: any;
  warningTriangle: any;
  wheelchair: any;
  woman: any;
}

export const icons: Icons = {
  aidMyFurryFriend: AidMyFurryFriendIcon,
  alphabeticalSort: AlphabeticalSortIcon,
  angleDoubleUp: AngleDoubleUpIcon,
  applyTopically: ApplyTopicallyIcon,
  badgeCheck: BadgeCheckIcon,
  bars: BarsIcon,
  bell: BellIcon,
  bellSlash: BellSlashIcon,
  bipoc: BipocIcon,
  blissful: BlissfulIcon,
  calendar: CalendarIcon,
  car: CarIcon,
  caret: CaretIcon,
  caretFilled: CaretRightIcon,
  chartBar: ChartBarIcon,
  checkmark: CheckIcon,
  chevron: ChevronLightIcon,
  chevronFat: ChevronFatIcon,
  circleCheck: CircleCheckIcon,
  circleCheckFilled: CircleCheckFilledIcon,
  clock: ClockIcon,
  close: CloseIcon,
  comment: CommentIcon,
  copy: CopyIcon,
  copyOutline: CopyOutlineIcon,
  creative: CreativeIcon,
  creditCard: CreditCardIcon,
  database: DatabaseIcon,
  dollarSign: DollarSignIcon,
  dollarSignRegular: DollarSignRegularIcon,
  dollarSort: DollarSortIcon,
  dot: DotIcon,
  easeMyMind: EaseMyMindIcon,
  eat: EatIcon,
  edit: EditIcon,
  energetic: EnergeticIcon,
  exclamationTriangle: ExclamationTriangleIcon,
  warningTriangle: WarningTriangleIcon,
  externalLink: ExternalLinkIcon,
  facebook: FacebookIcon,
  filter: FilterIcon,
  filters: FiltersIcon,
  getActive: GetActiveIcon,
  getIntimate: GetIntimateIcon,
  getRelief: GetReliefIcon,
  getSomeSleep: GetSomeSleepIcon,
  gridView: GridViewIcon,
  gridViewSolid: GridViewSolidIcon,
  handHoldingHeart: HandHoldingHeartIcon,
  hangWithFriends: HangWithFriendsIcon,
  heart: HeartIcon,
  home: HomeIcon,
  hungry: HungryIcon,
  idCard: IdCardIcon,
  infoCircle: InfoCircleIcon,
  infoSquare: InfoSquareIcon,
  leaf: LeafIcon,
  lgbtq: LgbtqIcon,
  locationArrow: LocationArrowIcon,
  mapMarker: MapMarkerIcon,
  mapMarkerInverted: MapMarkerInvertedIcon,
  medStorePlus: PlusIcon,
  minus: MinusIcon,
  money: MoneyIcon,
  notHigh: NotHighIcon,
  pan: PanIcon,
  polygon: PolygonIcon,
  iDontKnow: NotSureIcon,
  painFree: PainFreeIcon,
  percentage: PercentageIcon,
  parkingCircle: ParkingCircleIcon,
  paytender: PaytenderIcon,
  plus: PlusIcon,
  products: ProductsIcon,
  prideHeart: PrideHeartIcon,
  quantityMinus: QuantityMinusIcon,
  quantityPlus: QuantityPlusIcon,
  relaxed: RelaxedIcon,
  search: SearchIcon,
  searchBold: SearchBoldIcon,
  shieldCheck: ShieldCheckIcon,
  shoppingBag: ShoppingBagIcon,
  shoppingCart: ShoppingCartIcon,
  shoppingCartSolid: ShoppingCartSolidIcon,
  sleepy: SleepyIcon,
  sms: SMSIcon,
  smoke: SmokeIcon,
  sortAlphaDown: SortAlphaDownIcon,
  specialBanner: SpecialBannerIcon,
  spinner: SpinnerIcon,
  star: StarIcon,
  starEmpty: StarEmptyIcon,
  starFilled: StarFilledIcon,
  starHalf: StarHalfIcon,
  stimulateMyMind: StimulateMyMindIcon,
  store: StoreIcon,
  storeAlt: StoreAltIcon,
  sync: SyncIcon,
  thumbsUp: ThumbsUpIcon,
  thumbsUpFilled: ThumbsUpFilledIcon,
  times: TimesIcon,
  timesCircle: TimesCircleIcon,
  trackingLeaf: TrackingLeafIcon,
  trash: TrashIcon,
  twinkleStar: TwinkleStarIcon,
  twitter: TwitterIcon,
  usdCircle: UsdCircleIcon,
  usdSquare: UsdSquareIcon,
  userCircle: UserCircleIcon,
  userPlus: UserPlusIcon,
  vape: VapeIcon,
  verticalDrag: VerticalDragIcon,
  veteran: VeteranIcon,
  woman: WomanIcon,
  wheelchair: WheelChairIcon,
};

type Size = number | { height?: number; width?: number };

interface NudgeProps {
  down?: number;
  left?: number;
  right?: number;
  up?: number;
}

interface Props {
  ariaHidden?: boolean;
  ariaLabel?: string;
  color?: string;
  dim?: boolean;
  display?: string;
  hidden?: boolean;
  icon: keyof Icons;
  nudge?: NudgeProps;
  role?: string;
  rotate?: 'left' | 'right' | '180';
  scale?: number;
  size?: Size;
  styles?: CSSObject;
}

const transform = (
  rotate: Props['rotate'],
  scale: Props['scale'],
  nudge: Props['nudge']
) => {
  const transformValues = [];

  switch (rotate) {
    case 'left':
      transformValues.push('rotate(-90deg)');
      break;
    case 'right':
      transformValues.push('rotate(90deg)');
      break;
    case '180':
      transformValues.push('rotate(-180deg)');
      break;
  }

  if (scale) transformValues.push(`scale(${scale})`);
  if (nudge) {
    const nudgeX = nudge.right
      ? `${nudge.right}px`
      : nudge.left
      ? `-${nudge.left}px`
      : 0;
    const nudgeY = nudge.down
      ? `${nudge.down}px`
      : nudge.up
      ? `-${nudge.up}px`
      : 0;
    transformValues.push(`translate(${nudgeX}, ${nudgeY})`);
  }

  if (transformValues.length === 0) return undefined;
  return transformValues.join(' ');
};

export default function SvgIcon({
  color,
  dim = !color,
  icon,
  nudge,
  rotate = undefined,
  size = 18,
  scale,
  hidden,
  display,
  ariaHidden,
  ariaLabel,
  role,
  styles,
}: Props) {
  const Icon = icons[icon];
  if (!Icon) {
    return null;
  }

  return (
    <Icon
      css={css(
        typeof size === 'number' ? { height: size, width: size } : size,
        {
          fill: color || 'currentColor',
          transform: transform(rotate, scale, nudge),
          visibility: hidden ? 'hidden' : undefined,
          opacity: dim ? 0.9 : 1.0,
          display,
          ...styles,
        }
      )}
      aria-hidden={ariaHidden}
      aria-label={ariaLabel}
      role={role}
    />
  );
}
