import type { Reducer } from 'redux';

import type { DeepReadonly, StoreSeo } from '@jane/shared/models';

import { createStandardAction } from '../../redux-util';
import { StoreSeoSource } from '../../sources/storeSeo';
import type { CustomerThunkAction } from '../redux';
import type { CustomerAction } from './types';

export const GET_STORE_SEO = 'store-seo/get';
export const getStoreSeo =
  (storeId: string): CustomerThunkAction =>
  (dispatch) => {
    StoreSeoSource.get(storeId).then(
      ({ structured_data, page_title, disable_meta }) => {
        dispatch(
          setStoreSeo({
            structuredData: structured_data,
            pageTitle: page_title,
            disableMeta: disable_meta,
          })
        );
      }
    );
  };

export const SET_STORE_SEO = 'store-seo/set-store-seo';
export const setStoreSeo = createStandardAction(SET_STORE_SEO)<StoreSeo>();

export type StoreSeoActions = ReturnType<typeof setStoreSeo>;

export type StoreSeoState = DeepReadonly<{
  disableMeta: boolean | undefined | null;
  hasLoaded: boolean;
  pageTitle: string | undefined | null;
  structuredData: {};
}>;

const getInitialState = (): StoreSeoState => ({
  structuredData: {},
  pageTitle: undefined,
  disableMeta: undefined,
  hasLoaded: false,
});

export const storeSeoReducer: Reducer<StoreSeoState, CustomerAction> = (
  state = getInitialState(),
  action
) => {
  switch (action.type) {
    case SET_STORE_SEO:
      return { ...state, ...action.payload, hasLoaded: true };
  }

  return state;
};
