import clsx from 'clsx';
import { forwardRef, useEffect, useState } from 'react';

import { Box } from '../../../box';
import { Flex } from '../../../flex';
import { Typography } from '../../../typography';
import type { DoubleSliderFieldProps, DoubleSliderValue } from '../field.types';
import { HelperText } from '../helperText/helperText';
import styles from './doubleSliderField.module.css';
import { SingleSliderFieldInternal } from './singleSliderField';

export interface DoubleSliderFieldPropsInternal extends DoubleSliderFieldProps {
  onUpdate?: (value: DoubleSliderValue) => void;
  value?: DoubleSliderValue;
}

export const calculateDefaultValue = (
  min: number,
  max: number,
  step: number | 'any',
  defaultValueProp?: DoubleSliderValue
) => {
  let minDefaultValue =
    defaultValueProp?.min !== null && defaultValueProp?.min !== undefined
      ? defaultValueProp.min - min
      : (max - min) / 3;

  let maxDefaultValue = defaultValueProp?.max
    ? defaultValueProp.max - min
    : minDefaultValue * 2;
  if (typeof step === 'number') {
    let minMod = minDefaultValue % step;
    minMod = minMod > step / 2 ? minMod - step : minMod;
    let maxMod = maxDefaultValue % step;
    maxMod = maxMod > step / 2 ? maxMod - step : maxMod;
    minDefaultValue = minDefaultValue - minMod;
    maxDefaultValue = maxDefaultValue - maxMod;
  }
  return { max: maxDefaultValue + min, min: minDefaultValue + min };
};

export const DoubleSliderFieldInternal = forwardRef<
  HTMLInputElement,
  DoubleSliderFieldPropsInternal
>(
  (
    {
      autoFocus = false,
      children,
      className,
      defaultValue: defaultValueProp,
      disabled = false,
      endUnit,
      id,
      label,
      labelHidden = false,
      max,
      min,
      name,
      onAfterChange,
      onBlur,
      onChange,
      onUpdate,
      step = 1,
      startUnit,
      'data-testid': testId,
      typography,
      value: externalValue,
      variant = 'primary',
      width = 'auto',
      ...props
    },
    ref
  ) => {
    const defaultValue = calculateDefaultValue(
      min,
      max,
      step,
      defaultValueProp
    );
    const [value, setValue] = useState(defaultValue);

    const helperText = `${startUnit ? startUnit : ''}${value.min}${
      endUnit ? endUnit : ''
    } - ${startUnit ? startUnit : ''}${value.max}${endUnit ? endUnit : ''}`;

    useEffect(() => {
      if (
        externalValue !== undefined &&
        !(externalValue.min === value.min && externalValue.max === value.max)
      ) {
        setValue(externalValue);
        onUpdate && onUpdate(externalValue);
      }
    }, [externalValue, onUpdate, value]);

    return (
      <Flex
        as="fieldset"
        className={clsx(className, styles.doubleSlider_fieldset)}
        data-testid={testId}
        flexDirection="row"
        flexWrap="wrap"
        id={id}
        justifyContent="space-between"
        width={width}
        {...props}
      >
        <Flex alignItems="center" justifyContent="space-between" width="100%">
          <Typography
            className={clsx({
              [styles.doubleSlider_legend__isHidden]: labelHidden,
            })}
            variant={typography}
            as="div"
            mb={12}
          >
            {label}
          </Typography>
          <HelperText
            className={clsx(styles.doubleSlider_helperText, {
              [styles.doubleSlider_helperText__labelHidden]: labelHidden,
            })}
            disabled={disabled}
            id={name}
            mb={12}
          >
            {helperText}
          </HelperText>
        </Flex>
        <Box
          className={clsx({
            [styles.doubleSlider_sliders__disabled]: disabled,
          })}
          position="relative"
          width="100%"
        >
          {[defaultValue.min, defaultValue.max].map(
            (fieldDefaultValue, index) => {
              const sliderValueType = index === 0 ? 'min' : 'max';
              const sliderMin = index === 0 ? min : value.min;
              const sliderMax = index === 0 ? value.max : max;
              const direction = index === 0 ? 'left' : 'right';
              const sliderName = index === 0 ? `${name}Min` : `${name}Max`;
              return (
                <Box
                  key={sliderName}
                  position={direction === 'right' ? 'absolute' : undefined}
                  top={direction === 'right' ? 16 : undefined}
                  width="100%"
                >
                  <SingleSliderFieldInternal
                    autoFocus={autoFocus && index === 0}
                    defaultValue={fieldDefaultValue}
                    direction={direction}
                    disabled={disabled}
                    endUnit={endUnit}
                    helperTextId={name}
                    label={`${label} ${sliderValueType}`}
                    labelHidden
                    max={max}
                    maxInternal={sliderMax}
                    min={min}
                    minInternal={sliderMin}
                    name={sliderName}
                    onAfterChange={() => {
                      onAfterChange && onAfterChange(value);
                    }}
                    onChange={(fieldValue) => {
                      const currentValue = { ...value };
                      currentValue[sliderValueType] = fieldValue;
                      setValue(currentValue);
                      onChange && onChange(currentValue);
                    }}
                    onBlur={() => {
                      onBlur && onBlur(value);
                    }}
                    ref={index === 0 ? ref : undefined}
                    isDouble
                    step={step}
                    data-testid={sliderName}
                    typography={typography}
                    value={externalValue && externalValue[sliderValueType]}
                    variant={variant}
                    width="100%"
                  >
                    {index === 1 && children}
                  </SingleSliderFieldInternal>
                </Box>
              );
            }
          )}
        </Box>
      </Flex>
    );
  }
);

export const DoubleSliderField = forwardRef<
  HTMLInputElement,
  DoubleSliderFieldProps
>((props, ref) => <DoubleSliderFieldInternal ref={ref} {...props} />);
