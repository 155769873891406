import { pluralize } from '@jane/shared/util';

import type { FilterBarPopoverProps } from './filterBarPopover.types';
import { FilterList } from './filterList';
import { FilterPopover } from './filterPopover';

export const FilterBarPopover = ({
  filterGroup,
  hideCounts,
  isLoading,
  onChange,
  onDeselect,
  totalResults,
  variant,
}: FilterBarPopoverProps) => {
  const { label, items, nested, key } = filterGroup;

  const renderedFilterList = items.map((item) => {
    return (
      <FilterList
        filterKey={filterGroup.key}
        filters={item}
        hideCounts={hideCounts}
        isNested={nested}
        key={item.label}
        onChange={onChange}
      />
    );
  });

  return (
    <FilterPopover
      buttonText={label}
      targetLabel={`Filter ${pluralize({
        noun: variant,
        number: 0,
        returnValue: 'word',
      })} by ${label}`}
      isLoading={isLoading}
      listLabel={`Select ${label}`}
      onDeselect={() => onDeselect(key)}
      disabled={items.length === 0}
      totalResults={totalResults}
      nested={nested}
      variant={variant}
      rightAlign={variant === 'dispensary'}
    >
      {renderedFilterList}
    </FilterPopover>
  );
};
