import { createContext, useContext } from 'react';

import type { DrawerProps } from './drawer.types';

interface DrawerContextProps {
  fitScreen: boolean;
  onRequestClose: DrawerProps['onRequestClose'];
}

export const DrawerContext = createContext<DrawerContextProps>({
  onRequestClose: () => {
    /* */
  },
  fitScreen: true,
});

export const useDrawerContext = () => useContext(DrawerContext);
