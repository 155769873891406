import styled from '@emotion/styled';

import { useLocalBrandSpecials } from '@jane/shared-ecomm/data-access';
import { useUserPreferences } from '@jane/shared-ecomm/providers';
import { useShouldShowGold } from '@jane/shared/hooks';
import type { Brand } from '@jane/shared/models';
import { Box, PartnerLogo, ReadMore, Typography } from '@jane/shared/reefer';

import { JaneGoldSubheader } from './janeGoldSubheader';

const MobileBrandContainer = styled.div({
  marginTop: '-75px',
  position: 'relative',
});

const PartnerLogoContainer = styled.div({
  display: 'flex',
  justifyContent: 'center',
});

const StyledBrandName = styled(Typography)<{ forceMobile?: boolean }>(
  ({ forceMobile }) => ({
    '&&': {
      fontSize: forceMobile ? 28 : undefined,
      textAlign: forceMobile ? 'center' : undefined,
    },
  })
);

const StyledDescription = styled(Typography)<{ forceMobile?: boolean }>(
  ({ forceMobile }) => ({
    '&&': {
      fontSize: forceMobile ? 15 : undefined,
      textAlign: forceMobile ? 'center' : undefined,
    },
  })
);

export const MobileBrandDetail = ({
  brand,
  forceMobile,
  isGuestUser,
  isJaneGoldMember,
}: {
  brand: Brand;
  forceMobile?: boolean;
  isGuestUser?: boolean;
  isJaneGoldMember?: boolean;
}) => {
  const { userLocation, userPreferences } = useUserPreferences();
  const { name, logo_url, description } = brand;
  const { localBrandSpecials: brandSpecials = [] } = useLocalBrandSpecials({
    enabled: !!brand,
    brandIds: [brand.id as number],
    userLocation,
    userPreferences,
  });
  const shouldShowGold = useShouldShowGold({ brandSpecials });

  return (
    <MobileBrandContainer data-testid="brand-detail-mobile">
      <Box mx={24} py={24} pb={40}>
        <PartnerLogoContainer>
          <PartnerLogo variant="brand" name={name} image={logo_url} />
        </PartnerLogoContainer>
        <StyledBrandName
          variant="title-bold"
          mt={16}
          textAlign="center"
          forceMobile={forceMobile}
        >
          {name}
        </StyledBrandName>
        {description && (
          <StyledDescription
            mt={8}
            as="h2"
            forceMobile={forceMobile}
            whiteSpace="pre-wrap"
          >
            <ReadMore
              content={description}
              more="View more"
              less="View less"
              characters={135}
            />
          </StyledDescription>
        )}
        {shouldShowGold && (
          <JaneGoldSubheader
            brandName={name}
            isGuestUser={isGuestUser}
            isJaneGoldMember={isJaneGoldMember}
          />
        )}
      </Box>
    </MobileBrandContainer>
  );
};
