import type { Reducer } from 'redux';

import type {
  BerbixTransaction,
  BerbixVerification,
  DeepReadonly,
  Identification,
} from '@jane/shared/models';

import { createSimpleAction, createStandardAction } from '../../redux-util';
import { NotificationsService } from '../../services/notifications';
import { IdentificationSource } from '../../sources/identification';
import type { CustomerThunkAction } from '../redux';
import type { CustomerAction } from './types';
import { UPDATE_USERS } from './users';

export const UPDATE_IDENTIFICATION = 'identification/update';
export const updateIdentification =
  (
    documentPart: Partial<Identification>,
    onSuccess?: () => void
  ): CustomerThunkAction =>
  (dispatch) => {
    dispatch({ type: UPDATE_IDENTIFICATION });

    IdentificationSource.update({ document: documentPart }).then(
      ({ document, message }) => {
        const documentWithImmediateImages = {
          ...document,
          ...documentPart,
        };

        NotificationsService.success(message);
        dispatch(updateIdentificationSuccess(documentWithImmediateImages));
        onSuccess && onSuccess();
      },
      () => {
        dispatch(updateIdentificationError());
      }
    );
  };

export const UPDATE_IDENTIFICATION_SUCCESS = 'identification/update-success';
export const updateIdentificationSuccess = createStandardAction(
  UPDATE_IDENTIFICATION_SUCCESS
)<{ first_name: string; last_name: string }>();

export const UPDATE_IDENTIFICATION_ERROR = 'identification/update-error';
export const updateIdentificationError = createSimpleAction(
  UPDATE_IDENTIFICATION_ERROR
);

export const GET_IDENTIFICATION = 'identification/get-identification';
export const getIdentification = (): CustomerThunkAction => (dispatch) =>
  IdentificationSource.get().then((result) =>
    dispatch({ type: GET_IDENTIFICATION, payload: result })
  );

export const UPLOAD_IDENTIFICATION = 'identification/upload';
export const uploadIdentification =
  ({
    key,
    value,
  }: {
    key: keyof Identification;
    value: string | null;
  }): CustomerThunkAction =>
  (dispatch) => {
    dispatch({ type: UPLOAD_IDENTIFICATION, payload: key });

    const data = {
      document: {
        [key]: value,
      },
    };

    IdentificationSource.update(data).then(
      () => dispatch(uploadIdentificationSuccess({ key, value })),
      () => dispatch(uploadIdentificationError(key))
    );
  };

export const UPLOAD_IDENTIFICATION_SUCCESS = 'identification/upload-success';
export const uploadIdentificationSuccess = createStandardAction(
  UPLOAD_IDENTIFICATION_SUCCESS
)<{ key: keyof Identification; value: string | null }>();

export const UPLOAD_IDENTIFICATION_ERROR = 'identification/upload-error';
export const uploadIdentificationError = createStandardAction(
  UPLOAD_IDENTIFICATION_ERROR
)<unknown>();

export const UPDATE_BIRTH_DATE = 'identification/update-birth-date';
export const updateBirthDate =
  createStandardAction(UPDATE_BIRTH_DATE)<string>();

export const SET_IDENTIFICATION_VALUE =
  'identification/set-identification-value';
export const setIdentificationValue = createStandardAction(
  SET_IDENTIFICATION_VALUE
)<{ name: keyof Identification; value: string }>();

export const SET_BERBIX_VERIFICATION = 'identification/set-berbix-verification';
export const setBerbixVerification = createStandardAction(
  SET_BERBIX_VERIFICATION
)<BerbixVerification>();

export const SET_BERBIX_TRANSACTION_VALUE =
  'identification/set-berbix-transaction-value';
export const setBerbixTransactionValue = createStandardAction(
  SET_BERBIX_TRANSACTION_VALUE
)<BerbixTransaction>();

export type IdentificationActions =
  | { type: typeof UPDATE_IDENTIFICATION }
  | ReturnType<typeof updateIdentificationError>
  | ReturnType<typeof updateIdentificationSuccess>
  | { payload: { document: Identification }; type: typeof GET_IDENTIFICATION }
  | { payload: string; type: typeof UPLOAD_IDENTIFICATION }
  | ReturnType<typeof uploadIdentificationSuccess>
  | ReturnType<typeof uploadIdentificationError>
  | ReturnType<typeof updateBirthDate>
  | ReturnType<typeof setIdentificationValue>
  | ReturnType<typeof setBerbixVerification>
  | ReturnType<typeof setBerbixTransactionValue>;

export interface Checkers {
  birth_date: boolean;
  first_name: boolean;
  government_photo: boolean;
  last_name: boolean;
  mmj_card: boolean;
  student_photo: boolean;
  veteran_photo: boolean;
}

export type IdentificationState = DeepReadonly<{
  berbix: BerbixTransaction;
  berbixVerification: BerbixVerification;
  checkers: Checkers;
  document: Identification;
  documentHasLoaded: boolean;
  isUploading: boolean;
  loading_government_photo: boolean;
  loading_mmj_card: boolean;
  loading_mmj_card_back: boolean;
  loading_student_photo: boolean;
  loading_veteran_photo: boolean;
}>;

const getInitialState = (): IdentificationState => ({
  checkers: {
    first_name: false,
    last_name: false,
    birth_date: false,
    government_photo: false,
    mmj_card: false,
    student_photo: false,
    veteran_photo: false,
  },
  berbix: {
    client_token: '',
    transaction_id: 0,
    customer_uid: '',
  },
  berbixVerification: {
    status: '',
    images: {
      front: '',
    },
  },
  document: {
    first_name: '',
    last_name: '',
    birth_date: '',
    government_photo: null,
    mmj_card: null,
    mmj_card_back: null,
    student_photo: null,
    veteran_photo: null,
  },
  documentHasLoaded: false,
  isUploading: false,
  loading_government_photo: false,
  loading_mmj_card: false,
  loading_mmj_card_back: false,
  loading_student_photo: false,
  loading_veteran_photo: false,
});

const getCheckers = (document: Identification): Checkers => ({
  first_name: !!document.first_name,
  last_name: !!document.last_name,
  birth_date: !!document.birth_date,
  government_photo: !!document.government_photo,
  mmj_card: !!(document.mmj_card && document.mmj_card_back),
  student_photo: !!document.student_photo,
  veteran_photo: !!document.veteran_photo,
});

const setValue = (
  state: IdentificationState,
  key: keyof Identification,
  value: string | number | null
) => {
  const document = {
    ...state.document,
    [key]: value,
  };
  return { ...state, document, checkers: getCheckers(document) };
};
export const identificationReducer: Reducer<
  IdentificationState,
  CustomerAction
> = (state = getInitialState(), action) => {
  switch (action.type) {
    case GET_IDENTIFICATION: {
      const document = { ...state.document, ...action.payload.document };
      return {
        ...state,
        document,
        checkers: getCheckers(document),
        documentHasLoaded: true,
      };
    }

    case SET_IDENTIFICATION_VALUE: {
      return setValue(state, action.payload.name, action.payload.value);
    }

    case SET_BERBIX_TRANSACTION_VALUE: {
      return { ...state, berbix: action.payload };
    }

    case SET_BERBIX_VERIFICATION: {
      return { ...state, berbixVerification: action.payload };
    }

    case UPDATE_IDENTIFICATION:
      return { ...state, isUploading: true };

    case UPDATE_IDENTIFICATION_SUCCESS:
      return {
        ...state,
        isUploading: false,
        document: {
          ...state.document,
          ...action.payload,
        },
        checkers: getCheckers(action.payload),
      };

    case UPDATE_IDENTIFICATION_ERROR:
      return { ...state, isUploading: false };

    case UPLOAD_IDENTIFICATION:
      return { ...state, [`loading_${action.payload}`]: true };

    case UPLOAD_IDENTIFICATION_SUCCESS:
      return setValue(
        {
          ...state,
          [`loading_${action.payload.key}`]: false,
        },
        action.payload.key,
        action.payload.value
      );

    case UPLOAD_IDENTIFICATION_ERROR:
      return { ...state, [`loading_${action.payload}`]: false };

    case UPDATE_BIRTH_DATE:
      return setValue(state, 'birth_date', action.payload);

    case UPDATE_USERS:
      return {
        ...state,
        isUploading: false,
        document: {
          ...state.document,
          first_name: action.payload.first_name,
          last_name: action.payload.last_name,
        },
      };
  }

  return state;
};
