import { config } from '@jane/shared/config';

export default function sourceUrlBuilder(
  app: 'business' | 'customer',
  suffix: string
) {
  if (app === 'business') {
    suffix = `managers/${suffix}`;
  }

  return `${config.apiPath}/${suffix}`;
}
