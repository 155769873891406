import { useMediaQuery } from 'react-responsive';

import type {
  ReeferMediaQueryDesktopSize,
  ReeferMediaQueryMobileSize,
  ReeferMediaQueryParam,
} from '../internal/utils';
import { REEFER_MEDIA_QUERY_SIZES } from '../internal/utils';

export interface ReeferMediaQueryMobileProps {
  size?: ReeferMediaQueryMobileSize;
  width?: ReeferMediaQueryParam;
}

export interface ReeferMediaQueryDesktopProps {
  size?: ReeferMediaQueryDesktopSize;
  width?: ReeferMediaQueryParam;
}

export interface ReeferMediaQueryTabletProps {
  width?: ReeferMediaQueryParam;
}

/**
 * Media Query Hooks
 * */

export function useMobileMediaQuery({
  size,
  width = 'max',
}: ReeferMediaQueryMobileProps) {
  // defaults to 1px smaller than tablet
  const screenSize = size
    ? REEFER_MEDIA_QUERY_SIZES[`mobile-${size}`]
    : REEFER_MEDIA_QUERY_SIZES['tablet'];

  return useMediaQuery({
    query: `(${width}-width: ${screenSize})`,
  });
}

export function useTabletMediaQuery({
  width = 'min',
}: ReeferMediaQueryTabletProps) {
  return useMediaQuery({
    query: `(${width}-width: ${REEFER_MEDIA_QUERY_SIZES['tablet']})`,
  });
}

export function useDesktopMediaQuery({
  size = 'sm',
  width = 'min',
}: ReeferMediaQueryDesktopProps) {
  const screenSize = REEFER_MEDIA_QUERY_SIZES[`desktop-${size}`];

  return useMediaQuery({
    query: `(${width}-width: ${screenSize})`,
  });
}
