import type { AlgoliaProduct } from '@jane/search/types';
import type { MenuProduct, Product } from '@jane/shared/models';
import type { MenuProduct as ZMenuProduct } from '@jane/shared/types';

import { defaultProductPhotoUrl } from './defaultProductPhotoUrl';

export const productPhotoMetadata = (
  product?: Product | MenuProduct | ZMenuProduct | AlgoliaProduct
): { photoSource: 'jane' | 'default'; url: string } => {
  let photoSource: 'jane' | 'default';
  let url: string;

  if (!product) {
    return { photoSource: 'jane', url: '' };
  }

  const { type, root_subtype, image_urls, category, kind } = product;

  if (image_urls?.length) {
    url = image_urls[0];
    photoSource = 'jane';
  } else {
    url = defaultProductPhotoUrl({
      category,
      kind,
      productRootSubtype: root_subtype,
      productType: type,
    });
    photoSource = 'default';
  }

  return {
    photoSource,
    url,
  };
};
