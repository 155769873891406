import type { QueryFunctionContext } from '@tanstack/react-query';
import { useQuery } from '@tanstack/react-query';
import { useEffect, useState } from 'react';

import type {
  AppMode,
  FetchTopOfMenuRowProps,
  Filters,
  JaneDM,
  JaneDMIdentifiers,
  TopOfMenuRow,
} from '@jane/dm/sdk';
import { useAlgoliaMenuProductsQuery } from '@jane/search/hooks';
import type { MenuCategoryPath } from '@jane/shared/types';

import { useDmSdk } from '../useDmSdk';
import { useGetJaneDMIdentifiers } from '../useGetJaneDMIdentifiers';

type SharedRowProps<T> = T &
  Omit<FetchTopOfMenuRowProps, 'currentCreativeIds'> &
  Partial<JaneDMIdentifiers> & {
    appMode: AppMode;
  };

type UseTopOfMenuRowQueryProps = SharedRowProps<{
  enabled: boolean;
  previousData: TopOfMenuRow | undefined;
}>;

type UseTopOfMenuProps = SharedRowProps<{
  enableRefetch?: boolean;
  isTimerFinished: boolean;
  pageCategory?: MenuCategoryPath;
  restartTimer: () => void;
}>;

const topOfMenuRowKeys = {
  all: ['sponsoredRow'] as const,
  list: (filters: Filters | undefined, storeId: number, sdk: JaneDM) =>
    [...topOfMenuRowKeys.all, filters, storeId, sdk] as const,
};

interface FetchTopOfMenuRowResponse {
  instance: TopOfMenuRow;
  productIds: number[];
}

const fetchTopOfMenuRow = async (
  context: QueryFunctionContext<ReturnType<typeof topOfMenuRowKeys['list']>>,
  row: TopOfMenuRow | undefined
): Promise<FetchTopOfMenuRowResponse> => {
  const [, filters, storeId, sdk] = context.queryKey;

  const instance = await sdk?.fetchTopOfMenuRow({
    currentCreativeIds: row?.creativeIds ?? [],
    filters,
    storeId: Number(storeId),
  });

  return { instance, productIds: instance.products.map((p) => p.productId) };
};

const useTopOfMenuRowQuery = (props: UseTopOfMenuRowQueryProps) => {
  const {
    appMode,
    jdid,
    mixpanelDistinctId,
    previousData,
    storeId,
    filters,
    enabled,
  } = props;
  const sdk = useDmSdk({
    appMode,
    identifier: useGetJaneDMIdentifiers({ jdid, mixpanelDistinctId }),
  });

  const { data, isError, isLoading, isSuccess, refetch } = useQuery({
    enabled,
    queryFn: async (context) => await fetchTopOfMenuRow(context, previousData),
    // eslint-disable-next-line @tanstack/query/exhaustive-deps
    queryKey: topOfMenuRowKeys.list(filters, storeId, sdk),
  });

  return {
    isError,
    isLoading,
    isSuccess,
    productIds: data?.productIds,
    refetch,
    rowInstance: data?.instance,
  };
};

interface UseTopOfMenuRowResponse {
  isError: boolean;
  isLoading: boolean;
  isSuccess: boolean;
  rowInstance: TopOfMenuRow | undefined;
}

export const useTopOfMenuRow = ({
  enableRefetch = true,
  isTimerFinished,
  pageCategory,
  restartTimer,
  ...props
}: UseTopOfMenuProps): UseTopOfMenuRowResponse => {
  const [topOfMenuRow, setTopOfMenuRow] = useState<TopOfMenuRow | undefined>(
    undefined
  );

  /**
   * Query for sponsored products
   */
  const {
    productIds,
    rowInstance,
    isError,
    isLoading,
    isSuccess,
    refetch: refetchRowInstance,
  } = useTopOfMenuRowQuery({
    enabled: false, // Use refetch to run the query
    previousData: topOfMenuRow,
    ...props,
  });

  /**
   * Query for product information for sponsored products
   */
  const {
    data: menuProducts,
    isError: menuProductsError,
    isSuccess: menuProductsSuccess,
  } = useAlgoliaMenuProductsQuery({
    productIds: productIds ?? [],
    storeId: props.storeId,
  });

  /**
   * Set all of the product information on the sponsored products
   * */
  useEffect(() => {
    if (menuProducts && menuProducts.length > 0 && rowInstance) {
      menuProducts.forEach((product) => {
        const productInstance = rowInstance.products.find(
          (p) => p.productId === product.product_id
        );

        if (productInstance) {
          productInstance.setAttributes(product);
        }
      });
      setTopOfMenuRow(rowInstance);
    }
  }, [menuProducts, rowInstance]);

  /**
   * Restart timer and refetch on page change/load
   *  */
  useEffect(() => {
    restartTimer();
    refetchRowInstance();
  }, [pageCategory]);

  /**
   * Restart the refresh timer when time has elapsed
   */
  useEffect(() => {
    if (isTimerFinished) {
      if (productIds && productIds.length !== 0 && enableRefetch) {
        refetchRowInstance();
      }
      restartTimer();
    }
  }, [isTimerFinished, productIds, enableRefetch]);

  /**
   * Check to make sure we should render the row
   */
  const isRowValid = topOfMenuRow
    ? topOfMenuRow.isRowInFilterBrands(rowInstance)
    : true;

  return {
    isError: isError || menuProductsError,
    isLoading: isLoading,
    isSuccess: isSuccess && menuProductsSuccess,
    rowInstance: isRowValid ? topOfMenuRow : undefined,
  };
};
