import clsx from 'clsx';
import { forwardRef } from 'react';

import { useDrawerContext } from '../drawer.context';
import type { DrawerContentProps } from '../drawer.types';
import styles from './drawerContent.module.css';

export const DrawerContent = forwardRef<HTMLDivElement, DrawerContentProps>(
  (
    {
      children,
      className,
      'data-testid': testId,
      id,
      noPadding = false,
      style,
    },
    ref
  ) => {
    const { fitScreen } = useDrawerContext();

    return (
      <div
        className={clsx(className, styles.drawerContent, {
          [styles.drawerContent__fitScreen]: fitScreen,
          [styles.drawerContent__withPadding]: !noPadding,
        })}
        data-testid={testId}
        id={id}
        ref={ref}
        style={style}
      >
        {children}
      </div>
    );
  }
);
