import { keyframes } from '@emotion/react';
import styled from '@emotion/styled';

import { shadows } from '@jane/shared/reefer-emotion';

const cardAnimation = keyframes`
0% { opacity: 0; transform: scale(0.9) translateY(-10px); }
100% { opacity: 1; transform: scale(1) translateY(0); }
`;

export const StyledCardContent = styled.div<{
  focused?: boolean;
  myHighMenu?: boolean;
}>(({ focused, myHighMenu, theme }) => ({
  width: '100%',
  backfaceVisibility: 'hidden',
  WebkitBackfaceVisibility: 'hidden',
  borderRadius: theme.borderRadius.sm,
  overflow: 'hidden',
  backgroundColor: focused
    ? theme.colors.grays.ultralight
    : theme.colors.grays.white,
  cursor: focused ? 'default' : 'pointer',
  ...(!myHighMenu
    ? {
        boxShadow: focused ? shadows.item : shadows.hard,
        ...(!focused && {
          '&:hover': {
            boxShadow: '0 2px 11px 0 rgba(108, 108, 108, 0.5)',
          },
          '&:hover:active': {
            boxShadow: '0 2px 4px 0 rgba(212, 212, 212, 0.5)',
          },
        }),
      }
    : {
        border: `1px solid ${theme.colors.transparent.black.ten}`,
        ...(!focused && {
          '&:hover': {
            borderColor: theme.colors.transparent.black.twenty,
          },
        }),
      }),
  willChange: 'transform',
  animation: `${cardAnimation} cubic-bezier(0.250, 0.460, 0.450, 0.940) 200ms both`,
}));
