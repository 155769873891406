import { useEffect, useRef } from 'react';

// Relative path import was preventing mocks from working in unit tests
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { tokenIsExpired } from '@jane/shared/auth';

const CHECK_INTERVAL = 300000; // 5 minutes

export const useCheckAuthTimer = (
  bearerToken: string | null | undefined,
  onInvalid: () => void,
  intervalTimeMs: number = CHECK_INTERVAL
) => {
  const onInvalidRef = useRef(onInvalid);

  useEffect(() => {
    onInvalidRef.current = onInvalid;
  }, [onInvalid]);

  useEffect(() => {
    if (!bearerToken) {
      return;
    }

    const validateToken = () => {
      // check if token expires 2x the sampling period to ensure we hit it
      // before it actually is expired
      if (tokenIsExpired(bearerToken, intervalTimeMs * 2)) {
        onInvalidRef.current();
      }
    };

    validateToken();

    const interval = setInterval(() => {
      validateToken();
    }, intervalTimeMs);

    return () => clearInterval(interval);
  }, [bearerToken, intervalTimeMs]);
};
