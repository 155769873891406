import clsx from 'clsx';
import { useContext } from 'react';

import { paddingCSSVars } from '../../../utils';
import { ModalContext } from '../modal';
import type { ModalFooterProps } from '../modal.types';
import styles from './modalFooter.module.css';

export function ModalFooter({
  children,
  className,
  'data-testid': testId,
  id,
  style,
}: ModalFooterProps) {
  const { variant } = useContext(ModalContext);

  return (
    <div
      className={clsx(
        className,
        [styles.modalFooter],
        styles[`modalFooter__${variant}`]
      )}
      id={id}
      style={{
        ...paddingCSSVars({
          px: 24,
          py: 16,
        }),
        ...style,
      }}
      data-id="modal-footer"
      data-testid={testId}
    >
      {children}
    </div>
  );
}
