import styled from '@emotion/styled';

import type { AccordionSummaryProps } from './accordion.types';
import { useItemExpanded, useItemId } from './accordionItemContext';

const SummaryWrapper = styled.div<{ isVisible: boolean }>(({ isVisible }) => ({
  display: 'grid',
  gridTemplateRows: isVisible ? '1fr' : '0fr',
}));

const Summary = styled.div({
  overflow: 'hidden',
});

export const AccordionSummary = ({
  hidden = false,
  children,
  ...props
}: AccordionSummaryProps) => {
  const id = useItemId();
  const isExpanded = useItemExpanded();
  // Only show the summary content when hidden is false, and when it's not expanded.
  const isVisible = !hidden && !isExpanded;

  return (
    <SummaryWrapper isVisible={isVisible}>
      {isVisible && (
        <Summary key={`summary-${id}`} {...props}>
          {children}
        </Summary>
      )}
    </SummaryWrapper>
  );
};
