import { useCallback, useEffect, useState } from 'react';
import type { MutableRefObject } from 'react';

import { MultifactorAuthentication } from '@jane/shared/components';
import { useMfa } from '@jane/shared/providers';
import { Box } from '@jane/shared/reefer';

import { AeropayLoading } from './aeropayLoading';
import type { AerosyncWidget } from './useAerosync';

export interface AeropayScreenProps {
  isLinkingBankAccount: boolean;
  isLoadingWidget: boolean;
  onCancel: () => void;
  onMfaSuccess?: () => void;
  widgetRef: MutableRefObject<AerosyncWidget | null>;
}

export const WIDGET_CONTAINER_ID = 'aerosync-widget';

export const AeropayScreen = ({
  isLinkingBankAccount,
  isLoadingWidget,
  onCancel,
  widgetRef,
}: AeropayScreenProps) => {
  const { requiresMfa } = useMfa();
  const [userVerified, setUserVerified] = useState(false);

  const handleVerifySuccess = useCallback(() => {
    setUserVerified(true);
    widgetRef.current && widgetRef.current.launch();
  }, [widgetRef]);

  const isLoading = isLinkingBankAccount || isLoadingWidget;

  useEffect(() => {
    if (!isLoadingWidget && !requiresMfa()) {
      handleVerifySuccess();
    }
  }, [isLoadingWidget]);

  return (
    <Box
      width={userVerified && !isLoading ? '342px' : 'auto'}
      height={userVerified && !isLoading ? '633px' : 'auto'}
    >
      {isLinkingBankAccount && <AeropayLoading />}

      {!userVerified && requiresMfa() && !isLinkingBankAccount && (
        <MultifactorAuthentication
          isLoading={isLoadingWidget}
          onCancel={onCancel}
          onSuccess={handleVerifySuccess}
        />
      )}

      <div id={WIDGET_CONTAINER_ID}></div>
    </Box>
  );
};
