import type { Reducer } from 'redux';

import type { Address, DeepReadonly, PosCart } from '@jane/shared/models';
import { Storage, trackError } from '@jane/shared/util';

import { createStandardAction } from '../../redux-util';
import { PosCartSource } from '../../sources/posCart';
import type { CustomerThunkAction } from '../redux';
import type { CustomerAction } from './types';

export const GET_POS_CART = 'pos-cart/get';
export const getPosCart = (): CustomerThunkAction => (dispatch, getState) => {
  const {
    cart: {
      cart: { uuid, id, products, store },
    },
  } = getState();

  dispatch({ type: GET_POS_CART });

  const deliveryAddress = Storage.get<Address | null>('deliveryAddress');

  if (!uuid) {
    trackError('Cannot fetch PosCart without a cart uuid', {
      uuid,
      id,
      productCount: products.length,
      storeId: store?.id,
    });

    return;
  }

  return PosCartSource.get(deliveryAddress, uuid).then(
    (result) => dispatch(getPosCartSuccess(result)),
    (err) => dispatch(getPosCartError(err))
  );
};

export const GET_POS_CART_SUCCESS = 'pos-cart/get-success';
export const getPosCartSuccess = createStandardAction(GET_POS_CART_SUCCESS)<{
  pos_cart: PosCart;
}>();

export const GET_POS_CART_ERROR = 'pos-cart/get-error';
export const getPosCartError =
  createStandardAction(GET_POS_CART_ERROR)<unknown>();

export type PosCartActions =
  | { type: typeof GET_POS_CART }
  | ReturnType<typeof getPosCartSuccess>
  | ReturnType<typeof getPosCartError>;

export type PosCartState = DeepReadonly<{
  isLoading: boolean;
  posCart: PosCart;
}>;

const getInitialState = (): PosCartState => ({
  posCart: {
    totals: null,
  },
  isLoading: false,
});

export const posCartReducer: Reducer<PosCartState, CustomerAction> = (
  state = getInitialState(),
  action
) => {
  switch (action.type) {
    case GET_POS_CART:
      return { ...state, isLoading: true };

    case GET_POS_CART_SUCCESS:
      return { ...state, posCart: action.payload.pos_cart, isLoading: false };

    case GET_POS_CART_ERROR:
      return { ...state, isLoading: false };
  }

  return state;
};
