import { useAeropayUser } from '@jane/shared-ecomm/data-access';
import { useJaneUser } from '@jane/shared/data-access';

export const useJaneGoldData = () => {
  const { data: userData, isFetching: userIsFetching } = useJaneUser();
  const { data: aeropayUserData, isFetching: aeropayUserIsFetching } =
    useAeropayUser({
      janeUserId: userData?.user?.id || null,
    });

  const bankAccounts = aeropayUserData?.bank_accounts || [];
  const selectedBankAccount = bankAccounts?.find(
    (bankAccount) => bankAccount.is_selected === true
  );
  const userHasLinkedBank = bankAccounts && bankAccounts.length > 0;

  const requiresAuthentication =
    !userData || !userData?.user?.id || !userData?.user?.authenticated;

  const isLoading = userIsFetching || aeropayUserIsFetching;

  return {
    userData,
    aeropayUserData,
    isGuestUser: requiresAuthentication,
    isLoading,
    selectedBankAccount,
    userHasLinkedBank,
  };
};
