/**
 * DO NOT MODIFY THIS FILE!
 * It was created by the reefer-icons generator.
 * Please update & re-run the generator if need be.
 */
import type { SVGProps } from 'react';

import type { DynamicIconProps } from '../../../icon/icon.types';
import { Icon } from '../../icon';

const Filters24 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="100%"
    height="100%"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <g className="Filters 24">
      <path
        fill="#0E0E0E"
        fillRule="evenodd"
        d="M16 3a4.002 4.002 0 0 1 3.874 3H21a1 1 0 1 1 0 2h-1.126a4.002 4.002 0 0 1-7.748 0H3a1 1 0 0 1 0-2h9.126c.444-1.725 2.01-3 3.874-3Zm0 6a2 2 0 1 0 0-4 2 2 0 0 0 0 4ZM2 17a1 1 0 0 0 1 1h1.126a4.002 4.002 0 0 0 7.748 0H21a1 1 0 1 0 0-2h-9.126a4.002 4.002 0 0 0-7.748 0H3a1 1 0 0 0-1 1Zm8 0a2 2 0 1 0-4 0 2 2 0 0 0 4 0Z"
        className="SVG"
        clipRule="evenodd"
      />
    </g>
  </svg>
);

const SIZE_MAP = {
  md: Filters24,
  sm: Filters24,
  lg: Filters24,
  xl: Filters24,
  xxl: Filters24,
  xxxl: Filters24,
};

export const FiltersIcon = ({
  size = 'md',
  'data-testid': testId,
  ...props
}: DynamicIconProps) => {
  return (
    <Icon
      data-testid={testId || 'filters-icon'}
      icon={SIZE_MAP[size]}
      {...props}
      size={size}
    />
  );
};
