import { Flex, TagFilledIcon, Typography } from '@jane/shared/reefer';

export const SpecialsTag = ({
  title,
  bloomUserExperience,
}: {
  bloomUserExperience?: boolean;
  title: string;
}) => {
  return (
    <Flex alignItems="center">
      <TagFilledIcon size="sm" color="info" />
      <Typography color={bloomUserExperience ? 'text-main' : 'info'} ml={4}>
        {title}
      </Typography>
    </Flex>
  );
};
