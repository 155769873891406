import { CountDot } from '@jane/shared/components';
import { AccountIcon, Box } from '@jane/shared/reefer';

export const UserIcon = ({
  inProgressReservations,
  pendingCashBack,
}: {
  inProgressReservations: number;
  pendingCashBack?: number | string;
}) => {
  return (
    <Box position="relative" m={12}>
      <AccountIcon color="primary" />
      {!!inProgressReservations && (
        <CountDot
          ariaLabel="active reservations"
          count={inProgressReservations}
        />
      )}
      {!inProgressReservations && !!pendingCashBack && (
        <CountDot ariaLabel="pending cash back" gold count={'$'} />
      )}
    </Box>
  );
};
