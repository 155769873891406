import type {
  AlgoliaProduct,
  FetchAlgoliaProductParams,
  SearchAlgoliaProductsParams,
} from '@jane/search/types';
import { AlgoliaProductsSchema } from '@jane/search/types';
import { isEmpty } from '@jane/shared/guards';

import { search } from './client';

export async function searchAlgoliaProducts({
  activities,
  applicableBrandSpecialIds,
  brands,
  categories,
  cbdPotenciesMax,
  cbdPotenciesMin,
  feelings,
  hasBrandDiscount,
  hitsPerPage,
  lineages,
  options,
  page,
  prices,
  ratings,
  searchText,
  sort,
  staticFilters,
  storeIds,
  storeSpecificProduct,
  thcPotenciesMax,
  thcPotenciesMin,
}: SearchAlgoliaProductsParams) {
  return await search<AlgoliaProduct>(
    {
      booleanFilters: {
        has_brand_discount: hasBrandDiscount,
        store_specific_product: storeSpecificProduct,
      },
      bucketFilters: {
        aggregate_rating: ratings ?? [],
        bucket_price: prices ?? [],
      },
      filters: {
        activities: activities ?? [],
        applicable_brand_special_ids: applicableBrandSpecialIds ?? [],
        brand: brands ?? [],
        category: lineages ?? [],
        feelings: feelings ?? [],
        root_types: categories ?? [],
        store_id: storeIds ?? [],
      },
      hitsPerPage,
      indexPrefix: `menu-products-`,
      options,
      page,
      rangeFilters: {
        product_percent_cbd: { max: cbdPotenciesMax, min: cbdPotenciesMin },
        product_percent_thc: { max: thcPotenciesMax, min: thcPotenciesMin },
      },
      searchText,
      currentSort: sort,
      staticFilters,
    },
    options?.attributesToRetrieve ? undefined : AlgoliaProductsSchema
  );
}

export async function fetchAlgoliaProduct({
  storeId,
  productId,
  uniqueSlug,
}: FetchAlgoliaProductParams): Promise<AlgoliaProduct | null> {
  const filters = {
    ...(productId && { product_id: [productId] }),
    ...(uniqueSlug && { unique_slug: [uniqueSlug] }),
    store_id: [storeId],
  };

  const { hits } = await search<AlgoliaProduct>(
    {
      filters,
      indexPrefix: 'menu-products-',
    },
    AlgoliaProductsSchema
  );

  return isEmpty(hits) ? null : hits[0];
}
