import {
  Box,
  Button,
  ChevronDownIcon,
  ConditionalWrapper,
  Flex,
  Link,
  Popover,
  SearchField,
  Typography,
  useMobileMediaQuery,
} from '@jane/shared/reefer';
import type { PopoverContextProps } from '@jane/shared/reefer';
import { pluralize } from '@jane/shared/util';

import {
  FilterButtonGrid,
  StyledButtonWrapper,
  StyledFilterList,
} from '../filter.styles';
import { DeselectAllListItem } from './deselectAllListItem';
import type { FilterPopoverProps } from './filterBarPopover.types';

export const FilterPopover = ({
  buttonText,
  children,
  disabled,
  isLoading,
  listLabel,
  totalResults,
  nested,
  onDeselect,
  rightAlign,
  targetLabel,
  typeaheadOptions,
  variant,
}: FilterPopoverProps) => {
  const isMobile = useMobileMediaQuery({});
  const isSmallestMobile = useMobileMediaQuery({ size: 'sm', width: 'max' });

  const countLabel = pluralize({
    kilo: true,
    noun: variant,
    number: totalResults,
  });

  const noResultsLabel = `${countLabel} ${!isMobile ? 'available' : ''}`;

  const showButtonLabel =
    totalResults === 0 ? noResultsLabel : `Show ${countLabel}`;

  return (
    <Popover
      label={targetLabel}
      target={
        <Button
          label={buttonText}
          endIcon={!isMobile ? <ChevronDownIcon size="sm" /> : undefined}
          variant="tertiary"
          disabled={disabled}
        />
      }
      alignment={{
        horizontal: rightAlign ? 'right' : 'left',
        vertical: 'bottom',
      }}
      openOn={disabled ? 'disabled' : 'click'}
    >
      {({ closePopover, isOpen }: PopoverContextProps) => {
        const { label, onChange, query } = typeaheadOptions || {};

        if (onChange) {
          if (!isOpen && query) {
            query.length > 0 && onChange('');
          }
        }

        return (
          <Popover.Content padding={false}>
            {typeaheadOptions && (
              <SearchField
                label={`Search ${label}`}
                name={`Search ${label}`}
                defaultValue={query}
                onChange={onChange}
                disableMobileInputStyling
                mx={16}
                mt={16}
              />
            )}
            <StyledFilterList aria-label={listLabel}>
              <ConditionalWrapper
                condition={!nested && isMobile}
                wrapper={(children) => (
                  <Box p={24}>
                    <FilterButtonGrid itemsPerRow={4} mobileItemsPerRow={2}>
                      {children}
                    </FilterButtonGrid>
                  </Box>
                )}
              >
                {children}
              </ConditionalWrapper>

              {isMobile ? (
                <StyledButtonWrapper>
                  <Flex
                    gap={16}
                    flexDirection={isSmallestMobile ? 'column' : 'row'}
                    alignItems="center"
                    py={16}
                    px={24}
                  >
                    <Flex grow pl={12}>
                      <Link onClick={onDeselect}>
                        <Typography>Deselect all</Typography>
                      </Link>
                    </Flex>

                    <Button
                      label={showButtonLabel}
                      full
                      onClick={closePopover}
                      data-testid={`show-${variant}-button`}
                      loading={isLoading || totalResults === undefined}
                      disabled={totalResults === 0}
                    />
                  </Flex>
                </StyledButtonWrapper>
              ) : (
                <DeselectAllListItem onClick={onDeselect} />
              )}
            </StyledFilterList>
          </Popover.Content>
        );
      }}
    </Popover>
  );
};
