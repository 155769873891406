import { Button, GoogleIcon } from '@jane/shared/reefer';
import type { ButtonProps } from '@jane/shared/reefer';

export interface GoogleButtonProps extends ButtonProps {
  label: string;
  loaded: boolean | undefined;
  onClick: () => void;
}

const GoogleSignInButton = ({
  onClick,
  loaded,
  label,
  ...buttonProps
}: GoogleButtonProps) => {
  return (
    <Button
      disabled={!loaded}
      full
      label={label}
      onClick={onClick}
      startIcon={<GoogleIcon size="sm" />}
      variant="tertiary"
      {...buttonProps}
    />
  );
};

export default GoogleSignInButton;
