import type { CSSObject } from '@emotion/react';
import styled from '@emotion/styled';
import type { ReactNode } from 'react';

import type { ReeferTheme } from '@jane/shared/reefer';
import { spacing } from '@jane/shared/reefer-emotion';

import { useTextField } from './text-field-context';
import type { Variant } from './text-input';

type ContainerProps = {
  disabled?: boolean;
  error?: boolean;
  focused?: boolean;
  success?: boolean;
  theme?: ReeferTheme;
  variant?: Variant;
};

const material = (props: ContainerProps): CSSObject => ({
  ...spacing({ mt: 16 }),
  position: 'relative',
  width: '100%',
  '&::before': {
    left: 0,
    right: 0,
    bottom: 0,
    content: '"\\00a0"',
    position: 'absolute',
    transition: 'border-bottom-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    borderBottom: `1px solid ${props.theme.colors.grays.light}`,
    pointerEvents: 'none',
    ...(props.error && {
      borderBottom: `2px solid ${props.theme.colors.system.negative.main}`,
    }),
    ...(props.success && {
      borderBottom: `2px solid ${props.theme.colors.system.positive.main}`,
    }),
    ...(props.disabled && {
      borderBottom: `1px dotted ${props.theme.colors.grays.light}`,
    }),
  },
  '&::after': {
    left: 0,
    right: 0,
    bottom: 0,
    content: '""',
    position: 'absolute',
    transformOrigin: 'left center',
    transform: props.focused ? 'scaleX(1)' : 'scaleX(0)',
    transition: 'transform 250ms cubic-bezier(0.0, 0, 0.2, 1) 0ms',
    borderBottom: `2px solid ${props.theme && props.theme.colors.primary.main}`,
    pointerEvents: 'none',
    ...(props.error && {
      borderBottomColor: props.theme.colors.system.negative.main,
    }),
    ...(props.success && {
      borderBottomColor: props.theme.colors.system.positive.main,
    }),
    ...(props.disabled && {
      borderBottom: `1px dotted ${props.theme.colors.grays.light}`,
    }),
  },
  '@media (hover: hover)': {
    '&:hover::before': {
      borderBottom: `2px solid ${props.theme.colors.grays.light}`,
      ...(props.error && {
        borderBottomColor: props.theme.colors.system.negative.main,
      }),
      ...(props.success && {
        borderBottomColor: props.theme.colors.system.positive.main,
      }),
      ...(props.disabled && {
        borderBottom: `1px dotted ${props.theme.colors.grays.light}`,
      }),
    },
  },
});

const Container = styled.div<ContainerProps>((props) => {
  switch (props.variant) {
    case 'material':
      return material(props);

    default:
      break;
  }
});

const InputControl = ({
  disabled = false,
  children,
}: {
  children: ReactNode;
  disabled?: boolean;
}) => {
  const { inputRef, setShrink, setFocused, success, error, focused, variant } =
    useTextField();

  const onFocus = () => {
    setShrink(true);
    setFocused(true);
  };

  const onBlur = () => {
    if (inputRef && inputRef.current && !inputRef.current.value) {
      setShrink(false);
    }
    setFocused(false);
  };

  return (
    <Container
      onFocus={onFocus}
      onBlur={onBlur}
      error={error}
      success={success}
      focused={focused}
      disabled={disabled}
      variant={variant}
    >
      {children}
    </Container>
  );
};

export default InputControl;
