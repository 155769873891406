import type { Hit } from '@jane/search/types';
import type { MenuProduct } from '@jane/shared/models';
import type { GridData } from '@jane/shared/types';

/**
 * Filters product results to ensure the final row never consists of only an inline ad
 */

export const filterAds = (
  gridData: GridData | null,
  products: Hit<MenuProduct>[]
): Hit<MenuProduct>[] => {
  if (!products || !gridData) return products;

  const numAdsToRender =
    products.length % gridData.gridColumnCount === 1
      ? gridData.gridRowCount - 1
      : gridData.gridRowCount;

  let numAdsRendered = 0;

  const filteredProducts = products.filter((product) => {
    if (!product?.flight) {
      return true;
    } else if (numAdsRendered < numAdsToRender) {
      numAdsRendered++;
      return true;
    } else {
      return false;
    }
  });

  return filteredProducts;
};
