import type { SVGProps } from 'react';

import { Icon } from '../../icon/icon';
import type { StaticIconProps } from '../../icon/icon.types';

const SeaLion = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="64"
    height="64"
    viewBox="0 0 64 64"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <circle cx={32} cy={32} r={32} fill="#5E1FF0" />
    <circle cx={32} cy={32} r={32} fill="white" fillOpacity={0.8} />
    <circle cx={32} cy={32} r={20} fill="#5E1FF0" />
    <ellipse
      cx={12.7321}
      cy={28.4641}
      rx={2}
      ry={4}
      transform="rotate(30 12.7321 28.4641)"
      fill="#5E1FF0"
    />
    <ellipse
      cx={50.7321}
      cy={28.4641}
      rx={2}
      ry={4}
      transform="rotate(-30 50.7321 28.4641)"
      fill="#5E1FF0"
    />
    <path
      d="M31.5464 47.5464C26.9516 52.1412 20.7436 53.3828 17.6804 50.3196C14.6172 47.2564 15.8588 41.0484 20.4536 36.4536C25.0484 31.8588 31.2564 30.6172 34.3196 33.6804C37.3828 36.7436 36.1412 42.9516 31.5464 47.5464Z"
      fill="#FF92E0"
    />
    <path
      d="M43.5464 36.4536C48.1412 41.0484 49.3828 47.2564 46.3196 50.3196C43.2564 53.3828 37.0484 52.1412 32.4536 47.5464C27.8588 42.9516 26.6172 36.7436 29.6804 33.6804C32.7436 30.6172 38.9516 31.8588 43.5464 36.4536Z"
      fill="#FF92E0"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M24.4999 39.9913C24.5047 40.2674 24.2848 40.4951 24.0087 40.4999C17.7643 40.6089 12.6089 45.7643 12.4999 52.0087C12.4951 52.2848 12.2674 52.5047 11.9913 52.4999C11.7152 52.4951 11.4953 52.2674 11.5001 51.9913C11.6185 45.2083 17.2083 39.6185 23.9913 39.5001C24.2674 39.4953 24.4951 39.7152 24.4999 39.9913Z"
      fill="#0E0E0E"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M52.0087 52.4999C51.7326 52.5047 51.5049 52.2848 51.5001 52.0087C51.3911 45.7643 46.2357 40.6089 39.9913 40.4999C39.7152 40.4951 39.4953 40.2674 39.5001 39.9913C39.5049 39.7152 39.7326 39.4953 40.0087 39.5001C46.7917 39.6185 52.3815 45.2083 52.4999 51.9913C52.5047 52.2674 52.2848 52.4951 52.0087 52.4999Z"
      fill="#0E0E0E"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M26.4999 41.9913C26.5047 42.2674 26.2848 42.4951 26.0087 42.4999C19.7643 42.6089 14.6089 47.7643 14.4999 54.0087C14.4951 54.2848 14.2674 54.5047 13.9913 54.4999C13.7152 54.4951 13.4953 54.2674 13.5001 53.9913C13.6185 47.2083 19.2083 41.6185 25.9913 41.5001C26.2674 41.4953 26.4951 41.7152 26.4999 41.9913Z"
      fill="#0E0E0E"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M50.0087 54.4999C49.7326 54.5047 49.5049 54.2848 49.5001 54.0087C49.3911 47.7643 44.2357 42.6089 37.9913 42.4999C37.7152 42.4951 37.4953 42.2674 37.5001 41.9913C37.5049 41.7152 37.7326 41.4953 38.0087 41.5001C44.7917 41.6185 50.3815 47.2083 50.4999 53.9913C50.5047 54.2674 50.2848 54.4951 50.0087 54.4999Z"
      fill="#0E0E0E"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M28.4999 43.9913C28.5047 44.2674 28.2848 44.4951 28.0087 44.4999C21.7643 44.6089 16.6089 49.7643 16.4999 56.0087C16.4951 56.2848 16.2674 56.5047 15.9913 56.4999C15.7152 56.4951 15.4953 56.2674 15.5001 55.9913C15.6185 49.2083 21.2083 43.6185 27.9913 43.5001C28.2674 43.4953 28.4951 43.7152 28.4999 43.9913Z"
      fill="#0E0E0E"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M48.0087 56.4999C47.7326 56.5047 47.5049 56.2848 47.5001 56.0087C47.3911 49.7643 42.2357 44.6089 35.9913 44.4999C35.7152 44.4951 35.4953 44.2674 35.5001 43.9913C35.5049 43.7152 35.7326 43.4953 36.0087 43.5001C42.7917 43.6185 48.3815 49.2083 48.4999 55.9913C48.5047 56.2674 48.2848 56.4951 48.0087 56.4999Z"
      fill="#0E0E0E"
    />
    <circle cx={24} cy={26} r={6} fill="white" />
    <circle cx={40} cy={26} r={6} fill="white" />
    <circle cx={24} cy={26} r={4} fill="#FF92E0" />
    <circle cx={40} cy={26} r={4} fill="#FF92E0" />
    <circle cx={24} cy={26} r={2} fill="#0E0E0E" />
    <ellipse cx={32} cy={36} rx={4} ry={2} fill="#0E0E0E" />
    <circle cx={40} cy={26} r={2} fill="#0E0E0E" />
    <circle cx={25} cy={25} r={1} fill="white" />
    <circle cx={41} cy={25} r={1} fill="white" />
  </svg>
);
export const SeaLionAvatar = ({ size = 'xl', ...props }: StaticIconProps) => {
  return <Icon icon={SeaLion} {...props} size={size} color={undefined} />;
};
