import { useEcommApp } from '@jane/shared/providers';

import { isGTIStore } from '../components/registration/form';
import { isNoStore } from '../customer/redux/store';
import { useCustomerSelector } from '../customer/selectors';
import { get } from '../redux-util/selectors';

const useDisplayGTITerms = () => {
  const { store } = useCustomerSelector(get('store'));
  const { appMode } = useEcommApp();

  const displayGTITerms =
    appMode === 'whiteLabel' && !isNoStore(store) && isGTIStore(store?.id);

  return displayGTITerms;
};

export default useDisplayGTITerms;
