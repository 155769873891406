import type { ReactElement } from 'react';
import { cloneElement } from 'react';

import {
  Card,
  ConditionalWrapper,
  Typography,
  useTabletMediaQuery,
} from '@jane/shared/reefer';
import type { ColorKey, MarginProperties } from '@jane/shared/reefer';

import {
  StyledCard,
  StyledContentContainer,
  StyledLink,
} from './categoryCard.styles';

export interface CategoryCardProps extends MarginProperties {
  /** Background color of the card */
  background: ColorKey;

  /** Test id */
  'data-testid'?: string;

  /** Icon to represent the category, must be a Reefer icon component */
  icon?: ReactElement;

  /** Name of the category */
  name: string;

  /** `onClick` function the card should trigger */
  onClick?: () => void;

  /** Internal route to be linked to */
  to?: string;

  /** Width of the card */
  width?: string | number;
}

export const CATEGORY_CARD_MAX_WIDTH = 310;
export const CATEGORY_CARD_WIDTH_MOBILE = 136;
export const CATEGORY_CARD_HEIGHT = 212;
export const CATEGORY_CARD_HEIGHT_MOBILE = 72;

/**
 * A card component used for representing individual categories in carousels or similar.
 */
export function CategoryCard({
  background,
  'data-testid': testId,
  icon,
  name,
  onClick,
  to,
  width = CATEGORY_CARD_MAX_WIDTH,
  ...props
}: CategoryCardProps) {
  const isTablet = useTabletMediaQuery({ width: 'min' });

  return (
    <StyledCard data-testid={testId} flat width={width} {...props}>
      <ConditionalWrapper
        condition={!!(to || onClick)}
        wrapper={(children) => (
          <StyledLink
            data-testid={`${testId}-link`}
            onClick={onClick}
            to={to}
            variant="minimal"
          >
            {children}
          </StyledLink>
        )}
      >
        <Card.Content background={background}>
          <StyledContentContainer
            alignItems="center"
            height="100%"
            justifyContent="center"
            width="100%"
          >
            {!isTablet && icon && cloneElement(icon, { size: 'md' })}
            {isTablet && icon && cloneElement(icon, { size: 'lg' })}
            <Typography
              branded
              ml={isTablet ? 0 : 8}
              mt={isTablet ? 8 : 0}
              textAlign="center"
              variant={isTablet ? 'header-bold' : 'body-bold'}
              color={name === 'All products' ? 'grays-white' : 'grays-black'}
            >
              {name}
            </Typography>
          </StyledContentContainer>
        </Card.Content>
      </ConditionalWrapper>
    </StyledCard>
  );
}
