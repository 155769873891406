/**
 * DO NOT MODIFY THIS FILE!
 * It was created by the reefer-icons generator.
 * Please update & re-run the generator if need be.
 */
import type { SVGProps } from 'react';

import type { DynamicIconProps } from '../../../icon/icon.types';
import { Icon } from '../../icon';

const Add16 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="100%"
    height="100%"
    fill="none"
    viewBox="0 0 16 16"
    {...props}
  >
    <g className="Add 16">
      <path
        fill="#0E0E0E"
        d="M9 3a1 1 0 0 0-2 0v4H3a1 1 0 1 0 0 2h4v4a1 1 0 1 0 2 0V9h4a1 1 0 1 0 0-2H9V3Z"
        className="SVG"
      />
    </g>
  </svg>
);

const Add24 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="100%"
    height="100%"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <g className="Add 24">
      <g className="SVG">
        <path fill="#0E0E0E" d="M22 11a1 1 0 1 1 0 2H2a1 1 0 1 1 0-2h20Z" />
        <path fill="#0E0E0E" d="M13 22a1 1 0 1 1-2 0V2a1 1 0 1 1 2 0v20Z" />
      </g>
    </g>
  </svg>
);

const SIZE_MAP = {
  sm: Add16,
  md: Add24,
  lg: Add24,
  xl: Add24,
  xxl: Add24,
  xxxl: Add24,
};

export const AddIcon = ({
  size = 'md',
  'data-testid': testId,
  ...props
}: DynamicIconProps) => {
  return (
    <Icon
      data-testid={testId || 'add-icon'}
      icon={SIZE_MAP[size]}
      {...props}
      size={size}
    />
  );
};
