/**
 * DO NOT MODIFY THIS FILE!
 * It was created by the reefer-icons generator.
 * Please update & re-run the generator if need be.
 */
import type { SVGProps } from 'react';

import type { DynamicIconProps } from '../../../icon/icon.types';
import { Icon } from '../../icon';

const Wheelchair24 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="100%"
    height="100%"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <g className="Wheelchair 24">
      <path
        fill="#0E0E0E"
        d="M21.341 10.882a1.428 1.428 0 0 0-1.1-.429l-4.972.277 2.736-3.116a1.67 1.67 0 0 0 .35-1.564 1.42 1.42 0 0 0-.635-.903c-.01-.007-6.54-3.8-6.54-3.8a1.426 1.426 0 0 0-1.666.168l-3.19 2.846a1.426 1.426 0 1 0 1.9 2.128l2.417-2.157 2 1.16-3.53 4.02a7.271 7.271 0 0 0-3.813 1.884l1.843 1.843a4.67 4.67 0 0 1 3.151-1.22 4.694 4.694 0 0 1 4.688 4.689 4.67 4.67 0 0 1-1.22 3.15l1.844 1.844a7.264 7.264 0 0 0 1.98-4.994c0-1.152-.267-2.24-.742-3.21l1.924-.106-.468 5.742a1.426 1.426 0 0 0 2.842.232l.601-7.373a1.426 1.426 0 0 0-.4-1.111ZM18.7 4.774A2.387 2.387 0 1 0 18.7 0a2.387 2.387 0 0 0 0 4.774Zm-8.408 16.622a4.694 4.694 0 0 1-4.688-4.688c0-.974.299-1.88.81-2.63L4.55 12.214a7.292 7.292 0 0 0 10.234 10.234l-1.863-1.862c-.75.51-1.656.81-2.63.81Z"
        className="SVG"
      />
    </g>
  </svg>
);

const SIZE_MAP = {
  md: Wheelchair24,
  sm: Wheelchair24,
  lg: Wheelchair24,
  xl: Wheelchair24,
  xxl: Wheelchair24,
  xxxl: Wheelchair24,
};

export const WheelchairIcon = ({
  size = 'md',
  'data-testid': testId,
  ...props
}: DynamicIconProps) => {
  return (
    <Icon
      data-testid={testId || 'wheelchair-icon'}
      icon={SIZE_MAP[size]}
      {...props}
      size={size}
    />
  );
};
