/**
 * DO NOT MODIFY THIS FILE!
 * It was created by the reefer-icons generator.
 * Please update & re-run the generator if need be.
 */
import type { SVGProps } from 'react';

import type { StaticIconProps } from '../../../icon/icon.types';
import { Icon } from '../../icon';

const Cross24 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="100%"
    height="100%"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <g className="Cross 24">
      <path
        fill="#94B200"
        d="M16 1a1 1 0 0 0-1-1H9a1 1 0 0 0-1 1v6a1 1 0 0 1-1 1H1a1 1 0 0 0-1 1v6a1 1 0 0 0 1 1h6a1 1 0 0 1 1 1v6a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1v-6a1 1 0 0 1 1-1h6a1 1 0 0 0 1-1V9a1 1 0 0 0-1-1h-6a1 1 0 0 1-1-1V1Z"
        className="Fill-Main"
      />
    </g>
  </svg>
);

const SIZE_MAP = {
  md: Cross24,
  sm: Cross24,
  lg: Cross24,
  xl: Cross24,
  xxl: Cross24,
  xxxl: Cross24,
};

export const CrossIcon = ({
  size = 'md',
  'data-testid': testId,
  ...props
}: StaticIconProps) => {
  return (
    <Icon
      data-testid={testId || 'cross-icon'}
      icon={SIZE_MAP[size]}
      {...props}
      size={size}
      color={undefined}
    />
  );
};
