import { Helmet } from 'react-helmet-async';

import { config } from '@jane/shared/config';

interface NestedObj {
  [key: string]: string | NestedObj;
}

export interface PageTitleProps {
  cityState?: string;
  description?: string;
  janeGold?: boolean;
  // keep google from following and indexing the links on this page
  noFollow?: boolean;
  // keep google from indexing this page
  noIndex?: boolean;
  title?: string;
  url?: string;
}

// https://developers.google.com/search/docs/advanced/structured-data/intro-structured-data
const buildStructuredData = (): NestedObj => ({
  '@context': 'https://schema.org/',
  '@type': 'WebSite',
  url: config.deployPublicPath,
  name: 'Jane Technologies',
  potentialAction: {
    '@type': 'SearchAction',
    target: {
      '@type': 'EntryPoint',
      urlTemplate: `${config.deployPublicPath}products?searchText={search_term}`,
    },
    'query-input': 'required name=search_term',
  },
});

export const PageTitle = ({
  cityState,
  title,
  janeGold,
  noIndex = false,
  noFollow = false,
  description,
  url,
}: PageTitleProps) => {
  const pageTitle = `${
    title ||
    `Shop cannabis dispensaries and brand deals ${
      cityState ? `in ${cityState}` : 'near me'
    }`
  } | ${janeGold ? 'Jane Gold' : 'Jane'}`;
  const pageDescription =
    description ||
    `Find reviews and explore the best cannabis dispensaries ${
      cityState ? `in ${cityState}` : 'near me'
    } with Jane. Browse dispensary menus and order for delivery or pick up.`;

  return (
    <Helmet>
      <title>{pageTitle}</title>
      <meta property="og:title" content={pageTitle} />
      <meta property="twitter:title" content={pageTitle} />
      {noIndex && <meta name="robots" content="noindex" />}
      {noFollow && <meta name="robots" content="nofollow" />}

      <meta name="description" content={pageDescription} />
      <meta property="og:description" content={pageDescription} />

      {url && <link rel="canonical" href={url} />}

      <script type="application/ld+json">
        {JSON.stringify(buildStructuredData())}
      </script>
    </Helmet>
  );
};
