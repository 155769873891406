import { Feature, config, isFeatureEnabled } from '@jane/shared/config';

export const useIsRoundingAmountDisplayed = (
  roundingAmount: string | number | null,
  storeId?: string | number
) => {
  const roundingDisplayEnabled =
    isFeatureEnabled(Feature.RoundingDisplay) &&
    typeof storeId !== 'undefined' &&
    config.roundingBetaStoreIds.split(',').includes(storeId.toString());

  const HALF_PENNY = 0.005;

  const atLeastPennyRounded = Math.abs(Number(roundingAmount)) >= HALF_PENNY;

  return roundingDisplayEnabled && atLeastPennyRounded;
};
