import styled from '@emotion/styled';

import { Box, Button } from '@jane/shared/reefer';
import { mediaQueries } from '@jane/shared/reefer-emotion';

import sparklyBackground from '../../janeGoldCard/background.svg';

const MOBILE_PADDING = 24;
const DESKTOP_PADDING = 40;

export const BoxStyled = styled(Box)({
  width: `calc(100% + ${2 * MOBILE_PADDING}px)`,
  marginTop: `calc(${MOBILE_PADDING}px * -1)`,
  [mediaQueries.tablet('min')]: {
    width: `calc(100% + ${2 * DESKTOP_PADDING}px)`,
    marginTop: `calc(${DESKTOP_PADDING}px * -1)`,
  },
});

export const Background = styled.div<{
  hideBranding: boolean;
  sparkly: boolean;
}>(({ hideBranding, sparkly, theme }) => ({
  ...(sparkly && {
    background: `url(${sparklyBackground})`,
    backgroundSize: 'cover',
  }),
  backgroundColor: sparkly
    ? 'none'
    : hideBranding
    ? theme.colors.primary.light
    : theme.colors.brand.purple.main,
  width: '100%',
  height: '100%',
}));

export const ThemedButton = styled(Button)<{ janePay: boolean }>(
  ({ janePay, theme }) => ({
    ...(janePay && {
      backgroundColor: theme.colors.brand.purple.main,
      borderColor: theme.colors.brand.purple.main,
    }),
  })
);
