import { TrackJS } from 'trackjs';

import { config } from '@jane/shared/config';

const redactCreditCardNumber = (value: string) => {
  if (typeof value === 'string') {
    return value.replace(
      /\b\d{16}\b/gi,
      'REDACTED_POTENTIAL_CREDIT_CARD_NUMBER'
    );
  }

  return value;
};

export const initTrackJS = (applicationName?: string) => {
  TrackJS.install({
    token: config.trackJsToken,
    application: applicationName || config.trackJsApplication,
    forwardingDomain: config.trackJsDomain,
    version: config.appVersion,
    onError: (payload) => {
      if (!payload.stack || !/iheartjane/.test(payload.stack)) {
        return false;
      }
      payload.message = redactCreditCardNumber(payload.message);
      payload.stack = redactCreditCardNumber(payload.stack);
      return true;
    },
  });
};

export function identify({ userId }: { userId: string }) {
  TrackJS.configure({ userId });
}

const withErrorMeta = (meta: any | undefined = {}, fn: () => void) => {
  Object.entries(meta).forEach(([key, value]) => {
    const metaVal = JSON.stringify(value, null, 2);
    TrackJS.addMetadata(key, metaVal);
  });

  fn();

  Object.entries(meta).forEach(([key, _value]) => {
    TrackJS.removeMetadata(key);
  });
};

export const trackError = (
  error: Error | any | string,
  meta: any | undefined = undefined
) => {
  if (TrackJS.isInstalled()) {
    withErrorMeta(meta, () => {
      TrackJS.track(error);
    });
  } else {
    let stack;
    if (typeof error !== 'string' && 'stack' in error) {
      stack = error.stack;
    }

    if (!stack) {
      try {
        throw new Error();
      } catch (err: any) {
        stack = err.stack;
      }
    }
    // eslint-disable-next-line no-console
    console.error('Tracking:', error, JSON.stringify(meta, null, 2));
    // eslint-disable-next-line no-console
    console.error('Stack:', stack);
  }
};
export const debugLog = (log: string) => {
  if (TrackJS.isInstalled()) {
    TrackJS.console.debug(log);
  } else {
    console.debug('TrackJS debug log: \n', log);
  }
};
