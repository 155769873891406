import type { Reducer } from 'redux';

import type { DeepReadonly } from '@jane/shared/models';

import { createSimpleAction, createStandardAction } from '../../redux-util';
import type { CommonThunkAction } from '../redux';
import type { CommonActions } from './types';

export type Params = Record<string, string | number | null>;
export const fetchItems =
  ({
    params,
    source,
    batchSize = 50,
    successAction,
  }: {
    batchSize?: number;
    params: Params;
    source: { get(params: Params): Promise<any> };
    successAction?: (result: any) => any;
  }): CommonThunkAction<Promise<any>> =>
  (dispatch) => {
    dispatch(startPagination());

    return source.get({ count: batchSize, ...params }).then((result: any) => {
      const collection = result[Object.keys(result)[0]];

      if (successAction) {
        dispatch(successAction(result));
      }
      dispatch(paginationComplete({ collection, pageSize: batchSize }));

      return result;
    });
  };

export const PAGINATION_START = 'common/pagination/pagination';
export const startPagination = createSimpleAction(PAGINATION_START);

export const PAGINATION_COMPLETE = 'common/pagination/complete';
export const paginationComplete = createStandardAction(PAGINATION_COMPLETE)<{
  collection: readonly unknown[];
  pageSize: number;
}>();

export type PaginationActions =
  | ReturnType<typeof startPagination>
  | ReturnType<typeof paginationComplete>;

export type PaginationState = DeepReadonly<{
  endReached: boolean;
  isLoading: boolean;
}>;

const getInitialState = (): PaginationState => ({
  isLoading: false,
  endReached: false,
});

export const paginationReducer: Reducer<PaginationState, CommonActions> = (
  state = getInitialState(),
  action
) => {
  switch (action.type) {
    case PAGINATION_START:
      return { ...state, isLoading: true };
    case PAGINATION_COMPLETE: {
      const { collection, pageSize } = action.payload;
      return {
        ...state,
        isLoading: false,
        endReached: !collection.length || collection.length < pageSize,
      };
    }
  }

  return state;
};
