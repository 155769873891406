import styled from '@emotion/styled';

import { spacing } from '@jane/shared/reefer-emotion';

export const LimitItems = styled.ul(spacing({ m: 0, p: 0 }));

export const LimitItem = styled.li(spacing({ mb: 8 }), {
  listStyle: 'none',
  '&:last-child': spacing({ mb: 0 }),
});
