import { useEffect } from 'react';

import { EventNames, track } from '@jane/shared-ecomm/tracking';
import type { BundlePossibilities } from '@jane/shared/models';
import { Typography } from '@jane/shared/reefer';
import type { Store, StoreSpecial } from '@jane/shared/types';

import { StyledLink } from './specialBundleEnticement.styles';

export interface SpecialBundleEnticementProps {
  bundlePossibilities: BundlePossibilities;
  onClick?: (specialId: StoreSpecial['id']) => void;
  special: StoreSpecial;
  storeId: Store['id'];
  to: string;
}

export const SpecialBundleEnticement = ({
  bundlePossibilities,
  onClick,
  special,
  storeId,
  to,
}: SpecialBundleEnticementProps) => {
  useEffect(() => {
    const event =
      bundlePossibilities.status === 'qualified'
        ? EventNames.ViewedClickableBundleEnticement
        : EventNames.ViewedBundleEnticement;

    track({
      event,
      specialId: special.id,
      storeId,
    });
  }, []);

  if (bundlePossibilities.status === 'partially_qualified') {
    return (
      <Typography variant="body-bold" color="inherit">
        You're on your way to unlocking "{special.title}"
        <StyledLink to={to} ml={4}>
          <Typography as="span">View special</Typography>
        </StyledLink>
      </Typography>
    );
  }

  if (bundlePossibilities.status === 'qualified') {
    return (
      <Typography variant="body-bold" color="inherit">
        "{special.title}" has been unlocked.
        <StyledLink
          to={to}
          onClick={() => onClick && onClick(special.id)}
          ml={4}
        >
          <Typography as="span">Add discount</Typography>
        </StyledLink>
      </Typography>
    );
  }

  if (bundlePossibilities.status === 'applied') {
    return (
      <Typography variant="body-bold" color="inherit">
        "{special.title}" has been applied to your bag.
      </Typography>
    );
  }

  return null;
};
