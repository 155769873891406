import type { SVGProps } from 'react';

import { Icon } from '../../icon';
import type { LogoProps } from '../logo.types';

const Chase = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 96 96"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M41.17 24C40.2337 24 39.4753 24.7586 39.4753 25.6941V37.5757H70.8524L56.5634 24.0011L41.17 24Z"
      fill="#117ACA"
    />
    <path
      d="M72 41.1698C72 40.2321 71.247 39.4767 70.3053 39.4767H58.4302V70.8635L71.9945 56.564L72 41.1698Z"
      fill="#117ACA"
    />
    <path
      d="M54.8324 72.0001C55.7665 72.0001 56.5249 71.2404 56.5249 70.3027V58.4255H25.1468L39.438 71.9979L54.8324 72.0001Z"
      fill="#117ACA"
    />
    <path
      d="M23.9998 54.8321C23.9998 55.7687 24.7582 56.5317 25.6967 56.5317H37.5696V25.1428L24.002 39.4379L23.9998 54.8321Z"
      fill="#117ACA"
    />
  </svg>
);
export const ChaseLogo = ({
  height = 48,
  width = 'auto',
  ...props
}: LogoProps) => {
  return (
    <Icon
      height={height}
      width={width}
      {...props}
      icon={Chase}
      color={undefined}
      rotate={undefined}
      size={undefined}
    />
  );
};
