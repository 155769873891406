import type { AlgoliaProduct, JaneSearchState } from '@jane/search/types';
import { buildOptionalFilters } from '@jane/search/util';
import type { AppMode, BrandPartner, Store } from '@jane/shared/models';

import { useGetRecommendedSortVersion } from '../useGetRecommendedSortVersion';
import { useInlineAd } from '../useInlineAd/useInlineAd';
import { addStaticFiltersToSearchState } from './addStaticFiltersToSearchState';

interface UseProductIdsAndAdsQueryProps {
  appMode: AppMode;
  brandPartner?: BrandPartner;
  enabled: boolean;
  janeDeviceId: string;
  keywords: string[];
  numColumns?: number;
  searchState: JaneSearchState<AlgoliaProduct>;
  staticFilters?: string[];
  store: Store;
}

type UseProductIdsAndAdsQueryResponse = ReturnType<typeof useInlineAd>;

export const useProductIdsAndAdsQuery = ({
  appMode,
  brandPartner,
  enabled,
  janeDeviceId,
  keywords,
  numColumns,
  searchState,
  staticFilters,
  store,
}: UseProductIdsAndAdsQueryProps): UseProductIdsAndAdsQueryResponse => {
  const smartSortVersion = useGetRecommendedSortVersion();

  const updatedSearchState = addStaticFiltersToSearchState({
    brandPartner,
    staticFilters,
    searchState,
  });

  const optionalFilters = buildOptionalFilters({ brandPartner, store });
  const query = useInlineAd({
    appMode,
    enabled,
    isRecommendedSortEnabled: true,
    janeDeviceId,
    keywords,
    optionalFilters,
    numColumns,
    placement: 'menu_inline_table',
    searchState: updatedSearchState,
    smartSortVersion,
    storeId: store.id,
  });

  return query;
};
