import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';

import type {
  LocalizedProductContent,
  MenuProduct,
  Product,
  _DeepReadonlyArray,
} from '@jane/shared/models';

interface LocalizeProductProps {
  countryCode?: string;
  product?: Product;
  userState?: string;
}

export function localizeProduct({
  product,
  countryCode,
  userState,
}: LocalizeProductProps) {
  if (product && !isEmpty(product.localized_content)) {
    const stateSpecificContent = product.localized_content.find(
      (lc) => lc.subdivision === userState && lc.country === countryCode
    );

    const localizedProperties = stateSpecificContent
      ? {
          name: isNil(stateSpecificContent.name)
            ? product.name
            : stateSpecificContent.name,
          description: isNil(stateSpecificContent.description)
            ? product.description
            : stateSpecificContent.description,
          brand_subtype: isNil(stateSpecificContent.brand_subtype)
            ? product.brand_subtype
            : stateSpecificContent.brand_subtype,
          photos: isEmpty(stateSpecificContent.image_urls)
            ? product.photos
            : stateSpecificContent.photos,
          image_urls: isEmpty(stateSpecificContent.image_urls)
            ? product.image_urls
            : stateSpecificContent.image_urls,
          category: isNil(stateSpecificContent.lineage)
            ? product.category
            : stateSpecificContent.lineage,
        }
      : {};

    return {
      ...product,
      ...localizedProperties,
    };
  }

  return product;
}

export const getLocalizedMenuProductContent = (
  product: Partial<MenuProduct>,
  localizedProductsContent: _DeepReadonlyArray<LocalizedProductContent>
): any => {
  const localizedProductContent = localizedProductsContent?.find(
    (lpc) => lpc.product_id === product.product_id
  );

  if (localizedProductContent) {
    // override default value with localized value (state-specified) if field is populated
    const localizedProperties = {
      name: isNil(localizedProductContent.name)
        ? product.name
        : localizedProductContent.name,
      description: isNil(localizedProductContent.description)
        ? product.description
        : localizedProductContent.description,
      brand_subtype: isNil(localizedProductContent.brand_subtype)
        ? product.brand_subtype
        : localizedProductContent.brand_subtype,
      image_urls: isEmpty(localizedProductContent.image_urls)
        ? product.image_urls
        : localizedProductContent.image_urls,
      category: isNil(localizedProductContent.category)
        ? product.category
        : localizedProductContent.category,
    };

    return {
      ...product,
      ...localizedProperties,
    };
  }

  return product;
};
