import styled from '@emotion/styled';

import { Button, Image, Typography } from '@jane/shared/reefer';

export const StyledImage = styled(Image)<{ opacity: number }>(
  ({ opacity }) => ({
    opacity: opacity,
    '&:after': {
      content: '""',
      position: 'absolute',
      top: 0,
      left: 0,
      height: '100%',
      width: '100%',
      background:
        'linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%)',
    },
  })
);

export const StyledTypography = styled(Typography)<{
  textColor: string;
}>(({ textColor }) => ({
  color: textColor,
}));

export const StyledButton = styled(Button)<{
  backgroundColor: string;
  color: string;
}>(({ backgroundColor, color }) => ({
  background: backgroundColor,
  borderColor: backgroundColor,
  color: color,
}));
