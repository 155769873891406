/**
 * DO NOT MODIFY THIS FILE!
 * It was created by the reefer-icons generator.
 * Please update & re-run the generator if need be.
 */
import type { SVGProps } from 'react';

import type { DynamicIconProps } from '../../../icon/icon.types';
import { Icon } from '../../icon';

const Preroll24 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="100%"
    height="100%"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <g className="Preroll 24">
      <g className="SVG">
        <path
          fill="#000"
          fillOpacity={0.5}
          fillRule="evenodd"
          d="M22.875.516a1 1 0 0 0-1.36-.391c-.833.461-1.498 1.213-1.515 2.22-.016.968.576 1.803 1.38 2.439.565.448.621.754.62.836 0 .042-.02.248-.484.505a1 1 0 1 0 .968 1.75c.834-.461 1.498-1.213 1.516-2.22.016-.969-.576-1.803-1.38-2.44-.565-.447-.621-.753-.62-.835 0-.042.02-.248.484-.505a1 1 0 0 0 .39-1.36Z"
          className="Details Mono-Details"
          clipRule="evenodd"
        />
        <path
          fill="#F50"
          fillRule="evenodd"
          d="M18.39 6.424c.664.756 1.423 1.84 2.136 3.076.713 1.235 1.273 2.434 1.596 3.388.158.469.277.928.31 1.325a2.06 2.06 0 0 1-.045.676c-.062.243-.218.59-.593.807a1.197 1.197 0 0 1-.17.081L5.142 22.998c-.478.245-.961.14-1.262.02a3.155 3.155 0 0 1-.89-.58c-.528-.468-1.063-1.153-1.516-1.938-.453-.785-.78-1.591-.921-2.283-.07-.34-.107-.71-.056-1.06.046-.32.197-.791.647-1.084L15.64 5.41a1.2 1.2 0 0 1 .155-.106c.375-.217.754-.177.995-.11.24.067.446.187.608.3.328.226.666.559.993.93Z"
          className="Main"
          clipRule="evenodd"
        />
        <path
          fill="#000"
          fillOpacity={0.5}
          d="M13.83 9.902a1 1 0 1 1-1.732 1 1 1 0 0 1 1.732-1ZM17.928 11a1 1 0 1 1-1.732 1 1 1 0 0 1 1.732-1Zm-1.464 5.464a1 1 0 1 0-1-1.732 1 1 0 0 0 1 1.732ZM12.732 14A1 1 0 1 1 11 15a1 1 0 0 1 1.732-1Zm-7.294 5.366a1 1 0 1 0-1-1.732 1 1 0 0 0 1 1.732Zm2.83-5.098a1 1 0 1 1-1.732 1 1 1 0 0 1 1.732-1Zm1.634 4.83a1 1 0 1 0-1-1.732 1 1 0 0 0 1 1.732Z"
          className="Details"
        />
      </g>
    </g>
  </svg>
);

const SIZE_MAP = {
  md: Preroll24,
  sm: Preroll24,
  lg: Preroll24,
  xl: Preroll24,
  xxl: Preroll24,
  xxxl: Preroll24,
};

export const PrerollIcon = ({
  size = 'md',
  'data-testid': testId,
  ...props
}: DynamicIconProps) => {
  return (
    <Icon
      data-testid={testId || 'preroll-icon'}
      icon={SIZE_MAP[size]}
      {...props}
      size={size}
    />
  );
};
