import clsx from 'clsx';
import type { ReactNode } from 'react';

import type { ReeferBaseProps } from '../../../utils';
import styles from './loadingLabel.module.css';

interface LoadingLabelProps extends ReeferBaseProps {
  children?: ReactNode;
  isLoading?: boolean;
  subLabel?: string;
}

export function LoadingLabel({
  children,
  className,
  'data-testid': testId,
  id,
  isLoading,
  style,
  subLabel,
}: LoadingLabelProps) {
  return (
    <div
      className={clsx(className, styles.loadingLabel, {
        [styles.loadingLabel__loading]: isLoading,
        [styles.loadingLabel_sublabel]: !!subLabel,
      })}
      data-testid={testId}
      id={id}
      style={style}
    >
      {children}
    </div>
  );
}
