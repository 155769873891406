import type { IconSize, Spacing } from '@jane/shared/reefer';
import { Flex, HalfStarIcon, StarFilledIcon } from '@jane/shared/reefer';

interface Props {
  margin?: Spacing;
  rating: number;
  size?: IconSize;
}

const FIVE_STARS = [1, 2, 3, 4, 5];

// shows all available stars
export const FiveStarRating = ({
  rating,
  size = 'sm',
  margin,
  ...props
}: Props) => {
  return (
    <Flex alignItems="center" inline {...props}>
      {FIVE_STARS.map((starRating) => (
        <Flex key={starRating} mr={margin || 4}>
          {starRating > rating && starRating < rating + 1 ? (
            <HalfStarIcon size={size} />
          ) : (
            <StarFilledIcon
              color={starRating < rating + 1 ? undefined : 'grays-ultralight'}
              size={size}
            />
          )}
        </Flex>
      ))}
    </Flex>
  );
};

// only shows rating stars
export const MenuStarRating = ({
  rating,
  size = 'sm',
  margin,
  ...props
}: Props) => {
  return (
    <Flex alignItems="center" inline {...props}>
      {FIVE_STARS.map((starRating) => {
        const halfStar = starRating > rating && starRating < rating + 1;
        const fullStar = starRating < rating + 1;

        return halfStar ? (
          <Flex key={starRating} mr={margin || 4}>
            <HalfStarIcon size={size} />
          </Flex>
        ) : fullStar ? (
          <Flex key={starRating} mr={margin || 4}>
            <StarFilledIcon size={size} />
          </Flex>
        ) : undefined;
      })}
    </Flex>
  );
};
