/**
 * DO NOT MODIFY THIS FILE!
 * It was created by the reefer-icons generator.
 * Please update & re-run the generator if need be.
 */
import type { SVGProps } from 'react';

import type { DynamicIconProps } from '../../../icon/icon.types';
import { Icon } from '../../icon';

const LocationPinFilled16 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="100%"
    height="100%"
    fill="none"
    viewBox="0 0 16 16"
    {...props}
  >
    <g className="Location pin filled 16">
      <path
        fill="#0E0E0E"
        fillRule="evenodd"
        d="M8 15s6-2.91 6-8.182C14 3.605 11.313 1 8 1S2 3.605 2 6.818C2 12.091 8 15 8 15Zm0-6a2 2 0 1 0 0-4 2 2 0 0 0 0 4Z"
        className="SVG"
        clipRule="evenodd"
      />
    </g>
  </svg>
);

const SIZE_MAP = {
  sm: LocationPinFilled16,
  md: LocationPinFilled16,
  lg: LocationPinFilled16,
  xl: LocationPinFilled16,
  xxl: LocationPinFilled16,
  xxxl: LocationPinFilled16,
};

export const LocationPinFilledIcon = ({
  size = 'md',
  'data-testid': testId,
  ...props
}: DynamicIconProps) => {
  return (
    <Icon
      data-testid={testId || 'location-pin-filled-icon'}
      icon={SIZE_MAP[size]}
      {...props}
      size={size}
    />
  );
};
