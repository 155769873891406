import { useEffect, useRef } from 'react';

import { inIframe, postMessageToIframeParent } from '@jane/shared/util';

export const useHandleIframeModal = (
  isOpen: boolean,
  scrollPosition?: boolean
) => {
  const hasOpened = useRef(false);
  const isInIframe = inIframe();

  useEffect(() => {
    if (isInIframe) {
      if (isOpen) {
        hasOpened.current = true;
        if (scrollPosition) {
          postMessageToIframeParent({
            messageType: 'saveScrollPosition',
          });
        }

        postMessageToIframeParent({
          messageType: 'enableShrinkEveryResize',
        });

        postMessageToIframeParent({
          messageType: 'openModal',
        });

        postMessageToIframeParent({
          messageType: 'scrollToTop',
        });
      }

      if (!isOpen && hasOpened?.current === true) {
        postMessageToIframeParent({
          messageType: 'disableShrinkEveryResize',
        });

        postMessageToIframeParent({
          messageType: 'closeModal',
        });

        if (scrollPosition) {
          postMessageToIframeParent({
            messageType: 'restoreScrollPosition',
            payload: 'smooth',
          });
        }
      }
    }
  }, [isOpen, isInIframe, scrollPosition]);
};

export const useIframeScroll = (isOpen: boolean) => {
  useEffect(() => {
    if (isOpen) {
      postMessageToIframeParent({
        messageType: 'scrollToTop',
      });
    }
  }, [isOpen]);
};
