import type { CSSObject } from '@emotion/react';

export const hidden = (isHidden = true): CSSObject =>
  isHidden
    ? {
        '&:not(:focus):not(:active)': {
          clip: 'rect(0 0 0 0)',
          clipPath: 'inset(50%)',
          height: '1px',
          opacity: '0',
          overflow: 'hidden',
          position: 'absolute',
          whiteSpace: 'nowrap',
          width: '1px',
        },
      }
    : {};
