export const FLAGS = {
  /** Golden Gurls Flags */
  almostGone: 'ggAlmostGone',
  janeGoldServiceFee: 'fepJaneGoldServiceFee',
  janeGoldUserSegmentation: 'fepGoldUserSegmentation',
  kioskMyHighMenu: 'ggKioskMyHighMenu',
  ldLogging: 'fepLdLogging',
  myHighMenu: 'fepMyHighMenu',
  reeferMigration: 'fepReeferMigration',
  smartSortUpdates: 'dmGgSmartSortUpdates',

  /** Bargain Buds Flags */
  bbEditSpecialsStackingSettings: 'bbEditSpecialsStackingSettings',
  bbUseMmpEndpointForPdp: 'bbUseMmpEndpointForPdp',
  nueraWarning: 'bbNueraWarning',

  /** Pineapple Express Flags */
  kioskAnonymousCheckoutForMedStores: 'kioskAnonymousCheckoutForMedStores',
  kioskOrderConfirmationRating: 'kioskOrderConfirmationRatingEnabled',

  /** Sour Chiesel Flags */
  bloomPlusBetaNav: 'bloomPlusBetaNavOption',
  scSmsIntegration: 'sourChieselStoreSpecificSmsIntegrationUi',
  scHasImageCommsBannerAccess: 'sourChieselImageCommsBannerAccess',
  scHideSpotNumber: 'scHideCurbsideSpotNumber',
  scNotificationSettingsCardAccess: 'scNotificationSettingsCardAccess',
  scSpecialsBetaAccess: 'sourChieselSpecialsBetaAccess',
  scGlobalSpecialsBeta: 'sourChieselGlobalSpecialsBeta',
  scJanePayReservationModeSettings: 'scJanePayReservationModeSettings',
  scBypassPointDeductionFrontend: 'scBypassPointDeductionFrontend',
  scQuickActionBeta: 'scQuickActionBeta',
  scShowMenuRowIdInModal: 'scShowMenuRowIdInModal',
  // Usually SC flags are in the Manager project, but this one is in Consumer since it's used in Legacy Biz Admin
  consumerBoostUseNewTable: 'boostUseNewTable',
  consumerUseGlobalSpecials: 'scUseGlobalSpecials',
  consumerScJanePayByReservationMode: 'scJanePayByReservationMode',
  consumerQrCurbsideTable: 'scQrCurbsideUseTable',

  /** DM Flags */
  dmRecommendedSortVersion: 'dmRecommendedSortVersion',
  dmSharedHeadersEnabled: 'dmSharedHeadersEnabled',
  dmSmartApiEnabled: 'dmGlobalSmartApiUsage',
  dmSponsoredDesktopPositions: 'dmSmartSortDensityDesktop',
  dmSponsoredMobilePositions: 'dmSmartSortDensityMobile',
  dmUserSessionImpressionEventsEnabled: 'dmUserSessionImpressionEventsEnabled',

  /** CRM Flag */
  bbCrmTracking: 'bbCrmTracking',
} as const;
