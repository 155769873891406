import * as t from 'io-ts';

export const tFreeDeliveryConfig = t.interface({
  id: t.number,
  store_id: t.number,
  cart_minimum: t.union([t.null, t.number]),
});

export type FreeDeliveryConfig = t.TypeOf<typeof tFreeDeliveryConfig>;

export const tFreeDeliveryConfigV2 = t.interface({
  id: t.number,
  cart_minimum: t.union([t.null, t.string]),
});
export type FreeDeliveryConfigV2 = t.TypeOf<typeof tFreeDeliveryConfigV2>;
