import { useState } from 'react';

import { useHandleIframeModal, useIsInIframe } from '@jane/shared-ecomm/hooks';
import {
  Button,
  Modal,
  Typography,
  useMobileMediaQuery,
} from '@jane/shared/reefer';

import { FilterModalFooter } from '../filterModal/filterModalFooter';
import { FilterModalTypeaheadHeader } from '../filterModal/filterModalTypeaheadHeader';
import { TypeaheadList } from '../filterModal/typeaheadList';
import type { FilterBarPopoverProps } from './filterBarPopover.types';
import { FilterList } from './filterList';
import { FilterPopover } from './filterPopover';

export const FilterBarTypeahead = ({
  filterGroup,
  hideCounts,
  onChange,
  onDeselect,
  totalResults,
  variant,
}: FilterBarPopoverProps) => {
  const [open, setOpen] = useState(false);
  const [query, setQuery] = useState('');

  const { label, items, key, nested } = filterGroup;
  const typeaheadItems = items;
  const isMobile = useMobileMediaQuery({});
  const isInIframe = useIsInIframe();

  const changeHandler = (value: string) => {
    setQuery(value);
  };

  const typeaheadValues = {
    label,
    onChange: changeHandler,
    query,
  };

  const filteredTypeaheadItems = typeaheadItems.filter((item) =>
    item.label.toLowerCase().includes(query.toLowerCase())
  );

  const renderedFilterList = filteredTypeaheadItems.map((item) => {
    return (
      item.count > 0 && (
        <FilterList
          key={item.label}
          filterKey={filterGroup.key}
          filters={item}
          hideCounts={hideCounts}
          onChange={onChange}
          query={query}
        />
      )
    );
  });

  // resizes iframe when modal is open
  useHandleIframeModal(open);

  if (isMobile) {
    return (
      <>
        <div>
          <Button
            label={label}
            variant="tertiary"
            onClick={() => setOpen(true)}
          />
        </div>
        <Modal
          open={open}
          background="grays-white"
          onRequestClose={() => setOpen(false)}
          variant={isInIframe ? 'standard' : 'full-screen'}
          contentLabel="filter bar typeahead"
          overlayClose
        >
          <FilterModalTypeaheadHeader
            onClose={() => setOpen(false)}
            onDeselect={() => onDeselect(key)}
            onSearchChange={changeHandler}
            searchQuery={query}
            title={label}
          />
          <Modal.Content padding={false}>
            <TypeaheadList
              filterKey={filterGroup.key}
              hideCounts={hideCounts}
              items={items}
              onChange={onChange}
              query={query}
            />
          </Modal.Content>
          <FilterModalFooter
            hideClear={true}
            onClose={() => setOpen(false)}
            onDeselect={onDeselect}
            totalResults={totalResults}
            variant={variant}
          />
        </Modal>
      </>
    );
  }

  return (
    <FilterPopover
      buttonText={label}
      targetLabel={`Filter products by ${label}`}
      listLabel={`Select ${label}`}
      onDeselect={() => onDeselect(key)}
      disabled={items.length === 0}
      totalResults={totalResults}
      nested={nested}
      typeaheadOptions={typeaheadValues}
      variant={variant}
    >
      {renderedFilterList.length === 0 ? (
        <Typography m={24} mb={0}>
          No results
        </Typography>
      ) : (
        renderedFilterList
      )}
    </FilterPopover>
  );
};
