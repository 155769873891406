import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useRef,
} from 'react';
import type { ReactNode } from 'react';
import type { NavigationType } from 'react-router-dom';
import { useLocation, useNavigationType } from 'react-router-dom';

import { postMessageToIframeParent } from '@jane/shared/util';

/*
The Scroll Restoration provider is used to automatically scroll to a user's previous position on that page.
The provider keeps a running tally of how many times the user has navigated during their session (excluding "REPLACE" navigations).
It tracks saved scroll positions as an object where the keys are pathnames and the values are objects containing the scroll position and the current navigation tally.

Key functionalities:
1. **Saving Scroll Position**:
   - When a scroll position is saved, an entry is added or updated in the scroll positions object.
   - Each entry includes the pathname, the scroll position, and the current navigation tally.

2. **Restoring Scroll Position**:
   - When a scroll position restoration is requested, the provider checks the current navigation number against the saved navigation number for that pathname.
   - If the current navigation number is within two navigations of the saved navigation number, the scroll position is restored.
   - If not, the page scrolls to the top.

3. **Usage with Hooks**:
   - Two hooks (which are combined together in one useRestoreScroll() hook) are used to invoke this functionality. 
   - `useSaveScrollPosition()` calls the saveScrollPosition function on dismount.
   - `useRestoreScrollPosition()` calls the restoreScrollPosition function on mount. It uses a resize observer to wait until the window stops resizing (or after 1.5 seconds).
   - Because we're leveraging mounting and dismounting, the useRestoreScroll() hook should exist within the parent-most page component.

4. **Higher-Order Components (HOCs)**:
   - A HOC (withScrollRestoration) was created to simplify implementation of this feature, and to ensure it only exists once within a page.
   - withScrollRestoration() conditionally wraps components that are imported via the Loader.tsx util
   - When creating a new route for a page, passing `restoreScroll` to the page component will enable this functionality.
   - See storeRoutes.tsx for examples.
*/

interface SavedScroll {
  [path: string]: {
    navigations: number;
    position: number;
  };
}

interface ScrollRestorationProps {
  isIframe: boolean;
  restoreScrollPosition: (path: string, navigationType: NavigationType) => void;
  saveScrollPosition: (path: string, position: number) => void;
}

const ScrollRestorationContext = createContext<ScrollRestorationProps>(
  {} as ScrollRestorationProps
);

export const ScrollRestorationProvider = ({
  children,
  isIframe,
}: {
  children: ReactNode;
  isIframe: boolean;
}) => {
  const scrollPositions = useRef<SavedScroll>({});
  const navigations = useRef(0);
  const location = useLocation();
  const navigationType = useNavigationType();

  useEffect(() => {
    if (navigationType !== 'REPLACE') {
      navigations.current += 1;
    }
  }, [location.pathname]);

  const saveScrollPosition = useCallback((path: string, position: number) => {
    scrollPositions.current = {
      ...scrollPositions.current,
      [path]: {
        position,
        navigations: navigations.current,
      },
    };

    if (isIframe) {
      postMessageToIframeParent({
        messageType: 'saveScrollPosition',
        pathname: path,
      });
    }
  }, []);

  const restoreScrollPosition = useCallback(
    (path: string, navigationType: NavigationType) => {
      const savedScroll = scrollPositions.current[path];
      const shouldScroll = savedScroll?.navigations + 2 >= navigations.current;

      if (isIframe) {
        postMessageToIframeParent({
          messageType: shouldScroll ? 'restoreScrollPosition' : 'scrollToTop',
          pathname: path,
        });
        // When clicking the "back" button, we rely on the browser's native scroll restoration
      } else if (navigationType !== 'POP') {
        // If a scroll position is stale or doesn't exist, we'll just scroll to the top
        const position = shouldScroll ? savedScroll.position : 0;
        window.requestAnimationFrame(() => {
          // If, for some reason, the screen is still shorter than the saved scroll, just scroll to top
          const documentHeight = document.body.scrollHeight;
          window.scrollTo(0, documentHeight - 900 > position ? position : 0);
        });
      }

      if (savedScroll) {
        delete scrollPositions.current[path];
      }
    },
    []
  );

  return (
    <ScrollRestorationContext.Provider
      value={{
        isIframe,
        restoreScrollPosition,
        saveScrollPosition,
      }}
    >
      {children}
    </ScrollRestorationContext.Provider>
  );
};

export const useScrollRestoration = () => useContext(ScrollRestorationContext);
