import type { CrmDetails } from '@jane/shared/models';
import { Button, Typography } from '@jane/shared/reefer';
import type { Spacing } from '@jane/shared/reefer';

import { UnlockWalletWrapper } from '../customerLoyaltyPoints.styles';

const formatPhoneNumber = (phone: string) => {
  const match = phone.match(/^(\d{3})(\d{3})(\d{4})$/);

  return match
    ? `${match[1].toString()}-${match[2].toString()}–${match[3].toString()}`
    : null;
};

export const InitiateUnlockWallet = ({
  crmDetails,
  headless,
  onSendCode,
  loading,
}: {
  crmDetails: CrmDetails;
  headless?: boolean;
  loading: boolean;
  onSendCode: (e: React.MouseEvent) => void;
}) => (
  <UnlockWalletWrapper headless={headless}>
    <Typography variant="body">
      Please confirm your identity by entering a verification code sent to the
      following
      {crmDetails.integration_type === 'email' ? (
        <>
          {' '}
          email address:{' '}
          <Typography variant="body-bold"> {crmDetails.crmId}. </Typography>
        </>
      ) : (
        <>
          {' '}
          phone number:{' '}
          <Typography variant="body-bold">
            {' '}
            {formatPhoneNumber(crmDetails.crmId)}.
          </Typography>
        </>
      )}
    </Typography>
    <Button
      full
      variant="secondary"
      onClick={onSendCode}
      loading={loading}
      label="Send code"
      mt={10 as Spacing}
    />
  </UnlockWalletWrapper>
);
