import { useEffect, useState } from 'react';

import { useGetStore } from '@jane/shared/data-access';

import {
  CartDrawer,
  CartDrawerEmpty,
  CartDrawerError,
  CartDrawerLoading,
} from '.';
import { openModal } from '../../../../common/redux/application';
import { useCustomerDispatch } from '../../../../customer/dispatch';
import { getCart } from '../../../../customer/redux/cart';
import { useCustomerSelector } from '../../../../customer/selectors';
import { highestPriorityCartDecider } from '../../../../lib/highestPriorityCartDecider';

export const CartDrawerContainer = ({
  appMode,
  showCartAlerts,
}: {
  appMode?: string;
  showCartAlerts?: (value: boolean) => void;
}) => {
  const dispatch = useCustomerDispatch();
  const {
    cart: cartState,
    isLoading: isLoadingCart,
    status,
    isFixingUnavailableCheckout,
    users,
  } = useCustomerSelector(
    ({ users, cart: { cart, isLoading, isFixingUnavailableCheckout } }) => ({
      cart,
      isCartOpen: cart.is_open,
      isLoading,
      isFixingUnavailableCheckout,
      status: cart.status,
      users,
    })
  );

  const [isInitialLoad, setIsInitialLoad] = useState(true);
  const [isShowingCartAlerts, setIsShowingCartAlerts] =
    useState<boolean>(false);

  useEffect(() => {
    if (isInitialLoad && !isLoadingCart) setIsInitialLoad(false);
  }, [isInitialLoad, isLoadingCart]);

  useEffect(() => {
    const cartDecision = highestPriorityCartDecider(cartState, users);
    const { isPendingCart } = cartDecision;

    if (isPendingCart && !cartState) {
      dispatch(getCart());
      setIsInitialLoad(true);
    }
  }, [cartState.store?.id]);

  useEffect(() => {
    if (
      (cartState.brand_special_has_changed ||
        (isFixingUnavailableCheckout &&
          cartState.unavailable_products.length)) &&
      !isShowingCartAlerts
    ) {
      if (appMode === 'default') {
        dispatch(
          openModal({
            name: 'cartAlerts',
          })
        );
      } else if (showCartAlerts) {
        showCartAlerts(true);
      }

      setIsShowingCartAlerts(true);
    }
  }, [
    isFixingUnavailableCheckout,
    cartState.unavailable_products.length,
    cartState.brand_special_has_changed,
  ]);

  const {
    data: store,
    isInitialLoading: isLoadingStore,
    isError,
  } = useGetStore(cartState.store?.id);

  const isLoading = isInitialLoad || isLoadingStore;
  const emptyCart =
    !cartState?.products?.length ||
    status !== 'pending' ||
    !cartState.store?.id ||
    !store;

  if (isLoading) return <CartDrawerLoading />;
  if (isError) return <CartDrawerError />;
  if (emptyCart) return <CartDrawerEmpty />;

  return <CartDrawer />;
};
