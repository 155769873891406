/**
 * DO NOT MODIFY THIS FILE!
 * It was created by the reefer-icons generator.
 * Please update & re-run the generator if need be.
 */
import type { SVGProps } from 'react';

import type { StaticIconProps } from '../../../icon/icon.types';
import { Icon } from '../../icon';

const JaneGold16 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="100%"
    height="100%"
    fill="none"
    viewBox="0 0 16 16"
    {...props}
  >
    <g className="Jane Gold 16">
      <circle cx={8} cy={8} r={8} fill="#FFC220" className="Ellipse 739" />
      <path
        fill="#fff"
        fillRule="evenodd"
        d="M12.667 8a.36.36 0 0 1-.36.359 3.949 3.949 0 0 0-3.948 3.949.359.359 0 1 1-.718 0 3.949 3.949 0 0 0-3.949-3.95.359.359 0 1 1 0-.717 3.949 3.949 0 0 0 3.949-3.949.359.359 0 1 1 .718 0 3.949 3.949 0 0 0 3.949 3.949c.198 0 .359.16.359.359Z"
        className="Ellipse 734 (Stroke)"
        clipRule="evenodd"
      />
    </g>
  </svg>
);

const JaneGold24 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="100%"
    height="100%"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <g className="Jane Gold 24">
      <circle cx={12} cy={12} r={12} fill="#FFC220" className="Fill-Main" />
      <path
        fill="#fff"
        fillRule="evenodd"
        d="M19 12a.539.539 0 0 1-.538.539 5.923 5.923 0 0 0-5.923 5.923.539.539 0 0 1-1.078 0 5.923 5.923 0 0 0-5.923-5.923.538.538 0 0 1 0-1.078 5.923 5.923 0 0 0 5.923-5.923.538.538 0 0 1 1.078 0 5.923 5.923 0 0 0 5.923 5.923c.297 0 .538.242.538.539Z"
        className="Fill-Accent"
        clipRule="evenodd"
      />
    </g>
  </svg>
);

const JaneGold48 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="100%"
    height="100%"
    fill="none"
    viewBox="0 0 48 48"
    {...props}
  >
    <g className="Jane Gold 48">
      <circle cx={24} cy={24} r={24} fill="#FFC220" className="Fill-Main" />
      <path
        fill="#fff"
        fillRule="evenodd"
        d="M38 24c0 .595-.482 1.077-1.077 1.077-6.542 0-11.846 5.304-11.846 11.846a1.077 1.077 0 0 1-2.154 0c0-6.542-5.304-11.846-11.846-11.846a1.077 1.077 0 0 1 0-2.154c6.542 0 11.846-5.304 11.846-11.846a1.077 1.077 0 0 1 2.154 0c0 6.542 5.304 11.846 11.846 11.846.595 0 1.077.482 1.077 1.077Z"
        className="Fill-Accent"
        clipRule="evenodd"
      />
    </g>
  </svg>
);

const JaneGold64 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="100%"
    height="100%"
    fill="none"
    viewBox="0 0 64 64"
    {...props}
  >
    <g className="Jane Gold 64">
      <circle cx={32} cy={32} r={32} fill="#FFC220" className="Fill-Main" />
      <path
        fill="#fff"
        fillRule="evenodd"
        d="M50.667 32c0 .793-.643 1.436-1.436 1.436-8.724 0-15.795 7.071-15.795 15.795a1.436 1.436 0 0 1-2.872 0c0-8.724-7.072-15.795-15.795-15.795a1.436 1.436 0 0 1 0-2.872c8.723 0 15.795-7.072 15.795-15.795a1.436 1.436 0 0 1 2.872 0c0 8.723 7.071 15.795 15.795 15.795.793 0 1.436.643 1.436 1.436Z"
        className="Fill-Seconday"
        clipRule="evenodd"
      />
    </g>
  </svg>
);

const SIZE_MAP = {
  sm: JaneGold16,
  md: JaneGold24,
  xl: JaneGold48,
  lg: JaneGold48,
  xxl: JaneGold64,
  xxxl: JaneGold64,
};

export const JaneGoldIcon = ({
  size = 'md',
  'data-testid': testId,
  ...props
}: StaticIconProps) => {
  return (
    <Icon
      data-testid={testId || 'jane-gold-icon'}
      icon={SIZE_MAP[size]}
      {...props}
      size={size}
      color={undefined}
    />
  );
};
