import {
  Button,
  Flex,
  Link,
  Typography,
  useMobileMediaQuery,
} from '@jane/shared/reefer';

import { StyledCarouselWrapper } from './menuProductCarousel.styles';
import type { MenuProductCarouselProps } from './menuProductCarousel.types';
import { MenuProductCarouselTitle } from './menuProductCarouselTitle';

export const ListViewCarousel = ({
  children,
  name,
  onHover,
  onLeave,
  variant,
  viewAllTo,
  viewAllOnClick,
  ...props
}: MenuProductCarouselProps) => {
  const isMobile = useMobileMediaQuery({});
  const isAd = variant === 'sponsored' || variant === 'sponsored-inline';
  return (
    <StyledCarouselWrapper
      ariaLabel={isAd ? 'Sponsored content' : name}
      border={
        variant === 'sponsored-inline' ? 'transparent-black-ten' : undefined
      }
      borderWidth={variant === 'sponsored-inline' ? '1px 1px 0 0' : undefined}
      onMouseEnter={onHover}
      onMouseLeave={onLeave}
      variant={variant}
      {...props}
    >
      <Flex
        as="section"
        width="100%"
        gap={isMobile ? 16 : 24}
        flexDirection="column"
        alignItems="center"
        data-janedm-tag={variant === 'magic-row' ? 'product cards' : undefined}
      >
        <Flex
          pt={variant === 'sponsored-inline' ? 24 : 0}
          width="100%"
          alignItems="center"
          justifyContent="space-between"
        >
          <MenuProductCarouselTitle name={name} variant={variant} />
          {(viewAllTo || viewAllOnClick) && (
            // eslint-disable-next-line react/jsx-no-useless-fragment
            <>
              {isMobile ? (
                <Link ml={12} onClick={viewAllOnClick} to={viewAllTo}>
                  <Typography>View all</Typography>
                </Link>
              ) : (
                <Button
                  label="View all"
                  ml={12}
                  onClick={viewAllOnClick}
                  to={viewAllTo}
                  variant="tertiary"
                />
              )}
            </>
          )}
        </Flex>
        {children}
      </Flex>
    </StyledCarouselWrapper>
  );
};
