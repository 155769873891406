import type { AppMode, Store, UserLocation } from '@jane/shared/models';

export const useProductReviewsEnabled = (
  appMode: AppMode,
  userLocation: UserLocation | null,
  currentStore?: Store | null
) => {
  // Product reviews not allowed in Ohio
  if (userLocation?.cityState?.match(/, OH$/)) {
    return false;
  }

  if (appMode === 'default') {
    if (currentStore) {
      return !currentStore.force_disable_reviews;
    } else {
      return true;
    }
  }
  if (currentStore) return currentStore.product_reviews_enabled;
  return true;
};
