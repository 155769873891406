import { useCallback, useEffect, useState } from 'react';

interface VisualViewportHeightReturn {
  keyboardHeight: number;
  viewportHeight: number;
}

/**
 * Provides measurements of the visual viewport. A pixel count can be preferable to
 * '100vh' if you need to discount the space occupied by an on-screen keyboard.
 * @see https://developer.mozilla.org/en-US/docs/Web/API/Visual_Viewport_API
 */
export const useVisualViewportHeight = (): VisualViewportHeightReturn => {
  const [visibleHeight, setVisibleHeight] = useState(
    window.visualViewport?.height ?? window.innerHeight
  );

  const recalculateHeight = useCallback(() => {
    setVisibleHeight(window.visualViewport?.height ?? window.innerHeight);
  }, []);

  useEffect(() => {
    window.visualViewport?.addEventListener('resize', recalculateHeight);

    return () => {
      window.visualViewport?.removeEventListener('resize', recalculateHeight);
    };
  }, [recalculateHeight]);

  return {
    keyboardHeight: window.innerHeight - visibleHeight,
    viewportHeight: visibleHeight,
  };
};
