export const PRICE_BUCKET_FILTER_ITEMS = [
  { label: 'Under $20', value: ':19.99', end: 19.99 },
  { label: '$20-40', value: '20:39.99', start: 20, end: 39.99 },
  { label: '$40-60', value: '40:59.99', start: 40, end: 59.99 },
  { label: '$60-80', value: '60:79.99', start: 60, end: 79.99 },
  { label: '$80 & above', value: '80:', start: 80 },
];

export const RATING_FILTER_ITEMS = [
  { label: '4', value: '4:', start: 4 },
  { label: '3', value: '3:', start: 3 },
  { label: '2', value: '2:', start: 2 },
  { label: '1', value: '1:', start: 1 },
];

export const listOfFeelings = [
  'Relaxed',
  'Blissful',
  'Creative',
  'Sleepy',
  'Not high',
  'Pain free',
  'Hungry',
  'Energetic',
];
