import { FLAGS, useFlag } from '@jane/shared/feature-flags';

export enum RecommendedSortVersion {
  off = 'off',
  p0 = 'p0',
  p1 = 'p1',
}

export const useGetRecommendedSortVersion = (): RecommendedSortVersion => {
  return useFlag<RecommendedSortVersion>(
    FLAGS.dmRecommendedSortVersion,
    RecommendedSortVersion.off
  );
};
