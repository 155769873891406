import { useEffect, useState } from 'react';

import type { CustomThemeConfig } from '@jane/shared/reefer';
import { postMessageToIframeParent } from '@jane/shared/util';

import { useCustomerDispatch } from '../customer/dispatch';
import { setDisableAuthFeatures } from '../customer/redux/embeddedApp';
import { getTheme } from '../lib/getTheme';
import { useWindowListener } from './useWindowListener';

// Allow partners to dynamically apply a theme
export const useEmbeddedOptionsListener = () => {
  const [theme, setTheme] = useState<CustomThemeConfig | null>(null);
  const dispatch = useCustomerDispatch();

  // Post message to iframe parent to let them know we are ready to apply a theme from a payload
  useEffect(() => {
    postMessageToIframeParent({
      messageType: 'loadingEvent',
      payload: {
        name: 'embeddedAppLoaded',
      },
    });
  }, []);

  useWindowListener('message', (event) => {
    if (event.data.messageType === 'initEmbeddedOptions') {
      const theme = event.data?.payload?.options?.theme;

      setTheme(getTheme(theme));

      if (event.data?.payload?.options?.disableAuthFeatures) {
        dispatch(setDisableAuthFeatures(true));
      }
    }
  });

  return { theme };
};
