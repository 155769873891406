import { getWeightLabel } from '@jane/search/util';
import { JaneGoldTag, SpecialTag } from '@jane/shared/components';
import type { MenuProduct } from '@jane/shared/models';
import {
  AddIcon,
  Box,
  Button,
  Card,
  Flex,
  Typography,
} from '@jane/shared/reefer';
import type { Breadcrumb, PriceId } from '@jane/shared/types';
import { formatCurrency, productPhotoMetadata } from '@jane/shared/util';

import { StyledCard, StyledLink } from './quickAddCard.styles';

export interface QuickAddCardProps {
  addToCart: () => void;
  breadcrumbs?: Breadcrumb[];
  cartCount?: number;
  hasApplicableSpecial?: boolean;
  janeGoldLabel?: string;
  onClick?: () => void;
  pdpUrl: string;
  priceId: PriceId;
  product: MenuProduct;
  specialLabel?: string;
}

export const QuickAddCard = ({
  addToCart,
  breadcrumbs,
  cartCount = 0,
  hasApplicableSpecial,
  janeGoldLabel,
  onClick,
  pdpUrl,
  priceId,
  product,
  specialLabel,
}: QuickAddCardProps) => {
  const { url } = productPhotoMetadata(product);
  const { name } = product;

  const price = product[`price_${priceId}`];
  const discountedPrice = product[`discounted_price_${priceId}`];
  const unitPrice = (hasApplicableSpecial && discountedPrice) || price;
  const isDiscounted = discountedPrice && price !== unitPrice;

  const unit = getWeightLabel(
    ['half_gram', 'half gram'].includes(priceId)
      ? '0.5g'
      : priceId.replace(/_/g, ' ')
  );

  const handleClick = () => {
    onClick && onClick();
  };

  const ButtonIcon = () =>
    cartCount ? (
      <Typography color="text-inverse" variant="body-bold">
        {cartCount}
      </Typography>
    ) : (
      <AddIcon color="text-inverse" />
    );

  return (
    <StyledCard
      flat
      width="100%"
      height="100%"
      border="transparent-black-ten"
      data-testid="quick-add-card"
    >
      <Flex
        flexWrap="wrap"
        gap={4}
        justifyContent="flex-end"
        p={8}
        position="absolute"
        width="100%"
        zIndex={10}
        css={{ pointerEvents: 'none' }}
      >
        {janeGoldLabel && <JaneGoldTag label={janeGoldLabel} />}
        {specialLabel && <SpecialTag label={specialLabel} />}
      </Flex>
      <StyledLink
        to={pdpUrl}
        {...(breadcrumbs && { state: { breadcrumbs } })}
        onClick={handleClick}
        ariaLabel={`view ${name} product details`}
        variant="minimal"
      >
        <Card.Image alt={`${name}-image`} height="198px" src={url} responsive />
      </StyledLink>
      <Box position="static" ml="auto" mr={8} mt={-56}>
        <Button.Icon
          ariaLabel={`add ${name} to cart`}
          onClick={addToCart}
          variant="primary"
          icon={<ButtonIcon />}
        />
      </Box>
      <StyledLink
        to={pdpUrl}
        {...(breadcrumbs && { state: { breadcrumbs } })}
        onClick={handleClick}
        ariaLabel={`view ${name} product details`}
        variant="minimal"
      >
        <Card.Content>
          <Typography maxLines={2} variant="body-bold">
            {name}
          </Typography>

          <Flex justifyContent="space-between">
            <Typography variant="body-bold" color="primary">
              {formatCurrency(unitPrice)}/{unit}
            </Typography>
            {isDiscounted && (
              <Typography variant="body" color="primary" strikeThrough>
                {formatCurrency(price)}
              </Typography>
            )}
          </Flex>
        </Card.Content>
      </StyledLink>
    </StyledCard>
  );
};
