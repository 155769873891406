/**
 * DO NOT MODIFY THIS FILE!
 * It was created by the reefer-icons generator.
 * Please update & re-run the generator if need be.
 */
import type { SVGProps } from 'react';

import type { StaticIconProps } from '../../../icon/icon.types';
import { Icon } from '../../icon';

const Sleep24 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="100%"
    height="100%"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <g className="Not high 25">
      <path
        fill="#5E1FF0"
        fillRule="evenodd"
        d="M12 2a2 2 0 0 1 2-2h8a2 2 0 0 1 1.562 3.25l-5.4 6.75H22a2 2 0 1 1 0 4h-8a2 2 0 0 1-1.562-3.25l5.4-6.75H14a2 2 0 0 1-2-2ZM0 14a2 2 0 0 1 2-2h6a2 2 0 0 1 1.6 3.2L6 20h2a2 2 0 1 1 0 4H2a2 2 0 0 1-1.6-3.2L4 16H2a2 2 0 0 1-2-2Z"
        className="Fill-Main"
        clipRule="evenodd"
      />
    </g>
  </svg>
);

const SIZE_MAP = {
  md: Sleep24,
  sm: Sleep24,
  lg: Sleep24,
  xl: Sleep24,
  xxl: Sleep24,
  xxxl: Sleep24,
};

export const SleepIcon = ({
  size = 'md',
  'data-testid': testId,
  ...props
}: StaticIconProps) => {
  return (
    <Icon
      data-testid={testId || 'sleep-icon'}
      icon={SIZE_MAP[size]}
      {...props}
      size={size}
      color={undefined}
    />
  );
};
