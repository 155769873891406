import styled from '@emotion/styled';
import type { ReactNode } from 'react';

import { Accordion } from '@jane/shared/components';
import type { ColorKey, Spacing } from '@jane/shared/reefer';
import { Box, Flex, Link, Typography } from '@jane/shared/reefer';

const StyledTypography = styled(Typography)({
  lineHeight: '18px',
});

const Warning = ({ children }: { children: ReactNode }) => {
  return (
    <>
      <StyledTypography variant="mini-bold" as="span">
        WARNING:&nbsp;
      </StyledTypography>
      {children}&nbsp;
      <br />
    </>
  );
};

export const Prop65Warnings = ({
  color = 'text-main',
}: {
  color?: ColorKey;
}) => {
  return (
    <StyledTypography variant="mini" as="div" my="auto" color={color}>
      <Warning>
        Smoking cannabis increases your cancer risk and during pregnancy exposes
        your child to delta-9-THC and other chemicals that can affect your
        child’s birthweight, behavior, and learning ability.
      </Warning>
      <Warning>
        Consuming products during pregnancy exposes your child to delta-9-THC,
        which can affect your child’s behavior and learning ability.
      </Warning>
      <Warning>
        Vaping or dabbing products during pregnancy exposes your child to
        delta-9-THC, which can affect your child’s behavior and learning
        ability.
      </Warning>
      <Warning>
        Using transdermal products during pregnancy exposes your child to
        delta-9-THC, which can affect your child’s behavior and learning
        ability.
      </Warning>
      <Warning>
        A spent cannabis cartridge shall be properly disposed of as hazardous
        waste at a household hazardous waste facility or other approved
        facility. An empty integrated cannabis vaporizer shall be properly
        disposed of as hazardous waste at a household hazardous waste collection
        facility or other approved facility.
      </Warning>
      <br />
      For more information go to&nbsp;
      <Link href="https://www.P65Warnings.ca.gov">www.P65Warnings.ca.gov</Link>
    </StyledTypography>
  );
};

export const Prop65WarningsAccordion = ({ mb }: { mb?: Spacing }) => (
  <Box mb={mb || 24}>
    <Typography variant="body-bold">Warning</Typography>
    <Accordion.Item id="iWarning">
      <Accordion.Heading>
        <Flex my={0} pr={8} alignItems="center">
          <Typography as="span" mr={8} variant="body">
            Proposition 65 Warning for California Consumers
          </Typography>
          <Accordion.Indicator size={12} />
        </Flex>
      </Accordion.Heading>
      <Accordion.Content destroyOnClose>
        <Prop65Warnings />
      </Accordion.Content>
    </Accordion.Item>
  </Box>
);
