import type { CustomMenuLabels } from '@jane/search/types';
import { sentenceCase } from '@jane/shared/util';

export const formatFilterLabel = (
  label: string,
  customLabels?:
    | CustomMenuLabels['category']
    | CustomMenuLabels['root_types']
    | { [label: string]: string },
  storeId?: string | number
) => {
  const hasCustomLabels = customLabels;

  let formattedLabel = label;

  if (formattedLabel.includes(':')) {
    formattedLabel = formattedLabel.split(':')[1].toLowerCase();
  }

  // NOTE(elliot): Check unformatted label first to check if there is custom labels w/ full path {type}.{sub_type} ie. tincture:Sublingual vs edible:Sublingual
  const customLabel =
    hasCustomLabels &&
    (hasCustomLabels[label] || hasCustomLabels[formattedLabel]);

  if (customLabel) {
    return customLabel;
  }

  if (storeId && label.includes(storeId.toString())) {
    return label.replace(`-${storeId}`, '');
  }

  if (formattedLabel.toLowerCase() === 'cbd') {
    return 'CBD';
  }

  formattedLabel = sentenceCase(formattedLabel.replace('_', ' '));

  return formattedLabel;
};
