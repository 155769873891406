import type {
  BrandPriceIdSpecials,
  BrandSpecialPrices,
  PriceId,
} from '@jane/shared/models';

/*
 This file contains helper functions for JaneGoldTag functions. 
 They are written using for loops to be more performant,
 as the functions that utilize these helpers are used on many items at once
 and in parts of our app where performance is most important (menus)
*/

// We frequently check that a key within BrandSpecialPrices has a value and is not null or empty
const hasPriceIdSpecial = (priceIdSpecial?: BrandPriceIdSpecials | null) => {
  return !!Object.keys(priceIdSpecial || {}).length;
};

// Some functions require first removing the null or empty values from BrandSpecialPrices
export const removeNullBrandSpecials = (brandSpecial: BrandSpecialPrices) => {
  for (const key in brandSpecial) {
    if (!hasPriceIdSpecial(brandSpecial[key as PriceId])) {
      delete brandSpecial[key as PriceId];
    }
  }
};

// Before checking if all specials are the same, we need to grab the first non-null special
export const getFirstBrandSpecial = (brandSpecial: BrandSpecialPrices) => {
  for (const key in brandSpecial) {
    if (hasPriceIdSpecial(brandSpecial[key as PriceId])) {
      return brandSpecial[key as PriceId];
    }
  }
  return null;
};

// We're looking at the brand_special_prices object to see if there is more than one special.
// In most cases, we don't care if some price ids don't have specials, just that there isn't more than one.
export const brandSpecialHasOneDiscount = (
  brandSpecial: BrandSpecialPrices,
  skipNullSpecials = true
) => {
  const firstBrandSpecial = getFirstBrandSpecial(brandSpecial);
  if (!firstBrandSpecial) return false;

  for (const key in brandSpecial) {
    if (!hasPriceIdSpecial(brandSpecial[key as PriceId])) {
      if (skipNullSpecials) {
        continue;
      } else {
        return false;
      }
    }

    if (
      brandSpecial[key as PriceId]?.brand_special_id !==
      firstBrandSpecial.brand_special_id
    ) {
      return false;
    }
  }

  return true;
};

// We're cross-references an array of price ids/available_weights with the brand special to see if they share one brand_special_id
// In some cases, we don't care if some price ids don't have specials, just that the existing ones are the same.
export const priceIdsHaveOneDiscount = (
  priceIds: PriceId[],
  brandSpecial: BrandSpecialPrices,
  mustIncludeAllPriceIds = true
) => {
  const firstBrandSpecial = getFirstBrandSpecial(brandSpecial);
  if (!firstBrandSpecial) return false;

  for (const index in priceIds) {
    if (!hasPriceIdSpecial(brandSpecial[priceIds[index] as PriceId])) {
      if (mustIncludeAllPriceIds) {
        return false;
      } else {
        continue;
      }
    }

    if (
      brandSpecial[priceIds[index] as PriceId]?.brand_special_id !==
      firstBrandSpecial.brand_special_id
    ) {
      return false;
    }
  }

  return true;
};
