import { useMfa } from '@jane/shared/providers';
import { Button, Flex, Link, Typography } from '@jane/shared/reefer';

interface MultifactorAuthenticationErrorProps {
  codeResent: boolean;
  onCancel?: () => void;
  resendCode: () => void;
}

export const MultifactorAuthenticationError = ({
  codeResent,
  onCancel,
  resendCode,
}: MultifactorAuthenticationErrorProps) => {
  const { canResendCode, mfaCancel } = useMfa();

  const showResendButton = canResendCode ? canResendCode() : !codeResent;

  const handleCancel = () => {
    onCancel && onCancel();
    mfaCancel && mfaCancel();
  };

  return (
    <Flex
      width="100%"
      height="100%"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
    >
      <Typography textAlign="center">Something went wrong.</Typography>
      <Typography textAlign="center" as="span">
        Please {!codeResent ? 'resend the code and' : ''} try again or{' '}
        <Link href="https://customer-support.iheartjane.com/s/contactsupport">
          contact support.
        </Link>
      </Typography>

      <Flex flexDirection="column">
        {showResendButton && (
          <Button label="Resend Code" onClick={resendCode} mt={16} full />
        )}
        <Button
          label="Cancel"
          onClick={handleCancel}
          mt={16}
          variant="secondary"
          full
        />
      </Flex>
    </Flex>
  );
};
