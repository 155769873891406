import { sentenceCase } from './titleCase';

export const validationsToErrors = <
  T extends Record<string, string[] | undefined>
>(
  validations: T
): { [P in keyof T]: string } => {
  const errorObject: any = {};
  for (const [key, value] of Object.entries(validations)) {
    errorObject[key] = sentenceCase(`${key} ${value && value.join(', ')}`);
  }

  return errorObject;
};
