import styled from '@emotion/styled';

import {
  DEFAULT_DESKTOP_CARD_WIDTH,
  DEFAULT_MOBILE_CARD_WIDTH,
} from '@jane/shared/components';
import { mediaQueries, spacing } from '@jane/shared/reefer-emotion';

export const PRODUCTS_GRID_GAP = '24px 24px';
const MOBILE_PRODUCTS_GRID_GAP = '16px 16px';

const ProductsGrid = styled.div<{ addMarginY?: boolean }>(
  ({ addMarginY = true }) =>
    spacing({ my: addMarginY ? 16 : undefined, p: 8, mx: -8 }),
  {
    display: 'grid',
    gridGap: MOBILE_PRODUCTS_GRID_GAP,
    gridAutoRows: '1fr',
    overflowX: 'hidden',
    // show one card on smallest mobile
    gridTemplateColumns: `repeat(auto-fill, minmax(${DEFAULT_MOBILE_CARD_WIDTH}px, 1fr))`,
    // from iphone se aka 375px, show two cards
    // special media query to account for embeds
    '@media only screen and (min-width: 350px)': {
      gridTemplateColumns: `repeat(2, minmax(${DEFAULT_MOBILE_CARD_WIDTH}px, 1fr))`,
      height: '100%',
    },
    // from 425px + autofill cards
    [mediaQueries.mobile('lg', 'min')]: {
      gridTemplateColumns: `repeat(auto-fill, minmax(${DEFAULT_MOBILE_CARD_WIDTH}px, 1fr))`,
    },
    // from 768px + change to desktop card width
    [mediaQueries.tablet('min')]: {
      gridGap: PRODUCTS_GRID_GAP,
      gridTemplateColumns: `repeat(auto-fill, minmax(${DEFAULT_DESKTOP_CARD_WIDTH}px, 1fr))`,
    },
  }
);

export default ProductsGrid;
