import {
  Button,
  CheckCircledIcon,
  Flex,
  Typography,
} from '@jane/shared/reefer';

export interface WelcomeBackScreenProps {
  /** Function called when 'done' button is clicked */
  onDoneClick(): void;
}

export const WelcomeBackScreen = ({ onDoneClick }: WelcomeBackScreenProps) => {
  return (
    <Flex width="100%" flexDirection="column" alignItems="center">
      <CheckCircledIcon size="xxl" color="primary" mb={24} />
      <Typography branded variant="header-bold" textAlign="center" mb={8}>
        Welcome back!
      </Typography>
      <Typography textAlign="center" mb={24}>
        You already have your bank accounts linked.
      </Typography>
      <Button full variant="primary" label="Done" onClick={onDoneClick} />
    </Flex>
  );
};
