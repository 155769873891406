import type { AlgoliaFacet, FilterSelectItem } from '@jane/search/types';
import { StarFilledIcon } from '@jane/shared/reefer';

import { PRICE_BUCKET_FILTER_ITEMS, RATING_FILTER_ITEMS } from './filterItems';

const priceInRange = (
  price: string,
  start: number | undefined,
  end: number | undefined
) => {
  const numericPrice = parseInt(price);
  return numericPrice >= (start || 0) && numericPrice <= (end || Infinity);
};

const ratingInRange = (rating: string, start: number) => {
  const ratingRange = parseInt(rating.split('.')[0]);
  return ratingRange >= start;
};

export const getPriceBucketCount = (
  facet: AlgoliaFacet
): FilterSelectItem[] => {
  return PRICE_BUCKET_FILTER_ITEMS.map<FilterSelectItem>(
    ({ label, value, start, end }) => {
      const count = Object.entries(facet).reduce((sum, [price, count]) => {
        return priceInRange(price, start, end) ? sum + count : sum;
      }, 0);

      return {
        count,
        label,
        value,
      };
    }
  );
};

export const getRatingBucketCount = (
  facet: AlgoliaFacet
): FilterSelectItem[] => {
  return RATING_FILTER_ITEMS.map<FilterSelectItem>(
    ({ label, value, start }) => {
      const count = Object.entries(facet).reduce((sum, [rating, count]) => {
        return ratingInRange(rating, start) ? sum + count : sum;
      }, 0);

      const icon = (
        <>
          {Array.from({ length: parseInt(label) }, (_, i) => (
            <StarFilledIcon size="sm" key={i} ml={i !== 0 ? 4 : undefined} />
          ))}
        </>
      );

      return {
        count,
        icon,
        label: `${label}+`,
        value,
      };
    }
  );
};
