import styled from '@emotion/styled';

import type {
  ArrayFilterValue,
  FilterKeys,
  SelectFilterGroup,
} from '@jane/search/types';
import { formatCount } from '@jane/search/util';
import { Button, Flex } from '@jane/shared/reefer';

import { useActiveFilterContext } from './activeFilterProvider';

export interface FilterBarButtonsProps {
  /** Filter group to render */
  filterGroup: SelectFilterGroup;

  /** Option to hide filter counts */
  hideCounts?: boolean;

  /** Action when filter is changed */
  onChange: (key: FilterKeys, label: ArrayFilterValue) => void;
}

const FilterButtonWrapper = styled.div({
  whiteSpace: 'nowrap',
});

export const FilterBarButtons = ({
  filterGroup,
  hideCounts,
  onChange,
}: FilterBarButtonsProps) => {
  const singleSelectItems = filterGroup.items;
  const { activeFilters } = useActiveFilterContext();

  return (
    <>
      {singleSelectItems.map((item) => {
        const { label, count, value, icon } = item;

        const itemCount = !hideCounts ? ` (${formatCount(count)})` : '';

        const renderedLabel = `${label}${itemCount}`;

        // prevents the icon from shrinking
        const iconToRender = (
          <Flex data-testid="filter-button-icon">{icon}</Flex>
        );

        const isSelected = activeFilters.some(
          (filter) => filter.value === value
        );
        return (
          <FilterButtonWrapper key={label}>
            <Button
              label={renderedLabel}
              onClick={() => onChange(filterGroup.key, value)}
              variant={isSelected ? 'tertiary-selected' : 'tertiary'}
              disabled={!count}
              startIcon={icon ? iconToRender : undefined}
            />
          </FilterButtonWrapper>
        );
      })}
    </>
  );
};
