export const WEIGHT_SORT_ORDER: { [key: string]: number } = {
  'half gram': 0,
  gram: 1,
  'two gram': 2,
  'eighth ounce': 3,
  'quarter ounce': 4,
  'half ounce': 5,
  ounce: 6,
};

export const getWeightSortOrder = (label: string): number =>
  WEIGHT_SORT_ORDER[label] || 0;

export const sortWeights = (weightFacets: { [value: string]: number }) => {
  return Object.keys(weightFacets)
    .sort((a, b) => getWeightSortOrder(a) - getWeightSortOrder(b))
    .reduce((ordered: { [label: string]: number }, key) => {
      ordered[key] = weightFacets[key];
      return ordered;
    }, {});
};

export const WEIGHT_LABELS: { [key: string]: string } = {
  'half gram': '1/2g',
  gram: '1g',
  'two gram': '2g',
  'eighth ounce': '3.5g',
  'quarter ounce': '7g',
  'half ounce': '14g',
  ounce: '28g',
};

export const getWeightLabel = (label: string): string =>
  WEIGHT_LABELS[label] || label;
