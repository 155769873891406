import type { DeliveryValidation, Store } from '@jane/shared/models';
import { Banner } from '@jane/shared/reefer';
import { formatCurrency } from '@jane/shared/util';

import minimumDeliveryOrder from '../../../../../lib/minimumDeliveryOrder';
import {
  getPickupMinAmount,
  minimumPickupOrder,
} from '../../../../../lib/minimumPickupOrder';

interface Props {
  deliveryValidateData: DeliveryValidation | undefined;
  discountedSubtotal: number;
  modeLabel: string;
  reservationMode: string;
  store: Store;
}

export const CartMinimumAlert = ({
  reservationMode,
  discountedSubtotal,
  store,
  modeLabel,
  deliveryValidateData,
}: Props) => {
  let requiredMinimum: number | string = 0;
  let invalidCart = false;

  switch (reservationMode) {
    case 'pickup':
      requiredMinimum = getPickupMinAmount(store) || 0;
      invalidCart = !minimumPickupOrder(discountedSubtotal, store);
      break;
    case 'delivery':
      requiredMinimum = deliveryValidateData?.minimum || 0;
      invalidCart = !minimumDeliveryOrder({
        discountedSubtotal,
        store,
        deliveryValidateData,
      });
      break;
  }

  return (
    invalidCart && (
      <Banner
        label={`${
          store.name
        } requires a minimum ${modeLabel} purchase of ${formatCurrency(
          requiredMinimum
        )}`}
        variant="error"
        full
        mb={16}
      />
    )
  );
};
