import type { CSSObject } from '@emotion/react';
import styled from '@emotion/styled';
import type { ReactNode } from 'react';
import React from 'react';

import { spacing } from '@jane/shared/reefer-emotion';

import TextFieldProvider from './text-field-context';
import type { Variant } from './text-input';

type ContainerProps = {
  variant?: Variant;
};

const material: CSSObject = {
  position: 'relative',
  display: 'inline-flex',
  flexDirection: 'column',
  minWidth: 0,
  width: '100%',
  ...spacing({ mb: 4 }),
};

export const Container = styled.div<ContainerProps>((props) => {
  switch (props.variant) {
    case 'material':
      return material;

    default:
      break;
  }
});

interface FieldControlProps {
  children: ReactNode;
  error?: boolean;
  isFocused?: boolean;
  success?: boolean;
  variant?: Variant;
}

const FieldControl = ({
  children,
  variant = 'material',
  isFocused = false,
  error = false,
  success = false,
}: FieldControlProps) => {
  const [isShrunk] = React.useState(() => {
    let shrink = false;

    if (children) {
      React.Children.forEach(children, (child) => {
        if (!React.isValidElement(child)) return;
        if (child.props.defaultValue || child.props.value) {
          shrink = true;
        }
      });
    }

    return shrink;
  });

  return (
    <TextFieldProvider
      isShrunk={isShrunk}
      variant={variant}
      error={error}
      success={success}
      isFocused={isFocused}
    >
      <Container variant={variant}>{children}</Container>
    </TextFieldProvider>
  );
};

export default FieldControl;
