import type { MouseEventHandler } from 'react';

import { logPrefix } from './logger';

export interface AccessibilityProps {
  /** Provides a text alternative for elements that have no visible text  */
  'aria-label'?: string;

  /** Provides a a reference to a text alternative for elements that have no visible text  */
  'aria-labelledby'?: string;

  /** Provides a text alternative for elements that have no visible text  */
  ariaLabel?: string;

  /** Provides a a reference to a text alternative for elements that have no visible text  */
  ariaLabelledBy?: string;

  /** Sets the role of the element */
  role?: string;
}

const actionRoleList = ['link', 'button', 'menuitem'];

/**
 * Check accessibility for components that have action-related roles.
 * Return object of accessibility props, mapped to their appropriate html attributes.
 *
 * See: https://dequeuniversity.com/rules/axe/4.1/aria-command-name
 */
export function getAccessibilityProps(
  {
    ariaLabel,
    ariaLabelledBy,
    role: roleProp,
    onClick,
  }: AccessibilityProps & { onClick?: MouseEventHandler },
  componentName: string
) {
  const role = onClick ? 'button' : roleProp;

  if (role && actionRoleList.includes(role)) {
    if (!ariaLabel && !ariaLabelledBy) {
      throw new Error(
        `${logPrefix(
          componentName
        )} When using an action-related role (link, button, menuitem), you must supply a label of some kind (via ariaLabel or ariaLabelledBy). \n\tSee https://dequeuniversity.com/rules/axe/4.1/aria-command-name for more info.`
      );
    }
  }

  return {
    'aria-label': ariaLabel,
    'aria-labelledby': ariaLabelledBy,
    role,
  };
}
