import {
  REEFER_TYPOGRAPHY_FONT_WEIGHT_REGULAR,
  REEFER_TYPOGRAPHY_FONT_WEIGHT_SEMIBOLD,
} from '../../tokens';
import type { ReeferTheme } from '../theme.types';
import { generateReeferThemeComponentsCSSVars } from './components';
import { generateCSSVars } from './utils';

function generateThemeCSSVars(theme: ReeferTheme) {
  return {
    ...generateReeferThemeComponentsCSSVars(theme.components),
    ...generateCSSVars(theme.borderRadius, 'border-radius'),

    // Colors
    ...generateCSSVars(theme.colors, 'colors'),
    '--colors-inherit': 'inherit',
    '--colors-transparent': 'transparent',

    // Fonts
    '--font-family-default': `${theme.components.Typography.fonts.default.fontFamily}, Source Sans Pro, Helvetica, Arial, sans-serif`,
    '--font-family-branded': `${theme.components.Typography.fonts.branded.fontFamily}, Source Sans Pro, Helvetica, Arial, sans-serif`,
    '--font-weight-regular': REEFER_TYPOGRAPHY_FONT_WEIGHT_REGULAR,
    '--font-weight-semibold': REEFER_TYPOGRAPHY_FONT_WEIGHT_SEMIBOLD,
  };
}

export function generateThemeCSS(theme: ReeferTheme, id?: string) {
  const cssVars = generateThemeCSSVars(theme);
  const fonts = theme.components.Typography.fonts;

  let themeCss = '';

  if (fonts.default.url) {
    themeCss += `
      @import url(${fonts.default.url});
      @font-face {
        font-family: ${fonts.default.fontFamily};
        src: url("${fonts.default.url}");
        font-display: swap;
      }
      \n\n`;
  }

  if (fonts.branded.url) {
    themeCss += `
      @import url("${fonts.branded.url}");
      @font-face {
        font-family: ${fonts.branded.fontFamily};
        src: url("${fonts.branded.url}");
        font-display: swap;
      }\n\n`;
  }

  themeCss += id ? `#${id} {` : ':root {';

  Object.entries(cssVars).forEach(([key, value]) => {
    themeCss += `\n  ${key}: ${value};`;
  });

  themeCss += '\n}';

  return themeCss;
}
