import type { MenuProduct, PriceId } from '@jane/shared/models';

interface Props {
  menuProduct: MenuProduct;
  priceId: PriceId;
}

export const getPriceIdBrandSpecial = ({ menuProduct, priceId }: Props) => {
  if (!menuProduct.brand_special_prices || !menuProduct.has_brand_discount)
    return null;

  const priceIdSpecial = menuProduct.brand_special_prices?.[priceId];

  return priceIdSpecial;
};
