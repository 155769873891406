import type { SVGProps } from 'react';

import { Icon } from '../../icon/icon';
import type { StaticIconProps } from '../../icon/icon.types';

const Cat = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="64"
    height="64"
    viewBox="0 0 64 64"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <circle cx={32} cy={32} r={32} fill="#FF5500" />
    <circle cx={32} cy={32} r={32} fill="white" fillOpacity={0.8} />
    <path
      d="M12.1573 16.6874C11.7375 14.5882 13.5882 12.7375 15.6874 13.1573L27.189 15.4576C28.3766 15.6951 29.3049 16.6234 29.5424 17.811L30.9802 25L30.1961 28.9204C29.9513 30.1447 30.1698 31.3183 30.7073 32.2967C30.0592 32.7875 29.2085 33.0219 28.3126 32.8427L16.811 30.5424C15.6234 30.3049 14.6951 29.3766 14.4576 28.189L12.1573 16.6874ZM35.6874 32.8427C34.7915 33.0219 33.9409 32.7875 33.2927 32.2967C33.8302 31.3183 34.0487 30.1447 33.8039 28.9204L33.0198 25L34.4576 17.811C34.6951 16.6234 35.6234 15.6951 36.811 15.4576L48.3126 13.1573C50.4118 12.7375 52.2626 14.5882 51.8427 16.6874L49.5424 28.189C49.3049 29.3766 48.3766 30.3049 47.189 30.5424L35.6874 32.8427Z"
      fill="#FF5500"
    />
    <path
      d="M12.1573 16.6874C11.7375 14.5882 13.5882 12.7375 15.6874 13.1573L27.189 15.4576C28.3766 15.6951 29.3049 16.6234 29.5424 17.811L30.9802 25L30.1961 28.9204C29.9513 30.1447 30.1698 31.3183 30.7073 32.2967C30.0592 32.7875 29.2085 33.0219 28.3126 32.8427L16.811 30.5424C15.6234 30.3049 14.6951 29.3766 14.4576 28.189L12.1573 16.6874ZM35.6874 32.8427C34.7915 33.0219 33.9409 32.7875 33.2927 32.2967C33.8302 31.3183 34.0487 30.1447 33.8039 28.9204L33.0198 25L34.4576 17.811C34.6951 16.6234 35.6234 15.6951 36.811 15.4576L48.3126 13.1573C50.4118 12.7375 52.2626 14.5882 51.8427 16.6874L49.5424 28.189C49.3049 29.3766 48.3766 30.3049 47.189 30.5424L35.6874 32.8427Z"
      fill="black"
      fillOpacity={0.2}
    />
    <path
      d="M12.1573 16.6874C11.7375 14.5882 13.5882 12.7375 15.6874 13.1573L27.189 15.4576C28.3766 15.6951 29.3049 16.6234 29.5424 17.811L30.9802 25L30.1961 28.9204C29.9513 30.1447 30.1698 31.3183 30.7073 32.2967C30.0592 32.7875 29.2085 33.0219 28.3126 32.8427L16.811 30.5424C15.6234 30.3049 14.6951 29.3766 14.4576 28.189L12.1573 16.6874ZM35.6874 32.8427C34.7915 33.0219 33.9409 32.7875 33.2927 32.2967C33.8302 31.3183 34.0487 30.1447 33.8039 28.9204L33.0198 25L34.4576 17.811C34.6951 16.6234 35.6234 15.6951 36.811 15.4576L48.3126 13.1573C50.4118 12.7375 52.2626 14.5882 51.8427 16.6874L49.5424 28.189C49.3049 29.3766 48.3766 30.3049 47.189 30.5424L35.6874 32.8427Z"
      stroke="#FF5500"
      strokeWidth={2}
    />
    <circle cx={32} cy={33} r={20} fill="#FF5500" />
    <circle cx={20} cy={37} r={12} fill="#FF5500" />
    <circle cx={44} cy={37} r={12} fill="#FF5500" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19.7361 43.5753C19.9707 43.4296 20.279 43.5016 20.4247 43.7361C22.0206 46.3047 24.0518 47.5 26 47.5C27.9482 47.5 29.9794 46.3047 31.5753 43.7361C31.721 43.5016 32.0293 43.4296 32.2639 43.5753C32.4984 43.721 32.5704 44.0293 32.4247 44.2639C30.7069 47.0286 28.395 48.5 26 48.5C23.605 48.5 21.2931 47.0286 19.5753 44.2639C19.4296 44.0293 19.5016 43.721 19.7361 43.5753Z"
      fill="#0E0E0E"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M31.7361 43.5753C31.9707 43.4296 32.279 43.5016 32.4247 43.7361C34.0206 46.3047 36.0518 47.5 38 47.5C39.9482 47.5 41.9794 46.3047 43.5753 43.7361C43.721 43.5016 44.0293 43.4296 44.2639 43.5753C44.4984 43.721 44.5704 44.0293 44.4247 44.2639C42.7069 47.0286 40.395 48.5 38 48.5C35.605 48.5 33.2931 47.0286 31.5753 44.2639C31.4296 44.0293 31.5016 43.721 31.7361 43.5753Z"
      fill="#0E0E0E"
    />
    <circle cx={22} cy={29} r={6} fill="white" />
    <circle cx={42} cy={29} r={6} fill="white" />
    <circle cx={22} cy={29} r={4} fill="#FF9900" />
    <circle cx={42} cy={29} r={4} fill="#FF9900" />
    <circle cx={22} cy={29} r={2} fill="#0E0E0E" />
    <circle cx={42} cy={29} r={2} fill="#0E0E0E" />
    <circle cx={23} cy={28} r={1} fill="white" />
    <circle cx={43} cy={28} r={1} fill="white" />
    <path
      d="M32.7071 37.2929C32.3166 37.6834 31.6834 37.6834 31.2929 37.2929L29.7071 35.7071C29.0772 35.0771 29.5233 34 30.4142 34L33.5858 34C34.4767 34 34.9229 35.0771 34.2929 35.7071L32.7071 37.2929Z"
      fill="#0E0E0E"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M38.5 37C38.5 36.7239 38.7239 36.5 39 36.5H59C59.2761 36.5 59.5 36.7239 59.5 37C59.5 37.2761 59.2761 37.5 59 37.5H39C38.7239 37.5 38.5 37.2761 38.5 37Z"
      fill="#FF5500"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M38.5 37C38.5 36.7239 38.7239 36.5 39 36.5H59C59.2761 36.5 59.5 36.7239 59.5 37C59.5 37.2761 59.2761 37.5 59 37.5H39C38.7239 37.5 38.5 37.2761 38.5 37Z"
      fill="black"
      fillOpacity={0.2}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M25.5 37C25.5 36.7239 25.2761 36.5 25 36.5H5C4.72386 36.5 4.5 36.7239 4.5 37C4.5 37.2761 4.72386 37.5 5 37.5H25C25.2761 37.5 25.5 37.2761 25.5 37Z"
      fill="#FF5500"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M25.5 37C25.5 36.7239 25.2761 36.5 25 36.5H5C4.72386 36.5 4.5 36.7239 4.5 37C4.5 37.2761 4.72386 37.5 5 37.5H25C25.2761 37.5 25.5 37.2761 25.5 37Z"
      fill="black"
      fillOpacity={0.2}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M38.5 39C38.5 38.7239 38.7239 38.5 39 38.5H59C59.2761 38.5 59.5 38.7239 59.5 39C59.5 39.2761 59.2761 39.5 59 39.5H39C38.7239 39.5 38.5 39.2761 38.5 39Z"
      fill="#FF5500"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M38.5 39C38.5 38.7239 38.7239 38.5 39 38.5H59C59.2761 38.5 59.5 38.7239 59.5 39C59.5 39.2761 59.2761 39.5 59 39.5H39C38.7239 39.5 38.5 39.2761 38.5 39Z"
      fill="black"
      fillOpacity={0.2}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M25.5 39C25.5 38.7239 25.2761 38.5 25 38.5H5C4.72386 38.5 4.5 38.7239 4.5 39C4.5 39.2761 4.72386 39.5 5 39.5H25C25.2761 39.5 25.5 39.2761 25.5 39Z"
      fill="#FF5500"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M25.5 39C25.5 38.7239 25.2761 38.5 25 38.5H5C4.72386 38.5 4.5 38.7239 4.5 39C4.5 39.2761 4.72386 39.5 5 39.5H25C25.2761 39.5 25.5 39.2761 25.5 39Z"
      fill="black"
      fillOpacity={0.2}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M38.5 41C38.5 40.7239 38.7239 40.5 39 40.5H59C59.2761 40.5 59.5 40.7239 59.5 41C59.5 41.2761 59.2761 41.5 59 41.5H39C38.7239 41.5 38.5 41.2761 38.5 41Z"
      fill="#FF5500"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M38.5 41C38.5 40.7239 38.7239 40.5 39 40.5H59C59.2761 40.5 59.5 40.7239 59.5 41C59.5 41.2761 59.2761 41.5 59 41.5H39C38.7239 41.5 38.5 41.2761 38.5 41Z"
      fill="black"
      fillOpacity={0.2}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M25.5 41C25.5 40.7239 25.2761 40.5 25 40.5H5C4.72386 40.5 4.5 40.7239 4.5 41C4.5 41.2761 4.72386 41.5 5 41.5H25C25.2761 41.5 25.5 41.2761 25.5 41Z"
      fill="#FF5500"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M25.5 41C25.5 40.7239 25.2761 40.5 25 40.5H5C4.72386 40.5 4.5 40.7239 4.5 41C4.5 41.2761 4.72386 41.5 5 41.5H25C25.2761 41.5 25.5 41.2761 25.5 41Z"
      fill="black"
      fillOpacity={0.2}
    />
  </svg>
);
export const CatAvatar = ({ size = 'xl', ...props }: StaticIconProps) => {
  return <Icon icon={Cat} {...props} size={size} color={undefined} />;
};
