import type { UseQueryResult } from '@tanstack/react-query';
import { useQuery } from '@tanstack/react-query';

import type { AlgoliaProduct, JaneSearchState } from '@jane/search/types';
import type { AppMode, Id } from '@jane/shared/models';

import type { FetchInlineAdResponse } from '../../data-access/fetchInlineAd';
import { fetchInlineAd } from '../../data-access/fetchInlineAd';
import type { InlinePlacement } from '../../types/placement';
import type { RecommendedSortVersion } from '../useGetRecommendedSortVersion';
import { buildFetchInlineAdsSearchFilterParam } from './buildFetchInlineAdsSearchFilterParam';

interface UseInlineAdParams {
  appMode: AppMode;
  count?: number;
  enabled?: boolean;
  isRecommendedSortEnabled: boolean;
  janeDeviceId: string;
  keywords: string[];
  numColumns?: number;
  optionalFilters?: string;
  placement: InlinePlacement;
  searchState: JaneSearchState<AlgoliaProduct>;
  smartSortVersion: RecommendedSortVersion;
  staleTime?: number;
  storeId: Id;
}

type UseInlineAdResponse = FetchInlineAdResponse & {
  indexName: string;
};

export const useInlineAd = ({
  enabled = true,
  isRecommendedSortEnabled,
  optionalFilters,
  placement,
  searchState,
  smartSortVersion,
  staleTime,
  ...args
}: UseInlineAdParams): UseQueryResult<UseInlineAdResponse> => {
  const { bucketFilters, currentSort, filters, rangeFilters, searchText } =
    searchState;
  const filterSearchState = {
    bucketFilters,
    currentSort,
    filters,
    rangeFilters,
    searchText,
  };
  return useQuery({
    cacheTime: 0,
    enabled,
    queryFn: async () => {
      const searchFilter = buildFetchInlineAdsSearchFilterParam({
        optionalFilters,
        filterSearchState,
        isRecommendedSortEnabled,
        placement,
      });
      const adResponse = await fetchInlineAd({
        ...args,
        placement,
        searchFilter,
        smartSortVersion,
      });
      return {
        ...adResponse,
        indexName: searchFilter.index,
      };
    },

    queryKey: [
      'inline-ads',
      isRecommendedSortEnabled,
      smartSortVersion,
      placement,
      filterSearchState,
      optionalFilters,
      args,
    ],
    refetchOnWindowFocus: false,
    retry: false,
    staleTime: staleTime ?? 0,
  });
};
