import styled from '@emotion/styled';

import { Flex } from '@jane/shared/reefer';
import { mediaQueries, spacing } from '@jane/shared/reefer-emotion';

import {
  DESKTOP_PADDING_HORIZONTAL,
  PADDING_VERTICAL,
} from '../pendingCartDrawer.styles';

export const DeliveryAddressWrapper = styled(Flex)<{
  headless?: boolean;
  headlessWide?: boolean;
}>(({ theme, headless = false, headlessWide = false }) =>
  headless
    ? {
        backgroundColor: theme.colors.grays.white,
        borderBottom: headlessWide
          ? 'none'
          : `1px solid ${theme.colors.grays.light}`,
        ...spacing({ px: DESKTOP_PADDING_HORIZONTAL, py: PADDING_VERTICAL }),
      }
    : {
        backgroundColor: theme.colors.grays.white,
        ...spacing({ px: 0, py: 0 }),
        [mediaQueries.tablet('min')]: {
          borderBottom: `1px solid ${theme.colors.grays.light}`,
          ...spacing({
            px: DESKTOP_PADDING_HORIZONTAL,
            py: PADDING_VERTICAL,
          }),
        },
      }
);
