import type { ReactNode } from 'react';

import type { AppMode } from '@jane/shared/models';
import {
  AddIcon,
  Button,
  Flex,
  SubtractIcon,
  Typography,
} from '@jane/shared/reefer';

const Value: React.FC<{
  children?: ReactNode;
  inventory?: number;
  variant?: Variant;
}> = ({ children, inventory, variant }) => {
  const condensed = variant === 'condensed';
  const showInventoryCount = !!inventory && inventory < 4;

  return (
    <Flex
      flexDirection="column"
      width={condensed ? 45 : 64}
      mx={showInventoryCount ? 16 : 0}
    >
      <Typography
        textAlign="center"
        variant={'body-bold'}
        data-testid="product-quantity"
      >
        {children}
      </Typography>
      {showInventoryCount && (
        <Typography textAlign="center" variant="mini">
          Only {inventory} left
        </Typography>
      )}
    </Flex>
  );
};

type Variant = 'default' | 'small' | 'condensed';

interface Props {
  appMode: AppMode;
  decrementQuantity: () => void;
  incrementQuantity: () => void;
  inventoryCount?: number;
  maxQuantity?: number;
  minQuantity?: number;
  multipleCountDisabled: boolean;
  onPdp?: boolean;
  value: number;
  variant?: Variant;
}

export const QuantitySelector = ({
  value,
  decrementQuantity,
  incrementQuantity,
  inventoryCount,
  variant = 'default',
  maxQuantity = 5,
  minQuantity = 1,
  onPdp = false,
}: Props) => {
  const isMinimumVal = value <= minQuantity;
  const isMaximumVal = value >= maxQuantity;

  return (
    <Flex justifyContent="center" alignSelf="center" alignItems="center">
      <Button.Icon
        ariaLabel="decrement quantity"
        disabled={isMinimumVal}
        icon={<SubtractIcon color="text-main" />}
        onClick={decrementQuantity}
        variant={'tertiary'}
      />
      <Value variant={variant} inventory={onPdp ? inventoryCount : undefined}>
        {onPdp && 'Qty: '}
        {value}
      </Value>
      <Button.Icon
        ariaLabel="increment quantity"
        disabled={isMaximumVal}
        icon={<AddIcon color="text-main" />}
        onClick={incrementQuantity}
        variant={onPdp ? 'tertiary' : undefined}
      />
    </Flex>
  );
};
