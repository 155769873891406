import styled from '@emotion/styled';
import { useState } from 'react';
import type { FormEvent } from 'react';

import { mediaQueries, spacing } from '@jane/shared/reefer-emotion';

import SearchInput from './input';

export type SearchFormMode = 'body' | 'header' | 'hero';

export const Form = styled.form<{ mode: SearchFormMode; reefer?: boolean }>(
  spacing({ pl: 8 }),
  ({ reefer, theme }) => ({
    backgroundColor: theme.colors.grays.white,
    border: `1px solid ${theme.colors.grays.light}`,
    height: reefer ? 48 : 40,
    boxShadow: reefer ? '0px 2px 4px rgb(0 0 0 / 5%)' : 'inherit',
    borderRadius: theme.borderRadius.sm,
    overflow: 'hidden',
    width: '100%',
  }),
  ({ mode, theme }) =>
    mode === 'header' && {
      border: `1px solid ${theme.colors.brand.gold.main}`,
      height: 26,
      [mediaQueries.tablet('min')]: {
        height: 50,
      },
    }
);

interface Props {
  defaultValue: string;
  id?: string;
  label?: string;
  mode?: SearchFormMode;
  onClear?: () => void;
  onSubmit?: (...args: any[]) => void;
  placeholder: string;
  reefer?: boolean;
}

function SearchForm({
  defaultValue,
  id,
  label,
  onClear,
  placeholder,
  mode = 'body',
  onSubmit: onSubmitProp,
  reefer,
}: Props) {
  const [value, setValue] = useState(defaultValue);

  const onSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const focusedElement = document.querySelector(':focus') as HTMLElement;
    focusedElement && focusedElement.blur();

    onSubmitProp && onSubmitProp(value);
  };

  const onChange = (value: string) => {
    setValue(value);
  };

  return (
    <Form action="#" id={id} onSubmit={onSubmit} mode={mode} reefer={reefer}>
      <SearchInput
        onChange={onChange}
        onClear={onClear}
        placeholder={placeholder}
        searchType={`${label ? label + '-' : ''}input`}
        value={value}
        mode={mode}
      />
    </Form>
  );
}

export default SearchForm;
