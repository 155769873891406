import capitalize from 'lodash/capitalize';
import floor from 'lodash/floor';

import type {
  CartStore,
  CustomerReservationDetailsStore,
  DeepReadonly,
  ReservationMode,
  Store,
  StoreHeaderStore,
} from '@jane/shared/models';
import { pluralize } from '@jane/shared/util';

import type { NoStore } from '../customer/redux/store';
import { isNoStore } from '../customer/redux/store';
import type { HomePageStore } from '../sources/home';
import { deserializeStoreSchedule } from './deserializers/storeSchedule';
import type StoreSchedule from './storeSchedule';
import { ScheduleType } from './storeSchedule';

export function serviceName(service: string) {
  return capitalize(service.replace('_', ' '));
}

export function displayReviews(reviews: number) {
  return pluralize({ number: reviews, noun: 'review' });
}

export function calculateRating(rating: number | null) {
  if (!rating) return 0;
  return floor(rating, 1);
}

export const defaultInStorePickupText = 'In-store pickup';
export const defaultCurbsidePickupText = 'Curbside pickup';

export const filterOrderingSchedules = (
  schedules: DeepReadonly<StoreSchedule[]>,
  store: Store | NoStore | StoreHeaderStore
): DeepReadonly<StoreSchedule[]> => {
  const curbsideMandatory = isNoStore(store)
    ? false
    : store.curbside_pickup_setting === 'mandatory';
  const curbsideDisabled = isNoStore(store)
    ? false
    : store.curbside_pickup_setting === 'not_allowed';

  const shouldExcludeSchedule = (schedule: DeepReadonly<StoreSchedule>) => {
    return (
      (schedule.type === 'pickup' && curbsideMandatory) ||
      (schedule.type === 'curbside' && curbsideDisabled)
    );
  };

  return schedules.filter((schedule) => !shouldExcludeSchedule(schedule));
};

export const fulfillmentLabel = (
  store: Store | StoreHeaderStore | CustomerReservationDetailsStore,
  fulfillmentMode: ScheduleType
) => {
  const modeLabel = reservationModeLabel(store, fulfillmentMode);
  if (
    'curbside_pickup_setting' in store &&
    store.curbside_pickup_setting === 'allowed' &&
    modeLabel === ScheduleType.Pickup
  )
    return defaultInStorePickupText;
  else if (modeLabel === ScheduleType.Curbside)
    return defaultCurbsidePickupText;
  return modeLabel;
};

export const reservationModeLabel = (
  store:
    | Store
    | NoStore
    | CustomerReservationDetailsStore
    | StoreHeaderStore
    | CartStore
    | undefined,
  reservationMode: ReservationMode | ScheduleType
) => {
  if (!store || reservationMode === 'retail' || isNoStore(store))
    return reservationMode;

  const { reservation_mode_labels } = store;

  return (
    (reservation_mode_labels && reservation_mode_labels[reservationMode]) ||
    reservationMode
  );
};

export function snakeCaseToCamelCaseStoreDeserializer(
  store: Pick<
    Store | HomePageStore,
    | 'allow_future_day_ordering'
    | 'allow_off_hours_ordering'
    | 'curbside_hours'
    | 'curbside_last_call_interval'
    | 'curbside_max_lead_time_minutes'
    | 'curbside_min_lead_time_minutes'
    | 'delivery_hours'
    | 'delivery_last_call_interval'
    | 'delivery_max_lead_time_minutes'
    | 'delivery_min_lead_time_minutes'
    | 'last_call_interval'
    | 'pickup_hours'
    | 'pickup_max_lead_time_minutes'
    | 'pickup_min_lead_time_minutes'
    | 'time_zone_identifier'
    | 'working_hours'
  >
) {
  return deserializeStoreSchedule({
    allowFutureDayOrdering: store.allow_future_day_ordering,
    allowOffHoursOrdering: store.allow_off_hours_ordering,
    curbsideHours: store.curbside_hours,
    curbsideLastCallInterval: store.curbside_last_call_interval,
    curbsideMaxLeadTimeMinutes: store.curbside_max_lead_time_minutes,
    curbsideMinLeadTimeMinutes: store.curbside_min_lead_time_minutes,
    deliveryHours: store.delivery_hours,
    deliveryLastCallInterval: store.delivery_last_call_interval,
    deliveryMaxLeadTimeMinutes: store.delivery_max_lead_time_minutes,
    deliveryMinLeadTimeMinutes: store.delivery_min_lead_time_minutes,
    lastCallInterval: store.last_call_interval,
    pickupHours: store.pickup_hours,
    pickupMaxLeadTimeMinutes: store.pickup_max_lead_time_minutes,
    pickupMinLeadTimeMinutes: store.pickup_min_lead_time_minutes,
    timeZoneIdentifier: store.time_zone_identifier,
    workingHours: store.working_hours,
  });
}
