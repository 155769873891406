import { useEffect, useState } from 'react';

export const useIsInIframe = () => {
  const [isInIframe, setIsInIframe] = useState(true);

  useEffect(() => {
    setIsInIframe(window.location !== window.parent.location);
  }, []);

  return isInIframe;
};
