import clsx from 'clsx';
import type { CSSProperties } from 'react';
import { createContext, useMemo } from 'react';
import ReactModal from 'react-modal';

import { useVisualViewportHeight } from '../../hooks';
import { getColorCSSVar, getColorForBackgroundCSSVar } from '../../utils';
import styles from './modal.module.css';
import type { ModalProps } from './modal.types';

function shadowAppSelector(): HTMLElement {
  const shadowHost = document.getElementById('shadow-host')?.shadowRoot;
  let shadowApp = shadowHost?.getElementById('shadow-app-sibling');
  shadowApp = shadowApp || document.body;
  return shadowApp;
}

/**
 * Modal Context to pass "variant" and "onRequestClose" to the subcomponents
 * when necessary
 */
export const ModalContext = createContext<
  Required<Pick<ModalProps, 'variant' | 'onRequestClose'>>
>({
  onRequestClose: () => {
    return;
  },
  variant: 'standard',
});

/**
 * `Modal` component that allows for simple dialogue modals, complex modals
 * with headers and footers, or full-screen modals.
 */
export function Modal({
  appId = 'app',
  background,
  centerByVisualViewport = false,
  children,
  className,
  contentLabel,
  'data-testid': testId,
  onAfterClose,
  onAfterOpen,
  onRequestClose,
  open,
  overflow = 'hidden',
  overlayClassName,
  overlayClose,
  parentId = 'parent',
  parentSelector,
  style,
  shouldReturnFocusAfterClose = true,
  topOverride,
  variant = 'standard',
}: ModalProps) {
  if (variant === 'full-screen' && !background) {
    background = 'grays-ultralight';
  } else if (!background) {
    background = 'grays-white';
  }

  const { keyboardHeight } = useVisualViewportHeight();
  /** Only offset by keyboard height when centerByVisualViewport=true */
  const onScreenKeyboardHeight = centerByVisualViewport ? keyboardHeight : 0;

  const contextValue = useMemo(
    () => ({ onRequestClose, variant }),
    [onRequestClose, variant]
  );

  return (
    <ModalContext.Provider value={contextValue}>
      <ReactModal
        // eslint-disable-next-line ssr-friendly/no-dom-globals-in-react-fc
        appElement={document.getElementById(appId) as HTMLElement}
        className={clsx(className, styles[variant], [
          styles.ReactModal__Content,
        ])}
        closeTimeoutMS={200}
        contentLabel={contentLabel}
        data-testid={testId}
        id={parentId}
        isOpen={open}
        onAfterOpen={() => {
          document.body.style.overflow = 'hidden';
          onAfterOpen?.();
        }}
        onAfterClose={() => {
          document.body.style.overflow = 'unset';
          onAfterClose?.();
        }}
        onRequestClose={onRequestClose}
        overlayClassName={clsx(
          styles.ReactModal__Overlay,
          {
            [styles['ReactModal__Overlay--isOpen']]: open,
            [styles['ReactModal__Overlay--isClosed']]: !open,
          },
          overlayClassName
        )}
        parentSelector={parentSelector || shadowAppSelector}
        shouldCloseOnOverlayClick={overlayClose ? true : false}
        shouldReturnFocusAfterClose={shouldReturnFocusAfterClose}
        style={{
          content: {
            '--keyboard-height': `${onScreenKeyboardHeight}px`,
            '--modal-background-color': getColorCSSVar(background),
            '--modal-color': getColorForBackgroundCSSVar(background),
            '--modal-overflow': overflow,
            '--modal-top': topOverride
              ? topOverride
              : `calc(50% - ${onScreenKeyboardHeight / 2}px)`,
            '--modal-transform': topOverride
              ? 'translateX(-50%)'
              : 'translate(-50%, -50%)',
            ...style,
          } as CSSProperties,
        }}
      >
        {children}
      </ReactModal>
    </ModalContext.Provider>
  );
}
