/**
 * DO NOT MODIFY THIS FILE!
 * It was created by the reefer-icons generator.
 * Please update & re-run the generator if need be.
 */
import type { SVGProps } from 'react';

import type { DynamicIconProps } from '../../../icon/icon.types';
import { Icon } from '../../icon';

const Merch24 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="100%"
    height="100%"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <g className="Merch 24" clipPath="url(#a)">
      <g className="SVG">
        <path
          fill="#4BA279"
          fillRule="evenodd"
          d="M9.498.953 7.143 8.736a1.075 1.075 0 0 1-.634.688l-3.47 1.375a3.206 3.206 0 0 0-1.806 4.18c.665 1.648 2.553 2.45 4.216 1.79l3.469-1.375a7.527 7.527 0 0 0 4.438-4.81l2.353-7.775a1 1 0 0 0-.672-1.248L10.74.284a1 1 0 0 0-1.243.669Zm7 7-2.355 7.783a1.075 1.075 0 0 1-.634.688l-3.47 1.375a3.206 3.206 0 0 0-1.806 4.18c.665 1.648 2.553 2.45 4.216 1.79l3.469-1.375a7.527 7.527 0 0 0 4.438-4.81l2.353-7.775a1 1 0 0 0-.672-1.248L17.74 7.284a1 1 0 0 0-1.242.669Z"
          className="Main"
          clipRule="evenodd"
        />
        <path
          fill="#000"
          fillOpacity={0.5}
          fillRule="evenodd"
          d="M9.498.953a1.01 1.01 0 0 1 .067-.166l6.18 1.854a.998.998 0 0 1-.036.168l-.53 1.75-6.208-1.862.527-1.744Zm-.78 2.58 6.208 1.863-.58 1.914-6.208-1.863.58-1.914Zm7.78 4.42a.993.993 0 0 1 .05-.132l6.191 1.857a1.003 1.003 0 0 1-.03.131l-.54 1.786-6.21-1.863.54-1.78Zm-.791 2.615 6.209 1.863-.58 1.914-6.208-1.863.579-1.914Z"
          className="Details"
          clipRule="evenodd"
        />
      </g>
    </g>
    <defs>
      <clipPath>
        <path fill="#fff" d="M0 0h24v24H0z" />
      </clipPath>
    </defs>
  </svg>
);

const SIZE_MAP = {
  md: Merch24,
  sm: Merch24,
  lg: Merch24,
  xl: Merch24,
  xxl: Merch24,
  xxxl: Merch24,
};

export const MerchIcon = ({
  size = 'md',
  'data-testid': testId,
  ...props
}: DynamicIconProps) => {
  return (
    <Icon
      data-testid={testId || 'merch-icon'}
      icon={SIZE_MAP[size]}
      {...props}
      size={size}
    />
  );
};
