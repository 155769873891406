import type { DeliveryValidation, Store } from '@jane/shared/models';

export default function minimumDeliveryOrder({
  discountedSubtotal,
  store,
  deliveryValidateData,
}: {
  deliveryValidateData?: DeliveryValidation;
  discountedSubtotal: number;
  store: Store;
}) {
  if (!store.delivery) return true;
  else if (deliveryValidateData)
    return discountedSubtotal >= (deliveryValidateData?.minimum || 0);
  else return true;
}
