import { useTheme } from '@emotion/react';
import { useNavigate } from 'react-router-dom';

import type { AppMode } from '@jane/shared/models';
import { useEcommApp } from '@jane/shared/providers';

import { closeModal } from '../../common/redux/application';
import { useCustomerDispatch } from '../../customer/dispatch';
import type { StoreAndCartProduct } from '../../customer/redux/cart';
import { replaceCart } from '../../customer/redux/cart';
import { useCustomerSelector } from '../../customer/selectors';
import { paths } from '../../lib/routes';
import Modal from '../modal';
import ReplaceCartContents from './contents';

const ReplaceCart = () => {
  const theme = useTheme();
  const dispatch = useCustomerDispatch();
  const navigate = useNavigate();
  const { appMode } = useEcommApp();
  const { replacementCart } = useCustomerSelector(
    ({ cart: { replacementCart } }) => ({
      replacementCart,
    })
  );

  const goToNextPath = (appMode: AppMode, cart: StoreAndCartProduct) => {
    if (appMode === 'default') {
      return dispatch(closeModal());
    }
    if (appMode === 'brandEmbed' && cart.store) {
      return navigate(paths.brandEmbedStore(cart.store));
    }

    return navigate(paths.embeddedMenu());
  };

  const onReplaceCart = () => {
    if (!replacementCart) {
      return;
    }
    dispatch(replaceCart(replacementCart));
    goToNextPath(appMode, replacementCart);
  };

  if (appMode === 'default') {
    return (
      <div>
        <Modal
          className="modal--small"
          contentLabel="Clear bag"
          css={{ borderRadius: theme?.borderRadius.sm }}
        >
          {(closeModal: () => void) => (
            <ReplaceCartContents
              onCancel={closeModal}
              onReplaceCart={onReplaceCart}
            />
          )}
        </Modal>
      </div>
    );
  }

  return (
    <div className="former-modal__container">
      <ReplaceCartContents
        onCancel={() =>
          replacementCart && goToNextPath(appMode, replacementCart)
        }
        onReplaceCart={onReplaceCart}
      />
    </div>
  );
};

export default ReplaceCart;
