import styled from '@emotion/styled';

import { spacing } from '@jane/shared/reefer-emotion';

export const StyledContainer = styled.div(({ theme }) => ({
  borderRadius: theme.borderRadius.lg,
  // Have to pass this as a prop, overwritten on marketplace by Store settings, but not on biz admin
  backgroundColor: theme.colors.primary.light,
  marginBottom: 24,
}));

export const StyledHeadingContainer = styled.div(spacing({ py: 20, px: 24 }), {
  display: 'flex',
  justifyContent: 'space-between',
  alignItem: 'center',
});

export const StyledContentContainer = styled.div({
  padding: '0 24px 24px',
});

export const StyledContent = styled.div({
  alignItems: 'center',
  a: {
    textDecoration: 'underline',
    color: '#06c',
  },
  // font style should match client/shared/stylesheets/components/react-quill.css
  p: {
    fontFamily: 'Jane Default, Source Sans Pro, Helvetica, Arial, sans-serif',
    fontSize: '14px',
  },
  '.ql-size-large': {
    fontFamily: 'Jane Default, Source Sans Pro, Helvetica, Arial, sans-serif',
    fontSize: '18px',
  },
  '.ql-size-small': {
    fontFamily: 'Jane Default, Source Sans Pro, Helvetica, Arial, sans-serif',
    fontSize: '13px',
  },
});
