import type { FilterItemProps } from '@jane/search/types';
import { formatCount } from '@jane/search/util';
import { truncateLabel } from '@jane/shared-ecomm/util';
import { Button, Flex, useMobileMediaQuery } from '@jane/shared/reefer';

import { useActiveFilterContext } from './activeFilterProvider';

export const FilterButton = ({
  filterKey,
  hideCounts,
  item,
  onChange,
}: FilterItemProps) => {
  const { label, icon, count, value } = item;
  const isMobile = useMobileMediaQuery({});
  const { activeFilters } = useActiveFilterContext();

  const labelLength = isMobile ? 20 : 40;

  const itemCount = !hideCounts ? ` (${formatCount(count)})` : '';

  const renderedLabel = `${truncateLabel(label, labelLength)}${itemCount}`;

  // prevents the icon from shrinking
  const iconToRender = <Flex data-testid="filter-button-icon">{icon}</Flex>;

  const isSelected = activeFilters.some((filter) => filter.value === value);

  return (
    <Button
      key={label}
      label={renderedLabel}
      startIcon={icon ? iconToRender : undefined}
      onClick={() => onChange(filterKey, value)}
      variant={isSelected ? 'tertiary-selected' : 'tertiary'}
      alignment="left"
      size="large"
      full
      disabled={!count}
    />
  );
};
