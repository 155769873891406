import type {
  CartStore,
  CustomerReservationDetailsStore,
  NoStore,
  Store,
  StoreHeaderStore,
} from '@jane/shared/models';

export const isNoStore = (
  x:
    | Store
    | CustomerReservationDetailsStore
    | StoreHeaderStore
    | CartStore
    | NoStore
): x is NoStore => 'noStore' in x;
