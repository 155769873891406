import type { SVGProps } from 'react';

import { Icon } from '../../icon/icon';
import type { StaticIconProps } from '../../icon/icon.types';

const Owl = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="64"
    height="64"
    viewBox="0 0 64 64"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <circle cx={32} cy={32} r={32} fill="#DADADA" />
    <ellipse cx={38} cy={35} rx={20} ry={22} fill="#0E0E0E" />
    <ellipse cx={26} cy={35} rx={20} ry={22} fill="#0E0E0E" />
    <path
      d="M44.9575 14.4768C42.7484 18.3031 37.4934 19.405 37.4934 19.405C37.4934 19.405 35.8202 14.3031 38.0293 10.4768C40.2384 6.65045 45.4934 5.54858 45.4934 5.54858C45.4934 5.54858 47.1666 10.6504 44.9575 14.4768Z"
      fill="#666666"
    />
    <path
      d="M19.0296 14.4768C21.2387 18.3031 26.4937 19.405 26.4937 19.405C26.4937 19.405 28.1669 14.3031 25.9578 10.4768C23.7486 6.65045 18.4937 5.54858 18.4937 5.54858C18.4937 5.54858 16.8204 10.6504 19.0296 14.4768Z"
      fill="#666666"
    />
    <ellipse cx={32} cy={35.5} rx={20} ry={26.5} fill="#666666" />
    <circle cx={21} cy={31} r={12} fill="white" />
    <circle cx={43} cy={31} r={12} fill="white" />
    <rect x={30} y={35} width={4} height={4} fill="white" />
    <path d="M32 45L26 37L38 37L32 45Z" fill="#FFC220" />
    <circle cx={43} cy={31} r={8} fill="#FFC220" />
    <circle cx={43} cy={31} r={4} fill="#0E0E0E" />
    <circle cx={45} cy={29} r={2} fill="white" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M22.7361 45.5753C22.9707 45.4296 23.279 45.5016 23.4247 45.7361C24.1922 46.9714 25.1376 47.5 26 47.5C26.8624 47.5 27.8078 46.9714 28.5753 45.7361C28.721 45.5016 29.0293 45.4296 29.2639 45.5753C29.4984 45.721 29.5704 46.0293 29.4247 46.2639C28.5354 47.6953 27.3092 48.5 26 48.5C24.6908 48.5 23.4647 47.6953 22.5753 46.2639C22.4296 46.0293 22.5016 45.721 22.7361 45.5753Z"
      fill="#262626"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M22.7361 53.5753C22.9707 53.4296 23.279 53.5016 23.4247 53.7361C24.1922 54.9714 25.1376 55.5 26 55.5C26.8624 55.5 27.8078 54.9714 28.5753 53.7361C28.721 53.5016 29.0293 53.4296 29.2639 53.5753C29.4984 53.721 29.5704 54.0293 29.4247 54.2639C28.5354 55.6953 27.3092 56.5 26 56.5C24.6908 56.5 23.4647 55.6953 22.5753 54.2639C22.4296 54.0293 22.5016 53.721 22.7361 53.5753Z"
      fill="#262626"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19.7361 49.5753C19.9707 49.4296 20.279 49.5016 20.4247 49.7361C21.1922 50.9714 22.1376 51.5 23 51.5C23.8624 51.5 24.8078 50.9714 25.5753 49.7361C25.721 49.5016 26.0293 49.4296 26.2639 49.5753C26.4984 49.721 26.5704 50.0293 26.4247 50.2639C25.5354 51.6953 24.3092 52.5 23 52.5C21.6908 52.5 20.4647 51.6953 19.5753 50.2639C19.4296 50.0293 19.5016 49.721 19.7361 49.5753Z"
      fill="#262626"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M28.7361 45.5753C28.9707 45.4296 29.279 45.5016 29.4247 45.7361C30.1922 46.9714 31.1376 47.5 32 47.5C32.8624 47.5 33.8078 46.9714 34.5753 45.7361C34.721 45.5016 35.0293 45.4296 35.2639 45.5753C35.4984 45.721 35.5704 46.0293 35.4247 46.2639C34.5354 47.6953 33.3092 48.5 32 48.5C30.6908 48.5 29.4647 47.6953 28.5753 46.2639C28.4296 46.0293 28.5016 45.721 28.7361 45.5753Z"
      fill="#262626"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M28.7361 53.5753C28.9707 53.4296 29.279 53.5016 29.4247 53.7361C30.1922 54.9714 31.1376 55.5 32 55.5C32.8624 55.5 33.8078 54.9714 34.5753 53.7361C34.721 53.5016 35.0293 53.4296 35.2639 53.5753C35.4984 53.721 35.5704 54.0293 35.4247 54.2639C34.5354 55.6953 33.3092 56.5 32 56.5C30.6908 56.5 29.4647 55.6953 28.5753 54.2639C28.4296 54.0293 28.5016 53.721 28.7361 53.5753Z"
      fill="#262626"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M25.7361 49.5753C25.9707 49.4296 26.279 49.5016 26.4247 49.7361C27.1922 50.9714 28.1376 51.5 29 51.5C29.8624 51.5 30.8078 50.9714 31.5753 49.7361C31.721 49.5016 32.0293 49.4296 32.2639 49.5753C32.4984 49.721 32.5704 50.0293 32.4247 50.2639C31.5354 51.6953 30.3092 52.5 29 52.5C27.6908 52.5 26.4647 51.6953 25.5753 50.2639C25.4296 50.0293 25.5016 49.721 25.7361 49.5753Z"
      fill="#262626"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M34.7361 45.5753C34.9707 45.4296 35.279 45.5016 35.4247 45.7361C36.1922 46.9714 37.1376 47.5 38 47.5C38.8624 47.5 39.8078 46.9714 40.5753 45.7361C40.721 45.5016 41.0293 45.4296 41.2639 45.5753C41.4984 45.721 41.5704 46.0293 41.4247 46.2639C40.5354 47.6953 39.3092 48.5 38 48.5C36.6908 48.5 35.4647 47.6953 34.5753 46.2639C34.4296 46.0293 34.5016 45.721 34.7361 45.5753Z"
      fill="#262626"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M34.7361 53.5753C34.9707 53.4296 35.279 53.5016 35.4247 53.7361C36.1922 54.9714 37.1376 55.5 38 55.5C38.8624 55.5 39.8078 54.9714 40.5753 53.7361C40.721 53.5016 41.0293 53.4296 41.2639 53.5753C41.4984 53.721 41.5704 54.0293 41.4247 54.2639C40.5354 55.6953 39.3092 56.5 38 56.5C36.6908 56.5 35.4647 55.6953 34.5753 54.2639C34.4296 54.0293 34.5016 53.721 34.7361 53.5753Z"
      fill="#262626"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M31.7361 49.5753C31.9707 49.4296 32.279 49.5016 32.4247 49.7361C33.1922 50.9714 34.1376 51.5 35 51.5C35.8624 51.5 36.8078 50.9714 37.5753 49.7361C37.721 49.5016 38.0293 49.4296 38.2639 49.5753C38.4984 49.721 38.5704 50.0293 38.4247 50.2639C37.5354 51.6953 36.3092 52.5 35 52.5C33.6908 52.5 32.4647 51.6953 31.5753 50.2639C31.4296 50.0293 31.5016 49.721 31.7361 49.5753Z"
      fill="#262626"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M37.7361 49.5753C37.9707 49.4296 38.279 49.5016 38.4247 49.7361C39.1922 50.9714 40.1376 51.5 41 51.5C41.8624 51.5 42.8078 50.9714 43.5753 49.7361C43.721 49.5016 44.0293 49.4296 44.2639 49.5753C44.4984 49.721 44.5704 50.0293 44.4247 50.2639C43.5354 51.6953 42.3092 52.5 41 52.5C39.6908 52.5 38.4647 51.6953 37.5753 50.2639C37.4296 50.0293 37.5016 49.721 37.7361 49.5753Z"
      fill="#262626"
    />
    <circle cx={21} cy={31} r={8} fill="#FFC220" />
    <circle cx={21} cy={31} r={4} fill="#0E0E0E" />
    <circle cx={23} cy={29} r={2} fill="white" />
  </svg>
);
export const OwlAvatar = ({ size = 'xl', ...props }: StaticIconProps) => {
  return <Icon icon={Owl} {...props} size={size} color={undefined} />;
};
