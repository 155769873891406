import type { Identification, StoreSpecial } from '@jane/shared/models';

import { isEligibleForGroup } from './qualifiedGroup';

export const isEligibleForGroupDiscount = ({
  document,
  special,
  timezone,
}: {
  document: Identification;
  special?: StoreSpecial;
  timezone: string;
}): boolean => {
  if (!(special && special.conditions.qualified_group)) return false;

  const requiredAge =
    'required_age' in special.conditions.qualified_group
      ? special.conditions.qualified_group.required_age
      : undefined;

  return isEligibleForGroup({
    document,
    timezone,
    groupType: special.conditions.qualified_group.type,
    requiredAge,
  });
};
