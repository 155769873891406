import type { LogoComponent } from '@jane/shared/reefer';
import {
  BankIcon,
  BoaLogo,
  Box,
  CapitalOneLogo,
  ChaseLogo,
  CitiLogo,
  PncLogo,
  UsaaLogo,
  WfLogo,
  useTabletMediaQuery,
} from '@jane/shared/reefer';

const BANK_NAMES_MAP: { [key: string]: LogoComponent } = {
  'Bank of America': BoaLogo,
  'Capital One': CapitalOneLogo,
  Chase: ChaseLogo,
  Citibank: CitiLogo,
  'PNC Bank, N.A.': PncLogo,
  USAA: UsaaLogo,
  'Wells Fargo Bank': WfLogo,
};

export const BankCircledIcon = ({ name }: { name: string }) => {
  const isMobile = useTabletMediaQuery({ width: 'max' });
  const BankCircledIcon = BANK_NAMES_MAP[name] || BankIcon;
  const hasLogo = !!BANK_NAMES_MAP[name];

  return (
    <Box
      height={isMobile ? 72 : 96}
      width={isMobile ? 72 : 96}
      minHeight={isMobile ? 72 : 96}
      minWidth={isMobile ? 72 : 96}
      p={hasLogo ? 8 : isMobile ? 16 : 24}
      border="grays-light"
      borderRadius="lg"
    >
      <BankCircledIcon height="auto" width="auto" altText={`${name}-icon`} />
    </Box>
  );
};
