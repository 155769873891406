import {
  ChevronRightIcon,
  Modal,
  Typography,
  fakeButtonProps,
  handleEnterKey,
  useMobileMediaQuery,
} from '@jane/shared/reefer';

import { ModalSectionButton } from './filterModal.styles';
import { CONTENT_DIVIDER_PROPS } from './modalSection';

export const ModalTypeaheadToggle = ({
  label,
  onClick,
}: {
  label: string;
  onClick: () => void;
}) => {
  const isMobile = useMobileMediaQuery({});

  return (
    <>
      <Modal.ContentDivider {...CONTENT_DIVIDER_PROPS} />
      <ModalSectionButton
        onClick={onClick}
        {...fakeButtonProps}
        onKeyUp={(event) => handleEnterKey(event, onClick)}
        data-testid="modal-section-toggle"
      >
        <Typography
          variant={isMobile ? 'body' : 'header-bold'}
          branded={!isMobile}
        >
          {label}
        </Typography>
        <ChevronRightIcon />
      </ModalSectionButton>
    </>
  );
};
