import type { FilterRangeItem, FilterSelectItem } from '@jane/search/types';

import { FilterButtonGrid } from '../filter.styles';
import { FilterButton } from '../filterButton';
import { FilterSlider } from '../filterSlider';
import { NestedRangeWrapper } from './filterModal.styles';
import type { FilterModalSectionProps } from './filterModal.types';
import { ModalSection } from './modalSection';

export const FilterModalSection = ({
  filterGroup,
  hideCounts,
  onChange,
  onDeselect,
}: FilterModalSectionProps) => {
  const { items, label, key, nested, type } = filterGroup;

  const itemsPerRow = label === 'Categories' ? 3 : label === 'Specials' ? 2 : 4;

  const isRange = type === 'range';

  const renderedItems = items.map((item) => {
    if (isRange) {
      const { key: itemKey, label: itemLabel } = item as FilterRangeItem;
      return (
        <FilterSlider
          hideCounts={hideCounts}
          item={item as FilterRangeItem}
          key={itemLabel}
          onChange={onChange}
          onDeselect={() => onDeselect(itemKey)}
        />
      );
    }

    return (
      <FilterButton
        filterKey={filterGroup.key}
        hideCounts={hideCounts}
        item={item as FilterSelectItem}
        key={item.label}
        onChange={onChange}
      />
    );
  });

  if (isRange) {
    return (
      <div data-testid="filter-modal-section">
        {nested ? (
          <NestedRangeWrapper>{renderedItems}</NestedRangeWrapper>
        ) : (
          <div>{renderedItems}</div>
        )}
      </div>
    );
  }
  return (
    <div data-testid="filter-modal-section">
      <ModalSection title={label} onDeselectClick={() => onDeselect(key)}>
        <FilterButtonGrid
          itemsPerRow={filterGroup.modalItemsPerRow || itemsPerRow}
          mobileItemsPerRow={2}
        >
          {renderedItems}
        </FilterButtonGrid>
      </ModalSection>
    </div>
  );
};
