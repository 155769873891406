/**
 * DO NOT MODIFY THIS FILE!
 * It was created by the reefer-icons generator.
 * Please update & re-run the generator if need be.
 */
import type { SVGProps } from 'react';

import type { StaticIconProps } from '../../../icon/icon.types';
import { Icon } from '../../icon';

const Pizza24 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="100%"
    height="100%"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <g className="Pizza 24">
      <g className="SVG">
        <path
          fill="#FFC220"
          d="M21.653 1c.559 0 1 .462 1 1.02v19.785c0 1.54-1.667 2.502-3 1.732l-16.9-9.757c-.965-.557-1.295-1.801-.648-2.708C6.457 4.975 13.591 1 21.653 1Z"
          className="Fill-Main"
        />
        <g className="Fill-Secondary">
          <path
            fill="#FFC220"
            d="M22.653 2.02a1.01 1.01 0 0 0-1-1.02C13.591 1 6.458 4.975 2.105 11.072c-.647.907-.317 2.15.648 2.708l1.712.988C7.955 8.918 14.346 5 21.653 5c.335 0 .669.008 1 .025V2.02Z"
          />
          <path
            fill="#000"
            fillOpacity={0.2}
            d="M22.653 2.02a1.01 1.01 0 0 0-1-1.02C13.591 1 6.458 4.975 2.105 11.072c-.647.907-.317 2.15.648 2.708l1.712.988C7.955 8.918 14.346 5 21.653 5c.335 0 .669.008 1 .025V2.02Z"
          />
        </g>
        <path
          fill="#FF6334"
          fillRule="evenodd"
          d="M17.153 12a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5Zm-5 5a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5Zm10.5-.5a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0Zm-3.585 3.75a2.5 2.5 0 0 1 .915 3.415l-4.33-2.5a2.5 2.5 0 0 1 3.415-.915Z"
          className="Fill-Accent"
          clipRule="evenodd"
        />
      </g>
    </g>
  </svg>
);

const SIZE_MAP = {
  md: Pizza24,
  sm: Pizza24,
  lg: Pizza24,
  xl: Pizza24,
  xxl: Pizza24,
  xxxl: Pizza24,
};

export const PizzaIcon = ({
  size = 'md',
  'data-testid': testId,
  ...props
}: StaticIconProps) => {
  return (
    <Icon
      data-testid={testId || 'pizza-icon'}
      icon={SIZE_MAP[size]}
      {...props}
      size={size}
      color={undefined}
    />
  );
};
