import styled from '@emotion/styled';

import { useUserPreferences } from '@jane/shared-ecomm/providers';
import type {
  Brand,
  BrandMediaTag,
  BrandMediaTagWithMediaCompliances,
  MediaCompliance,
} from '@jane/shared/models';
import { Box, useDesktopMediaQuery } from '@jane/shared/reefer';
import { mediaQueries } from '@jane/shared/reefer-emotion';

import { Carousel } from '../carousel';

interface BrandCarouselMediaProps {
  activeIndex?: number;
  brandMediaTags: readonly BrandMediaTag[];
  imageAlt: string;
  imageSrc: string;
  videoSrc?: string;
  videoTitle?: string;
}

const NoAssetsBackground = styled.div<{
  backgroundColor?: string;
  forceMobile?: boolean;
}>(({ backgroundColor = 'white', forceMobile }) => ({
  position: 'relative',
  height: !forceMobile ? 192 : 96,
  backgroundSize: 'cover',
  backgroundPosition: '50% 50%',
  backgroundColor,
  [mediaQueries.mobile('lg', 'min')]: {
    height: 192,
  },
}));

const buildBrandMediaItems = (brand: Brand): BrandCarouselMediaProps[] => {
  const { custom_images = [], custom_video = null, name } = brand;

  const brandMediaItems = [...custom_images]
    .sort((a, b) => a.position - b.position)
    .map((image) => ({
      brandMediaTags: image.brand_media_tags,
      imageSrc: image.image_url,
      imageAlt: `${name} brand page image`,
    }));

  if (custom_video?.overlay_image_url) {
    const video = {
      imageSrc: custom_video.overlay_image_url,
      videoSrc: custom_video.video_url,
      videoTitle: custom_video.overlay_text || undefined,
      imageAlt: `${name} brand page video`,
      brandMediaTags: custom_video.brand_media_tags,
    };
    if (custom_video.position) {
      brandMediaItems.splice(custom_video.position, 0, video);
    } else {
      brandMediaItems.unshift(video);
    }
  }
  return brandMediaItems;
};

export const BrandMediaCarousel = ({
  brand,
  forceMobile,
  mediaTags,
  responsive,
}: {
  brand: Brand;
  forceMobile?: boolean;
  mediaTags: readonly BrandMediaTagWithMediaCompliances[];
  responsive?: boolean;
}) => {
  const isDesktop = useDesktopMediaQuery({ size: 'sm' });
  const brandMediaItems = buildBrandMediaItems(brand);
  const {
    userLocation: { cityState },
  } = useUserPreferences();

  const userState = cityState?.split(', ')[1];

  const filteredBrandMediaItems = brandMediaItems.filter((image) =>
    image.brandMediaTags?.every((tag) => {
      const correspondingMediaTag = mediaTags.find(
        (mediaTag: BrandMediaTag) => mediaTag.id === tag.id
      );
      if (correspondingMediaTag) {
        return correspondingMediaTag.media_compliances.every(
          (compliance: MediaCompliance) =>
            compliance.hide_in_state !== userState
        );
      }
      return true;
    })
  );

  if (filteredBrandMediaItems.length < 1) {
    return (
      <NoAssetsBackground
        data-testid="no-assets-background"
        backgroundColor={brand.color_hex || undefined}
        forceMobile={forceMobile}
        role="img"
      />
    );
  }

  return (
    <Box
      width="100%"
      height={isDesktop ? (forceMobile ? '240px' : '420px') : '480px'}
    >
      <Carousel
        items={filteredBrandMediaItems}
        variant="brand"
        desktopHeight={forceMobile ? '240px' : '420px'}
        mobileHeight="480px"
        forceMobile={forceMobile}
        responsive={responsive}
      />
    </Box>
  );
};
