/**
 * sort IDs are used to differentiate between different sort options using the same index suffix
 * for product search, they should match ads-server's expected IDs
 */
export enum FilterSortId {
  AZ = 'a-z',
  Default = 'default',
  PriceAsc = 'price-asc',
  PriceDesc = 'price-desc',
  Rating = 'rating',
  Recommended = 'recommended',
  ThcAsc = 'thc-asc',
  ThcDesc = 'thc-desc',
  Upvote = 'upvote',
}

export interface FilterSortOption {
  id: FilterSortId;
  isDefault: boolean; // there must be exactly one default for a set of options
  label: string;
  value: string;
}

export interface CurrentSort {
  id: FilterSortId;
  isDefault?: true;
  suffix: string;
}

export const makeCurrentSort = (
  selectedOption: FilterSortOption
): CurrentSort => {
  const currentSort: CurrentSort = {
    id: selectedOption.id,
    suffix: selectedOption.value,
  };
  if (selectedOption.isDefault) {
    currentSort.isDefault = selectedOption.isDefault;
  }
  return currentSort;
};
