import type { TerpeneEnum } from '@jane/shared/types';

export const TERPENE_DETAILS: { [Terpene in TerpeneEnum]?: string } = {
  limonene: `Limonene is the second most common terpene in nature and is abundant in citrus. The aroma and flavor is that of citrus fruit. This is a powerful terpene that can promote relaxation with its uplifting and mood enhancing qualities. Many studies confirm that limonene can also assist with nervousness, plus can be an incredible antioxidant.`,
  nerolidol: `Nerolidol is a cannabis sesquiterpene and is found in citrus peels. The potential therapeutic effects of Nerolidol include ease of penetration through the skin. Evidence of potential antifungal properties and antioxidant properties are strong with Nerolidol and for those needing rest Nerolidol can be a natural sedative.`,
  caryophyllene: `Caryophyllene smells like black pepper and musk. The taste can be spicy, specifically peppery. Caryophyllene can produces beneficial effects for muscle relief, sleeplessness, and nervousness. Caryophyllene is commonly found in black pepper.`,
  linalool: `Linalool is abundant in lavender and has a very floral aroma. In studies linalool can produce sedative effects when inhaled and can be perfect monoterpene pairing for rest, sleep, nervousness and muscle pain. Linalool is found in lavender cannabis, Bergamot and other cultivars like OG Kush, L.A. Confidential, Black Mamba, and Purple family of strains like Mendo Purps and Purple Urkle.`,
  humulene: `Humulene smells like earthy hops, with a woody and spicy flavor. It is commonly found in basil, coriander, and hops. Humulene can help with pain management.`,
  myrcene: `Myrcene is a signature terpene found in fresh cannabis, hops and lemongrass. The effects can be relaxing and can assist in inducing sleep. Myrcene in cannabis is associated with the “couch lock” effect and can be detected by its herbal and earthy aroma, plus woody and spicy flavor. Myrcene is present in Kush genetics, Cookies, and Chemdawg.`,
  pinene: `Pinene is the most abundant terpene in nature. Commonly rich in evergreen trees and botanical essential oils serving plants by repelling insects with their profound pine aromas. Pinene is found in pine, rosemary and parsley. The effects can promote breathing and aid in mild memory problems.`,
  eucalyptol: `Eucalyptol is the dominant terpene found in eucalyptus oil and can demonstrate potential powerful antiinflammatory effects by promoting healthy repiratory function. Eucalyptol is found in eucalyptus but also in bay leaves and at times the aroma can resemble a strong mint with a spicy cooling effect.`,
  terpinolene: `Terpinolene is an earthy terpene found in pine, lilac and nutmeg. The aroma is floral with a woody citrus edge. Cannabis cultivars will develop different spectrums of terpenes to express their unique terroirs in various geographical regions. The presence of terpinolene is one indicator to distinguish unique cultivars from all over the world. Terpinolene can be a strong antioxidant and can produce sedative effects plus can have effective transdermal applications.`,
  bisabolol: `Bisabolol is an herbal terpene commonly found in German chamomile. The aroma is floral and sweet with similar citrus flavors and a chamomile finish. Bisabolol can help with inflammation and is commonly found in cannabis cultivars like Green Crack, Strawberry Cough and Durban Poison.`,
  borneol: `Borneol is a terpene that smells like pine and wood found in cannabis and many other plants. Chinese herbalists have utilized the therapeutic effects of Borneol to promote activity in the central nervous system.`,
  ocimene: `Ocimene is a special terpene associated with fruits like strawberries, mangos, and herbs like mint and basil. The aromas are sweet, herbal, sometimes sparking memories of sacred woods and incense. Flavors of Ocimene are sweet with a slight citrus essence in some plants. Ocimene is covetted in creating perfumes due to its sweet scent. It can promote healthy nasal breathing, while also serving as a natural antibacterial and antifungal agent.`,
};
