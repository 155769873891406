import { trackFailedToTrackImpression } from '@jane/dm/internal';
import { config } from '@jane/shared/config';
import type {
  AdData,
  AdFlight,
  AppMode,
  ClickKind,
  DmMeta,
  Id,
  MenuProduct,
  Zone,
} from '@jane/shared/models';
import type { Nullable } from '@jane/shared/types';
import { isInMaintenance, maintenanceReload } from '@jane/shared/util';

import { EventNames } from '../eventNames';
import { track } from '../track';

export const clickTrackingUrl = () => `${config.dmUrl}/client_events/2`;
export const impressionTrackingUrl = () => `${config.dmUrl}/client_events/1`;

export type TrackImpressedRowAdProps = {
  appMode: AppMode;
  currentCycleIndex: number;
  flight: AdFlight;
  isDesktop: boolean;
  janeDeviceId: string | undefined;
  productIds: number[] | null;
  productIdsWithDmMeta: Record<string, DmMeta> | undefined;
  storeId: Id;
  userId?: number;
  zone: Zone;
};
export const trackImpressedRow = (props: TrackImpressedRowAdProps) => {
  const {
    userId,
    storeId,
    currentCycleIndex,
    janeDeviceId,
    flight,
    appMode,
    zone,
    productIds: positionedProductIds,
    productIdsWithDmMeta,
  } = props;

  const adTokens: string[] = [];
  const myHighDs: string[] = [];
  if (productIdsWithDmMeta !== undefined) {
    Object.entries(productIdsWithDmMeta).forEach(([pid, dmMeta]) => {
      if (dmMeta.adToken !== undefined) {
        adTokens.push(dmMeta.adToken);
      }
      if (dmMeta.myHighD !== undefined) {
        myHighDs.push(dmMeta.myHighD);
      }
    });
  }

  trackImpression({
    adTokens: adTokens,
    adUnit: flight.ad_unit,
    appMode,
    creativeIds: flight.creative_ids,
    currentCycleIndex,
    data_1: flight.data_1,
    distinctId: janeMixpanel?.get_distinct_id(),
    flightId: flight.id,
    flightType: flight.flight_type,
    janeDeviceId,
    kevelToken: flight.kevel_token,
    model: flight.model,
    myHighDs: myHighDs,
    positionedProductIds,
    productBrand: flight.product_brand.name,
    productBrandId: flight.product_brand.id,
    storeId,
    userId,
    zone,
  });
};

export interface TrackImpressedInlineProductProps {
  adToken: string;
  appMode: AppMode;
  flight: MenuProduct['flight'] | undefined;
  janeDeviceId: string;
  myHighD: string | undefined;
  placementRow: string;
  product: MenuProduct;
  storeId: Id;
  userId: number | undefined;
  zone: Zone;
}
export const trackImpressedInlineProduct = async ({
  adToken,
  appMode,
  flight,
  janeDeviceId,
  myHighD,
  placementRow,
  product,
  storeId,
  userId,
  zone,
}: TrackImpressedInlineProductProps) => {
  if (!adToken) {
    return;
  }
  return trackImpression({
    adToken,
    appMode,
    data_1: flight?.data_1,
    distinctId: janeMixpanel?.get_distinct_id(),
    flightId: flight?.id,
    flightType: flight?.flight_type,
    janeDeviceId,
    kevelToken: flight?.kevel_token,
    model: flight?.model,
    myHighD,
    placementRow,
    productBrand: product.brand ?? undefined,
    productBrandId: product.product_brand_id
      ? Number(product.product_brand_id)
      : undefined,
    productId: product.product_id || product.id,
    storeId,
    userId,
    zone,
  });
};

export interface TrackImpressedAdPayload {
  adToken?: string; //
  adTokens?: string[] | undefined; // This is currently only used for row ads
  adUnit?: string; //
  appMode: AppMode; //
  creativeIds?: number[];
  currentCycleIndex?: number;
  data_1?: string;
  distinctId: string | undefined;
  flightId?: number;
  flightType?: string;
  janeDeviceId: string | undefined;
  kevelToken: string | undefined;
  model?: string;
  myHighD?: string;
  myHighDs?: string[] | undefined; // This is currently only used for row ads
  placementRow?: string;
  positionedProductIds?: number[] | null;
  productBrand: string | undefined;
  productBrandId: number | undefined;
  productId?: number;
  storeId: Id;
  userId?: number;
  zone: Zone;
}
const trackImpression = async (payload: TrackImpressedAdPayload) => {
  try {
    const response = await fetch(impressionTrackingUrl(), {
      body: JSON.stringify(payload),
      headers: {
        Accept: 'application/json',
        'Content-Type': 'text/plain',
      },
      method: 'POST',
    });
    if (!response.ok) {
      trackFailedToTrackImpression({
        adToken: payload.adToken,
        error: response.statusText,
        productBrandId: payload.productBrandId,
        productId: payload.productId,
      });
    }
    if (isInMaintenance(response)) {
      maintenanceReload();
    }
  } catch (error) {
    // do nothing
  }
};

export type TrackClickedAdProps = {
  appMode?: AppMode;
  clickKind?: ClickKind;
  dmMeta?: DmMeta;
  flight?: Nullable<AdData['flight']>;
  janeDeviceId: string;
  menuProductBrandId?: Nullable<number>;
  productId?: number;
};
export type TrackClickedAdPayload = {
  adToken?: string;
  clickKind?: ClickKind;
  flightId?: number;
  janeDeviceId: string;
  kevelToken?: string;
  model?: 'cpc' | 'cpm';
  myHighD?: string;
  productBrandId?: number;
};
export const trackClickedAd = ({
  appMode,
  clickKind = 'product_click',
  dmMeta,
  flight,
  janeDeviceId,
}: TrackClickedAdProps) => {
  if (!janeDeviceId || (!dmMeta?.adToken && !flight)) {
    return;
  }

  const adClickPayload: TrackClickedAdPayload = {
    adToken: dmMeta?.adToken,
    clickKind: clickKind,
    flightId: flight?.id,
    janeDeviceId,
    kevelToken: flight?.kevel_token,
    model: flight?.model,
    myHighD: dmMeta?.myHighD,
    productBrandId: flight?.product_brand.id,
  };

  fetch(clickTrackingUrl(), {
    body: JSON.stringify(adClickPayload),
    headers: {
      Accept: 'application/json',
      'Content-Type': 'text/plain',
    },
    method: 'POST',
  })
    .then((response) => {
      if (isInMaintenance(response)) {
        maintenanceReload();
        return;
      }

      if (flight) {
        track({
          ...(appMode && { appMode }),
          dmMeta,
          event: EventNames.ClickedAd,
          flightProps: flight,
          janeDeviceId,
        });
      }
    })
    .catch((error) => {
      if (flight) {
        track({
          dmMeta,
          error,
          event: EventNames.ClickedAdError,
          flightProps: flight,
          janeDeviceId,
        });
      }
    });
};
