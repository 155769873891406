import { useEffect, useRef } from 'react';

// with permission from d.abramov:
// https://overreacted.io/making-setinterval-declarative-with-react-hooks/
export const useInterval = (
  callback: () => void,
  delay: number | null,
  clearIntervalCounter = 0
) => {
  const savedCallback = useRef(() => {
    return;
  });
  const currentInterval = useRef<ReturnType<typeof setTimeout>>();

  useEffect(() => {
    savedCallback.current = callback;
  });

  useEffect(() => {
    if (delay !== null) {
      if (currentInterval.current) clearInterval(currentInterval.current);

      const interval = setInterval(() => savedCallback.current(), delay);
      currentInterval.current = interval;

      return () => clearInterval(interval);
    }
  }, [delay, clearIntervalCounter]);
};
