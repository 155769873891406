/**
 * DO NOT MODIFY THIS FILE!
 * It was created by the reefer-icons generator.
 * Please update & re-run the generator if need be.
 */
import type { SVGProps } from 'react';

import type { StaticIconProps } from '../../../icon/icon.types';
import { Icon } from '../../icon';

const Soil24 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="100%"
    height="100%"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <g className="Soil 24">
      <path
        fill="#4BA279"
        fillRule="evenodd"
        d="M3.212.285c-.702 2.105-.06 4.15 1.4 5.61 1.1 1.1 2.53 1.735 4.067 1.683l.07.124c1.08 1.958 2.117 4.587 2.12 7.428v.012h2.262v-.011c.002-2.937.67-5.483 1.343-7.3.32-.862.637-1.554.881-2.04.705-.333 1.263-.91 1.624-1.63.58-1.16.556-2.508-.281-3.623-1.396-.002-2.488.786-3.069 1.946-.37.74-.495 1.556-.319 2.34a22.59 22.59 0 0 0-.957 2.222 24.32 24.32 0 0 0-.572 1.744 21.863 21.863 0 0 0-1.306-2.628c.19-1.692-.457-3.28-1.653-4.476-1.46-1.46-3.506-2.103-5.61-1.4Z"
        className="Fill-Accent"
        clipRule="evenodd"
      />
      <g className="Fill-Main">
        <path
          fill="#F90"
          fillRule="evenodd"
          d="M.693 12.692c.031-.01 4.546-1.507 11.307-1.507 6.76 0 11.276 1.497 11.308 1.507C23.308 18.937 18.245 24 12 24S.692 18.937.692 12.692Z"
          clipRule="evenodd"
        />
        <path
          fill="#000"
          fillOpacity={0.2}
          fillRule="evenodd"
          d="M.693 12.692c.031-.01 4.546-1.507 11.307-1.507 6.76 0 11.276 1.497 11.308 1.507C23.308 18.937 18.245 24 12 24S.692 18.937.692 12.692Z"
          clipRule="evenodd"
        />
      </g>
      <path
        fill="#000"
        fillOpacity={0.5}
        fillRule="evenodd"
        d="M8.608 16.273a1.13 1.13 0 1 1-2.262 0 1.13 1.13 0 0 1 2.262 0Zm4.523 3.392a1.13 1.13 0 1 1-2.262 0 1.13 1.13 0 0 1 2.262 0Zm3.392-2.261a1.13 1.13 0 1 0 0-2.262 1.13 1.13 0 0 0 0 2.262Z"
        className="Fill-Seconday"
        clipRule="evenodd"
      />
    </g>
  </svg>
);

const SIZE_MAP = {
  md: Soil24,
  sm: Soil24,
  lg: Soil24,
  xl: Soil24,
  xxl: Soil24,
  xxxl: Soil24,
};

export const SoilIcon = ({
  size = 'md',
  'data-testid': testId,
  ...props
}: StaticIconProps) => {
  return (
    <Icon
      data-testid={testId || 'soil-icon'}
      icon={SIZE_MAP[size]}
      {...props}
      size={size}
      color={undefined}
    />
  );
};
