/**
 * DO NOT MODIFY THIS FILE!
 * It was created by the reefer-icons generator.
 * Please update & re-run the generator if need be.
 */
import type { SVGProps } from 'react';

import type { StaticIconProps } from '../../../icon/icon.types';
import { Icon } from '../../icon';

const Lemon24 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="100%"
    height="100%"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <g className="Lemon 24" clipPath="url(#a)">
      <path
        fill="#FFC220"
        d="M21.73 2.27c2.703 2.703.796 2.92 1.315 4.09 1.935 4.365.915 9.898-2.936 13.75-3.852 3.85-9.385 4.87-13.749 2.936-1.171-.52-3.19-.414-4.09-1.315-.901-.901-.796-2.92-1.315-4.09C-.98 13.275.04 7.742 3.89 3.89 7.743.042 13.276-.978 17.64.956c1.17.52 1.387-1.388 4.09 1.315Z"
        className="Fill-Main"
      />
      <g className="Vector 156 (Stroke)">
        <path
          fill="#FFC220"
          fillRule="evenodd"
          d="M5.513 7.135a1.147 1.147 0 0 1 0-1.621c1.39-1.39 3.21-2.289 4.993-2.735 1.77-.442 3.623-.467 5.1.025a1.147 1.147 0 0 1-.725 2.176c-.955-.319-2.346-.344-3.82.024-1.46.366-2.884 1.09-3.926 2.131a1.147 1.147 0 0 1-1.622 0Z"
          clipRule="evenodd"
        />
        <path
          fill="#fff"
          fillOpacity={0.8}
          fillRule="evenodd"
          d="M5.513 7.135a1.147 1.147 0 0 1 0-1.621c1.39-1.39 3.21-2.289 4.993-2.735 1.77-.442 3.623-.467 5.1.025a1.147 1.147 0 0 1-.725 2.176c-.955-.319-2.346-.344-3.82.024-1.46.366-2.884 1.09-3.926 2.131a1.147 1.147 0 0 1-1.622 0Z"
          clipRule="evenodd"
        />
      </g>
    </g>
    <defs>
      <clipPath>
        <path fill="#fff" d="M0 0h24v24H0z" />
      </clipPath>
    </defs>
  </svg>
);

const SIZE_MAP = {
  md: Lemon24,
  sm: Lemon24,
  lg: Lemon24,
  xl: Lemon24,
  xxl: Lemon24,
  xxxl: Lemon24,
};

export const LemonIcon = ({
  size = 'md',
  'data-testid': testId,
  ...props
}: StaticIconProps) => {
  return (
    <Icon
      data-testid={testId || 'lemon-icon'}
      icon={SIZE_MAP[size]}
      {...props}
      size={size}
      color={undefined}
    />
  );
};
