import clsx from 'clsx';

import type { DrawerContentDividerProps } from '../drawer.types';
import styles from './drawerContent.module.css';

export function DrawerContentDivider({
  className,
  'data-testid': testId,
  id,
}: DrawerContentDividerProps) {
  return (
    <hr
      className={clsx(className, styles.drawerContentDivider)}
      data-testid={testId}
      id={id}
    ></hr>
  );
}
