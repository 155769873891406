import { nanoid } from 'nanoid';
import { useEffect, useMemo, useRef } from 'react';
import type { RefObject } from 'react';

import { useIframeResizerRegistry } from '@jane/shared-ecomm/providers';
import {
  debouncedPostMessageToIframeParent,
  postMessageToIframeParent,
} from '@jane/shared/util';

const useResizeIframe = (
  containerElement: RefObject<any>,
  options: { autoRegister?: boolean; isModal: boolean } = {
    autoRegister: true,
    isModal: false,
  }
) => {
  const prevHeight = useRef(0);

  const messageType = options.isModal ? 'resizeIFrameForModal' : 'resizeIFrame';
  const { activeResizer, registerResizer, unregisterResizer } =
    useIframeResizerRegistry();

  const id = useMemo(() => nanoid(), []);

  useEffect(() => {
    if (options.autoRegister) {
      registerResizer(id);
    }

    return () => unregisterResizer(id);
  }, []);

  const handleRegisterResizer = () => registerResizer(id);

  const handleUnRegisterResizer = () => unregisterResizer(id);

  useEffect(() => {
    let currentInterval: NodeJS.Timer | null;
    if (activeResizer === id) {
      if (containerElement.current && containerElement.current.scrollHeight) {
        postMessageToIframeParent({
          messageType,
          payload: containerElement.current.scrollHeight,
        });
      }

      if (!currentInterval) {
        currentInterval = setInterval(() => {
          // NOTE(elliot): Resetting prevHeight allows resizing when the ref is removed and re-added
          if (!containerElement.current && prevHeight.current !== 0) {
            prevHeight.current = 0;
          }

          if (
            containerElement.current &&
            containerElement.current.scrollHeight !== prevHeight.current
          ) {
            // (CU-3ujh47z): Excessive resize requests were overwriting the automatic scroll feature
            // Added debouncing to reduce these requests.
            debouncedPostMessageToIframeParent({
              messageType,
              payload: containerElement.current.scrollHeight,
            });
            prevHeight.current = containerElement.current.scrollHeight;
          }
        }, 200);
      }
    }

    return () => {
      if (activeResizer !== id) {
        debouncedPostMessageToIframeParent.cancel();
      }
      if (currentInterval && activeResizer !== id) {
        clearInterval(currentInterval);
      }
    };
  }, [activeResizer]);

  return {
    registerResizer: handleRegisterResizer,
    unregisterResizer: handleUnRegisterResizer,
  };
};

export default useResizeIframe;
