import type { PriceId, WeightSelectorWeight } from '@jane/shared/models';
import { Flex } from '@jane/shared/reefer';

import SvgIcon from '../../legacyIcons/legacySvgIcon';
import {
  DisplayMode,
  useProductCardContext,
} from '../../productCardProvider/productCardProvider';
import { VariantSelectionButton } from './variantSelectionButton';
import WeightItems, { CheckIconWrapper, StyledGrid } from './weightItems';

interface WeightSelectorContainerProps {
  isSoldByWeight: boolean;
  noSelectedWeightDefault: boolean;
  onWeightSelected: (selectedWeight: PriceId) => void;
  selectedWeight: PriceId;
  weights: WeightSelectorWeight[];
}
const WeightSelectorContainer = ({
  isSoldByWeight,
  noSelectedWeightDefault,
  onWeightSelected,
  selectedWeight,
  weights,
}: WeightSelectorContainerProps) => {
  const {
    cartProduct,
    disableInteraction,
    displayMode,
    menuProduct,
    setDisplayMode,
  } = useProductCardContext();

  if (isSoldByWeight && menuProduct) {
    return (
      <Flex
        alignItems="center"
        onClick={() => {
          if (disableInteraction) return;
          setDisplayMode(DisplayMode.Edit);
        }}
        ariaLabel="edit"
        width="100%"
        height="100%"
      >
        <WeightItems
          cartWeights={cartProduct?.map((product) => product.price_id)}
          menuProduct={menuProduct}
          selectedWeight={noSelectedWeightDefault ? null : selectedWeight}
          onChange={onWeightSelected}
          weights={weights}
          editingMode={displayMode === DisplayMode.Edit}
        />
      </Flex>
    );
  }

  return (
    <Flex alignItems="center" width="100%" height="100%">
      <StyledGrid
        onClick={() => {
          if (disableInteraction) return;
          setDisplayMode(DisplayMode.Edit);
        }}
        ariaLabel="edit"
      >
        <VariantSelectionButton
          onChange={() => onWeightSelected('each')}
          selected={displayMode === DisplayMode.Edit}
          weight="each"
        />
        {(cartProduct || []).length > 0 && (
          <CheckIconWrapper>
            <SvgIcon size={18} icon="circleCheckFilled" />
          </CheckIconWrapper>
        )}
      </StyledGrid>
    </Flex>
  );
};

export default WeightSelectorContainer;
