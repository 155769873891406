import dayjs from 'dayjs';
import jwtDecode from 'jwt-decode';

import {
  SessionStorage,
  isBrowserStorageAvailable,
  timeIsSameOrAfter,
} from '@jane/shared/util';

import { AuthStorage } from './authStorage';

function storeJwt(token: string, headless: boolean) {
  if (headless && isBrowserStorageAvailable()) {
    SessionStorage.set('jwt', token);
    AuthStorage.remove('jwt');
  } else {
    AuthStorage.set('jwt', token);
  }
}

export function setJwt(jwt: string, headless = false) {
  if (jwt) {
    storeJwt(jwt, headless);
  }
}

export function getJwt() {
  const jwt = AuthStorage.get<string>('jwt');

  if (!jwt && isBrowserStorageAvailable()) {
    return SessionStorage.get('jwt');
  }

  return jwt;
}

export function removeJwt() {
  AuthStorage.remove('jwt');

  if (isBrowserStorageAvailable()) {
    SessionStorage.remove('jwt');
  }
}

export const parseToken = (token: string) =>
  token.includes('Bearer') ? token.split('Bearer ')[1] : token;

export const tokenIsExpired = (token: string, msFromNow: number) => {
  const { exp } = jwtDecode(parseToken(token)) as { exp: number };
  return !timeIsSameOrAfter(dayjs(exp, 'X'), dayjs().add(msFromNow, 'ms'));
};
