import { numberRegex } from '../../../utils';
import type { NumberFieldProps } from '../fields/field.types';
import { NumberFieldInternal } from '../fields/numberField/numberField';
import type { FormFieldProps } from '../formField.types';
import { FormFieldWrapper } from '../formFieldWrapper/formFieldWrapper';

export interface FormNumberFieldProps
  extends Omit<NumberFieldProps, 'children'>,
    Omit<FormFieldProps, 'maxLength' | 'minLength'> {}

/**
 * Number fields allow users to enter numeric values into a UI.
 *
 * Use this component *within forms* for inputs of `type`:
 * `number`.
 *
 * **NOTE: DO NOT USE THIS COMPONENT OUTSIDE FORMS**
 *
 * *For a similar component for use outside forms, see [`NumberField`](/story/components-forms-fields-numberfield--default).*
 */
export function FormNumberField({
  allowedDecimalPlaces,
  allowLeadingZeros,
  autocomplete,
  autoFocus = false,
  defaultValue,
  disabled = false,
  endUnit,
  errorMessage = 'Please enter a numeric value.',
  errorOnBlur = false,
  helperText,
  label,
  labelHidden = false,
  maskFormat,
  max,
  min,
  name,
  onBlur,
  onChange,
  pattern = numberRegex,
  placeholder,
  readOnly = false,
  required = false,
  shouldUnregister,
  startUnit,
  step = 'any',
  'data-testid': testId,
  validate,
  width = 'auto',
  ...props
}: FormNumberFieldProps) {
  return (
    <FormFieldWrapper
      defaultValue={defaultValue}
      errorMessage={errorMessage}
      errorOnBlur={errorOnBlur}
      name={name}
      max={max}
      min={min}
      pattern={pattern}
      render={({ children, field }) => (
        <NumberFieldInternal
          allowedDecimalPlaces={allowedDecimalPlaces}
          allowLeadingZeros={allowLeadingZeros}
          autoFocus={autoFocus}
          defaultValue={defaultValue}
          autocomplete={autocomplete}
          disabled={disabled}
          helperText={helperText}
          maskFormat={maskFormat}
          label={label}
          labelHidden={labelHidden}
          name={field.name}
          onBlur={(value?: number) => {
            onBlur && onBlur(value);
            field.onBlur();
          }}
          onChange={(value?: number) => {
            onChange && onChange(value);
            field.onChange(value === undefined ? '' : value);
          }}
          onUpdate={onChange}
          placeholder={placeholder}
          readOnly={readOnly}
          ref={field.ref}
          step={step}
          startUnit={startUnit}
          endUnit={endUnit}
          data-testid={testId}
          value={field.value}
          width={width}
          {...props}
        >
          {children}
        </NumberFieldInternal>
      )}
      required={required}
      shouldUnregister={shouldUnregister}
      validate={validate}
    />
  );
}
