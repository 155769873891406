import { z } from 'zod';

import { zId } from './id';

const tProductBrand = z.intersection(
  z.object({ id: zId }),
  z.object({ name: z.string() }).partial()
);

export const zAdFlight = z.intersection(
  z.object({
    id: zId,
    ad_unit: z.string(),
    creative_ids: z.array(z.number()),
    custom_title: z.string(),
    // data_1 is the base64 encoded revenue of the impression. Revenue per impression is meaningful for CPM.
    // For non CPM model, e.g. CPC, revenue per impression would always be 0.
    // Because tAdData is returned to the browser, we hide the revenue behind non-obvious name "data_1" and encoding.
    data_1: z.string(),
    flight_type: z.string(),
    kevel_token: z.string(),
    model: z.enum(['cpc', 'cpm']),
    product_brand: tProductBrand,
  }),
  z
    .object({
      keywords: z.array(z.string()),
    })
    .partial()
);

export const zDmMeta = z
  .object({
    adToken: z.string(),
    myHighD: z.string(),
  })
  .partial();
