import * as t from 'io-ts';

import type { DeepReadonly } from './deepReadonly';

const tPosCartTotals = t.interface({
  delivery_fee: t.union([t.number, t.null]),
  total: t.union([t.number, t.null]),
  rounding_amount: t.union([t.number, t.null]),
  service_fee: t.union([t.number, t.null]),
  sales_tax: t.union([t.number, t.null]),
  store_tax: t.union([t.number, t.null]),
});

export const tPosCart = t.partial({
  totals: t.union([t.null, tPosCartTotals]),
});

export type PosCart = DeepReadonly<t.TypeOf<typeof tPosCart>>;

export type PosCartTotals = DeepReadonly<t.TypeOf<typeof tPosCartTotals>>;
