import omit from 'lodash/omit';
import omitBy from 'lodash/omitBy';
import pick from 'lodash/pick';

import type { JaneSearchState } from '@jane/search/types';
import { encodeQuery, parseSearch } from '@jane/shared/util';

import { instantSearchStateToSearchState } from './instantSearchMapping';

type QueryParams = Record<string, any>;

const INSTANTSEARCH_STATE_KEYS = ['refinementList', 'multiRange', 'range'];

export const JANE_SEARCH_STATE_KEYS = [
  'bucketFilters',
  'currentSort',
  'filters',
  'page',
  'rangeFilters',
  'searchText',
];

export const isInstantSearchState = (searchState: QueryParams) =>
  'refinementList' in searchState ||
  'multiRange' in searchState ||
  'range' in searchState;

/**
 * Parses the initial location query and returns the search state
 *
 * - Removes any non-search params
 * - Removes the default sort or any incomplete sort params
 * - If instanstearch keys are provided, maps them to the new
 *   search state keys
 */
export const searchStateFromLocationQuery = (locationSearch: string) => {
  const searchParams = parseSearch(locationSearch);

  deleteDefaultOrIncompleteCurrentSort(searchParams);

  return isInstantSearchState(searchParams)
    ? instantSearchStateToSearchState(searchParams)
    : searchParams;
};

/**
 * Get the current search state, along with any other
 * non-search params from the location.
 *
 * - Removes `page` param from the query
 * - Removes any instantsearch state from the query, as we will
 *   have mapped that state to the new search state.
 * - Filters out any null/undefined values
 */
export function getLocationQueryWithSearchState<T>(
  locationSearch: string,
  searchState: JaneSearchState<T>
) {
  const currentParams = parseSearch(locationSearch);
  const allParams = {
    ...currentParams,
    ...searchState,
  };
  const filteredParams = omitBy(
    omit(allParams, ['page', ...INSTANTSEARCH_STATE_KEYS]),
    (value) => !value
  );

  return encodeQuery('', filteredParams);
}

export const getLocationQueryWithoutSearchState = (
  locationSearch: string
): string => {
  const currentParams = parseSearch(locationSearch);
  return encodeQuery(
    '',
    omit(currentParams, [
      ...JANE_SEARCH_STATE_KEYS,
      ...INSTANTSEARCH_STATE_KEYS,
    ])
  );
};

export const getSearchParams = <T>({
  page,
  ...searchState
}: QueryParams): JaneSearchState<T> =>
  pick(
    omitBy(searchState, (value) => !value),
    JANE_SEARCH_STATE_KEYS
  );

export const deleteDefaultOrIncompleteCurrentSort = (
  searchParams: QueryParams
): void => {
  const { currentSort } = searchParams;
  if (currentSort) {
    if (!('id' in currentSort) || !('suffix' in currentSort)) {
      delete searchParams['currentSort'];
    }
    if (currentSort.isDefault !== undefined) {
      delete searchParams['currentSort'];
    }
  }
};
