import { useEffect } from 'react';

import { useMultifactorAuthentication } from '@jane/shared/hooks';
import { useMfa } from '@jane/shared/providers';
import { Box, Loading, Typography } from '@jane/shared/reefer';

import { MultifactorAuthenticationError } from './multifactorAuthenticationError';
import { MultifactorAuthenticationForm } from './multifactorAuthenticationForm';

export interface MultifactorAuthenticationProps {
  isLoading?: boolean;
  onCancel?: () => void;
  onSuccess?: () => void;
}

export const MultifactorAuthentication = ({
  isLoading = false,
  onCancel,
  onSuccess,
}: MultifactorAuthenticationProps) => {
  const { canSendCode, getNewPhone, onCodeResent, onCodeSent, mfaSuccess } =
    useMfa();

  const {
    codeSent,
    codeResent,
    resendCode,
    sendCode,
    sendingCode,
    verifyCode,
    errorSendingCode,
    errorVerifyingCode,
  } = useMultifactorAuthentication({
    newPhone: getNewPhone(),
    onCodeResent: () => {
      onCodeResent && onCodeResent();
    },
    onCodeSentSuccess: () => {
      onCodeSent && onCodeSent();
    },
    onVerifySuccess: () => {
      mfaSuccess && mfaSuccess();
      onSuccess && onSuccess();
    },
  });

  const autoSendCode = canSendCode ? canSendCode() : !codeSent;

  useEffect(() => {
    if (autoSendCode) {
      sendCode();
    }
  }, []);

  const onSubmit = (values: { code: string }) => {
    verifyCode(values.code);
  };

  return (
    <Box>
      <Typography variant="header-bold" textAlign="center" mb={8} branded>
        Verify your {getNewPhone() ? 'phone number' : 'identity'}
      </Typography>

      {(isLoading || sendingCode) && (
        <Box mt={24} py={48} position="relative">
          <Loading color="black" size="lg" />
        </Box>
      )}

      {!isLoading && !sendingCode && !errorSendingCode && (
        <MultifactorAuthenticationForm
          codeResent={codeResent}
          errorVerifyingCode={errorVerifyingCode}
          newPhone={getNewPhone()}
          onCancel={onCancel}
          onSubmit={onSubmit}
          resendCode={resendCode}
        />
      )}

      {!isLoading && !sendingCode && errorSendingCode && (
        <MultifactorAuthenticationError
          codeResent={codeResent}
          onCancel={onCancel}
          resendCode={resendCode}
        />
      )}
    </Box>
  );
};
