import { Link, Typography } from '@jane/shared/reefer';

export const JaneGoldTermsLink = ({ finePrint }: { finePrint?: boolean }) => {
  return (
    <Link
      href="https://www.iheartjane.com/terms"
      color={finePrint ? 'text-light' : 'text-main'}
    >
      <Typography
        as="span"
        variant={finePrint ? 'mini' : 'body'}
        color={finePrint ? 'text-light' : 'text-main'}
      >
        Terms and conditions apply
      </Typography>
    </Link>
  );
};
