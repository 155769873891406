import { z } from 'zod';

export const BrandPriceIdSpecialsSchema = z.object({
  brand_special_id: z.number(),
  discount_label: z.string(),
  price: z.string(),
});

export const BrandSpecialPricesSchema = z.object({
  each: BrandPriceIdSpecialsSchema.nullable().optional(),
  half_gram: BrandPriceIdSpecialsSchema.nullable().optional(),
  gram: BrandPriceIdSpecialsSchema.nullable().optional(),
  two_gram: BrandPriceIdSpecialsSchema.nullable().optional(),
  eighth_ounce: BrandPriceIdSpecialsSchema.nullable().optional(),
  quarter_ounce: BrandPriceIdSpecialsSchema.nullable().optional(),
  half_ounce: BrandPriceIdSpecialsSchema.nullable().optional(),
  ounce: BrandPriceIdSpecialsSchema.nullable().optional(),
});
