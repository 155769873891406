// eslint-disable-next-line no-restricted-imports
import type { MomentFormatSpecification } from 'moment-timezone';
// eslint-disable-next-line no-restricted-imports
import moment from 'moment-timezone';

export function calculateMinutes(date: string | Date, timezone: string) {
  const parsed = moment(date).tz(timezone);
  const minutes = parsed.minutes();
  const hours = parsed.hours();
  return minutes + hours * 60;
}

export function convertHourMinuteToMoment(hour: number, minute: number) {
  return moment.tz().hour(hour).minute(minute).second(0).millisecond(0);
}

export function currentTime(timezone: string) {
  return moment.tz(timezone);
}

export const ISO8601 = moment.ISO_8601;

export function formatUtc(
  date: string,
  inputFormat: MomentFormatSpecification = ISO8601,
  outputFormat?: string
) {
  return moment.utc(date, inputFormat).format(outputFormat);
}

export function formatUtcWithoutInputFormat(date: string, format: string) {
  return moment.utc(date).format(format);
}

export function isBefore(date: string): boolean {
  return moment().isBefore(date);
}

export function parse(date: string | number) {
  return moment(date);
}

export function parseWithTimezone(date: string, timezone: string) {
  return moment.tz(date, timezone);
}

export function parseWithFormatAndTimezone(
  date: string,
  format: string,
  timezone: string
) {
  return moment.tz(date, format, timezone);
}

export function clientTimeZone() {
  return Intl.DateTimeFormat().resolvedOptions().timeZone;
}
