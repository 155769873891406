import type { ReactNode } from 'react';
import { createPortal } from 'react-dom';

import { trackError } from '@jane/shared/util';

interface PortalProps {
  children: ReactNode;
  containerId?: string;
  shadowContainerId?: string;
}

/**
 * A minimal modal that renders content outside the parent component.
 * Compatible when using a shadow dom
 */
export const Portal = ({
  children,
  containerId = 'app',
  shadowContainerId,
}: PortalProps) => {
  const rootNode: ShadowRoot | Document = shadowContainerId
    ? document.getElementById(shadowContainerId)?.shadowRoot || document
    : document;

  const modalRoot = rootNode.getElementById(containerId);

  if (modalRoot) {
    return createPortal(children, modalRoot);
  } else {
    trackError(
      `shared-components - Portal: Unable to create portal to #${containerId}`
    );
    return null;
  }
};
