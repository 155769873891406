/**
 * DO NOT MODIFY THIS FILE!
 * It was created by the reefer-icons generator.
 * Please update & re-run the generator if need be.
 */
import type { SVGProps } from 'react';

import type { DynamicIconProps } from '../../../icon/icon.types';
import { Icon } from '../../icon';

const Topical24 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="100%"
    height="100%"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <g className="Topical 24">
      <g className="SVG">
        <g className="Main">
          <path
            fill="#CE349A"
            d="M4 6a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v16a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2V6Z"
          />
          <path
            fill="#CE349A"
            d="M7.75 1a1 1 0 0 1 1-1h6a1 1 0 0 1 1 1v4a1 1 0 0 1-1 1h-6a1 1 0 0 1-1-1V1Z"
          />
        </g>
        <path
          fill="#000"
          fillOpacity={0.5}
          d="M8 10a2 2 0 0 1 2-2h10v12H10a2 2 0 0 1-2-2v-8Z"
          className="Details"
        />
      </g>
    </g>
  </svg>
);

const SIZE_MAP = {
  md: Topical24,
  sm: Topical24,
  lg: Topical24,
  xl: Topical24,
  xxl: Topical24,
  xxxl: Topical24,
};

export const TopicalIcon = ({
  size = 'md',
  'data-testid': testId,
  ...props
}: DynamicIconProps) => {
  return (
    <Icon
      data-testid={testId || 'topical-icon'}
      icon={SIZE_MAP[size]}
      {...props}
      size={size}
    />
  );
};
