import {
  Flex,
  JaneGoldShadowLogo,
  JaneLogo,
  JanePayLogoWhite,
  PartnerLogo,
  Typography,
} from '@jane/shared/reefer';

import { Background, BoxStyled, ThemedButton } from './successScreen.styles';

export interface SuccessScreenProps {
  hideJanePayBranding: boolean;
  isJanePay: boolean;
  location?: string;

  /** Function called when 'done' button is clicked */
  onDoneClick(): void;

  storePhoto?: string;
}

export const SuccessScreen = ({
  hideJanePayBranding,
  isJanePay,
  location,
  onDoneClick,
  storePhoto,
}: SuccessScreenProps) => {
  const noImage = isJanePay && hideJanePayBranding && !storePhoto;

  return (
    <Flex
      width="100%"
      height="100%"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
    >
      <BoxStyled background="gold-light" height="100%">
        <Background sparkly={!isJanePay} hideBranding={hideJanePayBranding}>
          <Flex
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            width="100%"
            height="100%"
            py={noImage ? 0 : 24}
          >
            {isJanePay && !hideJanePayBranding && (
              <JanePayLogoWhite my={40} height="41px" />
            )}

            {isJanePay && hideJanePayBranding && storePhoto && (
              <PartnerLogo
                name="store-logo"
                variant="store"
                image={storePhoto}
              />
            )}

            {!isJanePay && <JaneLogo size="xs" />}

            {!isJanePay && <JaneGoldShadowLogo height="55px" />}
          </Flex>
        </Background>
      </BoxStyled>
      <Typography
        variant="header-bold"
        textAlign="center"
        mt={24}
        mb={8}
        branded
      >
        {hideJanePayBranding
          ? 'Success!'
          : `Welcome to ${isJanePay ? 'Jane Pay' : 'Jane Gold!'}`}
      </Typography>
      {!isJanePay && (
        <Typography textAlign="center" mb={24}>
          You can now earn cash back rewards from your favorite brands directly
          to your linked bank account.
        </Typography>
      )}

      {isJanePay && (
        <Typography textAlign="center" mb={24}>
          {!hideJanePayBranding
            ? 'You successfully linked your bank account. You can now use Jane Pay to checkout on Jane and other participating cannabis stores.'
            : 'You successfully linked your bank account to check out on Jane and other participating cannabis stores, powered by Jane and Aeropay.'}
        </Typography>
      )}

      <ThemedButton
        full
        janePay={isJanePay && !hideJanePayBranding}
        label={location === 'checkout' ? 'Back to checkout' : 'Done'}
        onClick={onDoneClick}
        variant="primary"
      />
    </Flex>
  );
};
