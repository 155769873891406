/**
 * DO NOT MODIFY THIS FILE!
 * It was created by the reefer-icons generator.
 * Please update & re-run the generator if need be.
 */
import type { SVGProps } from 'react';

import type { StaticIconProps } from '../../../icon/icon.types';
import { Icon } from '../../icon';

const Woman24 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="100%"
    height="100%"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <g className="Woman 24">
      <path
        fill="#8E00E6"
        fillRule="evenodd"
        d="M16 8a4 4 0 1 1-8 0 4 4 0 0 1 8 0Zm-6 7.748V17H9a2 2 0 1 0 0 4h1v1a2 2 0 1 0 4 0v-1h1a2 2 0 1 0 0-4h-1v-1.252a8 8 0 1 0-4 0Z"
        className="Fill-Main"
        clipRule="evenodd"
      />
    </g>
  </svg>
);

const SIZE_MAP = {
  md: Woman24,
  sm: Woman24,
  lg: Woman24,
  xl: Woman24,
  xxl: Woman24,
  xxxl: Woman24,
};

export const WomanIcon = ({
  size = 'md',
  'data-testid': testId,
  ...props
}: StaticIconProps) => {
  return (
    <Icon
      data-testid={testId || 'woman-icon'}
      icon={SIZE_MAP[size]}
      {...props}
      size={size}
      color={undefined}
    />
  );
};
