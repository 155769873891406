import {
  AeropayLogo,
  Box,
  Button,
  DismissIcon,
  Flex,
  JaneLogo,
  PartnerLogo,
  Typography,
} from '@jane/shared/reefer';

export interface AlreadyEnrolledScreenProps {
  hideJanePayBranding: boolean;
  isJanePay: boolean;

  /** Function called when 'done' or 'learn more' button is clicked */
  onDoneClick(): void;

  storePhoto?: string;
}

export const AlreadyEnrolledScreen = ({
  hideJanePayBranding,
  isJanePay,
  onDoneClick,
  storePhoto,
}: AlreadyEnrolledScreenProps) => {
  const hideBranding = isJanePay && hideJanePayBranding;
  const noImage = hideBranding && !storePhoto;

  return (
    <Flex width="100%" flexDirection="column" alignItems="center">
      <Flex
        width="100%"
        flexDirection="row"
        alignItems="center"
        justifyContent="center"
        mb={24}
      >
        {hideBranding && storePhoto && (
          <Box width="96px">
            <PartnerLogo name="store-logo" variant="store" image={storePhoto} />
          </Box>
        )}

        {!hideBranding && (
          <Box width="96px">
            <JaneLogo size="sm" />
          </Box>
        )}

        {!noImage && <DismissIcon size="sm" color="grays-light" mx={12} />}

        {!hideBranding && <AeropayLogo height="96px" />}

        {hideBranding && (
          <Box width="96px">
            <JaneLogo removeSafeSpace size="sm" />
          </Box>
        )}
      </Flex>

      <Typography branded variant="header-bold" textAlign="center" mb={8}>
        {!hideBranding &&
          `You're already enrolled in Jane ${isJanePay ? 'Pay' : 'Gold'} with
        Aeropay.`}

        {hideBranding &&
          'Your bank account is already linked with Jane and Aeropay'}
      </Typography>

      <Typography textAlign="center" mb={24}>
        {!hideBranding
          ? `You’ve been automatically enrolled with Jane ${
              isJanePay ? 'Pay' : 'Gold'
            }`
          : 'You’ve been automatically enrolled'}{' '}
        because you previously linked your bank account via Aeropay to make
        payments at a dispensary using Jane.
      </Typography>

      <Button
        full
        variant="primary"
        label="Done"
        onClick={onDoneClick}
        mb={16}
      />

      {!isJanePay && (
        <Button
          full
          variant="secondary"
          label="Learn more about Jane Gold"
          href="https://customer-support.iheartjane.com/s/article/Jane-Gold-FAQs"
          onClick={onDoneClick}
        />
      )}
    </Flex>
  );
};
