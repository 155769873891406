import type { FilterSortId } from '@jane/search/types';
import type { AdFlight, AppMode, Id } from '@jane/shared/models';
import type { MinMax, Nullable } from '@jane/shared/types';

import type { RecommendedSortVersion } from '../hooks/useGetRecommendedSortVersion';
import type { InlinePlacement } from '../types/placement';
import { getSharedDmQueryParams } from '../utils/getSharedDmQueryParams';
import { dmApi } from './dmApi';

export interface FetchInlineAdParams {
  appMode: AppMode;
  count?: number;
  janeDeviceId: string;
  keywords: string[];
  numColumns?: number;
  placement?: InlinePlacement;
  searchFilter: SearchFilter;
  smartSortVersion: RecommendedSortVersion;
  storeId: Id;
}
interface SearchFilter {
  index: string;
  optional_filters?: string;
  query?: string | null;
  range: {
    // single string format: eg "1:10", ":10", "1:"
    aggregate_rating?: string;
    bucket_price?: string;

    // json format: eg { "min": "1", "max": "10" }, { "max": 10 }
    percent_cbd?: Partial<MinMax>;
    percent_thc?: Partial<MinMax>;
    percent_thca?: Partial<MinMax>;
  };
  refinement_list: {
    activities?: string[];
    applicable_special_ids?: string[];
    available_weights?: string[];
    brand_subtypes?: string[];
    brands?: string[];
    categories?: string[];
    feelings?: string[];
    has_brand_discount?: string[];
    product_ids?: string[];
    root_types?: string[];
  };
  sort_by: FilterSortId;
}

export interface FetchInlineAdsRequestBody {
  app_mode: AppMode;
  count: number;
  jane_device_id: string;
  keywords: string[];
  num_columns?: number;
  placement: InlinePlacement;
  search_filter: SearchFilter;
  smart_sort_version: RecommendedSortVersion;
}

export interface FetchInlineAdResponse {
  flights: Nullable<AdFlight[]>;
  products_details: Nullable<Record<string, AdProductDetail>>;
  rows: Nullable<AdRow[]>;
}
export interface AdProductDetail {
  brand: string;
  name: string;
  product_brand_id: number;
  product_id: number;
  store_id: number;
}

export interface AdRow {
  ad_token: string;
  flight_id?: number;
  keyword: string;
  my_high_d?: string;
  product_id: number;
}

export const fetchInlineAd = async ({
  appMode,
  count = 10,
  janeDeviceId,
  keywords,
  numColumns,
  placement = 'menu_inline',
  searchFilter,
  smartSortVersion,
  storeId,
}: FetchInlineAdParams): Promise<FetchInlineAdResponse> => {
  const queryParams = new URLSearchParams(getSharedDmQueryParams());
  const url = `/stores/${storeId}/inline?${queryParams.toString()}`;

  const body: FetchInlineAdsRequestBody = {
    app_mode: appMode,
    count,
    jane_device_id: janeDeviceId,
    keywords,
    placement,
    search_filter: searchFilter,
    smart_sort_version: smartSortVersion,
  };
  if (typeof numColumns === 'number') {
    body.num_columns = numColumns;
  }
  return await dmApi.post<FetchInlineAdResponse>(url, body);
};
