/**
 * DO NOT MODIFY THIS FILE!
 * It was created by the reefer-icons generator.
 * Please update & re-run the generator if need be.
 */
import type { SVGProps } from 'react';

import type { DynamicIconProps } from '../../../icon/icon.types';
import { Icon } from '../../icon';

const Check16 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="100%"
    height="100%"
    fill="none"
    viewBox="0 0 16 16"
    {...props}
  >
    <g className="Check 16">
      <path
        fill="#0E0E0E"
        fillRule="evenodd"
        d="M15.711 2.797a1 1 0 0 1-.008 1.414l-9.1 9a1 1 0 0 1-1.406 0l-4.9-4.846a1 1 0 0 1 1.406-1.422l4.197 4.15 8.397-8.304a1 1 0 0 1 1.414.008Z"
        className="SVG"
        clipRule="evenodd"
      />
    </g>
  </svg>
);

const Check24 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="100%"
    height="100%"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <g className="Check 24">
      <path
        fill="#0E0E0E"
        fillRule="evenodd"
        d="M22.707 4.293a1 1 0 0 1 0 1.414l-13 13a1 1 0 0 1-1.414 0l-7-7a1 1 0 1 1 1.414-1.414L9 16.586 21.293 4.293a1 1 0 0 1 1.414 0Z"
        className="SVG"
        clipRule="evenodd"
      />
    </g>
  </svg>
);

const SIZE_MAP = {
  sm: Check16,
  md: Check24,
  lg: Check24,
  xl: Check24,
  xxl: Check24,
  xxxl: Check24,
};

export const CheckIcon = ({
  size = 'md',
  'data-testid': testId,
  ...props
}: DynamicIconProps) => {
  return (
    <Icon
      data-testid={testId || 'check-icon'}
      icon={SIZE_MAP[size]}
      {...props}
      size={size}
    />
  );
};
