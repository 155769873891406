import type { SVGProps } from 'react';

import { Icon } from '../../icon';
import type { LogoProps } from '../logo.types';

const Citi = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 96 96"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M37.0747 59.1152L36.9718 59.2149C35.355 60.8623 33.4841 61.7326 31.5569 61.7326C27.5319 61.7326 24.6121 58.713 24.6121 54.5481C24.6121 50.3896 27.5319 47.3653 31.5569 47.3653C33.4841 47.3653 35.355 48.2404 36.9718 49.8942L37.0747 49.9923L39.6631 46.8618L39.5939 46.7781C37.4415 44.2348 34.8579 42.9977 31.6824 42.9977C28.4987 42.9977 25.5838 44.0707 23.486 46.0027C21.2017 48.0972 20 51.0508 20 54.5481C20 58.0454 21.2017 61.0054 23.486 63.1C25.5838 65.0433 28.4987 66.1082 31.6824 66.1082C34.8579 66.1082 37.4415 64.8695 39.5939 62.3246L39.6631 62.2473L37.0747 59.1152Z"
      fill="#004685"
    />
    <path d="M42.2774 65.6813H46.8493V43.359H42.2774V65.6813Z" fill="#004685" />
    <path
      d="M64.7614 60.8286C63.5404 61.5718 62.4063 61.9466 61.3863 61.9466C59.9079 61.9466 59.2387 61.1648 59.2387 59.4274V47.5826H63.8991V43.3807H59.2387V36.4343L54.7585 38.8345V43.3807H50.8912V47.5826H54.7585V60.1819C54.7585 63.6133 56.7919 65.962 59.8291 66.0231C61.8899 66.0633 63.1318 65.4488 63.8846 64.9983L63.9329 64.9678L65.0316 60.6629L64.7614 60.8286Z"
      fill="#004685"
    />
    <path d="M68.5459 65.6813H73.1242V43.359H68.5459V65.6813Z" fill="#004685" />
    <path
      d="M75.7908 39.4463C71.613 33.5295 64.6763 30 57.6189 30C50.5632 30 43.6249 33.5295 39.4551 39.4463L39.2395 39.7503H44.5L44.5579 39.6876C48.1373 35.9908 52.8121 34.0378 57.6189 34.0378C62.4257 34.0378 67.0974 35.9908 70.6848 39.6876L70.7427 39.7503H75.9999L75.7908 39.4463Z"
      fill="#EE1C25"
    />
  </svg>
);
export const CitiLogo = ({
  height = 48,
  width = 'auto',
  ...props
}: LogoProps) => {
  return (
    <Icon
      height={height}
      width={width}
      {...props}
      icon={Citi}
      color={undefined}
      rotate={undefined}
      size={undefined}
    />
  );
};
