import * as t from 'io-ts';

const tUserOptionalAttributes = t.partial({
  externalId: t.string,
});

export const tUser = t.intersection([
  t.type({
    authenticated: t.boolean,
    id: t.number,
    email: t.string,
    nickname: t.union([t.string, t.null]),
    avatar: t.union([t.number, t.null]),
    phone: t.union([t.string, t.null]),
    first_name: t.union([t.string, t.null]),
    last_name: t.union([t.string, t.null]),
    name: t.union([t.string, t.null]),
    birth_date: t.union([t.string, t.null]),
    promotions_opt_out: t.boolean,
  }),
  tUserOptionalAttributes,
]);

export type User = t.TypeOf<typeof tUser>;
