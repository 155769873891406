import React, { Suspense, lazy, useContext } from 'react';
import { useSwipeable } from 'react-swipeable';

import { inIframe } from '@jane/shared-ecomm/util';
import { LoadingWrapper } from '@jane/shared/components';
import {
  ArrowLeftIcon,
  ArrowRightIcon,
  Button,
  Flex,
  useDesktopMediaQuery,
  useTabletMediaQuery,
} from '@jane/shared/reefer';

import { CarouselContext } from '../carousel';
import type { CarouselSwiperProps } from '../carousel.types';
import { BrandCarouselPagination } from '../carouselPagination/brandCarouselPagination';
import { CarouselPagination } from '../carouselPagination/carouselPagination';
import { CarouselThumbnails } from '../carouselThumbnails/carouselThumbnails';
import {
  CarouselButtonWrapper,
  CarouselTrack,
  CarouselWrapper,
} from './carouselSwiper.styles';

/** Code splitting to load react-player only when called */
const CarouselItem = lazy(() =>
  import('../carouselItem/carouselItem').then(({ CarouselItem }) => ({
    default: CarouselItem,
  }))
);

/**
 * CarouselSwiper for product and brand images and videos
 */

export const CarouselSwiper = ({
  activeIndex,
  isLightbox,
  items,
  onClick,
  forceMobile,
  showThumbnails,
  updateIndex,
  variant,
  withLightbox,
}: CarouselSwiperProps) => {
  const isDesktop = useDesktopMediaQuery({ size: 'sm' });
  const isTablet = useTabletMediaQuery({});
  const { desktopHeight, desktopWidth, mobileHeight, mobileWidth } =
    useContext(CarouselContext);
  const totalItems = items.length;
  const isInIframe = inIframe();

  const nextIndex = activeIndex === totalItems - 1 ? 0 : activeIndex + 1;
  const prevIndex = activeIndex === 0 ? totalItems - 1 : activeIndex - 1;

  if (variant === 'brand' && !isLightbox) showThumbnails = false;

  const touchHandlers = useSwipeable({
    onSwipedLeft: () => {
      updateIndex(nextIndex);
    },
    onSwipedRight: () => {
      updateIndex(prevIndex);
    },
  });

  const arrowKeyHandlers = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'ArrowLeft') {
      updateIndex(prevIndex);
    }

    if (event.key === 'ArrowRight') {
      updateIndex(nextIndex);
    }
  };

  return (
    <Flex flexDirection="column" alignItems="center" width="100%">
      <Flex
        justifyContent="center"
        alignItems="center"
        width={isLightbox && !isDesktop && isTablet ? '80%' : '100%'}
      >
        {isLightbox && totalItems > 1 && (
          <CarouselButtonWrapper>
            <Button.Icon
              icon={<ArrowLeftIcon />}
              label="View previous item"
              variant="minimal-inverse"
              mr={24}
              onClick={() => updateIndex(prevIndex)}
            />
          </CarouselButtonWrapper>
        )}
        <CarouselWrapper
          desktopHeight={desktopHeight}
          desktopWidth={desktopWidth}
          isLightbox={isLightbox}
          mobileHeight={mobileHeight}
          mobileWidth={mobileWidth}
          variant={variant}
          withLightbox={withLightbox}
        >
          <CarouselTrack
            activeIndex={activeIndex}
            {...touchHandlers}
            onKeyUp={(event) => arrowKeyHandlers(event)}
          >
            <Suspense
              fallback={
                <LoadingWrapper
                  isLoading
                  variant="block"
                  height={isDesktop ? desktopHeight : mobileHeight}
                />
              }
            >
              {items.map((item, index) => (
                <CarouselItem
                  key={`${index}-${item.imageSrc}`}
                  {...item}
                  activeIndex={activeIndex}
                  isLightbox={isLightbox}
                  onClick={onClick}
                  variant={variant}
                />
              ))}
            </Suspense>
          </CarouselTrack>
          {variant === 'product' && totalItems > 1 && (
            <CarouselPagination
              activeIndex={activeIndex}
              forceMobile={forceMobile}
              isInIframe={isInIframe}
              isLightbox={isLightbox}
              totalItems={totalItems}
              updateIndex={updateIndex}
            />
          )}

          {variant === 'brand' && !isLightbox && totalItems > 1 && (
            <BrandCarouselPagination
              activeIndex={activeIndex}
              totalItems={totalItems}
              forceMobile={forceMobile}
              updateIndex={updateIndex}
            />
          )}
        </CarouselWrapper>
        {isLightbox && totalItems > 1 && (
          <CarouselButtonWrapper>
            <Button.Icon
              icon={<ArrowRightIcon />}
              label="View next item"
              variant="minimal-inverse"
              ml={24}
              onClick={() => updateIndex(nextIndex)}
            />
          </CarouselButtonWrapper>
        )}
      </Flex>
      {showThumbnails && isTablet && totalItems > 1 && (
        <CarouselThumbnails
          activeIndex={activeIndex}
          isLightbox={isLightbox}
          items={items}
          updateIndex={updateIndex}
          variant={variant}
        />
      )}
    </Flex>
  );
};
