import * as t from 'io-ts';
import qs from 'qs';

import { config } from '@jane/shared/config';
import type {
  Address,
  CrmProviderType,
  CrmRedemption,
  Id,
} from '@jane/shared/models';
import { tCrmIntegration } from '@jane/shared/models';

import { request } from '../lib/request';

export const getCrmIntegration = (
  storeId: Id,
  crmMemberId: string | null,
  email: string | null,
  authCode?: string
) => ({
  url: `${config.apiPath}/stores/${storeId}/crm_integration?${qs.stringify({
    crm_member_id: crmMemberId,
    auth_code: authCode,
    email,
  })}`,
  type: t.interface({
    crm_integration: t.union([tCrmIntegration, t.undefined]),
    integration_type: t.union([t.literal('phone'), t.literal('email'), t.null]),
    error: t.union([t.string, t.undefined]),
  }),
});

export const applyCrmRedemption = (
  redemption: CrmRedemption,
  delivery_address?: Address | null
) =>
  request(`${config.apiPath}/carts/apply_crm_redemption`, {
    method: 'POST',
    body: JSON.stringify({ ...redemption, delivery_address }),
  });

export const removeCrmRedemption = (id: Id, crmProvider: CrmProviderType) =>
  request(`${config.apiPath}/carts/remove_crm_redemption`, {
    method: 'DELETE',
    body: JSON.stringify({ id, crm_provider: crmProvider }),
  });

export const initiateCrmAuth = (cartUuid: string) =>
  request(`${config.apiPath}/carts/initiate_crm_auth`, {
    method: 'POST',
    body: JSON.stringify({ cart_uuid: cartUuid }),
  });
