import pluralise from 'pluralise';

import { Accordion } from '@jane/shared/components';
import type { CrmIntegration, CrmRedemption } from '@jane/shared/models';
import {
  Box,
  Flex,
  Link,
  StarCircledIcon,
  Typography,
  useMobileMediaQuery,
} from '@jane/shared/reefer';

import { ApplyableDiscount } from './applyableDiscount';

export const CustomerLoyaltyPointsAccordionHeader = ({
  appliedRedemptions,
  availablePoints,
  crmIntegration: { wallet_state, jane_redeemable_rewards },
  handleRemoveCrmRedemption,
  loading,
  storeRewardLabel,
}: {
  appliedRedemptions: readonly CrmRedemption[];
  availablePoints: number;
  crmIntegration: CrmIntegration;
  handleRemoveCrmRedemption: (id: string) => void;
  loading: boolean;
  storeRewardLabel?: string | null;
}) => {
  const headingTitle = `Use store ${
    storeRewardLabel ? storeRewardLabel : 'rewards'
  }`;
  const isMobile = useMobileMediaQuery({});

  const Header = () => (
    <Flex alignItems="center" justifyContent="space-between">
      <Flex alignItems="center">
        <StarCircledIcon />
        <Typography color="grays-black" ml={16} variant="body-bold">
          {headingTitle}
        </Typography>
      </Flex>
      <Accordion.Indicator iconSize="md" />
    </Flex>
  );

  if (loading) {
    return <Header />;
  }

  return (
    <Accordion.Heading px={isMobile ? 24 : 40} py={20}>
      <Header />
      <Box pl={40}>
        {wallet_state === 'unlocked' && (
          <Typography>
            {availablePoints} {pluralise(availablePoints, 'pt', 'pts')}
          </Typography>
        )}
        {wallet_state === 'locked' && (
          <Typography>Authenticate your account to unlock rewards</Typography>
        )}
        {appliedRedemptions.length > 0 && jane_redeemable_rewards.length > 0 && (
          <Box pt={16}>
            <Typography variant="body-bold">Applied rewards</Typography>
            <ul>
              {appliedRedemptions.map((redemption) => (
                <ApplyableDiscount
                  key={redemption.id}
                  applied
                  enabled
                  onClick={(e) => {
                    e.stopPropagation();
                    handleRemoveCrmRedemption(redemption.id.toString());
                  }}
                  text={`${redemption.reward_name} (${
                    redemption.point_value
                  } ${pluralise(redemption.point_value, 'pt', 'pts')}) `}
                >
                  <Link>Remove</Link>
                </ApplyableDiscount>
              ))}
            </ul>
          </Box>
        )}
      </Box>
    </Accordion.Heading>
  );
};
