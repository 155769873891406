import throttle from 'lodash/throttle';
import { useEffect, useRef } from 'react';

import { useScrollRestoration } from '@jane/shared/providers';

export const useSaveScrollPosition = () => {
  const { saveScrollPosition } = useScrollRestoration();
  const pathname = useRef(window.location.href);
  const scrollY = useRef(0);

  useEffect(() => {
    pathname.current = window.location.href;
  }, [window.location.href]);

  useEffect(() => {
    const updateScrollY = throttle(
      () => (scrollY.current = window.scrollY),
      200
    );

    window.addEventListener('scroll', updateScrollY, false);
    return () => {
      saveScrollPosition(pathname.current, scrollY.current);
      window.removeEventListener('scroll', updateScrollY);
    };
  }, []);
};
