export const appModeBloom = 'bloom';
export const appModeBrandEmbed = 'brandEmbed';
export const appModeDefault = 'default';
export const appModeEmbedded = 'embedded';
export const appModeFramelessEmbed = 'framelessEmbed';
export const appModeHeadless = 'headless';
export const appModeKiosk = 'kiosk';
export const appModeOperatorEmbed = 'operatorEmbed';
export const appModeWhiteLabel = 'whiteLabel';
export const appModes = [
  appModeBloom,
  appModeBrandEmbed,
  appModeDefault,
  appModeEmbedded,
  appModeFramelessEmbed,
  appModeHeadless,
  appModeKiosk,
  appModeOperatorEmbed,
  appModeWhiteLabel,
] as const;
export type AppMode = typeof appModes[number];
