import noop from 'lodash/noop';
import type { ReactNode } from 'react';
import React, { useState } from 'react';

export const ScrollPositionContext = React.createContext<any>({
  scrollPosition: 0,
  setScrollPosition: noop,
  windowHeightMap: {},
  setWindowHeightMap: noop,
});

interface Props {
  children: ReactNode;
}

export const ScrollPositionProvider = ({ children }: Props) => {
  const [scrollPosition, setScrollPosition] = useState(0);
  const [windowHeightMap, setWindowHeightMap] = useState({});

  return (
    <ScrollPositionContext.Provider
      value={{
        scrollPosition,
        setScrollPosition,
        windowHeightMap,
        setWindowHeightMap,
      }}
    >
      {children}
    </ScrollPositionContext.Provider>
  );
};
