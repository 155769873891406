import capitalize from 'lodash/capitalize';
import { useEffect, useMemo, useState } from 'react';

import type { CurrentSort, FilterSortOption } from '@jane/search/types';
import type { PopoverContextProps } from '@jane/shared/reefer';
import {
  Button,
  ChevronDownIcon,
  Popover,
  useMobileMediaQuery,
} from '@jane/shared/reefer';

import {
  FilterListButton,
  FilterListItem,
  StyledFilterList,
} from '../filter.styles';

export interface FilterSortProps {
  /** Current sort option */
  currentSort?: CurrentSort;

  /** Function to call when changing the sort option */
  onSort: (value: FilterSortOption) => void;

  /** Array of sort options */
  sortOptions: FilterSortOption[];

  /** Variant is used in various label text within the component */
  variant?: 'brand' | 'product' | 'store';

  width?: string;
}

const getSortLabel = ({
  currentSort,
  sortOptions,
}: {
  currentSort?: CurrentSort;
  sortOptions: FilterSortOption[];
}) => {
  // try to get the current sort's label
  if (currentSort) {
    const currentSortOption = sortOptions.find(
      (option) => option.id === currentSort.id
    );
    if (currentSortOption) {
      return currentSortOption.label;
    }
  }

  // try to get the default sort's label
  const defaultSortOption = sortOptions.find((option) => option.isDefault);
  if (defaultSortOption) {
    return defaultSortOption.label;
  }

  // backup label, shouldn't happen if options are set up with a default
  return 'Default';
};

export const FilterSort = ({
  currentSort,
  onSort,
  sortOptions,
  variant = 'product',
  width,
}: FilterSortProps) => {
  const isMobile = useMobileMediaQuery({});

  const currentSortLabel = useMemo(
    () => getSortLabel({ currentSort, sortOptions }),
    [currentSort, sortOptions]
  );
  const [sortLabel, setSortLabel] = useState(currentSortLabel);

  useEffect(() => {
    if (currentSortLabel !== sortLabel) {
      setSortLabel(currentSortLabel);
    }
  }, [currentSortLabel, sortLabel]);

  return (
    <Popover
      alignment={{ vertical: 'bottom', horizontal: 'right' }}
      label={`Sort ${capitalize(variant)}s by ${sortLabel}`}
      mb={16}
      openOn={!sortOptions.length ? 'disabled' : 'click'}
      target={
        <Button
          label={`Sort: ${sortLabel}`}
          endIcon={<ChevronDownIcon size="sm" />}
          variant="tertiary"
          full={isMobile}
          disabled={!sortOptions.length}
        />
      }
      targetWidth={width || '100%'}
    >
      {({ closePopover }: PopoverContextProps) => (
        <StyledFilterList aria-label={`${capitalize(variant)} sort options`}>
          {sortOptions.map((option) => (
            <FilterListItem key={option.label}>
              <FilterListButton
                onClick={() => {
                  setSortLabel(option.label);
                  onSort(option);
                  closePopover();
                }}
              >
                {option.label}
              </FilterListButton>
            </FilterListItem>
          ))}
        </StyledFilterList>
      )}
    </Popover>
  );
};
