import { useEffect, useState } from 'react';

import {
  LocationDetector,
  LocationDetectorError,
} from '@jane/shared-ecomm/util';
import type {
  Address,
  DeliveryValidation,
  GeocodingResult,
  Store,
} from '@jane/shared/models';

import { useCustomerDispatch } from '../../../../customer/dispatch';
import { updateDeliveryAddress } from '../../../../customer/redux/cart';
import { reservationModeLabel } from '../../../../lib/store';
import { DeliveryValidateSource } from '../../../../sources/deliveryValidate';

interface UseValidateDeliveryAddress {
  address: string;
  store: Store;
  street2?: string;
}

interface ValidateDeliveryAddress {
  addressErrorMessage: string;
  addressIsValid: boolean;
  deliveryValidationData: DeliveryValidation | null;
  googleValidatedAddress: GeocodingResult | null;
  resetAddressValidation: () => void;
}

export const useValidateDeliveryAddress = ({
  address,
  store,
  street2,
}: UseValidateDeliveryAddress): ValidateDeliveryAddress => {
  const dispatch = useCustomerDispatch();
  const [valid, setValid] = useState(false);
  const [message, setMessage] = useState<string | null>(null);
  const [validationData, setValidationData] = useState(null);
  const [googlePlaceId, setGooglePlaceId] = useState(null);
  const [googleValidatedAddress, setGoogleValidatedAddress] = useState(null);

  const resetAddressValidation = () => {
    setValid(false);
    setMessage(null);
  };

  useEffect(() => {
    if (!address) return;
    if (address.length < 6) {
      setValid(false);
      setMessage('Please enter a valid address.');
      return;
    }

    const validateAddress = async () => {
      const location = await LocationDetector.getInstance().geocode(address);

      const { zipcode, street, city, state, google_place_id } = location;

      if (google_place_id === googlePlaceId) return;
      resetAddressValidation();
      setGooglePlaceId(google_place_id);

      if (!street || !city || !state || !zipcode) {
        setValid(false);
        setMessage(
          "We don't service this address. Please confirm the spelling."
        );
        return;
      }

      setGoogleValidatedAddress(location);

      const newValidation = await DeliveryValidateSource.get({
        storeId: store.id,
        zipcode: location?.zipcode,
        coordinates: location?.coordinates,
      });

      setValidationData(newValidation);

      if (!newValidation.can_deliver) {
        setValid(false);
        setMessage(
          `Outside of ${reservationModeLabel(store, 'delivery')} range`
        );
        return;
      }
      setValid(true);
      setMessage(null);

      const deliveryAddress: Address = {
        street: street || null,
        street2,
        city: city || null,
        country_code: location.countryCode || null,
        state_code: state || null,
        zipcode: zipcode || null,
        lat: location.coordinates.lat,
        lng: location.coordinates.long,
      };

      // ???: What does this redux thing do? How can I find the subscribers to this action?
      dispatch(updateDeliveryAddress({ deliveryAddress }));
    };

    validateAddress().catch((err) => {
      setValid(false);
      setValidationData({
        can_delivery: false,
        message: 'unable to find address',
      });
      switch (err.message) {
        case LocationDetectorError.ZeroResults:
          return setMessage(
            'We can’t find this address. Make sure it is spelled correctly or try adding a city, state, or zip code.'
          );
        default:
          return setMessage('Unable to verify address');
      }
    });
  }, [address, dispatch, googlePlaceId, store, street2]);

  return {
    addressIsValid: valid,
    addressErrorMessage: message,
    resetAddressValidation,
    deliveryValidationData: validationData,
    googleValidatedAddress: googleValidatedAddress,
  };
};
