export const JANE_ERRORS = {
  janeUser: 'fetchJaneUser',
  update: 'updateJaneUser',
};

export const AEROPAY_ERRORS = {
  delete: 'unable to delete aeropay bank account',
  disabled: 'account disabled',
  existingAccount: 'account already exists',
  existingNumber: 'user is already associated with this phone number',
};

export const DEFAULT_ERROR_MESSAGE = {
  message: 'There was an error processing this request.',
  tryAgain: true,
};

export const ERROR_MESSAGES = {
  delete: {
    message: 'We were unable to remove that bank account.',
    tryAgain: false,
  },
  disabled: {
    message: 'Your account has been disabled.',
    tryAgain: false,
  },
  existingAccount: {
    message: 'This bank account has already been linked multiple times.',
    tryAgain: false,
  },
  existingNumber: {
    message: 'A user is already associated with this phone number.',
    tryAgain: false,
  },
  janeUser: {
    message: 'There was an error fetching your Jane account.',
    tryAgain: true,
  },
  update: {
    message: 'There was an error updating your Jane account.',
    tryAgain: true,
  },
};

export const parseError = (message: unknown) => {
  if (typeof message !== 'string') return DEFAULT_ERROR_MESSAGE;

  const lowercaseMsg = message.toLowerCase();

  switch (true) {
    case lowercaseMsg.includes(AEROPAY_ERRORS.existingNumber):
      return ERROR_MESSAGES.existingNumber;
    case lowercaseMsg.includes(AEROPAY_ERRORS.existingAccount):
      return ERROR_MESSAGES.existingAccount;
    case lowercaseMsg.includes(AEROPAY_ERRORS.delete):
      return ERROR_MESSAGES.delete;
    case lowercaseMsg.includes(AEROPAY_ERRORS.disabled):
      return ERROR_MESSAGES.disabled;
    case message === JANE_ERRORS.update:
      return ERROR_MESSAGES.update;
    case message === JANE_ERRORS.janeUser:
      return ERROR_MESSAGES.janeUser;
    default:
      return DEFAULT_ERROR_MESSAGE;
  }
};
