import type { SVGProps } from 'react';

import { Icon } from '../../icon/icon';
import type { StaticIconProps } from '../../icon/icon.types';

const Sloth = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="64"
    height="64"
    viewBox="0 0 64 64"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <circle cx={32} cy={32} r={32} fill="#FF9900" />
    <circle cx={32} cy={32} r={32} fill="white" fillOpacity={0.8} />
    <ellipse cx={32} cy={32} rx={22} ry={16} fill="#FF9900" />
    <ellipse
      cx={15.7321}
      cy={42.4641}
      rx={2}
      ry={4}
      transform="rotate(30 15.7321 42.4641)"
      fill="#FF9900"
    />
    <ellipse
      rx={2}
      ry={4}
      transform="matrix(-0.866025 0.5 0.5 0.866025 48.7321 42.4641)"
      fill="#FF9900"
    />
    <ellipse
      cx={13.7321}
      cy={40.4641}
      rx={2}
      ry={4}
      transform="rotate(30 13.7321 40.4641)"
      fill="#FF9900"
    />
    <ellipse
      rx={2}
      ry={4}
      transform="matrix(-0.866025 0.5 0.5 0.866025 50.7321 40.4641)"
      fill="#FF9900"
    />
    <ellipse
      cx={11.7321}
      cy={38.4641}
      rx={2}
      ry={4}
      transform="rotate(30 11.7321 38.4641)"
      fill="#FF9900"
    />
    <ellipse
      cx={11.7321}
      cy={24.4641}
      rx={2}
      ry={4}
      transform="rotate(30 11.7321 24.4641)"
      fill="#FF9900"
    />
    <ellipse
      rx={2}
      ry={4}
      transform="matrix(-0.866025 0.5 0.5 0.866025 52.7321 38.4641)"
      fill="#FF9900"
    />
    <ellipse
      rx={2}
      ry={4}
      transform="matrix(-0.866025 0.5 0.5 0.866025 52.7321 24.4641)"
      fill="#FF9900"
    />
    <ellipse cx={32} cy={41} rx={12} ry={8} fill="#FF9900" />
    <ellipse cx={32} cy={41} rx={12} ry={8} fill="black" fillOpacity={0.2} />
    <ellipse
      cx={18.3923}
      cy={28.9282}
      rx={12}
      ry={8}
      transform="rotate(-30 18.3923 28.9282)"
      fill="#FF9900"
    />
    <ellipse
      cx={18.3923}
      cy={28.9282}
      rx={12}
      ry={8}
      transform="rotate(-30 18.3923 28.9282)"
      fill="black"
      fillOpacity={0.2}
    />
    <ellipse
      rx={12}
      ry={8}
      transform="matrix(-0.866025 -0.5 -0.5 0.866025 45.3924 28.9282)"
      fill="#FF9900"
    />
    <ellipse
      rx={12}
      ry={8}
      transform="matrix(-0.866025 -0.5 -0.5 0.866025 45.3924 28.9282)"
      fill="black"
      fillOpacity={0.2}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M23.5719 40.7416C23.7146 40.5052 24.022 40.4292 24.2584 40.5719C26.372 41.8476 29.1726 42.5 32 42.5C34.8274 42.5 37.6281 41.8476 39.7416 40.5719C39.9781 40.4292 40.2854 40.5052 40.4281 40.7416C40.5708 40.9781 40.4948 41.2854 40.2584 41.4281C37.9537 42.8191 34.9635 43.5 32 43.5C29.0365 43.5 26.0463 42.8191 23.7416 41.4281C23.5052 41.2854 23.4292 40.9781 23.5719 40.7416Z"
      fill="#0E0E0E"
    />
    <circle cx={22} cy={28} r={6} fill="white" />
    <circle cx={42} cy={28} r={6} fill="white" />
    <circle cx={22} cy={28} r={4} fill="#FFC220" />
    <circle cx={42} cy={28} r={4} fill="#FFC220" />
    <circle cx={22} cy={28} r={2} fill="#0E0E0E" />
    <ellipse cx={32} cy={39} rx={4} ry={2} fill="#0E0E0E" />
    <circle cx={42} cy={28} r={2} fill="#0E0E0E" />
    <circle cx={23} cy={27} r={1} fill="white" />
    <circle cx={43} cy={27} r={1} fill="white" />
  </svg>
);
export const SlothAvatar = ({ size = 'xl', ...props }: StaticIconProps) => {
  return <Icon icon={Sloth} {...props} size={size} color={undefined} />;
};
