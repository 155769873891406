import {
  Button,
  CheckCircledIcon,
  Flex,
  Typography,
} from '@jane/shared/reefer';

export interface NewAccountLinkedScreenProps {
  /** Function called when 'done' button is clicked */
  onDoneClick(): void;
}

export const NewAccountLinkedScreen = ({
  onDoneClick,
}: NewAccountLinkedScreenProps) => {
  return (
    <Flex width="100%" flexDirection="column" alignItems="center">
      <CheckCircledIcon size="xxl" color="primary" mb={24} />
      <Typography branded variant="header-bold" textAlign="center" mb={8}>
        New bank account linked
      </Typography>
      <Typography textAlign="center" mb={24}>
        You successfully linked a new bank account.
      </Typography>
      <Button full variant="primary" label="Done" onClick={onDoneClick} />
    </Flex>
  );
};
