import * as t from 'io-ts';

export const tMenuTab = t.union([
  t.literal('featured'),
  t.literal('specials'),
  t.literal('flower'),
  t.literal('edible'),
  t.literal('pre-roll'),
  t.literal('extract'),
  t.literal('tincture'),
  t.literal('topical'),
  t.literal('vape'),
  t.literal('merch'),
  t.literal('gear'),
  t.literal('grow'),
  t.literal('all-products'),
]);

export type MenuTab = t.TypeOf<typeof tMenuTab>;
