import { connect } from 'react-redux';
import type { ThunkAction, ThunkDispatch } from 'redux-thunk';

import type { Connect } from '../redux-util/types';
import { applicationReducer } from './redux/application';
import { formReducer } from './redux/form';
import { loginReducer } from './redux/login';
import { paginationReducer } from './redux/pagination';
import type { CommonActions, CommonState } from './redux/types';

export const commonReducers = {
  application: applicationReducer,
  form: formReducer,
  login: loginReducer,
  pagination: paginationReducer,
};

export type CommonThunkAction<
  Result = void,
  ExtraArgument = void
> = ThunkAction<Result, CommonState, ExtraArgument, CommonActions>;

export type CommonDispatch = ThunkDispatch<CommonState, void, CommonActions>;

export const commonConnect: Connect<CommonState> = connect;
