import * as t from 'io-ts';

export const tDeliveryValidation = t.interface({
  can_deliver: t.boolean,
  fee: t.union([t.number, t.null]),
  minimum: t.union([t.number, t.null]),
  message: t.string,
  lead_time_minutes: t.union([t.number, t.null]),
  last_call_minutes: t.union([t.number, t.null]),
});

export type DeliveryValidation = t.TypeOf<typeof tDeliveryValidation>;
