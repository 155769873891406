const baseColorKeys = [
  'primary',
  'secondary',
  'info',
  'success',
  'error',
  'gold',
  'tangerine',
  'ember',
  'sunset',
  'grape',
  'purple',
  'emerald',
  'palm',
  'seafoam',
  'pacific',
] as const;

export type BaseColorKeys = typeof baseColorKeys[number];

const systemColorKeys = [
  'system-positive-light',
  'system-positive-dark',
  'system-positive-main',
  'system-negative-light',
  'system-negative-dark',
  'system-negative-main',
] as const;

type SystemColorKeys = typeof systemColorKeys[number];

export const COLOR_KEYS = [
  'primary',
  'primary-light',
  'primary-dark',
  'secondary',
  'secondary-light',
  'secondary-dark',
  'info',
  'info-light',
  'info-dark',
  'success',
  'success-light',
  'success-dark',
  'error',
  'error-light',
  'error-dark',
  'text-main',
  'text-inverse',
  'text-info',
  'text-light',
  'gold',
  'gold-light',
  'gold-dark',
  'tangerine',
  'tangerine-light',
  'tangerine-dark',
  'ember',
  'ember-light',
  'ember-dark',
  'sunset',
  'sunset-light',
  'sunset-dark',
  'grape',
  'grape-light',
  'grape-dark',
  'purple',
  'purple-light',
  'purple-dark',
  'emerald',
  'emerald-light',
  'emerald-dark',
  'palm',
  'palm-light',
  'palm-dark',
  'seafoam',
  'seafoam-light',
  'seafoam-dark',
  'pacific',
  'pacific-light',
  'pacific-dark',
  'grays-white',
  'grays-ultralight',
  'grays-light',
  'grays-mid',
  'grays-dark',
  'grays-black',
  'grays-hover',
  'transparent',
  'transparent-black-five',
  'transparent-black-ten',
  'transparent-black-twenty',
  'transparent-black-fifty',
  'transparent-white-ten',
  'inherit',
] as const;

export type ColorKey = typeof COLOR_KEYS[number];

export interface ColorGroup {
  dark: string;
  light: string;
  main: string;
}

export type AllColorKeys =
  | ColorKey
  | SystemColorKeys
  | 'background'
  | 'default';

export interface BaseColors {
  brand: {
    ember: ColorGroup;
    gold: ColorGroup;
    grape: ColorGroup;
    purple: ColorGroup;
    sunset: ColorGroup;
    tangerine: ColorGroup;
  };
  grays: {
    black: string;
    dark: string;
    hover: string;
    light: string;
    mid: string;
    ultralight: string;
    white: string;
  };
  system: {
    negative: ColorGroup;
    positive: ColorGroup;
  };
  tertiary: {
    emerald: ColorGroup;
    pacific: ColorGroup;
    palm: ColorGroup;
    seafoam: ColorGroup;
  };
  transparent: {
    black: {
      fifty: string;
      five: string;
      ten: string;
      twenty: string;
    };
    white: {
      ten: string;
    };
  };
}

export interface ColorConfig {
  background: string;
  error: string;
  info: string;
  primary: string;
  secondary: string;
  success: string;
  text: {
    info: string;
    inverse: string;
    light: string;
    main: string;
  };
}

export interface ThemeColors {
  background: string;
  error: ColorGroup;
  info: ColorGroup;
  primary: ColorGroup;
  secondary: ColorGroup;
  success: ColorGroup;
  text: {
    info: string;
    inverse: string;
    light: string;
    main: string;
  };
}
