import { fold } from 'fp-ts/lib/Either';
import { pipe } from 'fp-ts/lib/pipeable';
import * as t from 'io-ts';
import snakeCase from 'lodash/snakeCase';

import { config } from '@jane/shared/config';
import type { AppMode, Id } from '@jane/shared/models';
import { tUser } from '@jane/shared/models';

import { decodeType } from '../lib/loadable';
import { loginRequest } from '../lib/request';

export interface RegisterArgs {
  app_mode_at_registration: AppMode;
  email: string;
  nickname?: string;
  password: string;
  phone: string;
  store_id_at_registration?: Id;
}

const tRegisterSuccessResponse = t.interface({
  headers: t.interface({ Authorization: t.string }),
  body: t.interface({
    user: tUser,
  }),
});

const tValidationError = t.interface({
  error: t.union([t.string, t.null]),
  id: t.string,
  status: t.number,
  validations: t.partial({
    email: t.array(t.string),
    password: t.array(t.string),
    phone: t.array(t.string),
  }),
});

const tRegisterValidationErrorResponse = t.interface({
  headers: t.partial({ Authorization: t.union([t.null, t.string]) }),
  body: t.interface({
    errors: tValidationError,
  }),
});

export type RegisterSuccessResponse = t.TypeOf<typeof tRegisterSuccessResponse>;

export type RegisterValidationErrorResponse = t.TypeOf<
  typeof tRegisterValidationErrorResponse
>;
export type RegisterResponse = t.TypeOf<typeof tRegisterResponse>;

const tRegisterResponse = t.union([
  tRegisterSuccessResponse,
  tRegisterValidationErrorResponse,
]);

export const RegistrationSource = {
  register: ({ app_mode_at_registration, ...user }: RegisterArgs) =>
    loginRequest(`${config.apiPath}/users`, {
      method: 'POST',
      body: JSON.stringify({
        user: {
          ...user,
          app_mode_at_registration: snakeCase(app_mode_at_registration),
        },
      }),
    })
      .then((r) => {
        if ('error' in r.body) {
          throw r.body.error;
        }
        return decodeType({
          data: r,
          type: tRegisterResponse,
          source: { name: 'Register User' },
        });
      })
      .then((r) =>
        pipe(
          r,
          fold(
            (error) => {
              throw error;
            },
            (response) => response
          )
        )
      ),
};
