/**
 * DO NOT MODIFY THIS FILE!
 * It was created by the reefer-icons generator.
 * Please update & re-run the generator if need be.
 */
import type { SVGProps } from 'react';

import type { DynamicIconProps } from '../../../icon/icon.types';
import { Icon } from '../../icon';

const Bank48 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="100%"
    height="100%"
    fill="none"
    viewBox="0 0 48 48"
    {...props}
  >
    <g className="Bank 48">
      <path
        fill="#0E0E0E"
        fillRule="evenodd"
        d="M25.195 6.61a3 3 0 0 0-2.39 0L5 14.346V17h38v-2.654L25.195 6.61ZM4 19h4v18H5a1 1 0 0 0-1 1v5a1 1 0 0 0 1 1h38a1 1 0 0 0 1-1v-5a1 1 0 0 0-1-1h-3V19h4a1 1 0 0 0 1-1v-4.31a1 1 0 0 0-.602-.917L25.992 4.775a5 5 0 0 0-3.985 0L3.602 12.773A1 1 0 0 0 3 13.69V18a1 1 0 0 0 1 1Zm6 18V19h3v18h-3Zm5 0V19h3v18h-3Zm5 0V19h3v18h-3Zm5 0V19h3v18h-3Zm5 0V19h3v18h-3Zm5 0V19h3v18h-3Zm4 2H6v3h36v-3h-3ZM24 13a2 2 0 1 0 0-4 2 2 0 0 0 0 4Z"
        className="Union"
        clipRule="evenodd"
      />
    </g>
  </svg>
);

const SIZE_MAP = {
  xl: Bank48,
  lg: Bank48,
  md: Bank48,
  sm: Bank48,
  xxl: Bank48,
  xxxl: Bank48,
};

export const BankIcon = ({
  size = 'md',
  'data-testid': testId,
  ...props
}: DynamicIconProps) => {
  return (
    <Icon
      data-testid={testId || 'bank-icon'}
      icon={SIZE_MAP[size]}
      {...props}
      size={size}
    />
  );
};
