import startCase from 'lodash/startCase';
import toLower from 'lodash/toLower';

export const titleCase = (str: string) => startCase(toLower(str));

export const sentenceCase = (str: string) =>
  str.toLowerCase().charAt(0).toUpperCase() + str.toLowerCase().slice(1);

export const titleize = (text: string | null | undefined) => {
  if (!text || text === null) return '';
  if (text.toLowerCase() === 'cbd') return 'CBD';
  return titleCase(text);
};
