import noop from 'lodash/noop';
import { createContext, useContext, useState } from 'react';
import type { Dispatch, SetStateAction } from 'react';

const CarouselMenuProductCardWidthContext = createContext<number>(0);
const SetCarouselMenuProductCardWidthContext =
  createContext<Dispatch<SetStateAction<number>>>(noop);

export const CarouselMenuProductCardWidthProvider = ({ children }) => {
  const [cardWidth, setCardWidth] = useState(0);

  return (
    <CarouselMenuProductCardWidthContext.Provider value={cardWidth}>
      <SetCarouselMenuProductCardWidthContext.Provider value={setCardWidth}>
        {children}
      </SetCarouselMenuProductCardWidthContext.Provider>
    </CarouselMenuProductCardWidthContext.Provider>
  );
};

export const useCarouselMenuProductCardWidth = () =>
  useContext(CarouselMenuProductCardWidthContext);

export const useSetCarouselMenuProductCardWidth = () =>
  useContext(SetCarouselMenuProductCardWidthContext);
