import clsx from 'clsx';

import { getNodeText } from '../../../internal/utils';
import type { ReeferBaseProps } from '../../../utils/baseProps.types';
import type { ButtonProps } from '../../button';
import { loadingColor } from '../../button/button';
import buttonStyles from '../../button/button.module.css';
import { LoadingLabel } from '../../internal/loadingLabel';
import { Loading } from '../../loading';
import { Typography } from '../../typography';
import styles from './cardAction.module.css';

export interface CardActionProps
  extends ReeferBaseProps,
    Pick<
      ButtonProps,
      | 'ariaLabel'
      | 'disabled'
      | 'endIcon'
      | 'label'
      | 'loading'
      | 'onClick'
      | 'data-testid'
      | 'type'
      | 'variant'
    > {}

/**
 * The `Card.Action` component can be included as a child of a `Card` component to add an
 * action button to the `Card`. Should be used below `Card.Content`.
 * */

export function CardAction({
  ariaLabel,
  className,
  disabled,
  endIcon,
  id,
  label,
  loading = false,
  onClick,
  'data-testid': testId,
  type = 'button',
  variant = 'primary',
}: CardActionProps) {
  return (
    <div className={clsx(styles.cardAction)}>
      <button
        className={clsx(
          className,
          styles.cardAction_button,
          buttonStyles.button,
          buttonStyles.buttonText,
          buttonStyles.button__full,
          buttonStyles[`button__${variant}`],
          {
            [buttonStyles.button__disabled]: disabled,
            [buttonStyles.button__loading]: loading,
          }
        )}
        aria-label={ariaLabel}
        data-testid={testId}
        disabled={disabled}
        id={id}
        name={getNodeText(label)}
        onClick={onClick}
        type={type}
      >
        {loading && <Loading color={loadingColor(variant)} />}
        <LoadingLabel isLoading={loading}>
          <Typography color="inherit" variant="body-bold" as="span">
            {label}
          </Typography>
          {endIcon}
        </LoadingLabel>
      </button>
    </div>
  );
}
