import type { SVGProps } from 'react';

import { Icon } from '../../icon/icon';
import type { StaticIconProps } from '../../icon/icon.types';

const Bear = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="64"
    height="64"
    viewBox="0 0 64 64"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <circle cx={32} cy={32} r={32} fill="#FF5500" />
    <circle cx={32} cy={32} r={32} fill="white" fillOpacity={0.8} />
    <circle cx={18} cy={19} r={8} fill="#FF5500" />
    <circle cx={18} cy={19} r={6} fill="#FF5500" />
    <circle cx={18} cy={19} r={6} fill="black" fillOpacity={0.2} />
    <circle cx={46} cy={19} r={8} fill="#FF5500" />
    <circle cx={46} cy={19} r={6} fill="#FF5500" />
    <circle cx={46} cy={19} r={6} fill="black" fillOpacity={0.2} />
    <circle cx={32} cy={33} r={22} fill="#FF5500" />
    <ellipse
      cx={15.7321}
      cy={43.4641}
      rx={2}
      ry={4}
      transform="rotate(30 15.7321 43.4641)"
      fill="#FF5500"
    />
    <ellipse
      rx={2}
      ry={4}
      transform="matrix(-0.866025 0.5 0.5 0.866025 48.7321 43.4641)"
      fill="#FF5500"
    />
    <ellipse
      cx={13.7321}
      cy={41.4641}
      rx={2}
      ry={4}
      transform="rotate(30 13.7321 41.4641)"
      fill="#FF5500"
    />
    <ellipse
      rx={2}
      ry={4}
      transform="matrix(-0.866025 0.5 0.5 0.866025 50.7321 41.4641)"
      fill="#FF5500"
    />
    <ellipse
      cx={11.7321}
      cy={39.4641}
      rx={2}
      ry={4}
      transform="rotate(30 11.7321 39.4641)"
      fill="#FF5500"
    />
    <ellipse
      rx={2}
      ry={4}
      transform="matrix(-0.866025 0.5 0.5 0.866025 52.7321 39.4641)"
      fill="#FF5500"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M21.6403 45.6527C21.8322 45.454 22.1487 45.4485 22.3473 45.6403C24.915 48.1199 29.085 48.1199 31.6527 45.6403C31.8513 45.4485 32.1678 45.454 32.3597 45.6527C32.5515 45.8513 32.546 46.1678 32.3473 46.3597C29.3921 49.2134 24.6079 49.2134 21.6527 46.3597C21.454 46.1678 21.4485 45.8513 21.6403 45.6527Z"
      fill="#0E0E0E"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M31.6403 45.6527C31.8322 45.454 32.1487 45.4485 32.3473 45.6403C34.915 48.1199 39.085 48.1199 41.6527 45.6403C41.8513 45.4485 42.1678 45.454 42.3597 45.6527C42.5515 45.8513 42.546 46.1678 42.3473 46.3597C39.3921 49.2134 34.6079 49.2134 31.6527 46.3597C31.454 46.1678 31.4485 45.8513 31.6403 45.6527Z"
      fill="#0E0E0E"
    />
    <circle cx={22} cy={29} r={6} fill="white" />
    <circle cx={42} cy={29} r={6} fill="white" />
    <circle cx={22} cy={29} r={4} fill="#FF9900" />
    <circle cx={42} cy={29} r={4} fill="#FF9900" />
    <circle cx={22} cy={29} r={2} fill="#0E0E0E" />
    <circle cx={42} cy={29} r={2} fill="#0E0E0E" />
    <circle cx={23} cy={28} r={1} fill="white" />
    <circle cx={43} cy={28} r={1} fill="white" />
    <path
      d="M33.4142 45.5858C32.6331 46.3668 31.3668 46.3668 30.5858 45.5858L27.4142 42.4142C26.1543 41.1543 27.0466 39 28.8284 39L35.1716 39C36.9534 39 37.8457 41.1543 36.5858 42.4142L33.4142 45.5858Z"
      fill="#0E0E0E"
    />
  </svg>
);
export const BearAvatar = ({ size = 'xl', ...props }: StaticIconProps) => {
  return <Icon icon={Bear} {...props} size={size} color={undefined} />;
};
