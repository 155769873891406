import { getCSSVar } from '../internal/utils/css';
import type { AllColorKeys, ColorKey, ReeferTheme } from '../theme';

export const lightBackgroundColors: ColorKey[] = [
  'primary-light',
  'secondary',
  'secondary-light',
  'info-light',
  'error',
  'error-light',
  'success',
  'success-light',
  'text-inverse',
  'text-info',
  'gold',
  'gold-light',
  'tangerine',
  'tangerine-light',
  'ember',
  'ember-light',
  'sunset-light',
  'grape-light',
  'purple-light',
  'emerald-light',
  'palm-light',
  'seafoam',
  'seafoam-light',
  'pacific-light',
  'grays-white',
  'grays-ultralight',
  'grays-light',
  'transparent',
];

export const COLOR_TO_CSS_VAR_MAPPING: { [key in AllColorKeys]: string } = {
  primary: '--colors-primary-main',
  'primary-light': '--colors-primary-light',
  'primary-dark': '--colors-primary-dark',
  secondary: '--colors-secondary-main',
  'secondary-light': '--colors-secondary-light',
  'secondary-dark': '--colors-secondary-dark',
  info: '--colors-info-main',
  'info-light': '--colors-info-light',
  'info-dark': '--colors-info-dark',
  success: '--colors-success-main',
  'success-light': '--colors-success-light',
  'success-dark': '--colors-success-dark',
  error: '--colors-error-main',
  'error-light': '--colors-error-light',
  'error-dark': '--colors-error-dark',
  'text-main': '--colors-text-main',
  'text-inverse': '--colors-text-inverse',
  'text-info': '--colors-text-info',
  'text-light': '--colors-text-light',
  gold: '--colors-brand-gold-main',
  'gold-light': '--colors-brand-gold-light',
  'gold-dark': '--colors-brand-gold-dark',
  tangerine: '--colors-brand-tangerine-main',
  'tangerine-light': '--colors-brand-tangerine-light',
  'tangerine-dark': '--colors-brand-tangerine-dark',
  ember: '--colors-brand-ember-main',
  'ember-light': '--colors-brand-ember-light',
  'ember-dark': '--colors-brand-ember-dark',
  sunset: '--colors-brand-sunset-main',
  'sunset-light': '--colors-brand-sunset-light',
  'sunset-dark': '--colors-brand-sunset-dark',
  grape: '--colors-brand-grape-main',
  'grape-light': '--colors-brand-grape-light',
  'grape-dark': '--colors-brand-grape-dark',
  purple: '--colors-brand-purple-main',
  'purple-light': '--colors-brand-purple-light',
  'purple-dark': '--colors-brand-purple-dark',
  emerald: '--colors-tertiary-emerald-main',
  'emerald-light': '--colors-tertiary-emerald-light',
  'emerald-dark': '--colors-tertiary-emerald-dark',
  palm: '--colors-tertiary-palm-main',
  'palm-light': '--colors-tertiary-palm-light',
  'palm-dark': '--colors-tertiary-palm-dark',
  seafoam: '--colors-tertiary-seafoam-main',
  'seafoam-light': '--colors-tertiary-seafoam-light',
  'seafoam-dark': '--colors-tertiary-seafoam-dark',
  pacific: '--colors-tertiary-pacific-main',
  'pacific-light': '--colors-tertiary-pacific-light',
  'pacific-dark': '--colors-tertiary-pacific-dark',
  'grays-white': '--colors-grays-white',
  'grays-ultralight': '--colors-grays-ultralight',
  'grays-light': '--colors-grays-light',
  'grays-mid': '--colors-grays-mid',
  'grays-dark': '--colors-grays-dark',
  'grays-black': '--colors-grays-black',
  'grays-hover': '--colors-grays-hover',
  'system-positive-light': '--colors-system-positive-light',
  'system-positive-dark': '--colors-system-positive-dark',
  'system-positive-main': '--colors-system-positive-main',
  'system-negative-light': '--colors-system-negative-light',
  'system-negative-dark': '--colors-system-negative-dark',
  'system-negative-main': '--colors-system-negative-main',
  'transparent-black-five': '--colors-transparent-black-five',
  'transparent-black-ten': '--colors-transparent-black-ten',
  'transparent-black-twenty': '--colors-transparent-black-twenty',
  'transparent-black-fifty': '--colors-transparent-black-fifty',
  'transparent-white-ten': '--colors-transparent-white-ten',
  background: '--colors-background',
  default: '--colors-default',
  transparent: '--colors-transparent',
  inherit: '--colors-inherit',
};

export function getColorCSSVar(color?: AllColorKeys, theme?: ReeferTheme) {
  return color && color !== 'default'
    ? getCSSVar(COLOR_TO_CSS_VAR_MAPPING[color])
    : getCSSVar(COLOR_TO_CSS_VAR_MAPPING['text-main']);
}

export const getColorValue = getColorCSSVar;

export function getColorForBackgroundCSSVar(
  background: ColorKey,
  theme?: ReeferTheme
) {
  return lightBackgroundColors.includes(background)
    ? getCSSVar(COLOR_TO_CSS_VAR_MAPPING['text-main'])
    : getCSSVar(COLOR_TO_CSS_VAR_MAPPING['text-inverse']);
}

export const textColorForBackgroundColor = getColorForBackgroundCSSVar;
