import * as t from 'io-ts';

import { tBrandSpecialUserSegmentIds } from './brandSpecial';
import type { DeepReadonly } from './deepReadonly';
import { tPhoto } from './photo';

export const tEffect = t.type({
  id: t.number,
  title: t.string,
  value: t.string,
});

export const tFlavor = t.type({
  id: t.number,
  title: t.string,
  value: t.string,
});

const tAlias = t.type({
  id: t.number,
  name: t.string,
});

export const tProductLineage = t.keyof({
  indica: null,
  sativa: null,
  hybrid: null,
  cbd: null,
});

export const tProductKind = t.keyof({
  gear: null,
  'pre-roll': null,
  extract: null,
  edible: null,
  merch: null,
  grow: null,
  tincture: null,
  flower: null,
  topical: null,
  vape: null,
});

export const tProductKindSoldByWeight = t.union([
  t.literal('extract'),
  t.literal('flower'),
  t.literal('vape'),
]);

export const tBusinessLicense = t.type({
  id: t.number,
  business_name: t.string,
  license_number: t.string,
  state: t.string,
});

export const tProductType = t.keyof({
  indica: null,
  sativa: null,
  hybrid: null,
  cbd: null,
  vape: null,
  extract: null,
  'pre-roll': null,
  edible: null,
  tincture: null,
  topical: null,
  gear: null,
  grow: null,
  merch: null,
  flower: null,
});

const tLocalizedContent = t.partial({
  name: t.union([t.string, t.null]),
  description: t.union([t.string, t.null]),
  lineage: t.union([tProductLineage, t.null]),
  brand_subtype: t.union([t.string, t.null]),
  country: t.union([t.string, t.null]),
  subdivision: t.union([t.string, t.null]),
  image_urls: t.array(t.string),
  photos: t.array(tPhoto),
});

const tBrandPriceIdSpecials = t.partial({
  brand_special_id: t.number,
  discount_label: t.string,
  price: t.string,
  title: t.string,
});

export const tBrandSpecialPrices = t.partial({
  each: t.union([tBrandPriceIdSpecials, t.null]),
  half_gram: t.union([tBrandPriceIdSpecials, t.null]),
  gram: t.union([tBrandPriceIdSpecials, t.null]),
  two_gram: t.union([tBrandPriceIdSpecials, t.null]),
  eighth_ounce: t.union([tBrandPriceIdSpecials, t.null]),
  quarter_ounce: t.union([tBrandPriceIdSpecials, t.null]),
  half_ounce: t.union([tBrandPriceIdSpecials, t.null]),
  ounce: t.union([tBrandPriceIdSpecials, t.null]),
});

const tProductOptionalProperties = t.partial({
  aggregate_rating: t.union([t.number, t.null]),
  aliases: t.array(tAlias),
  allergens: t.array(t.string),
  amount: t.union([t.string, t.null]),
  applicable_brand_special_ids: t.array(t.number),
  applicable_brand_specials_excluded_user_segments: tBrandSpecialUserSegmentIds,
  applicable_brand_specials_included_user_segments: tBrandSpecialUserSegmentIds,
  brand_special_has_segment_rules: t.union([t.boolean, t.null]),
  brand_discount_label: t.union([t.string, t.null]),
  brand_discount_title: t.union([t.string, t.null]),
  brand_special_prices: t.union([tBrandSpecialPrices, t.null]),
  brand_subtype: t.union([t.string, t.null]),
  business_licenses: t.union([t.array(tBusinessLicense), t.null]),
  category: t.union([tProductLineage, t.null]),
  cbd_dosage_milligrams: t.union([t.number, t.null, t.undefined]),
  description: t.string,
  dosage: t.union([t.string, t.null]),
  effects: t.array(tEffect),
  flavors: t.array(tFlavor),
  has_brand_discount: t.union([t.boolean, t.null]),
  image_urls: t.array(t.string),
  ingredients: t.array(t.string),
  localized_content: t.array(tLocalizedContent),
  lowest_available_price: t.partial({
    found: t.literal(true),
    val: t.union([t.string, t.undefined, t.null]),
    price: t.union([t.number, t.undefined, t.null]),
  }),
  net_weight_grams: t.union([t.number, t.null]),
  percent_cbd: t.union([t.number, t.null]),
  percent_cbda: t.union([t.number, t.null]),
  percent_thc: t.union([t.number, t.null]),
  percent_tac: t.union([t.number, t.null]),
  percent_thca: t.union([t.number, t.null]),
  percentCBDA: t.union([t.number, t.null]),
  percentTHCA: t.union([t.number, t.null]),
  photos: t.array(tPhoto),
  primaryPercentCBD: t.number,
  primaryPercentTHC: t.number,
  product_percent_cbd: t.union([t.number, t.null]),
  product_percent_thc: t.union([t.number, t.null]),
  quantity_units: t.union([t.string, t.null]),
  quantity_value: t.union([t.number, t.null]),
  recommended: t.boolean,
  review_count: t.union([t.number, t.null]),
  root_subtype: t.union([t.string, t.null]),
  secondaryPercentCBD: t.number,
  secondaryPercentTHC: t.number,
  terpenes: t.array(t.record(t.string, t.string)),
  thc_dosage_milligrams: t.union([t.number, t.null, t.undefined]),
  url_slug: t.string,
});

export const tProduct = t.intersection([
  t.type({
    brand: t.union([t.string, t.null]),
    id: t.number,
    kind: tProductKind,
    name: t.string,
    product_brand_id: t.union([t.number, t.null, t.undefined]),
    type: tProductType,
  }),
  tProductOptionalProperties,
]);

export type Effect = t.TypeOf<typeof tEffect>;
export type BrandPriceIdSpecials = t.TypeOf<typeof tBrandPriceIdSpecials>;
export type BrandSpecialPrices = t.TypeOf<typeof tBrandSpecialPrices>;
export type BusinessLicense = t.TypeOf<typeof tBusinessLicense>;
export type Flavor = t.TypeOf<typeof tFlavor>;
export type ProductLineage = t.TypeOf<typeof tProductLineage>;
export type ProductKind = t.TypeOf<typeof tProductKind>;
export type ProductKindSoldByWeight = t.TypeOf<typeof tProductKindSoldByWeight>;
export type Product = DeepReadonly<t.TypeOf<typeof tProduct>>;
export type ProductType = t.TypeOf<typeof tProductType>;
export type Photo = t.TypeOf<typeof tPhoto>;
