import { config } from '@jane/shared/config';
import { api } from '@jane/shared/data-access';

const buildUrl = (url: string) => `${config.dmUrl}${url}`;

export const dmApi = {
  delete: <T>(url: string, params?: RequestInit): Promise<T | null> =>
    api.delete(`${buildUrl(url)}`, params),
  get: <T>(url: string, params?: RequestInit): Promise<T> =>
    api.get(`${buildUrl(url)}`, params),
  patch: <T>(url: string, data: unknown, params?: RequestInit): Promise<T> =>
    api.patch(`${buildUrl(url)}`, data, params),
  post: <T>(url: string, data: unknown, params?: RequestInit): Promise<T> =>
    api.post(`${buildUrl(url)}`, data, params),
};
