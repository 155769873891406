import {
  Box,
  ChevronLeftIcon,
  DismissIcon,
  Flex,
  SearchField,
  Typography,
  useMobileMediaQuery,
} from '@jane/shared/reefer';

import { DeselectAllButton } from './deselectAllButton';
import {
  IconContainer,
  StyledModalTypeaheadHeader,
} from './filterModal.styles';

interface FilterModalTypeaheadHeaderProps {
  isNested?: boolean;
  onClose: () => void;
  onDeselect: () => void;
  onSearchChange: (value: string) => void;
  searchQuery: string;
  title: string;
}

export const FilterModalTypeaheadHeader = ({
  isNested,
  onClose,
  onDeselect,
  onSearchChange,
  searchQuery,
  title,
}: FilterModalTypeaheadHeaderProps) => {
  const isMobile = useMobileMediaQuery({});

  return (
    <StyledModalTypeaheadHeader>
      <Flex width="100%" alignItems="center">
        <IconContainer>
          {isNested ? (
            <ChevronLeftIcon
              altText="Back to previous modal"
              data-testid="modal-header-back-icon"
              onClick={onClose}
            />
          ) : (
            <DismissIcon
              altText="Close Modal"
              data-testid="modal-header-close-icon"
              onClick={onClose}
            />
          )}
        </IconContainer>
        <Flex flexDirection="column" alignSelf="center" width="100%" mr={24}>
          <Typography branded variant="header-bold" textAlign="center">
            {title}
          </Typography>
        </Flex>

        <Box position="absolute" right={24}>
          <Flex alignSelf="center" width="100%">
            <DeselectAllButton onClick={onDeselect} />
          </Flex>
        </Box>
      </Flex>
      <SearchField
        label={`Search ${title}`}
        name="typeahead search"
        defaultValue={searchQuery}
        onChange={onSearchChange}
        disableMobileInputStyling
        width="100%"
        mt={isMobile ? 32 : 20}
      />
    </StyledModalTypeaheadHeader>
  );
};
