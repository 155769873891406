import type { PrepaymentProviders } from '@jane/shared-ecomm/providers';
import type {
  AeropayIntegration,
  JanePayIntegration,
} from '@jane/shared/types';

export const filterPrepaymentProviders = ({
  aeropayIntegration,
  janePayIntegration,
  pickupMode,
  prepaymentOptions,
  reservationMode,
}: {
  aeropayIntegration: AeropayIntegration | null | undefined;
  janePayIntegration: JanePayIntegration | null | undefined;
  pickupMode: 'pickup' | 'curbside';
  prepaymentOptions: PrepaymentProviders[];
  reservationMode: 'delivery' | 'pickup' | 'kiosk' | 'curbside';
}): PrepaymentProviders[] => {
  const aeropayReservationModes =
    aeropayIntegration && aeropayIntegration?.reservation_modes;
  const janePayReservationModes =
    janePayIntegration && janePayIntegration?.reservation_modes;

  let filteredProviders = prepaymentOptions;
  let reservationModeWithPickupMode = null;

  reservationModeWithPickupMode =
    reservationMode === 'delivery'
      ? 'delivery'
      : pickupMode === 'pickup'
      ? 'pickup'
      : 'curbside';

  if (
    aeropayReservationModes &&
    !aeropayReservationModes.includes(reservationModeWithPickupMode)
  ) {
    filteredProviders = filteredProviders.filter((val) => val !== 'aeropay');
    filteredProviders = filteredProviders.filter(
      (val) => val !== 'aeropay_white_label'
    );
  }

  if (
    janePayReservationModes &&
    !janePayReservationModes.includes(reservationModeWithPickupMode)
  ) {
    filteredProviders = filteredProviders.filter((val) => val !== 'jane_pay');
  }

  return filteredProviders;
};
