import upperFirst from 'lodash/upperFirst';

import type {
  DeepReadonly,
  DeliveryValidation,
  ReservationMode,
  Store,
} from '@jane/shared/models';
import { formatCurrency } from '@jane/shared/util';

import minimumDeliveryOrder from './minimumDeliveryOrder';
import type OrderingSchedule from './orderingSchedule';
import type StoreSchedule from './storeSchedule';

export default function (
  store: Store,
  orderingSchedule: DeepReadonly<OrderingSchedule>,
  cartSubtotal: number,
  reservationMode: ReservationMode,
  deliveryValidateData?: DeliveryValidation | undefined
) {
  const genericMessage = `You can't proceed to checkout because ${store.name} is closed`;
  const reservationModeMessage = `${store.name} online ordering for ${reservationMode} is`;

  const reservationSchedule = (
    reservationMode: ReservationMode
  ): StoreSchedule => orderingSchedule[`${reservationMode}Schedule`];

  const schedule = reservationSchedule(reservationMode) || orderingSchedule;

  const openLaterToday = schedule?.willOpenLaterToday;
  const nextOpenDay = schedule?.nextOpenDay;
  const pastLastCall =
    reservationSchedule(reservationMode)?.currentTime?.unix() >=
    schedule?.today?.lastCallTime?.unix();

  if (
    !reservationMode &&
    reservationSchedule('delivery')?.isCurrentlyClosed &&
    reservationSchedule('pickup')?.isCurrentlyClosed
  ) {
    return genericMessage;
  }

  if (schedule?.isCurrentlyClosed && !schedule?.allowOffHoursOrdering) {
    if (openLaterToday) {
      return `${reservationModeMessage} closed until ${schedule.today.displayOpeningTime} today`;
    }

    if (nextOpenDay) {
      return `${reservationModeMessage} closed until ${
        nextOpenDay.displayOpeningTime
      } on ${upperFirst(nextOpenDay.name)}`;
    }
    return genericMessage;
  }

  if (pastLastCall && !orderingSchedule.allowOffHoursOrdering) {
    if (nextOpenDay) {
      return `${reservationModeMessage} closed until ${
        nextOpenDay.displayOpeningTime
      } on ${upperFirst(nextOpenDay.name)}`;
    }

    return `${reservationModeMessage} closed`;
  }

  if (orderingSchedule.isAlwaysClosed) {
    return `${genericMessage} for pre-ordering`;
  }

  if (
    deliveryValidateData &&
    !minimumDeliveryOrder({
      discountedSubtotal: cartSubtotal,
      store,
      deliveryValidateData,
    })
  ) {
    return `${
      store.name
    } requires a minimum delivery purchase of ${formatCurrency(
      deliveryValidateData?.minimum || 0
    )}`;
  }

  return undefined;
}
