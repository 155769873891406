/**
 * DO NOT MODIFY THIS FILE!
 * It was created by the reefer-icons generator.
 * Please update & re-run the generator if need be.
 */
import type { SVGProps } from 'react';

import type { StaticIconProps } from '../../../icon/icon.types';
import { Icon } from '../../icon';

const Blossom24 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="100%"
    height="100%"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <g className="Blossom 24" clipPath="url(#a)">
      <path
        fill="#4485DA"
        fillRule="evenodd"
        d="M4.197 8.768c-1.415-2.026-1.754-4.18-.682-5.253 1.072-1.072 3.227-.733 5.253.681C9.2 1.764 10.484 0 12 0c1.516 0 2.8 1.764 3.232 4.197 2.026-1.414 4.181-1.754 5.253-.682 1.073 1.073.733 3.227-.681 5.253C22.236 9.201 24 10.484 24 12c0 1.517-1.764 2.8-4.196 3.233 1.414 2.026 1.753 4.18.681 5.252-1.072 1.073-3.227.733-5.253-.68C14.8 22.235 13.516 24 12 24c-1.516 0-2.8-1.764-3.233-4.196-2.025 1.414-4.18 1.754-5.252.681-1.072-1.072-.733-3.226.681-5.252C1.764 14.8 0 13.517 0 12c0-1.517 1.764-2.8 4.197-3.233Z"
        className="Fill-Main"
        clipRule="evenodd"
      />
      <circle
        cx={12}
        cy={12.001}
        r={3.273}
        fill="#FFC220"
        className="Fill-Accent"
      />
    </g>
    <defs>
      <clipPath>
        <path fill="#fff" d="M0 0h24v24H0z" />
      </clipPath>
    </defs>
  </svg>
);

const SIZE_MAP = {
  md: Blossom24,
  sm: Blossom24,
  lg: Blossom24,
  xl: Blossom24,
  xxl: Blossom24,
  xxxl: Blossom24,
};

export const BlossomIcon = ({
  size = 'md',
  'data-testid': testId,
  ...props
}: StaticIconProps) => {
  return (
    <Icon
      data-testid={testId || 'blossom-icon'}
      icon={SIZE_MAP[size]}
      {...props}
      size={size}
      color={undefined}
    />
  );
};
