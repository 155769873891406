export type MediaQueryMobileSize = 'sm' | 'md' | 'lg' | 'legacy';
export type MediaQueryDesktopSize = 'sm' | 'md' | 'lg';

export type MediaQueryParam = 'min' | 'max';

export const MEDIAQUERIES_SIZE_MAP = {
  'desktop-lg': '2560px',

  'desktop-md': '1440px',

  'desktop-sm': '1024px',

  /**
   * mobile-legacy is not an official breakpoint.
   * It is used as a direct replacement for the legacy MediaQuery wrapper's mobile breakpoint
   */
  'mobile-legacy': '480px',
  'mobile-lg': '425px',
  'mobile-md': '375px',
  'mobile-sm': '320px',
  tablet: '768px',
};

export const mediaQueries = {
  desktop: (size: MediaQueryDesktopSize, width: MediaQueryParam) => {
    const screenSize = MEDIAQUERIES_SIZE_MAP[`desktop-${size}`];
    return `@media (${width}-width: ${screenSize})`;
  },
  mobile: (size: MediaQueryMobileSize, width: MediaQueryParam) => {
    const screenSize = MEDIAQUERIES_SIZE_MAP[`mobile-${size}`];
    return `@media (${width}-width: ${screenSize})`;
  },
  tablet: (width: MediaQueryParam) => {
    return `@media (${width}-width: ${MEDIAQUERIES_SIZE_MAP['tablet']})`;
  },
};
