import styled from '@emotion/styled';

import useDisplayGTITerms from '../hooks/useDisplayGTITerms';

const FinePrintBase = styled.div<{ gtiColors: boolean }>(
  ({ theme, gtiColors }) => ({
    color: gtiColors ? theme.colors.grays.black : theme.colors.text.light,
    display: 'inline-block',
  })
);

export const FinePrint = ({ children }) => {
  const displayGTITerms = useDisplayGTITerms();

  return <FinePrintBase gtiColors={displayGTITerms}>{children}</FinePrintBase>;
};
