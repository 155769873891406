import type { CardCarouselMode } from './cardCarousel.types';

export const getCardCarouselDimensions = (
  mode: CardCarouselMode,
  cardWidthProp: number,
  cardGapDesktop: number,
  width: number
) => {
  let cardWidth = cardWidthProp;
  let cardGap = cardGapDesktop;
  let cardCount;
  const scrollAreaWidthDesktop = width + cardGapDesktop;
  const cardFullWidth = cardWidth + cardGap;

  if (mode === 'max-width') {
    cardCount = Math.ceil(scrollAreaWidthDesktop / cardFullWidth);
    cardWidth = scrollAreaWidthDesktop / cardCount - cardGapDesktop;
  } else if (mode === 'ideal-width') {
    const cardCountMin = Math.floor(scrollAreaWidthDesktop / cardFullWidth);
    const cardCountMax = Math.ceil(scrollAreaWidthDesktop / cardFullWidth);
    const cardWidthMin = scrollAreaWidthDesktop / cardCountMin - cardGapDesktop;
    const cardWidthMax = scrollAreaWidthDesktop / cardCountMax - cardGapDesktop;
    cardCount =
      Math.abs(cardWidthProp - cardWidthMin) <=
      Math.abs(cardWidthProp - cardWidthMax)
        ? cardCountMin
        : cardCountMax;
    cardWidth = scrollAreaWidthDesktop / cardCount - cardGapDesktop;
  } else if (mode === 'min-gap') {
    cardCount = Math.floor(scrollAreaWidthDesktop / cardFullWidth);
    cardGap =
      cardGap +
      (scrollAreaWidthDesktop - cardFullWidth * cardCount) / (cardCount - 1);
  }

  return { cardGap, cardWidth };
};
