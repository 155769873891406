import { useInfiniteQuery, useQuery } from '@tanstack/react-query';
import algoliasearch from 'algoliasearch';
import once from 'lodash/once';
import type { SafeParseError, ZodTypeAny } from 'zod';

import type {
  AlgoliaConfig,
  SearchProps,
  SearchResponse,
} from '@jane/search/types';
import {
  buildBooleanFilter,
  buildBucketFilter,
  buildFilter,
  buildRangeFilter,
  composeFilters,
} from '@jane/search/util';
import { config } from '@jane/shared/config';

export const getAlgoliaConfig = (): AlgoliaConfig => ({
  algoliaApiKey: config.algoliaApiKey,
  algoliaAppId: config.algoliaAppId,
  algoliaEnv: config.algoliaEnv,
  algoliaUrl: config.algoliaUrl,
});

export const getAlgoliaClient = once(
  (config: AlgoliaConfig = getAlgoliaConfig()) => {
    const defaultOptions = {
      timeouts: {
        // https://www.algolia.com/doc/api-reference/api-methods/configuring-timeouts/?client=javascript
        connect: 4, // (Default: 2) connection timeout in seconds
        read: 5, // (Default: 5) read timeout in seconds
        write: 30, // (Default: 30) write timeout in seconds
      },
    };
    const options = config.algoliaUrl
      ? { hosts: [{ url: config.algoliaUrl }], ...defaultOptions }
      : defaultOptions;
    return algoliasearch(config.algoliaAppId, config.algoliaApiKey, options);
  }
);

export function getAlgoliaIndex(indexPrefix: string) {
  const config = getAlgoliaConfig();
  const client = getAlgoliaClient(config);
  return client.initIndex(`${indexPrefix}${config.algoliaEnv}`);
}

export function useSearch<T>({ enabled = true, ...params }: SearchProps<T>) {
  return useQuery({
    enabled,
    queryFn: () => search<T>(params),
    queryKey: ['searchResponse', JSON.stringify(params)],
  });
}

export function useInfiniteSearch<T>({
  enabled = true,
  ...params
}: SearchProps<T>) {
  return useInfiniteQuery({
    enabled,
    getNextPageParam: (lastPage: SearchResponse<T>) => {
      if (lastPage.page + 1 < lastPage.nbPages) {
        return lastPage.page + 1;
      }
      return undefined;
    },
    onError: params.onError,
    queryFn: ({ pageParam }: { pageParam?: number }) =>
      search<T>({ ...params, page: pageParam }),
    queryKey: ['infiniteSearchResponse', JSON.stringify(params)],
  });
}

export async function search<T>(
  {
    booleanFilters,
    bucketFilters,
    hitsPerPage,
    indexPrefix,
    filters,
    options,
    page,
    rangeFilters,
    searchText,
    facets,
    currentSort,
    staticFilters,
  }: SearchProps<T>,
  schema?: ZodTypeAny
) {
  const prefix = currentSort
    ? `${indexPrefix}${currentSort.suffix}`
    : indexPrefix;

  const index = getAlgoliaIndex(prefix);

  const composedFilters = composeFilters(
    ...(staticFilters ? [staticFilters] : []),
    ...(filters
      ? Object.keys(filters).map((attribute) =>
          buildFilter(attribute, filters[attribute as keyof T])
        )
      : []),
    ...(rangeFilters
      ? Object.keys(rangeFilters).map((attribute) => {
          const value = rangeFilters[attribute as keyof T];

          return buildRangeFilter(attribute, value?.min, value?.max);
        })
      : []),
    ...(bucketFilters
      ? Object.keys(bucketFilters).map((attribute) => {
          const value = bucketFilters[attribute as keyof T];

          return buildBucketFilter(attribute, value);
        })
      : []),
    ...(booleanFilters
      ? Object.keys(booleanFilters).map((attribute) => {
          const value = booleanFilters[attribute as keyof T];

          return buildBooleanFilter(attribute, value);
        })
      : [])
  );

  let requestOptions: Parameters<typeof index.search>[1] = {
    filters: composedFilters,
    ...(hitsPerPage && { hitsPerPage: Number(hitsPerPage) }),
    ...(page && { page: Number(page) }),
    ...options,
  };

  if (facets && facets.length > 0) {
    requestOptions = {
      ...requestOptions,
      facets,
    };
  }

  const response = await index
    .search<T>(searchText ?? '', requestOptions)
    .then((response) => ({ ...response, index: index.indexName }));

  if (schema) {
    const { hits, ...rest } = response;
    const parsed = await schema.safeParseAsync(hits);

    if (!parsed.success) {
      const error = (parsed as SafeParseError<T>).error;
      error.issues.forEach((e) => console.error(e));
    }

    return {
      hits: parsed.success ? parsed.data : hits,
      ...rest,
    } as SearchResponse<T>;
  }

  return response;
}
