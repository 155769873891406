interface ParseImageResult {
  orientation: number;
  url: string;
}

function toBase64(arrayBuffer: ArrayBufferLike): string {
  let binary = '';

  if (!arrayBuffer) return binary;

  const bytes = new Uint8Array(arrayBuffer);
  const len = bytes.byteLength;

  for (let i = 0; i < len; i++) {
    binary += String.fromCharCode(bytes[i]);
  }

  return window.btoa(binary);
}

/* eslint-disable max-statements */
function orientationFromExif(arrayBuffer: ArrayBufferLike): number {
  let idx = 0;
  let orientationValue = 1;

  if (!arrayBuffer) return orientationValue;

  const scanner = new DataView(arrayBuffer);

  if (arrayBuffer.byteLength < 2 || scanner.getUint16(idx) !== 0xffd8) {
    // not a JPEG
    return orientationValue;
  }

  idx += 2;

  let maxBytes = scanner.byteLength;
  while (idx < maxBytes - 2) {
    const uint16 = scanner.getUint16(idx);
    idx += 2;

    switch (uint16) {
      case 0xffe1: {
        // start of EXIF
        const exifLength = scanner.getUint16(idx);
        maxBytes = exifLength - idx;
        idx += 2;
        break;
      }
      case 0x0112: {
        // get orientation tag
        orientationValue = scanner.getUint16(idx + 6, false);
        maxBytes = 0; // stop scanning
        break;
      }
    }
  }

  return orientationValue;
}
/* eslint-enable max-statements */

export const ImagePreview = {
  parse: (file: File): Promise<ParseImageResult> =>
    new Promise((resolve, reject) => {
      const fileReader = new FileReader();

      fileReader.onloadend = () => {
        if (fileReader.result === null) {
          return reject(new Error('file unreadable or empty'));
        }

        const url = `data:${file.type};base64,${toBase64(
          fileReader.result as ArrayBufferLike
        )}`;

        const orientation = orientationFromExif(
          fileReader.result as ArrayBufferLike
        );

        resolve({ orientation, url });
      };

      fileReader.readAsArrayBuffer(file);
    }),

  rotationForOrientation: (orientation: number): number => {
    const ORIENTATIONS_TO_DEGREES: Record<number, number> = {
      1: 0,
      3: 180,
      6: 90,
      8: 270,
    };

    return ORIENTATIONS_TO_DEGREES[orientation] || 0;
  },
};
