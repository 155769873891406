import kebabCase from 'lodash/kebabCase';

import type {
  EcommPathsConfig,
  MenuCategoryPath,
  MenuProduct,
  MenuSpecialPath,
  NewProductReviewPath,
  Query,
} from '@jane/shared/types';

import { buildPath } from './buildPath';

export const getEcommPaths = (config: EcommPathsConfig) => {
  const { appMode, storePathProps } = config;

  const useStoreSlug = appMode === 'default';

  let storePath = `stores/${storePathProps?.id}`;
  if (useStoreSlug) {
    storePath += `/${storePathProps?.slug || kebabCase(storePathProps?.name)}`;
  }

  const menuPath =
    appMode === 'default' || appMode === 'brandEmbed'
      ? `${storePath}/menu`
      : 'menu';

  return {
    cart() {
      return buildPath(config, '/cart');
    },
    checkout() {
      return buildPath(config, '/cart/checkout');
    },
    login() {
      return buildPath(config, '/login');
    },
    menu(query?: Query) {
      return buildPath(config, `/${menuPath}`, query);
    },
    menuCategory(category: MenuCategoryPath, query?: Query) {
      return buildPath(config, `/${menuPath}/${category}`, query);
    },
    menuFeaturedCategory(featuredCategory: string, query?: Query) {
      return buildPath(
        config,
        `/${menuPath}/featured/${encodeURIComponent(
          kebabCase(featuredCategory)
        )}`,
        query
      );
    },
    menuReviews() {
      return buildPath(config, `/${storePath}/reviews`);
    },
    menuProduct(
      product: Pick<MenuProduct, 'brand' | 'id' | 'name' | 'url_slug'>
    ) {
      const slug = product.url_slug
        ? product.url_slug
        : kebabCase(`${product.brand ? product.brand : ''} ${product.name}`);
      return buildPath(config, `/${menuPath}/products/${product.id}/${slug}`);
    },
    menuSpecial({ id, title }: MenuSpecialPath) {
      return buildPath(
        config,
        `/${menuPath}/specials/${id}/${kebabCase(title)}`
      );
    },
    newProductReview({ id, name }: NewProductReviewPath) {
      return buildPath(
        config,
        `/products/${id}/${encodeURIComponent(name)}/reviews/new/`,
        {
          storeId: storePathProps?.id,
          storeName: storePathProps?.name,
        }
      );
    },
    profile() {
      return buildPath(config, '/profile');
    },
  };
};
