import { Button, ErrorIcon, Flex, Link, Typography } from '@jane/shared/reefer';

import { ERROR_MESSAGES, parseError } from './parseError';

export interface FailureScreenProps {
  /** The error; if available */
  error?: unknown;

  /** Function called when 'done' button is clicked */
  onDoneClick(): void;

  /** Function called when 'try again' button is clicked */
  onTryAgainClick(): void;
}

export const FailureScreen = ({
  error,
  onDoneClick,
  onTryAgainClick,
}: FailureScreenProps) => {
  const { message, tryAgain } = parseError(error);
  const supportEmail = 'janegold@iheartjane.com';
  const contactText = message.includes(ERROR_MESSAGES.existingNumber.message)
    ? 'If you feel this is incorrect, please contact'
    : 'Please contact';

  return (
    <Flex width="100%" flexDirection="column" alignItems="center">
      <ErrorIcon size="xxl" color="primary" mb={24} />
      <Typography variant="header-bold" textAlign="center" mb={8} branded>
        {message}
      </Typography>
      {tryAgain ? (
        <Typography textAlign="center" mb={24}>
          Try again or contact{' '}
          <Link
            ariaLabel="link for iheartjane email"
            href={`mailto: ${supportEmail}`}
            data-testid="link-test-id"
          >
            {supportEmail}
          </Link>{' '}
          for more help.
        </Typography>
      ) : (
        <Typography textAlign="center" mb={24}>
          {contactText}
          <Link
            ariaLabel="link for iheartjane email"
            href={`mailto: ${supportEmail}`}
            data-testid="link-test-id"
          >
            {supportEmail}
          </Link>{' '}
          so we can resolve this issue.
        </Typography>
      )}

      <Button
        full
        variant="primary"
        label="Done"
        mb={16}
        onClick={onDoneClick}
      />
      {tryAgain && (
        <Button
          full
          variant="secondary"
          label="Try again"
          onClick={onTryAgainClick}
          data-testid="try-again-btn"
        />
      )}
    </Flex>
  );
};
