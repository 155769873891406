export class SdkError extends Error {
  constructor(message: string) {
    super(`[Jane DM SDK]: ${message}`);
    this.name = 'SdkError';

    // Set the prototype explicitly for compatibility with transpilers
    Object.setPrototypeOf(this, SdkError.prototype);
  }
}

export function isSdkError(error: unknown): error is SdkError {
  return error instanceof SdkError;
}
