/**
 * DO NOT MODIFY THIS FILE!
 * It was created by the reefer-icons generator.
 * Please update & re-run the generator if need be.
 */
import type { SVGProps } from 'react';

import type { StaticIconProps } from '../../../icon/icon.types';
import { Icon } from '../../icon';

const Lightning24 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="100%"
    height="100%"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <g className="Pawprint 25">
      <path
        fill="#FF6334"
        fillRule="evenodd"
        d="M13.029.285a2 2 0 0 0-2.744.686l-6 10A2 2 0 0 0 6 14.001h8.468l-4.183 6.97a2 2 0 1 0 3.43 2.058l6-10A2 2 0 0 0 18 10H9.532l4.183-6.97a2 2 0 0 0-.686-2.745Z"
        className="Fill-Main"
        clipRule="evenodd"
      />
    </g>
  </svg>
);

const SIZE_MAP = {
  md: Lightning24,
  sm: Lightning24,
  lg: Lightning24,
  xl: Lightning24,
  xxl: Lightning24,
  xxxl: Lightning24,
};

export const LightningIcon = ({
  size = 'md',
  'data-testid': testId,
  ...props
}: StaticIconProps) => {
  return (
    <Icon
      data-testid={testId || 'lightning-icon'}
      icon={SIZE_MAP[size]}
      {...props}
      size={size}
      color={undefined}
    />
  );
};
