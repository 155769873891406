import * as t from 'io-ts';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { config } from '@jane/shared/config';
import { useRuntimeConfig } from '@jane/shared/runtime-config';

import { getIsDocumentVisible } from './useDocumentVisible';
import { useInterval } from './useInterval';
import { useIsOnline } from './useIsOnline';
import { useSource } from './useSource';

const DEFAULT_POLLING_INTERVAL_SECONDS = 60 * 5;

interface Props {
  currentVersion?: string;
  pollingIntervalSeconds?: number;
}

export const useForceClientRefresh = (
  {
    currentVersion = config.buildVersion,
    pollingIntervalSeconds = DEFAULT_POLLING_INTERVAL_SECONDS,
  }: Props = {
    currentVersion: config.buildVersion,
    pollingIntervalSeconds: DEFAULT_POLLING_INTERVAL_SECONDS,
  }
) => {
  const location = useLocation();
  const [pullNewVersion, setPullNewVersion] = useState(false);
  const [newVersionAvailable, setNewVersionAvailable] = useState(false);
  const { isPartnerHosted } = useRuntimeConfig();
  const isOnline = useIsOnline();

  const { data, fetch } = useSource(
    {
      url: isPartnerHosted
        ? `${config.deployPublicPath}version.json`
        : '/version.json',
      type: t.interface({
        buildVersion: t.string,
      }),
    },
    { trackErrors: false, manual: true, simple: true }
  );

  useInterval(
    () => {
      setPullNewVersion(true);
    },
    newVersionAvailable ? null : pollingIntervalSeconds * 1000
  );

  useEffect(() => {
    const documentVisible = getIsDocumentVisible();
    if (!documentVisible) {
      setPullNewVersion(false);
    }
    if (documentVisible && pullNewVersion && isOnline) {
      fetch();
      setPullNewVersion(false);
    }
  }, [pullNewVersion, isOnline]);

  useEffect(() => {
    if (!data) {
      return;
    }
    if (data.buildVersion !== currentVersion) {
      setNewVersionAvailable(data.buildVersion !== currentVersion);
    }
  }, [data, currentVersion]);

  useEffect(() => {
    if (newVersionAvailable && !location.pathname.includes('checkout')) {
      window.location.reload();
    }
  }, [newVersionAvailable, location]);
};
