import type {
  Cart,
  CustomerReservationHistory,
  _DeepReadonlyArray,
} from '@jane/shared/models';

export const calcNumberOfCartItems = (cart: Cart) =>
  cart.products.reduce((acc, cartProduct) => {
    acc += cartProduct.count;
    return acc;
  }, 0);

const cartResult = (cart: Cart & { status: string | undefined }) => ({
  cart,
  isPendingCart: true,
  isReservation: false,
});

const reservationResult = (reservation: CustomerReservationHistory) => ({
  cart: reservation,
  isPendingCart: false,
  isReservation: true,
});

const emptyResult = () => ({
  cart: undefined,
  isPendingCart: false,
  isReservation: false,
});

export const highestPriorityCartDecider = (
  cart: Cart & { status: string | undefined },
  inProgressReservations: _DeepReadonlyArray<CustomerReservationHistory>
) => {
  if (cart.status === 'pending') {
    return cartResult(cart);
  }

  if (inProgressReservations.length > 0) {
    return reservationResult(inProgressReservations[0]);
  }

  return emptyResult();
};

export const inProgressStatuses = [
  'verification',
  'staff_member_review',
  'preparing',
  'delivery_started',
  'delivery_finished',
  'ready_for_pickup',
];
