import type { Dispatch, SetStateAction } from 'react';
import { useEffect, useRef, useState } from 'react';

/**
 * Ensure that setState is never called when component is unmounted.
 */
export const useSafeState = <S>(
  initialState: S | (() => S)
): [S, Dispatch<SetStateAction<S>>] => {
  const [state, setState] = useState<S>(initialState);
  const mountedRef = useRef(false);

  useEffect(() => {
    mountedRef.current = true;
    return () => {
      mountedRef.current = false;
    };
  }, []);

  const safeSetState: Dispatch<SetStateAction<S>> = (newState) =>
    mountedRef.current && setState(newState);

  return [state, safeSetState];
};
