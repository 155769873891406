import type { StoreCommunicationBannerState } from '@jane/shared/models';
import { Storage } from '@jane/shared/util';

export const getExpandDefaultState = (
  bannerSettings: StoreCommunicationBannerState
) => {
  if (!bannerSettings.updated_at) return bannerSettings.is_expanded;

  const localCommBannerState = Storage.get('communication-banner');
  if (!localCommBannerState) return bannerSettings.is_expanded;

  if (localCommBannerState.updated_at) {
    const previousUpdatedAt = new Date(localCommBannerState.updated_at);
    const currentUpdatedAt = new Date(bannerSettings.updated_at);

    return previousUpdatedAt.getTime() < currentUpdatedAt.getTime();
  }

  return true;
};
