import styled from '@emotion/styled';
import { useEffect, useState } from 'react';

import { Flex } from '@jane/shared/reefer';

import { useCustomerDispatch } from '../../customer/dispatch';
import { updateBirthDate } from '../../customer/redux/identification';
import { useCustomerSelector } from '../../customer/selectors';
import { ageAtTimezone } from '../../lib/qualifiedGroup';
import { get } from '../../redux-util/selectors';
import { DateInput } from '../dateInputField';
import { InstructionsText } from '../instructionsText';
import type { IdentificationProps } from './identification.types';
import { ValidationIcon } from './validationIcon';
import { WarningMessage } from './warningMessage';

const BirthDateOuter = styled.div(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  '& input': [
    {
      border: `1px solid ${theme.colors.grays.light}`,
      width: '100%',
      height: 40,
      maxWidth: '120px',
      borderRadius: theme.borderRadius.sm,
      padding: '0 8px',
    },
  ],
}));

const validBirthdateRegex = /^\d{4}-\d{2}-\d{2}$/;

interface Props extends IdentificationProps {
  requiredAge: number;
  timezone: string;
}
const SeniorIdentification = ({ requiredAge, timezone }: Props) => {
  const idStoreState = useCustomerSelector(get('identification'));
  const dispatch = useCustomerDispatch();
  const { document } = idStoreState;
  const [ageAtStore, setAgeAtStore] = useState<number | undefined>();

  useEffect(() => {
    const { birth_date } = document;
    const ageAtStore = ageAtTimezone(birth_date, timezone);
    setAgeAtStore(ageAtStore);
  }, [document.birth_date]);

  if (!document) return null;
  const meetsRequiredBirthDate = ageAtStore && ageAtStore >= requiredAge;
  const maybeUpdateBirthDate = (value = '') => {
    if (
      !value ||
      !validBirthdateRegex.test(value) ||
      value === document.birth_date
    ) {
      return;
    }

    dispatch(updateBirthDate(value));
  };

  return (
    <Flex flexDirection="column">
      <InstructionsText>Enter your birth date:</InstructionsText>
      <BirthDateOuter>
        <DateInput
          date={document.birth_date || ''}
          onChange={maybeUpdateBirthDate}
          invalid={!meetsRequiredBirthDate}
        />
        {!!(document.government_photo && meetsRequiredBirthDate) && (
          <ValidationIcon />
        )}
      </BirthDateOuter>
      {!meetsRequiredBirthDate && (
        <WarningMessage>
          You do not meet the age requirement for this discount.
        </WarningMessage>
      )}
    </Flex>
  );
};

export default SeniorIdentification;
