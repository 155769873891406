import { forwardRef, useEffect, useState } from 'react';
import type { ChangeEvent, FocusEvent } from 'react';

import { Box } from '../../../box';
import type { BooleanFieldProps, SwitchFieldProps } from '../field.types';
import { FieldWrapper } from '../fieldWrapper/fieldWrapper';
import styles from './switchField.module.css';

export const SwitchFieldInternal = forwardRef<
  HTMLInputElement,
  SwitchFieldProps
>(
  (
    {
      autoFocus = false,
      checked: externalChecked,
      children,
      className,
      defaultChecked = false,
      disabled = false,
      helperText,
      id,
      label,
      labelHidden = false,
      name,
      onBlur,
      onChange,
      onClick,
      onUpdate,
      'data-testid': testId,
      width = 'auto',
      ...props
    },
    ref
  ) => {
    const [checked, setChecked] = useState(defaultChecked);

    useEffect(() => {
      if (externalChecked !== undefined && externalChecked !== checked) {
        setChecked(externalChecked);
        onUpdate && onUpdate(externalChecked);
      }
    }, [externalChecked, onUpdate, checked]);

    const handleBlur = (event: FocusEvent<HTMLInputElement>) => {
      setChecked(event.target.checked);
      onBlur && onBlur(event.target.checked);
    };

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
      setChecked(event.target.checked);
      onChange && onChange(event.target.checked);
    };

    return (
      <FieldWrapper
        className={className}
        disabled={disabled}
        helperText={helperText}
        id={id}
        label={label}
        labelHidden={labelHidden}
        labelPosition="r"
        name={name}
        render={(renderProps) => (
          <Box height={24} position="relative">
            <input
              autoFocus={autoFocus}
              checked={checked}
              className={styles.switchField_input}
              disabled={disabled}
              id={name}
              name={name}
              onBlur={handleBlur}
              onChange={handleChange}
              onClick={onClick}
              ref={ref}
              type="checkbox"
              {...renderProps}
            />
            <div role="presentation" />
          </Box>
        )}
        data-testid={testId}
        width={width}
        {...props}
      >
        {children}
      </FieldWrapper>
    );
  }
);

/**
 * Similar to checkbox fields, switch fields allow users to choose an option, or not.
 *
 * Use this component *outside forms* for inputs of `type`:
 * `checkbox` displayed as a switch.
 *
 * **NOTE: DO NOT USE THIS COMPONENT WITHIN FORMS**
 *
 * *For a similar component for use within forms, see [`Form.SwitchField`](/story/components-forms-form-switchfield--default).*
 */
export const SwitchField = forwardRef<HTMLInputElement, BooleanFieldProps>(
  (props, ref) => <SwitchFieldInternal ref={ref} {...props} />
);
