/* eslint-disable-next-line no-restricted-imports */
import type {
  LDContext,
  LDSingleKindContext,
} from 'launchdarkly-js-client-sdk';

import type { AppMode, Id } from '@jane/shared/models';

/** Default context key values */
export const DEFAULT_USER_KEY = 'TEMPORARY_USER_ID';

export const DEFAULT_USER_CONTEXT: LDContext = {
  anonymous: true,
  /** Provide a temporary user ID so LaunchDarkly doesn't assign
   * its own anonymous ID every time we initialize the client. */
  key: DEFAULT_USER_KEY,
  kind: 'user',
};

/** Guest bucketing constants + utility fns */
export const GUEST_BUCKET_COUNT = 1000000;

/** NOTE: Below hashing fn is taken from stackoverflow...
 * should figure out the ideal hashing function for this.
 * Likely needs larger than 32-bit hashing given the bucketing scale is in 250k-2million */

/*
    cyrb53 (c) 2018 bryc (github.com/bryc)
    License: Public domain. Attribution appreciated.
    A fast and simple 53-bit string hash function with decent collision resistance.
    Largely inspired by MurmurHash2/3, but with a focus on speed/simplicity.
*/
const cyrb53 = (str: string, seed = 0): number => {
  let h1 = 0xdeadbeef ^ seed,
    h2 = 0x41c6ce57 ^ seed;

  for (let i = 0, ch; i < str.length; i++) {
    ch = str.charCodeAt(i);
    h1 = Math.imul(h1 ^ ch, 2654435761);
    h2 = Math.imul(h2 ^ ch, 1597334677);
  }
  h1 = Math.imul(h1 ^ (h1 >>> 16), 2246822507);
  h1 = Math.imul(h2 ^ (h2 >>> 13), 3266489909);
  h2 = Math.imul(h2 ^ (h2 >>> 16), 2246822507);
  h2 = Math.imul(h1 ^ (h1 >>> 13), 3266489909);
  return 4294967296 * (2097151 & h2) + (h1 >>> 0);
};

const guestBucketForDeviceID = (deviceID: string): string => {
  const hash = cyrb53(deviceID);
  const bucket_number = hash % (GUEST_BUCKET_COUNT - 1);
  return 'guest:' + bucket_number;
};

export interface UserContext {
  authenticated: boolean;
  email?: string | null;
  firstName?: string | null;
  id?: number | null;
  lastName?: string | null;
}

export interface ExtraContextAttributes extends Record<string, unknown> {
  appMode?: AppMode;
  state?: string | null;
  storeId?: Id;
  storeIds?: number[];
}

export interface FlagContextProps {
  extraAttributes?: ExtraContextAttributes;
  janeDeviceId?: string;
  user?: UserContext;
}

export const getFlagContext = ({
  janeDeviceId,
  user,
  extraAttributes,
}: FlagContextProps = {}): LDSingleKindContext => {
  let context: LDSingleKindContext = {
    ...DEFAULT_USER_CONTEXT,
  };

  if (user?.id) {
    context = {
      ...context,
      anonymous: false,
      key: user?.id.toString(),
      email: user?.email,
      firstName: user?.firstName,
      lastName: user?.lastName,
    };
  } else if (janeDeviceId) {
    context.key = guestBucketForDeviceID(janeDeviceId);
  }

  // always add jdid as an attribute if exists
  if (janeDeviceId) {
    context = {
      ...context,
      jdid: janeDeviceId,
    };
  }

  return { ...context, ...extraAttributes };
};
