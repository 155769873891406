import { getJwt } from '@jane/shared/auth';
import { createUseQueryHook, janeApi } from '@jane/shared/data-access';
import type { AeropayWhiteLabelUser, Id } from '@jane/shared/models';

import { queryKeys, urls } from './aeropay.util';

export const fetchAeropayUser = async () => {
  const jwt = getJwt();

  if (jwt) {
    return await janeApi.get<AeropayWhiteLabelUser>(urls.aeropayAccounts, jwt);
  } else {
    return null;
  }
};

interface UseAeropayUserProps {
  janeUserId: Id | null;
}

const enableUserQuery = ({ janeUserId }: UseAeropayUserProps) => {
  const jwt = getJwt();
  return !!jwt && !!janeUserId;
};

export const useAeropayUser = createUseQueryHook<
  UseAeropayUserProps,
  AeropayWhiteLabelUser | null
>(fetchAeropayUser, queryKeys.aeropayUser, enableUserQuery);

interface AeropayBankConnection {
  fastLinkUrl: string;
  success: boolean;
  token: string;
  username: string;
}

const fetchAeropayBankConnection = async () =>
  await janeApi.get<AeropayBankConnection>(
    `${urls.bankConnection}?aggregator=aerosync`,
    getJwt()
  );

interface UseAeropayBankConnectionProps {
  aeropayUserId?: string;
}

const enableBankConnectionQuery = ({
  aeropayUserId,
}: UseAeropayBankConnectionProps) => !!aeropayUserId;

export const useAeropayBankConnection = createUseQueryHook<
  UseAeropayBankConnectionProps,
  AeropayBankConnection
>(
  fetchAeropayBankConnection,
  queryKeys.bankConnection,
  enableBankConnectionQuery
);
