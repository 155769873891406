import dayjs from 'dayjs';
import { useRef, useState } from 'react';
import type { ReactElement } from 'react';
import { useParams } from 'react-router-dom';

import { useQuery } from '@jane/shared-ecomm/hooks';
import { useJaneUser } from '@jane/shared/data-access';
import { useSafeState } from '@jane/shared/hooks';
import type { AppMode } from '@jane/shared/models';
import { useEcommApp } from '@jane/shared/providers';
import { getRuntimeConfig } from '@jane/shared/runtime-config';
import { Storage } from '@jane/shared/util';

import AgeGateModal from './ageGateModal';
import UserLocation from './userLocation';

const CONFIRM_CONDITIONS_EXPIRE_TIME_STORAGE_KEY =
  'confirmConditionsExpireTime';

const getInitialConditions = (appMode: AppMode, userBirthDate?: string) => {
  if (userBirthDate && dayjs().diff(userBirthDate, 'years') > 21) {
    return true;
  }

  const confirmConditionsExpireTime = Storage.get(
    CONFIRM_CONDITIONS_EXPIRE_TIME_STORAGE_KEY
  );
  const confirmConditionsExpired = confirmConditionsExpireTime
    ? confirmConditionsExpireTime <= new Date().getTime()
    : true;

  if (confirmConditionsExpired) {
    Storage.remove(CONFIRM_CONDITIONS_EXPIRE_TIME_STORAGE_KEY);
  }

  if (appMode === 'default') {
    return !confirmConditionsExpired;
  }

  return (
    !confirmConditionsExpired || // user has clicked accept already with remember me for 30 days
    document.cookie.includes('age_gate') || // wordpress age gate plugin sets this cookie
    /bot|google|baidu|bing|msn|teoma|slurp|yandex/i.test(navigator.userAgent) || // is it a search crawler?
    getRuntimeConfig().showAgeGate === false || // turned off in business app
    false // we should show the modal
  );
};

// 30 days
const REMEMBER_ME_MILLISECONDS = 30 * 24 * 60 * 60 * 1000;

export const ApplicationGates = ({ children }: { children?: ReactElement }) => {
  const { appMode } = useEcommApp();
  const [rememberMe, setRememberMe] = useState(false);
  const query = useQuery<{ skipgate?: string }>();
  const neverShowGatesRef = useRef(query.skipgate === '1');
  const { data: janeUser } = useJaneUser();
  const [confirmedConditionsState, setConfirmedConditionsState] = useSafeState(
    getInitialConditions(appMode, janeUser?.user?.birth_date)
  );
  const [showModal, setShowModal] = useSafeState(
    !neverShowGatesRef.current && !confirmedConditionsState
  );
  // no location modal on dispensary page when location is defined
  const { country } = useParams<{ country: string }>();

  const onConfirm = () => {
    if (rememberMe) {
      const now = new Date();
      const expire = now.getTime() + REMEMBER_ME_MILLISECONDS;

      Storage.set(CONFIRM_CONDITIONS_EXPIRE_TIME_STORAGE_KEY, expire);
    }

    setConfirmedConditionsState(true);
    const body = document.querySelector('body');
    body && body.classList.remove('ReactModal__Body--open');
    setShowModal(false);
  };

  if (neverShowGatesRef.current) {
    return children;
  }

  const ageGateModalProps = {
    rememberMe,
    setRememberMe,
    showModal,
    onConfirm,
  };

  if (showModal) {
    return (
      <>
        {children}
        <AgeGateModal {...ageGateModalProps} />
      </>
    );
  }

  if (appMode === 'default' && !country) {
    return <UserLocation>{children}</UserLocation>;
  }

  return children;
};
