import clsx from 'clsx';
import type { CSSProperties, ReactNode } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import type { LinkProps as RouterLinkProps } from 'react-router-dom';

import type { ColorKey, TypographyVariant } from '../../theme';
import type { MarginProperties } from '../../utils';
import {
  fakeButtonProps,
  getColorCSSVar,
  handleEnterKey,
  marginCSSVars,
} from '../../utils';
import type { ReeferBaseProps } from '../../utils/baseProps.types';
import { useReeferTheme } from '../providers';
import { Typography } from '../typography';
import type { TypographyProps } from '../typography/typography';
import styles from './link.module.css';

export type LinkVariant = 'underline' | 'minimal';

export interface LinkProps
  extends ReeferBaseProps,
    MarginProperties,
    Pick<TypographyProps, 'branded'> {
  /** aria label */
  ariaLabel?: string;

  /** any valid ReactNode including text */
  children?: ReactNode;

  /** color variant */
  color?: ColorKey;

  /** a url: if passed, it renders an anchor tag and will open the URL in a new tab */
  href?: string;

  /** onClick action if needed */
  onClick?: (() => any) | ((e: any) => any);

  /** Location state that can be accessed via useLocation(). (Only works when passing a `to` prop) */
  state?: RouterLinkProps['state'];

  /** optional target value, defaults to _blank */
  target?: '_blank' | '_self' | '_parent' | '_top';

  /** a string that is set to the link's title attribute, displayed as a tooltip by the browser */
  title?: string;

  /** an internal route => if passed, it renders a `<Link>` from `react-router-dom` */
  to?: string;

  /** Link Typography style, defaults to inherit */
  typography?: TypographyVariant | 'inherit';

  /** Link style variant, defaults to underline */
  variant?: LinkVariant;
}

/**
 * `Link` components can be rendered as an`anchor` tag that opens in a new tab, as a `span` that accepts a router path or as a `button` to initiate an `onClick` action.
 */
export function Link({
  ariaLabel,
  branded,
  children,
  className: classNameProp,
  color,
  'data-testid': testId,
  href,
  id,
  onClick,
  state,
  style: customStyle,
  target,
  title,
  to,
  typography = 'inherit',
  variant = 'underline',
  ...marginProps
}: LinkProps) {
  const theme = useReeferTheme();

  const className = clsx(classNameProp, styles.link, {
    [styles.underline]: variant === 'underline',
    [styles.not_clickable]: !onClick && !to && !href,
  });

  const linkColor = color ? getColorCSSVar(color) : theme.colors.grays.black;

  const style = {
    '--color': linkColor,
    ...marginCSSVars(marginProps),
    ...customStyle,
  } as CSSProperties;

  const sharedProps = {
    'aria-label': ariaLabel,
    'data-testid': testId,
    onClick,
    title,
  };

  const renderedLabel =
    typography === 'inherit' ? (
      children
    ) : (
      <Typography
        variant={typography}
        branded={branded}
        as="span"
        color="inherit"
      >
        {children}
      </Typography>
    );

  //RouterLink
  if (to) {
    return (
      <span className={className} id={id} style={style}>
        <RouterLink
          target={target}
          className={clsx(styles.link__router)}
          to={to}
          state={state}
          {...sharedProps}
        >
          {target === '_blank' && (
            <span className={styles.new_tab_label}>Opens in new window</span>
          )}
          {renderedLabel}
        </RouterLink>
      </span>
    );
  }

  //Link
  if (href) {
    return (
      <a
        className={className}
        id={id}
        style={style}
        href={href}
        rel="noopener noreferrer"
        target={target || '_blank'}
        {...sharedProps}
      >
        <span className={styles.new_tab_label}>Opens in new window</span>
        {renderedLabel}
      </a>
    );
  }

  //Button

  return (
    // eslint-disable-next-line jsx-a11y/no-static-element-interactions
    <span
      className={className}
      id={id}
      style={style}
      {...sharedProps}
      onKeyUp={(event) => handleEnterKey(event, onClick)}
      {...(onClick && fakeButtonProps)}
    >
      {renderedLabel}
    </span>
  );
}
