import * as t from 'io-ts';

export const tPublicImage = t.interface({
  id: t.number,
  position: t.union([t.null, t.number]),
  urls: t.interface({
    small: t.union([t.string, t.null]),
    medium: t.union([t.string, t.null]),
    extraLarge: t.union([t.string, t.null]),
    original: t.union([t.string, t.null]),
  }),
});

export type PublicImage = t.TypeOf<typeof tPublicImage>;
