/**
 * DO NOT MODIFY THIS FILE!
 * It was created by the reefer-icons generator.
 * Please update & re-run the generator if need be.
 */
import type { SVGProps } from 'react';

import type { DynamicIconProps } from '../../../icon/icon.types';
import { Icon } from '../../icon';

const Gummy24 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="100%"
    height="100%"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <g className="Gummy 24">
      <g className="SVG">
        <path
          fill="#F90"
          fillRule="evenodd"
          d="M12 1C6.887 1 2.72 3.14 2.536 5.815a4.016 4.016 0 0 0-.143.648l-1.354 10a3.99 3.99 0 0 0 1.296 3.518C3.95 21.757 7.67 23 12 23c4.33 0 8.05-1.243 9.665-3.019a3.99 3.99 0 0 0 1.297-3.518l-1.354-10a4.027 4.027 0 0 0-.143-.648C21.28 3.14 17.114 1 12 1Z"
          className="Main"
          clipRule="evenodd"
        />
        <g className="Main">
          <ellipse
            cx={12}
            cy={4}
            fill="#fff"
            fillOpacity={0.8}
            rx={3}
            ry={1}
            className="Details"
          />
        </g>
        <path
          fill="#000"
          fillOpacity={0.2}
          fillRule="evenodd"
          d="M6.636 5.364a.9.9 0 0 0-1.272 1.272C6.022 7.295 8.434 8.9 12 8.9c3.567 0 5.978-1.605 6.636-2.264a.9.9 0 0 0-1.272-1.272C17.022 5.705 15.06 7.1 12 7.1S6.978 5.705 6.636 5.364ZM12 21c4.97 0 9-1.79 9-4s-4.03-4-9-4-9 1.79-9 4 4.03 4 9 4Z"
          className="Details"
          clipRule="evenodd"
        />
      </g>
    </g>
  </svg>
);

const SIZE_MAP = {
  md: Gummy24,
  sm: Gummy24,
  lg: Gummy24,
  xl: Gummy24,
  xxl: Gummy24,
  xxxl: Gummy24,
};

export const GummyIcon = ({
  size = 'md',
  'data-testid': testId,
  ...props
}: DynamicIconProps) => {
  return (
    <Icon
      data-testid={testId || 'gummy-icon'}
      icon={SIZE_MAP[size]}
      {...props}
      size={size}
    />
  );
};
