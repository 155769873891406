import styled from '@emotion/styled';
import last from 'lodash/last';
import type { ReactNode } from 'react';

import { FLAGS, useFlag } from '@jane/shared/feature-flags';
import type {
  MenuProduct,
  PriceId,
  Product,
  StoreSpecial,
} from '@jane/shared/models';
import {
  Card,
  Flex,
  Typography,
  useMobileMediaQuery,
} from '@jane/shared/reefer';
import {
  availableWeightsForMenuProduct,
  formatCurrency,
  generateCustomLabel,
  getDiscountedUnitPrice,
  getSpecialTagLabel,
  getUnitPrice,
  titleize,
} from '@jane/shared/util';

import { Brand, Category, Kind, Name } from '../legacyProductCard';
import { useProductCardContext } from '../productCardProvider/productCardProvider';
import ProductPotency, {
  getDosageAmountPackInfo,
  getLabResults,
  getPackTotal,
} from '../productPotency';
import { SpecialTag } from '../specialTag';
import { FiveStarRating } from '../starRatings';
import { PRICE_LOOKUP } from './utils/productCardHelper';
import { useProductReviewsEnabled } from './utils/useProductReviewsEnabled';

export const REVIEWS_REQUIRED_BEFORE_DISPLAY = 5;

const PriceDistance: React.FC<{
  children?: ReactNode;
  myHighMenu?: boolean;
}> = ({ children, myHighMenu }) => (
  <Typography
    color={myHighMenu ? 'primary' : 'text-main'}
    ml="auto"
    variant="body-bold"
  >
    {children}
  </Typography>
);

const TruncateWrapper = styled.div<{ truncateOffset: number | false }>(
  ({ truncateOffset }) => [
    {
      maxWidth: truncateOffset ? `calc(100% - ${truncateOffset}px)` : '100%',
    },
  ]
);

const ReviewStars = ({ product }: { product: MenuProduct | Product }) => {
  return product?.aggregate_rating &&
    product?.review_count &&
    product?.review_count >= REVIEWS_REQUIRED_BEFORE_DISPLAY ? (
    <Flex alignItems="center" data-testid="product-rating">
      <FiveStarRating rating={product.aggregate_rating} />
      <Typography color="text-light" variant="body">
        {product.review_count}
      </Typography>
    </Flex>
  ) : null;
};

export const MenuBasicProductInfo = ({
  fullDetails = true,
  applyTruncateOffset = false,
  selectedWeight,
  specialApplies,
}: {
  applyTruncateOffset?: boolean;
  fullDetails?: boolean;
  selectedWeight?: PriceId;
  specialApplies?: boolean;
}) => {
  const {
    appMode,
    defaultWeight,
    menuProduct: product,
    showOnlyWeights,
    store,
    userLocation,
  } = useProductCardContext();
  const mobile = useMobileMediaQuery({ size: 'legacy' });
  const specialTagLabel =
    store && product && getSpecialTagLabel({ defaultWeight, product, store });

  const productReviewsEnabled = useProductReviewsEnabled(
    appMode,
    userLocation,
    store
  );
  const hasReviews = Boolean(
    productReviewsEnabled &&
      product?.aggregate_rating &&
      product?.review_count &&
      product?.review_count >= REVIEWS_REQUIRED_BEFORE_DISPLAY
  );

  const hasLongName = product?.name ? product?.name.length >= 25 : false;

  const productKind = generateCustomLabel({
    appMode,
    store,
    attribute: product?.brand_subtype || product?.kind,
    fallback: product?.brand_subtype || titleize(product?.kind),
  });

  const availableWeights = availableWeightsForMenuProduct(
    product as MenuProduct,
    showOnlyWeights
  ).map((price) => PRICE_LOOKUP[price.toUpperCase()]);

  const availablePrices =
    availableWeights.length === 0 || product?.amount
      ? 'EACH'
      : formatWeights(availableWeights);

  const brandText = (
    <Brand title={product?.brand || ''}>
      {product && product.brand ? product.brand : ''}
    </Brand>
  );

  const productLineage = generateCustomLabel({
    appMode,
    store,
    attribute: product?.category,
    isLineageLabel: true,
    fallback: titleize(product?.category),
  });

  // NOTE(elliot): Display pre roll pack info if product has lab results. (potencyInfo pioritizes lab results over other info)
  const displayPackInfo = Boolean(
    product &&
      getLabResults(product) &&
      getDosageAmountPackInfo(product) &&
      getPackTotal(product)
  );

  return (
    <>
      <Flex justifyContent="space-between" alignItems="center">
        {productLineage.length > 0 && (
          <Category
            css={{ minHeight: mobile ? 16 : 24 }}
            title={product?.category || ''}
            category={product?.category}
          >
            {productLineage}
          </Category>
        )}
        {specialApplies && specialTagLabel && (
          <Flex ml={8}>
            <SpecialTag label={specialTagLabel} />
          </Flex>
        )}
      </Flex>

      <TruncateWrapper truncateOffset={applyTruncateOffset && 40}>
        <Name title={product?.name}>{product?.name}</Name>
      </TruncateWrapper>

      <Typography color="text-light" variant="body" as="div">
        {product && product.brand ? (
          <TruncateWrapper truncateOffset={applyTruncateOffset && 15}>
            {brandText}
          </TruncateWrapper>
        ) : (
          brandText
        )}
      </Typography>

      {fullDetails && product && (
        <>
          {productReviewsEnabled && <ReviewStars product={product} />}
          <Flex alignItems="center">
            <Kind title={productKind}>{productKind}</Kind>
            <Typography
              color="text-light"
              ml={4}
              truncateAt="auto"
              variant="body"
            >
              (
              {displayPackInfo
                ? getDosageAmountPackInfo(product)
                : availablePrices.toUpperCase()}
              )
            </Typography>
          </Flex>
        </>
      )}
      <ProductPotency
        product={product as MenuProduct}
        showLess={hasReviews && hasLongName}
        selectedWeight={selectedWeight}
      />
    </>
  );
};

const formatWeights = (availableWeights: string[]) => {
  switch (availableWeights.length) {
    case 1:
      return availableWeights[0];
    case 2:
      return `${availableWeights[0]}, ${availableWeights[1]}`;
    default:
      return `${availableWeights[0]} - ${last(availableWeights)}`;
  }
};

interface Props {
  currentSpecial?: StoreSpecial;
}

const MenuProductInfo = ({ currentSpecial }: Props) => {
  const { defaultWeight, menuProduct: product } = useProductCardContext();
  const myHighMenu = useFlag(FLAGS.myHighMenu);

  const originalPrice = getUnitPrice(product as MenuProduct, defaultWeight);

  const discountedUnitPrice = getDiscountedUnitPrice(
    product as MenuProduct,
    defaultWeight
  );
  const unitPrice =
    currentSpecial && discountedUnitPrice ? discountedUnitPrice : originalPrice;

  const defaultPriceId =
    product && product.amount ? '' : PRICE_LOOKUP[defaultWeight?.toUpperCase()];

  return (
    <Card.Content height="100%">
      <Flex flexDirection="column" height="100%">
        <MenuBasicProductInfo />

        <Flex mt="auto">
          {currentSpecial && originalPrice && originalPrice !== unitPrice && (
            <Typography
              color={myHighMenu ? 'primary' : 'text-main'}
              strikeThrough
              variant="body"
            >
              {formatCurrency(originalPrice)}
            </Typography>
          )}
          {unitPrice !== undefined && (
            <PriceDistance myHighMenu={myHighMenu}>
              {formatCurrency(unitPrice)}
              {defaultPriceId && (
                <Typography
                  as="span"
                  variant="body-bold"
                  color={myHighMenu ? 'primary' : 'text-main'}
                >
                  /{defaultPriceId}
                </Typography>
              )}
            </PriceDistance>
          )}
        </Flex>
      </Flex>
    </Card.Content>
  );
};

export default MenuProductInfo;
