import { SmartSortProduct } from '@jane/dm/sdk';

export const isSmartSortProduct = (
  product: unknown
): product is SmartSortProduct => {
  return product instanceof SmartSortProduct;
};

export const isSmartSortProductsArray = (
  products: unknown
): products is SmartSortProduct[] => {
  return Array.isArray(products) && products.every(isSmartSortProduct);
};
