import styled from '@emotion/styled';

import { Link, Typography } from '@jane/shared/reefer';

const InlineLink = styled(Link)(({ theme }) => ({
  color: theme.colors.text.main,
  display: 'inline',
}));

export const OptInDescription = () => {
  return (
    <Typography ml={16}>
      I consent to Jane using my email to send me marketing materials, special
      offers, recommendations and more, in accordance with Jane's{' '}
      <InlineLink href="https://www.iheartjane.com/privacy-policy">
        Privacy Policy.
      </InlineLink>
    </Typography>
  );
};
