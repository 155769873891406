import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { createRoot } from 'react-dom/client';
import { HelmetProvider } from 'react-helmet-async';
import { Provider as ReduxProvider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';

import { bootstrap, configureCustomerStore } from '@jane/monolith/frameless';
import { config, defaultQueryClientConfig } from '@jane/shared/config';
import { withFlagProvider } from '@jane/shared/feature-flags';
import {
  EcommAppProvider,
  ScrollRestorationProvider,
} from '@jane/shared/providers';
import { getRuntimeConfig } from '@jane/shared/runtime-config';

import App from './main';

const store = configureCustomerStore();
const queryClient = new QueryClient(defaultQueryClientConfig);

const container = document.getElementById('app') as HTMLElement;
const root = createRoot(container);

const { isBoostForAll, partnerHostedPath } = getRuntimeConfig();

const basename = isBoostForAll ? partnerHostedPath : undefined;

const AppBase = () => (
  <EcommAppProvider appMode="framelessEmbed">
    <HelmetProvider>
      <ReduxProvider store={store}>
        <QueryClientProvider client={queryClient}>
          <BrowserRouter basename={basename}>
            <ScrollRestorationProvider isIframe={false}>
              <App />
            </ScrollRestorationProvider>
          </BrowserRouter>
        </QueryClientProvider>
      </ReduxProvider>
    </HelmetProvider>
  </EcommAppProvider>
);

const AppWithFlagProvider = withFlagProvider({
  clientSideID: config.launchDarklyClientID,
})(AppBase);

(async () => {
  await bootstrap();
  root.render(<AppWithFlagProvider />);
})();
