import type { Font, TypographyConfig } from './themeComponents.types';

const loadCustomFont = async (font?: Font) => {
  if (!font?.fontFamily) {
    return;
  }

  if (typeof FontFace === 'undefined') {
    throw Error('FontFace is undefined');
  }
  const fontFamily = font.fontFamily.toString();
  if (font.url) {
    const externalFont = new FontFace(fontFamily, `url(${font.url})`);
    const externalFontResponse = await externalFont.load();
    document.fonts.add(externalFontResponse);
  }
};

export const loadCustomFonts = async (
  customTypography: TypographyConfig,
  element: HTMLElement = document.body
) => {
  if (!customTypography) return;

  if (customTypography.fonts.branded) {
    await loadCustomFont(customTypography.fonts.branded);
    if (!customTypography.fonts.default) {
      element.style.fontFamily = 'var(--font-family-branded)';
    }
  }

  if (customTypography.fonts.default) {
    await loadCustomFont(customTypography.fonts.default);
    element.style.fontFamily = 'var(--font-family-default)';
  }
};
