import type { CurrentSort } from '@jane/search/types';

export const buildIndex = (
  algoliaEnv: string,
  currentSort: CurrentSort | undefined
): string => {
  const prefix = 'menu-products-';
  const suffix = currentSort?.suffix || '';

  return `${prefix}${suffix}${algoliaEnv}`;
};
