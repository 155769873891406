import clsx from 'clsx';
import { forwardRef, useEffect, useState } from 'react';
import type { CSSProperties, ChangeEvent, FocusEvent } from 'react';

import chevronDownIcon from '../../../../../assets/icons/dynamic/chevron-down-24.svg';
import { Box } from '../../../box';
import type { SelectFieldProps } from '../field.types';
import { FieldWrapper } from '../fieldWrapper/fieldWrapper';
import styles from './selectField.module.css';

export interface SelectFieldPropsInternal extends SelectFieldProps {
  onUpdate?: (value: string) => void;
  value?: string;
}

export const SelectFieldInternal = forwardRef<
  HTMLSelectElement,
  SelectFieldPropsInternal
>(
  (
    {
      autoFocus = false,
      children,
      className,
      borderRadius = 'sm',
      defaultValue,
      disabled = false,
      helperText,
      id,
      label,
      labelHidden = false,
      loading = false,
      name,
      onBlur,
      onChange,
      onUpdate,
      options,
      placeholder,
      required = false,
      'data-testid': testId,
      width = 'auto',
      value: externalValue,
      ...props
    },
    ref
  ) => {
    const [value, setValue] = useState<string | undefined>(
      defaultValue || placeholder
    );

    useEffect(() => {
      if (externalValue !== undefined && externalValue !== value) {
        setValue(externalValue);
        onUpdate && onUpdate(externalValue);
      }
    }, [externalValue, onUpdate, value]);

    const handleBlur = (event: FocusEvent<HTMLSelectElement>) => {
      setValue(event.target.value);
      onBlur && onBlur(event.target.value);
    };

    const handleChange = (event: ChangeEvent<HTMLSelectElement>) => {
      setValue(event.target.value);
      onChange && onChange(event.target.value);
    };

    return (
      <FieldWrapper
        className={className}
        disabled={disabled || loading}
        helperText={helperText}
        id={id}
        label={label}
        labelHidden={labelHidden}
        name={name}
        render={(renderProps) => (
          <Box position="relative">
            <select
              autoFocus={autoFocus}
              className={clsx(
                styles.selectField_select,
                styles[`selectField_select__borderRadius_${borderRadius}`]
              )}
              disabled={disabled || loading}
              id={name}
              name={name}
              onBlur={handleBlur}
              onChange={handleChange}
              ref={ref}
              style={
                {
                  '--chevron-down-icon': `url("${chevronDownIcon}")`,
                } as CSSProperties
              }
              value={value}
              {...renderProps}
            >
              {loading && (
                <option disabled value="">
                  Loading...
                </option>
              )}
              {!loading && placeholder && !required && (
                <option value="">{placeholder}</option>
              )}
              {!loading && placeholder && required && (
                <option disabled value={placeholder}>
                  {placeholder}
                </option>
              )}
              {!loading && !placeholder && !required && (
                <option value="">None</option>
              )}
              {!loading &&
                options.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
            </select>
          </Box>
        )}
        data-testid={testId}
        width={width}
        {...props}
      >
        {children}
      </FieldWrapper>
    );
  }
);

/**
 * Select field allow users to choose one option from a dropdown options.
 *
 * Use this component *outside forms*
 *
 * **NOTE: DO NOT USE THIS COMPONENT WITHIN FORMS**
 *
 * *For a similar component for use within forms, see [`Form.SelectField`](/story/components-forms-form-selectfield--default).*
 */
export const SelectField = forwardRef<HTMLSelectElement, SelectFieldProps>(
  (props, ref) => <SelectFieldInternal ref={ref} {...props} />
);
