import type { DeepReadonly } from '@jane/shared/models';

import type StoreSchedule from './storeSchedule';

class OrderingSchedule {
  readonly retailSchedule: StoreSchedule;
  readonly deliverySchedule: StoreSchedule | null;
  readonly pickupSchedule: StoreSchedule | null;
  readonly curbsideSchedule: StoreSchedule | null;
  readonly fulfillmentSchedules: StoreSchedule[];
  readonly allowOffHoursOrdering: boolean;

  constructor(
    retailSchedule: StoreSchedule,
    deliverySchedule: StoreSchedule | null,
    pickupSchedule: StoreSchedule | null,
    curbsideSchedule: StoreSchedule | null,
    allowOffHoursOrdering: boolean
  ) {
    this.retailSchedule = retailSchedule;
    this.deliverySchedule = deliverySchedule;
    this.pickupSchedule = pickupSchedule;
    this.curbsideSchedule = curbsideSchedule;
    this.fulfillmentSchedules = [
      deliverySchedule,
      pickupSchedule,
      curbsideSchedule,
    ].filter(Boolean) as StoreSchedule[];
    this.allowOffHoursOrdering = allowOffHoursOrdering;
  }

  get isAlwaysClosed() {
    return this.fulfillmentSchedules.every(
      (schedule) => schedule.isAlwaysClosed
    );
  }

  get willOpenLaterToday() {
    return this.fulfillmentSchedules.some(
      (schedule) => schedule.willOpenLaterToday
    );
  }

  get isCurrentlyOpen() {
    return this.fulfillmentSchedules.some(
      (schedule) => schedule.isCurrentlyOpen
    );
  }

  get isCurrentlyClosed() {
    return this.fulfillmentSchedules.every(
      (schedule) => schedule.isCurrentlyClosed
    );
  }

  get today() {
    return (
      (this.pickupSchedule && this.pickupSchedule.today) ||
      (this.deliverySchedule && this.deliverySchedule.today)
    );
  }

  get nextOpenDay() {
    if (this.isAlwaysClosed) return undefined;
    const nextDeliveryDay = this.deliverySchedule
      ? this.deliverySchedule.nextOpenDay
      : undefined;
    const nextPickupDay = this.pickupSchedule
      ? this.pickupSchedule.nextOpenDay
      : undefined;

    if (
      nextDeliveryDay &&
      nextDeliveryDay.openingTime &&
      nextPickupDay &&
      nextPickupDay.openingTime
    ) {
      if (nextDeliveryDay.openingTime.isSameOrBefore(nextPickupDay.openingTime))
        return nextDeliveryDay;
      return nextPickupDay;
    }
    return nextDeliveryDay || nextPickupDay;
  }

  isCurrentlyAcceptingReservations() {
    return this.fulfillmentSchedules.some((schedule) =>
      schedule.isCurrentlyAcceptingReservations()
    );
  }
}

export const storeIsOpenForRetail = (
  schedules: DeepReadonly<OrderingSchedule>
) => schedules.retailSchedule.isCurrentlyOpen;

export default OrderingSchedule;
