import * as t from 'io-ts';

export const tPhoto = t.type({
  id: t.union([t.number, t.string]),
  position: t.number,
  urls: t.type({
    extraLarge: t.string,
    medium: t.string,
    original: t.string,
    small: t.string,
  }),
});
