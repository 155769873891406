import type {
  SearchState as InstantSearchState,
  JaneSearchState,
} from '@jane/search/types';
import { config } from '@jane/shared/config';

// TODO(elliot): Remove these fns once we rip out instantsearch everywhere?
export const searchStateToInstantSearchState = <T>(
  {
    currentSort,
    searchText,
    bucketFilters,
    rangeFilters,
    filters,
    page,
  }: JaneSearchState<T>,
  indexPrefix: string
): InstantSearchState => {
  const multiRange = bucketFilters
    ? Object.keys(bucketFilters).reduce((prev, filterKey) => {
        const values = bucketFilters[filterKey as keyof T];

        if (!values || !values.length) {
          return prev;
        }

        return { ...prev, [filterKey]: values[0] };
      }, {})
    : undefined;

  const range = rangeFilters
    ? Object.keys(rangeFilters).reduce((prev, filterKey) => {
        const rangeValue = rangeFilters[filterKey as keyof T];

        if (!rangeValue) {
          return prev;
        }

        return { ...prev, [filterKey]: rangeValue };
      }, {})
    : undefined;

  const refinementList = filters
    ? Object.keys(filters).reduce((prev, filterKey) => {
        const filterValues = filters[filterKey as keyof T];

        if (!filterValues || !filterValues.length) {
          return prev;
        }

        return { ...prev, [filterKey]: filterValues };
      }, {})
    : undefined;

  const sortBy = currentSort
    ? `${indexPrefix}${currentSort.suffix}${config.algoliaEnv}`
    : undefined;

  return {
    multiRange,
    page: page ? Number(page) : undefined,
    query: searchText ? searchText : '',
    range,
    refinementList,
    sortBy,
    currentSort,
  };
};

interface InstantSearchStateWithSearchText extends InstantSearchState {
  searchText?: string;
}

export const instantSearchStateToSearchState = <T>({
  currentSort,
  multiRange,
  page,
  range,
  refinementList,
  query,
  searchText,
}: InstantSearchStateWithSearchText): JaneSearchState<T> => {
  const bucketFilters = multiRange
    ? Object.keys(multiRange).reduce((prev, filterKey) => {
        const rangeValue = multiRange[filterKey];

        if (!rangeValue) {
          return prev;
        }

        return { ...prev, [filterKey]: [rangeValue] };
      }, {})
    : undefined;

  const filters = refinementList
    ? Object.keys(refinementList).reduce((prev, filterKey) => {
        const filterValue = refinementList[filterKey];

        if (!filterValue) {
          return prev;
        }

        return {
          ...prev,
          // NOTE(elliot): to handle old url format ie. ?refinementList[root_types]=flower which gets parsed as a string instead of an array.
          [filterKey]: Array.isArray(filterValue) ? filterValue : [filterValue],
        };
      }, {})
    : undefined;

  const searchState: JaneSearchState<T> = {
    bucketFilters,
    filters,
    page,
    rangeFilters: range as JaneSearchState<T>['rangeFilters'],
    searchText: searchText || query,
  };

  if (currentSort) {
    searchState.currentSort = currentSort;
  }

  return searchState;
};
