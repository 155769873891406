import { Box, Button, Typography } from '@jane/shared/reefer';
import { spacing } from '@jane/shared/reefer-emotion';

export const SUBMIT_ERROR_TEXT = 'Please check the red errors above';

export interface ShowLoginProps {
  setShowLogin?: (arg: boolean) => void;
}

export const SubmitErrorMessage = ({ children }) => (
  <Typography color="error" as="div" mt={8}>
    {children}
  </Typography>
);

export const CustomFieldError = ({ children }) => <Box mb={12}>{children}</Box>;

interface ButtonProps extends React.HTMLProps<HTMLButtonElement> {
  'data-testid'?: string;
  label: string;
  loading?: boolean;
  type?: 'button' | 'submit';
}
export const PrimaryLoginButton = ({
  'data-testid': testId,
  disabled,
  loading,
  label,
  onClick,
}: ButtonProps) => {
  return (
    <Button
      data-testid={testId}
      disabled={disabled}
      loading={loading}
      onClick={onClick}
      css={{
        height: 40,
        ...spacing({ py: 0 }),
      }}
      label={label}
      type="submit"
    />
  );
};
