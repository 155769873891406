import { useJaneGoldData } from '@jane/shared-ecomm/hooks';
import {
  Flex,
  JaneGoldLogo,
  Link,
  Typography,
  useMobileMediaQuery,
} from '@jane/shared/reefer';
import { getJaneGoldFormattedLabel } from '@jane/shared/util';

import type { JaneGoldCartCalloutProps } from '../janeGoldCallout.types';

/**
 * Checkout summary variant of the Jane Gold callout action component. This is a final nudge for logged in users to claim jane gold discounts before checkout.
 */

export const JaneGoldCheckoutSummaryCallout = ({
  discountLabel,
  onLinkAccountClick,
}: JaneGoldCartCalloutProps) => {
  const { userHasLinkedBank } = useJaneGoldData();
  const isMobile = useMobileMediaQuery({});

  const formattedLabel =
    typeof discountLabel === 'number'
      ? getJaneGoldFormattedLabel(discountLabel)
      : discountLabel;

  if (userHasLinkedBank) return null;

  return (
    <Flex
      alignItems="center"
      background="grays-ultralight"
      borderRadius="lg"
      mt={isMobile ? 16 : 24}
      p={24}
    >
      <div>
        <JaneGoldLogo altText="Jane Gold logo" height={26} width={60} />
      </div>
      <Typography ml={16}>
        Last chance to{' '}
        <Link onClick={onLinkAccountClick} css={{ display: 'inline' }}>
          link your bank account
        </Link>{' '}
        and earn {formattedLabel} in cash back with Jane Gold
      </Typography>
    </Flex>
  );
};
