import clsx from 'clsx';
import type { CSSProperties } from 'react';

import { toCSSSize } from '../../internal/utils';
import { prefixStyles } from '../../utils';
import type { Spacing } from '../../utils';
import type { BoxProps } from '../box';
import { Box } from '../box';
import styles from './flex.module.css';

function flexProperty({ grow, shrink, basis }: ReeferFlexPropertyArgs) {
  if ([grow, shrink, basis].every((el) => el === undefined))
    return { '--flex': 'initial' };

  const growVal = typeof grow === 'number' ? grow : grow ? 1 : 0;
  const shrinkVal = typeof shrink === 'number' ? shrink : shrink ? 1 : 0;
  const basisVal = !basis ? 'auto' : basis;

  return { '--flex': `${growVal} ${shrinkVal} ${basisVal}` };
}

interface ReeferFlexPropertyArgs {
  /** The length of the item. Legal values: "auto", "inherit", or a number followed by "%", "px", "em" or any other length unit */
  basis?: string;

  /** Set `flex-grow`, how the item grows relative to other flex items*/
  grow?: boolean | number;

  /** Documented above under FlexProperties */
  inline?: boolean;

  /** Set `flex-shrink`, how the item shrinks relative to other flex items*/
  shrink?: boolean | number;
}

export type ReeferFlexItemProperties = Pick<
  CSSProperties,
  'alignSelf' | 'justifySelf'
> &
  ReeferFlexPropertyArgs;

export type ReeferFlexProperties = Pick<
  CSSProperties,
  | 'alignItems'
  | 'alignContent'
  | 'flexDirection'
  | 'justifyContent'
  | 'justifyItems'
  | 'flexWrap'
> & {
  /** Set `gap`, the spacing between rows and columns */
  gap?: Spacing;

  /** Set `display` as `inline-flex` on the item */
  inline?: boolean;
};

export interface FlexProps
  extends ReeferFlexProperties,
    ReeferFlexItemProperties,
    BoxProps {}

/**
 * The `Flex` component is a container component with `flex` layout that allows you
 * to easily set any `flex` parent and child related properties, as well as `margin`
 * and `padding` spacing props.
 * */
export const Flex = ({
  alignItems = 'normal',
  alignSelf = 'auto',
  alignContent = 'auto',
  accessibilityComponentName = 'Flex',
  basis,
  children,
  className,
  flexDirection = 'row',
  flexWrap = 'nowrap',
  gap = 0,
  grow,
  inline,
  justifyContent = 'normal',
  justifyItems = 'legacy',
  justifySelf = 'auto',
  shrink,
  style,
  ...props
}: FlexProps) => {
  return (
    <Box
      accessibilityComponentName={accessibilityComponentName}
      className={clsx(className, styles.flex, {
        [styles.flex__inline]: inline,
      })}
      style={prefixStyles({
        '--flex-align-content': alignContent,
        '--flex-align-items': alignItems,
        '--flex-align-self': alignSelf,
        '--flex-direction': flexDirection,
        '--flex-gap': toCSSSize(gap),
        '--flex-justify-content': justifyContent,
        '--flex-justify-items': justifyItems,
        '--flex-justify-self': justifySelf,
        '--flex-wrap': flexWrap,
        ...flexProperty({ grow, shrink, basis }),
        ...style,
      } as CSSProperties)}
      {...props}
    >
      {children}
    </Box>
  );
};
