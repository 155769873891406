import styled from '@emotion/styled';

import { Flex } from '@jane/shared/reefer';
import { mediaQueries } from '@jane/shared/reefer-emotion';

export const LoadingContainer = styled(Flex)({
  position: 'absolute',
  top: '35%',
  left: 0,
  right: 0,
});

export const GroupDiscountOuter = styled(Flex)({
  width: '100%',
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  [mediaQueries.desktop('sm', 'min')]: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
});
