import type { ReactNode } from 'react';

import { Typography } from '@jane/shared/reefer';

import { useTextField } from './text-field-context';

const HelperText = ({ id, children }: { children: ReactNode; id: string }) => {
  const { error, success } = useTextField();
  return (
    <Typography
      id={id}
      color={error ? 'error' : success ? 'success' : 'text-light'}
      as="div"
      data-testid={id}
      variant="body"
      role={error ? 'alert' : undefined}
    >
      {children}
    </Typography>
  );
};

export default HelperText;
