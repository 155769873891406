import clsx from 'clsx';
import { nanoid } from 'nanoid';
import type { CSSProperties } from 'react';
import { forwardRef, useEffect, useMemo, useState } from 'react';

import type { ModalContentProps } from '../modal.types';
import styles from './modalContent.module.css';

export const ModalContent = forwardRef<HTMLDivElement, ModalContentProps>(
  function ModalContent(
    {
      overflowY = 'auto',
      children,
      className,
      'data-testid': testId,
      id,
      padding = true,
      style,
    },
    ref
  ) {
    const [divHeight, setDivHeight] = useState('100%');
    const uniqueId = useMemo(() => nanoid(), []);

    useEffect(() => {
      let offset = 0;

      const contentDiv = document.querySelector(`[data-id="${uniqueId}"]`);
      const previousSibling = contentDiv?.previousElementSibling;
      const nextSibling = contentDiv?.nextElementSibling;

      if (
        previousSibling?.getAttributeNode('data-id')?.value === 'modal-header'
      )
        offset += previousSibling?.clientHeight;
      if (nextSibling?.getAttributeNode('data-id')?.value === 'modal-footer')
        offset += nextSibling?.clientHeight;

      setDivHeight(`calc(100% - ${offset}px)`);
    }, [uniqueId]);

    return (
      <div
        className={clsx(className, styles.modalContent, {
          [styles.modalContent__withPadding]: padding,
        })}
        id={id}
        style={
          {
            '--modalContent-overflow-y': overflowY,
            '--modalContent-height': divHeight,
            ...style,
          } as CSSProperties
        }
        ref={ref}
        data-id={uniqueId}
        data-testid={testId}
      >
        {children}
      </div>
    );
  }
);
