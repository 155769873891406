import styled from '@emotion/styled';
import type { MouseEvent } from 'react';

import { spacing } from '@jane/shared/reefer-emotion';

import { useAutoScroll } from './accordion';
import type { AccordionHeadingProps } from './accordion.types';
import {
  useItemExpanded,
  useItemId,
  useSetItemExpanded,
} from './accordionItemContext';

const StyledButton = styled.button<{ autoScrollMargin: string }>(
  ({ autoScrollMargin, ...props }) => ({
    width: '100%',
    border: 'none',
    ...spacing({ m: 0, p: 0, ...props }),
    textAlign: 'left',
    boxSizing: 'border-box',
    display: 'block',
    cursor: 'pointer',
    scrollMarginTop: autoScrollMargin,
    ':disabled': {
      cursor: 'auto',
    },
  })
);

export const AccordionHeading = ({
  children,
  onChange,
  onClick,
  ...props
}: AccordionHeadingProps) => {
  const { autoScrollMargin } = useAutoScroll();
  const expanded = useItemExpanded();
  const setExpanded = useSetItemExpanded();
  const id = useItemId();

  // Expand on click
  const handleClick = (e: MouseEvent<HTMLButtonElement>) => {
    onClick && onClick(e);
    setExpanded(!expanded);
  };

  return (
    <StyledButton
      id={`accordion-heading-${id}`}
      data-testid={`accordion-heading-${id}`}
      aria-expanded={expanded}
      aria-controls={`accordion-content-${id}`}
      onClick={handleClick}
      onMouseDown={(e) => e.preventDefault()}
      autoScrollMargin={autoScrollMargin || '0px'}
      {...props}
      type="button"
    >
      {children}
    </StyledButton>
  );
};
