import {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import type { ReactNode } from 'react';

import { useJaneUser } from '@jane/shared/data-access';
import { postMessageToIframeParent } from '@jane/shared/util';

/**
 * The MFA provider allows for launching the MFA modal from any component within the Provider.
 * It also stores the Date of the previously successful MFA and the last codes that were sent and resent.
 * If a non-modal version of MFA exists, ths useMfa hook returns the helper functions for using/updating these values.
 * @returns
 * A method for checking if mfa has been completed within a specified number of minutes (default 60).
 * Two methods for checking if a code has been sent or resent within a specified number of seconds (default 20).
 * A method for launching the modal that can be passed an optional onSuccess or onCancel callback, as well as an updated phone number.
 * A method to record a new timestamp in cases where the MFA was done from a separate component than the <MfaModal />.
 * a method to record a new timestamp for the last SMS that was sent.
 */

interface MfaContextType {
  canResendCode: (seconds?: number) => boolean;
  canSendCode: (seconds?: number) => boolean;
  getNewPhone: () => string | undefined;
  launch: ({
    newPhone,
    onSuccess,
  }: {
    newPhone?: string;
    onCancel?: () => void;
    onSuccess?: () => void;
  }) => void;
  mfaCancel: () => void;
  mfaSuccess: () => void;
  onCodeResent: () => void;
  onCodeSent: () => void;
  open: boolean;
  requiresMfa: (minutes?: number) => boolean;
}

const MfaContext = createContext<MfaContextType>({} as MfaContextType);

export const isMoreThanTime = (date: Date, seconds: number) => {
  const now = new Date();
  const timeDifferenceInMilliseconds = now.getTime() - date.getTime();
  const timeDifferenceInSeconds = Math.floor(
    timeDifferenceInMilliseconds / 1000
  );

  return timeDifferenceInSeconds > seconds;
};

export const MfaProvider = ({
  children,
  isEmbed = false,
}: {
  children: ReactNode;
  isEmbed?: boolean;
}) => {
  const { data: userData } = useJaneUser();

  const onCancelRef = useRef<(() => void) | null>(null);
  const onSuccessRef = useRef<(() => void) | null>(null);
  const newPhoneRef = useRef<string | null>(null);

  const lastMfaDate = useRef<Date | null>(null);
  const lastCodeSentDate = useRef<Date | null>(null);
  const lastCodeResentDate = useRef<Date | null>(null);

  const [showModal, setShowModal] = useState(false);

  const clearRefs = () => {
    onCancelRef.current = null;
    onSuccessRef.current = null;
    newPhoneRef.current = null;
  };

  const clearTimestamps = () => {
    lastMfaDate.current = null;
    lastCodeSentDate.current = null;
    lastCodeResentDate.current = null;
  };

  const openModal = () => {
    if (isEmbed) {
      postMessageToIframeParent({
        messageType: 'enableShrinkEveryResize',
      });

      postMessageToIframeParent({
        messageType: 'openModal',
      });

      postMessageToIframeParent({
        messageType: 'scrollToTop',
      });
    }
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
    if (isEmbed) {
      postMessageToIframeParent({
        messageType: 'disableShrinkEveryResize',
      });

      postMessageToIframeParent({
        messageType: 'closeModal',
      });
    }
  };

  const handleLaunchModal = ({
    newPhone,
    onCancel,
    onSuccess,
  }: {
    newPhone?: string;
    onCancel?: () => void;
    onSuccess?: () => void;
  }) => {
    openModal();
    onCancelRef.current = onCancel || null;
    onSuccessRef.current = onSuccess || null;
    newPhoneRef.current = newPhone || null;
  };

  const handleMfaSuccess = () => {
    lastMfaDate.current = new Date();
    onSuccessRef.current && onSuccessRef.current();
    clearRefs();
    closeModal();
  };

  const handleGetNewPhone = () => newPhoneRef.current || undefined;

  const handleRequiresMfa = (minutes = 60) =>
    !lastMfaDate.current || isMoreThanTime(lastMfaDate.current, minutes * 60);

  const handleCanSendCode = (seconds = 20) => {
    const lastCodeSent = lastCodeSentDate.current;
    const lastCodeResent = lastCodeResentDate.current;

    // If the first code hasn't been sent or this modal has a new phone number.
    if (!lastCodeSent || !!newPhoneRef.current) return true;
    // If the second code hasn't been sent, we check the first code's timestamp.
    if (!lastCodeResent) return isMoreThanTime(lastCodeSent, seconds);
    // If both codes have been sent, we check the most recent timestamp.
    const mostRecentDate =
      lastCodeSent.getTime() > lastCodeResent.getTime()
        ? lastCodeSent
        : lastCodeResent;

    return isMoreThanTime(mostRecentDate, seconds);
  };

  const handleCodeSent = () => {
    lastCodeSentDate.current = new Date();
  };

  const handleCanResendCode = (seconds = 20) =>
    !lastCodeResentDate.current ||
    isMoreThanTime(lastCodeResentDate.current, seconds);

  const handleCodeResent = () => {
    lastCodeResentDate.current = new Date();
  };

  const handleMfaCancel = () => {
    onCancelRef.current && onCancelRef.current();
    clearRefs();
    closeModal();
  };

  // If a new user signs in, clear all the saved info
  useEffect(() => {
    clearRefs();
    clearTimestamps();
  }, [userData?.user?.id]);

  const value = useMemo(
    () => ({
      canResendCode: handleCanResendCode,
      canSendCode: handleCanSendCode,
      getNewPhone: handleGetNewPhone,
      launch: handleLaunchModal,
      onCodeResent: handleCodeResent,
      onCodeSent: handleCodeSent,
      open: showModal,
      mfaCancel: handleMfaCancel,
      mfaSuccess: handleMfaSuccess,
      requiresMfa: handleRequiresMfa,
    }),
    [showModal]
  );

  return <MfaContext.Provider value={value}>{children}</MfaContext.Provider>;
};

export const useMfa = () => {
  const context = useContext(MfaContext);

  if (context === undefined) {
    throw new Error('useMfa must be used within a MfaProvider');
  }

  return context;
};
