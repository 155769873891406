import { z } from 'zod';

import type { FilterSortOption } from '../sortOptions';
import { FilterSortId } from '../sortOptions';

export enum ReviewsSortSuffix {
  Default = '',
  Rating = 'by-rating-',
}

export const reviewsSortDefault: FilterSortOption = {
  id: FilterSortId.Default,
  isDefault: true,
  label: 'Most Recent',
  value: ReviewsSortSuffix.Default,
};
export const reviewsSortByRating: FilterSortOption = {
  id: FilterSortId.Rating,
  isDefault: false,
  label: 'Rating',
  value: ReviewsSortSuffix.Rating,
};
export const reviewsSortOptions: FilterSortOption[] = [
  reviewsSortDefault,
  reviewsSortByRating,
];

export enum Feeling {
  Blissful = 'blissful',
  Creative = 'creative',
  Energetic = 'energetic',
  Hungry = 'hungry',
  NotHigh = 'not high',
  PainFree = 'pain free',
  Relaxed = 'relaxed',
  Sleepy = 'sleepy',
}

export const AlgoliaReviewFacetSchema = z.object({
  activities: z.record(z.string(), z.number()).optional(),
  feelings: z.record(z.string(), z.number()).optional(),
  rating: z.record(z.string(), z.number()).optional(),
});

export type AlgoliaReviewFacets = z.infer<typeof AlgoliaReviewFacetSchema>;

export const AlgoliaReviewSchema = z.object({
  activities: z.array(z.string()),
  avatar: z.number(),
  comment: z.string(),
  created_at: z.string(),
  feelings: z.array(z.string()),
  has_comment: z.boolean(),
  hidden: z.boolean(),
  nickname: z.string(),
  objectID: z.string(),
  product_id: z.number(),
  rating: z.number(),
  updated_at: z.string(),
  upvote_count: z.number(),
});

export type AlgoliaReview = z.infer<typeof AlgoliaReviewSchema>;

export const AlgoliaReviewsSchema = z.array(AlgoliaReviewSchema);

export type AlgoliaReviews = z.infer<typeof AlgoliaReviewsSchema>;
