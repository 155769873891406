import styled from '@emotion/styled';

import { Button, Card, Flex, Popover, Typography } from '@jane/shared/reefer';
import { mediaQueries, spacing } from '@jane/shared/reefer-emotion';

export const QuantityButton = styled(Button)({
  width: '100%',
  marginLeft: 'auto',
});

// TODO(Kyr): Update Popover component to handle full-width targets
export const StyledPopover = styled(Popover)({
  width: '100%',
});

export const StyledTypography = styled(Typography)({
  textTransform: 'capitalize',
});

export const ProductDetailsContainer = styled(Flex)({
  cursor: 'pointer',
});

export const CartDrawerItem = styled(Card)({
  overflow: 'visible',
  ...spacing({ px: 8 }),
  [mediaQueries.tablet('min')]: {
    ...spacing({ px: 24 }),
  },
});
