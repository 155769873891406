import type { Reducer } from 'redux';

import type { DeepReadonly } from '@jane/shared/models';

import { createSimpleAction, createStandardAction } from '../../redux-util';
import { APPLICATION_OPEN_MODAL } from './application';
import type { CommonActions } from './types';

export const HIDE_FORM_VALIDATIONS = 'common/form/hide-form-validations';
export const hideFormValidations = createSimpleAction(HIDE_FORM_VALIDATIONS);

export const SUBMIT_FORM = 'common/form/submit-form';
export const submitForm = createSimpleAction(SUBMIT_FORM);

export const UNSUBMIT_FORM = 'common/form/unsubmit-form';
export const unsubmitForm = createSimpleAction(UNSUBMIT_FORM);

export const SHOW_FORM_VALIDATIONS = 'common/form/show-form-validations';
export const showFormValidations = createStandardAction(SHOW_FORM_VALIDATIONS)<
  Record<string, string[] | undefined | null>
>();

export type FormActions =
  | ReturnType<typeof hideFormValidations>
  | ReturnType<typeof showFormValidations>
  | ReturnType<typeof submitForm>
  | ReturnType<typeof unsubmitForm>;

export type FormState = DeepReadonly<{
  submited: boolean;
  validations: Record<string, string[] | undefined | null> | null;
}>;

const getInitialState = (): FormState => ({
  submited: false,
  validations: null,
});

export const formReducer: Reducer<FormState, CommonActions> = (
  state = getInitialState(),
  action
) => {
  switch (action.type) {
    case SUBMIT_FORM:
      return { ...state, submited: true };

    case UNSUBMIT_FORM:
      return { ...state, submited: false };

    case SHOW_FORM_VALIDATIONS:
      return { ...state, validations: action.payload };

    case HIDE_FORM_VALIDATIONS:
    case APPLICATION_OPEN_MODAL:
      return getInitialState();
  }

  return state;
};
