import { FLAGS, useFlag } from '@jane/shared/feature-flags';
import { useEcommApp } from '@jane/shared/providers';
import {
  Box,
  Flex,
  Loading,
  Skeleton,
  useDesktopMediaQuery,
  useMobileMediaQuery,
  useTabletMediaQuery,
} from '@jane/shared/reefer';

import {
  BlockSkeleton,
  CarouselSkeleton,
  CarouselSkeletonNew,
  FilterSkeleton,
  MenuProductDetailMobile,
  ProductDetailDesktop,
  ProductDetailMobile,
  ProductGridSkeleton,
  RoundSkeleton,
} from './skeletonComponents';

export const ProductSearchSkeleton = () => (
  <Flex justifyContent="center" width="100%">
    <Box width="100%" maxWidth="1440px">
      {/** NOTE: pending implementation of new filters */}
      {/* <FilterSkeleton /> */}
      <ProductGridSkeleton />
    </Box>
  </Flex>
);

export const StoreMenuSkeleton = () => (
  <Flex justifyContent="center" width="100%">
    <Box width="100%">
      {/** NOTE: pending implementation of new filters */}
      {/* <FilterSkeleton /> */}
      <CarouselSkeleton />
      <CarouselSkeleton />
      <CarouselSkeleton />
      <CarouselSkeleton />
    </Box>
  </Flex>
);

export const MenuSkeleton = () => {
  const isMobile = useMobileMediaQuery({});
  const myHighMenu = useFlag(FLAGS.myHighMenu);

  const { appMode } = useEcommApp();
  const isMarketplace = appMode === 'default';
  const headerHeight = isMarketplace
    ? isMobile
      ? '260px'
      : '308px'
    : !myHighMenu
    ? '0px'
    : '95px';

  return (
    <Flex flexDirection="column" width="100%" alignItems="center">
      <Skeleton.Bone height={headerHeight} width="100%" />
      <Flex
        flexDirection="column"
        p={isMobile ? 24 : 48}
        width="100%"
        maxWidth="1440px"
      >
        {myHighMenu ? (
          <Skeleton.Bone height={isMobile ? 520 : 600} />
        ) : (
          <>
            <Skeleton.Bone height="56px" mb={isMobile ? undefined : 24} />
            <FilterSkeleton />
            <CarouselSkeletonNew />
            <CarouselSkeletonNew />
          </>
        )}
      </Flex>
    </Flex>
  );
};

export const ProductDetailSkeleton = () => {
  const isMobile = useMobileMediaQuery({});

  return (
    <Flex justifyContent="center" width="100%">
      <Box width="100%" maxWidth="1440px">
        {isMobile ? <ProductDetailMobile /> : <ProductDetailDesktop />}
      </Box>
    </Flex>
  );
};

export const MenuProductDetailSkeleton = () => {
  const isMobile = useMobileMediaQuery({});

  return (
    <Flex justifyContent="center" width="100%">
      <Box width="100%" maxWidth="1440px">
        {isMobile ? <MenuProductDetailMobile /> : <ProductDetailDesktop />}
      </Box>
    </Flex>
  );
};

export const MenuTitleSkeleton = () => {
  const isTablet = useTabletMediaQuery({ width: 'max' });

  return (
    <Flex
      alignItems={isTablet ? 'flex-start' : 'center'}
      width="100%"
      gap={isTablet ? 16 : 24}
    >
      <RoundSkeleton diameter={isTablet ? 64 : 96} />
      <Flex width="100%" flexDirection="column" gap={8}>
        <Skeleton.Bone width={150} height={40} />
        <Skeleton.Bone width={isTablet ? 300 : 600} height={30} />
      </Flex>
    </Flex>
  );
};

export const CheckoutSkeleton = () => {
  const isDesktop = useDesktopMediaQuery({ size: 'sm', width: 'min' });
  return (
    <Flex
      maxWidth="1440px"
      width="100%"
      flexDirection={isDesktop ? 'row' : 'column'}
      gap={24}
      p={isDesktop ? 64 : 12}
      m="auto"
    >
      <Flex width="100%" flexDirection="column" gap={24}>
        <BlockSkeleton height={50} />
        <BlockSkeleton height={200} />
        <BlockSkeleton height={200} />
        <BlockSkeleton height={200} />
        <BlockSkeleton height={200} />
        <BlockSkeleton height={200} />
      </Flex>
      <Flex width="100%">
        <BlockSkeleton height={740} />
      </Flex>
    </Flex>
  );
};

export const FullPageLoading = () => {
  const { isIframe } = useEcommApp();

  return (
    <Box height={isIframe ? '700px' : '100vh'}>
      <Box position="relative" width="100%" height="100%">
        <Loading size="lg" />
      </Box>
    </Box>
  );
};
