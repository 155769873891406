import type { CSSObject } from '@emotion/react';
import { css, keyframes, useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import type { ForwardedRef, HTMLAttributes } from 'react';
import { NavLink } from 'react-router-dom';

import type { ReeferTheme } from '@jane/shared/reefer';
import { spacing } from '@jane/shared/reefer-emotion';

const ALPHA_MAP = {
  100: 0.08,
  200: 0.16,
  300: 0.24,
  400: 0.32,
  500: 0.4,
  600: 0.48,
  700: 0.56,
  800: 0.64,
  900: 0.72,
  1000: 0.8,
};

const hexToRgba = (color: string, alpha: number) => {
  const red = parseInt(`${color[1]}${color[2]}`, 16);
  const green = parseInt(`${color[3]}${color[4]}`, 16);
  const blue = parseInt(`${color[5]}${color[6]}`, 16);

  return `rgba(${red}, ${green}, ${blue}, ${alpha})`;
};

type Size = 'xs' | 'sm' | 'md' | 'lg';
type Sizes = { [key in Size]: CSSObject };

export const sizes: Sizes = {
  xs: spacing({ px: 24, py: 8 }),
  sm: spacing({ px: 24, py: 12 }),
  md: spacing({ px: 24, py: 16 }),
  lg: spacing({ px: 24, py: 20 }),
};

export type ButtonVariant =
  | 'fill'
  | 'outline'
  | 'ghost'
  | 'inverse'
  | 'danger'
  | 'secondary'
  | 'secondary-inverse'
  | 'success'
  | 'tertiary'
  | 'tertiary-selected';
type Variants = { [key in ButtonVariant]: CSSObject };

export const variants = (theme: ReeferTheme, textColor?: string): Variants => ({
  fill: {
    color: textColor || theme.colors.text.inverse || '#ffffff',
    background: theme.colors.primary.main,
    '@media (hover: hover)': {
      ':hover': {
        background: hexToRgba(theme.colors.primary.main, ALPHA_MAP[1000]),
      },
    },
    ':active': {
      background: theme.colors.primary.main,
    },
    ':focus': {
      background: theme.colors.primary.main,
    },
    ':disabled': {
      background: theme.colors.grays.ultralight,
      color: theme.colors.grays.mid,
      cursor: 'not-allowed',
    },
  },
  secondary: {
    color: textColor || theme.colors.text.inverse || '#ffffff',
    background: theme.colors.secondary.main,
    '@media (hover: hover)': {
      ':hover': {
        background: hexToRgba(theme.colors.secondary.main, ALPHA_MAP[1000]),
      },
    },
    ':active': {
      background: theme.colors.secondary.main,
    },
    ':focus': {
      background: theme.colors.secondary.main,
    },
    ':disabled': {
      background: theme.colors.grays.ultralight,
      color: theme.colors.grays.mid,
      cursor: 'not-allowed',
    },
  },
  'secondary-inverse': {
    fontWeight: 400,
    color: textColor || theme.colors.text.main,
    background: theme.colors.text.inverse,
    border: `1px solid ${theme.colors.grays.light}`,
    '@media (hover: hover)': {
      ':hover': {
        background: theme.colors.grays.ultralight,
      },
    },
    ':active': {
      background: theme.colors.grays.ultralight,
    },
    ':focus': {
      background: theme.colors.grays.ultralight,
    },
    ':disabled': {
      background: theme.colors.grays.ultralight,
      color: theme.colors.grays.mid,
      cursor: 'not-allowed',
    },
  },
  inverse: {
    color: textColor || theme.colors.primary.main,
    borderColor: theme.colors.primary.main,
    borderWidth: 1,
    background: theme.colors.grays.white,
    '@media (hover: hover)': {
      ':hover': {
        background: theme.colors.grays.ultralight,
      },
    },
    ':active': {
      background: theme.colors.grays.ultralight,
    },
    ':focus': {
      background: theme.colors.grays.ultralight,
    },
    ':disabled': {
      background: theme.colors.grays.ultralight,
      borderColor: theme.colors.grays.ultralight,
      color: theme.colors.grays.mid,
      cursor: 'not-allowed',
    },
  },
  outline: {
    color: textColor || theme.colors.primary.main,
    background: 'transparent',
    border: `1px solid ${theme.colors.primary.main}`,
    '@media (hover: hover)': {
      ':hover': {
        background: hexToRgba(theme.colors.primary.main, ALPHA_MAP[200]),
      },
    },
    ':active': {
      background: hexToRgba(theme.colors.primary.main, ALPHA_MAP[300]),
    },
    ':focus': {
      background: hexToRgba(theme.colors.primary.main, ALPHA_MAP[300]),
    },
    ':disabled': {
      background: 'transparent',
      color: theme.colors.grays.light,
      borderColor: theme.colors.grays.light,
      cursor: 'not-allowed',
    },
  },
  ghost: {
    color: theme.colors.primary.main,
    background: 'transparent',
    '@media (hover: hover)': {
      ':hover': {
        background: theme.colors.grays.ultralight,
      },
    },
    ':active': {
      background: theme.colors.grays.ultralight,
    },
    ':focus': {
      background: 'transparent',
      borderColor: theme.colors.grays.ultralight,
    },
    ':disabled': {
      color: theme.colors.grays.light,
      cursor: 'not-allowed',
    },
  },
  danger: {
    color: '#ffffff',
    background: theme.colors.system.negative.main,
    '@media (hover: hover)': {
      ':hover': {
        background: hexToRgba(
          theme.colors.system.negative.main,
          ALPHA_MAP[1000]
        ),
      },
    },
    ':active': {
      background: theme.colors.system.negative.main,
    },
    ':focus': {
      background: theme.colors.system.negative.main,
    },
    ':disabled': {
      background: theme.colors.grays.light,
      color: theme.colors.grays.mid,
      cursor: 'not-allowed',
    },
  },
  success: {
    color: '#ffffff',
    background: theme.colors.system.positive.main,
    '@media (hover: hover)': {
      ':hover': {
        background: hexToRgba(
          theme.colors.system.positive.main,
          ALPHA_MAP[1000]
        ),
      },
    },
    ':active': {
      background: theme.colors.system.positive.main,
    },
    ':focus': {
      background: theme.colors.system.positive.main,
    },
    ':disabled': {
      background: theme.colors.grays.ultralight,
      color: theme.colors.grays.mid,
      cursor: 'not-allowed',
    },
  },
  tertiary: {
    color: theme.colors.grays.black,
    background: theme.colors.grays.ultralight,
    border: `2px solid ${theme.colors.grays.ultralight}`,
    ':hover, :active': {
      backgroundColor: theme.colors.grays.light,
      borderColor: theme.colors.grays.light,
    },
    transition: 'all 250ms',
  },
  'tertiary-selected': {
    color: theme.colors.grays.black,
    background: theme.colors.grays.ultralight,
    border: `2px solid ${theme.colors.primary.main}`,
    ':hover, :active': {
      backgroundColor: theme.colors.grays.light,
    },
    transition: 'all 250ms',
  },
});

// SVG string is inserted into content: url(), and must be properly escaped
const svgSpinnerContent = `
<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'>
  <path stroke='%23d1cfd1' fill='%23d1cfd1' d='M108.92 355.08a48 48 0 1 0 48 48 48 48 0 0 0-48-48zM256 416a48 48 0 1 0 48 48 48 48 0 0 0-48-48zm208-208a48 48 0 1 0 48 48 48 48 0 0 0-48-48zm-60.92 147.08a48 48 0 1 0 48 48 48 48 0 0 0-48-48zm0-198.16a48 48 0 1 0-48-48 48 48 0 0 0 48 48z'/>
  <path stroke='%236d6a6c' fill='%236d6a6c' d='M108.92 60.92a48 48 0 1 0 48 48 48 48 0 0 0-48-48zM48 208a48 48 0 1 0 48 48 48 48 0 0 0-48-48zM256 0a48 48 0 1 0 48 48 48 48 0 0 0-48-48z' />
</svg>
`;

const rotateSpinner = keyframes({
  from: {
    transform: 'rotate(0deg)',
  },
  to: {
    transform: 'rotate(359deg)',
  },
});

const spinnerSvgStyle = css(spacing({ mr: 12 }), {
  display: 'inline-block',
  content: `url("data:image/svg+xml;utf8,${svgSpinnerContent}")`,
  height: 16,
  width: 16,
  animation: `${rotateSpinner} 2s infinite linear`,
  verticalAlign: 'bottom',
});

interface ButtonProps
  extends HTMLAttributes<HTMLAnchorElement | HTMLButtonElement> {
  ariaLabel: string;
  block?: boolean;
  disabled?: boolean;
  fontWeight?: number;
  icon?: string;
  loading?: boolean;
  ref?: ForwardedRef<HTMLButtonElement>;
  size?: Size;
  textColor?: string;
  to?: string;
  type?: 'button' | 'submit' | 'reset';
  variant?: ButtonVariant;
}

interface LinkProps {
  block?: boolean;
  disabled?: boolean;
  loading?: boolean;
  size?: Size;
  theme?: ReeferTheme;
  type?: 'button' | 'submit' | 'reset';
  variant?: ButtonVariant;
}

const StyledNavLink = styled(NavLink)<LinkProps>(
  {
    fontWeight: 600,
    border: '1px solid transparent',
    cursor: 'pointer',
    display: 'inline-block',
    lineHeight: 1,
  },
  ({ block = false, size = 'md', variant = 'fill', theme, loading }) => ({
    ...sizes[size],
    ...(block && { width: '100%' }),
    ...(loading && {
      '&::before': { ...spinnerSvgStyle },
      pointerEvents: 'none',
    }),
  })
);

const StyledButton = styled.button<Omit<ButtonProps, 'ariaLabel'>>(
  {
    border: '1px solid transparent',
    cursor: 'pointer',
    lineHeight: 1,
  },
  ({ block = false, size = 'md', theme, loading, fontWeight = 600 }) => ({
    fontWeight,
    borderRadius: theme?.borderRadius.lg,
    ...(block && { width: '100%' }),
    ...sizes[size],
    ...(loading && {
      '&::before': { ...spinnerSvgStyle },
      pointerEvents: 'none',
    }),
  })
);

export const LegacyButton = ({
  textColor,
  block,
  size,
  variant,
  to,
  loading,
  children,
  // REMOVED: Not needed for menuProductCard
  // icon,
  ref,
  ariaLabel,
  ...props
}: ButtonProps) => {
  const theme = useTheme();
  const styles = variants(theme, textColor)[variant || 'fill'];

  if (to) {
    return (
      <StyledNavLink
        css={styles}
        to={to}
        block={block}
        size={size}
        variant={variant}
        loading={loading}
        {...props}
      >
        {children}
      </StyledNavLink>
    );
  }
  return (
    <StyledButton
      {...props}
      {...(ref && { ref })}
      block={block}
      size={size}
      variant={variant}
      loading={loading}
      css={styles}
      aria-label={ariaLabel}
    >
      {children}
    </StyledButton>
  );
};
