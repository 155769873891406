import { useEffect, useState } from 'react';

import type { SelectFilterGroup } from '@jane/search/types';
import { useHandleIframeModal, useIsInIframe } from '@jane/shared-ecomm/hooks';
import { Modal, useMobileMediaQuery } from '@jane/shared/reefer';

import type { FilterModalProps } from './filterModal.types';
import { FilterModalFooter } from './filterModalFooter';
import { FilterModalSection } from './filterModalSection';
import { FilterModalTypeahead } from './filterModalTypeahead';
import { CONTENT_DIVIDER_PROPS } from './modalSection';
import { ModalTypeaheadToggle } from './modalSectionToggle';

export const FilterModal = <SearchState,>({
  filters,
  hideCounts = false,
  isLoading,
  onChange,
  onDeselect,
  open,
  searchState,
  setOpen,
  setSearchState,
  totalResults,
  variant,
}: FilterModalProps<SearchState>) => {
  const isMobile = useMobileMediaQuery({});
  const isInIframe = useIsInIframe();
  const [currentTypeahead, setCurrentTypeahead] = useState<string | null>(null);
  const [initialSearchState, setInitialSearchState] = useState<
    SearchState | undefined
  >();

  useEffect(() => {
    if (open) {
      setInitialSearchState(searchState);
    }
  }, [open]);

  const handleDiscardSelection = () => {
    if (initialSearchState) {
      const initialKeys = Object.keys(initialSearchState);
      const filtering =
        initialKeys.includes('filters') ||
        initialKeys.includes('rangeFilters') ||
        initialKeys.includes('bucketFilters');

      if (filtering) {
        setSearchState(initialSearchState);
      } else {
        onDeselect('all');
      }
    }

    setOpen(false);
  };

  const handleCurrentTypeahead = (key: string | null) => {
    setCurrentTypeahead(key);
  };

  const closeModal = () => {
    setOpen(false);
  };

  // resizes iframe when modal is open
  useHandleIframeModal(open);

  const renderedModalFilters = filters?.map((filterGroup) => {
    const { items } = filterGroup;

    if (!items.length) return null;

    if (filterGroup.typeahead) {
      // show typeahead when selected
      if (currentTypeahead === filterGroup.label) {
        return (
          <FilterModalTypeahead
            key={filterGroup.key}
            closeTypeahead={() => handleCurrentTypeahead(null)}
            hideCounts={hideCounts}
            filterGroup={filterGroup as SelectFilterGroup}
            onChange={onChange}
            onDeselect={onDeselect}
          />
        );
      }

      return (
        // if no typeahead is selected, show the toggle
        currentTypeahead === null && (
          <ModalTypeaheadToggle
            key={filterGroup.key}
            label={filterGroup.label}
            onClick={() => handleCurrentTypeahead(filterGroup.label)}
          />
        )
      );
    }

    return (
      // render all other sections when no typeahead is selected
      currentTypeahead === null && (
        <FilterModalSection
          key={filterGroup.key}
          filterGroup={filterGroup}
          hideCounts={hideCounts}
          onChange={onChange}
          onDeselect={onDeselect}
        />
      )
    );
  });

  return (
    <Modal
      open={open}
      onRequestClose={handleDiscardSelection}
      overlayClose
      variant={isMobile && !isInIframe ? 'full-screen' : 'standard'}
      background="grays-white"
    >
      {/** if a typeahead is selected, hide main modal.header to show only the typeahead */}
      {currentTypeahead !== null ? (
        <>{renderedModalFilters}</>
      ) : (
        <>
          <Modal.Header title="All filters" textAlign="center" />

          <Modal.Content padding={false}>
            {renderedModalFilters}
            <Modal.ContentDivider {...CONTENT_DIVIDER_PROPS} />
          </Modal.Content>
        </>
      )}
      <FilterModalFooter
        isLoading={isLoading}
        onDeselect={onDeselect}
        onClose={closeModal}
        totalResults={totalResults}
        variant={variant}
      />
    </Modal>
  );
};
