import { useEffect } from 'react';

import type { ReservationMode, Store } from '@jane/shared/models';
import type { Spacing } from '@jane/shared/reefer';
import { ButtonToggle, Flex, Tag, Typography } from '@jane/shared/reefer';
import { sentenceCase } from '@jane/shared/util';

import { useCustomerDispatch } from '../../../../customer/dispatch';
import { updateReservationMode } from '../../../../customer/redux/cart';
import { useCustomerSelector } from '../../../../customer/selectors';
import type OrderingSchedule from '../../../../lib/orderingSchedule';
import { reservationModeLabel } from '../../../../lib/store';
import type StoreSchedule from '../../../../lib/storeSchedule';
import { StyledButtonToggleButton } from './cartDrawerToggle.styles';

interface CartDrawerToggleProps {
  /** @private internal flag to configure headless styles to be initially tested in Bloom */
  bloomUserExperience?: boolean;
  mb?: Spacing;
  mt?: Spacing;
  schedules: OrderingSchedule;
  store: Store;
}

export const CartDrawerToggle = ({
  bloomUserExperience,
  mb,
  mt,
  schedules,
  store,
}: CartDrawerToggleProps) => {
  const dispatch = useCustomerDispatch();
  const { reservation_mode: currentReservationMode, userGroupSpecialId } =
    useCustomerSelector(
      ({
        cart: {
          userGroupSpecialId,
          cart: { reservation_mode },
        },
      }) => ({
        reservation_mode,
        userGroupSpecialId,
      })
    );

  const handleReservationModeToggle = (reservationMode: ReservationMode) => {
    dispatch(
      updateReservationMode({
        reservationMode,
        userGroupSpecialId,
      })
    );
  };

  const getReservationModeLabel = (reservationMode: ReservationMode) =>
    sentenceCase(reservationModeLabel(store, reservationMode));

  const reservationModes: ReservationMode[] = ['delivery', 'pickup'];

  const availableReservationModes = bloomUserExperience
    ? reservationModes
    : reservationModes.filter((mode) => schedules[`${mode}Schedule`]);

  const reservationSchedule = (
    reservationMode: ReservationMode
  ): StoreSchedule => schedules[`${reservationMode}Schedule`];

  const openReservationModes = availableReservationModes.reduce(
    (acc, mode) =>
      reservationSchedule(mode)?.isCurrentlyAcceptingReservations()
        ? [...acc, mode]
        : acc,
    []
  );

  useEffect(() => {
    const reservationMode = openReservationModes.includes(
      currentReservationMode
    )
      ? currentReservationMode
      : openReservationModes[0];

    if (reservationMode !== currentReservationMode) {
      handleReservationModeToggle(reservationMode);
    }
  }, []);

  if (availableReservationModes.length > 1) {
    const orderedReservationModes = bloomUserExperience
      ? availableReservationModes.reduce((modes, mode) => {
          if (mode === 'pickup') {
            return [mode, ...modes];
          }
          return [...modes, mode];
        }, [])
      : availableReservationModes;
    return (
      <Flex width="100%" mb={mb} mt={mt}>
        <ButtonToggle
          variant="tertiary"
          value={currentReservationMode}
          onChange={(orderType: ReservationMode) =>
            handleReservationModeToggle(orderType)
          }
        >
          {orderedReservationModes.map((mode) => (
            <StyledButtonToggleButton
              bloomUserExperience={bloomUserExperience}
              key={mode}
              value={mode}
              disabled={!openReservationModes.includes(mode)}
            >
              <Flex
                justifyContent="center"
                flexDirection={bloomUserExperience ? 'column' : 'row'}
              >
                <Typography color="inherit" as="span" variant="body-bold">
                  {getReservationModeLabel(mode)}
                </Typography>
                {!bloomUserExperience &&
                  !openReservationModes.includes(mode) && (
                    <Tag label="Closed" background="grays-black" ml={4} />
                  )}
                {bloomUserExperience && !openReservationModes.includes(mode) && (
                  <Typography color="inherit" as="span" variant="mini">
                    Unavailable
                  </Typography>
                )}
              </Flex>
            </StyledButtonToggleButton>
          ))}
        </ButtonToggle>
      </Flex>
    );
  }

  return (
    <Flex width="100%" justifyContent="center" mb={mb} mt={mt}>
      <Typography variant="body-bold">
        {getReservationModeLabel(currentReservationMode || reservationModes[0])}
      </Typography>
    </Flex>
  );
};
