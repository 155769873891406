export const impressionPath = '/v1/client_events/1' as const;
export const clickPath = '/v1/client_events/2' as const;

export interface Params {
  apiKey?: string;
  source: string;
  version: string;
}

const addRequiredQueryParams = (url: string, params: Params) => {
  const { source, apiKey, version } = params;
  const components = new URL(url);
  components.searchParams.set('jdm_source', source);
  components.searchParams.set('jdm_version', version);

  if (apiKey) {
    components.searchParams.set('jdm_api_key', apiKey);
  }
  components.searchParams.sort();
  return components.toString();
};

export const generateUrl = (host: string, path: string, params: Params) => {
  const url = new URL(host + path);

  return addRequiredQueryParams(url.toString(), params);
};

export const generateTrackingUrls = (host: string, params: Params) => {
  const clickUrl = new URL(host + clickPath);
  const impressionUrl = new URL(host + impressionPath);

  return {
    clickEndpoint: addRequiredQueryParams(clickUrl.toString(), params),
    impressionEndpoint: addRequiredQueryParams(
      impressionUrl.toString(),
      params
    ),
  };
};

class JaneDMApiRequestError extends Error {
  public response: Response;

  constructor(message: string, response: Response) {
    super(message);
    this.response = response;
  }
}

const handleResponse = async (res: Response) => {
  if (!res.ok) {
    throw new JaneDMApiRequestError('Api Request Failed', res);
  } else if (res.status === 204) {
    return null;
  }
  try {
    return await res.json();
  } catch {
    // do nothing
  }
};

const post = async <Body>(url: string, body: Body) => {
  const response = await fetch(url, {
    body: JSON.stringify(body),
    headers: {
      Accept: 'application/json',
      'Content-Type': 'text/plain',
    },
    method: 'POST',
  });

  return handleResponse(response);
};

const get = async (url: string, params: RequestInit = {}) => {
  const { headers, ...restOfParams } = params;
  const response = await fetch(url, {
    ...restOfParams,
    headers: { Accept: 'application/json', ...headers },
    method: 'GET',
  });

  return handleResponse(response);
};

export const api = { get, post };
