import styled from '@emotion/styled';

export interface Props {
  lines: number;
}
export const MultilineTruncate = styled.div<Props>(({ lines }) => ({
  display: '-webkit-box',
  overflow: 'hidden',
  WebkitBoxOrient: 'vertical',
  WebkitLineClamp: lines,
}));
