import clsx from 'clsx';
import type { CSSProperties, MouseEventHandler, ReactNode } from 'react';

import { getAccessibilityProps, toCSSSize } from '../../internal/utils';
import type { AccessibilityProps } from '../../internal/utils';
import type { ColorKey } from '../../theme';
import type { MarginProperties } from '../../utils';
import {
  fakeButtonProps,
  getColorCSSVar,
  handleEnterKey,
  marginCSSVars,
} from '../../utils';
import type { ReeferBaseProps } from '../../utils/baseProps.types';
import styles from './card.module.css';

export interface CardBaseProps {
  /** Applies a border around the entire `Card`component in a variety of predefined styles */
  border?: ColorKey | 'none';

  /** Optionally change the border width, defaults to 1px. For `Card.Content` can use shorthand to set bottom, top, left and right border widths eg. `0 0 1px 0`. */
  borderWidth?: string;

  /** Child elements of the `Card` component */
  children: ReactNode;

  /** Set a fixed height for the `Card` component, accepts any valid `height` value */
  height?: string | number;

  /** Sets `onClick` handler for the `Card` component itself (not the `Card` action) */
  onClick?: MouseEventHandler;

  /** Set a fixed width for the card, accepts any valid `width` value */
  width?: string | number;
}

export interface CardProps
  extends AccessibilityProps,
    CardBaseProps,
    ReeferBaseProps,
    MarginProperties {
  /** Disables the box shadow */
  flat?: boolean;
}

/**
 * The `Card` component is a wrapper component used for displaying a styled card with an optional `border` and `box-shadow`.
 * `Card` contents can be included via sub-components `Card.Action`, `Card.Content` and `Card.Image`.
 * */

export function Card({
  ariaLabel,
  ariaLabelledBy,
  border = 'none',
  borderWidth = '1px',
  children,
  className,
  'data-testid': testId,
  flat = false,
  height = 'auto',
  id,
  onClick,
  role,
  style,
  width = 'auto',
  ...marginProps
}: CardProps) {
  const a11yProps = getAccessibilityProps(
    { ariaLabel, ariaLabelledBy, role, onClick },
    'Card'
  );

  return (
    // eslint-disable-next-line jsx-a11y/no-static-element-interactions
    <div
      className={clsx(className, styles.card, {
        [styles.card__clickable]: !!onClick,
        [styles.card__flat]: flat,
      })}
      id={id}
      style={
        {
          '--card-border':
            border === 'none'
              ? 'none'
              : `${borderWidth} solid ${getColorCSSVar(border)}`,
          '--card-height': toCSSSize(height),
          ...marginCSSVars(marginProps),
          '--card-width': toCSSSize(width),
          ...style,
        } as CSSProperties
      }
      data-testid={testId}
      onClick={onClick}
      onKeyUp={(event) => handleEnterKey(event, onClick)}
      {...(onClick && fakeButtonProps)}
      {...a11yProps}
    >
      {children}
    </div>
  );
}
