import { keyframes } from '@emotion/react';
import styled from '@emotion/styled';
import { useEffect } from 'react';

import { Box } from '@jane/shared/reefer';

import { useAutoScroll } from './accordion';
import type { AccordionContentProps } from './accordion.types';
import { useItemExpanded, useItemId } from './accordionItemContext';

const ContentWrapper = styled.div<{ duration: number; isExpanded: boolean }>(
  ({ duration, isExpanded }) => ({
    display: 'grid',
    gridTemplateRows: isExpanded ? '1fr' : '0fr',
    transition: `${duration}ms`,
  })
);

const animateContent = keyframes`
0% { opacity: 0; transform: scale3d(0.99,0.99,1); }
50% { opacity: 0.5; }
100% { opacity: 1; transform: scale3d(1,1,1) }
`;

const Content = styled(Box)<{ duration: number }>(({ duration }) => ({
  overflow: 'hidden',
  animation: `${animateContent} ${duration}ms cubic-bezier(0.250, 0.460, 0.450, 0.940)`,
}));

export const AccordionContent = ({
  children,
  destroyOnClose = false,
  duration = 250,
  ...props
}: AccordionContentProps) => {
  const { autoScrollMargin } = useAutoScroll();
  const isExpanded = useItemExpanded();
  const id = useItemId();

  const heading = document.getElementById(`accordion-heading-${id}`);
  const content = document.getElementById(`accordion-content-${id}`);

  useEffect(() => {
    let timeout: NodeJS.Timeout;

    if (!!autoScrollMargin && isExpanded) {
      timeout = setTimeout(() => {
        // Don't readjust the scroll if we're already pretty close
        if (heading && Math.abs(heading.offsetTop - window.scrollY) > 125) {
          heading.scrollIntoView({ behavior: 'smooth' });
        }
      }, duration + 10);
    }

    return () => {
      // Don't "follow" the closing accordion if we're already near the top (or above) it
      if (
        content &&
        content.offsetTop + content.getBoundingClientRect().height / 2 <
          window.scrollY
      ) {
        content.scrollIntoView({ behavior: 'smooth' });
      }

      timeout && clearTimeout(timeout);
    };
  }, [isExpanded, autoScrollMargin]);

  return (
    <ContentWrapper duration={duration} isExpanded={isExpanded}>
      {(isExpanded || !destroyOnClose) && (
        <Content
          id={`accordion-content-${id}`}
          data-testid={`accordion-content-${id}`}
          key={`accordion-content-${id}`}
          role="region"
          aria-labelledby={`accordion-heading-${id}`}
          duration={duration}
          {...props}
        >
          {children}
        </Content>
      )}
    </ContentWrapper>
  );
};
