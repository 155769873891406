/**
 * DO NOT MODIFY THIS FILE!
 * It was created by the reefer-icons generator.
 * Please update & re-run the generator if need be.
 */
import type { SVGProps } from 'react';

import type { StaticIconProps } from '../../../icon/icon.types';
import { Icon } from '../../icon';

const PainFree24 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="100%"
    height="100%"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <g className="Pain free 24">
      <g className="SVG">
        <path
          fill="#FFC220"
          d="M24 12c0 6.627-5.373 12-12 12S0 18.627 0 12 5.373 0 12 0s12 5.373 12 12Z"
          className="Fill-Main"
        />
        <path
          fill="#000"
          fillOpacity={0.5}
          fillRule="evenodd"
          d="M10 9a2 2 0 1 1-4 0 2 2 0 0 1 4 0Zm-4.707 6.293a1 1 0 0 1 1.414 0 7.485 7.485 0 0 0 10.586 0 1 1 0 0 1 1.414 1.414 9.485 9.485 0 0 1-13.414 0 1 1 0 0 1 0-1.414ZM16 11a2 2 0 1 0 0-4 2 2 0 0 0 0 4Z"
          className="Fill-Secondary"
          clipRule="evenodd"
        />
      </g>
    </g>
  </svg>
);

const SIZE_MAP = {
  md: PainFree24,
  sm: PainFree24,
  lg: PainFree24,
  xl: PainFree24,
  xxl: PainFree24,
  xxxl: PainFree24,
};

export const PainFreeIcon = ({
  size = 'md',
  'data-testid': testId,
  ...props
}: StaticIconProps) => {
  return (
    <Icon
      data-testid={testId || 'pain-free-icon'}
      icon={SIZE_MAP[size]}
      {...props}
      size={size}
      color={undefined}
    />
  );
};
