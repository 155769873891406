import type { ComponentType } from 'react';
import { lazy } from 'react';

import { trackError } from './errorTracking';

/**
 * Custom lazy load component for
 * retrying failed requests up to
 * 3 times
 *
 * https://goenning.net/2018/11/16/how-to-retry-dynamic-import-with-react-lazy/
 */

type LoadComponent = Parameters<typeof lazy>[0];

export const retry = (
  fn: LoadComponent,
  retriesLeft = 3,
  timeout = 1000
): Promise<{ default: ComponentType<any> }> => {
  return new Promise((resolve, reject) => {
    fn()
      .then(resolve)
      .catch((error: Error) => {
        setTimeout(() => {
          if (retriesLeft === 1) {
            trackError(error, { message: 'Error lazy loading page' });
            reject(error);
            return;
          }

          retry(fn, retriesLeft - 1, timeout).then(resolve, reject);
        }, timeout);
      });
  });
};

export const load = (fn: LoadComponent) => lazy(() => retry(fn));
