import { Helmet } from 'react-helmet-async';

import { config } from '@jane/shared/config';

interface Props {
  description: string;
  // Tags added in Boost controller logic, can omit certain ones here
  hasTagsFromBoost?: boolean;
  image?: string;
  omitJane?: boolean;
  title: string;
  type?: string;
  url: string;
}

export const OpenGraph = ({
  title,
  description,
  image,
  url,
  type = 'website',
  omitJane = false,
  hasTagsFromBoost = false,
}: Props) => (
  <Helmet>
    <html
      lang="en"
      prefix="og: http://ogp.me/ns# fb: http://ogp.me/ns/fb# product: http://ogp.me/ns/product#"
    />
    {!hasTagsFromBoost && <meta property="og:title" content={title} />}
    {!hasTagsFromBoost && (
      <meta property="og:description" content={description} />
    )}
    {image && <meta property="og:image" content={image} />}
    {!hasTagsFromBoost && <meta property="og:url" content={url} />}
    <meta property="og:type" content={type} />
    {omitJane ? null : <meta property="og:site_name" content="Jane" />}
    <meta property="og:locale" content="en_US" />
    {omitJane ? null : (
      <meta property="fb:app_id" content={config.facebookAppId} />
    )}
    <meta
      name="twitter:card"
      content={image ? 'summary_large_image' : 'summary'}
    />
    {omitJane ? null : <meta name="twitter:creator" content="@_iheartjane" />}
    {omitJane ? null : <meta name="twitter:site" content="@_iheartjane" />}
    {!hasTagsFromBoost && <meta name="twitter:title" content={title} />}
    {!hasTagsFromBoost && (
      <meta name="twitter:description" content={description} />
    )}
    {image && <meta name="twitter:image" content={image} />}
  </Helmet>
);
