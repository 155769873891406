/**
 * DO NOT MODIFY THIS FILE!
 * It was created by the reefer-icons generator.
 * Please update & re-run the generator if need be.
 */
import type { SVGProps } from 'react';

import type { DynamicIconProps } from '../../../icon/icon.types';
import { Icon } from '../../icon';

const BagFilled24 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="100%"
    height="100%"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <g className="Bag filled 24" clipPath="url(#a)">
      <g className="SVG">
        <path
          fill="#5E1FF0"
          d="M23.379 21.515A2 2 0 0 1 21.439 24H2.561a2 2 0 0 1-1.94-2.485l1.318-5.276a2 2 0 0 0 .06-.485V7a2 2 0 0 1 2-2h15.795a2 2 0 0 1 2 1.955l.2 8.822c.003.148.023.296.06.44l1.324 5.298Z"
          className="Fill-Main"
        />
        <path
          fill="#5E1FF0"
          fillRule="evenodd"
          d="M12 2c-1.658 0-3 1.342-3 3H7c0-2.762 2.238-5 5-5s5 2.238 5 5h-2c0-1.658-1.342-3-3-3Z"
          className="Fill-Main"
          clipRule="evenodd"
        />
      </g>
    </g>
    <defs>
      <clipPath>
        <path fill="#fff" d="M0 0h24v24H0z" />
      </clipPath>
    </defs>
  </svg>
);

const SIZE_MAP = {
  md: BagFilled24,
  sm: BagFilled24,
  lg: BagFilled24,
  xl: BagFilled24,
  xxl: BagFilled24,
  xxxl: BagFilled24,
};

export const BagFilledIcon = ({
  size = 'md',
  'data-testid': testId,
  ...props
}: DynamicIconProps) => {
  return (
    <Icon
      data-testid={testId || 'bag-filled-icon'}
      icon={SIZE_MAP[size]}
      {...props}
      size={size}
    />
  );
};
