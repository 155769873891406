import debounce from 'lodash/debounce';
import { useEffect, useState } from 'react';

export const useDoneResizing = (maxWaitTime = 1500, debounceTime = 500) => {
  const [isDoneResizing, setIsDoneResizing] = useState(false);

  useEffect(() => {
    const resizeObserver = new ResizeObserver(() => {
      setIsDoneResizing(false);
      handleResize();
    });

    const maxWait = setTimeout(() => {
      setIsDoneResizing(true);
      resizeObserver.disconnect();
      handleResize.cancel();
    }, maxWaitTime);

    const handleResize = debounce(() => {
      setIsDoneResizing(true);
      resizeObserver.disconnect();
      clearTimeout(maxWait);
    }, debounceTime);

    resizeObserver.observe(document.body);

    return () => {
      clearTimeout(maxWait);
      resizeObserver.disconnect();
      handleResize.cancel();
    };
  }, [maxWaitTime]);

  return isDoneResizing;
};
