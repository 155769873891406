import { ErrorIcon, Flex, Typography } from '@jane/shared/reefer';

export type LocationErrors =
  | 'illegal'
  | 'regulations'
  | 'deliveryAddress'
  | 'noCity'
  | 'noDeliveryStores'
  | 'noPickupStores'
  | 'noStoresInSettings'
  | 'error';

export type ErrorMessages = {
  [key in LocationErrors]: {
    subtitle: string;
    title: string;
  };
};

export const errorMessages: ErrorMessages = {
  deliveryAddress: {
    title: 'For deliveries, please add a full address.',
    subtitle: '',
  },
  error: {
    title: 'Something went wrong.',
    subtitle:
      'Please try again or contact support@iheartjane.com to get some help.',
  },
  illegal: {
    title: 'Cannabis is not yet legalized in your state.',
    subtitle: 'Go back and try another location.',
  },
  regulations: {
    title: 'Unavailable in your area due to state regulations.',
    subtitle:
      'The regulations in your state restrict the app from displaying your local stores. Go back and try another location.',
  },
  noPickupStores: {
    title: `There aren't any stores on Jane in your area yet.`,
    subtitle:
      'Try another location or email us at support@iheartjane.com to request a store to be added to our network!',
  },
  noDeliveryStores: {
    title: `There aren't stores that deliver to your address.`,
    subtitle: `It looks like there aren't any stores that deliver to your address. Change your fulfillment to pickup and try again.`,
  },
  noStoresInSettings: {
    title: "There aren't stores that match your settings.",
    subtitle:
      "It looks like there aren't any stores that match your settings even though we detect products in your area. Broaden your settings and try again.",
  },
  noCity: {
    title: 'We need more information.',
    subtitle:
      'It looks like your search is too broad. Specify a location near you and try again.',
  },
};

export const UnservedLocationModalContent = ({
  error,
}: {
  error: LocationErrors;
}) => {
  if (!error) return null;
  const errorMessage = errorMessages[error] || {
    title: 'Oops!',
    subtitle: "We're sorry, please try again.",
  };

  return (
    <Flex justifyContent="center" flexDirection="column" alignItems="center">
      <ErrorIcon color="purple" size="xxl" mb={24} />
      <Typography variant="header-bold" textAlign="center">
        {errorMessage.title}
      </Typography>
      {errorMessage.subtitle.length > 0 && (
        <Typography variant="body" mt={8} textAlign="center">
          {errorMessage.subtitle}
        </Typography>
      )}
    </Flex>
  );
};
