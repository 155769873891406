import type { Address } from '@jane/shared/models';

export const getInitialDeliveryAddress = (
  deliveryAddress?: Partial<Address>
) => {
  return [
    deliveryAddress?.street?.trim() ?? '',
    deliveryAddress?.city?.trim() ?? '',
    deliveryAddress?.state_code?.trim() ?? '',
  ]
    .filter((str) => str.length > 0)
    .join(', ');
};
