import type { Reducer } from 'redux';

import type { DeepReadonly, Id, Product } from '@jane/shared/models';

import { createSimpleAction, createStandardAction } from '../../redux-util';
import { ProductsSource } from '../../sources/products';
import type { CustomerThunkAction } from '../redux';
import { GET_STORE_SUCCESS } from './store';
import type { CustomerAction } from './types';

export const GET_PRODUCT = 'product/get-product';
export const getProduct =
  ({ id, storeId }: { id: Id; storeId?: Id }): CustomerThunkAction =>
  (dispatch) => {
    dispatch(productIsLoading(true));

    ProductsSource.getProduct(id, storeId).then((result) => {
      dispatch({ type: GET_PRODUCT, payload: result.product });
    });
  };

export const RESET_PRODUCT = 'product/reset-product';
export const resetProduct = createSimpleAction(RESET_PRODUCT);

export const PRODUCT_IS_LOADING = 'product/product-is-loading';
export const productIsLoading =
  createStandardAction(PRODUCT_IS_LOADING)<unknown>();

export type ProductActions =
  | { payload: Product; type: typeof GET_PRODUCT }
  | ReturnType<typeof resetProduct>
  | ReturnType<typeof productIsLoading>;

export type ProductState = DeepReadonly<{
  isLoading: boolean;
  product: Product | undefined;
  selectedPrice: {};
  store: {
    delivery: boolean;
    full_address: string;
    id: Id;
    name: string;
    pickup: boolean;
    recreational: boolean;
    time_zone_identifier: string;
    working_hours: any[];
  };
}>;

const getInitialState = (): ProductState => ({
  product: undefined,
  isLoading: true,
  selectedPrice: {},
  store: {
    delivery: false,
    full_address: '',
    id: 0,
    name: '',
    pickup: false,
    recreational: false,
    time_zone_identifier: 'Pacific Time (US & Canada)',
    working_hours: [],
  },
});

export const productReducer: Reducer<ProductState, CustomerAction> = (
  state = getInitialState(),
  action
) => {
  switch (action.type) {
    case GET_PRODUCT:
      return { ...state, product: action.payload, isLoading: false };
    case GET_STORE_SUCCESS:
      return { ...state, store: action.payload.store };
    case RESET_PRODUCT:
      return getInitialState();
    case PRODUCT_IS_LOADING:
      return { ...state, isLoading: !!action.payload };
  }

  return state;
};
