import {
  countryList,
  getCountryName,
  getStateName,
  statePostalCode,
} from '@jane/shared/util';

export const coordToParam = (coord: number) =>
  coord.toString().replace('.', ',');

export const paramToCoord = (param: string) => Number(param.replace(',', '.'));

export const placeToParam = (place: string) => place.split(' ').join('-');

export const paramToPlace = (param: string) => param.split('-').join(' ');

export const userLocationToParams = (
  cityState?: string,
  countryCode?: string
) => {
  const isCityState = cityState?.includes(',');

  if (cityState && countryCode && isCityState) {
    const city = placeToParam(cityState.split(',')[0].trim().toLowerCase());
    const stateAcronym = cityState.split(',')[1].trim();
    const foundState = stateAcronym && getStateName(stateAcronym);
    const state = foundState ? placeToParam(foundState) : undefined;
    const foundCountryCode = countryCode && getCountryName(countryCode);
    const country = foundCountryCode
      ? placeToParam(foundCountryCode)
      : undefined;

    return country && state
      ? {
          country,
          state,
          city,
        }
      : null;
  }
  return null;
};

export const isValidCountryParam = (param: string) => {
  const countryName = paramToPlace(param);
  const isSupportedCountry = Object.keys(countryList).includes(countryName);

  return Boolean(isSupportedCountry);
};

export const isValidStateParam = (param: string) => {
  const stateName = paramToPlace(param);
  const isSupportedState = statePostalCode(stateName);

  return Boolean(isSupportedState);
};
