import { Button, Flex, useMobileMediaQuery } from '@jane/shared/reefer';

import type { ScreenName } from '../janeAuthentication';

interface SignInToggleButtonsProps {
  screen: ScreenName;
  setScreen: (screen: ScreenName) => void;
}

export const SignInToggleButtons = ({
  screen,
  setScreen,
}: SignInToggleButtonsProps) => {
  const isMobile = useMobileMediaQuery({ size: 'lg', width: 'max' });
  return (
    <Flex
      width="100%"
      flexDirection="row"
      alignItems="center"
      mb={isMobile ? 24 : 40}
    >
      <Button
        full
        variant={screen === 'sign-in' ? 'tertiary-selected' : 'tertiary'}
        label="Sign in"
        mr={16}
        onClick={() => setScreen('sign-in')}
        data-testid="sign-in-toggle"
      />
      <Button
        full
        variant={screen === 'create-account' ? 'tertiary-selected' : 'tertiary'}
        label="Create account"
        onClick={() => setScreen('create-account')}
        data-testid="create-account-toggle"
      />
    </Flex>
  );
};
