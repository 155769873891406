import { Box, Flex, Typography } from '@jane/shared/reefer';

import AppleButton from './appleButton';
import GoogleButton from './googleButton';

interface ContinueWithProps {
  onAppleClick(): void;
  onGoogleClick(): void;
  showAppleLogin: boolean;
  showGoogleLogin: boolean;
}

export const ContinueWith = ({
  onAppleClick,
  onGoogleClick,
  showAppleLogin,
  showGoogleLogin,
}: ContinueWithProps) => {
  return (
    <Box width="100%" data-test-id="social-sign-in">
      <Typography variant="body" textAlign="center" color="grays-mid">
        Continue with
      </Typography>
      <Flex width="100%" flexDirection="row" my={16} alignItems="center">
        {showAppleLogin && (
          <AppleButton onClick={onAppleClick} label="Apple" loaded mr={16} />
        )}
        {showGoogleLogin && (
          <GoogleButton onClick={onGoogleClick} label="Google" loaded />
        )}
      </Flex>
      <Typography variant="body" textAlign="center" color="grays-mid">
        or
      </Typography>
    </Box>
  );
};
