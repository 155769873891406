import difference from 'lodash/difference';
import isEqual from 'lodash/isEqual';
import uniq from 'lodash/uniq';
import { createContext, useContext, useEffect, useMemo, useState } from 'react';

import { useCustomerSelector } from '../../customer/selectors';
import { get } from '../../redux-util/selectors';

interface CartAlertBrandSpecialChangesContextType {
  addedBrandSpecialIds: number[];
  removedBrandSpecialIds: number[];
}

const CartAlertBrandSpecialChangesContext =
  createContext<CartAlertBrandSpecialChangesContextType>(
    {} as CartAlertBrandSpecialChangesContextType
  );

export const CartAlertBrandSpecialChangesProvider = ({ children }) => {
  const { cart } = useCustomerSelector(get('cart'));
  const [cartBrandSpecialIds, setCartBrandSpecialIds] = useState<number[]>([]);
  const [addedBrandSpecialIds, setAddedBrandSpecialIds] = useState<number[]>(
    []
  );
  const [removedBrandSpecialIds, setRemovedBrandSpecialIds] = useState<
    number[]
  >([]);

  useEffect(() => {
    const newBrandSpecialIds = uniq<number>(
      cart.products
        .map(({ brand_special_id }) => brand_special_id)
        .filter(Boolean)
        .sort((a, b) => a - b)
    );

    if (!isEqual(cartBrandSpecialIds, newBrandSpecialIds)) {
      const addedBrandSpecialIds = difference<number>(
        newBrandSpecialIds,
        cartBrandSpecialIds
      );
      setAddedBrandSpecialIds(addedBrandSpecialIds);
      const removedBrandSpecialIds = difference(
        cartBrandSpecialIds,
        newBrandSpecialIds
      );
      setRemovedBrandSpecialIds(removedBrandSpecialIds);
      setCartBrandSpecialIds(newBrandSpecialIds);
    }
  }, [cart.products, cartBrandSpecialIds]);

  const contextValue = useMemo(
    () => ({ addedBrandSpecialIds, removedBrandSpecialIds }),
    [addedBrandSpecialIds, removedBrandSpecialIds]
  );

  return (
    <CartAlertBrandSpecialChangesContext.Provider value={contextValue}>
      {children}
    </CartAlertBrandSpecialChangesContext.Provider>
  );
};

export const useCartAlertBrandSpecialChanges = () =>
  useContext(CartAlertBrandSpecialChangesContext);
