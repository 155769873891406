import { Popover as BasePopover } from './popover';
import type {
  PopoverContentProps,
  PopoverContextProps,
  PopoverProps,
} from './popover.types';
import { PopoverContent } from './popoverContent/popoverContent';
import {
  PopoverContext,
  usePopoverContext,
} from './popoverContext/popoverContext';
import { PopoverDivider } from './popoverDivider/popoverDivider';

export const Popover = Object.assign(BasePopover, {
  Content: PopoverContent,
  Divider: PopoverDivider,
});

export {
  PopoverContentProps,
  PopoverContext,
  PopoverContextProps,
  PopoverProps,
  usePopoverContext,
};
