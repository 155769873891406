/**
 * DO NOT MODIFY THIS FILE!
 * It was created by the reefer-icons generator.
 * Please update & re-run the generator if need be.
 */
import type { SVGProps } from 'react';

import type { StaticIconProps } from '../../../icon/icon.types';
import { Icon } from '../../icon';

const Apple24 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="100%"
    height="100%"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <g className="Apple 24">
      <path
        fill="#0E0E0E"
        d="M15.598 6.83c-.497 0-.961.078-1.392.233-.424.155-.811.31-1.162.465-.352.148-.656.222-.915.222-.271 0-.573-.07-.904-.212a17.085 17.085 0 0 0-1.073-.434 3.428 3.428 0 0 0-1.213-.213c-.815 0-1.6.226-2.355.677-.755.452-1.375 1.119-1.859 2.001C4.242 10.452 4 11.54 4 12.834c0 .808.093 1.617.278 2.425.186.809.434 1.573.746 2.294.311.721.655 1.358 1.033 1.91.49.694.967 1.29 1.431 1.79.47.498 1.014.747 1.63.747a2.54 2.54 0 0 0 1.053-.202c.299-.135.613-.273.945-.414.337-.135.758-.203 1.262-.203.384 0 .705.04.964.122.258.08.493.171.705.273.219.107.447.202.686.282.238.081.527.122.864.122a2.05 2.05 0 0 0 1.193-.364 4.22 4.22 0 0 0 .984-.92c.305-.377.6-.771.884-1.182.312-.465.563-.91.756-1.334.198-.431.344-.782.437-1.051.093-.276.142-.428.149-.455a9.716 9.716 0 0 1-.437-.222c-.265-.149-.57-.38-.915-.698-.337-.316-.636-.73-.894-1.243-.252-.512-.378-1.138-.378-1.88 0-.646.1-1.202.298-1.667.206-.472.444-.859.716-1.162a5.3 5.3 0 0 1 .726-.708c.218-.161.344-.252.377-.272-.331-.485-.692-.863-1.083-1.132a4.434 4.434 0 0 0-1.153-.586 4.77 4.77 0 0 0-1.014-.223 6.25 6.25 0 0 0-.645-.05Zm-.696-1.636c.305-.371.553-.799.745-1.284.192-.492.288-.997.288-1.516 0-.148-.01-.28-.03-.394-.496.02-1.016.172-1.56.455-.536.283-.98.636-1.331 1.06a4.812 4.812 0 0 0-.756 1.214 3.605 3.605 0 0 0-.328 1.495c0 .081.004.155.01.223l.03.141c.086.02.176.03.268.03.444 0 .915-.134 1.412-.404.503-.27.92-.61 1.252-1.02Z"
        className="\xF4\x80\xA3\xBA"
      />
    </g>
  </svg>
);

const SIZE_MAP = {
  md: Apple24,
  sm: Apple24,
  lg: Apple24,
  xl: Apple24,
  xxl: Apple24,
  xxxl: Apple24,
};

export const AppleIcon = ({
  size = 'md',
  'data-testid': testId,
  ...props
}: StaticIconProps) => {
  return (
    <Icon
      data-testid={testId || 'apple-icon'}
      icon={SIZE_MAP[size]}
      {...props}
      size={size}
      color={undefined}
    />
  );
};
