import { useQuery, useQueryClient } from '@tanstack/react-query';
import cloneDeep from 'lodash/cloneDeep';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import type {
  AppMode,
  FetchCartTopperRowProps,
  FetchMenuRowProps,
  FetchRecommendedRowProps,
  FetchRecommendedSortProps,
  JaneDM,
  SmartSort,
  SmartSortProduct,
} from '@jane/dm/sdk';
import { FLAGS, useFlag } from '@jane/shared/feature-flags';

import type { DmSdkSource } from '../useDmSdk';
import { useDmSdk } from '../useDmSdk';
import { useGetJaneDMIdentifiers } from '../useGetJaneDMIdentifiers';

export interface UseSmartSortResponse {
  fetchNextPage: () => Promise<void>;
  hasNextPage: boolean;
  instance: SmartSort | undefined;
  isError: boolean;
  isLoading: boolean;
  isSuccess: boolean;
  numHits: number;
  products: SmartSortProduct[];
  searchResultFacets: Record<string, Record<string, number>>;
}

export const isUseSmartSortResponse = (
  response: unknown
): response is UseSmartSortResponse => {
  return (
    typeof response === 'object' && response !== null && 'instance' in response
  );
};

export type UseSmartSortProps<T = unknown> = T & {
  appMode: AppMode;
  dependencies: (string | number | undefined | number[])[];
  distinctId?: string;
  enabled?: boolean;
  jdid?: string;
  source?: DmSdkSource;
};

export const useSmartMagicRow = (
  props: Omit<UseSmartSortProps<FetchRecommendedRowProps>, 'dependencies'>
) => {
  const {
    searchAttributes = ['*'],
    appMode,
    jdid,
    distinctId,
    storeId,
    searchFilter,
    searchOptionalFilters,
    source,
  } = props;

  const dependencies = [storeId, searchFilter, searchOptionalFilters];

  const fetchPlacement = useCallback(
    async (sdk: JaneDM) =>
      await sdk.fetchRecommendedRow({
        searchAttributes,
        searchFilter,
        searchOptionalFilters,
        storeId,
      }),
    dependencies
  );

  return useSmartSort(
    { appMode, distinctId, jdid, dependencies, source },
    fetchPlacement
  );
};

export const useSmartCartTopperRow = (
  props: Omit<UseSmartSortProps<FetchCartTopperRowProps>, 'dependencies'>
) => {
  const {
    appMode,
    distinctId,
    jdid,
    storeId,
    cartProductIds,
    searchAttributes = ['*'],
    source,
  } = props;

  const dependencies = [storeId, cartProductIds];

  const fetchPlacement = useCallback(
    async (sdk: JaneDM) =>
      await sdk.fetchCartTopperRow({
        cartProductIds,
        searchAttributes,
        storeId,
      }),
    dependencies
  );

  const enabled = useMemo(() => cartProductIds.length !== 0, dependencies);

  return useSmartSort(
    { appMode, distinctId, enabled, jdid, dependencies, source },
    fetchPlacement
  );
};

export const useRecommendedSort = (
  props: Omit<UseSmartSortProps<FetchRecommendedSortProps>, 'dependencies'>
) => {
  const {
    appMode,
    enabled,
    jdid,
    disableAds,
    distinctId,
    maxProducts,
    numColumns,
    pageSize,
    searchAttributes = ['*'],
    searchFacets = ['*'],
    searchFilter,
    searchOptionalFilters,
    searchQuery = '',
    searchSort,
    storeId,
    source,
  } = props;

  const dependencies = [
    maxProducts,
    storeId,
    searchFilter,
    searchQuery,
    searchSort,
  ];

  const fetchPlacement = useCallback(
    async (sdk: JaneDM) =>
      await sdk.fetchRecommendedSort({
        disableAds,
        maxProducts,
        numColumns,
        pageSize,
        searchAttributes,
        searchFacets,
        searchFilter,
        searchOptionalFilters,
        searchQuery,
        searchSort,
        storeId,
      }),
    dependencies
  );

  return useSmartSort(
    { appMode, dependencies, distinctId, enabled, jdid, source },
    fetchPlacement
  );
};

export const useSmartMenuRow = (
  props: Omit<UseSmartSortProps<FetchMenuRowProps>, 'dependencies'>
) => {
  const {
    appMode,
    enabled,
    jdid,
    disableAds,
    distinctId,
    numColumns,
    searchAttributes = ['*'],
    searchFilter,
    searchOptionalFilters,
    searchQuery = '',
    searchSort,
    storeId,
    source,
  } = props;

  const dependencies = [storeId, searchSort, searchFilter];

  const fetchPlacement = useCallback(
    async (sdk: JaneDM) =>
      await sdk.fetchMenuRow({
        disableAds,
        numColumns,
        searchAttributes,
        searchFilter,
        searchOptionalFilters,
        searchQuery,
        searchSort,
        storeId,
      }),
    dependencies
  );

  return useSmartSort(
    { appMode, dependencies, distinctId, enabled, jdid, source },
    fetchPlacement
  );
};

const useSmartSortOld = (
  props: UseSmartSortProps,
  fetchPlacement: (sdk: JaneDM) => Promise<SmartSort>
): UseSmartSortResponse => {
  const smartSortCaching = useFlag(FLAGS.smartSortUpdates);
  const { appMode, distinctId, jdid, source, enabled = true } = props;

  const sdk = useDmSdk({
    appMode,
    identifier: useGetJaneDMIdentifiers({
      jdid,
      mixpanelDistinctId: distinctId,
    }),
    source,
  });

  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [smartSortInstance, setSmartSortInstance] = useState<
    SmartSort | undefined
  >(undefined);

  const fetchPlacements = useCallback(async () => {
    setIsLoading(true);
    setIsError(false);
    setIsSuccess(false);

    try {
      const response =
        enabled && !smartSortCaching ? await fetchPlacement(sdk) : undefined;
      setSmartSortInstance(response);
      setIsSuccess(true);
    } catch {
      setIsError(true);
    } finally {
      setIsLoading(false);
    }
  }, [fetchPlacement, enabled, sdk]);

  const fetchNextPage = useCallback(async () => {
    if (smartSortInstance && smartSortInstance.hasNextPage()) {
      setIsLoading(true);
      setIsError(false);
      setIsSuccess(false);
      try {
        await smartSortInstance.nextPage();
        setIsSuccess(true);
      } catch {
        setIsError(true);
      } finally {
        setIsLoading(false);
      }
    }
  }, [smartSortInstance]);

  useEffect(() => {
    fetchPlacements();
  }, [fetchPlacements]);

  const smartSortResponse = useMemo(
    () => ({
      fetchNextPage,
      hasNextPage: smartSortInstance?.hasNextPage() ?? false,
      instance: smartSortInstance,
      isError,
      isLoading,
      isSuccess,
      numHits: smartSortInstance?.totalProducts ?? 0,
      products: smartSortInstance?.products ?? [],
      searchResultFacets: smartSortInstance?.searchFacets ?? {},
    }),
    [smartSortInstance, isError, isLoading, isSuccess, fetchNextPage]
  );

  return smartSortResponse;
};

const useSmartSortNew = (
  props: UseSmartSortProps,
  fetchPlacement: (sdk: JaneDM) => Promise<SmartSort>
): UseSmartSortResponse => {
  const smartSortCaching = useFlag(FLAGS.smartSortUpdates);
  const queryClient = useQueryClient();
  const instanceRef = useRef<SmartSort>();
  const {
    appMode,
    dependencies,
    enabled = true,
    distinctId,
    jdid,
    source,
  } = props;

  const identifier = useGetJaneDMIdentifiers({
    jdid,
    mixpanelDistinctId: distinctId,
  });

  const sdk = useDmSdk({ appMode, identifier, source });

  const queryKey = ['smart-sort', jdid, ...dependencies];

  const smartSortResult = useQuery({
    enabled: enabled && smartSortCaching,
    queryFn: async () => await fetchPlacement(sdk),
    // eslint-disable-next-line @tanstack/query/exhaustive-deps
    queryKey,
    staleTime: 5 * 60 * 1000,
  });

  useEffect(() => {
    if (!smartSortResult?.isStale && smartSortResult?.data) {
      instanceRef.current = smartSortResult.data;
    }
  }, [smartSortResult.data]);

  const fetchNextPage = async () => {
    await instanceRef.current?.nextPage();

    queryClient.setQueryData(queryKey, cloneDeep(instanceRef.current));
  };

  return {
    fetchNextPage,
    hasNextPage: smartSortResult.data?.hasNextPage() ?? false,
    instance: smartSortResult.data,
    isError: smartSortResult.isError,
    isLoading: smartSortResult.isLoading,
    isSuccess: smartSortResult.isSuccess,
    numHits: smartSortResult.data?.totalProducts ?? 0,
    products: smartSortResult.data?.products ?? [],
    searchResultFacets: smartSortResult.data?.searchFacets ?? {},
  };
};

const useSmartSort = (
  props: UseSmartSortProps,
  fetchPlacement: (sdk: JaneDM) => Promise<SmartSort>
) => {
  const smartSortCaching = useFlag(FLAGS.smartSortUpdates);

  const newSmartSort = useSmartSortNew(props, fetchPlacement);
  const oldSmartSort = useSmartSortOld(props, fetchPlacement);

  return smartSortCaching ? newSmartSort : oldSmartSort;
};
