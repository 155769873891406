import type { SVGProps } from 'react';

import { Icon } from '../../icon';
import type { LogoProps } from '../logo.types';

const Pnc = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 96 96"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M33.4945 55.3965H33.6423C33.7471 55.3965 33.8138 55.4511 33.8138 55.59C33.8138 55.6856 33.7741 55.8077 33.6423 55.8077H33.4945V55.3965ZM33.4945 56.0157H33.5779C33.8344 56.0157 33.8074 56.1659 33.8074 56.3402C33.8074 56.439 33.8074 56.537 33.8511 56.6261H34.1751C34.1442 56.5651 34.1378 56.2888 34.1378 56.1932C34.1378 55.929 33.9218 55.9105 33.8511 55.9073V55.9001C34.0671 55.8671 34.1442 55.7266 34.1442 55.5394C34.1442 55.2872 33.9996 55.1507 33.7741 55.1507H33.1642V56.6261H33.4945V56.0157ZM33.6526 54.6262C32.9553 54.6262 32.3962 55.1884 32.3962 55.8936C32.3962 56.5988 32.9553 57.1675 33.6526 57.1675C34.3467 57.1675 34.9026 56.5988 34.9026 55.8936C34.9026 55.1884 34.3467 54.6262 33.6526 54.6262ZM33.6526 56.9297C33.0864 56.9297 32.6313 56.4631 32.6313 55.8936C32.6313 55.325 33.0864 54.8639 33.6526 54.8639C34.2077 54.8639 34.6667 55.325 34.6667 55.8936C34.6667 56.4631 34.2077 56.9297 33.6526 56.9297Z"
      fill="#F58025"
    />
    <path
      d="M26.095 38.3691C20.9506 38.3691 16.7805 42.5866 16.7805 47.7881C16.7805 52.9903 20.9506 57.207 26.095 57.207C31.2379 57.207 35.408 52.9903 35.408 47.7881C35.408 42.5866 31.2379 38.3691 26.095 38.3691ZM21.0856 42.9376L21.0887 42.6204C21.0927 41.5843 21.5184 40.7152 22.1037 40.5586C22.8684 40.353 23.8992 41.2734 24.9324 43.083L25.8925 44.7649C25.613 44.8139 25.3771 45.0018 25.1945 45.3231L22.06 50.8161C21.6018 51.6153 21.2849 52.3357 21.1062 52.9662C21.1054 52.9566 21.1054 52.9469 21.1054 52.9357C21.0737 51.4859 21.0451 48.5752 21.0856 42.9376ZM24.4448 54.5983L24.1716 54.7557C23.2814 55.2722 22.3268 55.3332 21.8988 54.8979C21.3405 54.3308 21.6129 52.9678 22.6461 51.159L23.6086 49.4732C23.792 49.6964 24.0692 49.8073 24.4345 49.8073H30.7035C31.6183 49.8073 32.3926 49.7262 33.0224 49.5687C33.0128 49.5736 33.0057 49.5768 32.9961 49.584C31.7716 50.335 29.2915 51.8193 24.4448 54.5983ZM30.7035 49.1222H28.7808C28.8785 48.8499 28.8372 48.5511 28.6546 48.2322L25.5193 42.7409C25.0619 41.9393 24.6044 41.3015 24.1534 40.8301C24.1637 40.8349 24.1716 40.8397 24.1804 40.8453C25.4359 41.5401 27.9422 42.9698 32.7468 45.8243L33.0224 45.9865C33.9086 46.5094 34.4375 47.315 34.2795 47.9069C34.0738 48.6788 32.7706 49.1222 30.7035 49.1222Z"
      fill="#F58025"
    />
    <path
      d="M44.0636 47.5741H43.1297V43.1052C43.1297 42.0562 43.1297 41.8956 45.1103 41.8956C47.3355 41.8956 48.5608 42.9076 48.5608 44.7453C48.5608 47.2376 46.2872 47.5741 44.0636 47.5741ZM44.363 40.8346H38.3473H38.21V41.4394L38.3315 41.4538C40.4733 41.7173 40.6909 42.0016 40.6909 43.1068V54.6221H43.1297V48.6351H44.1136C46.4309 48.6351 51.253 48.6351 51.253 44.7453C51.253 40.8346 46.7708 40.8346 44.363 40.8346Z"
      fill="#0069AA"
    />
    <path
      d="M65.0488 40.8357H63.6153V51.5823C63.2373 51.0498 55.9884 40.8357 55.9884 40.8357H50.8137V41.4381L50.9336 41.4542C53.027 41.7297 53.2946 41.8236 53.2946 43.3449V54.6216H54.8654V43.0637C55.2458 43.5979 63.0872 54.6216 63.0872 54.6216H65.1861V40.8357H65.0488Z"
      fill="#0069AA"
    />
    <path
      d="M67.0557 47.2769C67.0557 51.0406 69.3269 54.8389 74.4007 54.8389C76.8101 54.8389 77.9974 54.5706 79.0997 54.2718L79.2013 54.2445V53.1136L79.0147 53.1642C77.8433 53.4823 76.6918 53.6574 75.773 53.6574C71.7189 53.6574 69.7478 51.4004 69.7478 46.7556C69.7478 43.6513 71.2074 41.7991 73.6518 41.7991C75.2917 41.7991 76.2534 42.4497 76.5115 43.734L76.5338 43.8449H79.2196L79.1997 43.6882C79.1084 42.9678 78.45 40.6177 73.8019 40.6177C69.3253 40.6177 67.0557 42.8577 67.0557 47.2769Z"
      fill="#0069AA"
    />
  </svg>
);
export const PncLogo = ({
  height = 48,
  width = 'auto',
  ...props
}: LogoProps) => {
  return (
    <Icon
      height={height}
      width={width}
      {...props}
      icon={Pnc}
      color={undefined}
      rotate={undefined}
      size={undefined}
    />
  );
};
