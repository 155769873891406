import {
  BearAvatar,
  CatAvatar,
  HoundDogAvatar,
  KoalaAvatar,
  LionAvatar,
  MonkeyAvatar,
  OwlAvatar,
  PineappleAvatar,
  SeaLionAvatar,
  SkunkAvatar,
  SlothAvatar,
  TerrierDogAvatar,
} from '@jane/shared/reefer';
import type { IconSize } from '@jane/shared/reefer';

// These are ordered in a way that properly maps to the user's profile.
// ie. index 0 is user.avatar = 0
export const AVATARS = [
  { avatar: CatAvatar, altText: 'Cat Avatar' },
  { avatar: BearAvatar, altText: 'Bear Avatar' },
  { avatar: MonkeyAvatar, altText: 'Monkey Avatar' },
  { avatar: PineappleAvatar, altText: 'Pineapple Avatar' },
  { avatar: KoalaAvatar, altText: 'Koala Avatar' },
  { avatar: TerrierDogAvatar, altText: 'Terrier Avatar' },
  { avatar: LionAvatar, altText: 'Lion Avatar' },
  { avatar: SlothAvatar, altText: 'Sloth Avatar' },
  { avatar: OwlAvatar, altText: 'Owl Avatar' },
  { avatar: SkunkAvatar, altText: 'Skunk Avatar' },
  { avatar: HoundDogAvatar, altText: 'Hound Dog Avatar' },
  { avatar: SeaLionAvatar, altText: 'Sea Lion Avatar' },
];

// The profile edit avatars are sorted by design, not numerical order.
// These numbers reference the AVATARS array indices
export const AVATARS_SORTED_BY_DESIGN = [10, 11, 4, 5, 3, 2, 0, 1, 6, 7, 9, 8];

export const AVATAR_COUNT = AVATARS.length;

export interface AvatarProps {
  altText?: string;
  avatar: number;
  size?: IconSize;
}

export const Avatar = ({ avatar, size = 'xxl', altText }: AvatarProps) => {
  const avatarIndex = avatar % AVATAR_COUNT;
  const AvatarComponent = AVATARS[avatarIndex].avatar;

  return (
    <AvatarComponent
      altText={altText || AVATARS[avatarIndex].altText}
      size={size}
    />
  );
};
