import type { AlgoliaBrand } from '@jane/search/types';
import { CardCarousel } from '@jane/shared/components';

import {
  BRAND_CARD_HEIGHT,
  BRAND_CARD_HEIGHT_MOBILE,
  BRAND_CARD_WIDTH,
  BRAND_CARD_WIDTH_MOBILE,
  BrandCard,
} from '../../';

export interface BrandCardCarouselProps {
  brandCardOnClick?: (brand: AlgoliaBrand, index: number) => void;
  brandCardTo?: (brandId: string | number, brandName: string) => string;
  brands: AlgoliaBrand[];
  isLoading?: boolean;
  title?: string;
  viewAllTo?: string;
}

export const BrandCardCarousel = ({
  brandCardOnClick,
  brandCardTo,
  brands,
  isLoading,
  title,
  viewAllTo,
}: BrandCardCarouselProps) => {
  if (isLoading || brands.length > 0) {
    return (
      <CardCarousel
        cardHeight={BRAND_CARD_HEIGHT}
        cardHeightMobile={BRAND_CARD_HEIGHT_MOBILE}
        cardWidth={BRAND_CARD_WIDTH}
        cardWidthMobile={BRAND_CARD_WIDTH_MOBILE}
        cardGap={18}
        cardGapMobile={18}
        isLoading={isLoading}
        minHeight={206}
        mode="min-gap"
        mt={24}
        mb={48}
        name={title || 'Brands'}
        buttonTo={viewAllTo}
      >
        {brands.slice(0, 20).map((brand, index) => (
          <CardCarousel.Card key={brand.id}>
            <BrandCard
              image={brand.logo_url}
              name={brand.name}
              onClick={
                brandCardOnClick && (() => brandCardOnClick(brand, index))
              }
              // NOTE(sarah): Temporarily hiding until discrepancies with brands pages are resolved
              // productCount={brand.productCount}
              showGoldIcon={brand.has_active_brand_special}
              to={brandCardTo && brandCardTo(brand.id, brand.name)}
            />
          </CardCarousel.Card>
        ))}
      </CardCarousel>
    );
  }

  return null;
};
