import type { Dispatch, SetStateAction } from 'react';
import { createContext, useContext } from 'react';

export const AccordionItemExpandedContext = createContext<boolean | undefined>(
  undefined
);

export const AccordionItemSetExpandedContext = createContext<
  Dispatch<SetStateAction<boolean>> | undefined
>(undefined);

export const AccordionItemIdContext = createContext<string | undefined>(
  undefined
);

export const useItemId = () => {
  const context = useContext(AccordionItemIdContext);
  if (context === undefined) {
    throw new Error('useItemId must be used within a AccordionItem');
  }

  return context;
};

export const useItemExpanded = () => {
  const context = useContext(AccordionItemExpandedContext);
  if (context === undefined) {
    throw new Error('useItemExpanded must be used within a AccordionItem');
  }

  return context;
};

export const useSetItemExpanded = () => {
  const context = useContext(AccordionItemSetExpandedContext);
  if (context === undefined) {
    throw new Error('useSetItemExpanded must be used within a AccordionItem');
  }

  return context;
};
