import { Global } from '@emotion/react';
import 'shared/stylesheets/application.css';

// Android & iPhone browser quirks were reported where focusing on a text input resulted
// in zooming to the maximum level.
const phoneStyle = {
  'input[type=text]': {
    touchAction: 'none',
  },
  'input[type=search]': {
    touchAction: 'none',
  },
  'input[type=email]': {
    touchAction: 'none',
  },
  'input[type=password]': {
    touchAction: 'none',
  },
  'input[type=tel]': {
    touchAction: 'none',
  },
  // Default input type
  'input:not([type])': {
    touchAction: 'none',
  },
  textarea: {
    touchAction: 'none',
  },
};

const CustomerGlobal = () => {
  const styles = /Android|iPhone/i.test(navigator.userAgent)
    ? phoneStyle
    : undefined;

  return <Global styles={styles} />;
};

export default CustomerGlobal;
