import kebabCase from 'lodash/kebabCase';

// NOTE(sarah): This is porting functionality out of the monolith
// from client/libs/monolith/shared/src/lib/lib/routes.ts:
// this only ports over what's needed for the brands page;
// porting over the whole util will be split into a separate piece of work.
// We will determine the best API for this util as part of that work.

export const getBrandProductPath = (id: string | number, name: string) => {
  return `/brands/${id}/${kebabCase(name)}`;
};

export const getBrandSpecialDetailPath = ({
  brandId,
  brandName,
  brandSpecialId,
}: {
  brandId: string | number;
  brandName: string;
  brandSpecialId: string | number;
}) => `/brands/${brandId}/${kebabCase(brandName)}/specials/${brandSpecialId}`;

export const getBrandOrProductDetailPath = ({
  brandId,
  brandName = '',
  productId,
  productName,
}: {
  brandId?: string | number;
  brandName?: string;
  productId: string | number;
  productName: string;
}) => {
  if (!brandId)
    return `/products/${productId}/${kebabCase(brandName + productName)}`;

  return `/brands/${brandId}/${kebabCase(
    brandName
  )}/products/${productId}/${kebabCase(brandName + productName)}`;
};

export const getJaneGoldLandingPath = () =>
  'https://iheartjane.com/janegold?utm_source=website&utm_medium=Jane';

export const getJaneGoldFAQPath = () =>
  ' https://customer-support.iheartjane.com/s/article/Jane-Gold-FAQs';
