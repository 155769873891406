import type { ReactNode } from 'react';

import {
  Flex,
  Skeleton,
  Typography,
  useMobileMediaQuery,
} from '@jane/shared/reefer';

import { CarouselHeaderContainer } from './carouselHeader.styles';

export interface CarouselHeaderProps {
  afterHeader?: ReactNode;

  beforeHeader?: ReactNode;
  /** Carousel children, add carousel cards here */
  children?: ReactNode;

  /** Whether to display the carousel header's loading state */
  isLoading?: boolean;

  /** Name of the carousel, displayed as heading beside the controls */
  name: string;
}

type CarouselHeaderSkeletonProps = Omit<
  CarouselHeaderProps,
  'afterHeader' | 'beforeHeader' | 'isLoading' | 'name'
>;

function CarouselHeaderSkeleton({ children }: CarouselHeaderSkeletonProps) {
  const isMobile = useMobileMediaQuery({});
  return (
    <CarouselHeaderContainer
      alignItems="center"
      justifyContent="space-between"
      width="100%"
    >
      <Flex width="50%">
        <Skeleton.Bone
          height={isMobile ? '24px' : '48px'}
          width="100%"
          borderRadius="sm"
        />
      </Flex>
      {children}
    </CarouselHeaderContainer>
  );
}

export function CarouselHeader({
  afterHeader,
  beforeHeader,
  children,
  isLoading = false,
  name,
}: CarouselHeaderProps) {
  if (isLoading) {
    return <CarouselHeaderSkeleton>{children}</CarouselHeaderSkeleton>;
  }
  return (
    <CarouselHeaderContainer
      alignItems="center"
      justifyContent="space-between"
      width="100%"
    >
      {!beforeHeader ? (
        <Typography as="h2" branded variant="header-bold">
          {name}
        </Typography>
      ) : (
        beforeHeader
      )}
      {children}
      {afterHeader ? afterHeader : null}
    </CarouselHeaderContainer>
  );
}
