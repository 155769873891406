import type { Reducer } from 'redux';

import type {
  DeepReadonly,
  Id,
  StoreWithMenuProduct,
} from '@jane/shared/models';

import { createStandardAction } from '../../redux-util';
import { StoresForProductSource } from '../../sources/storesForProduct';
import type { CustomerThunkActionCreator } from '../redux';
import type { CustomerAction } from './types';

export interface GetStoresForProductsProps {
  coordinates?: { lat: number; long: number };
  forceStoreVisibility?: boolean | null;
  id: Id;
  maxRadius?: number | string | null;
  specialId: Id | null;
}

export const GET_STORES_FOR_PRODUCT = 'storesForProduct/getStoresForProduct';
export const getStoresForProduct: CustomerThunkActionCreator<
  GetStoresForProductsProps
> =
  ({ id, coordinates, maxRadius, specialId, forceStoreVisibility }) =>
  (dispatch, getState) => {
    dispatch({ type: GET_STORES_FOR_PRODUCT });
    const {
      embeddedApp: { appMode },
    } = getState();

    return StoresForProductSource.get({
      id,
      coordinates,
      maxRadius,
      specialId,
      forceStoreVisibility,
      appMode,
    }).then(
      (result) => dispatch(getStoresForProductSuccess(result.stores)),
      (err) => dispatch(getStoresForProductFailure(err))
    );
  };

export const GET_STORES_FOR_PRODUCT_SUCCESS = 'stores-for-product/get-success';
export const getStoresForProductSuccess = createStandardAction(
  GET_STORES_FOR_PRODUCT_SUCCESS
)<any[]>();

export const GET_STORES_FOR_PRODUCT_FAILURE = 'stores-for-product/get-failure';
export const getStoresForProductFailure = createStandardAction(
  GET_STORES_FOR_PRODUCT_FAILURE
)<unknown>();

export type StoresForProductActions =
  | { type: typeof GET_STORES_FOR_PRODUCT }
  | ReturnType<typeof getStoresForProductSuccess>
  | ReturnType<typeof getStoresForProductFailure>;

export type StoresForProductState = DeepReadonly<{
  error: undefined | any;
  isLoading: boolean;
  stores: StoreWithMenuProduct[];
}>;

const getInitialState = (): StoresForProductState => ({
  error: undefined,
  isLoading: false,
  stores: [],
});

export const storesForProductReducer: Reducer<
  StoresForProductState,
  CustomerAction
> = (state = getInitialState(), action) => {
  switch (action.type) {
    case GET_STORES_FOR_PRODUCT:
      return { isLoading: true, stores: [], error: undefined };

    case GET_STORES_FOR_PRODUCT_SUCCESS:
      return { ...state, isLoading: false, stores: action.payload };

    case GET_STORES_FOR_PRODUCT_FAILURE:
      return { ...state, isLoading: false, error: action.payload };
  }

  return state;
};
