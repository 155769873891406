import {
  CardCarousel,
  CarouselListSkeleton,
  DEFAULT_DESKTOP_CARD_WIDTH,
  DEFAULT_MOBILE_CAROUSEL_CARD_WIDTH,
  MENU_PRODUCT_ITEM_HEIGHT,
  MENU_PRODUCT_MOBILE_ITEM_HEIGHT,
} from '@jane/shared/components';

import { ListViewCarousel } from './listViewCarousel';
import { StyledCarouselWrapper } from './menuProductCarousel.styles';
import type { MenuProductCarouselProps } from './menuProductCarousel.types';
import { MenuProductCarouselTitle } from './menuProductCarouselTitle';

/**
 * This component uses CardCarousel under the hood. Children elements should be wrapped in CardCarousel.Card for correct styling
 */

export const MenuProductCarousel = ({
  children,
  cardRef,
  isLoading,
  listView,
  minHeight = 376,
  mode = 'fixed',
  name,
  onHover,
  onLeave,
  variant = 'default',
  viewAllOnClick,
  viewAllTo,
  ...props
}: MenuProductCarouselProps) => {
  const isAd = variant === 'sponsored' || variant === 'sponsored-inline';
  if (listView) {
    return isLoading ? (
      <CarouselListSkeleton />
    ) : (
      <ListViewCarousel
        name={name}
        onHover={onHover}
        onLeave={onLeave}
        variant={variant}
        viewAllTo={viewAllTo}
        viewAllOnClick={viewAllOnClick}
        {...props}
      >
        {children}
      </ListViewCarousel>
    );
  }

  return (
    <StyledCarouselWrapper
      ariaLabel={isAd ? 'Sponsored content' : name}
      border={
        variant === 'sponsored-inline' ? 'transparent-black-ten' : undefined
      }
      onMouseEnter={onHover}
      onMouseLeave={onLeave}
      variant={variant}
      {...props}
    >
      <CardCarousel
        ref={cardRef}
        cardGap={24}
        cardGapMobile={16}
        cardHeight={MENU_PRODUCT_ITEM_HEIGHT}
        cardHeightMobile={MENU_PRODUCT_MOBILE_ITEM_HEIGHT}
        cardWidth={DEFAULT_DESKTOP_CARD_WIDTH}
        cardWidthMobile={DEFAULT_MOBILE_CAROUSEL_CARD_WIDTH}
        mode={mode}
        name={name}
        buttonTo={viewAllTo}
        buttonOnClick={viewAllOnClick}
        minHeight={minHeight}
        isLoading={isLoading}
        beforeHeader={
          <MenuProductCarouselTitle name={name} variant={variant} />
        }
        data-janedm-tag={variant === 'magic-row' ? 'product cards' : undefined}
      >
        {children}
      </CardCarousel>
    </StyledCarouselWrapper>
  );
};
