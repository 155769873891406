export const placementMenuTopRow = 'menu_top_row';
export const placementMenuInline = 'menu_inline';
export const placementMenuInlineTable = 'menu_inline_table';
export const placementCartToppers = 'cart_toppers';
export const placementPDPRow = 'pdp_row';
export const placementRecommendedRow = 'menu_dynamic_row';

export const placements = [
  placementMenuTopRow,
  placementMenuInline,
  placementMenuInlineTable,
  placementCartToppers,
  placementPDPRow,
  placementRecommendedRow,
] as const;

export type Placement = typeof placements[number];
