import { CountDot } from '@jane/shared/components';
import { BagFilledIcon, Box } from '@jane/shared/reefer';

export const BagIcon = ({ productCount }: { productCount: number }) => {
  return (
    <Box position="relative" m={12}>
      <BagFilledIcon color="primary" />
      {productCount > 0 && (
        <CountDot ariaLabel="number of cart items" count={productCount} />
      )}
    </Box>
  );
};
