import type { ReactNode } from 'react';

import { Flex, Typography } from '@jane/shared/reefer';

export interface EmptyResultsProps {
  children?: ReactNode;
}
/**
 * The `EmptyResults` component is used to display empty results text on pages of items with no results.
 */
export const EmptyResults = ({ children }: EmptyResultsProps) => {
  return (
    <Flex
      alignItems="center"
      flexDirection="column"
      minHeight={420}
      p={48}
      gap={16}
    >
      <Typography as="h2" branded textAlign="center" variant="header-bold">
        0 matches, that stinks.
      </Typography>
      {children}
    </Flex>
  );
};
