import styled from '@emotion/styled';

import type {
  MenuProduct,
  PriceId,
  WeightSelectorWeight,
} from '@jane/shared/models';
import { Box, Flex } from '@jane/shared/reefer';

import SvgIcon from '../../legacyIcons/legacySvgIcon';
import { VariantSelectionButton } from './variantSelectionButton';

export const priceForWeight = (
  weight: PriceId,
  weights: WeightSelectorWeight[]
): number | string => {
  const foundWeightItem = weights.find(
    (weightItem: WeightSelectorWeight) => weightItem.value === weight
  );
  return foundWeightItem && foundWeightItem.price ? foundWeightItem.price : '-';
};

export const CheckIconWrapper = styled.span(
  {
    position: 'absolute',
    top: -4,
    right: -3,
    zIndex: 10,
    svg: {
      background: 'white',
      opacity: 1,
      border: '1px solid inherit',
      borderRadius: '50%',
    },
  },
  ({ theme }) => [
    { color: theme ? theme.colors.primary.main : 'rgba(255, 255, 255, 1)' },
  ]
);

export const StyledGrid = styled(Box)({
  display: 'grid',
  gridTemplateColumns: 'repeat(auto-fit, minmax(155px, 1fr))',
  gap: 8,
  width: '100%',
  justifyItems: 'stretch',
  justifyContent: 'flex-end',
});

interface WeightItemsProps {
  cartWeights?: PriceId[];
  editingMode: boolean;
  menuProduct: MenuProduct;
  onChange: (priceId: PriceId) => void;
  selectedWeight: PriceId | null;
  weights: WeightSelectorWeight[];
}

const WeightItems = ({
  weights,
  selectedWeight,
  onChange,
  menuProduct,
  cartWeights,
}: WeightItemsProps) => {
  const menuProductWeightValues = weights.map((weight) => weight.value);

  return (
    <StyledGrid>
      {menuProductWeightValues.map((weight) => {
        return (
          <Flex
            position="relative"
            key={weight}
            width="100%"
            justifyContent="flex-end"
          >
            <VariantSelectionButton
              key={`${menuProduct.id}-${weight}`}
              onChange={() => onChange(weight)}
              selected={weight === selectedWeight}
              weight={weight}
            />
            {cartWeights?.includes(weight) && (
              <CheckIconWrapper>
                <SvgIcon size={18} icon="circleCheckFilled" />
              </CheckIconWrapper>
            )}
          </Flex>
        );
      })}
    </StyledGrid>
  );
};

export default WeightItems;
