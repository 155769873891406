/**
 * DO NOT MODIFY THIS FILE!
 * It was created by the reefer-icons generator.
 * Please update & re-run the generator if need be.
 */
import type { SVGProps } from 'react';

import type { DynamicIconProps } from '../../../icon/icon.types';
import { Icon } from '../../icon';

const Extract24 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="100%"
    height="100%"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <g className="Extract 24">
      <g className="SVG">
        <path
          fill="#FFC220"
          d="M2 10C2 4.477 6.477 0 12 0s10 4.477 10 10v4a2 2 0 1 1-4 0 2 2 0 1 0-4 0v8a2 2 0 1 1-4 0v-8a2 2 0 1 0-4 0v4a2 2 0 0 1-4 0v-8Z"
          className="Main"
        />
        <path
          fill="#000"
          fillOpacity={0.5}
          fillRule="evenodd"
          d="M18 9a1 1 0 0 1-1-1 3 3 0 0 0-3-3 1 1 0 1 1 0-2 5 5 0 0 1 5 5 1 1 0 0 1-1 1Z"
          className="Details"
          clipRule="evenodd"
        />
      </g>
    </g>
  </svg>
);

const SIZE_MAP = {
  md: Extract24,
  sm: Extract24,
  lg: Extract24,
  xl: Extract24,
  xxl: Extract24,
  xxxl: Extract24,
};

export const ExtractIcon = ({
  size = 'md',
  'data-testid': testId,
  ...props
}: DynamicIconProps) => {
  return (
    <Icon
      data-testid={testId || 'extract-icon'}
      icon={SIZE_MAP[size]}
      {...props}
      size={size}
    />
  );
};
