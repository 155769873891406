/**
 * DO NOT MODIFY THIS FILE!
 * It was created by the reefer-icons generator.
 * Please update & re-run the generator if need be.
 */
import type { SVGProps } from 'react';

import type { DynamicIconProps } from '../../../icon/icon.types';
import { Icon } from '../../icon';

const Percentage24 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="100%"
    height="100%"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <g className="Percentage 24">
      <path
        fill="#0E0E0E"
        fillRule="evenodd"
        d="M10 8a2 2 0 1 1-4 0 2 2 0 0 1 4 0Zm6.71-.71a.991.991 0 0 1 0 1.402L8.692 16.71a.991.991 0 1 1-1.402-1.402l8.018-8.018a.991.991 0 0 1 1.402 0ZM16 18a2 2 0 1 0 0-4 2 2 0 0 0 0 4Z"
        className="Union"
        clipRule="evenodd"
      />
      <circle
        cx={12}
        cy={12}
        r={11}
        stroke="#0E0E0E"
        strokeWidth={2}
        className="Ellipse 731"
      />
    </g>
  </svg>
);

const SIZE_MAP = {
  md: Percentage24,
  sm: Percentage24,
  lg: Percentage24,
  xl: Percentage24,
  xxl: Percentage24,
  xxxl: Percentage24,
};

export const PercentageIcon = ({
  size = 'md',
  'data-testid': testId,
  ...props
}: DynamicIconProps) => {
  return (
    <Icon
      data-testid={testId || 'percentage-icon'}
      icon={SIZE_MAP[size]}
      {...props}
      size={size}
    />
  );
};
