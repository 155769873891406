import { config } from '@jane/shared/config';

import { request } from '../lib/request';

export class ReviewsSource {
  static urlRoot = `${config.apiPath}/stores`;

  static get(params: Record<string, string | number | null>) {
    const { id, ...queryParams } = params;

    return request(
      `${this.urlRoot}/${id}/reviews`,
      {
        method: 'GET',
      },
      queryParams
    );
  }
}
