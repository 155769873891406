/**
 * DO NOT MODIFY THIS FILE!
 * It was created by the reefer-icons generator.
 * Please update & re-run the generator if need be.
 */
import type { SVGProps } from 'react';

import type { DynamicIconProps } from '../../../icon/icon.types';
import { Icon } from '../../icon';

const Dismiss16 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="100%"
    height="100%"
    fill="none"
    viewBox="0 0 16 16"
    {...props}
  >
    <g className="Dismiss 16">
      <path
        fill="#0E0E0E"
        d="M12.293 13.707a1 1 0 0 0 1.414-1.414L9.414 8l4.293-4.293a1 1 0 0 0-1.414-1.414L8 6.586 3.707 2.293a1 1 0 0 0-1.414 1.414L6.586 8l-4.293 4.293a1 1 0 1 0 1.414 1.414L8 9.414l4.293 4.293Z"
        className="SVG"
      />
    </g>
  </svg>
);

const Dismiss24 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="100%"
    height="100%"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <g className="Dismiss 24">
      <path
        fill="#0E0E0E"
        fillRule="evenodd"
        d="M19.71 5.692a.991.991 0 1 0-1.402-1.402L12 10.598 5.692 4.29A.991.991 0 0 0 4.29 5.692L10.598 12 4.29 18.308a.991.991 0 0 0 1.402 1.402L12 13.402l6.308 6.308a.991.991 0 1 0 1.402-1.402L13.402 12l6.308-6.308Z"
        className="SVG"
        clipRule="evenodd"
      />
    </g>
  </svg>
);

const Dismiss32 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="100%"
    height="100%"
    fill="none"
    viewBox="0 0 32 32"
    {...props}
  >
    <g className="Dismiss 32">
      <path
        fill="#0E0E0E"
        fillRule="evenodd"
        d="M27.564 6.538a1.487 1.487 0 1 0-2.102-2.103L16 13.897 6.538 4.435a1.487 1.487 0 1 0-2.102 2.103L13.897 16l-9.462 9.462a1.487 1.487 0 1 0 2.103 2.102L16 18.103l9.462 9.461a1.487 1.487 0 1 0 2.103-2.102L18.103 16l9.461-9.462Z"
        className="SVG"
        clipRule="evenodd"
      />
    </g>
  </svg>
);

const SIZE_MAP = {
  sm: Dismiss16,
  md: Dismiss24,
  lg: Dismiss32,
  xl: Dismiss32,
  xxl: Dismiss32,
  xxxl: Dismiss32,
};

export const DismissIcon = ({
  size = 'md',
  'data-testid': testId,
  ...props
}: DynamicIconProps) => {
  return (
    <Icon
      data-testid={testId || 'dismiss-icon'}
      icon={SIZE_MAP[size]}
      {...props}
      size={size}
    />
  );
};
