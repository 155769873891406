import { getJwt } from '@jane/shared/auth';

import { janeApi } from '../api';

interface SendCodeApiResponse {
  error?: string;
  exception?: string;
  status?: number;
}

interface SendCodeResponse {
  error?: string;
  success?: boolean;
}

interface VerifyCodeApiResponse {
  error?: string;
  exception?: string;
  status: number;
  success: boolean;
}

const sendCodeUrl = '/users/multifactor_codes';
const verifyCodeUrl = '/users/multifactor_codes/verify';

export const sendMultifactorCode = async (
  newPhone?: string
): Promise<SendCodeResponse> => {
  const response = await janeApi.post<SendCodeApiResponse>(
    sendCodeUrl,
    newPhone ? { new_phone: newPhone } : {},
    getJwt(),
    {},
    true
  );

  return { success: response.status === 201, error: response.error };
};

export const verifyMultifactorCode = async (code: string) =>
  await janeApi.post<VerifyCodeApiResponse>(verifyCodeUrl, { code }, getJwt());
