import styled from '@emotion/styled';

import { FLAVOR_ICONS } from '@jane/search/util';
import { Box, Flex, Typography } from '@jane/shared/reefer';
import type { FlavorEnum } from '@jane/shared/types';

export interface FlavorCardProps {
  flavor: FlavorEnum;
  width?: string;
}

const FlavorContainer = styled(Box)<{ flexBasis?: string }>(
  ({ flexBasis }) => ({ flexBasis })
);

export const FlavorCard = ({ flavor, width }: FlavorCardProps) => {
  const Icon = FLAVOR_ICONS[flavor];

  return (
    <FlavorContainer
      background="grays-ultralight"
      borderRadius="lg"
      p={12}
      minWidth="fit-content"
      flexBasis={width}
    >
      <Flex flexDirection="column" alignItems="center" justifyContent="center">
        <Icon size="md" />
        <Typography color="grays-black">
          {flavor.charAt(0).toUpperCase() + flavor.slice(1)}
        </Typography>
      </Flex>
    </FlavorContainer>
  );
};
