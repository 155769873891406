import styled from '@emotion/styled';

import { Card } from '@jane/shared/reefer';
import { mediaQueries, spacing } from '@jane/shared/reefer-emotion';

export const StyledCardGroup = styled(Card.Group)<{
  myHighMenu: boolean;
}>(({ myHighMenu, theme }) => ({
  ...(myHighMenu && {
    '&:hover': { borderColor: theme.colors.transparent.black.twenty },
  }),
}));

export const StyledCardContent = styled(Card.Content)({
  ...spacing({ p: 0 }),
});

export const StyledActionCardContent = styled(Card.Content)({
  ...spacing({ px: 24, pb: 24, pt: 0 }),
  height: 'auto',
  [mediaQueries.tablet('min')]: {
    ...spacing({ p: 24 }),
  },
});
