import * as t from 'io-ts';

export const tReservationMode = t.union([
  t.literal('delivery'),
  t.literal('pickup'),
  t.literal('kiosk'),
  t.literal('curbside'),
]);

export type ReservationMode = t.TypeOf<typeof tReservationMode>;

export const tReservationModeV2 = t.union([
  t.literal('delivery'),
  t.literal('pickup'),
  t.literal('retail'),
  t.literal('curbside'),
]);

export type ReservationModeV2 = t.TypeOf<typeof tReservationModeV2>;
