/**
 * DO NOT MODIFY THIS FILE!
 * It was created by the reefer-icons generator.
 * Please update & re-run the generator if need be.
 */
import type { SVGProps } from 'react';

import type { StaticIconProps } from '../../../icon/icon.types';
import { Icon } from '../../icon';

const Google24 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="100%"
    height="100%"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <g className="Google 24">
      <g className="Icon">
        <path
          fill="#4285F4"
          d="M12.204 10.182v3.873h5.492c-.241 1.245-.965 2.3-2.05 3.009l3.311 2.518c1.93-1.745 3.043-4.31 3.043-7.355 0-.709-.065-1.39-.186-2.045h-9.61Z"
          className="Vector"
        />
        <path
          fill="#34A853"
          d="m6.485 13.903-.747.56-2.644 2.019A10.225 10.225 0 0 0 12.204 22c2.755 0 5.064-.89 6.753-2.418l-3.312-2.518c-.909.6-2.069.963-3.441.963-2.653 0-4.907-1.754-5.715-4.118l-.004-.006Z"
          className="Vector"
        />
        <path
          fill="#FBBC05"
          d="M3.095 7.518a9.717 9.717 0 0 0 0 8.964c0 .009 3.395-2.582 3.395-2.582a5.884 5.884 0 0 1-.325-1.9c0-.664.12-1.3.325-1.9L3.095 7.518Z"
          className="Vector"
        />
        <path
          fill="#EA4335"
          d="M12.204 5.982c1.503 0 2.838.509 3.905 1.49l2.922-2.863C17.26 2.991 14.96 2 12.204 2a10.21 10.21 0 0 0-9.11 5.518L6.49 10.1c.807-2.364 3.06-4.118 5.714-4.118Z"
          className="Vector"
        />
      </g>
    </g>
  </svg>
);

const SIZE_MAP = {
  md: Google24,
  sm: Google24,
  lg: Google24,
  xl: Google24,
  xxl: Google24,
  xxxl: Google24,
};

export const GoogleIcon = ({
  size = 'md',
  'data-testid': testId,
  ...props
}: StaticIconProps) => {
  return (
    <Icon
      data-testid={testId || 'google-icon'}
      icon={SIZE_MAP[size]}
      {...props}
      size={size}
      color={undefined}
    />
  );
};
