import clsx from 'clsx';
import { useContext } from 'react';

import type { ColorKey } from '../../../theme';
import { paddingCSSVars } from '../../../utils';
import type { Spacing } from '../../../utils';
import { Box } from '../../box';
import { Flex } from '../../flex/flex';
import { ChevronLeftIcon, DismissIcon } from '../../icon';
import { Typography } from '../../typography/typography';
import { ModalContext } from '../modal';
import type { ModalHeaderProps } from '../modal.types';
import styles from './modalHeader.module.css';

export function ModalHeader({
  actions,
  branded = true,
  centerContent,
  children,
  className,
  onIconClick,
  id,
  nested,
  style,
  subtitle,
  'data-testid': testId,
  textAlign = 'left',
  title,
}: ModalHeaderProps) {
  const { onRequestClose, variant } = useContext(ModalContext);

  const iconProps = {
    color: 'inherit' as ColorKey,
    mr: textAlign === 'left' ? (24 as Spacing) : undefined,
    onClick: onIconClick ? onIconClick : onRequestClose,
  };

  return (
    <div
      className={clsx(
        className,
        [styles.modalHeader],
        styles[`modalHeader__${variant}`]
      )}
      id={id}
      style={{
        ...paddingCSSVars({
          px: 24,
          py: 16,
        }),
        ...style,
      }}
      data-id="modal-header"
      data-testid={testId}
    >
      <Flex width="100%" alignItems="center">
        <Box zIndex={1} position="relative">
          {nested ? (
            <ChevronLeftIcon
              altText="Back to previous modal"
              data-testid="modal-header-back-icon"
              {...iconProps}
            />
          ) : (
            <DismissIcon
              altText="Close Modal"
              data-testid="modal-header-close-icon"
              {...iconProps}
            />
          )}
        </Box>
        <Flex
          flexDirection="column"
          alignSelf="center"
          mr={24}
          grow
          basis={'0'}
        >
          <Typography
            branded={branded}
            variant="header-bold"
            textAlign={textAlign}
          >
            {title}
          </Typography>
          {subtitle && (
            <Typography color="text-light" textAlign={textAlign}>
              {subtitle}
            </Typography>
          )}
        </Flex>
        {centerContent && textAlign !== 'center' && (
          <Box position="absolute" left={0} width="100%">
            <Flex
              alignSelf="center"
              justifyContent="center"
              pr={24}
              px={24}
              width="100%"
            >
              {centerContent}
            </Flex>
          </Box>
        )}
        {actions && (
          <Flex>
            <Flex alignSelf="center" width="100%">
              {actions}
            </Flex>
          </Flex>
        )}
      </Flex>
      {children && <Flex width="100%">{children}</Flex>}
    </div>
  );
}
