import clsx from 'clsx';

import { getColorCSSVar } from '../../../utils';
import type { ModalContentDividerProps } from '../modal.types';
import styles from './modalContent.module.css';

export function ModalContentDivider({
  color = 'grays-light',
  className,
  'data-testid': testId,
  height = 1,
  id,
  padding = true,
}: ModalContentDividerProps) {
  return (
    <hr
      className={clsx(className, styles['content-divider'], {
        [styles['divider-padding']]: padding,
      })}
      data-testid={testId}
      id={id}
      style={{
        backgroundColor: getColorCSSVar(color),
        height,
      }}
    ></hr>
  );
}
