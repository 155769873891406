import pluralise from 'pluralise';
import { useEffect, useState } from 'react';

import { EventNames, track } from '@jane/shared-ecomm/tracking';
import type {
  AppMode,
  BundlePossibilities,
  Id,
  Product,
  Store,
  StoreSpecial,
} from '@jane/shared/models';
import { Link } from '@jane/shared/reefer';
import { bundleSpecialComplianceWord, trackError } from '@jane/shared/util';

import { useCustomerDispatch } from '../customer/dispatch';
import {
  applyDiscountableProductsFilter,
  clearTouchedProduct,
} from '../customer/redux/bundlePossibilities';
import { paths } from '../lib/routes';
import { formatDiscountAmount } from '../lib/storeSpecial';
import { ProductsSource } from '../sources/products';

interface Props {
  appMode: AppMode;
  bundlePossibilities: BundlePossibilities;
  special: StoreSpecial;
  store: Store;
}

const useGetProducts = (product_ids: Id[]) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [productNames, setProductNames] = useState<string[]>([]);

  useEffect(() => {
    setLoading(true);

    const params = {
      product_ids,
    };

    ProductsSource.getProducts(params).then((res: { products: Product[] }) => {
      setLoading(false);
      setProductNames(res.products.map((p) => p.name));
    });
  }, [product_ids]);

  return { productNames, loading };
};

export const bundleDiscountAmountText = (special: StoreSpecial) => {
  const {
    discount_dollar_amount,
    discount_type,
    discount_percent,
    discount_target_price,
  } = special;
  const discountAmount = formatDiscountAmount({
    discount_dollar_amount,
    discount_type,
    discount_percent,
    discount_target_price,
  });

  return discount_type === 'target_price'
    ? `${discountAmount}!`
    : `${discountAmount} off!`;
};

interface QualifiedProductProps {
  count: number;
  discountAmount: string;
  onFilter: () => void;
  productIds: number[];
  special: StoreSpecial;
  store: Store;
}

const QualifiedProductBundleContent = ({
  count,
  discountAmount,
  onFilter,
  productIds,
  special,
  store,
}: QualifiedProductProps) => {
  const { loading, productNames } = useGetProducts(productIds);

  if (loading) return null;

  return (
    <>
      <span>
        You&apos;ve unlocked the {bundleSpecialComplianceWord(store)}.
      </span>
      <span>
        {`Get ${count} `}
        {productNames.map((productName, index) => (
          <span key={productName}>
            <Link
              color="grays-white"
              to={paths.special(special, store)}
              onClick={onFilter}
            >
              {productName}
            </Link>
            {index < productNames.length - 1 && <span> or </span>}
          </span>
        ))}
        {` at ${discountAmount}`}
      </span>
    </>
  );
};

const QualifiedBundleContent = ({
  appMode,
  special,
  store,
}: Omit<Props, 'bundlePossibilities'>) => {
  const dispatch = useCustomerDispatch();
  const { conditions } = special;

  const dependentConditions = conditions.bundle?.dependent;

  if (!dependentConditions) {
    trackError(new Error('Cannot build bundle special message'), {
      specialId: special.id,
    });

    return (
      <span>
        You&apos;ve unlocked the {bundleSpecialComplianceWord(store)}.
      </span>
    );
  }

  const discountAmount = bundleDiscountAmountText(special);
  const count = dependentConditions.max_number_of_discounted_products as number;

  const onFilter = () => {
    dispatch(applyDiscountableProductsFilter());
    dispatch(clearTouchedProduct());
    track({
      event: EventNames.ClickedBundleEnticementFilter,
      specialId: special.id,
      storeId: store.id,
    });
  };

  if (
    dependentConditions.included_product_ids &&
    dependentConditions.included_product_ids?.length > 0 &&
    dependentConditions.included_product_ids?.length < 4
  ) {
    return (
      <QualifiedProductBundleContent
        count={count}
        discountAmount={discountAmount}
        onFilter={onFilter}
        productIds={dependentConditions.included_product_ids}
        special={special}
        store={store}
      />
    );
  }

  return (
    <>
      <span>
        You&apos;ve unlocked the {bundleSpecialComplianceWord(store)}.
      </span>
      <span>
        {`Get ${count} `}
        <Link
          color="grays-white"
          to={paths.special(special, store)}
          onClick={onFilter}
        >
          eligible {pluralise(count, 'product')}
        </Link>
        {` at ${discountAmount}`}
      </span>
    </>
  );
};

export const BundleSpecialEnticementContent = ({
  appMode,
  bundlePossibilities,
  special,
  store,
}: Props) => {
  useEffect(() => {
    const event =
      bundlePossibilities.status === 'qualified'
        ? EventNames.ViewedClickableBundleEnticement
        : EventNames.ViewedBundleEnticement;

    track({
      event,
      specialId: special.id,
      storeId: store.id,
    });
  }, []);

  if (bundlePossibilities.status === 'applied') {
    return (
      <span>
        {`Congrats! The ${bundleSpecialComplianceWord(store)} "${
          special.title
        }" has been applied to
        your bag`}
      </span>
    );
  }

  if (bundlePossibilities.status === 'qualified') {
    return (
      <QualifiedBundleContent
        appMode={appMode}
        special={special}
        store={store}
      />
    );
  }

  if (bundlePossibilities.status === 'partially_qualified') {
    return (
      <span>{`You're on your way to a ${bundleSpecialComplianceWord(store)}: ${
        special.title
      }`}</span>
    );
  }

  return null;
};
