import styled from '@emotion/styled';

import type { ButtonToggleButtonProps } from '@jane/shared/reefer';
import { Box, ButtonToggle } from '@jane/shared/reefer';
import { mediaQueries, spacing } from '@jane/shared/reefer-emotion';

type StyledButtonProps = {
  bloomUserExperience?: boolean;
} & ButtonToggleButtonProps;

export const StyledButtonToggle = styled(Box)({
  ...spacing({ px: 16, mx: 16 }),
  [mediaQueries.tablet('min')]: {
    ...spacing({ px: 40, mx: 40 }),
  },
});

const BloomStyleToggleButton = styled(ButtonToggle.Button)({
  height: '60px',
  [mediaQueries.tablet('min')]: {
    height: '68px',
  },
});

export const StyledButtonToggleButton = ({
  bloomUserExperience,
  ...rest
}: StyledButtonProps) =>
  bloomUserExperience ? (
    <BloomStyleToggleButton {...rest} />
  ) : (
    <ButtonToggle.Button {...rest} />
  );
