import styled from '@emotion/styled';

import { Box } from '@jane/shared/reefer';
import { mediaQueries, spacing } from '@jane/shared/reefer-emotion';

export const CartAlertsWrapper = styled(Box)(({ theme }) => ({
  backgroundColor: theme.colors.grays.white,
  '> :not(:first-of-type)': {
    ...spacing({ mt: 8 }),
  },
  ...spacing({ px: 16, py: 8 }),
  [mediaQueries.tablet('min')]: {
    '> :not(:first-of-type)': {
      ...spacing({ mt: 16 }),
    },
    ...spacing({ px: 24, py: 16 }),
  },
}));
