/**
 * DO NOT MODIFY THIS FILE!
 * It was created by the reefer-icons generator.
 * Please update & re-run the generator if need be.
 */
import type { SVGProps } from 'react';

import type { StaticIconProps } from '../../../icon/icon.types';
import { Icon } from '../../icon';

const Veteran24 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="100%"
    height="100%"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <g className="Veteran 24">
      <path
        fill="#1F42F0"
        d="M4.586 19.586a2 2 0 1 0 2.828 2.828l-2.828-2.828Zm5-5-5 5 2.828 2.828 5-5-2.828-2.828Z"
        className="Fill-Secondary-Special"
      />
      <path
        fill="#F50"
        d="M8.536 11.536 7.12 12.95l1.415-1.414Zm7.07 0 1.375 1.453.02-.02.02-.02-1.414-1.413Zm.98 10.878a2 2 0 1 0 2.828-2.828l-2.828 2.828ZM9.95 10.121a3 3 0 0 1 0-4.242L7.12 3.05a7 7 0 0 0 0 9.9l2.83-2.83Zm0-4.242a3 3 0 0 1 4.242 0l2.829-2.829a7 7 0 0 0-9.9 0L9.95 5.88Zm4.242 0a3 3 0 0 1 0 4.242l2.829 2.829a7 7 0 0 0 0-9.9l-2.829 2.83Zm.04 4.203-2.606 2.465 2.748 2.906 2.607-2.464-2.749-2.907Zm-7.11 2.868 9.464 9.464 2.828-2.828L9.95 10.12l-2.83 2.83Z"
        className="Fill-Main-Special"
      />
    </g>
  </svg>
);

const SIZE_MAP = {
  md: Veteran24,
  sm: Veteran24,
  lg: Veteran24,
  xl: Veteran24,
  xxl: Veteran24,
  xxxl: Veteran24,
};

export const VeteranIcon = ({
  size = 'md',
  'data-testid': testId,
  ...props
}: StaticIconProps) => {
  return (
    <Icon
      data-testid={testId || 'veteran-icon'}
      icon={SIZE_MAP[size]}
      {...props}
      size={size}
      color={undefined}
    />
  );
};
