/**
 * DO NOT MODIFY THIS FILE!
 * It was created by the reefer-icons generator.
 * Please update & re-run the generator if need be.
 */
import type { SVGProps } from 'react';

import type { DynamicIconProps } from '../../../icon/icon.types';
import { Icon } from '../../icon';

const Info24 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="100%"
    height="100%"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <g className="Info 24" clipPath="url(#a)">
      <g className="SVG">
        <path
          fill="#0E0E0E"
          d="M14.131 7.6c-.112.672-.544 1.056-1.504 1.056s-1.312-.416-1.312-.976c0-.112.016-.256.08-.64.112-.672.544-1.056 1.504-1.056s1.312.416 1.312.976c0 .112-.016.256-.08.64Zm.912 8.752c-1.072 1.216-1.968 1.84-3.072 1.84-1.312 0-1.984-.752-1.984-1.92 0-.352.032-.624.16-1.28l.64-3.424H8.291l.304-1.824h4.864l-1.232 6.56.272.08 2.24-2.288 1.28 1.136-.976 1.12Z"
        />
        <path
          fill="#0E0E0E"
          fillRule="evenodd"
          d="M24 12c0 6.627-5.373 12-12 12S0 18.627 0 12 5.373 0 12 0s12 5.373 12 12Zm-2 0c0 5.523-4.477 10-10 10S2 17.523 2 12 6.477 2 12 2s10 4.477 10 10Z"
          clipRule="evenodd"
        />
      </g>
    </g>
    <defs>
      <clipPath>
        <path fill="#fff" d="M0 0h24v24H0z" />
      </clipPath>
    </defs>
  </svg>
);

const SIZE_MAP = {
  md: Info24,
  sm: Info24,
  lg: Info24,
  xl: Info24,
  xxl: Info24,
  xxxl: Info24,
};

export const InfoIcon = ({
  size = 'md',
  'data-testid': testId,
  ...props
}: DynamicIconProps) => {
  return (
    <Icon
      data-testid={testId || 'info-icon'}
      icon={SIZE_MAP[size]}
      {...props}
      size={size}
    />
  );
};
