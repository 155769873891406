import type { Hit } from '@jane/search/types';
import { EventNames, coreProductProperties } from '@jane/shared-ecomm/tracking';
import type { CardLocation } from '@jane/shared-ecomm/tracking';
import type { MenuProduct, Product, Store } from '@jane/shared/models';
import type { UserSegmentIds } from '@jane/shared/types';
import { trackError } from '@jane/shared/util';

interface TrackingProps {
  cardIndex?: number;
  carouselView?: boolean;
  columnPosition?: number | null;
  creativeIds?: number[];
  currentCycleIndex?: number;
  flightId?: number;
  indexName: string;
  listView: boolean;
  menuProduct:
    | Hit<(MenuProduct & { root_types: string[] }) | Product>
    | MenuProduct;
  menuRowName?: string;
  rowPosition?: number | null;
  userSegments?: UserSegmentIds;
}

export const buildClickTrackingEvent = ({
  store,
  menuProduct,
  carouselView,
  rowPosition,
  columnPosition,
  cardIndex,
  indexName,
  listView,
  flightId,
  creativeIds,
  menuRowName,
  currentCycleIndex,
  userSegments,
}: TrackingProps & { store: Store }) => {
  if ('objectID' in menuProduct && !menuProduct?.objectID && !menuProduct?.id) {
    trackError('MenuProduct missing id', {
      menuRowName,
      menuProduct,
    });
    return undefined;
  }

  return {
    objectIds: [
      'objectID' in menuProduct
        ? menuProduct?.objectID
        : menuProduct?.id?.toString(),
    ],
    storeId: store.id.toString() || undefined,
    storeCity: store.city || undefined,
    storeState: store.state || undefined,
    bestSelling:
      'root_types' in menuProduct
        ? menuProduct.root_types?.includes('best_selling')
        : false,
    ...coreProductProperties(menuProduct, userSegments),
    ...additionalTrackingProperties({
      carouselView,
      menuProduct,
      columnPosition,
      rowPosition,
      cardIndex,
      indexName,
      listView,
      flightId,
      creativeIds,
      menuRowName,
      currentCycleIndex,
    }),
  };
};

const additionalTrackingProperties = ({
  carouselView,
  menuProduct,
  columnPosition,
  rowPosition,
  cardIndex,
  indexName,
  listView,
  flightId,
  creativeIds,
  menuRowName,
  currentCycleIndex,
}: TrackingProps) => {
  const sharedProperties = {
    event:
      EventNames.ClickedMenuProductCard as EventNames.ClickedMenuProductCard,
    category: menuProduct.kind,
    flightId,
    creativeIds,
    menuRowName,
    currentCycleIndex,
  };

  return carouselView
    ? {
        cardIndex: undefined,
        columnPosition,
        rowPosition,
        indexName,
        cardLocation: 'grid carousel' as CardLocation,
        ...sharedProperties,
      }
    : {
        cardIndex,
        cardLocation: (listView ? 'list' : 'grid table') as CardLocation,
        rowPosition: undefined,
        columnPosition: undefined,
        indexName,
        ...sharedProperties,
      };
};
