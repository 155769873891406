import { useRef } from 'react';

import { getRuntimeConfig } from './runtime-config';
import type { RuntimeConfig } from './runtime-config';

export { getRuntimeConfig };

/**
 * DO NOT USE UNLESS SETTING __webpack_public_path__
 * ALL OTHER CASES SHOULD USE useRuntimeConfig or getRuntimeConfig
 */
export const getWebpackConfigUtils = () => {
  const config = getRuntimeConfig();
  return {
    shouldSetPublicPath: !!config.partnerHostedStoreId,
    isFramelessEmbedMode: config.isFramelessEmbedMode,
  };
};

export const useRuntimeConfig = (): RuntimeConfig => {
  const runtimeConfig = useRef<RuntimeConfig | null>(null);
  if (runtimeConfig.current === null) {
    runtimeConfig.current = getRuntimeConfig();
  }
  return runtimeConfig.current;
};
