import type { SVGProps } from 'react';

import { Icon } from '../../icon/icon';
import type { StaticIconProps } from '../../icon/icon.types';

const Koala = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="64"
    height="64"
    viewBox="0 0 64 64"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <circle cx={32} cy={32} r={32} fill="#CE349A" />
    <circle cx={32} cy={32} r={32} fill="white" fillOpacity={0.8} />
    <ellipse
      rx={2}
      ry={4}
      transform="matrix(-0.866025 0.5 0.5 0.866025 49.7321 36.4641)"
      fill="#CE349A"
    />
    <ellipse
      cx={14.7321}
      cy={36.4641}
      rx={2}
      ry={4}
      transform="rotate(30 14.7321 36.4641)"
      fill="#CE349A"
    />
    <ellipse
      rx={2}
      ry={4}
      transform="matrix(-0.866025 0.5 0.5 0.866025 51.7321 34.4641)"
      fill="#CE349A"
    />
    <ellipse
      cx={12.7321}
      cy={34.4641}
      rx={2}
      ry={4}
      transform="rotate(30 12.7321 34.4641)"
      fill="#CE349A"
    />
    <ellipse
      rx={2}
      ry={4}
      transform="matrix(-0.866025 0.5 0.5 0.866025 53.7321 32.4641)"
      fill="#CE349A"
    />
    <ellipse
      cx={10.7321}
      cy={32.4641}
      rx={2}
      ry={4}
      transform="rotate(30 10.7321 32.4641)"
      fill="#CE349A"
    />
    <path
      d="M28.5772 20.0503C31.5632 23.0362 33.3306 26.621 33.809 29.9698C34.2874 33.3184 33.4763 36.3644 31.4056 38.435C29.3349 40.5057 26.2889 41.3168 22.9404 40.8385C19.5915 40.3601 16.0068 38.5926 13.0208 35.6066C10.0348 32.6206 8.26735 29.0359 7.78894 25.687C7.31058 22.3385 8.12171 19.2925 10.1924 17.2218C12.2631 15.1511 15.3091 14.34 18.6576 14.8184C22.0065 15.2968 25.5912 17.0643 28.5772 20.0503Z"
      fill="#CE349A"
    />
    <path
      d="M28.5772 20.0503C31.5632 23.0362 33.3306 26.621 33.809 29.9698C34.2874 33.3184 33.4763 36.3644 31.4056 38.435C29.3349 40.5057 26.2889 41.3168 22.9404 40.8385C19.5915 40.3601 16.0068 38.5926 13.0208 35.6066C10.0348 32.6206 8.26735 29.0359 7.78894 25.687C7.31058 22.3385 8.12171 19.2925 10.1924 17.2218C12.2631 15.1511 15.3091 14.34 18.6576 14.8184C22.0065 15.2968 25.5912 17.0643 28.5772 20.0503Z"
      fill="black"
      fillOpacity={0.2}
    />
    <path
      d="M28.5772 20.0503C31.5632 23.0362 33.3306 26.621 33.809 29.9698C34.2874 33.3184 33.4763 36.3644 31.4056 38.435C29.3349 40.5057 26.2889 41.3168 22.9404 40.8385C19.5915 40.3601 16.0068 38.5926 13.0208 35.6066C10.0348 32.6206 8.26735 29.0359 7.78894 25.687C7.31058 22.3385 8.12171 19.2925 10.1924 17.2218C12.2631 15.1511 15.3091 14.34 18.6576 14.8184C22.0065 15.2968 25.5912 17.0643 28.5772 20.0503Z"
      stroke="#CE349A"
      strokeWidth={2}
    />
    <path
      d="M35.0207 20.0503C32.0347 23.0362 30.2673 26.621 29.7889 29.9698C29.3105 33.3184 30.1216 36.3644 32.1923 38.435C34.263 40.5057 37.309 41.3168 40.6575 40.8385C44.0064 40.3601 47.5911 38.5926 50.5771 35.6066C53.5631 32.6206 55.3305 29.0359 55.809 25.687C56.2873 22.3385 55.4762 19.2925 53.4055 17.2218C51.3348 15.1511 48.2888 14.34 44.9403 14.8184C41.5914 15.2968 38.0067 17.0643 35.0207 20.0503Z"
      fill="#CE349A"
    />
    <path
      d="M35.0207 20.0503C32.0347 23.0362 30.2673 26.621 29.7889 29.9698C29.3105 33.3184 30.1216 36.3644 32.1923 38.435C34.263 40.5057 37.309 41.3168 40.6575 40.8385C44.0064 40.3601 47.5911 38.5926 50.5771 35.6066C53.5631 32.6206 55.3305 29.0359 55.809 25.687C56.2873 22.3385 55.4762 19.2925 53.4055 17.2218C51.3348 15.1511 48.2888 14.34 44.9403 14.8184C41.5914 15.2968 38.0067 17.0643 35.0207 20.0503Z"
      fill="black"
      fillOpacity={0.2}
    />
    <path
      d="M35.0207 20.0503C32.0347 23.0362 30.2673 26.621 29.7889 29.9698C29.3105 33.3184 30.1216 36.3644 32.1923 38.435C34.263 40.5057 37.309 41.3168 40.6575 40.8385C44.0064 40.3601 47.5911 38.5926 50.5771 35.6066C53.5631 32.6206 55.3305 29.0359 55.809 25.687C56.2873 22.3385 55.4762 19.2925 53.4055 17.2218C51.3348 15.1511 48.2888 14.34 44.9403 14.8184C41.5914 15.2968 38.0067 17.0643 35.0207 20.0503Z"
      stroke="#CE349A"
      strokeWidth={2}
    />
    <path
      d="M50 37C50 46.9411 41.9411 55 32 55C22.0589 55 14 46.9411 14 37C14 19 22.0589 19 32 19C41.9411 19 50 19 50 37Z"
      fill="#CE349A"
    />
    <ellipse
      cx={18.732}
      cy={45.4641}
      rx={2}
      ry={4}
      transform="rotate(30 18.732 45.4641)"
      fill="#CE349A"
    />
    <ellipse
      rx={2}
      ry={4}
      transform="matrix(-0.866025 0.5 0.5 0.866025 45.7321 45.4641)"
      fill="#CE349A"
    />
    <ellipse
      cx={16.732}
      cy={43.4641}
      rx={2}
      ry={4}
      transform="rotate(30 16.732 43.4641)"
      fill="#CE349A"
    />
    <ellipse
      rx={2}
      ry={4}
      transform="matrix(-0.866025 0.5 0.5 0.866025 47.7321 43.4641)"
      fill="#CE349A"
    />
    <ellipse
      cx={14.7321}
      cy={41.4641}
      rx={2}
      ry={4}
      transform="rotate(30 14.7321 41.4641)"
      fill="#CE349A"
    />
    <ellipse
      rx={2}
      ry={4}
      transform="matrix(-0.866025 0.5 0.5 0.866025 49.7321 41.4641)"
      fill="#CE349A"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M23.5719 47.7416C23.7146 47.5052 24.022 47.4292 24.2584 47.5719C26.372 48.8476 29.1726 49.5 32 49.5C34.8274 49.5 37.6281 48.8476 39.7416 47.5719C39.9781 47.4292 40.2854 47.5052 40.4281 47.7416C40.5708 47.9781 40.4948 48.2854 40.2584 48.4281C37.9537 49.8191 34.9635 50.5 32 50.5C29.0365 50.5 26.0463 49.8191 23.7416 48.4281C23.5052 48.2854 23.4292 47.9781 23.5719 47.7416Z"
      fill="#0E0E0E"
    />
    <circle cx={22} cy={33} r={6} fill="white" />
    <circle cx={42} cy={33} r={6} fill="white" />
    <circle cx={22} cy={33} r={4} fill="#FFC220" />
    <circle cx={42} cy={33} r={4} fill="#FFC220" />
    <circle cx={22} cy={33} r={2} fill="#0E0E0E" />
    <circle cx={42} cy={33} r={2} fill="#0E0E0E" />
    <circle cx={23} cy={32} r={1} fill="white" />
    <circle cx={43} cy={32} r={1} fill="white" />
    <ellipse cx={32} cy={36} rx={6} ry={12} fill="#CE349A" />
    <ellipse cx={32} cy={36} rx={6} ry={12} fill="black" fillOpacity={0.2} />
  </svg>
);
export const KoalaAvatar = ({ size = 'xl', ...props }: StaticIconProps) => {
  return <Icon icon={Koala} {...props} size={size} color={undefined} />;
};
