/* eslint-disable @typescript-eslint/no-explicit-any */

/**
 * Simple object check.
 * @param item
 * @returns {boolean}
 */
const isObject = (item: unknown): item is object => {
  return (
    item !== undefined &&
    item !== null &&
    typeof item === 'object' &&
    !Array.isArray(item)
  );
};

/**
 * Deep merge two objects.
 * @param target
 * @param ...sources
 */
export const deepMerge = <T extends Record<string, any>, P = T>(
  target: T,
  ...sources: P[]
): T => {
  if (!sources.length) return target;
  const source = sources.shift();

  if (isObject(target) && isObject(source)) {
    for (const key in source) {
      if (isObject(source[key])) {
        if (!target[key]) Object.assign(target, { [key]: {} });
        deepMerge(target[key], source[key]);
      } else if (source[key] !== undefined) {
        Object.assign(target, { [key]: source[key] });
      }
    }
  }

  return deepMerge(target, ...sources);
};
