import {
  Box,
  Button,
  CheckIcon,
  DismissIcon,
  Form,
  Typography,
} from '@jane/shared/reefer';

import { StyledTextField, SubmittableTextContainer } from './promoCodes.styles';

interface Props {
  hasValidationError: boolean;
  name: string;
  onReset: () => void;
  onSubmit: (value: string) => void;
  placeholder: string;
  submitLabel: string;
  validated: boolean;
  validationMessage?: string;
}

export const SubmittableTextInput = ({
  onReset,
  onSubmit,
  placeholder,
  submitLabel,
  validated,
  hasValidationError,
  validationMessage,
  name,
}: Props) => {
  const handleSubmit = (values) => {
    onSubmit(values[name]);
  };

  return (
    <Form name="promocode-form" onSubmit={handleSubmit}>
      <SubmittableTextContainer alignItems="center" justifyContent="stretch">
        {!validated && (
          <StyledTextField
            disableMobileInputStyling
            labelHidden
            label="Promo code"
            name={name}
            aria-label={name}
            placeholder={placeholder}
          />
        )}
        {!validated && (
          <Box width={124} pl={24}>
            <Form.SubmitButton label={submitLabel} full />
          </Box>
        )}
        {validated && hasValidationError && (
          <Button.Icon
            icon={<DismissIcon />}
            onClick={onReset}
            type="button"
            variant="destructive"
            full
          />
        )}
        {validated && !hasValidationError && <CheckIcon color="success" />}
        {validated && (
          <Typography
            color={hasValidationError ? 'error' : 'success'}
            whiteSpace="nowrap"
            pl={24}
          >
            {validationMessage}
          </Typography>
        )}
      </SubmittableTextContainer>
    </Form>
  );
};
