import { requestWithoutErrorHandling } from '../lib/request';
import sourceUrlBuilder from '../lib/sourceUrlBuilder';

export const ResetPasswordRequestSource = {
  create: (app: 'customer' | 'business', email: string) => {
    const url = sourceUrlBuilder(app, 'reset_password_request');

    return requestWithoutErrorHandling(url, {
      body: JSON.stringify({ email }),
      method: 'POST',
    });
  },
};
