export const COLORS = {
  brand: {
    gold: '#FFC220',
    tangerine: '#FF9900',
    ember: '#FF5500',
    sunset: '#CE349A',
    grape: '#8E00E6',
    purple: '#5E1FF0',
  },

  system: {
    positive: '#94B200',
    negative: '#FF3333',
  },

  grays: {
    white: '#FFFFFF',
    ultralight: '#F7F7F7',
    light: '#DADADA',
    mid: '#666666',
    dark: '#262626',
    black: '#0E0E0E',
    hover: '#0000001a',
  },

  tertiary: {
    emerald: '#1D5A53',
    palm: '#4BA279',
    seafoam: '#63B8D2',
    pacific: '#4485DA',
  },

  transparent: {
    black: {
      five: 'rgba(0, 0, 0, 0.05)',
      ten: 'rgba(0, 0, 0, 0.10)',
      twenty: 'rgba(0, 0, 0, 0.20)',
      fifty: 'rgba(0, 0, 0, 0.50)',
    },
    white: {
      ten: 'rgba(255, 255, 255, 0.10)',
    },
  },
};
