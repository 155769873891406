import merge from 'lodash/merge';
import noop from 'lodash/noop';
import type { PropsWithChildren } from 'react';
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import type {
  SearchState as InstantSearchState,
  JaneSearchState,
} from '@jane/search/types';
import {
  deleteDefaultOrIncompleteCurrentSort,
  getLocationQueryWithSearchState,
  getSearchParams,
  isInstantSearchState,
  searchStateFromLocationQuery,
  searchStateToInstantSearchState,
} from '@jane/search/util';
import { parseSearch } from '@jane/shared/util';

interface SearchContext<T> {
  indexPrefix: string;
  instantSearchState: InstantSearchState;
  resetSearchState: (nextSearchState?: Partial<JaneSearchState<T>>) => void;
  searchState: JaneSearchState<T>;
  setSearchState: (searchState: Partial<JaneSearchState<T>>) => void;
}

const buildSearchState = <T,>(
  initialSearchParams: JaneSearchState<T> = {}
): JaneSearchState<T> => ({
  filters: {},
  bucketFilters: {},
  rangeFilters: {},
  searchText: '',
  page: 0,
  ...initialSearchParams,
});

const SearchContext = createContext<SearchContext<any>>({
  indexPrefix: '',
  instantSearchState: {},
  resetSearchState: noop,
  searchState: buildSearchState<any>(),
  setSearchState: noop,
});

export const useSearchContext = () => useContext(SearchContext);

export const SearchConsumer = SearchContext.Consumer;

type SearchProviderProps<T> = PropsWithChildren<{
  indexPrefix: string;
  initialSearchState?: Partial<JaneSearchState<T>>;
  noUrlChanges?: boolean;
  onSearchStateChange?: (nextSearchState: JaneSearchState<T>) => void;
}>;

export const SearchProvider = <T,>({
  indexPrefix,
  initialSearchState,
  children,
  onSearchStateChange,
  noUrlChanges,
}: SearchProviderProps<T>) => {
  const location = useLocation();
  const navigate = useNavigate();
  const shouldReplaceRef = useRef(false);

  const locationQueryState = useMemo(
    () => searchStateFromLocationQuery(location.search),
    [location.search]
  );

  const [searchState, setSearchState] = useState<JaneSearchState<T>>(
    buildSearchState(merge(initialSearchState, locationQueryState))
  );

  const mappingInstantSearchState = useMemo(
    () => isInstantSearchState(parseSearch(location.search)),
    [location.search]
  );

  const instantSearchState = useMemo(
    () => searchStateToInstantSearchState(searchState, indexPrefix),
    [searchState]
  );

  useEffect(() => {
    shouldReplaceRef.current = mappingInstantSearchState;
  }, [mappingInstantSearchState]);

  useEffect(() => {
    if (noUrlChanges) {
      return;
    }

    const encodedSearchState = getLocationQueryWithSearchState(
      location.search,
      searchState
    );
    if (location.search.replace('?', '') !== encodedSearchState) {
      navigate(
        {
          ...location,
          search: encodedSearchState,
        },
        { replace: shouldReplaceRef.current }
      );
    }
  }, [JSON.stringify(searchState)]);

  useEffect(() => {
    setSearchState((prevSearchState) => {
      const nextSearchState = getSearchParams(locationQueryState);

      if (!Object.keys(nextSearchState).length) {
        return nextSearchState;
      }
      const result = { ...prevSearchState, ...nextSearchState };
      deleteDefaultOrIncompleteCurrentSort(result);

      return result;
    });
  }, [location.search]);

  const setState = useCallback(
    (nextSearchState: Partial<JaneSearchState<T>>) => {
      setSearchState((prevSearchState) => {
        const searchState = {
          ...prevSearchState,
          ...nextSearchState,
        };

        onSearchStateChange?.(searchState);

        return searchState;
      });
    },
    [onSearchStateChange]
  );

  const resetSearchState = useCallback(
    (nextSearchState: Partial<JaneSearchState<T>> = {}) => {
      setSearchState(() => {
        onSearchStateChange?.(nextSearchState);

        return nextSearchState;
      });
    },
    [onSearchStateChange]
  );

  const contextValue = useMemo(
    () => ({
      indexPrefix,
      instantSearchState,
      resetSearchState,
      searchState,
      setSearchState: setState,
    }),
    [indexPrefix, instantSearchState, resetSearchState, searchState, setState]
  );

  return (
    <SearchContext.Provider value={contextValue}>
      {children}
    </SearchContext.Provider>
  );
};
