/* eslint-disable-next-line no-restricted-imports */
import { useLDClient, useLDClientError } from 'launchdarkly-react-client-sdk';

import { FLAGS } from './flags';
import { useFlag } from './useFlags';

const DEBUG_EVENT_NAME = 'LaunchDarkly Context Identified';

export const useFlagClient = () => {
  const client = useLDClient();
  const ldLogging = useFlag(FLAGS.ldLogging);

  if (!client) return;

  const originalIdentify = client.identify;

  if (ldLogging) {
    client.identify = (context, ...args) => {
      janeMixpanel?.track(DEBUG_EVENT_NAME, context);
      return originalIdentify && originalIdentify(context, ...args);
    };
  }
  return client;
};

export const useFlagClientError = useLDClientError;

export * from './eventNames';
export * from './flagContext';
export * from './flagProvider';
export * from './flags';
export { useFlag, useFlags } from './useFlags';
export type { FlagsType } from './useFlags';
