import hash from 'hash.js';

import { Storage } from '@jane/shared/util';

interface CacheArgs {
  phone: string | null;
  storeId: number | string;
}

const authCodeCacheKey = ({ phone, storeId }: CacheArgs) =>
  hash.sha256().update(`${phone}:${storeId}`).digest('hex');

export const getCachedAuthCode = (cacheArgs: CacheArgs) =>
  Storage.get(authCodeCacheKey(cacheArgs));

export const setCachedAuthCode = (cacheArgs: CacheArgs, authCode: string) =>
  Storage.set(authCodeCacheKey(cacheArgs), authCode);
export const removeCachedAuthCode = (cacheArgs: CacheArgs) =>
  Storage.remove(authCodeCacheKey(cacheArgs));
