import type { SVGProps } from 'react';

import { Icon } from '../../icon/icon';
import type { StaticIconProps } from '../../icon/icon.types';

const Skunk = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="64"
    height="64"
    viewBox="0 0 64 64"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <circle cx={32} cy={32} r={32} fill="#DADADA" />
    <circle cx={14} cy={23} r={8} fill="#262626" />
    <circle cx={14} cy={23} r={6} fill="#FF92E0" />
    <circle cx={50} cy={23} r={8} fill="#262626" />
    <circle cx={50} cy={23} r={6} fill="#FF92E0" />
    <circle cx={32} cy={33} r={22} fill="#262626" />
    <circle cx={22} cy={33} r={6} fill="white" />
    <circle cx={42} cy={33} r={6} fill="white" />
    <circle cx={29} cy={45} r={6} fill="white" />
    <circle cx={35} cy={45} r={6} fill="white" />
    <ellipse cx={32} cy={17} rx={4} ry={8} fill="white" />
    <circle cx={22} cy={33} r={4} fill="#FF92E0" />
    <circle cx={42} cy={33} r={4} fill="#FF92E0" />
    <circle cx={22} cy={33} r={2} fill="#0E0E0E" />
    <ellipse cx={32} cy={45} rx={3} ry={2} fill="#FF92E0" />
    <circle cx={42} cy={33} r={2} fill="#0E0E0E" />
    <circle cx={23} cy={32} r={1} fill="white" />
    <circle cx={43} cy={32} r={1} fill="white" />
  </svg>
);
export const SkunkAvatar = ({ size = 'xl', ...props }: StaticIconProps) => {
  return <Icon icon={Skunk} {...props} size={size} color={undefined} />;
};
