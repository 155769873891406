/* eslint-disable-next-line no-restricted-imports */
import type { ProviderConfig } from 'launchdarkly-react-client-sdk';

/* eslint-disable-next-line no-restricted-imports */
import {
  asyncWithLDProvider,
  withLDProvider,
} from 'launchdarkly-react-client-sdk';

import { DEFAULT_USER_CONTEXT } from './flagContext';

export const withFlagProvider = (config: ProviderConfig) => {
  config.context = DEFAULT_USER_CONTEXT;
  return withLDProvider(config);
};

export const asyncFlagProvider = async (
  clientSideID: string,
  context = DEFAULT_USER_CONTEXT
) => {
  return await asyncWithLDProvider({
    clientSideID,
    context,
    options: {
      /**
       * The capacity of the analytics events queue. (Default is 100)
       */
      eventCapacity: 500,

      /**
       * If set to true, this prevents the SDK from trying to use a synchronous HTTP request to deliver
       * analytics events if the page is being closed. Not all browsers allow such requests; the SDK
       * normally tries to avoid making them if not allowed, by using browser detection, but sometimes
       * browser detection may not work so if you are seeing errors like "synchronous XHR request
       * during page dismissal", you may want to set this option. Since currently the SDK does not have
       * a better way to deliver events in this scenario, some events may be lost.
       *
       * We have set this to true to prevent the app throwing Errors during Cypress tests.
       */
      disableSyncEventPost: true,

      /**
       * Whether analytics events should be sent only when you call variation (true), or also when you
       * call allFlags (false).
       *
       * By default, this is false (events will be sent in both cases).
       */
      sendEventsOnlyForVariation: true,
    },
  });
};
