import isArray from 'lodash/isArray';

import type {
  AlgoliaDispensary,
  AlgoliaFacet,
  AlgoliaProduct,
  JaneSearchState,
} from '@jane/search/types';

type PartialFacets = { [attribute: string]: AlgoliaFacet };

/**
 * When listing filters for menu products, we would like to show all possible ways that
 *  products can be filtered, but disable any filters that would result in 0 hits. This
 *  requires knowing what is actively being filtered (to allow joining with OR along the same
 *  facet), and two sets of facets:
 *    1. All facets with no additional filters applied
 *    2. Facets factoring in an active search term and previously-selected filters.
 * Note that this **modifies and returns** the `facetCounts` argument.
 */
export const updateFacetCounts = (
  searchState:
    | JaneSearchState<AlgoliaProduct>
    | JaneSearchState<AlgoliaDispensary>,
  facetCounts: PartialFacets,
  filteredFacetCounts: PartialFacets
): PartialFacets => {
  const activeFilters: {
    [attribute: string]:
      | string[]
      | number[]
      | {
          max?: number;
          min?: number;
        };
  } = {
    ...searchState?.filters,
    ...searchState?.bucketFilters,
    ...searchState?.rangeFilters,
  };

  for (const facetGroup in facetCounts) {
    const facetsToReplace = ['aggregate_rating'];
    const facetsToIgnore = ['has_brand_discount'];

    // return new counts only
    if (facetsToReplace.includes(facetGroup)) {
      facetCounts[facetGroup] = filteredFacetCounts[facetGroup];
    }

    if (
      !facetsToReplace.includes(facetGroup) &&
      !facetsToIgnore.includes(facetGroup)
    ) {
      // if we're not already filtering on this facet
      if (facetGroup in activeFilters === false) {
        // if algolia sends back new facet counts for group (ex. root_types, brand)

        if (facetGroup in filteredFacetCounts) {
          const updatedAttributes = filteredFacetCounts[facetGroup];
          const allAttributes = facetCounts[facetGroup];

          // if facet is returned, use updated count
          for (const filter in updatedAttributes) {
            if (filter in allAttributes) {
              allAttributes[filter] = updatedAttributes[filter];
            }
          }

          // if facet is not returned, set count to 0
          for (const filter in allAttributes) {
            if (filter in updatedAttributes === false) {
              allAttributes[filter] = 0;
            }
          }
        }

        // if algolia sends back 0 results for facet group, set all to 0
        if (facetGroup in filteredFacetCounts === false) {
          const allAttributes = facetCounts[facetGroup];

          for (const filter in allAttributes) {
            allAttributes[filter] = 0;
          }
        }
      }

      // if we are already filtering on this facet
      if (facetGroup in activeFilters) {
        const isFilterArray =
          isArray(activeFilters[facetGroup]) &&
          (activeFilters[facetGroup] as string[]);

        if (isFilterArray && isFilterArray.length === 0) {
          // if algolia sends back new facet counts for group (ex. root_types, brand)
          if (facetGroup in filteredFacetCounts) {
            const updatedAttributes = filteredFacetCounts[facetGroup];
            const allAttributes = facetCounts[facetGroup];

            // if facet is returned, use updated count
            for (const filter in updatedAttributes) {
              if (filter in allAttributes) {
                allAttributes[filter] = updatedAttributes[filter];
              }
            }

            // if facet is not returned, set count to 0
            for (const filter in allAttributes) {
              if (filter in updatedAttributes === false) {
                allAttributes[filter] = 0;
              }
            }
          }

          // if algolia sends back 0 results for facet group, set all to 0
          if (facetGroup in filteredFacetCounts === false) {
            const allAttributes = facetCounts[facetGroup];

            for (const filter in allAttributes) {
              allAttributes[filter] = 0;
            }
          }
        }
      }
    }
  }

  return facetCounts;
};
