import { config } from '@jane/shared/config';
import type {
  AppMode,
  Id,
  PendingCartProduct,
  PriceId,
  Store,
} from '@jane/shared/models';

import { request } from '../lib/request';

interface CartProductSourceAddProps {
  appMode: AppMode;
  appModeName: AppMode;
  cartProduct: PendingCartProduct;
  cart_uuid: string;
  janeDeviceId: string;
  store: Store;
  tags: unknown;
}

export class CartProductsSource {
  static buildUrl(url = '') {
    return `${config.apiPath}/cart_products${url}`;
  }

  static add({
    appMode,
    appModeName,
    cart_uuid,
    cartProduct,
    janeDeviceId,
    store,
    tags,
  }: CartProductSourceAddProps) {
    const data = {
      app_mode: appMode,
      app_mode_name: appModeName,
      cart_uuid,
      j_device_id: janeDeviceId,
      product: {
        count: cartProduct.count,
        price_id: cartProduct.price_id,
        product_id: cartProduct.id,
        recommended: !!cartProduct.recommended,
      },
      store_id: store.id,
      tags,
      user_agent: navigator.userAgent,
    };

    return request(this.buildUrl(), {
      body: JSON.stringify(data),
      method: 'POST',
    });
  }

  static update(
    id: number,
    count: number,
    price_id: PriceId,
    cart_uuid: string
  ) {
    const data = {
      cart_uuid,
      product: {
        count,
        price_id,
        product_id: id,
      },
    };

    return request(this.buildUrl(`/${id}`), {
      body: JSON.stringify(data),
      method: 'PATCH',
    });
  }

  static delete(id: Id, price_id: PriceId, cart_uuid: string) {
    const data = { cart_uuid, price_id };
    return request(this.buildUrl(`/${id}`), {
      body: JSON.stringify(data),
      method: 'DELETE',
    });
  }

  static deleteUnavailableProducts(cart_uuid: string) {
    return request(this.buildUrl('/unavailable'), {
      body: JSON.stringify({ cart_uuid }),
      method: 'DELETE',
    });
  }
}
