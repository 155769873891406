import styled from '@emotion/styled';
import type { PropsWithChildren } from 'react';

import { Box, Flex, Typography } from '@jane/shared/reefer';
import type { ColorKey } from '@jane/shared/reefer';

export const Badge = styled(Box)({
  borderRadius: 80,
  padding: 32,
  textAlign: 'center',
  backdropFilter: 'blur(16px)',
  width: 'fit-content',
});

export type JumboDiscountBadgeProps = PropsWithChildren<{
  color: ColorKey;
  textColor: ColorKey;
}>;

export const JumboDiscountBadge = ({
  children,
  color = 'gold',
  textColor = 'grays-black',
}: JumboDiscountBadgeProps) => (
  <Badge background={color}>
    <Flex height="100%" justifyContent="center" alignItems="center">
      <Typography color={textColor} variant="header-bold" branded>
        {children}
      </Typography>
    </Flex>
  </Badge>
);
