/**
 * DO NOT MODIFY THIS FILE!
 * It was created by the reefer-icons generator.
 * Please update & re-run the generator if need be.
 */
import type { SVGProps } from 'react';

import type { StaticIconProps } from '../../../icon/icon.types';
import { Icon } from '../../icon';

const Leaf24 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="100%"
    height="100%"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <g className="Leaf 24">
      <path
        fill="#1D5A53"
        fillRule="evenodd"
        d="M23.499 1.558C19.297.625 10.933.43 5.214 6.15c-4.74 4.74-3.172 9.054-1.034 11.853-.292.13-.592.249-.901.357-.703.257-1.486.512-2.35.765-.306.09-.53.233-.67.43a1.201 1.201 0 0 0-.22.67c-.01.488.168.905.538 1.25.368.352.833.535 1.394.547.425.01.913-.111 1.463-.364a10.23 10.23 0 0 0 1.681-.96c.322-.22.63-.443.927-.67 2.697 2.473 7.509 5.174 12.866-.184 5.72-5.719 5.525-14.083 4.59-18.285Z"
        className="Fill-Main"
        clipRule="evenodd"
      />
      <path
        fill="#4BA279"
        d="M19.22 7.55a1.21 1.21 0 1 1-1.712-1.712 1.21 1.21 0 0 1 1.711 1.712Zm-5.992 11.126a1.21 1.21 0 1 1-1.712-1.712 1.21 1.21 0 0 1 1.712 1.712ZM8.093 13.54a1.21 1.21 0 1 1-1.712-1.71 1.21 1.21 0 0 1 1.712 1.71Zm9.415.857a1.21 1.21 0 1 1-1.712-1.712 1.21 1.21 0 0 1 1.712 1.712Zm-5.136-5.136a1.21 1.21 0 1 1-1.711-1.711 1.21 1.21 0 0 1 1.711 1.711Z"
        className="Fill-Secondary"
      />
    </g>
  </svg>
);

const SIZE_MAP = {
  md: Leaf24,
  sm: Leaf24,
  lg: Leaf24,
  xl: Leaf24,
  xxl: Leaf24,
  xxxl: Leaf24,
};

export const LeafIcon = ({
  size = 'md',
  'data-testid': testId,
  ...props
}: StaticIconProps) => {
  return (
    <Icon
      data-testid={testId || 'leaf-icon'}
      icon={SIZE_MAP[size]}
      {...props}
      size={size}
      color={undefined}
    />
  );
};
