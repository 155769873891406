// eslint-disable-next-line no-restricted-imports
import { tz } from 'moment-timezone';

import { timezoneData } from './data/moment-timezone-us';

export function loadTimezoneData() {
  tz.load(timezoneData);

  tz.link([
    'Hawaii|Pacific/Honolulu',
    'Alaska|America/Juneau',
    'Pacific Time (US & Canada)|America/Los_Angeles',
    'Arizona|America/Phoenix',
    'Mountain Time (US & Canada)|America/Denver',
    'Central Time (US & Canada)|America/Chicago',
    'Eastern Time (US & Canada)|America/New_York',
    'Indiana (East)|America/Indiana/Indianapolis',
    'Puerto Rico|America/Puerto_Rico',
  ]);
}

export function setDefaultTimezone(timezone: string) {
  tz.setDefault(timezone);
}
