import { useNavigate } from 'react-router-dom';

import { useEcommApp } from '@jane/shared/providers';
import {
  Button,
  Drawer,
  Flex,
  Typography,
  useDrawerContext,
} from '@jane/shared/reefer';

import { paths } from '../../../../lib/routes';
import EmptyBag from '../assets/EmptyBag.svg?react';
import { CartDrawerStatesWrapper } from './cartDrawer.styles';

export const CartDrawerEmpty = () => {
  const navigate = useNavigate();
  const { appMode } = useEcommApp();
  const { onRequestClose } = useDrawerContext();

  const handleClick = () => {
    onRequestClose();
    if (appMode === 'default') {
      return navigate(paths.products());
    }
    if (appMode === 'brandEmbed') {
      return navigate(paths.brandEmbedProducts());
    }

    return navigate(paths.embeddedMenu());
  };

  return (
    <>
      <Drawer.Header title="Bag" />
      <Drawer.Content noPadding>
        <CartDrawerStatesWrapper p={40}>
          <Flex
            flexDirection="column"
            alignItems="center"
            width="100%"
            my="auto"
          >
            <EmptyBag />
            <Typography mt={24} color="grays-mid" textAlign="center">
              Your bag is empty. Tap to explore all local cannabis products!
            </Typography>
            <Button
              mt={24}
              full
              onClick={handleClick}
              label="Shop all products"
            />
          </Flex>
        </CartDrawerStatesWrapper>
      </Drawer.Content>
    </>
  );
};
