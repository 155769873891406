import type { Reducer } from 'redux';

import type { DeepReadonly } from '@jane/shared/models';

import { createStandardAction } from '../../redux-util';
import type { Options } from '../../types/headlessCart';
import type { CustomerAction } from './types';

export const SET_EXTERNAL_ID = 'headless-app/set-external-id';
export const setExternalId = createStandardAction(SET_EXTERNAL_ID)<
  string | undefined
>();

export const SET_HEADLESS_PARTNER_NAME =
  'headless-app/set-headless-partner-name';
export const setHeadlessPartnerName = createStandardAction(
  SET_HEADLESS_PARTNER_NAME
)<string | undefined>();

export const SET_HEADLESS_CHECKOUT_PARTNER_ID =
  'headless-app/set-headless-checkout-partner-id';
export const setHeadlessCheckoutPartnerId = createStandardAction(
  SET_HEADLESS_CHECKOUT_PARTNER_ID
)<string | undefined>();

export const SET_HEADLESS_STORE_ID = 'headless-app/set-headless-store-id';
export const setHeadlessStoreId = createStandardAction(SET_HEADLESS_STORE_ID)<
  string | undefined
>();

export const SET_HEADLESS_OPTIONS = 'headless-app/set-app-options';
export const setHeadlessOptions =
  createStandardAction(SET_HEADLESS_OPTIONS)<Options>();

export type HeadlessAppActions =
  | ReturnType<typeof setExternalId>
  | ReturnType<typeof setHeadlessPartnerName>
  | ReturnType<typeof setHeadlessOptions>
  | ReturnType<typeof setHeadlessCheckoutPartnerId>
  | ReturnType<typeof setHeadlessStoreId>;

export type HeadlessAppState = DeepReadonly<{
  externalId: string | undefined;
  headlessCheckoutPartnerId: string | undefined;
  headlessPartnerName: string | undefined;
  headlessStoreId: string | undefined;
  options: Options | undefined;
}>;

const getInitialState = (): HeadlessAppState => ({
  externalId: undefined,
  headlessPartnerName: undefined,
  headlessCheckoutPartnerId: undefined,
  headlessStoreId: undefined,
  options: {},
});

export const headlessAppReducer: Reducer<HeadlessAppState, CustomerAction> = (
  state = getInitialState(),
  action
) => {
  switch (action.type) {
    case SET_EXTERNAL_ID:
      return { ...state, externalId: action.payload };
    case SET_HEADLESS_PARTNER_NAME:
      return { ...state, headlessPartnerName: action.payload };
    case SET_HEADLESS_CHECKOUT_PARTNER_ID:
      return { ...state, headlessCheckoutPartnerId: action.payload };
    case SET_HEADLESS_STORE_ID:
      return { ...state, headlessStoreId: action.payload };
    case SET_HEADLESS_OPTIONS:
      return { ...state, options: { ...state.options, ...action.payload } };
  }

  return state;
};
