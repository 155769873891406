import type { RefCallback } from 'react';
import { useCallback, useState } from 'react';

interface Response {
  gridRef: RefCallback<HTMLElement>;
  numColumns: number | null;
}

/**
 * Calculates the number of columns in a CSS grid at initial render (it currently does not update on window resize
 * because that was not desired at time of implementation).
 *
 * To use, pass `gridRef` to an element with `display: grid`, and then `numColumns` will get updated with the
 * number of columns rendered in that element.
 */
export const useGetNumColumnsInCssGrid = (): Response => {
  const [numColumns, setNumColumns] = useState<number | null>(null);
  const gridRef = useCallback((node: HTMLElement) => {
    const calculateNumColumns = () => {
      if (node !== null) {
        const cols = window
          .getComputedStyle(node)
          .getPropertyValue('grid-template-columns')
          .split(' ').length;
        setNumColumns(cols);
      }
    };
    calculateNumColumns();
  }, []);
  return { numColumns, gridRef };
};
