import debounce from 'lodash/debounce';
import isEqual from 'lodash/isEqual';
import { createContext, useContext, useEffect, useState } from 'react';
import type { PropsWithChildren } from 'react';
import {
  MediaQueryProvider as OuterMediaQueryProvider,
  withMedia,
} from 'react-media-query-hoc';

const mediaQueries = {
  desktop: '(min-width: 1024px)',
  verticalTablet: '(min-width: 481px) and (max-width: 767px)',
  largerThanMobile: '(min-width: 481px)',
  largerThanVerticalTablet: '(min-width: 768px)',
  smallerThanDesktop: '(max-width: 1023px)',
  smallerThanVerticalTablet: '(max-width: 767px)',
  mobile: '(max-width: 480px)',
};

export interface MediaQueryState {
  desktop: boolean;
  largerThanMobile: boolean;
  largerThanVerticalTablet: boolean;
  mobile: boolean;
  smallerThanDesktop: boolean;
  smallerThanVerticalTablet: boolean;
  verticalTablet: boolean;
}

const defaultState = () => ({
  desktop: false,
  verticalTablet: false,
  largerThanMobile: false,
  largerThanVerticalTablet: false,
  smallerThanDesktop: false,
  smallerThanVerticalTablet: false,
  mobile: false,
});

export const MediaQueryContext = createContext<MediaQueryState>(defaultState());

interface MediaQueryProviderProps {
  media: any;
}

const MediaQueryProviderState = ({
  media,
  children,
}: PropsWithChildren<MediaQueryProviderProps>) => {
  const [contextValue, setContextValue] = useState<MediaQueryState>(
    defaultState()
  );

  const updateMedia = debounce((value) => setContextValue(value));

  useEffect(() => {
    if (!isEqual(media, contextValue)) {
      updateMedia(media);
    }
  }, [media, contextValue, updateMedia]);

  // To avoid over-rendering the application, wait until we calculate the
  // current media query before returning
  const mediaQueryCalculated = Object.values(contextValue).some((v) => !!v);
  if (!mediaQueryCalculated) return null;

  return (
    <MediaQueryContext.Provider value={contextValue}>
      {children}
    </MediaQueryContext.Provider>
  );
};

const MediaQueryProviderContext = withMedia(MediaQueryProviderState);

export const MediaQueryProvider = ({ children }: PropsWithChildren) => (
  <OuterMediaQueryProvider queries={mediaQueries}>
    <MediaQueryProviderContext>{children}</MediaQueryProviderContext>
  </OuterMediaQueryProvider>
);

export const MediaQueryValue = MediaQueryContext.Provider;
export const MediaQuery = MediaQueryContext.Consumer;

export const DesktopOnly = ({ children }: PropsWithChildren) => (
  <MediaQuery>{(media) => (media.desktop ? children : null)}</MediaQuery>
);

export const useMediaQuery = () => useContext(MediaQueryContext);
