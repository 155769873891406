import { effects } from '../utils';
import type { ColorGroup } from './themeColors.types';

export const calculateColorGroup = (color: string): ColorGroup => {
  return {
    dark: effects.darken(color, 0.2),
    light: effects.lighten(color, 0.8),
    main: color as string,
  };
};
