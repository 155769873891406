import { Drawer, Flex, Typography } from '@jane/shared/reefer';

import { CartDrawerStatesWrapper } from './cartDrawer.styles';

export const CartDrawerError = () => {
  return (
    <>
      <Drawer.Header title="Bag" />
      <Drawer.Content noPadding>
        <CartDrawerStatesWrapper p={40}>
          <Flex justifyContent="center" mt={24} width="100%">
            <Typography>
              Error fetching your request, please refresh.
            </Typography>
          </Flex>
        </CartDrawerStatesWrapper>
      </Drawer.Content>
    </>
  );
};
