import type { ReactNode } from 'react';
import { isValidElement } from 'react';

export const getNodeText = (node: ReactNode): string | undefined => {
  if (!node) return undefined;

  if (typeof node === 'number' || typeof node === 'string') {
    return node.toString();
  } else if (node instanceof Array) {
    return node.map(getNodeText).join('');
  } else if (typeof node === 'object' && isValidElement(node)) {
    return getNodeText(node.props.children);
  } else {
    return undefined;
  }
};
