import { useState } from 'react';

import type { SelectBankAccount } from '@jane/shared-ecomm/data-access';
import type { AeropayBankAccount } from '@jane/shared/models';
import {
  Box,
  Button,
  Flex,
  Link,
  TrashIcon,
  Typography,
  useTabletMediaQuery,
} from '@jane/shared/reefer';
import type { MarginProperties } from '@jane/shared/reefer';

import { BankAccountDetails } from '../bankAccountDetails';
import { BankLinkingModal } from '../bankLinkingModal';
import {
  StyledDesktopButton,
  StyledMobileButton,
  StyledProfileSection,
} from './accountLinkedBankAccountsSection.styles';

export interface AccountLinkedBankAccountsSectionProps
  extends MarginProperties {
  bankAccounts: AeropayBankAccount[];
  hideJanePayBranding: boolean;
  isEmbeddedMode?: boolean;
  isMarketplace?: boolean;
  onAddNewBank: () => void;
  onSelectBank: SelectBankAccount;
  showJaneGold?: boolean;
  storePhoto?: string;
}

export function AccountLinkedBankAccountsSection({
  bankAccounts,
  hideJanePayBranding,
  isEmbeddedMode,
  isMarketplace,
  onAddNewBank,
  onSelectBank,
  showJaneGold,
  storePhoto,
  ...marginProps
}: AccountLinkedBankAccountsSectionProps) {
  const isTablet = useTabletMediaQuery({ width: 'min' });

  const [openModal, setOpenModal] = useState(false);
  const [deleteBankAccount, setDeleteBankAccount] =
    useState<AeropayBankAccount | null>(null);
  const isLastBankAccount = bankAccounts?.length === 1;

  return (
    <>
      <StyledProfileSection
        background="grays-white"
        border="grays-light"
        borderRadius="lg"
        maxWidth={1088}
        {...marginProps}
      >
        <Flex
          alignItems="center"
          justifyContent="space-between"
          flexDirection="row"
        >
          <Box>
            <Typography mb={4} branded variant="header-bold">
              {!bankAccounts?.length
                ? 'Link bank accounts'
                : 'Linked bank accounts'}
            </Typography>
            <Typography>
              {!bankAccounts?.length
                ? `Link your bank account to ${
                    hideJanePayBranding ? 'pay at checkout' : 'use Jane Pay'
                  }`
                : `Your bank accounts are linked to ${
                    hideJanePayBranding ? 'pay at checkout' : 'use Jane Pay'
                  }`}
              {showJaneGold || isMarketplace
                ? ' and receive cash back with Jane Gold'
                : ''}
              .
              {!bankAccounts?.length && (
                <>
                  &nbsp;
                  <Link href="https://customer-support.iheartjane.com/s/article/Jane-Pay-FAQs">
                    Learn more
                  </Link>
                </>
              )}
            </Typography>
          </Box>
          <StyledDesktopButton
            ml={4}
            label="Add new bank account"
            onClick={onAddNewBank}
            variant="secondary"
          />
        </Flex>

        {!!bankAccounts?.length && (
          <Flex flexDirection="column" gap={24} mt={isTablet ? 24 : 20}>
            {bankAccounts?.map((bankAccount: AeropayBankAccount) => (
              <Flex
                alignItems="center"
                flexDirection="row"
                justifyContent="space-between"
                key={bankAccount.bank_account_id}
              >
                <BankAccountDetails
                  bankAccount={bankAccount}
                  hideJanePayBranding={hideJanePayBranding}
                  selectable
                  onSelect={onSelectBank}
                />

                <Button.Icon
                  ariaLabel={`Remove bank account ${
                    bankAccount?.bank_name || ''
                  } ${bankAccount?.name || ''}`}
                  icon={<TrashIcon />}
                  onClick={() => {
                    setOpenModal(true);
                    setDeleteBankAccount(bankAccount);
                  }}
                  variant="tertiary"
                />
              </Flex>
            ))}
          </Flex>
        )}

        <StyledMobileButton
          full
          label="Add new bank account"
          mt={24}
          onClick={onAddNewBank}
          variant="secondary"
        />
      </StyledProfileSection>

      {openModal && (
        <BankLinkingModal
          hideJanePayBranding={hideJanePayBranding}
          isLastBankAccount={isLastBankAccount}
          deleteBankAccount={deleteBankAccount}
          isJanePay
          open={openModal}
          onRequestClose={() => {
            setOpenModal(false);
            setDeleteBankAccount(null);
          }}
          location="account"
          storePhoto={storePhoto}
        />
      )}
    </>
  );
}
