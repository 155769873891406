/**
 * DO NOT MODIFY THIS FILE!
 * It was created by the reefer-icons generator.
 * Please update & re-run the generator if need be.
 */
import type { SVGProps } from 'react';

import type { DynamicIconProps } from '../../../icon/icon.types';
import { Icon } from '../../icon';

const Trash24 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="100%"
    height="100%"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <g className="Trash 24">
      <g className="SVG">
        <path
          fill="#0E0E0E"
          fillRule="evenodd"
          d="M16.9 6H21a1 1 0 1 1 0 2h-1v9a5 5 0 0 1-5 5H9a5 5 0 0 1-5-5V8H3a1 1 0 0 1 0-2h4.1a5.002 5.002 0 0 1 9.8 0ZM9.17 6h5.66a3.001 3.001 0 0 0-5.66 0ZM6 17V8h12v9a3 3 0 0 1-3 3H9a3 3 0 0 1-3-3Z"
          clipRule="evenodd"
        />
        <path
          fill="#0E0E0E"
          fillRule="evenodd"
          d="M14 11a1 1 0 0 1 1 1v4a1 1 0 1 1-2 0v-4a1 1 0 0 1 1-1Zm-4 0a1 1 0 0 1 1 1v4a1 1 0 1 1-2 0v-4a1 1 0 0 1 1-1Z"
          clipRule="evenodd"
        />
      </g>
    </g>
  </svg>
);

const SIZE_MAP = {
  md: Trash24,
  sm: Trash24,
  lg: Trash24,
  xl: Trash24,
  xxl: Trash24,
  xxxl: Trash24,
};

export const TrashIcon = ({
  size = 'md',
  'data-testid': testId,
  ...props
}: DynamicIconProps) => {
  return (
    <Icon
      data-testid={testId || 'trash-icon'}
      icon={SIZE_MAP[size]}
      {...props}
      size={size}
    />
  );
};
