interface BasePayload<E extends EventNames> {
  eventName: E;
}
export enum EventNames {
  AddedProductToCart = 'Added Product to Cart',
  ClickedSeeAll = 'Clicked See All',
  SearchedMenuProducts = 'Searched Menu Products',
  ViewedProduct = 'Viewed Product',
}

export interface AddedProductToCartPayload
  extends BasePayload<EventNames.AddedProductToCart> {
  productId: number;
  storeId: number | undefined;
}

export interface ProductDetailPagePayload
  extends BasePayload<EventNames.ViewedProduct> {
  productId: number;
  storeId: number | undefined;
}

export interface ClickedSeeAllPayload
  extends BasePayload<EventNames.ClickedSeeAll> {
  category: string;
  storeId: number | undefined;
}
export interface SearchedMenuProductsPayload
  extends BasePayload<EventNames.SearchedMenuProducts> {
  filters: string[];
}

type UserSessionEventPayload =
  | ProductDetailPagePayload
  | AddedProductToCartPayload
  | ClickedSeeAllPayload
  | SearchedMenuProductsPayload;
export const trackUserSessionEvent = (payload: UserSessionEventPayload) => {
  const { eventName, ...props } = payload;
  userSessionMixpanel.track(eventName, props);
};
