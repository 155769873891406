import type { TextAreaFieldProps } from '../fields/field.types';
import { TextAreaFieldInternal } from '../fields/textAreaField/textAreaField';
import type { FormFieldProps } from '../formField.types';
import { FormFieldWrapper } from '../formFieldWrapper/formFieldWrapper';

export interface FormTextAreaFieldProps
  extends Omit<TextAreaFieldProps, 'children'>,
    Omit<FormFieldProps, 'max' | 'min'> {}

/**
 * TextArea fields define multi-line text input controls.
 *
 * **NOTE: DO NOT USE THIS COMPONENT OUTSIDE FORMS**
 *
 * *For a similar component for use outside forms, see [`TextAreaField`](/story/components-forms-fields-textareafield--default).*
 */
export function FormTextAreaField({
  autoFocus = false,
  defaultValue = '',
  disabled = false,
  autocomplete,
  helperText,
  errorMessage,
  errorOnBlur = false,
  label,
  labelHidden = false,
  maxLength,
  minLength,
  name,
  onBlur,
  onChange,
  pattern,
  placeholder,
  readOnly = false,
  required = false,
  resize = true,
  rows,
  shouldUnregister,
  'data-testid': testId,
  validate,
  width = 'auto',
  ...props
}: FormTextAreaFieldProps) {
  return (
    <FormFieldWrapper
      defaultValue={defaultValue}
      errorMessage={errorMessage}
      errorOnBlur={errorOnBlur}
      maxLength={maxLength}
      minLength={minLength}
      name={name}
      pattern={pattern}
      render={({ children, field }) => (
        <TextAreaFieldInternal
          autoFocus={autoFocus}
          defaultValue={defaultValue}
          disabled={disabled}
          autocomplete={autocomplete}
          helperText={helperText}
          label={label}
          labelHidden={labelHidden}
          name={field.name}
          onBlur={(value) => {
            onBlur && onBlur(value);
            field.onBlur();
          }}
          onChange={(value) => {
            onChange && onChange(value);
            field.onChange(value);
          }}
          onUpdate={onChange}
          placeholder={placeholder}
          readOnly={readOnly}
          ref={field.ref}
          resize={resize}
          rows={rows}
          data-testid={testId}
          value={field.value}
          width={width}
          {...props}
        >
          {children}
        </TextAreaFieldInternal>
      )}
      required={required}
      shouldUnregister={shouldUnregister}
      validate={validate}
    />
  );
}
