import { Flex, Typography } from '@jane/shared/reefer';
import type { MarginProperties } from '@jane/shared/reefer';

import { MENU_PRODUCT_SPONSORED_HEIGHT } from '../legacyMenuProductCard';

interface SponsoredTagProps extends MarginProperties {
  hideText?: boolean;
}

export const SponsoredTag = ({
  hideText = false,
  ...spacingProps
}: SponsoredTagProps) => (
  <Flex height={MENU_PRODUCT_SPONSORED_HEIGHT} justifyContent="center">
    {!hideText && <SponsoredText {...spacingProps} />}
  </Flex>
);

export const SponsoredText = (spacingProps: MarginProperties) => (
  <Typography
    variant="mini"
    color="text-light"
    data-janedm-tag="sponsored"
    {...spacingProps}
  >
    Sponsored
  </Typography>
);
