import kebabCase from 'lodash/kebabCase';

import type { Store } from '@jane/shared/models';
import type { Store as zStore } from '@jane/shared/types';

import { buildFilter } from './buildFilter';
import { formatFilterLabel } from './formatFilterLabel';
import { JANE_DEFINED_ROWS } from './searchStateUtils';

/** Takes a featuredCategory path param and returns the corresponding custom row title and filter */

export const formatFeaturedCategory = (
  store: Store | zStore,
  featuredCategory?: string
): {
  featuredFilter: string;
  featuredTitle: string;
  isJaneDefined: boolean;
} => {
  const { id, custom_product_type_labels } = store;
  const customLabels = custom_product_type_labels;
  const janeCategories = [...JANE_DEFINED_ROWS, 'buy_again'];

  if (!featuredCategory)
    return { featuredTitle: '', featuredFilter: '', isJaneDefined: false };

  // encode/decode uri component for handling emojis
  // and use kebabCase to strip special characters from custom labels
  const pathToLabel = featuredCategory && decodeURIComponent(featuredCategory);

  // need to skip anything with a : to omit labels that are not tied to custom rows
  const customFilterAndLabel = customLabels
    ? Object.entries(customLabels).filter(
        ([filter, label]) =>
          !filter.includes(':') &&
          (kebabCase(filter) === pathToLabel ||
            kebabCase(label) === pathToLabel)
      )
    : [];

  // if a custom label exists
  if (customFilterAndLabel.length) {
    const [filter, label] = customFilterAndLabel[0];
    const isJaneDefined = janeCategories.includes(filter);
    const formattedFilter = isJaneDefined ? filter : `${filter}-${id}`;

    return {
      featuredTitle: label,
      featuredFilter: buildFilter('root_types', [formattedFilter]),
      isJaneDefined,
    };
  }

  const snakeCaseLabel = pathToLabel.split('-').join('_');

  // if no custom label but path is jane defined
  if (!customFilterAndLabel.length && janeCategories.includes(snakeCaseLabel)) {
    return {
      featuredTitle: formatFilterLabel(snakeCaseLabel, customLabels, id),
      featuredFilter:
        pathToLabel !== 'buy-again'
          ? buildFilter('root_types', [snakeCaseLabel])
          : '',
      isJaneDefined: true,
    };
  }

  return {
    featuredTitle: '',
    featuredFilter: '',
    isJaneDefined: false,
  };
};
