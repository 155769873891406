import capitalize from 'lodash/capitalize';
import compact from 'lodash/compact';
import {
  AggregateRating,
  Generic,
  GenericCollection,
  JSONLD,
} from 'react-structured-data';

import type { Store } from '@jane/shared/models';
import type { StoreSeo } from '@jane/shared/types';
import { monolithPaths } from '@jane/shared/util';

interface Props {
  store: Store;
  structuredData?: StoreSeo['structured_data'];
}

export const StoreDetailStructuredData = ({ store, structuredData }: Props) => {
  const {
    id,
    address,
    city,
    name,
    description,
    lat,
    long,
    medical,
    phone,
    photo,
    rating,
    recreational,
    reviews_count,
    state,
    working_hours,
    url_slug,
    zip,
  } = store;

  const hours = working_hours.filter((day) => day.period.from && day.period.to);
  const additionalProperties = compact([
    medical ? { name: 'Medical', value: medical } : undefined,
    recreational ? { name: 'Recreational', value: recreational } : undefined,
  ]);

  return (
    <JSONLD>
      <Generic
        type="store"
        jsonldtype="Store"
        schema={{
          ...structuredData,
          '@id': monolithPaths.store({ id, name }, undefined, url_slug),
          '@type': 'Store',
          name,
          description,
          telephone: phone,
          image: photo,
        }}
      >
        {/*
         * Need to filter falsy values before passing in since library doesn't.
         * https://github.com/bentaylor2/react-structured-data/issues/7
         */}
        {[
          reviews_count > 0 && (
            <AggregateRating
              key="rating"
              ratingValue={rating}
              reviewCount={reviews_count}
            />
          ),
          <Generic
            key="address"
            type="address"
            jsonldtype="PostalAddress"
            schema={{
              addressLocality: city,
              addressRegion: state,
              postalCode: zip,
              streetAddress: address,
            }}
          />,
          <Generic
            key="geo"
            type="geo"
            jsonldtype="GeoCoordinates"
            schema={{
              latitude: lat,
              longitude: long,
            }}
          />,
          hours.length > 0 && (
            <GenericCollection
              key="openingHours"
              type="openingHoursSpecification"
            >
              {hours.map((day) => (
                <Generic
                  key={day.day}
                  jsonldtype="OpeningHoursSpecification"
                  schema={{
                    dayOfWeek: `https://schema.org/${capitalize(day.day)}`,
                    opens: day.period.from,
                    closes: day.period.to,
                  }}
                />
              ))}
            </GenericCollection>
          ),
          additionalProperties.length > 0 && (
            <GenericCollection
              key="additionalProperties"
              type="additionalProperty"
            >
              {additionalProperties.map((property) => (
                <Generic
                  key={property.name}
                  jsonldtype="PropertyValue"
                  schema={property}
                />
              ))}
            </GenericCollection>
          ),
        ].filter(Boolean)}
      </Generic>
    </JSONLD>
  );
};
