import debounce from 'lodash/debounce';
import { useEffect, useState } from 'react';

import { getGoogleAddresses } from '@jane/shared-ecomm/util';
import {
  Flex,
  Form,
  LocationPinFilledIcon,
  TextField,
} from '@jane/shared/reefer';

import type { AddressAutocompleteProps } from './addressAutocomplete.types';
import { GoogleFooter } from './googleFooter';

export const AddressAutocomplete = ({
  autoFocus,
  clearIcon,
  countries = ['us', 'ca', 'pr'],
  'data-testid': testId,
  defaultValue = '',
  disableMobileInputStyling = false,
  errorMessage,
  name,
  isForm,
  label,
  onBlur,
  onChange,
  onSelection,
  locationIcon,
  placeholder,
}: AddressAutocompleteProps) => {
  const [options, setOptions] = useState<string[]>([]);

  const TextFieldComponent = isForm ? Form.TextField : TextField;

  const handleGetAddresses = debounce((value) => {
    getGoogleAddresses(value, countries).then((result) =>
      setOptions(
        result.map(
          (location: { label: string; value: string }) => location.value
        )
      )
    );
  }, 200);

  const handleOnChange = (value: string) => {
    if (value.length > 0) {
      handleGetAddresses(value);
    } else {
      setOptions([]);
    }

    onChange && onChange(value);
  };

  useEffect(() => {
    if (defaultValue.length > 0) {
      handleGetAddresses(defaultValue);
    }
  }, [defaultValue]);

  return (
    <Flex width="100%">
      <TextFieldComponent
        autocompleteFooter={<GoogleFooter />}
        autocompleteOptions={options}
        autoFocus={autoFocus}
        clearIcon={clearIcon}
        defaultValue={defaultValue}
        disableMobileInputStyling={disableMobileInputStyling}
        helperText={errorMessage}
        label={label}
        name={name}
        onBlur={onBlur}
        onChange={handleOnChange}
        onSelection={onSelection}
        placeholder={placeholder}
        required
        startIcon={
          locationIcon && <LocationPinFilledIcon size="sm" color="grays-mid" />
        }
        data-testid={testId}
        width="100%"
      />
    </Flex>
  );
};
