import styled from '@emotion/styled';

import type { Feeling } from '@jane/search/types';
import { FEELING_ICONS } from '@jane/search/util';
import { Box, Flex, Typography } from '@jane/shared/reefer';

export interface FeelingCardProps {
  feeling: Feeling;
  selected?: boolean;
  width?: string;
}

const FeelingContainer = styled(Box)<{ flexBasis?: string }>(
  ({ flexBasis }) => ({ flexBasis })
);

export const FeelingCard = ({ feeling, selected, width }: FeelingCardProps) => {
  const Icon = FEELING_ICONS[feeling];

  return (
    <FeelingContainer
      border={selected ? 'purple' : undefined}
      background="grays-ultralight"
      borderRadius="lg"
      p={12}
      minWidth="fit-content"
      flexBasis={width}
    >
      <Flex flexDirection="column" alignItems="center" justifyContent="center">
        <Icon size="md" />
        <Typography color="grays-black">{feeling}</Typography>
      </Flex>
    </FeelingContainer>
  );
};
