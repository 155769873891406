import clsx from 'clsx';
import type { ReactPortal } from 'react';
import { createPortal } from 'react-dom';

import { prefixStyles } from '../../../utils';
import styles from '../../toast/toast.module.css';
import type {
  ToastContentProps,
  ToastsRenderer,
} from '../../toast/toast.types';
import { renderToast } from './renderToast';

export const renderToasts = ({
  position = 'top-right',
  remove,
  toasts,
  verticalOffset = 5,
}: ToastsRenderer) => {
  const verticalProperty = position.includes('top')
    ? { top: verticalOffset }
    : { bottom: verticalOffset };

  return (
    <div className={clsx(styles.toast_overlay)}>
      <div
        className={clsx(styles.toast_container, {
          [styles.toast_container__middle]: position.includes('middle'),
        })}
        style={prefixStyles(verticalProperty)}
      >
        {toasts.map((toast: ToastContentProps) =>
          renderToast({
            ...toast,
            onClose: remove,
            position,
          })
        )}
      </div>
    </div>
  );
};

export const renderToastsInPortal = (props: ToastsRenderer): ReactPortal =>
  createPortal(renderToasts(props), document.body);
