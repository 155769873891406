import { z } from 'zod';

export const AlgoliaConfigSchema = z.object({
  algoliaAppId: z.string(),
  algoliaApiKey: z.string(),
  algoliaEnv: z.string(),
  algoliaUrl: z.string(),
});

export type AlgoliaConfig = z.infer<typeof AlgoliaConfigSchema>;
