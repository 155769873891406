import styled from '@emotion/styled';

import { Card, Link } from '@jane/shared/reefer';

export const StyledCard = styled(Card)(({ theme }) => ({
  '&:hover': { borderColor: theme.colors.transparent.black.twenty },
}));

export const StyledLink = styled(Link)({
  width: '100%',
});
