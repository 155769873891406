import type { JaneDMIdentifiers } from '@jane/dm/sdk';
import { useGetJaneDeviceId } from '@jane/shared/data-access';

export const useGetJaneDMIdentifiers = (
  defaultIdentifiers?: Partial<JaneDMIdentifiers>
): JaneDMIdentifiers => {
  const { data: jdid } = useGetJaneDeviceId();
  const mixpanelDistinctId = janeMixpanel.get_distinct_id();

  return {
    jdid: defaultIdentifiers?.jdid ?? jdid,
    mixpanelDistinctId:
      defaultIdentifiers?.mixpanelDistinctId ?? mixpanelDistinctId,
  };
};
