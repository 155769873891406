/**
 * DO NOT MODIFY THIS FILE!
 * It was created by the reefer-icons generator.
 * Please update & re-run the generator if need be.
 */
import type { SVGProps } from 'react';

import type { DynamicIconProps } from '../../../icon/icon.types';
import { Icon } from '../../icon';

const Vape24 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="100%"
    height="100%"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <g className="Vape 24">
      <path
        fill="#CE349A"
        d="M2.958 13.294C3.887 12.712 4 12.192 4 12c0-.191-.113-.71-1.036-1.29a2.042 2.042 0 0 1-.147-.098C1.067 9.34 0 7.515 0 5.501 0 3.483 1.07 1.654 2.826.38a2 2 0 1 1 2.348 3.24C4.264 4.28 4 4.985 4 5.5c0 .507.254 1.196 1.125 1.845C6.814 8.418 8 10.048 8 12.001c0 1.952-1.186 3.581-2.874 4.654-.872.65-1.126 1.34-1.126 1.846 0 .515.263 1.22 1.174 1.88a2 2 0 0 1-2.348 3.239C1.07 22.347 0 20.519 0 18.5c0-2.01 1.062-3.831 2.805-5.103a2.088 2.088 0 0 1 .153-.103Zm7.999 0C11.887 12.712 12 12.192 12 12c0-.191-.113-.71-1.036-1.29a2.052 2.052 0 0 1-.128-.084l-.02-.014C9.067 9.34 8 7.515 8 5.501 8 3.483 9.07 1.654 10.826.38a2 2 0 1 1 2.348 3.239C12.264 4.28 12 4.985 12 5.5c0 .507.254 1.196 1.125 1.845C14.814 8.418 16 10.048 16 12.001c0 1.952-1.186 3.581-2.874 4.654-.872.65-1.126 1.34-1.126 1.846 0 .515.263 1.22 1.174 1.88a2 2 0 0 1-2.348 3.239C9.07 22.347 8 20.519 8 18.5c0-2.01 1.062-3.831 2.805-5.103a2.133 2.133 0 0 1 .152-.103Zm8 0C19.887 12.712 20 12.192 20 12c0-.191-.113-.71-1.036-1.29a2.052 2.052 0 0 1-.128-.084l-.02-.014C17.067 9.34 16 7.515 16 5.501c0-2.018 1.07-3.847 2.826-5.12a2 2 0 1 1 2.348 3.239C20.264 4.28 20 4.985 20 5.5c0 .507.254 1.196 1.125 1.845C22.814 8.418 24 10.048 24 12.001c0 1.952-1.186 3.581-2.874 4.654-.872.65-1.126 1.34-1.126 1.846 0 .515.263 1.22 1.174 1.88a2 2 0 0 1-2.348 3.239C17.07 22.347 16 20.519 16 18.5c0-2.01 1.062-3.831 2.805-5.103a2.133 2.133 0 0 1 .152-.103Z"
        className="Fill-Main"
      />
    </g>
  </svg>
);

const SIZE_MAP = {
  md: Vape24,
  sm: Vape24,
  lg: Vape24,
  xl: Vape24,
  xxl: Vape24,
  xxxl: Vape24,
};

export const VapeIcon = ({
  size = 'md',
  'data-testid': testId,
  ...props
}: DynamicIconProps) => {
  return (
    <Icon
      data-testid={testId || 'vape-icon'}
      icon={SIZE_MAP[size]}
      {...props}
      size={size}
    />
  );
};
