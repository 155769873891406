import { FilterBarButtons } from './filterBarButtons';
import { FilterBarPopover } from './filterPopover/filterBarPopover';
import type { FilterBarPopoverProps } from './filterPopover/filterBarPopover.types';
import { FilterBarTypeahead } from './filterPopover/filterBarTypeahead';

/** Renders filter bar components according to FilterItemType */

export const FilterBarGroup = ({
  filterGroup,
  hideCounts,
  isLoading,
  onChange,
  onDeselect,
  totalResults,
  variant,
}: FilterBarPopoverProps) => {
  const { items, showInFilterBar, type, typeahead } = filterGroup;

  if (!showInFilterBar || !items.length) return null;

  if (type === 'singleselect') {
    return (
      <FilterBarButtons
        key={filterGroup.key}
        filterGroup={filterGroup}
        hideCounts={hideCounts}
        onChange={onChange}
      />
    );
  }

  if (typeahead) {
    return (
      <FilterBarTypeahead
        key={filterGroup.key}
        filterGroup={filterGroup}
        hideCounts={hideCounts}
        onChange={onChange}
        onDeselect={onDeselect}
        totalResults={totalResults}
        variant={variant}
      />
    );
  }

  return (
    <FilterBarPopover
      key={filterGroup.key}
      filterGroup={filterGroup}
      hideCounts={hideCounts}
      isLoading={isLoading}
      onChange={onChange}
      onDeselect={onDeselect}
      totalResults={totalResults}
      variant={variant}
    />
  );
};
