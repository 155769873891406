import * as t from 'io-ts';

export const tStoreTax = t.interface({
  title: t.string,
  rate: t.string,
  is_taxable: t.boolean,
  apply_to_discounted_price: t.boolean,
  apply_to_non_cannabis_items: t.boolean,
});

export type StoreTax = t.TypeOf<typeof tStoreTax>;
