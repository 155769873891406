/**
 * DO NOT MODIFY THIS FILE!
 * It was created by the reefer-icons generator.
 * Please update & re-run the generator if need be.
 */
import type { SVGProps } from 'react';

import type { DynamicIconProps } from '../../../icon/icon.types';
import { Icon } from '../../icon';

const Tincture24 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="100%"
    height="100%"
    fill="none"
    viewBox="0 0 24 25"
    {...props}
  >
    <g className="Tincture 24">
      <g className="SVG">
        <path
          fill="#8E00E6"
          d="M4.929 21.268a9.9 9.9 0 0 1 0-14.059l5.661-5.627a2 2 0 0 1 2.82 0l5.661 5.627a9.9 9.9 0 0 1 0 14.06c-3.905 3.882-10.237 3.882-14.142 0Z"
          className="Main"
        />
        <path
          fill="#000"
          fillOpacity={0.5}
          fillRule="evenodd"
          d="M6 15.18a1 1 0 0 1 1 1 3 3 0 0 0 3 3 1 1 0 1 1 0 2 5 5 0 0 1-5-5 1 1 0 0 1 1-1Z"
          className="Details"
          clipRule="evenodd"
        />
      </g>
    </g>
  </svg>
);

const SIZE_MAP = {
  md: Tincture24,
  sm: Tincture24,
  lg: Tincture24,
  xl: Tincture24,
  xxl: Tincture24,
  xxxl: Tincture24,
};

export const TinctureIcon = ({
  size = 'md',
  'data-testid': testId,
  ...props
}: DynamicIconProps) => {
  return (
    <Icon
      data-testid={testId || 'tincture-icon'}
      icon={SIZE_MAP[size]}
      {...props}
      size={size}
    />
  );
};
