import compact from 'lodash/compact';
import uniq from 'lodash/uniq';
import { useEffect, useRef, useState } from 'react';

import type { Id, Store, StoreSpecial } from '@jane/shared/models';
import { Box, Loading } from '@jane/shared/reefer';

import { useCustomerDispatch } from '../../../../../customer/dispatch';
import { analyzeSpecials } from '../../../../../customer/redux/cart';
import { useCustomerSelector } from '../../../../../customer/selectors';
import { isEligibleForGroupDiscount } from '../../../../../lib/userGroupDiscount';
import { get } from '../../../../../redux-util/selectors';
import { LoginProtected } from '../loginProtected';
import { GroupDiscountButton } from './groupDiscountButton';
import { GroupDiscountDetails } from './groupDiscountDetails';
import { GroupDiscountOuter, LoadingContainer } from './groupDiscounts.styles';

export const groupSpecialsTitle = (
  groupSpecials: StoreSpecial[],
  specialLabel?: string | null
) => {
  const applicableGroups = compact(
    uniq(
      groupSpecials.map((s) => {
        if (!s.conditions['qualified_group']) return null;
        const specialType = s.conditions['qualified_group']['type'];
        return specialType === 'pediatric' ? 'pediatric/minors' : specialType;
      })
    )
  )
    .sort()
    .join('/');

  return `Apply ${applicableGroups} ${specialLabel || 'discount'}`;
};

interface Props {
  groupSpecials: StoreSpecial[];
  headless?: boolean;
  userGroupSpecialId?: Id;
}

export const GroupDiscounts = ({
  groupSpecials,
  headless,
  userGroupSpecialId,
}: Props) => {
  const dispatch = useCustomerDispatch();
  const { document, promoCode, reservationMode, storeState } =
    useCustomerSelector(
      ({
        identification: { document },
        store: { store },
        cart: {
          cart: { promo_code, reservation_mode },
        },
      }) => ({
        document,
        promoCode: promo_code,
        reservationMode: reservation_mode,
        storeState: store,
      })
    );
  const store = storeState as Store;
  const { time_zone_identifier: timezone, store_compliance_language_settings } =
    store;
  const specialsLabel = store_compliance_language_settings?.['specials'];

  const [selectedSpecialId, setSelectedSpecialId] = useState<Id | undefined>(
    userGroupSpecialId
  );
  const [eligible, setEligible] = useState<boolean>(false);
  const { analyzingSpecials } = useCustomerSelector(get('cart'));

  const eligibleRef = useRef(false);
  const firstTimeRef = useRef(true);

  useEffect(() => {
    const eligible = isEligibleForGroupDiscount({
      document,
      special: groupSpecials.find((sp) => sp.id === selectedSpecialId),
      timezone,
    });
    setEligible(eligible);
    eligibleRef.current = eligible;
  }, [document, selectedSpecialId]);

  useEffect(() => {
    if (firstTimeRef.current) {
      firstTimeRef.current = false;
      return;
    }

    const specialIdToApply =
      eligibleRef.current && selectedSpecialId ? selectedSpecialId : undefined;

    dispatch(
      analyzeSpecials({
        userGroupSpecialId: specialIdToApply,
        reservationMode,
        promoCode,
      })
    );
  }, [selectedSpecialId, eligible]);

  const selectedDiscount = groupSpecials.find(
    (d) => selectedSpecialId === d.id
  );

  return (
    <Box position="relative" pl={headless ? undefined : 40}>
      <LoginProtected
        reservationMode={reservationMode}
        specialLabel={specialsLabel}
      >
        <>
          {analyzingSpecials && (
            <LoadingContainer
              alignItems="center"
              flexDirection="column"
              mx="auto"
            >
              <Loading />
            </LoadingContainer>
          )}
          <GroupDiscountOuter>
            <GroupDiscountButton
              analyzingSpecials={analyzingSpecials}
              groupDiscounts={groupSpecials}
              selectSpecial={setSelectedSpecialId}
              selectedSpecial={selectedSpecialId}
            />
            {selectedDiscount && (
              <GroupDiscountDetails
                special={selectedDiscount}
                timezone={timezone}
              />
            )}
          </GroupDiscountOuter>
        </>
      </LoginProtected>
    </Box>
  );
};
