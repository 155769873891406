import qs from 'qs';

import { getJwt } from '@jane/shared/auth';
import type { Address, ReservationMode } from '@jane/shared/types';

import { janeApi } from '../api';
import { createUseQueryHook } from '../utils';
import { queryKeys, urls } from './promoCode.util';

export interface PromoCodeProps {
  deliveryAddress?: Address;
  promoCode?: string;
  reservationMode: ReservationMode;
  userGroupSpecialId?: number | string;
  uuid: string;
}

const validatePromoCode = async ({
  promoCode,
  reservationMode,
  userGroupSpecialId,
  uuid,
  deliveryAddress,
}: PromoCodeProps) => {
  if (!promoCode) {
    return { validated: false, isValid: false };
  }

  const queryParams = {
    promo_code: promoCode,
    reservation_mode: reservationMode,
    user_group_special_id: userGroupSpecialId,
    uuid: uuid,
    delivery_address: deliveryAddress ? deliveryAddress : undefined,
  };
  const queryString = `?${qs.stringify(queryParams)}`;
  try {
    const response = await janeApi.get<any>(
      `${urls.promoCode}${queryString}`,
      getJwt()
    );

    if (response.validations) {
      return { validated: true, isValid: false };
    }

    return { validated: true, isValid: true, cart: response.cart };
  } catch {
    return { validated: true, isValid: false };
  }
};

export const useValidatePromoCode = createUseQueryHook<PromoCodeProps, any>(
  validatePromoCode,
  queryKeys.promoCode
);
