import { useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import type { ChangeEvent } from 'react';
import { Component } from 'react';

import { Flex } from '@jane/shared/reefer';
import type { ReeferTheme } from '@jane/shared/reefer';
import { mediaQueries, spacing } from '@jane/shared/reefer-emotion';

import { MediaQuery } from '../../common/providers/mediaQueryProvider';
import { SvgIcon } from '../../component-library/svg-icon';
import OffscreenLabel from '../offscreenLabel';
import type { SearchFormMode } from './form';

const Input = styled.input<{ mode: SearchFormMode }>(
  spacing({ px: 8, py: 0 }),
  {
    border: 'none',
    borderRadius: 0,
    flex: '1 1 auto',
    height: '100%',
    width: '100%',
    WebkitAppearance: 'none',
    '&::-ms-clear': {
      display: 'none',
    },
    '&::-webkit-search-cancel-button': {
      display: 'none',
    },
    '&:-webkit-autofill': {
      boxShadow: '0 0 0 100px white inset',
    },
  },
  ({ mode }) =>
    mode === 'header' && {
      [mediaQueries.tablet('min')]: spacing({ pt: 4 }),
    }
);

const ButtonContainer = styled(Flex)<{ mode: SearchFormMode }>(
  {
    height: '100%',
    position: 'relative',
  },
  ({ mode }) => ({
    marginLeft: mode === 'header' ? 20 : 14,
    [mediaQueries.tablet('min')]: spacing({ m: 0 }),
  })
);

const ClearButton = styled.button<{ mode: SearchFormMode }>(
  { alignItems: 'center', display: 'flex', justifyContent: 'center' },
  spacing({ pr: 8 }),
  {
    height: 24,
    width: 24,
    position: 'absolute',
    top: '50%',
    transform: 'translate(0, -50%)',
  },
  ({ mode }) => ({
    right: 32,
  })
);

const submitStyles = (theme: ReeferTheme) => [
  {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: theme.colors.grays.white,
    height: '100%',
    width: 40,
    [mediaQueries.tablet('min')]: {
      width: 48,
    },
  },
  ({ mode }: { mode: SearchFormMode }) =>
    mode === 'header' && {
      width: 32,
      [mediaQueries.tablet('min')]: {
        width: 48,
      },
    },
  ({ mode }: { mode: SearchFormMode }) =>
    mode === 'header' && {
      backgroundColor: theme.colors.brand.gold.main,
    },
];

const SubmitButton = styled.button<{ mode: SearchFormMode }>(({ theme }) =>
  submitStyles(theme)
);

const SubmitIcon = styled.div<{ mode: SearchFormMode }>(({ theme }) =>
  submitStyles(theme)
);

interface Props {
  hideSubmitButton?: boolean;
  mode: SearchFormMode;
  onChange: (value: string) => void;
  onClear?: () => void;
  placeholder: string;
  searchType: string;
  submitButtonDisabled?: boolean;
  value: string;
}

interface State {
  showClearButton: boolean;
}

export default class SearchInput extends Component<Props, State> {
  override state = {
    showClearButton: this.shouldShowClearButton(this.props.value),
  };

  override UNSAFE_componentWillReceiveProps(nextProps: Props) {
    this.setState({
      showClearButton: this.shouldShowClearButton(nextProps.value),
    });
  }

  shouldShowClearButton(value: string) {
    return !!(value && value.length);
  }

  onChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;

    this.setState({
      showClearButton: this.shouldShowClearButton(value),
    });

    this.props.onChange(value);
  };

  clearInput = () => {
    const { onChange, onClear } = this.props;
    this.setState({ showClearButton: false });

    onChange('');
    if (onClear) onClear();
  };

  override render() {
    const {
      hideSubmitButton,
      mode,
      placeholder,
      searchType,
      value,
      submitButtonDisabled,
    } = this.props;

    const SubmitContainer = submitButtonDisabled ? SubmitIcon : SubmitButton;

    return (
      <MediaQuery>
        {(media) => (
          <Flex alignItems="center" justifyContent="center" height="100%">
            <OffscreenLabel htmlFor={searchType}>Search</OffscreenLabel>
            <Input
              id={searchType}
              name={searchType}
              onChange={this.onChange}
              placeholder={placeholder}
              type="search"
              value={value}
              mode={mode}
            />
            <ButtonContainer mode={mode}>
              {this.state.showClearButton && media.smallerThanVerticalTablet && (
                <ClearButton onClick={this.clearInput} type="reset" mode={mode}>
                  <DismissFilledIcon />
                </ClearButton>
              )}
              {!hideSubmitButton && (
                <SubmitContainer
                  type="submit"
                  mode={mode}
                  aria-label="Search"
                  css={submitButtonDisabled && { pointerEvents: 'none' }}
                >
                  <SearchIcon />
                </SubmitContainer>
              )}
            </ButtonContainer>
          </Flex>
        )}
      </MediaQuery>
    );
  }
}

const DismissFilledIcon = () => {
  const theme = useTheme();

  return <SvgIcon color={theme.colors.grays.black} icon="times" size={18} />;
};

const SearchIcon = () => {
  const theme = useTheme();

  return <SvgIcon icon="search" color={theme.colors.grays.black} size={16} />;
};
