/**
 * DO NOT MODIFY THIS FILE!
 * It was created by the reefer-icons generator.
 * Please update & re-run the generator if need be.
 */
import type { SVGProps } from 'react';

import type { DynamicIconProps } from '../../../icon/icon.types';
import { Icon } from '../../icon';

const Hybrid16 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="100%"
    height="100%"
    fill="none"
    viewBox="0 0 16 16"
    {...props}
  >
    <g className="Hybrid 16">
      <g className="SVG">
        <path
          fill="#666"
          d="M4.769 12.502V4h1.508v3.419h3.445V4h1.508v8.502H9.722v-3.77H6.277v3.77H4.769Z"
        />
        <path
          fill="#666"
          fillRule="evenodd"
          d="M12 1H4a3 3 0 0 0-3 3v8a3 3 0 0 0 3 3h8a3 3 0 0 0 3-3V4a3 3 0 0 0-3-3ZM4 0a4 4 0 0 0-4 4v8a4 4 0 0 0 4 4h8a4 4 0 0 0 4-4V4a4 4 0 0 0-4-4H4Z"
          clipRule="evenodd"
        />
      </g>
    </g>
  </svg>
);

const Hybrid24 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="100%"
    height="100%"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <g className="Hybrid 24">
      <rect
        width={22}
        height={22}
        x={1}
        y={1}
        stroke="#0E0E0E"
        strokeWidth={2}
        className="Rectangle 644"
        rx={3}
      />
      <path
        fill="#0E0E0E"
        d="M8.272 17V7.19h1.74v3.945h3.975V7.19h1.74V17h-1.74v-4.35h-3.975V17h-1.74Z"
        className="H"
      />
    </g>
  </svg>
);

const SIZE_MAP = {
  sm: Hybrid16,
  md: Hybrid24,
  lg: Hybrid24,
  xl: Hybrid24,
  xxl: Hybrid24,
  xxxl: Hybrid24,
};

export const HybridIcon = ({
  size = 'md',
  'data-testid': testId,
  ...props
}: DynamicIconProps) => {
  return (
    <Icon
      data-testid={testId || 'hybrid-icon'}
      icon={SIZE_MAP[size]}
      {...props}
      size={size}
    />
  );
};
