import { useState } from 'react';
import { matchRoutes, useLocation } from 'react-router-dom';

import { useUserPreferences } from '@jane/shared-ecomm/providers';
import { LocationDetector } from '@jane/shared-ecomm/util';
import type {
  Preferences,
  StoreFulfillmentTypes,
  StoreTypes,
} from '@jane/shared/models';
import {
  Box,
  Button,
  ButtonToggle,
  DismissIcon,
  FieldWrapper,
  Flex,
  HeartFilledIcon,
  Modal,
  Typography,
  useMobileMediaQuery,
  useWindowListener,
} from '@jane/shared/reefer';

import type { LocationErrors } from './unservedLocationModalContent';
import { UnservedLocationModalContent } from './unservedLocationModalContent';
import { UserPreferencesLocation } from './userPreferencesLocation';
import {
  PreferencesWrapper,
  StyledButtonToggleButton,
  StyledSlider,
} from './userPreferencesModal.styles';
import { validateUserPreferences } from './validateUserPreferences';

export const MESSAGE_ID = 'location-message';

const FULFILLMENT_TYPES = [
  { label: 'Pickup', value: 'pickup' },
  { label: 'Delivery', value: 'delivery' },
];
const STORE_TYPES = [
  { label: 'Recreational', value: 'recreational' },
  { label: 'Medical', value: 'medical' },
  { label: 'All', value: 'all' },
];

interface Props {
  appPartner?: string;
  disallowCloseModal?: boolean;
  onCloseModal: () => void;
  showModal: boolean;
}

const getInitialPreferences = (userPreferences: Preferences): Preferences => ({
  storeAvailability: userPreferences.storeAvailability,
  storeFulfillmentType: userPreferences.storeFulfillmentType || 'pickup',
  storeType: userPreferences.storeType || 'recreational',
  storeSearchRadius: userPreferences.storeSearchRadius || 20,
});

export const UserPreferencesModal = ({
  disallowCloseModal = false,
  onCloseModal,
  showModal,
}: Props) => {
  const location = useLocation();

  const {
    resetLocation,
    resetPreferences,
    userPreferences,
    setUserLocation,
    setUserPreferences,
  } = useUserPreferences();

  const [preferences, setPreferences] = useState<Preferences>(
    getInitialPreferences(userPreferences as Preferences)
  );

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<LocationErrors | null>(null);
  const [address, setAddress] = useState<string>('');

  const hideFullfillmentType = !!matchRoutes(
    [
      { path: '/brands/all' },
      { path: '/brands/:brand_id/:brand_name/products/:product_id/:slug' },
    ],
    location
  );

  const hideStoreType = !!matchRoutes(
    [
      { path: '/products/:product_id/:slug' },
      { path: '/brands/:brand_id/:brand_name/products/:product_id/:slug' },
    ],
    location
  );

  const hideRadiusSlider =
    preferences.storeFulfillmentType === 'delivery' ||
    hideFullfillmentType ||
    !!matchRoutes([{ path: '/stores' }], location);

  const isMobile = useMobileMediaQuery({});

  const handleSubmit = async () => {
    if (!address) {
      resetLocation();
      resetPreferences();
      onCloseModal && onCloseModal();
      return;
    }
    try {
      setLoading(true);
      const location = await LocationDetector.getInstance().geocode(address);

      if (!location.coordinates) {
        setError('error');
        setLoading(false);
        return;
      }

      const storeFulfillmentType = hideFullfillmentType
        ? 'pickup'
        : (preferences.storeFulfillmentType as StoreFulfillmentTypes);

      const { error } = await validateUserPreferences(location, {
        ...preferences,
        storeFulfillmentType,
      });

      if (error) {
        setError(error as LocationErrors);
      } else {
        setUserLocation({ ...location, hasResetLocation: false });
        setUserPreferences({
          ...preferences,
          storeFulfillmentType,
        });
        onCloseModal && onCloseModal();
      }
    } catch (error) {
      setError(error as LocationErrors);
    }

    setLoading(false);
  };

  const handleSetAddress = (address: string) => {
    setAddress(address);
  };

  const handleKeyPress = (event: KeyboardEvent) => {
    if (event.key === 'Enter' && !loading && address) {
      handleSubmit();
    }
  };

  useWindowListener('keydown', handleKeyPress);

  return (
    <>
      <Modal
        background="purple"
        contentLabel="User preferences modal"
        onRequestClose={disallowCloseModal ? () => null : onCloseModal}
        open={showModal}
        overlayClose
        variant={isMobile ? 'full-screen' : 'dialogue'}
      >
        <Modal.Content>
          <Flex justifyContent="center">
            <Box position="absolute" top={16} left={16}>
              <DismissIcon
                altText="dismiss-modal"
                onClick={onCloseModal}
                color="grays-white"
              />
            </Box>
            {isMobile && <HeartFilledIcon />}
          </Flex>

          <UserPreferencesLocation
            handleSetAddress={handleSetAddress}
            fulfillmentType={preferences.storeFulfillmentType}
          />

          <PreferencesWrapper
            flexDirection="column"
            mt={!hideFullfillmentType ? 12 : 24}
            width="100%"
            id="user-preferences"
          >
            {!hideFullfillmentType && (
              <FieldWrapper
                name="Fulfillment Type"
                label=""
                labelHidden
                render={() => (
                  <ButtonToggle
                    value={preferences.storeFulfillmentType as string}
                    variant="inverse"
                    full
                    onChange={(value) =>
                      setPreferences((prevValue: Preferences) => ({
                        ...prevValue,
                        storeFulfillmentType: value as 'delivery' | 'pickup',
                      }))
                    }
                  >
                    {FULFILLMENT_TYPES.map((type) => (
                      <ButtonToggle.Button
                        key={type.value}
                        value={type.value}
                        label={type.label}
                      />
                    ))}
                  </ButtonToggle>
                )}
              />
            )}
            <Modal.ContentDivider color="purple-dark" />

            {!hideStoreType && (
              <>
                <FieldWrapper
                  name="Store Types"
                  label="Store Types"
                  disableMobileInputStyling
                  render={() => (
                    <ButtonToggle
                      value={preferences.storeType as string}
                      variant="inverse"
                      full
                      onChange={(value) =>
                        setPreferences((prevValue) => ({
                          ...prevValue,
                          storeType: value as StoreTypes,
                        }))
                      }
                    >
                      {STORE_TYPES.map((type) => (
                        <ButtonToggle.Button
                          key={type.value}
                          value={type.value}
                          label={type.label}
                        />
                      ))}
                    </ButtonToggle>
                  )}
                />
                <Modal.ContentDivider color="purple-dark" />
              </>
            )}

            {!hideFullfillmentType && (
              <StyledButtonToggleButton>
                <FieldWrapper
                  name="Availability"
                  label="Availability"
                  disableMobileInputStyling
                  render={() => (
                    <ButtonToggle.Button
                      variant="inverse"
                      value=""
                      selected={preferences.storeAvailability === 'openNow'}
                      label="Open Now"
                      onClick={(e) => {
                        e.currentTarget.blur();
                        setPreferences((prevValue) => ({
                          ...prevValue,
                          storeAvailability:
                            prevValue.storeAvailability === 'openNow'
                              ? undefined
                              : 'openNow',
                        }));
                      }}
                    />
                  )}
                />
              </StyledButtonToggleButton>
            )}

            {!hideRadiusSlider && (
              <Box mt={!hideFullfillmentType ? 24 : 0}>
                <Flex justifyContent="space-between" mb={16}>
                  <Typography color="grays-white">Radius</Typography>
                  <Typography color="grays-light">{`${preferences.storeSearchRadius} mi`}</Typography>
                </Flex>
                <StyledSlider
                  variant="inverse"
                  isDouble={false}
                  onChange={(value: string) =>
                    setPreferences((prevValue) => ({
                      ...prevValue,
                      storeSearchRadius: parseInt(value),
                    }))
                  }
                  defaultValue={preferences.storeSearchRadius || 20}
                  endUnit="mi"
                  min={5}
                  max={100}
                  step={5}
                  label="Radius"
                  labelHidden
                  name="radius"
                />
              </Box>
            )}
          </PreferencesWrapper>

          <Button
            disabled={loading}
            loading={loading}
            mt={40}
            full
            variant="primary-inverse"
            type="submit"
            onClick={handleSubmit}
            label="Update"
          />
        </Modal.Content>
      </Modal>

      {!!error && (
        <Modal
          variant="dialogue"
          onRequestClose={() => setError(null)}
          open={!!error}
        >
          <Modal.Content>
            <UnservedLocationModalContent error={error as LocationErrors} />

            <Button
              onClick={() => setError(null)}
              label={error === 'deliveryAddress' ? 'Okay' : 'Go Back'}
              full
              mt={24}
            />
          </Modal.Content>
        </Modal>
      )}
    </>
  );
};
