import {
  Box,
  Flex,
  Skeleton,
  useDesktopMediaQuery,
  useMobileMediaQuery,
} from '@jane/shared/reefer';

import {
  FilterBarWrapper,
  ProductsGrid,
  RowWrapper,
} from './loadingWrapper.styles';

interface SkeletonProps {
  desktopCardWidth?: number;
  height?: number;
  length?: number;
  mobileCardWidth?: number;
}

export const FilterSkeleton = () => {
  return (
    <Box>
      <FilterBarWrapper>
        <Flex>
          <Skeleton.Bone height="48px" width="48px" mr={16} mb={16} />
          <Skeleton.Bone height="48px" width="48px" mr={16} mb={16} />
          <Skeleton.Bone height="48px" width="150px" mr={16} mb={16} />
          <Skeleton.Bone height="48px" width="150px" mr={16} mb={16} />
          <Skeleton.Bone height="48px" width="150px" mr={16} mb={16} />
        </Flex>

        <Flex justifyContent="flex-end">
          <Skeleton.Bone height="48px" width="150px" mr={16} />
          <Skeleton.Bone height="48px" width="150px" />
        </Flex>
      </FilterBarWrapper>
    </Box>
  );
};

export const ProductGridSkeleton = ({
  desktopCardWidth = 200,
  height = 375,
  length = 18,
  mobileCardWidth = 150,
}: SkeletonProps) => (
  <ProductsGrid
    desktopCardWidth={desktopCardWidth}
    mobileCardWidth={mobileCardWidth}
  >
    {Array.from({ length }, (_, i) => (
      <Skeleton.Bone height={height} key={i} />
    ))}
  </ProductsGrid>
);

// store cards
// TODO: add height and width props
export const CardRowSkeleton = () => (
  <Box p={16}>
    <RowWrapper>
      {Array.from({ length: 8 }, (_, i) => (
        <Skeleton.Bone height="214px" width="340px" key={i} />
      ))}
    </RowWrapper>
  </Box>
);

export const ProductCardRowSkeleton = () => {
  const isMobile = useMobileMediaQuery({ size: 'md', width: 'min' });
  return (
    <RowWrapper>
      {Array.from({ length: 10 }, (_, i) => (
        <Skeleton.Bone
          height={isMobile ? '425px' : '475px'}
          width={isMobile ? '175px' : '200px'}
          key={i}
        />
      ))}
    </RowWrapper>
  );
};

const CarouselHeader = () => (
  <Flex width="100%" pb={24}>
    <Flex width="50%">
      <Skeleton.Bone height="48px" width="250px" borderRadius="sm" />
    </Flex>

    <Flex width="50%" justifyContent="flex-end" alignItems="center">
      <Skeleton.Bone height="48px" width="100px" borderRadius="lg" />
    </Flex>
  </Flex>
);

/** NOTE: these will replace the above, pending implementation of new carousels */
const CarouselHeaderNew = () => {
  const isMobile = useMobileMediaQuery({});
  return (
    <Flex width="100%" pb={24}>
      <Flex width="50%">
        <Skeleton.Bone
          height="48px"
          width={isMobile ? '150px' : '250px'}
          borderRadius="sm"
        />
      </Flex>
      <Flex width="50%" justifyContent="flex-end" alignItems="center">
        {isMobile ? (
          <Skeleton.Bone height="24px" width="70px" borderRadius="sm" />
        ) : (
          <>
            <Skeleton.Bone height="48px" width="48px" mr={16} />
            <Skeleton.Bone height="48px" width="48px" mr={16} />
            <Skeleton.Bone height="48px" width="90px" />
          </>
        )}
      </Flex>
    </Flex>
  );
};

// Product cards for now
export const CarouselSkeleton = () => (
  <Flex flexDirection="column" width="100%" justifyContent="center" pb={64}>
    <CarouselHeader />
    <ProductCardRowSkeleton />
  </Flex>
);

export const CarouselSkeletonNew = () => (
  <Flex flexDirection="column" width="100%" justifyContent="center" pb={64}>
    <CarouselHeaderNew />
    <ProductCardRowSkeleton />
  </Flex>
);

export const CarouselGridSkeleton = () => (
  <Flex flexDirection="column" width="100%" justifyContent="center" pb={64}>
    <CarouselHeader />
    <ProductGridSkeleton />
  </Flex>
);

export const CarouselListSkeleton = () => {
  return (
    <Flex flexDirection="column" pb={64}>
      <CarouselHeader />
      {Array.from({ length: 8 }, (_, i) => (
        <Skeleton.Bone height="200px" width="100%" key={i} mb={24} />
      ))}
    </Flex>
  );
};

export const ProductDetailDesktop = () => {
  const isDesktop = useDesktopMediaQuery({});
  return (
    <Flex justifyContent="center">
      <Flex flexDirection="column" py={48} width="100%" maxWidth="1024px">
        <Skeleton.Bone height="48px" width="200px" mb={56} />
        <Flex
          width="100%"
          gap={48}
          flexDirection={isDesktop ? 'row' : 'column'}
        >
          <Flex justifyContent="center">
            <Skeleton.Bone height="566px" width="566px" />
          </Flex>
          <Flex flexDirection="column" width="100%" pb={24}>
            <Flex flexDirection="column" height="100%">
              <Skeleton.Bone
                height="48px"
                width="250px"
                borderRadius="sm"
                mb={24}
              />
              <Skeleton.Bone
                height="24px"
                width="150px"
                borderRadius="sm"
                mb={24}
              />
              <Skeleton.Bone mb={12} />
              <Skeleton.Bone mb={12} />
              <Skeleton.Bone mb={12} />
              <Skeleton.Bone mb={12} />
              <Skeleton.Bone mb={12} />
              <Skeleton.Bone mb={12} />
            </Flex>

            <Flex flexDirection="column" height="100%">
              <Skeleton.Bone
                height="24px"
                width="150px"
                borderRadius="sm"
                mb={24}
              />
              <Flex gap={24}>
                <Skeleton.Bone height="48px" width="48px" />
                <Skeleton.Bone height="48px" width="48px" />
                <Skeleton.Bone height="48px" width="48px" />
              </Flex>
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
};

export const ProductDetailMobile = () => (
  <Flex width="100%" flexDirection="column" py={24}>
    <Skeleton.Bone height="48px" width="125px" mb={16} />
    <Skeleton.Bone height="300px" width="100%" mb={24} />
    <Skeleton.Bone height="48px" width="250px" borderRadius="sm" mb={24} />
    <Skeleton.Bone height="24px" width="150px" borderRadius="sm" mb={24} />
    <Skeleton.Bone height="48px" width="100%" my={24} />
  </Flex>
);

export const MenuProductDetailMobile = () => (
  <Flex width="100%" flexDirection="column" py={16}>
    <Flex justifyContent="space-between" pb={48} alignItems="center">
      <Flex gap={16}>
        <Skeleton.Bone height="48px" width="125px" />
        <Skeleton.Bone height="48px" width="72px" />
      </Flex>
      <Skeleton.Bone height="48px" width="48px" borderRadius="sm" />
    </Flex>

    <Flex gap={16} flexWrap="wrap" pb={24} justifyContent="center">
      <Skeleton.Bone height="48px" width="160px" />
      <Skeleton.Bone height="48px" width="160px" />
      <Skeleton.Bone height="48px" width="160px" />
      <Skeleton.Bone height="48px" width="160px" />
    </Flex>
    <Flex flexDirection="column" pb={48} alignItems="center">
      <Skeleton.Bone height="48px" width="85px" borderRadius="sm" mb={24} />
      <Flex gap={24} pb={48}>
        <Skeleton.Bone height="48px" width="24px" borderRadius="sm" />
        <Skeleton.Bone height="48px" width="24px" borderRadius="sm" />
        <Skeleton.Bone height="48px" width="24px" borderRadius="sm" />
      </Flex>
      <Skeleton.Bone height="48px" width="105px" />
    </Flex>
    <Skeleton.Bone height="300px" width="100%" mb={24} />
    <Skeleton.Bone height="48px" width="250px" borderRadius="sm" mb={24} />
    <Skeleton.Bone height="24px" width="150px" borderRadius="sm" mb={24} />
    <Skeleton.Bone height="48px" width="100%" my={24} />
  </Flex>
);

export const BlockSkeleton = ({ height }: { height: string | number }) => (
  <Skeleton.Bone height={height} />
);

export const RoundSkeleton = ({ diameter }: { diameter: string | number }) => (
  <Skeleton.Bone height={diameter} width={diameter} borderRadius="circular" />
);
