import type { CSSObject } from '@emotion/react';

import type {
  MarginProperties,
  PaddingProperties,
  Spacing,
} from '@jane/shared/reefer';

const calculateProp = (prop?: Spacing): string | undefined => {
  if (prop === undefined) return;
  return !isNaN(parseInt(prop as string)) ? `${prop}px` : `${prop}`;
};

const margin = ({ m, mx, my, mt, mr, mb, ml }: MarginProperties): CSSObject => {
  if (
    m != null &&
    mx == null &&
    my == null &&
    mt == null &&
    mr == null &&
    mb == null &&
    ml == null
  )
    return { margin: calculateProp(m) };

  return {
    marginBottom: calculateProp(mb ?? my ?? m),
    marginLeft: calculateProp(ml ?? mx ?? m),
    marginRight: calculateProp(mr ?? mx ?? m),
    marginTop: calculateProp(mt ?? my ?? m),
  };
};

const padding = ({
  p,
  px,
  py,
  pt,
  pr,
  pb,
  pl,
}: PaddingProperties): CSSObject => {
  if (
    p != null &&
    px == null &&
    py == null &&
    pt == null &&
    pr == null &&
    pb == null &&
    pl == null
  )
    return { padding: calculateProp(p) };

  return {
    paddingBottom: calculateProp(pb ?? py ?? p),
    paddingLeft: calculateProp(pl ?? px ?? p),
    paddingRight: calculateProp(pr ?? px ?? p),
    paddingTop: calculateProp(pt ?? py ?? p),
  };
};

export const spacing = (
  args: MarginProperties & PaddingProperties
): CSSObject => ({
  ...margin(args),
  ...padding(args),
});
