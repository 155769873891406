import type { SVGProps } from 'react';

import { Icon } from '../../icon/icon';
import type { StaticIconProps } from '../../icon/icon.types';

const TerrierDog = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="64"
    height="64"
    viewBox="0 0 64 64"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <circle cx={32} cy={32} r={32} fill="#CE349A" />
    <circle cx={32} cy={32} r={32} fill="white" fillOpacity={0.8} />
    <path
      d="M12.1399 14.7171C11.5602 12.0552 14.5423 10.0609 16.7809 11.6135L28.3372 19.6285C29.1453 20.189 29.6274 21.1101 29.6274 22.0936V33.1384C29.6274 34.7952 28.2843 36.1384 26.6274 36.1384H19.2226C17.8117 36.1384 16.5915 35.1553 16.2913 33.7768L12.1399 14.7171ZM46.5572 12.2601C48.7701 10.7582 51.6937 12.6838 51.1877 15.31L47.643 33.706C47.3709 35.118 46.1352 36.1384 44.6972 36.1384H37.3726C35.7158 36.1384 34.3726 34.7952 34.3726 33.1384V22.1194C34.3726 21.1252 34.8652 20.1955 35.6879 19.6372L46.5572 12.2601Z"
      fill="#CE349A"
    />
    <path
      d="M12.1399 14.7171C11.5602 12.0552 14.5423 10.0609 16.7809 11.6135L28.3372 19.6285C29.1453 20.189 29.6274 21.1101 29.6274 22.0936V33.1384C29.6274 34.7952 28.2843 36.1384 26.6274 36.1384H19.2226C17.8117 36.1384 16.5915 35.1553 16.2913 33.7768L12.1399 14.7171ZM46.5572 12.2601C48.7701 10.7582 51.6937 12.6838 51.1877 15.31L47.643 33.706C47.3709 35.118 46.1352 36.1384 44.6972 36.1384H37.3726C35.7158 36.1384 34.3726 34.7952 34.3726 33.1384V22.1194C34.3726 21.1252 34.8652 20.1955 35.6879 19.6372L46.5572 12.2601Z"
      fill="black"
      fillOpacity={0.2}
    />
    <path
      d="M12.1399 14.7171C11.5602 12.0552 14.5423 10.0609 16.7809 11.6135L28.3372 19.6285C29.1453 20.189 29.6274 21.1101 29.6274 22.0936V33.1384C29.6274 34.7952 28.2843 36.1384 26.6274 36.1384H19.2226C17.8117 36.1384 16.5915 35.1553 16.2913 33.7768L12.1399 14.7171ZM46.5572 12.2601C48.7701 10.7582 51.6937 12.6838 51.1877 15.31L47.643 33.706C47.3709 35.118 46.1352 36.1384 44.6972 36.1384H37.3726C35.7158 36.1384 34.3726 34.7952 34.3726 33.1384V22.1194C34.3726 21.1252 34.8652 20.1955 35.6879 19.6372L46.5572 12.2601Z"
      stroke="#CE349A"
      strokeWidth={2}
    />
    <circle cx={32} cy={36} r={18} fill="#CE349A" />
    <circle cx={20} cy={34} r={6} fill="white" />
    <circle cx={44} cy={34} r={6} fill="white" />
    <circle cx={20} cy={34} r={4} fill="#FFC220" />
    <circle cx={44} cy={34} r={4} fill="#FFC220" />
    <circle cx={20} cy={34} r={2} fill="#0E0E0E" />
    <circle cx={44} cy={34} r={2} fill="#0E0E0E" />
    <circle cx={21} cy={33} r={1} fill="white" />
    <circle cx={45} cy={33} r={1} fill="white" />
    <path
      d="M32.7314 49.2532C29.8497 55.2342 25.3512 58.5254 22.6837 56.6043C20.0162 54.6832 20.1898 48.2773 23.0714 42.2963C25.9531 36.3153 30.4515 33.0241 33.1191 34.9452C35.7866 36.8663 35.613 43.2722 32.7314 49.2532Z"
      fill="#CE349A"
    />
    <path
      d="M32.7314 49.2532C29.8497 55.2342 25.3512 58.5254 22.6837 56.6043C20.0162 54.6832 20.1898 48.2773 23.0714 42.2963C25.9531 36.3153 30.4515 33.0241 33.1191 34.9452C35.7866 36.8663 35.613 43.2722 32.7314 49.2532Z"
      fill="black"
      fillOpacity={0.2}
    />
    <path
      d="M31.0711 49.2532C33.9528 55.2342 38.4512 58.5254 41.1188 56.6043C43.7863 54.6832 43.6127 48.2773 40.7311 42.2963C37.8494 36.3153 33.3509 33.0241 30.6834 34.9452C28.0159 36.8663 28.1895 43.2722 31.0711 49.2532Z"
      fill="#CE349A"
    />
    <path
      d="M31.0711 49.2532C33.9528 55.2342 38.4512 58.5254 41.1188 56.6043C43.7863 54.6832 43.6127 48.2773 40.7311 42.2963C37.8494 36.3153 33.3509 33.0241 30.6834 34.9452C28.0159 36.8663 28.1895 43.2722 31.0711 49.2532Z"
      fill="black"
      fillOpacity={0.2}
    />
    <ellipse cx={32} cy={41} rx={4} ry={2} fill="#0E0E0E" />
  </svg>
);
export const TerrierDogAvatar = ({
  size = 'xl',
  ...props
}: StaticIconProps) => {
  return <Icon icon={TerrierDog} {...props} size={size} color={undefined} />;
};
