import {
  Flex,
  JaneLogo,
  Link,
  Typography,
  useMobileMediaQuery,
} from '@jane/shared/reefer';

import { StyledWrapper } from './poweredByJane.styles';

export interface PoweredByJaneProps {
  /** Align 'left' or 'center', for absolute positioning only */
  alignment?: 'left' | 'center';

  /** Color of text and logo, defaults to 'black' */
  color?: 'black' | 'white';

  /** Determines component position, 'static' or 'absolute' */
  position?: 'static' | 'absolute';
}

export const PoweredByJane = ({
  color = 'black',
  position = 'static',
  alignment,
}: PoweredByJaneProps) => {
  const isMobile = useMobileMediaQuery({});
  return (
    <StyledWrapper alignment={alignment} position={position}>
      <Link
        variant="minimal"
        href="https://www.iheartjane.com"
        title="Powered By Jane"
        ariaLabel="Powered By Jane"
      >
        <Flex alignItems="center">
          {!isMobile && (
            <Typography
              variant="mini-bold"
              color={color === 'white' ? 'grays-white' : 'grays-black'}
              mt={8}
            >
              Powered By
            </Typography>
          )}
          <JaneLogo
            variant="secondary"
            size="xs"
            color={color === 'white' ? 'white' : 'black'}
          />
        </Flex>
      </Link>
    </StyledWrapper>
  );
};
