export const updateSortSuffix = (value: string, weightFilters: string[]) => {
  let newSortSuffix = value;
  const sortedDesc = newSortSuffix.includes('desc');
  const sortedByPrice = newSortSuffix.includes('price');

  if (sortedByPrice) {
    if (weightFilters?.length) {
      const weight = weightFilters[0].replace(' ', '-');

      newSortSuffix = sortedDesc
        ? `by-price-${weight}-desc-`
        : `by-price-${weight}-`;
    } else {
      newSortSuffix = sortedDesc ? `by-price-desc-` : `by-price-`;
    }
  }

  return newSortSuffix;
};

export const updateSortSuffixOnChange = (
  sortSuffix: string,
  weightFilter: string,
  isAddingFilter: boolean,
  currentWeightFilters?: string[]
) => {
  let newSortSuffix = sortSuffix;
  const sortedDesc = newSortSuffix.includes('desc');
  const sortedByPrice = newSortSuffix.includes('price');

  if (sortedByPrice) {
    // if adding weight filter, format and add to sortSuffix
    if (isAddingFilter) {
      const weight = weightFilter.replace(' ', '-');

      newSortSuffix = sortedDesc
        ? `by-price-${weight}-desc-`
        : `by-price-${weight}-`;
    } else {
      // if removing weight filter, but still filtering by weight
      // format and add the next weight to sortSuffix
      if (currentWeightFilters && currentWeightFilters?.length) {
        const nextWeight = currentWeightFilters?.shift();
        const weightToIndex = nextWeight?.replace(' ', '-');
        newSortSuffix = sortedDesc
          ? `by-price-${weightToIndex}-desc-`
          : `by-price-${weightToIndex}-`;
      } else {
        // if no longer filtering by weight, return sortSuffix to default
        newSortSuffix = sortedDesc ? 'by-price-desc-' : 'by-price-';
      }
    }
  }

  return newSortSuffix;
};
