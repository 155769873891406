import type { SVGProps } from 'react';

import { Icon } from '../../icon';
import type { LogoProps } from '../logo.types';

const PoweredByAeropay = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 176 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M111.253 16.0405V15.6139L111.52 15.2405L111.573 15.0805C111.467 15.1872 111.307 15.3472 111.2 15.4539C110.56 15.9339 109.707 16.2005 108.693 16.2005C108.107 16.2005 107.52 16.0939 106.987 15.8272C106.453 15.5605 105.92 15.2939 105.547 14.8672C105.12 14.4405 104.8 13.9605 104.587 13.4272C104.373 12.8939 104.267 12.3072 104.267 11.7205C104.267 11.1339 104.373 10.5472 104.587 9.96054C104.8 9.37387 105.173 8.89387 105.6 8.46721C105.973 8.04054 106.507 7.66721 107.04 7.45387C107.573 7.24054 108.16 7.08054 108.747 7.13387C109.813 7.13387 110.667 7.40054 111.307 7.93387C111.413 8.04054 111.573 8.14721 111.627 8.20054L111.573 8.09387L111.307 7.72054V7.18721H113.707V16.0405H111.253ZM108.853 9.26721C108.533 9.26721 108.213 9.32054 107.947 9.42721C107.68 9.53387 107.413 9.74721 107.2 9.96054C106.773 10.4405 106.56 11.0272 106.56 11.6672C106.56 12.3072 106.827 12.8939 107.2 13.3739C107.413 13.5872 107.68 13.7472 107.947 13.9072C108.213 14.0139 108.533 14.0672 108.853 14.0672C109.173 14.0672 109.493 14.0139 109.76 13.9072C110.027 13.8005 110.347 13.6405 110.56 13.3739C110.773 13.1605 110.933 12.8939 111.093 12.5739C111.2 12.3072 111.253 11.9872 111.253 11.6672C111.253 11.3472 111.2 11.0272 111.093 10.7605C110.987 10.4939 110.827 10.2272 110.56 9.96054C110.347 9.74721 110.08 9.58721 109.76 9.42721C109.493 9.32054 109.173 9.26721 108.853 9.26721Z"
      fill="black"
    />
    <path
      d="M123.36 14.1205C122.453 15.5072 121.013 16.2005 119.146 16.2005C117.76 16.2005 116.64 15.7739 115.733 14.8672C114.88 13.9605 114.453 12.7872 114.453 11.5605C114.453 10.3339 114.933 9.16053 115.786 8.25387C116.693 7.3472 117.76 6.92053 119.146 6.92053C120.533 6.92053 121.6 7.3472 122.453 8.20053C123.253 9.05387 123.68 10.1205 123.68 11.4539C123.68 11.5605 123.68 11.6672 123.68 11.8272C123.68 11.9872 123.68 12.0939 123.68 12.1472C123.68 12.2005 123.68 12.3072 123.68 12.3072H116.8C116.906 12.8405 117.173 13.3205 117.6 13.6405C118.026 13.9605 118.56 14.1739 119.146 14.1205C119.626 14.1205 120.053 14.0139 120.48 13.8539C120.906 13.6405 121.226 13.3205 121.493 12.9472L123.36 14.1205ZM119.146 9.00053C117.973 9.00053 117.28 9.53387 116.906 10.6005H121.226C121.12 10.1205 120.853 9.69387 120.48 9.4272C120.106 9.1072 119.626 8.9472 119.146 9.00053Z"
      fill="black"
    />
    <path
      d="M126.933 16.0405H124.533V7.18719H126.933V8.14719L126.827 8.57386C126.827 8.57386 126.933 8.36053 126.987 8.36053C127.253 7.93386 127.627 7.61386 128.107 7.40053C128.533 7.18719 129.067 7.02719 129.547 7.08053V9.48053C128.693 9.48053 128.053 9.69386 127.627 10.1205C127.2 10.5472 126.987 11.1872 126.987 11.9872L126.933 16.0405Z"
      fill="black"
    />
    <path
      d="M134.293 7.02719C134.933 7.02719 135.573 7.13385 136.16 7.34719C136.746 7.56052 137.28 7.93385 137.76 8.36052C138.186 8.78719 138.56 9.32052 138.773 9.85385C138.986 10.3872 139.146 11.0272 139.146 11.6139C139.146 12.2539 139.04 12.8405 138.773 13.3739C138.506 13.9072 138.186 14.4405 137.76 14.8672C136.853 15.7205 135.626 16.2005 134.346 16.2005C133.066 16.2005 131.84 15.7205 130.933 14.8672C130.506 14.4405 130.133 13.9072 129.92 13.3739C129.653 12.8405 129.546 12.2005 129.546 11.6139C129.546 10.9739 129.653 10.3872 129.92 9.85385C130.186 9.32052 130.506 8.78719 130.933 8.36052C131.36 7.93385 131.893 7.56052 132.48 7.34719C133.066 7.08052 133.706 6.97385 134.293 7.02719ZM136.053 9.90719C135.626 9.48052 134.986 9.21385 134.346 9.21385C133.706 9.21385 133.12 9.48052 132.64 9.90719C132.426 10.1205 132.266 10.4405 132.106 10.7072C132 11.0272 131.946 11.3472 131.946 11.6672C131.946 11.9872 132 12.3072 132.106 12.6272C132.213 12.9472 132.373 13.2139 132.64 13.4272C132.853 13.6405 133.12 13.8005 133.386 13.9605C133.653 14.0672 133.973 14.1205 134.293 14.1205C134.613 14.1205 134.933 14.0672 135.2 13.9605C135.466 13.8539 135.786 13.6405 136 13.4272C136.426 12.9472 136.693 12.3072 136.693 11.6672C136.693 10.9739 136.48 10.3872 136.053 9.90719Z"
      fill="black"
    />
    <path
      d="M144.64 16.2005C144.053 16.2005 143.467 16.0939 142.933 15.8805C142.4 15.6672 141.92 15.3472 141.493 14.9205L141.333 14.7072L141.493 15.1339V19.0805H140V7.34719L141.547 7.18719V8.36053L141.44 8.73386L141.547 8.52053C142.347 7.6672 143.36 7.18719 144.693 7.18719C145.28 7.18719 145.867 7.29386 146.4 7.50719C146.933 7.72053 147.413 8.04053 147.84 8.46719C148.693 9.32053 149.173 10.4405 149.173 11.6672C149.173 12.8939 148.693 14.0139 147.84 14.8672C147.467 15.2939 146.933 15.6139 146.4 15.8272C145.813 16.0939 145.227 16.2005 144.64 16.2005ZM144.587 8.57386C143.787 8.57386 142.987 8.89386 142.4 9.48053C141.813 10.0672 141.493 10.8672 141.493 11.6672C141.493 12.4672 141.813 13.2672 142.4 13.8539C142.987 14.4405 143.787 14.7605 144.587 14.7605C144.96 14.7605 145.387 14.7072 145.76 14.5472C146.133 14.3872 146.453 14.1739 146.773 13.8539C147.307 13.2672 147.627 12.4672 147.627 11.6672C147.627 10.8672 147.307 10.0672 146.773 9.48053C146.507 9.21386 146.133 8.94719 145.76 8.78719C145.387 8.68053 144.96 8.57386 144.587 8.57386Z"
      fill="black"
    />
    <path
      d="M157.493 16.0405V14.8672C156.693 15.7739 155.627 16.2005 154.347 16.2005C153.76 16.2005 153.173 16.0939 152.64 15.8805C152.107 15.6672 151.627 15.3472 151.2 14.9205C150.347 14.0672 149.867 12.9472 149.867 11.7205C149.867 10.4939 150.347 9.37387 151.2 8.52054C151.573 8.09387 152.107 7.77387 152.64 7.56054C153.173 7.34721 153.76 7.24054 154.347 7.24054C154.933 7.24054 155.52 7.34721 156.053 7.56054C156.587 7.77387 157.12 8.09387 157.493 8.52054V7.29387H158.987V16.0405H157.493ZM154.453 8.57387C154.08 8.57387 153.653 8.62721 153.28 8.78721C152.907 8.94721 152.587 9.16054 152.267 9.48054C151.733 10.0672 151.413 10.8672 151.413 11.6672C151.413 12.4672 151.733 13.2672 152.267 13.8539C152.533 14.1205 152.907 14.3872 153.28 14.5472C153.653 14.7072 154.08 14.7605 154.453 14.7605C155.253 14.7605 156.053 14.4405 156.64 13.8539C157.227 13.2672 157.547 12.4672 157.547 11.6672C157.547 10.8672 157.227 10.0672 156.64 9.48054C156.053 8.94721 155.253 8.57387 154.453 8.57387Z"
      fill="black"
    />
    <path
      d="M168 7.29388L164.907 15.8272C164.48 17.0006 164 17.8006 163.36 18.3339C162.72 18.8672 161.92 19.0806 160.96 19.0806H160.587V17.6939H160.96C162.027 17.6939 162.827 17.1072 163.307 15.9339L159.787 7.34722H161.493L164.053 13.9606L166.4 7.29388H168Z"
      fill="black"
    />
    <path
      d="M87.6265 12.7872C88.1599 12.5205 97.0665 7.50717 97.2799 7.4005C97.3332 7.34717 97.4399 7.29384 97.5465 7.29384C97.6532 7.2405 97.7599 7.29384 97.8132 7.29384C97.8665 7.34717 97.9199 7.45384 97.8665 7.5605C97.8132 7.61384 97.7599 7.7205 97.7065 7.77384C97.5999 7.82717 88.2132 13.6938 87.8399 13.9605C87.5732 14.1205 87.3065 14.3338 87.3065 14.6538C87.3065 14.8138 87.3599 14.9738 87.4665 15.0805C87.5732 15.1872 87.7332 15.2405 87.8932 15.2405C88.0532 15.2405 88.2132 15.2405 88.3732 15.2405C88.8532 15.2405 89.3332 15.4005 89.7065 15.7205C90.1332 16.0938 90.5065 16.5205 90.7732 17.0538C90.9332 17.3205 91.2532 17.8538 91.6265 17.8538H91.7332C92.0532 17.8538 92.3732 17.5872 92.5332 17.2672C93.4399 16.0938 100.96 6.01384 101.28 5.53384C101.387 5.37384 101.387 5.0005 101.067 5.0005C100.853 4.94717 86.6132 9.1605 85.9732 9.37384L85.2265 9.6405C85.1732 9.6405 85.1199 9.69384 85.0132 9.69384L84.7999 9.74717C84.6932 9.8005 84.5332 9.8005 84.4265 9.85384C84.1599 9.9605 83.8399 10.0672 83.6265 10.1738C83.5732 10.2272 83.4665 10.2805 83.4132 10.3338C83.3599 10.3872 83.3599 10.4938 83.3599 10.6005C83.3599 10.8138 83.4665 10.9738 83.6799 11.0272C83.9465 11.1338 84.2132 11.2405 84.4799 11.3472C85.2799 11.6138 85.9199 12.0938 86.4532 12.7338C86.5599 12.8405 86.6132 13.0005 86.8265 13.0005C86.9332 13.0005 87.0932 13.0005 87.1999 12.9472L87.6265 12.7872Z"
      fill="#24C3D2"
    />
    <path
      d="M8 15.1338V5.6405H11.7867C11.8933 5.6405 12 5.6405 12.1067 5.6405C12.2133 5.6405 12.3733 5.6405 12.48 5.69384C13.0133 5.74717 13.44 5.9605 13.76 6.22717C14.1333 6.49384 14.4 6.86717 14.56 7.2405C14.72 7.61384 14.8267 8.09384 14.8267 8.62717C14.8267 9.10717 14.72 9.58717 14.56 10.0138C14.4 10.4405 14.1333 10.7605 13.76 11.0272C13.3867 11.2938 12.96 11.4538 12.48 11.5605C12.3733 11.5605 12.2133 11.6138 12.1067 11.6138C12 11.6138 11.8933 11.6138 11.7867 11.6138H9.17333V15.1338H8ZM9.17333 10.4938H11.7333C11.84 10.4938 11.8933 10.4938 12 10.4938C12.1067 10.4938 12.2133 10.4938 12.32 10.4405C12.64 10.3872 12.8533 10.2272 13.0667 10.0672C13.28 9.85384 13.3867 9.6405 13.4933 9.42717C13.6 9.1605 13.6 8.89384 13.6 8.62717C13.6 8.3605 13.5467 8.09384 13.4933 7.82717C13.3867 7.61384 13.2267 7.4005 13.0667 7.18717C12.8533 6.97384 12.64 6.86717 12.32 6.81384C12.2133 6.81384 12.1067 6.7605 12 6.7605C11.8933 6.7605 11.7867 6.7605 11.7333 6.7605H9.17333V10.4938Z"
      fill="black"
    />
    <path
      d="M19.0933 15.3471C18.3999 15.3471 17.7599 15.1871 17.2799 14.8671C16.7466 14.5471 16.3733 14.1205 16.1066 13.5338C15.8399 12.9471 15.6799 12.3071 15.6799 11.5605C15.6799 10.8138 15.8399 10.1738 16.1066 9.58714C16.3733 9.00047 16.7999 8.5738 17.2799 8.2538C17.7599 7.9338 18.3999 7.7738 19.0933 7.7738C19.7866 7.7738 20.4266 7.9338 20.9599 8.2538C21.4933 8.5738 21.8666 9.00047 22.1333 9.58714C22.3999 10.1738 22.5599 10.8138 22.5599 11.5605C22.5599 12.3071 22.3999 12.9471 22.1333 13.5338C21.8666 14.1205 21.4399 14.5471 20.9599 14.8671C20.4799 15.1871 19.8399 15.3471 19.0933 15.3471ZM19.0933 14.2271C19.8399 14.2271 20.3733 13.9605 20.7466 13.4805C21.1199 13.0005 21.2799 12.3605 21.2799 11.5605C21.2799 10.7605 21.1199 10.1205 20.7466 9.64047C20.3733 9.16047 19.8399 8.94714 19.0933 8.94714C18.6133 8.94714 18.1866 9.0538 17.8666 9.26714C17.5466 9.48047 17.2799 9.80047 17.1199 10.1738C16.9599 10.5471 16.9066 11.0271 16.9066 11.5605C16.9066 12.3605 17.0666 13.0005 17.4399 13.4805C17.8666 13.9605 18.3999 14.2271 19.0933 14.2271Z"
      fill="black"
    />
    <path
      d="M25.2799 15.1338L23.0933 7.98718H24.2666L25.8666 13.2138L27.4666 7.98718H28.4799L30.0799 13.2138L31.6799 7.98718H32.8533L30.6666 15.1338H29.7066L27.9999 9.69385L26.2933 15.1338H25.2799Z"
      fill="black"
    />
    <path
      d="M36.8534 15.3471C36.1601 15.3471 35.5201 15.1871 35.0401 14.8671C34.5067 14.5471 34.1334 14.1205 33.8134 13.5871C33.5467 13.0538 33.3867 12.3605 33.3867 11.6138C33.3867 10.8138 33.5467 10.1738 33.8134 9.58714C34.0801 9.00047 34.5067 8.5738 34.9867 8.2538C35.5201 7.9338 36.1067 7.7738 36.8001 7.7738C37.4934 7.7738 38.1334 7.9338 38.6134 8.2538C39.0934 8.5738 39.5201 9.0538 39.7334 9.6938C40.0001 10.2805 40.1067 11.0271 40.0534 11.8805H38.9334V11.4538C38.9334 10.6005 38.7201 9.90714 38.4001 9.48047C38.0801 9.0538 37.5467 8.84047 36.8534 8.84047C36.1067 8.84047 35.5734 9.0538 35.2001 9.5338C34.8267 10.0138 34.6134 10.6538 34.6134 11.5605C34.6134 12.4138 34.8267 13.0538 35.2001 13.5338C35.5734 14.0138 36.1067 14.2271 36.8001 14.2271C37.2801 14.2271 37.7067 14.1205 38.0267 13.9071C38.4001 13.6938 38.6667 13.3738 38.8267 13.0005L39.9467 13.3738C39.6801 14.0138 39.2534 14.4938 38.7201 14.8138C38.1867 15.1871 37.5467 15.3471 36.8534 15.3471ZM34.2401 11.8805V10.9205H39.5201V11.8805H34.2401Z"
      fill="black"
    />
    <path
      d="M41.6001 15.1338V7.98717H42.6668V9.69384L42.5068 9.4805C42.6134 9.26717 42.7201 9.05384 42.8268 8.89384C42.9868 8.73384 43.0934 8.57384 43.2534 8.41384C43.4134 8.25384 43.6268 8.14717 43.8934 8.09384C44.1068 8.0405 44.3734 7.98717 44.5868 7.93384C44.8534 7.93384 45.0668 7.93384 45.2801 7.98717V9.10717C45.0134 9.05384 44.7468 9.0005 44.4801 9.05384C44.1601 9.10717 43.8934 9.21384 43.6801 9.37384C43.4668 9.53384 43.2534 9.74717 43.1468 9.9605C43.0401 10.1738 42.9334 10.4405 42.8801 10.7072C42.8268 10.9738 42.8268 11.2405 42.8268 11.5605V15.1338H41.6001Z"
      fill="black"
    />
    <path
      d="M49.4935 15.3471C48.8002 15.3471 48.1602 15.1871 47.6802 14.8671C47.1469 14.5471 46.7735 14.1205 46.4535 13.5871C46.1869 13.0538 46.0269 12.3605 46.0269 11.6138C46.0269 10.8138 46.1869 10.1738 46.4535 9.58714C46.7202 9.00047 47.1469 8.5738 47.6269 8.2538C48.1602 7.9338 48.7469 7.7738 49.4402 7.7738C50.1335 7.7738 50.7735 7.9338 51.2535 8.2538C51.7335 8.5738 52.1602 9.0538 52.3735 9.6938C52.6402 10.2805 52.7469 11.0271 52.6935 11.8805H51.5202V11.4538C51.5202 10.6005 51.3069 9.90714 50.9869 9.48047C50.6669 9.10714 50.1335 8.84047 49.4935 8.84047C48.7469 8.84047 48.2135 9.0538 47.8402 9.5338C47.4669 10.0138 47.2535 10.6538 47.2535 11.5605C47.2535 12.4138 47.4669 13.0538 47.8402 13.5338C48.2135 14.0138 48.7469 14.2271 49.4402 14.2271C49.9202 14.2271 50.3469 14.1205 50.6669 13.9071C51.0402 13.6938 51.3069 13.3738 51.4669 13.0005L52.5869 13.3738C52.3202 14.0138 51.8935 14.4938 51.3602 14.8138C50.8269 15.1871 50.1869 15.3471 49.4935 15.3471ZM46.8269 11.8805V10.9205H52.1069V11.8805H46.8269Z"
      fill="black"
    />
    <path
      d="M57.0135 15.3472C56.3735 15.3472 55.7868 15.1872 55.3068 14.8672C54.8268 14.5472 54.4535 14.0672 54.1868 13.5338C53.9201 12.9472 53.8135 12.3072 53.8135 11.6138C53.8135 10.9205 53.9201 10.2805 54.1868 9.69382C54.4535 9.10716 54.8268 8.68049 55.3068 8.36049C55.7868 8.04049 56.3735 7.88049 57.0135 7.88049C57.7068 7.88049 58.2401 8.04049 58.7201 8.36049C59.2001 8.68049 59.5201 9.10716 59.7868 9.69382C60.0001 10.2805 60.1601 10.9205 60.1601 11.6138C60.1601 12.3072 60.0535 12.9472 59.7868 13.5338C59.5735 14.1205 59.2001 14.5472 58.7201 14.8672C58.2401 15.1872 57.6535 15.3472 57.0135 15.3472ZM57.1201 14.2805C57.6001 14.2805 57.9735 14.1738 58.2935 13.9072C58.6135 13.6938 58.8268 13.3738 58.9868 12.9472C59.1468 12.5205 59.2001 12.0938 59.2001 11.5605C59.2001 11.0272 59.1468 10.5472 58.9868 10.1738C58.8268 9.74716 58.6135 9.48049 58.2935 9.21382C57.9735 8.94716 57.6001 8.89382 57.1201 8.89382C56.6401 8.89382 56.2135 9.00049 55.9468 9.26716C55.6268 9.48049 55.4135 9.80049 55.2535 10.2272C55.0935 10.6538 55.0401 11.0805 55.0401 11.6138C55.0401 12.0938 55.0935 12.5738 55.2535 13.0005C55.4135 13.4272 55.6268 13.7472 55.9468 13.9605C56.2668 14.1205 56.6935 14.2805 57.1201 14.2805ZM59.2535 15.1338V9.85382H59.1468V5.58716H60.2668V15.0805H59.2535V15.1338Z"
      fill="black"
    />
    <path
      d="M64.7998 15.1338V5.6405H65.9731V9.90717H65.8665V15.1872H64.7998V15.1338ZM68.0531 15.3472C67.3598 15.3472 66.8265 15.1872 66.3465 14.8672C65.8665 14.5472 65.5465 14.0672 65.2798 13.5338C65.0665 12.9472 64.9065 12.3072 64.9065 11.6138C64.9065 10.9205 65.0131 10.2272 65.2798 9.69384C65.4931 9.10717 65.8665 8.6805 66.3465 8.3605C66.8265 8.0405 67.3598 7.8805 68.0531 7.8805C68.6931 7.8805 69.2798 8.0405 69.7598 8.3605C70.2398 8.6805 70.6131 9.1605 70.8798 9.69384C71.1465 10.2805 71.2531 10.9205 71.2531 11.6138C71.2531 12.3072 71.1465 12.9472 70.8798 13.5338C70.6131 14.1205 70.2398 14.5472 69.7598 14.8672C69.2798 15.1872 68.7465 15.3472 68.0531 15.3472ZM67.8931 14.2805C68.3731 14.2805 68.7465 14.1738 69.0665 13.9072C69.3865 13.6938 69.5998 13.3205 69.7598 12.9472C69.9198 12.5205 69.9731 12.0938 69.9731 11.5605C69.9731 11.0805 69.9198 10.6005 69.7598 10.1738C69.5998 9.74717 69.3865 9.42717 69.0665 9.21384C68.7465 9.0005 68.3731 8.8405 67.8931 8.8405C67.4131 8.8405 67.0398 8.94717 66.7198 9.1605C66.3998 9.37384 66.1865 9.69384 66.0265 10.1205C65.8665 10.5472 65.8131 10.9738 65.8131 11.5072C65.8131 12.0405 65.8665 12.4672 66.0265 12.8938C66.1865 13.3205 66.3998 13.6405 66.7198 13.8538C67.0398 14.1738 67.4131 14.2805 67.8931 14.2805Z"
      fill="black"
    />
    <path
      d="M73.3332 18.3339L74.7199 14.6005V15.7205L71.5732 8.04053H72.7999L75.2532 14.2272H74.8266L77.1199 8.04053H78.2932L74.3999 18.3339H73.3332Z"
      fill="black"
    />
  </svg>
);
export const PoweredByAeropayLogo = ({
  height = 48,
  width = 'auto',
  ...props
}: LogoProps) => {
  return (
    <Icon
      height={height}
      width={width}
      {...props}
      icon={PoweredByAeropay}
      color={undefined}
      rotate={undefined}
      size={undefined}
    />
  );
};
