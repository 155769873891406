import { useJaneGoldData } from '@jane/shared-ecomm/hooks';
import {
  Box,
  Button,
  Flex,
  JaneGoldLogo,
  Skeleton,
  Typography,
} from '@jane/shared/reefer';
import { getJaneGoldFormattedLabel } from '@jane/shared/util';

import type { JaneGoldCartCalloutProps } from '../janeGoldCallout.types';
import { JaneGoldFeeBreakdown } from '../janeGoldFeeBreakdown';
import { JaneGoldLearnMoreLink } from '../janeGoldLearnMoreLink';
import { JaneGoldTermsLink } from '../janeGoldTermsLink';

/**
 * Checkout variant of the Jane Gold callout action component. If the user doesn't have a linked bank, it will display a Jane Gold Logo `Learn more` link and `Link bank account` button. If the user has a linked bank, it will list the linked bank information and hide the `Link bank account` button.
 */
export const JaneGoldCheckoutCallout = ({
  buttonLabel,
  description,
  discountLabel,
  feeLabel,
  hasBreakdown,
  headlessLoginUrl,
  onLinkAccountClick,
  title,
  totalLabel,
}: JaneGoldCartCalloutProps) => {
  const { isGuestUser, isLoading, selectedBankAccount, userHasLinkedBank } =
    useJaneGoldData();

  const formattedLabel =
    typeof discountLabel === 'number'
      ? getJaneGoldFormattedLabel(discountLabel)
      : discountLabel;
  const shouldShowHeadlessLogin = isGuestUser && !!headlessLoginUrl;

  return (
    <>
      {hasBreakdown ? (
        <>
          <Flex alignItems="center" justifyContent="space-between" pb={20}>
            <Typography as="h2" branded variant="header-bold">
              {title}
            </Typography>
            <JaneGoldLogo altText="Jane Gold logo" height={26} ml={16} />
          </Flex>
          <JaneGoldFeeBreakdown
            buttonLabel={buttonLabel}
            description={description}
            discountLabel={discountLabel}
            feeLabel={feeLabel}
            onLinkAccountClick={onLinkAccountClick}
            totalLabel={totalLabel}
            variant="checkout"
          />
        </>
      ) : (
        <>
          <Box>
            <Typography mb={20} as="h2" branded variant="header-bold">
              {title}
            </Typography>
          </Box>
          <Flex flexDirection="column">
            <JaneGoldLogo altText="Jane Gold logo" mr="auto" />
            <Typography as="span" variant="body">
              {description}
              &nbsp;
              {!userHasLinkedBank ? (
                <JaneGoldTermsLink />
              ) : (
                <JaneGoldLearnMoreLink userHasLinkedBank />
              )}
            </Typography>
            <Box
              mt={24}
              p={!isLoading ? 24 : 0}
              border="grays-light"
              borderRadius="lg"
            >
              {isLoading && (
                <Skeleton animate>
                  <Skeleton.Bone height="81px" />
                </Skeleton>
              )}

              {!isLoading && shouldShowHeadlessLogin && (
                <Flex justifyContent="center">
                  <Button
                    href={headlessLoginUrl}
                    variant="secondary"
                    label="Sign in to link bank account"
                  />
                </Flex>
              )}

              {!(isLoading || shouldShowHeadlessLogin) && (
                <Flex
                  width={'100%'}
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Typography branded variant="header-bold" mr={8}>
                    {formattedLabel}
                  </Typography>
                  {userHasLinkedBank ? (
                    <Flex
                      justifyContent="flex-end"
                      flexDirection="row"
                      flexWrap="wrap"
                    >
                      {selectedBankAccount?.bank_name && (
                        <>
                          <Typography textAlign="right">{`To ${selectedBankAccount?.bank_name},`}</Typography>
                          &nbsp;
                        </>
                      )}
                      <Typography textAlign="right">
                        {`${!selectedBankAccount?.bank_name ? 'To ' : ''} ${
                          selectedBankAccount?.name || 'linked account'
                        }`}
                      </Typography>
                    </Flex>
                  ) : (
                    <Button
                      onClick={onLinkAccountClick}
                      variant="secondary"
                      label="Link bank account"
                    />
                  )}
                </Flex>
              )}
            </Box>
          </Flex>
        </>
      )}
    </>
  );
};
