import { useTheme } from '@emotion/react';
import styled from '@emotion/styled';

import { Flex } from '@jane/shared/reefer';

import { SvgIcon } from '../../component-library/svg-icon';

const Validation = styled(Flex)({
  borderRadius: '50%',
});

export const ValidationIcon = () => {
  const theme = useTheme();
  return (
    <Validation
      ml={16}
      alignItems="center"
      justifyContent="center"
      height={24}
      width={24}
    >
      <SvgIcon
        color={theme.colors.system.positive.main}
        icon="circleCheckFilled"
        size={24}
      />
    </Validation>
  );
};
