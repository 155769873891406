import type {
  DeepReadonly,
  PriceId,
  ProductWeight,
  ReservationCartProduct,
  WeightSelectorWeight,
} from '@jane/shared/models';
import { EQUIVALENCY_TYPE, EQUIVALENCY_UNIT } from '@jane/shared/models';
import type {
  Cart,
  CartLimitRule,
  CartLimitRuleReport,
  EmptyCart,
  Id,
  PendingCartProduct,
  Store,
} from '@jane/shared/types';

type PriceIdToWeightType = { [k in ProductWeight]: number };

export interface CartLimitPolicyReport {
  over: boolean;
  overReports: CartLimitRuleReport[];
  storeName: string;
}

const MILLIGRAMS_PER_OUNCE = 28349.5;
const MILLILITERS_PER_FLUID_OUNCE = 29.5735;

export const PRICE_ID_TO_GRAMS: PriceIdToWeightType = {
  half_gram: 0.5,
  gram: 1.0,
  two_gram: 2.0,
  eighth_ounce: 3.5,
  quarter_ounce: 7,
  half_ounce: 14,
  ounce: 28,
};

const PRICE_ID_TO_MILLIGRAMS: PriceIdToWeightType = {
  half_gram: 500,
  gram: 1000,
  two_gram: 2000,
  eighth_ounce: 3500,
  quarter_ounce: 7000,
  half_ounce: 14000,
  ounce: 28000,
};

export const unitTypeMap: { [arg: string]: string } = {
  g: 'weight',
  oz: 'weight',
  fl_oz: 'volume',
  ml: 'volume',
  mg: 'dosage',
};

const unitMap: { [arg: string]: (val: number) => number } = {
  g: (val: number) => val * 1000,
  oz: (val: number) => val * MILLIGRAMS_PER_OUNCE,
  fl_oz: (val: number) => val * MILLILITERS_PER_FLUID_OUNCE,
  ml: (val: number) => val,
  mg: (val: number) => val,
};

const productTypeKey = (
  productType: string,
  productSubtype: string | null | undefined
): string => {
  let formatted = productType.toLowerCase();
  formatted += productSubtype
    ? ':' + productSubtype.toLowerCase().replace(/\s+/g, '-')
    : '';
  return formatted;
};

const getItemGrams = (product: PendingCartProduct) =>
  product.price_id === 'each'
    ? product.net_weight_grams
    : PRICE_ID_TO_GRAMS[product.price_id];

export const sumEquivalencyTotal = (cart: Cart | EmptyCart) =>
  cart.products.reduce((sum, product) => {
    const { inventories, count, price_id } = product;

    const inventory = inventories?.find(
      (inventory) => inventory.price_id === price_id || inventory.bulk
    );

    const equivalency = inventory?.bulk
      ? getItemGrams(product) || 0
      : inventory?.equivalency || 0;

    return equivalency * count + sum;
  }, 0);

const normalizedQuantityFromPriceId = (product: PendingCartProduct) => {
  const itemMilligrams =
    PRICE_ID_TO_MILLIGRAMS[product.price_id as ProductWeight];
  return itemMilligrams || 0;
};

const getDenormalizedDimension = (
  value?: number | null,
  unit?: string | null
) => {
  return unit && value && denormalizeMap[unit]
    ? denormalizeMap[unit](value)
    : 0;
};

const getNormalizedDimension = (
  value?: number | null,
  unit?: string | null
) => {
  return unit && value && unitMap[unit] ? unitMap[unit](value) : 0;
};

const denormalizeMap: { [arg: string]: (val: number) => number } = {
  g: (val: number) => val / 1000,
  oz: (val: number) => val / MILLIGRAMS_PER_OUNCE,
  fl_oz: (val: number) => val / MILLILITERS_PER_FLUID_OUNCE,
  ml: (val: number) => val,
  mg: (val: number) => val,
};

/*
  iterate over products:
  - get the product's type/subtype
  - if product's subtype matches a subtype-rule, bucket by that subtype's rules
  - else if product's kind matches a type-rule, bucket by that type's rules
*/
const bucketProductsByRule = ({
  products,
  typeLookup,
  subtypeLookup,
}: {
  products: DeepReadonly<PendingCartProduct[]>;
  subtypeLookup: Map<string, Id[]>;
  typeLookup: Map<string, Id[]>;
}) => {
  const productsByRule = new Map();
  products.forEach((product) => {
    const { kind, root_subtype } = product;
    const key = productTypeKey(kind, root_subtype);
    const ruleIds = subtypeLookup.get(key) || typeLookup.get(kind);

    if (ruleIds?.length) {
      ruleIds.forEach((ruleId) => {
        if (!productsByRule.has(ruleId)) productsByRule.set(ruleId, []);
        productsByRule.get(ruleId).push(product);
      });
    }
  });

  return productsByRule;
};

const generateProductTypeLookups = (rules: readonly CartLimitRule[]) => {
  // map each rule's product-subtypes and product-types to their rules
  const subtypeLookup = new Map();
  const typeLookup = new Map();
  rules.forEach((rule) => {
    (rule.product_types || []).forEach((pt) => {
      const ptKey = productTypeKey(pt.product_type, pt.product_subtype);
      if (pt.product_subtype) {
        if (!subtypeLookup.has(ptKey)) subtypeLookup.set(ptKey, []);
        subtypeLookup.get(ptKey).push(rule.id);
      } else {
        if (!typeLookup.has(ptKey)) typeLookup.set(ptKey, []);
        typeLookup.get(ptKey).push(rule.id);
      }
    });
  });

  return { subtypeLookup, typeLookup };
};

const generateCartLimitEquivalencyReport = (
  cart: Cart | EmptyCart,
  store: Store
) => {
  const equivalency_rule = store.cart_limit_policy?.cart_limit_rules.find(
    (rule) => rule.rule_type === EQUIVALENCY_TYPE
  );

  if (!equivalency_rule) return;

  const { limit_value, limit_unit, product_group_name } = equivalency_rule;
  const equivalencySum = sumEquivalencyTotal(cart);

  const mappedEquivalencySum = unitMap[EQUIVALENCY_UNIT](equivalencySum);
  const mappedUnit = unitMap[limit_unit](limit_value);
  const normalizedLimit = getNormalizedDimension(limit_value, limit_unit);

  const limitExceeded = mappedEquivalencySum > mappedUnit;

  if (limitExceeded) {
    const limit_unit_total = getDenormalizedDimension(
      mappedEquivalencySum,
      limit_unit
    );
    return {
      over: true,
      storeName: store.name,
      overReports: [
        {
          limit_value,
          limit_unit,
          limit_unit_total,
          normalized_limit: normalizedLimit,
          normalized_total: equivalencySum,
          product_group_name,
        },
      ],
    };
  }

  return;
};

export const generateCartLimitReport = ({
  cart,
  store,
}: {
  cart: Cart | EmptyCart;
  store: Store;
}) => {
  const report: CartLimitPolicyReport = {
    over: false,
    storeName: store.name,
    overReports: [],
  };

  // gather inputs: policy->rules, cart->products
  const { cart_limit_policy: policy } = store;
  if (!policy) return report;

  const { cart_limit_rules: rules } = policy;
  if (!rules || rules.length === 0) return report;

  const { products } = cart;
  if (!products || products.length === 0) return report;

  const equivalencyReport = generateCartLimitEquivalencyReport(cart, store);
  if (equivalencyReport) return equivalencyReport;
  /*
    lookup maps: rules by ruleId, and types/subtypes by ruleId
    allows product type -> ruleId -> rule
  */
  const ruleMap = new Map();
  rules.forEach((r: CartLimitRule) => ruleMap.set(r.id, r));
  const { subtypeLookup, typeLookup } = generateProductTypeLookups(rules);

  const productsByRule = bucketProductsByRule({
    products,
    typeLookup,
    subtypeLookup,
  });

  // did any of the products fall under any type/subtype rules?
  if (productsByRule.size === 0) {
    return report;
  }

  // iterate over each rule and its product-collection
  Array.from(productsByRule).forEach(
    ([ruleId, ruleProducts]: [number, PendingCartProduct[]]) => {
      const rule = ruleMap.get(ruleId);
      const { limit_value, limit_unit, product_group_name } = rule;
      const normalizedLimit = getNormalizedDimension(limit_value, limit_unit);
      const limitUnitType = unitTypeMap[limit_unit];

      const productsTotal = ruleProducts.reduce((total, product) => {
        const {
          quantity_units,
          quantity_value,
          thc_dosage_milligrams,
          price_id,
          count,
        } = product;

        const productUnitType =
          limitUnitType === 'dosage'
            ? 'dosage'
            : (quantity_units && unitTypeMap[quantity_units]) ||
              (price_id !== 'each' && 'weight');

        if (productUnitType === 'dosage') {
          // if we add anything to 'dosage' units besides mg,
          // this will need to check/normalize/denormalize like
          // the others. But for now dosage is all in mg.
          return total + (thc_dosage_milligrams || 0) * count;
        } else {
          const usePriceIdWeight =
            limitUnitType === 'weight' &&
            productUnitType === 'weight' &&
            price_id !== 'each' &&
            !(quantity_value && quantity_units);

          const productQuantity = usePriceIdWeight
            ? normalizedQuantityFromPriceId(product)
            : getNormalizedDimension(
                product.quantity_value,
                product.quantity_units
              );

          return total + productQuantity * (count || 0);
        }
      }, 0);
      const productTotalsOver = productsTotal > normalizedLimit;

      if (productTotalsOver) {
        report.over = true;
        const limit_unit_total = getDenormalizedDimension(
          productsTotal,
          limit_unit
        );
        report.overReports.push({
          limit_value,
          limit_unit,
          limit_unit_total,
          normalized_limit: normalizedLimit,
          normalized_total: productsTotal,
          product_group_name,
        });
      }
    }
  );

  return report;
};

export const filterWeightsAboveMax = (
  weights: WeightSelectorWeight[],
  cartProduct: ReservationCartProduct[] = [],
  maxWeightOption: number
) =>
  weights.filter((weight) => {
    return (
      PRICE_ID_TO_GRAMS[weight.value as ProductWeight] <= maxWeightOption ||
      cartProduct.find((product) => product.price_id === weight.value)
    );
  });

type CanWeightBeAddedToCart = {
  canAddMore: boolean;
  canBeAddedToCart: boolean;
};

/**
 *
 * @param selectedWeight -- The priceId currently selected for the product
 * @param cartProduct -- The variants of the product on the cart
 * @param selectedQuantity -- How much of this product we want to check if we can add
 * @param maxWeightOption -- how many grams can we still add to the cart
 * @returns {
 *   canBeAddedToCart: boolean -- if we can add this amount to the cart
 *   canAddMore: boolean  -- if we can add selectedQuantity + 1 of this weight to the cart
 * }
 */
export const canWeightBeAddedToCart = (
  selectedWeight: PriceId,
  cartProduct: ReservationCartProduct[] = [],
  selectedQuantity: number,
  maxWeightOption: number
): CanWeightBeAddedToCart => {
  const selectedWeightInCart = cartProduct?.find(
    (product) => product.price_id === selectedWeight
  );
  const weightAlreadyInCart = selectedWeight === selectedWeightInCart?.price_id;
  const weightAndOptionAlreadyInCart =
    weightAlreadyInCart && selectedQuantity === selectedWeightInCart?.count;

  let selectedWeightIsOverMaxWeight = false;

  let canAddMore = true;

  // if the weight selected for the product is already on the cart
  // but the count is different i.e: we want to add x more/remove x from the cart
  // we want to calculate if that x would be over the remaining weight
  if (weightAlreadyInCart && !weightAndOptionAlreadyInCart) {
    // how much weight is the current selection using
    const totalWeightUsed =
      PRICE_ID_TO_GRAMS[selectedWeight as ProductWeight] *
      selectedWeightInCart?.count;

    // if we want to add more we calculate if the new amount would
    // overpass the remaining avaiable weight
    // and we calculate if we could add another one after that.
    if (selectedWeightInCart?.count < selectedQuantity) {
      // we calculate how much the current selection weights
      // and if it's over the max
      selectedWeightIsOverMaxWeight =
        PRICE_ID_TO_GRAMS[selectedWeight as ProductWeight] * selectedQuantity >
        maxWeightOption + totalWeightUsed;

      // if is still below the max, we check if we could add one more
      if (!selectedWeightIsOverMaxWeight) {
        canAddMore =
          PRICE_ID_TO_GRAMS[selectedWeight as ProductWeight] *
            (selectedQuantity + 1) <=
          maxWeightOption + totalWeightUsed;
      } else {
        canAddMore = false; // if it is over the max we shouldn't be able to add more
      }
    } else {
      // if we want to add less than what we already have
      // we say the weight should not overpass the limit (since it's less)
      // and yes we can add one more since that's what we had originally

      return {
        canBeAddedToCart: true,
        canAddMore: true,
      };
    }
  } else if (weightAlreadyInCart) {
    // if we already have the weight in the cart and the same count
    // we just need to calculate if we can add one more
    const totalWeightUsed =
      PRICE_ID_TO_GRAMS[selectedWeight as ProductWeight] *
      selectedWeightInCart?.count;
    canAddMore =
      PRICE_ID_TO_GRAMS[selectedWeight as ProductWeight] *
        (selectedQuantity + 1) <=
      maxWeightOption + totalWeightUsed;
  } else {
    // if this product wasn't on the cart we do the same as when
    // updating the quantities but we work with the maxWeightOption
    // instead of the totalWeightUsed
    // (since we are not using anything for this product yet)

    selectedWeightIsOverMaxWeight =
      PRICE_ID_TO_GRAMS[selectedWeight as ProductWeight] * selectedQuantity >
      maxWeightOption;

    canAddMore =
      PRICE_ID_TO_GRAMS[selectedWeight as ProductWeight] *
        (selectedQuantity + 1) <=
      maxWeightOption;
  }

  return {
    canBeAddedToCart: !selectedWeightIsOverMaxWeight,
    canAddMore,
  };
};
