import type { Identification } from '@jane/shared/models';
import type { Timezone } from '@jane/shared/types';
import { convertToDayJs, timeFromNow } from '@jane/shared/util';

export const isEligibleForGroup = ({
  document,
  timezone,
  groupType,
  requiredAge,
}: {
  document: Identification;
  groupType: string;
  requiredAge?: number | null;
  timezone: string;
}) => {
  switch (groupType) {
    case 'student': {
      return !!document.student_photo;
    }
    case 'pediatric': {
      const { birth_date } = document;
      if (!birth_date) return false;

      const ageAtStore = ageAtTimezone(birth_date, timezone);
      return !!(ageAtStore && requiredAge && ageAtStore <= requiredAge);
    }
    case 'ssi': {
      return !!document.government_photo;
    }
    case 'snap': {
      return !!document.government_photo;
    }
    case 'industry': {
      return !!document.government_photo;
    }
    case 'medicaid': {
      return !!document.government_photo;
    }
    case 'chip': {
      return !!document.government_assistance_photo;
    }
    case 'pace': {
      return !!document.government_assistance_photo;
    }
    case 'pacenet': {
      return !!document.government_assistance_photo;
    }
    case 'wic': {
      return !!document.government_assistance_photo;
    }
    case 'PA Gov': {
      return !!document.government_assistance_photo;
    }
    case 'medicare': {
      return !!document.government_photo;
    }
    case 'member': {
      return true;
    }
    case 'locals': {
      return !!document.government_photo;
    }
    case 'veteran': {
      return !!document.veteran_photo;
    }
    case 'medical': {
      return !!(document.mmj_card && document.mmj_card_back);
    }
    case 'senior': {
      const { birth_date } = document;
      if (!birth_date) return false;

      const ageAtStore = ageAtTimezone(birth_date, timezone);
      return !!(ageAtStore && requiredAge && ageAtStore >= requiredAge);
    }
    default: {
      return false;
    }
  }
};

export const ageAtTimezone = (
  birthDate: string | undefined | null,
  timezone: string
) => {
  if (!birthDate || !convertToDayJs(birthDate).isValid()) return undefined;

  const zone = (timezone as Timezone) || ('America/Los_Angeles' as Timezone);
  const age = timeFromNow(birthDate, undefined, false, zone);
  const ageStringToNumber = Number(age.split(' ')[0]);

  return ageStringToNumber;
};
