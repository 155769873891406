import { trackUnexpectedError } from '@jane/dm/internal';
import { getAlgoliaClient } from '@jane/search/data-access';
import { config } from '@jane/shared/config';
import type { MenuProduct } from '@jane/shared/models';
import { deserializeCannabinoids } from '@jane/shared/util';

import { MENU_PRODUCTS_DEFAULT } from '../../../../lib/algoliaIndices';

export const DEFAULT_HITS_PER_PAGE = 16;

export interface FetchMenuProductsProps {
  filters?: string;
  hitsPerPage?: number;
}

export async function fetchMenuProducts({
  filters = '',
  hitsPerPage = DEFAULT_HITS_PER_PAGE,
}: FetchMenuProductsProps): Promise<MenuProduct[]> {
  try {
    const client = getAlgoliaClient(config);
    const index = client.initIndex(MENU_PRODUCTS_DEFAULT);

    const { hits } = await index.search('', {
      hitsPerPage,
      filters,
    });

    const deserializedMenuProducts = hits.map((hit) =>
      deserializeCannabinoids(hit, 'menuProduct')
    );

    return deserializedMenuProducts;
  } catch (error) {
    trackUnexpectedError({ error });
    return [];
  }
}
