import { getJwt } from '@jane/shared/auth';

import { janeApi } from '../api';
import { createUseQueryHook } from '../utils';
import type {
  GuestReservationDetailsResponse,
  ReservationDetailsResponse,
  ReservationRequestProps,
  ReservationsProps,
  ReservationsResponse,
} from './reservations.types';
import { reservationsKeys, reservationsUrls } from './reservations.util';

export const IN_PROGRESS_STATUSES = [
  'verification',
  'staff_member_review',
  'preparing',
  'delivery_started',
  'delivery_finished',
  'ready_for_pickup',
];

const fetchReservations = async ({ storeId }: ReservationsProps) => {
  const url = storeId
    ? reservationsUrls.reservationsByStore({ storeId })
    : reservationsUrls.reservations();

  const response = await janeApi.get<ReservationsResponse>(url, getJwt());

  return response.reservations || [];
};

const fetchInProgressReservations = async (props: ReservationsProps) => {
  const reservations = await fetchReservations(props);

  return reservations.filter((reservation) =>
    IN_PROGRESS_STATUSES.includes(reservation.status)
  );
};

const fetchReservationDetails = async ({ id }: ReservationRequestProps) => {
  const url = reservationsUrls.reservationDetails({ id });

  const response = await janeApi.get<ReservationDetailsResponse>(url, getJwt());

  return response.reservation;
};

const fetchGuestReservationDetails = async ({
  id,
}: ReservationRequestProps) => {
  const url = reservationsUrls.guestReservationDetails({ id });

  const response = await janeApi.get<GuestReservationDetailsResponse>(
    url,
    getJwt()
  );
  return response.guestReservation;
};

export const useReservations = createUseQueryHook<
  ReservationsProps,
  ReservationsResponse['reservations']
>(fetchReservations, reservationsKeys.reservations);

export const useInProgressReservations = createUseQueryHook<
  ReservationsProps,
  ReservationsResponse['reservations']
>(fetchInProgressReservations, reservationsKeys.reservationsInProgress);

export const useReservationDetails = createUseQueryHook<
  ReservationRequestProps,
  ReservationDetailsResponse['reservation']
>(
  fetchReservationDetails,
  reservationsKeys.reservationDetails,
  ({ id }) => !!id
);

export const useGuestReservationDetails = createUseQueryHook<
  ReservationRequestProps,
  GuestReservationDetailsResponse['guestReservation']
>(
  fetchGuestReservationDetails,
  reservationsKeys.guestReservationDetails,
  ({ id }) => !!id
);
