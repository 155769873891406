import type { Dispatch, ReactNode, SetStateAction } from 'react';
import { createContext, useCallback, useContext, useState } from 'react';

import type {
  Coordinates,
  Location,
  Preferences,
  StoreFulfillmentTypes,
  StoreTypes,
} from '@jane/shared/models';
import { Storage } from '@jane/shared/util';

const getInitialLocation = (): Location => ({
  cityState: Storage.get<string>('cityState'),
  coordinates: Storage.get<Coordinates>('coordinates'),
  countryCode: Storage.get<string>('countryCode'),
  hasResetLocation: false,
  street: Storage.get<string>('street'),
  zipcode: Storage.get<string>('zipcode'),
});

const getInitialPreferences = (): Preferences => ({
  storeAvailability: Storage.get<'openNow' | undefined>('storeAvailability'),
  storeFulfillmentType: Storage.get<StoreFulfillmentTypes>(
    'storeFulfillmentType'
  ),
  storeSearchRadius: Storage.get<number>('storeSearchRadius'),
  storeType: Storage.get<StoreTypes>('storeType'),
});

export interface UserPreferences {
  newLocation?: boolean;
  resetLocation: () => void;
  resetPreferences: () => void;
  setNewLocation?: Dispatch<SetStateAction<boolean>>;
  setUserLocation: (location: Location) => void;
  setUserPreferences: (preferences: Preferences) => void;
  userLocation: Location;
  userPreferences?: Preferences;
}

export const UserPreferencesContext = createContext<UserPreferences>({
  newLocation: false,
  resetLocation: () => null,
  resetPreferences: () => null,
  setNewLocation: () => null,
  setUserLocation: () => null,
  setUserPreferences: () => null,
  userLocation: getInitialLocation(),
  userPreferences: getInitialPreferences(),
});

interface Props {
  children: ReactNode;
}

export const UserPreferencesProvider = ({ children }: Props) => {
  const [userLocation, setUserLocation] = useState(getInitialLocation());
  const [userPreferences, setUserPreferences] = useState(
    getInitialPreferences()
  );
  const [newLocation, setNewLocation] = useState(false);

  const handleSetPreferences = useCallback((preferences: Preferences) => {
    Object.keys(preferences).forEach((key) =>
      Storage.set(key, preferences[key as keyof Preferences])
    );
    setUserPreferences(preferences);
  }, []);

  const handleSetLocation = useCallback((location: Location) => {
    Object.keys(location).forEach((key) =>
      Storage.set(key, location[key as keyof Location])
    );
    setUserLocation(location);
    setNewLocation(true);
  }, []);

  const handleResetLocation = useCallback(() => {
    const locationKeys = [
      'coordinates',
      'cityState',
      'countryCode',
      'street',
      'zipcode',
    ];
    locationKeys.forEach((key) => {
      Storage.remove(key);
    });

    setUserLocation(
      locationKeys.reduce((prev, curr) => ({ ...prev, [curr]: undefined }), {
        hasResetLocation: true,
      })
    );
  }, []);

  const handleResetPreferences = useCallback(() => {
    const preferencesKeys = [
      'storeAvailability',
      'storeFulfillmentType',
      'storeSearchRadius',
      'storeType',
    ];
    preferencesKeys.forEach((key) => {
      Storage.remove(key);
    });

    setUserPreferences(
      preferencesKeys.reduce(
        (prev, curr) => ({ ...prev, [curr]: undefined }),
        {}
      )
    );
  }, []);

  const value = {
    newLocation,
    resetLocation: handleResetLocation,
    setNewLocation,
    resetPreferences: handleResetPreferences,
    setUserLocation: handleSetLocation,
    setUserPreferences: handleSetPreferences,
    userLocation,
    userPreferences,
  };

  return (
    <UserPreferencesContext.Provider value={value}>
      {children}
    </UserPreferencesContext.Provider>
  );
};

export const useUserPreferences = () => {
  const context = useContext(UserPreferencesContext);

  if (context === undefined) {
    throw new Error(
      'useUserPreferences must be used within a UserPreferencesProvider'
    );
  }

  return context;
};
