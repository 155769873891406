import { Suspense, lazy } from 'react';

import { LoadingWrapper } from '@jane/shared/components';

import { useCustomerSelector } from '../../customer/selectors';
import { get } from '../../redux-util/selectors';
import type { IdentificationProps } from './identification.types';
import { WarningMessage } from './warningMessage';

/** Code splitting to load convertIfPDF/pdfjs only when called */
const DeluxeIdentificationUploader = lazy(() =>
  import('./idUploader').then(({ DeluxeIdentificationUploader }) => ({
    default: DeluxeIdentificationUploader,
  }))
);

const VeteranIdentification = ({
  showValidationErrors,
  onIdUploadAttempt,
}: IdentificationProps) => {
  const { document, loading_veteran_photo } = useCustomerSelector(
    get('identification')
  );

  if (!document) return null;

  return (
    <Suspense fallback={<LoadingWrapper isLoading />}>
      <DeluxeIdentificationUploader
        imageKey="veteran_photo"
        inputName="government-id"
        label="Upload Veteran ID"
        document={document}
        isLoading={loading_veteran_photo}
        showValidationErrors={showValidationErrors}
        onIdUploadAttempt={onIdUploadAttempt}
      />
      {!document.veteran_photo && (
        <WarningMessage>ID required for discount</WarningMessage>
      )}
    </Suspense>
  );
};

export default VeteranIdentification;
