import type {
  AlgoliaFilterProperties,
  AlgoliaObjectProperties,
} from './eventNames';

// import algoliaInsights from 'search-insights' <- this will not work until
// they publish type definitions with their lib
// eslint-disable-next-line @typescript-eslint/no-var-requires
const algoliaInsights = require('search-insights');

const BRAND_FACET_REGEX = /^brand:/;

export const buildFilterEvent = (event: AlgoliaFilterProperties) => {
  // Algolia only wants filters that have a colon separated key:value,
  // limit 10. Filtering by brand results in higher conversion, so
  // brand filters should take precedent.
  const filters = event.filters
    .sort((f) => (f.match(BRAND_FACET_REGEX) ? -1 : 1))
    .filter((f) => f.includes(':'))
    .slice(0, 10);

  const userToken = (window as any).janeDeviceId;

  if (!userToken || !filters.length) {
    return null;
  }

  return {
    index: event.indexName,
    eventName: event.event,
    filters,
    userToken,
  };
};

export const buildObjectEvent = (event: AlgoliaObjectProperties) => {
  const userToken = (window as any).janeDeviceId;
  const objectIDs = event.objectIds.slice(0, 20);
  if (!userToken || !objectIDs.length) {
    return null;
  }
  return {
    index: event.indexName,
    eventName: event.event,
    objectIDs,
    userToken,
  };
};

export function trackViewedFilters(event: AlgoliaFilterProperties) {
  const filterEvent = buildFilterEvent(event);
  filterEvent && algoliaInsights('viewedFilters', filterEvent);
}

export function trackClickedFilters(event: AlgoliaFilterProperties) {
  const filterEvent = buildFilterEvent(event);
  filterEvent && algoliaInsights('clickedFilters', filterEvent);
}

export function trackConvertedFilters(event: AlgoliaFilterProperties) {
  const filterEvent = buildFilterEvent(event);
  filterEvent && algoliaInsights('convertedFilters', filterEvent);
}

export function trackClickedObjectIDs(event: AlgoliaObjectProperties) {
  const objectEvent = buildObjectEvent(event);
  objectEvent && algoliaInsights('clickedObjectIDs', objectEvent);
}

export function trackConvertedObjectIDs(event: AlgoliaObjectProperties) {
  const objectEvent = buildObjectEvent(event);
  objectEvent && algoliaInsights('convertedObjectIDs', objectEvent);
}

export function trackViewedObjectIDs(event: AlgoliaObjectProperties) {
  const objectEvent = buildObjectEvent(event);
  objectEvent && algoliaInsights('viewedObjectIDs', objectEvent);
}
