import type { ReeferTheme } from '../theme.types';
import type {
  ButtonSizeConfig,
  ButtonVariantConfig,
} from '../themeComponents.types';

type CSSVariablesMap = Record<string, string>;

const camelToKebabCase = (str: string) =>
  str.replace(/[A-Z]/g, (letter: string) => `-${letter.toLowerCase()}`);

/**
 * Generates a flattened CSS Variable map from a theme object
 */
export function generateCSSVars(
  themeSection:
    | ReeferTheme['borderRadius']
    | ReeferTheme['colors']
    | ReeferTheme['components']
    | ReeferTheme['components']['Icon']
    | ButtonSizeConfig
    | ButtonVariantConfig,
  sectionName: string,
  valueParser?: (value: string) => void
): CSSVariablesMap {
  const varMap: CSSVariablesMap = {};

  function flatten(obj: any, prefix = '') {
    Object.keys(obj).forEach((key) => {
      const value = obj[key];
      if (
        typeof value === 'object' &&
        value !== null &&
        !Array.isArray(value)
      ) {
        flatten(value, `${prefix}${camelToKebabCase(key)}-`);
      } else {
        varMap[`--${sectionName}-${prefix}${camelToKebabCase(key)}`] =
          valueParser ? valueParser(value) : value;
      }
    });
  }

  flatten(themeSection);
  return varMap;
}
