import styled from '@emotion/styled';

import { Box, Button, Flex, Typography } from '@jane/shared/reefer';
import { mediaQueries } from '@jane/shared/reefer-emotion';

export const StyledButton = styled(Button)({
  width: '100%',
  flexShrink: 0,
  [mediaQueries.tablet('min')]: {
    width: 'fit-content',
    flex: '0 0 fit-content',
  },
});

export const StyledContentContainer = styled(Flex)({
  flexDirection: 'column',
  [mediaQueries.tablet('min')]: {
    flexDirection: 'row',
  },
});

export const StyledDescriptionContainer = styled(Flex)({
  alignItems: 'center',
  [mediaQueries.tablet('min')]: {
    alignItems: 'flex-start',
  },
});

export const StyledProfileSection = styled(Box)({
  padding: 24,
  paddingTop: 20,

  [mediaQueries.tablet('min')]: {
    padding: 40,
  },

  [mediaQueries.tablet('max')]: {
    border: 'none',
    borderRadius: 0,
  },
});

export const StyledTitle = styled(Typography)({
  marginBottom: 20,
  [mediaQueries.tablet('min')]: {
    marginBottom: 24,
  },
});
