import isObject from 'lodash/isObject';
import { useEffect, useState } from 'react';

import type { FilterRangeProps } from '@jane/search/types';
import {
  Box,
  Flex,
  Modal,
  SliderField,
  Typography,
  useMobileMediaQuery,
} from '@jane/shared/reefer';

import { useActiveFilterContext } from './activeFilterProvider';
import { DeselectAllButton } from './filterModal/deselectAllButton';
import { CONTENT_DIVIDER_PROPS } from './filterModal/modalSection';

interface FilterSliderValue {
  max: number;
  min: number;
}

export const FilterSlider = ({
  item,
  onChange,
  onDeselect,
}: FilterRangeProps) => {
  const isMobile = useMobileMediaQuery({});

  const { label, max, min, key } = item;

  const { activeFilters } = useActiveFilterContext();

  const filteredRange = activeFilters
    .map((filter) => {
      if (isObject(filter.value)) {
        if (filter.key === key) {
          return filter.value;
        }
      }
      return undefined;
    })
    .filter((filter) => filter !== undefined)[0];

  const minValue = typeof min === 'string' ? parseFloat(min) : min;
  const maxValue = typeof max === 'string' ? parseFloat(max) : max;

  const rangeMin = Math.floor(minValue);
  const rangeMax = Math.ceil(maxValue);

  const [displayValue, setDisplayValue] = useState({
    min: filteredRange?.min || rangeMin,
    max: filteredRange?.max || rangeMax,
  });

  const fieldValue = {
    min: displayValue.min,
    max: displayValue.max,
  };

  const handleDeselect = () => {
    onDeselect();
    setDisplayValue({ min: rangeMin, max: rangeMax });
  };

  // reset displayValue on "clear all" click
  useEffect(() => {
    if (!activeFilters.length) {
      setDisplayValue({ min: rangeMin, max: rangeMax });
    }
  }, [activeFilters]);

  return (
    <Box width="100%" key={label}>
      <Modal.ContentDivider {...CONTENT_DIVIDER_PROPS} />
      <Flex flexDirection="column" p={isMobile ? 24 : 40}>
        <Flex justifyContent="space-between" mb={16}>
          <Typography
            variant={isMobile ? 'body' : 'header-bold'}
            branded={!isMobile}
          >
            {label}
          </Typography>
          <DeselectAllButton onClick={handleDeselect} />
        </Flex>

        <SliderField
          key={label}
          label={label}
          labelHidden
          defaultValue={fieldValue}
          value={fieldValue}
          max={rangeMax}
          min={rangeMin}
          step={1}
          onChange={(value: FilterSliderValue) => setDisplayValue(value)}
          onAfterChange={() => onChange(key, displayValue)}
          isDouble
          name={label}
          width="100%"
          endUnit="%"
          disabled={rangeMin === rangeMax}
        />
      </Flex>
    </Box>
  );
};
