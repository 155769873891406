import styled from '@emotion/styled';

import { mediaQueries, spacing } from '@jane/shared/reefer-emotion';

const PRODUCTS_GRID_GAP = '24px 24px';
const MOBILE_PRODUCTS_GRID_GAP = '16px 16px';

export interface CardGridProps {
  addMarginY?: boolean;
  desktopCardWidth: number;
  mobileCardWidth: number;
}

export const CardGrid = styled.div<CardGridProps>(
  ({ addMarginY = true, desktopCardWidth, mobileCardWidth }) => ({
    // for very small screens, the negative horizontal margins allow for smaller cards to have two columns
    ...spacing({ my: addMarginY ? 16 : undefined, p: 8, mx: -12 }),
    display: 'grid',
    gridGap: MOBILE_PRODUCTS_GRID_GAP,
    justifyContent: 'center',
    // .8fr prevents single column grids from stretching the cards uncomfortably wide
    gridTemplateColumns: `repeat(auto-fill, minmax(${mobileCardWidth}px, .8fr))`,
    // For wider cards, we wait until a little after tablet.min before swapping to desktopCardWidth
    [desktopCardWidth < 408
      ? mediaQueries.tablet('min')
      : `@media only screen and (min-width: ${desktopCardWidth * 2 + 72}px)`]: {
      ...spacing({ mx: -8 }),
      gridGap: PRODUCTS_GRID_GAP,
      gridTemplateColumns: `repeat(auto-fill, minmax(${desktopCardWidth}px, 1fr))`,
    },
  })
);
