import type { Store } from '@jane/shared/models';

export const minimumPickupOrder = (
  discountedSubtotal: number,
  store: Store
) => {
  return discountedSubtotal >= getPickupMinAmount(store);
};

export const getPickupMinAmount = (store: Store) => {
  switch (store.curbside_pickup_setting) {
    case 'not_allowed':
      return store.pickup_minimum;
    case 'mandatory':
      return store.curbside_minimum;
    case 'allowed':
      return Math.min(store.pickup_minimum, store.curbside_minimum);
    default:
      return store.pickup_minimum;
  }
};
