import debounce from 'lodash/debounce';

type GenericFunction<T extends any[], R> = (...args: T) => R;

interface DebounceSettings {
  leading?: boolean;
  maxWait?: number;
  trailing?: boolean;
}

type Inner<T extends GenericFunction<any[], any>> =
  ReturnType<T> extends Promise<infer I> ? I : never;

export function debounceAsync<T extends GenericFunction<any[], any>>(
  func: T,
  wait = 0,
  options: DebounceSettings = { leading: true, trailing: true }
): (...args: Parameters<T>) => Promise<Inner<T>> {
  const debounced = debounce(
    (resolve, reject, args) => {
      func(...args)
        .then(resolve)
        .catch(reject);
    },
    wait,
    options
  );

  return (...args) =>
    new Promise<Inner<T>>((resolve, reject) => {
      debounced(resolve, reject, args);
    });
}
