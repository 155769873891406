import { useMemo } from 'react';

import { useJaneUser } from '@jane/shared/data-access';
import type { BrandSpecial, PendingCartProduct } from '@jane/shared/models';
import type { ProductWithBrandSpecialSegments } from '@jane/shared/util';
import { brandSpecialHasSegments } from '@jane/shared/util';

export const useNeedsSignInForEligibility = ({
  brandSpecial,
  cartProduct,
  product,
}: {
  brandSpecial?: Pick<BrandSpecial, 'rules'>;
  cartProduct?: Pick<PendingCartProduct, 'brand_special_has_segment_rules'>;
  product?: Pick<
    ProductWithBrandSpecialSegments,
    | 'applicable_brand_specials_excluded_user_segments'
    | 'applicable_brand_specials_included_user_segments'
  >;
}) => {
  const { data: janeUser } = useJaneUser();

  const needsSignInForEligibility = useMemo(() => {
    if (janeUser?.user.authenticated) {
      return false;
    }

    return brandSpecialHasSegments({ brandSpecial, cartProduct, product });
  }, [janeUser?.user.authenticated, brandSpecial, product]);

  return needsSignInForEligibility;
};
