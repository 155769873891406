import type {
  ArrayFilterValue,
  FilterKeys,
  FilterSelectItem,
} from '@jane/search/types';
import { formatCount } from '@jane/search/util';
import { truncateLabel } from '@jane/shared-ecomm/util';
import {
  CheckboxField,
  ChevronDownIcon,
  getTextWithHighlights,
} from '@jane/shared/reefer';

import { useActiveFilterContext } from './activeFilterProvider';
import {
  FilterListCheckbox,
  FilterListCheckboxArrow,
  FilterListItem,
} from './filter.styles';

interface FilterCheckboxProps {
  children?: React.ReactNode;
  filterKey: FilterKeys;
  hasSubItems?: boolean;
  hideCounts?: boolean;
  indeterminate?: boolean;
  isSubItem?: boolean;
  isTopLevelChecked?: boolean;
  item: FilterSelectItem;
  onChange: (key: FilterKeys, value: ArrayFilterValue) => void;
  query?: string;
  setShowSubItems?: React.Dispatch<React.SetStateAction<boolean>>;
  showSubItems?: boolean;
  subItemValues?: string[];
  topLevelValue?: ArrayFilterValue;
}

export const FilterCheckbox = ({
  children,
  filterKey,
  hasSubItems,
  hideCounts,
  indeterminate,
  isSubItem,
  isTopLevelChecked,
  item,
  onChange,
  query,
  setShowSubItems,
  showSubItems,
  subItemValues,
  topLevelValue,
}: FilterCheckboxProps) => {
  const { count, label, value } = item;

  const { activeFilters } = useActiveFilterContext();

  const isChecked = activeFilters.some((filter) => filter.value === value);

  const itemCount = !hideCounts ? ` (${formatCount(count)})` : '';

  const renderedLabel = `${truncateLabel(label, 40)}${itemCount}`;

  return (
    <FilterListItem>
      <FilterListCheckbox subItem={isSubItem} hasSubItems={hasSubItems}>
        <CheckboxField
          checked={isChecked}
          name={label}
          label={
            query ? getTextWithHighlights(renderedLabel, query) : renderedLabel
          }
          onChange={(checked) => {
            if (checked !== isChecked) {
              // uncheck top level item on sub item check
              if (isSubItem && isTopLevelChecked && topLevelValue) {
                onChange(filterKey, topLevelValue);
              }

              onChange(filterKey, value);
            }

            if (!checked && indeterminate) {
              // uncheck sub items on indeterminate top level item click
              subItemValues?.map(
                (subValue) =>
                  activeFilters.some((filter) => filter.value === subValue) &&
                  onChange(filterKey, subValue)
              );
            }
          }}
          indeterminate={indeterminate}
          disabled={!count}
        />
        {hasSubItems && (
          <FilterListCheckboxArrow>
            <ChevronDownIcon
              onClick={() => setShowSubItems && setShowSubItems(!showSubItems)}
              rotate={showSubItems ? 'down' : 'up'}
              altText={`Click to ${
                showSubItems ? 'hide' : 'show'
              } subcategory filters`}
            />
          </FilterListCheckboxArrow>
        )}
      </FilterListCheckbox>
      {children}
    </FilterListItem>
  );
};
