import { useShouldShowGold } from '@jane/shared/hooks';
import {
  AeropayLogoSecondary,
  BankCircledIcon,
  Button,
  Flex,
  JaneLogo,
  Link,
  LockSquareIcon,
  PoweredByAeropayLogo,
  Typography,
} from '@jane/shared/reefer';

export interface LinkBankScreenProps {
  hideJanePayBranding: boolean;
  isJanePay: boolean;

  /** Function called when 'continue' button is clicked */
  onContinueClick(): void;

  /** Function called when 'cancel' button is clicked */
  onDoneClick(): void;
}

export const LinkBankScreen = ({
  hideJanePayBranding,
  isJanePay,
  onContinueClick,
  onDoneClick,
}: LinkBankScreenProps) => {
  const showGold = useShouldShowGold();

  const hideBranding = isJanePay && hideJanePayBranding;

  return (
    <Flex
      width="100%"
      height="100%"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
    >
      <BankCircledIcon size="xxl" color="primary" mb={24} />
      <Typography variant="header-bold" textAlign="center" mb={8} branded>
        Link your bank account
        {isJanePay
          ? hideJanePayBranding
            ? ''
            : ' to use Jane Pay'
          : ' to join Jane Gold'}
      </Typography>
      {!isJanePay && (
        <Typography textAlign="center" mb={24}>
          You’ll be asked to link your bank to receive cash back. We partner
          with Aeropay to securely connect your bank and never share your
          information.
        </Typography>
      )}

      {isJanePay && showGold && (
        <Typography textAlign="center" mb={24}>
          You will also join Jane Gold for free and qualify to earn cash back
          rewards. We partner with Aeropay to securely connect your bank and
          never share your information.
        </Typography>
      )}

      {isJanePay && !showGold && (
        <Typography textAlign="center" mb={24}>
          We securely connect your bank and never share your information.
        </Typography>
      )}

      <Button
        full
        startIcon={<LockSquareIcon color="inherit" />}
        variant="primary"
        mb={16}
        label="Continue"
        onClick={onContinueClick}
      />
      <Button
        full
        variant="secondary"
        mb={24}
        label="Cancel"
        onClick={onDoneClick}
      />
      <Typography variant="mini" color="grays-mid" textAlign="center" mb={8}>
        By agreeing to link your bank, you agree to Aeropay's{' '}
        <Link
          ariaLabel="link for Aeropay terms of service"
          href="https://www.aeropay.com/legal/terms-of-service"
          color="grays-mid"
        >
          Terms of Service
        </Link>{' '}
        and{' '}
        <Link
          ariaLabel="link for Aeropay privacy policy"
          href="https://www.aeropay.com/legal/privacy-policy"
          color="grays-mid"
        >
          Privacy Policy
        </Link>
      </Typography>

      {!hideBranding && <PoweredByAeropayLogo height="24px" />}

      {hideBranding && (
        <Flex alignItems="baseline">
          <Typography variant="mini" mr={4}>
            Powered by
          </Typography>
          <JaneLogo removeSafeSpace variant="secondary" size="xs" />
          <Typography variant="mini" mx={4}>
            and
          </Typography>
          <AeropayLogoSecondary my="auto" height={15} />
        </Flex>
      )}
    </Flex>
  );
};
