import { track } from './mixpanel';

interface BaseEvent<E extends EventName> {
  event: E;
}
enum EventName {
  AdsServerProductMissingInAlgolia = 'Ads-Server Product Missing in Algolia',
  BranchIoError = 'BranchIo Error',
  FailedRequest = 'Failed Request',
  FailedToTrackImpression = 'Failed to Track Impression',
  LoadedSmartSortProducts = 'Loaded Smart Sort Products',
  UnexpectedError = 'Unexpected Error',
  ZodError = 'Zod Error',
}
export type TrackedEvent =
  | BranchIoError
  | FailedRequest
  | FailedToTrackImpression
  | LoadedSmartSortProducts
  | AdsServerProductMissingInAlgolia
  | UnexpectedError
  | ZodError;

type BranchIoError = BaseEvent<EventName.BranchIoError> & BranchIoErrorProps;
interface BranchIoErrorProps {
  message: string;
}
export const trackBranchIoError = (props: BranchIoErrorProps) => {
  track({
    event: EventName.BranchIoError,
    ...props,
  });
};

type FailedRequest = BaseEvent<EventName.FailedRequest> & FailedRequestProps;
interface FailedRequestProps {
  attemptedUrl: string;
  code: number;
  error: string;
}
export const trackFailedRequest = (props: FailedRequestProps) => {
  track({
    event: EventName.FailedRequest,
    ...props,
  });
};

type FailedToTrackImpression = BaseEvent<EventName.FailedToTrackImpression> &
  FailedToTrackImpressionProps;
interface FailedToTrackImpressionProps {
  adToken: string | undefined;
  error: Error | string;
  productBrandId: number | undefined;
  productId: number | undefined;
}
export const trackFailedToTrackImpression = (
  props: FailedToTrackImpressionProps
) => {
  track({
    event: EventName.FailedToTrackImpression,
    ...props,
  });
};

type LoadedSmartSortProducts = BaseEvent<EventName.LoadedSmartSortProducts> &
  LoadedSmartSortProductsProps;
interface LoadedSmartSortProductsProps {
  durationMs: number;
  pageHits: number;
  recommendedSortVersion: string;
  rowName: string;
  totalHits: number;
}
export const trackLoadedSmartSortProducts = (
  props: LoadedSmartSortProductsProps
) => {
  track({ event: EventName.LoadedSmartSortProducts, ...props });
};

type AdsServerProductMissingInAlgolia =
  BaseEvent<EventName.AdsServerProductMissingInAlgolia>;
interface AdsServerProductMissingInAlgoliaProps {
  adToken: string | undefined;
  flightId: number | undefined;
  productId: number;
}
export const trackAdsServerProductMissingInAlgolia = (
  props: AdsServerProductMissingInAlgoliaProps
) => {
  track({ event: EventName.AdsServerProductMissingInAlgolia, ...props });
};

type UnexpectedError = BaseEvent<EventName.UnexpectedError> &
  UnexpectedErrorProps;
interface UnexpectedErrorProps {
  error: unknown;
}
export const trackUnexpectedError = (props: UnexpectedErrorProps) => {
  track({
    event: EventName.UnexpectedError,
    ...props,
  });
};

type ZodError = BaseEvent<EventName.ZodError> & ZodErrorProps;
interface ZodErrorProps {
  message: string;
  name: string;
}
export const trackZodError = (props: ZodErrorProps) => {
  track({
    event: EventName.ZodError,
    ...props,
  });
};
