import {
  SurfaceEcomm,
  SurfaceKiosk,
  SurfaceMarketplace,
} from '@jane/shared/models';
import type { AppMode, Surface } from '@jane/shared/models';

export const getSurfaceByAppMode = (appMode: AppMode): Surface => {
  if (appMode === 'default') {
    return SurfaceMarketplace;
  } else if (appMode === 'kiosk') {
    return SurfaceKiosk;
  }
  return SurfaceEcomm;
};
