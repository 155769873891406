import clsx from 'clsx';

import type { PopoverDividerProps } from '../popover.types';
import styles from './popoverDivider.module.css';

/**
 * Popover.Divider is a border used to divide popover content when necessary.
 */

export function PopoverDivider({
  className,
  'data-testid': testId,
  id,
}: PopoverDividerProps) {
  return (
    <hr
      className={clsx(className, styles.popover_divider)}
      data-testid={testId}
      id={id}
    />
  );
}
