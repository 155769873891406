import styled from '@emotion/styled';

import { Box, Button } from '@jane/shared/reefer';
import { mediaQueries } from '@jane/shared/reefer-emotion';

export const StyledDesktopButton = styled(Button)({
  display: 'none',
  flexShrink: 0,
  [mediaQueries.tablet('min')]: {
    display: 'block',
  },
});

export const StyledMobileButton = styled(Button)({
  [mediaQueries.tablet('min')]: {
    display: 'none',
  },
});

export const StyledProfileSection = styled(Box)({
  padding: 24,
  paddingTop: 20,

  [mediaQueries.tablet('min')]: {
    padding: 40,
  },

  [mediaQueries.tablet('max')]: {
    border: 'none',
    borderRadius: 0,
  },
});
