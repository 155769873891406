import { getConfig } from './config';
import type { Config } from './type';

const config: Config = getConfig(process.env.NODE_ENV);

const FEATURES = (config.features || '')
  .split(',')
  .map((s: string) => s.trim());

export enum Feature {
  AeropayWhiteLabel = 'aeropayWhiteLabel',
  AppleSignIn = 'appleSignIn',
  CurbsidePickupNotify = 'curbsidePickupNotify',
  CustomRows = 'customRows',
  GoogleSignIn = 'googleSignIn',
  GuestCheckout = 'guestCheckout',
  OwnershipIdentification = 'ownershipIdentification',
  RootsOnlyCustomRows = 'rootsOnlyCustomRows',
  RoundingDisplay = 'roundingDisplay',
  Square = 'square',
  Stronghold = 'stronghold',
  StrongholdBusiness = 'strongholdBusiness',
  TwoWayMessaging = 'twoWayMessaging',
  VariableMaxOrders = 'variableMaxOrders',
}

export const isFeatureEnabled = (feature: Feature) => {
  const __TEST__ = config.test || config.nodeEnv === 'test'; // jest doesn't use webpack.config
  return __TEST__ || FEATURES.includes(feature);
};
