import type { ReactNode } from 'react';

import { CarouselCardStyled } from './carouselCard.styles';
import type { ScrollSnapAlignType } from './carouselCard.styles';

export interface CarouselCardProps {
  /** Carousel children, add carousel cards here */
  children: ReactNode;

  /** Set `scroll-snap-align` css property of card */
  scrollSnapAlign?: ScrollSnapAlignType;

  /** Width of the card on desktop / tablet  */
  width?: number | string;

  /** Width of the card on mobile */
  widthMobile?: number | string;
}

export function CarouselCard({
  children,
  scrollSnapAlign = 'start',
  widthMobile,
  width,
}: CarouselCardProps) {
  return (
    <CarouselCardStyled
      height="100%"
      scrollSnapAlign={scrollSnapAlign}
      widthMobile={widthMobile}
      width={width}
    >
      {children}
    </CarouselCardStyled>
  );
}
