import qs from 'qs';

import type { EcommPathsConfig } from '@jane/shared/types';

/**
 * buildPath >>>
 * @param config Ecomm paths config object to optionally build router paths for all app modes
 * @param path The path that will be appended to the returned base path
 * @returns Path with appMode defined base + path + any queries
 */

export const buildPath = (
  config: EcommPathsConfig,
  path: string,
  query?: string | object
) => {
  const {
    appMode,
    brandId,
    isBoostForAll,
    isPartnerHosted,
    operatorId,
    partnerHostedPath,
    storePathProps,
  } = config;

  let basePath = '';
  const queryString = query
    ? typeof query === 'string'
      ? `?${query}`
      : `?${qs.stringify(query)}`
    : '';

  if (
    (appMode === 'framelessEmbed' ||
      appMode === 'embedded' ||
      appMode === 'whiteLabel') &&
    !isBoostForAll
  ) {
    if (isPartnerHosted && partnerHostedPath) {
      basePath = partnerHostedPath;
    } else {
      basePath = path.startsWith(`/stores/${storePathProps?.id}`)
        ? '/embed'
        : `/embed/stores/${storePathProps?.id}`;
    }
  }

  if (appMode === 'operatorEmbed') {
    basePath = path.startsWith(`/stores/${storePathProps?.id}`)
      ? `/operator_embed/operators/${operatorId}`
      : `/operator_embed/operators/${operatorId}/stores/${storePathProps?.id}`;
  }

  if (appMode === 'brandEmbed') {
    basePath = `/brand_embed/brand_partners/${brandId}`;
  }

  if (appMode === 'headless') {
    basePath = '/headless';
  }

  return basePath + path + queryString;
};
