/**
 * DO NOT MODIFY THIS FILE!
 * It was created by the reefer-icons generator.
 * Please update & re-run the generator if need be.
 */
import type { SVGProps } from 'react';

import type { DynamicIconProps } from '../../../icon/icon.types';
import { Icon } from '../../icon';

const ChevronDown16 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="100%"
    height="100%"
    fill="none"
    viewBox="0 0 16 16"
    {...props}
  >
    <g className="Chevron Down 16">
      <path
        fill="#0E0E0E"
        fillRule="evenodd"
        d="M12.707 6.293a1 1 0 0 1 0 1.414l-4 4a1 1 0 0 1-1.414 0l-4-4a1 1 0 0 1 1.414-1.414L8 9.586l3.293-3.293a1 1 0 0 1 1.414 0Z"
        className="SVG"
        clipRule="evenodd"
      />
    </g>
  </svg>
);

const ChevronDown24 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="100%"
    height="100%"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <g className="Chevron Down 24">
      <path
        fill="#0E0E0E"
        fillRule="evenodd"
        d="M19.707 8.293a1 1 0 0 1 0 1.414l-7 7a1 1 0 0 1-1.414 0l-7-7a1 1 0 0 1 1.414-1.414L12 14.586l6.293-6.293a1 1 0 0 1 1.414 0Z"
        className="SVG"
        clipRule="evenodd"
      />
    </g>
  </svg>
);

const SIZE_MAP = {
  sm: ChevronDown16,
  md: ChevronDown24,
  lg: ChevronDown24,
  xl: ChevronDown24,
  xxl: ChevronDown24,
  xxxl: ChevronDown24,
};

export const ChevronDownIcon = ({
  size = 'md',
  'data-testid': testId,
  ...props
}: DynamicIconProps) => {
  return (
    <Icon
      data-testid={testId || 'chevron-down-icon'}
      icon={SIZE_MAP[size]}
      {...props}
      size={size}
    />
  );
};
