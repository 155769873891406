import type { UserSegmentIds } from '@jane/shared/types';

const hasBrandDiscountClause = 'has_brand_discount:true';
const noIncludedSegmentsClause =
  'applicable_brand_specials_included_user_segments:-1';

const userSegmentFilters = (
  userSegmentIds: UserSegmentIds,
  key: 'included' | 'excluded'
): string[] => {
  const filterKey =
    key === 'included'
      ? 'applicable_brand_specials_included_user_segments'
      : 'applicable_brand_specials_excluded_user_segments';

  return userSegmentIds.reduce((acc: string[], userSegmentId) => {
    acc.push(`${filterKey}:${userSegmentId}`);
    return acc;
  }, []);
};

export const buildGoldUserSegmentsFilter = (
  userSegmentIds?: UserSegmentIds
): string => {
  if (!userSegmentIds || userSegmentIds.length === 0)
    return `${hasBrandDiscountClause} AND ${noIncludedSegmentsClause}`;

  const inclusionFilters = userSegmentFilters(userSegmentIds, 'included');
  const exclusionFilters = userSegmentFilters(userSegmentIds, 'excluded');

  return [
    `${hasBrandDiscountClause} AND (${noIncludedSegmentsClause} OR `,
    inclusionFilters.join(' OR '),
    ') AND NOT ',
    exclusionFilters.join(' AND NOT '),
  ].join('');
};
