import type { ReactNode } from 'react';
import { useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import type { AlgoliaProduct, Hit, JaneSearchState } from '@jane/search/types';
import { makeSeeAllLinkSearchState } from '@jane/search/util';
import type { ClickedSeeAll } from '@jane/shared-ecomm/tracking';
import {
  EventNames,
  track,
  trackClickedAd,
  trackUserSessionClickedSeeAll,
} from '@jane/shared-ecomm/tracking';
import {
  LoadingWrapper,
  MENU_PRODUCT_ITEM_HEIGHT,
  MENU_PRODUCT_MOBILE_ITEM_HEIGHT,
} from '@jane/shared/components';
import { config } from '@jane/shared/config';
import type {
  AdData,
  MenuProduct,
  Store,
  StoreSpecial,
  Zone,
  _DeepReadonlyObject,
} from '@jane/shared/models';
import { useEcommApp } from '@jane/shared/providers';
import {
  Box,
  Card,
  Flex,
  Link,
  Typography,
  useMobileMediaQuery,
} from '@jane/shared/reefer';
import {
  generateCustomLabel,
  getAppliedWeightFilter,
  postMessageToIframeParent,
  titleCase,
} from '@jane/shared/util';

import { useCustomerDispatch } from '../../customer/dispatch';
import { isNoStore } from '../../customer/redux/store';
import {
  isEmbeddedModeSelector,
  useCustomerSelector,
} from '../../customer/selectors';
import { callResetInitialQuery } from '../../lib/initialQuery';
import { paths } from '../../lib/routes';
import { get } from '../../redux-util/selectors';
import ItemsCarousel from '../itemsCarousel';
import { ITEM_MARGIN_AMOUNT } from '../itemsCarousel/carouselHelper';
import BucketHeader from './bucketHeader';
import HitProductCard from './hitProductCard';

const NUMBER_HITS_TO_RENDER = 16;

const SeeAllCard = ({
  productType,
  store,
  onClick,
}: {
  onClick?: () => void;
  productType: string;
  store: Store;
}) => {
  const { appMode } = useEcommApp();
  const isMobile = useMobileMediaQuery({});

  const displayedProductType = generateCustomLabel({
    appMode,
    store,
    attribute: productType,
    fallback: productType,
  });

  return (
    <Card
      width="100%"
      height={
        isMobile ? MENU_PRODUCT_MOBILE_ITEM_HEIGHT : MENU_PRODUCT_ITEM_HEIGHT
      }
      onClick={onClick}
      ariaLabel="See all card"
    >
      <Box height="100%" background="primary" borderRadius="lg">
        <Flex
          height="100%"
          width="100%"
          alignItems="center"
          justifyContent="center"
        >
          <Typography
            color="grays-white"
            textAlign="center"
          >{`See All ${titleCase(displayedProductType)}`}</Typography>
        </Flex>
      </Box>
    </Card>
  );
};

interface LinkWrapperProps {
  ariaLabel?: string;
  children: ReactNode;
  flightProps?: AdData['flight'];
  searchState: JaneSearchState<AlgoliaProduct>;
  store: Store;
  trackingInfo: ClickedSeeAll;
  variant?: 'underline' | 'minimal';
}

export const SeeAllLinkWrapper = ({
  searchState,
  store,
  children,
  trackingInfo,
  ariaLabel,
  flightProps,
  variant = 'underline',
}: LinkWrapperProps) => {
  const { appMode } = useEcommApp();
  const isEmbeddedMode = useCustomerSelector(isEmbeddedModeSelector);
  const { janeDeviceId } = useCustomerSelector(get('application'));
  const { initialQuery } = useCustomerSelector(get('initialQuery'));
  const dispatch = useCustomerDispatch();

  return (
    <Link
      aria-label={ariaLabel}
      onClick={() => {
        callResetInitialQuery(appMode, dispatch, initialQuery);
        if (isEmbeddedMode) {
          postMessageToIframeParent({
            messageType: 'scrollToTop',
          });
        }
        trackClickedAd({
          flight: flightProps,
          clickKind: 'see_all',
          janeDeviceId,
        });
        track(trackingInfo);
        trackUserSessionClickedSeeAll({
          category: trackingInfo.category,
          storeId: store.id,
        });
      }}
      to={
        isEmbeddedMode && appMode !== 'brandEmbed'
          ? paths.embeddedMenu(searchState)
          : paths.store({ id: store.id, name: store.name }, searchState)
      }
      variant={variant}
    >
      {children}
    </Link>
  );
};

const useSeeAllLinkClick = ({
  flightProps,
  searchState,
  store,
  trackingInfo,
}: Omit<LinkWrapperProps, 'variant' | 'children' | 'ariaLabel'>) => {
  const { appMode } = useEcommApp();
  const isEmbeddedMode = useCustomerSelector(isEmbeddedModeSelector);
  const { janeDeviceId } = useCustomerSelector(get('application'));
  const { initialQuery } = useCustomerSelector(get('initialQuery'));
  const dispatch = useCustomerDispatch();
  const navigate = useNavigate();

  const to = useMemo(
    () =>
      isEmbeddedMode && appMode !== 'brandEmbed'
        ? paths.embeddedMenu(searchState)
        : paths.store({ id: store.id, name: store.name }, searchState),
    [appMode, isEmbeddedMode, searchState, store.id, store.name]
  );

  const onClick = useCallback(() => {
    callResetInitialQuery(appMode, dispatch, initialQuery);
    if (isEmbeddedMode) {
      postMessageToIframeParent({
        messageType: 'scrollToTop',
      });
    }
    trackClickedAd({
      clickKind: 'see_all',
      flight: flightProps,
      janeDeviceId,
    });
    track(trackingInfo);
    trackUserSessionClickedSeeAll({
      category: trackingInfo.category,
      storeId: store.id,
    });

    navigate(to);
  }, [
    appMode,
    dispatch,
    initialQuery,
    isEmbeddedMode,
    flightProps,
    janeDeviceId,
    navigate,
    to,
    trackingInfo,
  ]);

  return onClick;
};

export const DEFAULT_PRODUCT_CARD_WIDTH = 200;

type Hits =
  | _DeepReadonlyObject<Hit<MenuProduct>[]>
  | _DeepReadonlyObject<MenuProduct[]>;
interface Props {
  currentCycleIndex?: number;
  flightProps?: AdData['flight'];
  hits: Hits | undefined;
  indexName?: string;
  isAd?: boolean;
  kindValue?: string;
  lastBucket: boolean;
  listView: boolean;
  name: string;
  noPaddingBottom?: boolean;
  numColumns?: number;
  numHits: number | undefined;
  outerSearchState: JaneSearchState<AlgoliaProduct>;
  placementIndex: number;
  productLocation?: string;
  specials?: StoreSpecial[];
  store: Store;
  zone: Zone;
}

export const ProductKindBucketCarousel = ({
  currentCycleIndex,
  flightProps,
  hits,
  indexName,
  isAd,
  kindValue,
  listView,
  name,
  noPaddingBottom,
  numHits,
  outerSearchState,
  placementIndex,
  productLocation,
  specials,
  store,
  zone,
  numColumns,
}: Props) => {
  const seeAllLinkSearchState = makeSeeAllLinkSearchState(
    [kindValue || name],
    outerSearchState,
    isAd
  );
  const onSeeAllLinkClick = useSeeAllLinkClick({
    flightProps,
    searchState: seeAllLinkSearchState,
    store,
    trackingInfo: {
      event: EventNames.ClickedSeeAll,
      category: name,
      linkLocation: 'carousel card',
      numberOfItems: numHits,
      flightId: flightProps?.id,
      creativeIds: flightProps?.creative_ids,
      productBrandId: flightProps?.product_brand.id,
      placementIndex,
      storeId: store && !isNoStore(store) ? store.id.toString() : undefined,
    },
  });

  const alphabeticalSort =
    !outerSearchState?.currentSort?.suffix?.includes('price') &&
    !outerSearchState?.currentSort?.suffix?.includes('rating') &&
    !outerSearchState?.currentSort?.suffix?.includes('potency');

  const sortHits = useCallback(
    (hits: Hits): Hits => {
      // default sort for best-selling is by overall sales and not alphabetical
      // this is temporary pending default state for all products
      if (alphabeticalSort) {
        if (kindValue === 'best_selling') {
          return [...hits]
            .sort(
              (a, b) => (a.best_seller_rank || 0) - (b.best_seller_rank || 0)
            )
            .slice(0, NUMBER_HITS_TO_RENDER);
        }
      }

      return (hits || []).slice(0, NUMBER_HITS_TO_RENDER);
    },
    [alphabeticalSort, kindValue]
  );

  const hitsToRender = useMemo(() => {
    return sortHits([...hits]);
  }, [sortHits, hits, isAd]);

  if (!hits) return null;

  return (
    <LoadingWrapper variant="carousel" isLoading={typeof numHits !== 'number'}>
      {numHits > 0 && (
        <Box
          aria-label={isAd ? 'sponsored content' : 'product carousel'}
          pb={!noPaddingBottom && 64}
        >
          <BucketHeader
            count={numHits}
            flightProps={flightProps}
            isAd={isAd}
            name={name}
            placementIndex={placementIndex}
            seeAllLinkSearchState={seeAllLinkSearchState}
            showSeeAll
            store={store}
          />
          {
            <ItemsCarousel
              storeMenu
              items={hitsToRender}
              itemMargin={ITEM_MARGIN_AMOUNT}
              arrowOffset={20}
              itemRenderer={({ item, index, itemWidth }) => {
                return index === NUMBER_HITS_TO_RENDER - 1 ? (
                  <SeeAllCard
                    key={isAd ? `ad-${name}` : name}
                    onClick={onSeeAllLinkClick}
                    productType={name}
                    store={store}
                  />
                ) : (
                  <HitProductCard
                    appliedWeightFilter={getAppliedWeightFilter(
                      outerSearchState
                    )}
                    specials={specials}
                    key={`${
                      isAd ? 'row-ad-' : item?.flight ? 'inline-ad-' : 'mp-'
                    }${
                      item.id ??
                      item.product_id ??
                      (item as Hit<MenuProduct>).objectID
                    }`}
                    listView={listView}
                    hit={item}
                    searchState={outerSearchState || {}}
                    store={store}
                    carouselView
                    rowPosition={placementIndex}
                    productLocation={productLocation}
                    columnPosition={index}
                    flightProps={flightProps}
                    algoliaIndexName={
                      isAd ? `products-${config.algoliaEnv}` : indexName
                    }
                    bucketName={name}
                    currentCycleIndex={currentCycleIndex}
                    itemWidth={itemWidth}
                    zone={zone}
                    numColumns={numColumns}
                  />
                );
              }}
            />
          }
        </Box>
      )}
    </LoadingWrapper>
  );
};
