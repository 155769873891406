import isEmpty from 'lodash/isEmpty';
import max from 'lodash/max';
import min from 'lodash/min';
import omit from 'lodash/omit';
import { useMemo } from 'react';

import type {
  AlgoliaFacets,
  AppOptions,
  FilterGroup,
  MenuOptions,
  RangeFilterGroup,
  SelectFilterGroup,
} from '@jane/search/types';
import {
  ACTIVITY_ICONS,
  CATEGORY_COLORS,
  CATEGORY_ICONS,
  FEELING_ICONS,
  JANE_GOLD_ICON,
  LINEAGE_COLORS,
  LINEAGE_ICONS,
  facetToFilter,
  getPriceBucketCount,
  getRatingBucketCount,
  sortAndFilterCategories,
  sortAndLabelSpecials,
  sortFilterItems,
  sortWeights,
} from '@jane/search/util';
import { useShouldShowGold } from '@jane/shared/hooks';
import type { Nullable } from '@jane/shared/types';

interface FormattedProductFilterProps {
  appOptions?: AppOptions;
  facets: AlgoliaFacets;
  menuOptions?: MenuOptions;
}

/**
 * Formats Algolia facets into filters ready for ui:
 * @param {AlgoliaFacets} [facets] - All product facets from Algolia.
 * @param {AppOptions} [appOptions] - App options that determine what renders where
 * @param {MenuOptions} [menuOptions] - Menu options encompass all customization options for store menus.
 */

export const useFormattedProductFilters = ({
  facets,
  appOptions,
  menuOptions,
}: FormattedProductFilterProps): FilterGroup[] => {
  const {
    customLabels,
    customRanking,
    customRows,
    disabledFilters,
    menuTabs,
    showOnlyWeights = [],
    showRatings,
    specials,
    specialsLabel,
    storeId,
    storeState,
  } = menuOptions || {};

  const { appMode, brandPage, brandSpecialPage, categoryPage, specialsPage } =
    appOptions || {};

  const showJaneGold = useShouldShowGold({
    ...(storeId && { store: { id: storeId, state: storeState } }),
  });

  return useMemo(() => {
    const branded = appMode === 'default';

    const filtersToOmit = disabledFilters || [];

    const categoryLabel = categoryPage ? 'Subcategory' : 'Category';

    const categoryFilters: Nullable<SelectFilterGroup> = facets.root_types
      ? {
          items: sortAndFilterCategories(
            facetToFilter(
              facets.root_types,
              {
                customLabels: customLabels?.root_types,
                branded,
                icons: CATEGORY_ICONS,
                iconColor: branded ? CATEGORY_COLORS : undefined,
                filterGroup: categoryLabel,
                storeId,
              },
              storeState
            ),
            customRanking,
            customRows,
            menuTabs,
            categoryPage ? undefined : storeId // ignore custom row & ranking on category pages
          ),
          key: 'root_types',
          label: categoryLabel,
          nested: true,
          showInFilterBar: true,
          type: 'multiselect',
        }
      : null;

    const brandFilters: Nullable<SelectFilterGroup> =
      !brandPage && facets.brand
        ? {
            items: sortFilterItems({
              items: facetToFilter(facets.brand, {
                filterGroup: 'Brand',
              }),
            }),
            key: 'brand',
            label: 'Brand',
            showInFilterBar: true,
            type: 'multiselect',
            typeahead: true,
          }
        : null;

    const activityFilters: Nullable<SelectFilterGroup> = facets.activities
      ? {
          items: facetToFilter(omit(facets.activities, filtersToOmit), {
            icons: ACTIVITY_ICONS,
          }),
          key: 'activities',
          label: 'Activity',
          type: 'multiselect',
          showInFilterBar: false,
        }
      : null;

    const feelingFilters: Nullable<SelectFilterGroup> = facets.feelings
      ? {
          items: facetToFilter(omit(facets.feelings, filtersToOmit), {
            icons: FEELING_ICONS,
          }),
          key: 'feelings',
          label: 'Feeling',
          showInFilterBar: !storeId,
          type: 'multiselect',
        }
      : null;

    const lineageFilters: Nullable<SelectFilterGroup> = facets.category
      ? {
          items: facetToFilter(facets.category, {
            customLabels: customLabels?.category,
            iconColor: LINEAGE_COLORS,
            icons: LINEAGE_ICONS,
            storeId,
          }),
          key: 'category',
          label: 'Lineage',
          showInFilterBar: true,
          type: 'multiselect',
        }
      : null;

    const hasSpecialsLabel = specialsLabel && specialsLabel?.trim().length > 0;

    const specialsFilters: Nullable<SelectFilterGroup> =
      !specialsPage && specials && facets.applicable_special_ids
        ? {
            key: 'applicable_special_ids',
            label: hasSpecialsLabel ? specialsLabel : 'Specials',
            showInFilterBar: true,
            type: 'multiselect',
            items: sortAndLabelSpecials(specials)(
              facets.applicable_special_ids,
              appMode
            ),
          }
        : null;

    const ratingFilters: Nullable<SelectFilterGroup> =
      showRatings && facets.aggregate_rating
        ? {
            key: 'aggregate_rating',
            label: 'Rating',
            showInFilterBar: false,
            modalItemsPerRow: 4,
            type: 'multiselect',
            items: getRatingBucketCount(facets.aggregate_rating),
          }
        : null;

    const thcPercentages = Object.keys(facets.percent_thc ?? {}).map(
      (percent) => parseFloat(percent)
    );
    const thcaPercentages = Object.keys(facets.percent_thca ?? {}).map(
      (percent) => parseFloat(percent)
    );
    const cbdPercentages = Object.keys(facets.percent_cbd ?? {}).map(
      (percent) => parseFloat(percent)
    );

    const potencyFilters: Nullable<RangeFilterGroup> = storeId
      ? {
          items: [
            {
              key: 'percent_thc',
              label: 'THC',
              max: max(thcPercentages) ?? 0,
              min: min(thcPercentages) ?? 0,
            },
            {
              key: 'percent_thca',
              label: 'THCa',
              max: max(thcaPercentages) ?? 0,
              min: min(thcaPercentages) ?? 0,
            },
            {
              key: 'percent_cbd',
              label: 'CBD',
              max: max(cbdPercentages) ?? 0,
              min: min(cbdPercentages) ?? 0,
            },
          ],
          key: 'percent_thc',
          label: '',
          nested: true,
          showInFilterBar: false,
          type: 'range',
        }
      : null;

    const showOnlyWeightFacets =
      !!showOnlyWeights?.length &&
      showOnlyWeights.reduce((prev, curr) => {
        const weight = curr.replace('_', ' ');
        const facet = facets.available_weights?.[weight];
        return facet ? { ...prev, [weight]: facet } : prev;
      }, {});

    const productWeights = showOnlyWeightFacets
      ? sortWeights(showOnlyWeightFacets)
      : facets.available_weights && sortWeights(facets.available_weights);

    const weightFilters: Nullable<SelectFilterGroup> =
      storeId && productWeights
        ? {
            items: facetToFilter(productWeights, {
              filterGroup: 'Weight',
            }),
            key: 'available_weights',
            label: 'Weight',
            showInFilterBar: false,
            modalItemsPerRow: 6,
            type: 'multiselect',
          }
        : null;

    const priceFilters: Nullable<SelectFilterGroup> = facets.bucket_price
      ? {
          key: 'bucket_price',
          label: 'Price',
          showInFilterBar: false,
          modalItemsPerRow: 5,
          type: 'multiselect',
          items: getPriceBucketCount(facets.bucket_price),
        }
      : null;

    const goldCounts = omit(facets.has_brand_discount, 'false');

    const janeGoldFilter: Nullable<SelectFilterGroup> =
      showJaneGold && !brandSpecialPage && !isEmpty(goldCounts)
        ? {
            key: 'has_brand_discount',
            label: 'Jane Gold',
            showInFilterBar: true,
            modalItemsPerRow: 2,
            type: 'singleselect',
            items: facetToFilter(goldCounts, {
              filterGroup: 'Jane Gold',
              customLabels: { true: 'Jane Gold' },
              icons: JANE_GOLD_ICON,
            }),
          }
        : null;

    return [
      janeGoldFilter,
      categoryFilters,
      brandFilters,
      lineageFilters,
      specialsFilters,
      feelingFilters,
      activityFilters,
      ratingFilters,
      potencyFilters,
      priceFilters,
      weightFilters,
    ].filter<FilterGroup>(
      (filterGroup): filterGroup is FilterGroup => filterGroup !== null
    );
  }, [facets, menuOptions, appOptions]);
};
