/**
 * DO NOT MODIFY THIS FILE!
 * It was created by the reefer-icons generator.
 * Please update & re-run the generator if need be.
 */
import type { SVGProps } from 'react';

import type { DynamicIconProps } from '../../../icon/icon.types';
import { Icon } from '../../icon';

const Indica16 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="100%"
    height="100%"
    fill="none"
    viewBox="0 0 16 16"
    {...props}
  >
    <g className="Indica 16">
      <g className="SVG">
        <path fill="#666" d="M7.245 4v8.502h1.508V4H7.245Z" />
        <path
          fill="#666"
          fillRule="evenodd"
          d="M0 4a4 4 0 0 1 4-4h8a4 4 0 0 1 4 4v8a4 4 0 0 1-4 4H4a4 4 0 0 1-4-4V4Zm4-3h8a3 3 0 0 1 3 3v8a3 3 0 0 1-3 3H4a3 3 0 0 1-3-3V4a3 3 0 0 1 3-3Z"
          clipRule="evenodd"
        />
      </g>
    </g>
  </svg>
);

const Indica24 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="100%"
    height="100%"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <g className="Indica 24">
      <rect
        width={22}
        height={22}
        x={1}
        y={1}
        stroke="#0E0E0E"
        strokeWidth={2}
        className="Rectangle 644"
        rx={3}
      />
      <path fill="#0E0E0E" d="M11.628 17V7.19h1.74V17h-1.74Z" className="I" />
    </g>
  </svg>
);

const SIZE_MAP = {
  sm: Indica16,
  md: Indica24,
  lg: Indica24,
  xl: Indica24,
  xxl: Indica24,
  xxxl: Indica24,
};

export const IndicaIcon = ({
  size = 'md',
  'data-testid': testId,
  ...props
}: DynamicIconProps) => {
  return (
    <Icon
      data-testid={testId || 'indica-icon'}
      icon={SIZE_MAP[size]}
      {...props}
      size={size}
    />
  );
};
