import type { ReactNode } from 'react';

import {
  Box,
  Breadcrumbs,
  Link,
  Typography,
  useMobileMediaQuery,
} from '@jane/shared/reefer';

export interface PageHeaderProps {
  breadcrumbCurrent?: string;
  breadcrumbs?: { title: string; url: string }[];
  subtitle?: string;
  title: string | ReactNode;
}

/**
 * Render a page header with title, subtitle and breadcrumbs.
 */
export const PageHeader = ({
  breadcrumbs,
  title,
  subtitle,
  breadcrumbCurrent,
}: PageHeaderProps) => {
  const isLargerThanMobile = useMobileMediaQuery({ size: 'lg', width: 'min' });
  return (
    <Box background="transparent">
      {breadcrumbs && breadcrumbCurrent && isLargerThanMobile && (
        <Breadcrumbs>
          {breadcrumbs.map((breadcrumb) => (
            <Link key={breadcrumb.title} to={breadcrumb.url}>
              {breadcrumb.title}
            </Link>
          ))}
          <Typography>{breadcrumbCurrent}</Typography>
        </Breadcrumbs>
      )}
      <Box mt={isLargerThanMobile ? 32 : 24}>
        {typeof title === 'string' && (
          <Typography variant="title-bold">{title}</Typography>
        )}
        {typeof title !== 'string' && title}
        {subtitle && (
          <Typography
            mt={isLargerThanMobile ? 8 : 0}
            variant="body"
            as="h2"
            color="text-light"
          >
            {subtitle}
          </Typography>
        )}
      </Box>
    </Box>
  );
};
