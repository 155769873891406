import styled from '@emotion/styled';

import { BagFilledIcon } from '@jane/shared/reefer';

import { CountDot } from '../countDot';

const CartIconWrapper = styled.div({
  cursor: 'pointer',
  position: 'relative',
});

interface Props {
  productCount?: number;
}

export const EmbeddedCart = ({ productCount = 0 }: Props) => {
  return (
    <CartIconWrapper data-testid="embedded-header-cart">
      <BagFilledIcon color="secondary" altText="Open Cart Drawer" />
      {productCount > 0 && (
        <CountDot ariaLabel="number of cart items" count={productCount} />
      )}
    </CartIconWrapper>
  );
};
