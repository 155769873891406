import type {
  ActiveFilters,
  ArrayFilterValue,
  CustomMenuLabels,
  DispensaryFilterKeys,
  ProductFilterKeys,
  ProductReviewFilterKeys,
  RangeFilterValue,
} from '@jane/search/types';
import { StarFilledIcon } from '@jane/shared/reefer';

import { formatFilterLabel } from './formatFilterLabel';
import { getPriceLabel } from './getPriceLabel';
import { getWeightLabel } from './getWeightLabel';

export interface ActiveFilterOptions {
  customLabels?:
    | CustomMenuLabels['category']
    | CustomMenuLabels['root_types']
    | { [label: string]: string };
  storeId?: string | number;
}

export const formatActiveFilterLabel = (
  filterKey: ProductFilterKeys | DispensaryFilterKeys | ProductReviewFilterKeys,
  filterItems: ArrayFilterValue[],
  options?: ActiveFilterOptions
): ActiveFilters[] => {
  const { customLabels, storeId } = options || {};

  const itemLabelToRender = (labelValue: ArrayFilterValue) => {
    if (filterKey === 'brand') {
      return labelValue;
    }

    if (filterKey === 'available_weights') {
      return getWeightLabel(labelValue);
    }

    if (filterKey === 'bucket_price') {
      return getPriceLabel(labelValue);
    }

    if (filterKey === 'aggregate_rating') {
      return `${labelValue.split(':')[0]} and up`;
    }

    if (filterKey === 'rating') {
      return `${labelValue} star`;
    }

    return formatFilterLabel(labelValue, customLabels, storeId);
  };

  return filterItems.map((filter) => {
    return {
      icon: filterKey === 'aggregate_rating' ? StarFilledIcon : undefined,
      key: filterKey,
      label: itemLabelToRender(filter),
      value: filter,
    };
  });
};

export const formatActivePotencyFilterLabel = (
  filterKey: ProductFilterKeys,
  filter: RangeFilterValue
) => {
  const { max, min } = filter || {};
  const potencyLabel = filterKey === 'percent_cbd' ? 'CBD' : 'THC';

  let filterLabel = `${potencyLabel} ${min}-${max}%`;

  if (!max) {
    filterLabel = `${potencyLabel} ${min}% and up`;
  }

  if (!min) {
    filterLabel = `${potencyLabel} 0-${max}%`;
  }

  return (
    (min || max) && {
      key: filterKey,
      label: filterLabel,
      value: filter,
    }
  );
};
