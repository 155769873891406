import type { QueryClientConfig } from '@tanstack/react-query';

/*
 * This default config sets the query `staleTime` to 10 minutes and `cacheTime` to 3 minutes.
 *
 * - `staleTime`: How long data remains fresh in the cache before requiring a refetch.
 * - `cacheTime`: How long unused data remains in the cache before being garbage collected.
 *   The timer only starts once the query is inactive (i.e., not in use).
 *
 * Example: While a user navigates menus, each menu fetches and caches its store's data.
 * For 10 minutes, while navigating a menu, that store's data is pulled from the cache without refetching.
 * If a user leaves the menu, the data is garbage collected after 3 minutes of inactivity.
 *
 * Note: For SSR apps, set `cacheTime` to Infinity since garbage collection is handled between requests.
 */

export const defaultQueryClientConfig: QueryClientConfig = {
  defaultOptions: {
    queries: {
      retry: false,
      refetchOnWindowFocus: false,
      staleTime: 10 * 60 * 1000,
      cacheTime: 3 * 60 * 1000,
    },
    mutations: {
      retry: false,
    },
  },
};

export const STALE_TIMES = {
  // Zero; for data that must always be up to date and requires real-time freshness
  zero: 0,
  // Three Minutes; for data that updates frequently or must remain relatively fresh, but can tolerate brief staleness
  short: 3 * 60 * 1000,
  // Thirty Minutes; for data that updates occasionally and doesn't need to be refreshed immediately
  medium: 30 * 60 * 1000,
  // 24 Hours; for data that is rarely updated and can remain fresh for long periods
  long: 24 * 60 * 60 * 1000,
  // Infinity; for data that almost never changes and can be considered fresh indefinitely
  infinity: Infinity,
};
