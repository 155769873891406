/**
 * DO NOT MODIFY THIS FILE!
 * It was created by the reefer-icons generator.
 * Please update & re-run the generator if need be.
 */
import type { SVGProps } from 'react';

import type { DynamicIconProps } from '../../../icon/icon.types';
import { Icon } from '../../icon';

const Search24 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="100%"
    height="100%"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <g className="Search 24">
      <path
        fill="#0E0E0E"
        fillRule="evenodd"
        d="M17 11a6 6 0 1 1-12 0 6 6 0 0 1 12 0Zm-1.094 6.32a8 8 0 1 1 1.414-1.414l3.387 3.387a1 1 0 0 1-1.414 1.414l-3.387-3.387Z"
        className="SVG"
        clipRule="evenodd"
      />
    </g>
  </svg>
);

const SIZE_MAP = {
  md: Search24,
  sm: Search24,
  lg: Search24,
  xl: Search24,
  xxl: Search24,
  xxxl: Search24,
};

export const SearchIcon = ({
  size = 'md',
  'data-testid': testId,
  ...props
}: DynamicIconProps) => {
  return (
    <Icon
      data-testid={testId || 'search-icon'}
      icon={SIZE_MAP[size]}
      {...props}
      size={size}
    />
  );
};
