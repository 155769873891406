import clsx from 'clsx';
import pickBy from 'lodash/pickBy';
import type {
  CSSProperties,
  ElementType,
  MouseEventHandler,
  ReactNode,
} from 'react';

import {
  getAccessibilityProps,
  getCSSVar,
  toCSSSize,
} from '../../internal/utils';
import type { AccessibilityProps } from '../../internal/utils';
import type { BorderRadiusKey, ColorKey } from '../../theme';
import type { MarginProperties, PaddingProperties } from '../../utils';
import {
  fakeButtonProps,
  getColorCSSVar,
  handleEnterKey,
  prefixStyles,
  spacingCSSVars,
} from '../../utils';
import type { ReeferBaseProps } from '../../utils/baseProps.types';
import styles from './box.module.css';

export interface BoxProps
  extends AccessibilityProps,
    ReeferBaseProps,
    MarginProperties,
    PaddingProperties {
  /**
   * Used to identify the component in the accessibility related error message that's thrown when the component is clickable and accessibility props are missing.
   * You do not need to over-ride this; it is used internally in Reefer.
   * */
  accessibilityComponentName?: string;

  /** Render the component as this HTML element. Defaults to `div`. */
  as?: keyof JSX.IntrinsicElements;

  /** Add a background color to the component */
  background?: ColorKey;

  /** Add a 1px solid border around the component */
  border?: ColorKey | 'none';

  /** Add a border radius to the component using available theme sizes */
  borderRadius?: BorderRadiusKey;

  /** Optionally change the border width, defaults to 1px */
  borderWidth?: string;

  /** CSS bottom attribute of element */
  bottom?: string | number;

  /** Child elements of the component */
  children?: ReactNode;

  'data-testid'?: string;

  /** Set a height, accepts any valid `height` value */
  height?: string | number;

  /** For attribute, for labels only, must match id of input label is for. **/
  htmlFor?: string;

  /** CSS left attribute of element */
  left?: string | number;

  /** Set item `max-height`, accepts any valid `max-height` value */
  maxHeight?: string | number;

  /** Set item `max-width`, accepts any valid `max-width` value */
  maxWidth?: string | number;

  /** Set item `min-height`, accepts any valid `min-height` value */
  minHeight?: string | number;

  /** Set item `min-width`, accepts any valid `min-width` value */
  minWidth?: string | number;

  /** An optional `onClick` handler */
  onClick?: MouseEventHandler<HTMLElement>;

  /** An optional `onMouseEnter` handler */
  onMouseEnter?: (event: React.MouseEvent<HTMLDivElement>) => void;

  /** An optional `onMouseLeave` handler */
  onMouseLeave?: (event: React.MouseEvent<HTMLDivElement>) => void;

  /** Set item `overflow`, accepts any valid `overflow` value */
  overflow?: string;

  /** CSS position attribute of element */
  position?:
    | 'static'
    | 'relative'
    | 'absolute'
    | 'fixed'
    | 'sticky'
    | 'inherit';

  /** CSS right attribute of element */
  right?: string | number;

  /** HTML `title` attribute, displays the supplied text as a tooltip. Note: Use
   * `title` sparingly as it is not very accessible and doesn't show up on touch
   * interfaces. */
  title?: string;

  /** CSS top attribute of element */
  top?: string | number;

  /** Set a width, accepts any valid `width` value */
  width?: string | number;

  /** Set item `z-index`, accepts any valid `z-index` value */
  zIndex?: string | number;
}

/**
 * The `Box` component is a wrapping container component with `margin` and `padding`
 * spacing props. Additional style props including `background`, `border`, `borderRadius`, `height` and `width` are also available.
 * */

export function Box({
  accessibilityComponentName = 'Box',
  'aria-label': ariaLabel,
  'aria-labelledby': ariaLabelledBy,
  ariaLabel: ariaLabel_toBeDeprecated,
  ariaLabelledBy: ariaLabelledBy_toBeDeprecated,
  as = 'div',
  background = 'transparent',
  border = 'none',
  borderRadius,
  borderWidth = '1px',
  bottom = 'auto',
  children,
  className,
  'data-testid': testId,
  height = 'auto',
  htmlFor,
  id,
  left = 'auto',
  maxHeight = 'none',
  maxWidth = 'none',
  minHeight = 'auto',
  minWidth = 'auto',
  onClick,
  onMouseEnter,
  onMouseLeave,
  overflow = 'visible',
  position = 'static',
  right = 'auto',
  role,
  style,
  title,
  top = 'auto',
  width = 'auto',
  zIndex = 'auto',
  ...props
}: BoxProps) {
  const a11yProps = getAccessibilityProps(
    {
      ariaLabel: ariaLabel || ariaLabel_toBeDeprecated,
      ariaLabelledBy: ariaLabelledBy || ariaLabelledBy_toBeDeprecated,
      role,
      onClick,
    },
    accessibilityComponentName
  );

  const dataAttributes = pickBy(props, (value: string, key: string) =>
    key.startsWith('data-')
  );
  const spacingProps = pickBy(
    props,
    (value: string, key: string) => !key.startsWith('data-')
  );

  const RenderAs = as as ElementType;

  return (
    <RenderAs
      className={clsx(className, styles.box, {
        [styles.box__clickable]: !!onClick,
      })}
      data-testid={testId}
      {...dataAttributes}
      htmlFor={htmlFor}
      id={id}
      onClick={onClick}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      onKeyUp={(event: React.KeyboardEvent) => handleEnterKey(event, onClick)}
      {...(onClick && fakeButtonProps)}
      style={prefixStyles({
        '--box-background-color': getColorCSSVar(background),
        '--box-border':
          border === 'none' ? 'none' : `solid ${getColorCSSVar(border)}`,
        '--box-border-width': borderWidth,
        '--box-border-radius': borderRadius
          ? getCSSVar(`--border-radius-${borderRadius}`)
          : 0,
        '--box-bottom': toCSSSize(bottom),
        '--box-height': toCSSSize(height),
        '--box-left': toCSSSize(left),
        '--box-max-height': toCSSSize(maxHeight),
        '--box-max-width': toCSSSize(maxWidth),
        '--box-min-height': toCSSSize(minHeight),
        '--box-min-width': toCSSSize(minWidth),
        '--box-position': position,
        '--box-overflow': overflow,
        '--box-right': toCSSSize(right),
        '--box-top': toCSSSize(top),
        '--box-width': toCSSSize(width),
        '--box-z-index': zIndex,
        ...spacingCSSVars(spacingProps),
        ...style,
      } as CSSProperties)}
      {...a11yProps}
      title={title}
    >
      {children}
    </RenderAs>
  );
}
