import { createContext, useContext, useState } from 'react';
import type { ReactNode } from 'react';

interface ResizeIframe {
  activeResizer: string | undefined;
  registerResizer: (id: string) => void;
  unregisterResizer: (id: string) => void;
}

const ResizeIframeContext = createContext<ResizeIframe>({
  activeResizer: undefined,
  registerResizer: () => null,
  unregisterResizer: () => null,
});

interface Props {
  children: ReactNode;
}

export const ResizeIframeProvider = ({ children }: Props) => {
  const [registered, setRegistered] = useState<string[]>([]);

  const handleRegisterResizer = (id: string) => {
    // If it's already been registered, but we're calling it again,
    // just move it to the front
    if (registered.includes(id) && registered[0] !== id) {
      setRegistered((prevValue) => {
        const index = prevValue.indexOf(id);
        return [id, ...prevValue.splice(index, 1)];
      });
    }

    if (!registered.includes(id)) {
      setRegistered((prevValue) => [id, ...prevValue]);
    }
  };

  const handleUnregisterResizer = (id: string) => {
    setRegistered((prevValue) =>
      prevValue.filter((registered) => registered !== id)
    );
  };

  const value = {
    activeResizer: registered[0],
    registerResizer: handleRegisterResizer,
    unregisterResizer: handleUnregisterResizer,
  };

  return (
    <ResizeIframeContext.Provider value={value}>
      {children}
    </ResizeIframeContext.Provider>
  );
};

export const useIframeResizerRegistry = () => {
  const context = useContext(ResizeIframeContext);

  if (context === undefined) {
    throw new Error(
      'useIframeResizerRegistry must be used within a ResizeIframeProvider'
    );
  }

  return context;
};
