import styled from '@emotion/styled';

import { Flex } from '@jane/shared/reefer';
import { shadows } from '@jane/shared/reefer-emotion';

interface AppliedPromoCodeSpecialWrapperProps {
  enabled: boolean;
}
export const AppliedPromoCodeSpecialWrapper = styled(
  Flex
)<AppliedPromoCodeSpecialWrapperProps>(({ enabled }) => ({
  '&hover': {
    cursor: enabled ? 'pointer' : 'auto',
  },
}));

export const SpecialsWrapper = styled(Flex)<{ headless?: boolean }>(
  ({ theme, headless }) => ({
    boxShadow: headless ? 'none' : shadows.divider,
    backgroundColor: theme.colors.grays.white,
  })
);
