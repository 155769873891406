/**
 * DO NOT MODIFY THIS FILE!
 * It was created by the reefer-icons generator.
 * Please update & re-run the generator if need be.
 */
import type { SVGProps } from 'react';

import type { DynamicIconProps } from '../../../icon/icon.types';
import { Icon } from '../../icon';

const HeartFilled24 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="100%"
    height="100%"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <g className="Heart filled 24">
      <path
        fill="#FFC220"
        d="M12 4.824C11.076 3.642 9.288 2 6.478 2 2.866 2 0 5.163 0 8.29c0 3.129 1.61 5.579 4.83 8.397 3.209 2.81 4.31 3.093 7.156 4.574 2.874-1.481 3.977-1.764 7.185-4.574C22.391 13.869 24 11.419 24 8.291 24 5.163 21.135 2 17.522 2 14.712 2 12.924 3.642 12 4.824Z"
        className="Fill-Main"
      />
    </g>
  </svg>
);

const SIZE_MAP = {
  md: HeartFilled24,
  sm: HeartFilled24,
  lg: HeartFilled24,
  xl: HeartFilled24,
  xxl: HeartFilled24,
  xxxl: HeartFilled24,
};

export const HeartFilledIcon = ({
  size = 'md',
  'data-testid': testId,
  ...props
}: DynamicIconProps) => {
  return (
    <Icon
      data-testid={testId || 'heart-filled-icon'}
      icon={SIZE_MAP[size]}
      {...props}
      size={size}
    />
  );
};
