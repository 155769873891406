import styled from '@emotion/styled';

import { spacing } from '@jane/shared/reefer-emotion';

export const StoreRedeemableListItem = styled.li({
  listStyleType: 'none',
  position: 'relative',
  left: 12,
  '&::before': {
    content: '"•"',
    position: 'absolute',
    left: -12,
  },
  ...spacing({ mb: 8 }),
});

export const ApplyableDiscountWrapper = styled.div<{ enabled?: boolean }>(
  ({ enabled }) => ({
    display: 'flex',
    alignItems: 'center',
    '&:hover': {
      cursor: enabled ? 'pointer' : 'auto',
    },
  })
);

export const ApplyableDiscountItem = styled.li({
  listStyleType: 'none',
});

export const UnlockWalletWrapper = styled.div<{ headless?: boolean }>(
  ({ theme, headless }) => ({
    border: headless ? 'none' : `1px solid ${theme.colors.grays.light}`,
    boxShadow: headless ? 'none' : '0px 2px 5px rgba(224, 229, 238, 0.5)',
    borderRadius: 4,
    boxSizing: 'border-box',
    background: theme.colors.grays.white,
    ...spacing({ px: 12, py: 16 }),
  })
);
