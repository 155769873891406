import { useGetStore } from '@jane/shared/data-access';
import type { DeliveryValidation } from '@jane/shared/models';
import { Banner } from '@jane/shared/reefer';

import { useCustomerSelector } from '../../../../customer/selectors';
import { generateCartLimitReport } from '../../../../lib/cartLimitPolicy';
import checkoutDisabledMessageGenerator from '../../../../lib/checkoutDisabledMessageGenerator';
import type { CartLineItems } from '../../../../lib/getCartLineItems';
import minimumDeliveryOrder from '../../../../lib/minimumDeliveryOrder';
import { minimumPickupOrder } from '../../../../lib/minimumPickupOrder';
import {
  reservationModeLabel,
  snakeCaseToCamelCaseStoreDeserializer,
} from '../../../../lib/store';
import { CartAlertsWrapper } from './cartDrawerAlerts.styles';
import { CartLimitPolicyAlert } from './cartLimitPolicyAlert/cartLimitPolicyAlert';
import { CartMinimumAlert } from './cartMinimumAlert/cartMinimumAlert';

interface CartDrawerAlertsProps {
  cartLineItems: CartLineItems;
  deliveryValidationData: DeliveryValidation;
}

export const CartDrawerAlerts = ({
  cartLineItems,
  deliveryValidationData,
}: CartDrawerAlertsProps) => {
  const { cart } = useCustomerSelector(({ cart: { cart } }) => ({
    cart,
  }));

  const { discountedSubtotal } = cartLineItems;
  const { reservation_mode } = cart;
  const { data: store } = useGetStore(cart?.store.id);
  const schedules = snakeCaseToCamelCaseStoreDeserializer(store);

  const cartReport =
    store && store.cart_limit_policy
      ? generateCartLimitReport({
          cart,
          store,
        })
      : null;

  const checkoutDisabledMessage = checkoutDisabledMessageGenerator(
    store,
    schedules,
    cartLineItems.total,
    reservation_mode
  );

  const minimumMet = () => {
    if (reservation_mode === 'delivery') {
      return minimumDeliveryOrder({
        discountedSubtotal,
        store,
        deliveryValidateData: deliveryValidationData,
      });
    }
    if (reservation_mode === 'pickup') {
      return minimumPickupOrder(discountedSubtotal, store);
    }
    return true;
  };

  const renderCheckoutAlert =
    checkoutDisabledMessage ||
    (cartReport && cartReport?.over) ||
    !minimumMet();

  return (
    renderCheckoutAlert && (
      <CartAlertsWrapper>
        {checkoutDisabledMessage && (
          <Banner label={checkoutDisabledMessage} variant="error" full />
        )}

        {cartReport && cartReport?.over && (
          <CartLimitPolicyAlert report={cartReport} />
        )}

        {!minimumMet() && (
          <CartMinimumAlert
            reservationMode={reservation_mode}
            discountedSubtotal={discountedSubtotal}
            store={store}
            modeLabel={reservationModeLabel(store, reservation_mode)}
            deliveryValidateData={deliveryValidationData}
          />
        )}
      </CartAlertsWrapper>
    )
  );
};
