import clsx from 'clsx';
import type { CSSProperties } from 'react';
import { useCallback, useEffect, useMemo, useState } from 'react';

import { toCSSSize } from '../../internal/utils';
import { useReeferTheme } from '../providers';
import { Skeleton } from '../skeleton';
import fallback from './assets/fallbackImage.svg';
import { getResponsiveImageAttributes } from './getResponsiveImageAttributes';
import styles from './image.module.css';
import type { ImageProps } from './image.types';

/**
 * Image component used to render images throughout the apps
 */

export function Image({
  altText,
  aspectRatio = 'auto',
  border,
  borderRadius = 'none',
  className,
  'data-testid': testId,
  fallbackSrc = fallback,
  id,
  height = 'auto',
  loading = 'lazy',
  objectFit = 'cover',
  objectPosition = '50% 50%',
  responsive = false,
  sizes,
  src,
  srcSet,
  style,
  width = '100%',
}: ImageProps) {
  const [imageError, setImageError] = useState(false);
  const [imgSrc, setImgSrc] = useState(src);
  const [imgSrcSet, setImgSrcSet] = useState<string | undefined>(srcSet);
  const [imgSizes, setImgSizes] = useState<string | undefined>(sizes);
  const theme = useReeferTheme();

  if (borderRadius === 'circular' && height !== width) {
    throw Error(
      'Height and width need to be equivalent to use a circular border radius.'
    );
  }

  const handleImageError = useCallback(() => {
    setImageError(true);
    setImgSrc(fallbackSrc);
    setImgSrcSet(undefined);
    setImgSizes(undefined);
  }, [fallbackSrc]);

  const responsiveImg = useMemo(
    () =>
      responsive
        ? getResponsiveImageAttributes({
            imageSize: sizes ?? 'default',
            src,
          })
        : null,
    [responsive, sizes, src]
  );

  const getBorderRadius = (imgRadius: string): string => {
    let radius = imgRadius;

    if (imgRadius === 'none') {
      radius = 'none';
    }

    if (imgRadius === 'rounded') {
      radius = theme.borderRadius.lg as string;
    }

    if (borderRadius === 'rounded-small') {
      radius = theme.borderRadius.sm as string;
    }

    if (imgRadius === 'circular') {
      radius = '50%';
    }

    return radius;
  };

  useEffect(() => {
    if (responsiveImg && !responsiveImg.invalid) {
      setImgSrc(responsiveImg.imageSrc);
      setImgSrcSet(responsiveImg.srcSet);
      setImgSizes(responsiveImg.sizes);
    }
  }, [responsiveImg]);

  useEffect(() => {
    if (typeof src !== 'string') {
      handleImageError();
    }
  }, [src, handleImageError]);

  // if an image is responsive, we need to wait for the correct image source
  if (
    !imageError &&
    responsive &&
    !responsiveImg?.invalid &&
    responsiveImg?.imageSrc !== imgSrc
  ) {
    return <Skeleton.Bone height={height} width={width} />;
  }

  return (
    <div
      className={clsx(className, styles.image, {
        [styles.image__border]: border,
      })}
      id={id}
      style={
        {
          '--image-border-radius': getBorderRadius(borderRadius),
          '--image-height': toCSSSize(height),
          '--image-width': toCSSSize(width),
          ...style,
        } as CSSProperties
      }
    >
      <img
        className={clsx(styles.image_img)}
        style={
          {
            '--image-aspect-ratio': aspectRatio,
            '--image-object-fit': objectFit,
            '--image-object-position': objectPosition,
          } as CSSProperties
        }
        alt={imageError ? '' : altText}
        height={height}
        loading={loading}
        onError={handleImageError}
        sizes={imgSizes}
        src={imgSrc}
        srcSet={imgSrcSet}
        data-test-id={testId}
        title={imageError ? 'Failed to load this image.' : altText}
        width={width}
      />
    </div>
  );
}
