export const PROTOCOL_REGEX = /^https?:\/\//;
export const DOMAIN_REGEX =
  /^(https?:\/\/)?([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}(:\d+)?(\/.*)?$/;

/**
 * @params endpoint Eg. 'https://the.weed.shop', 'https://the.weed.shop/', 'https://the.weed.shop/try-to-hack'
 * @params fallback Eg. 'https://the.fallback.weed.shop'
 * @returns sanitized string Eg. 'https://the.weed.shop'
 */
export const sanitizeEndpointString = (
  endpoint: string | undefined,
  fallback: string
): string => {
  const containsProtocol = PROTOCOL_REGEX.test(endpoint ?? fallback);
  const endpointWithProtocol = containsProtocol
    ? endpoint ?? fallback
    : `https://${endpoint}`;

  const sanitizedEndpoint = new URL(endpointWithProtocol);

  if (DOMAIN_REGEX.test(sanitizedEndpoint.toString())) {
    return sanitizedEndpoint.protocol + '//' + sanitizedEndpoint.host;
  } else {
    return fallback;
  }
};
