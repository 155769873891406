import type { Id } from '@jane/shared/models';

const baseKey = ['store'];
export const queryKeys = {
  commsBanner: (storeId: Id) => [...baseKey, 'commsBanner', storeId],
  seo: (storeId: Id) => [...baseKey, 'seo', storeId],
  store: (storeId: Id) => [...baseKey, storeId],
};

export const urls = {
  commsBanner: (storeId: Id) => `/stores/${storeId}/banner_message_settings`,
  seo: (storeId: Id) => `/stores/${storeId}/seo`,
  store: (storeId: Id) => `/stores/${storeId}`,
};
