export const REEFER_MEDIA_QUERY_MOBILE_SIZES = [
  'sm',
  'md',
  'lg',
  'legacy',
] as const;
export const REEFER_MEDIA_QUERY_DESKTOP_SIZES = ['sm', 'md', 'lg'] as const;

export const REEFER_MEDIA_QUERY_SIZES = {
  'mobile-sm': '320px',
  'mobile-md': '375px',
  'mobile-lg': '425px',
  'mobile-legacy': '480px',
  tablet: '768px',
  'desktop-sm': '1024px',
  'desktop-md': '1440px',
  'desktop-lg': '2560px',
};

export type ReeferMediaQueryMobileSize =
  typeof REEFER_MEDIA_QUERY_MOBILE_SIZES[number];
export type ReeferMediaQueryDesktopSize =
  typeof REEFER_MEDIA_QUERY_DESKTOP_SIZES[number];

export type ReeferMediaQueryParam = 'min' | 'max';

export const mediaQueries = {
  desktop: (
    size: ReeferMediaQueryDesktopSize,
    width: ReeferMediaQueryParam
  ) => {
    const screenSize = REEFER_MEDIA_QUERY_SIZES[`desktop-${size}`];
    return `@media (${width}-width: ${screenSize})`;
  },
  mobile: (size: ReeferMediaQueryMobileSize, width: ReeferMediaQueryParam) => {
    const screenSize = REEFER_MEDIA_QUERY_SIZES[`mobile-${size}`];
    return `@media (${width}-width: ${screenSize})`;
  },
  tablet: (width: ReeferMediaQueryParam) => {
    return `@media (${width}-width: ${REEFER_MEDIA_QUERY_SIZES['tablet']})`;
  },
};
