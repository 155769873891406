import { config } from '@jane/shared/config';

import {
  trackClickedObjectIDs,
  trackConvertedFilters,
  trackConvertedObjectIDs,
  trackViewedFilters,
} from './algolia';
import type { TrackedEvent, ViewedProduct } from './eventNames';
import { EventNames } from './eventNames';
import {
  gaTrackAddedProductToCart,
  gaTrackCheckoutSuccess,
  gaTrackProductClick,
  gaTrackRemovedProductFromCart,
} from './googleAnalytics';

export const track = (event: TrackedEvent) => {
  const { event: eventName, ...meta } = event;

  const isKioskMode = 'appMode' in meta && meta.appMode === 'kiosk';

  if (config.dev && !isKioskMode) {
    // eslint-disable-next-line no-console
    console.log('Tracking', eventName, meta);
    return;
  }

  !isKioskMode && janeMixpanel?.track(eventName, meta);

  switch (event.event) {
    case EventNames.ViewedProduct:
      gaTrackProductClick(event as ViewedProduct);
      break;
    case EventNames.AddedProductToCart:
      trackConvertedObjectIDs(event);
      trackConvertedFilters(event);
      gaTrackAddedProductToCart(event);
      break;
    case EventNames.RemovedProductFromCart:
      gaTrackRemovedProductFromCart(event);
      break;
    case EventNames.ClickedMarketplaceProductCard:
    case EventNames.ClickedMenuProductCard:
    case EventNames.UpdatedProductInCart:
      trackClickedObjectIDs(event);
      // TODO – eventually, we will likely want to handle this event for GA EC
      // however, for now, we are skipping it because it requires a non-trivial "re-plumbing" of the event
      break;
    case EventNames.SearchedMenuProducts:
    case EventNames.SearchedProducts:
    case EventNames.SearchedDispensaries:
      trackViewedFilters(event);
      break;
    case EventNames.LoadedCartPage:
      // GA4 Collects page_views automatically
      break;
    case EventNames.CheckoutSuccess:
      trackConvertedObjectIDs(event);
      trackConvertedFilters(event);
      gaTrackCheckoutSuccess(event);
      break;
    default:
      return;
  }
};
