import type { CSSProperties } from 'react';

import { toCSSSize } from '../internal/utils';
import type { MarginProperties, PaddingProperties } from './spacing.types';

export function marginCSSVars({
  m,
  mx,
  my,
  mt,
  mr,
  mb,
  ml,
}: MarginProperties): CSSProperties {
  return {
    '--margin-top': toCSSSize(mt || my || m || 0),
    '--margin-right': toCSSSize(mr || mx || m || 0),
    '--margin-bottom': toCSSSize(mb || my || m || 0),
    '--margin-left': toCSSSize(ml || mx || m || 0),
  } as CSSProperties;
}

export function paddingCSSVars({
  p,
  px,
  py,
  pt,
  pr,
  pb,
  pl,
}: PaddingProperties): CSSProperties {
  return {
    '--padding-top': toCSSSize(pt || py || p || 0),
    '--padding-right': toCSSSize(pr || px || p || 0),
    '--padding-bottom': toCSSSize(pb || py || p || 0),
    '--padding-left': toCSSSize(pl || px || p || 0),
  } as CSSProperties;
}

export function spacingCSSVars(
  args: MarginProperties & PaddingProperties
): CSSProperties {
  return {
    ...marginCSSVars(args),
    ...paddingCSSVars(args),
  };
}
