import { z } from 'zod';

const AlgoliaDispensaryGeoLoc = z.object({
  lat: z.number(),
  lng: z.number(),
});

const DispensaryWorkingHourPeriod = z.object({
  from: z.string(),
  to: z.string(),
});
const DispensaryWorkingHour = z.object({
  day: z.string(),
  period: DispensaryWorkingHourPeriod,
});

export type DispensaryWorkingHour = z.infer<typeof DispensaryWorkingHour>;

const DispensaryDealEnum = z.enum([
  'Daily deals',
  'Medical discounts',
  'Student discounts',
  'Veteran discounts',
  'Senior discounts',
]);

const DispensaryOptionEnum = z.enum(['accessible', 'cash', 'credit', 'debit']);

const OptionalArrayOrString = z.union([z.string(), z.array(z.string())]);

export const AlgoliaDispensarySchema = z.object({
  _geoloc: AlgoliaDispensaryGeoLoc.nullable(),
  allow_future_day_ordering: z.boolean(),
  allow_off_hours_ordering: z.boolean(),
  avg_response_time: z.number().nullable(),
  city: z.string().nullable(),
  current_deals: z.union([z.array(DispensaryDealEnum), z.null()]),
  curbside_hours: z.union([z.array(DispensaryWorkingHour), z.null()]),
  curbside_interval: z.number(),
  curbside_last_call_interval: z.number(),
  curbside_max_lead_time: z.number(),
  curbside_max_lead_time_minutes: z.number(),
  curbside_min_lead_time: z.number(),
  curbside_min_lead_time_minutes: z.number(),
  curbside_window: z.number(),
  delivery: z.boolean(),
  delivery_id_required: z.boolean(),
  delivery_hours: z.union([z.array(DispensaryWorkingHour), z.null()]),
  delivery_last_call_interval: z.number(),
  delivery_max_lead_time: z.number(),
  delivery_max_lead_time_minutes: z.number(),
  delivery_min_lead_time: z.number(),
  delivery_min_lead_time_minutes: z.number(),
  delivery_minimum: z.number(),
  delivery_radius: z.union([z.number(), z.null()]),
  display_curbside_windows_by_day: z.union([z.boolean(), z.null()]),
  full_address: z.string().nullable(),
  google_map_url: z.string().nullable(),
  last_call_interval: z.number(),
  lat: z.number().nullable(),
  long: z.number().nullable(),
  medical: z.boolean(),
  metro_area: z.string(),
  name: z.string(),
  objectID: z.string(),
  open_now_boolean: z.boolean().nullable(),
  ownership_identification: OptionalArrayOrString,
  phone: z.string(),
  photo: z.string(),
  pickup: z.boolean(),
  pickup_hours: z.union([z.array(DispensaryWorkingHour), z.null()]),
  pickup_id_required: z.boolean(),
  pickup_max_lead_time: z.number(),
  pickup_max_lead_time_minutes: z.number(),
  pickup_min_lead_time: z.number(),
  pickup_min_lead_time_minutes: z.number(),
  pickup_minimum: z.number(),
  primary_gallery_image: z.union([z.string(), z.null()]),
  product_count: z.number().nullable(),
  rating: z.number().nullable(),
  recreational: z.boolean(),
  reviews_count: z.number().nullable(),
  state: z.string().nullable(),
  store_options: z.union([
    z.array(DispensaryOptionEnum),
    z.string().array(),
    z.null(),
  ]),
  time_zone_identifier: z.string(),
  type: z.array(z.string()),
  url_slug: z.string().nullable(),
  working_hours: z.array(DispensaryWorkingHour),
});

export type AlgoliaDispensary = z.infer<typeof AlgoliaDispensarySchema>;
