import styled from '@emotion/styled';

import { Link } from '@jane/shared/reefer';

export const LinkStyled = styled(Link)({
  display: 'block',
  textDecoration: 'none',
  width: '100%',
  ':focus-visible': {
    outline: 'none',
    '[role=img]:before': {
      backgroundColor: 'rgba(0, 0, 0, 0.1)',
      borderRadius: 'inherit',
      content: '""',
      height: '100%',
      position: 'absolute',
      width: '100%',
    },
  },
});
