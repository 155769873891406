export const categoryAndNameAsc = 'category-and-name-asc';
export const categoryAndNameDesc = 'category-and-name-desc';
export const defaultOrder = 'default';
export const priceAsc = 'price-asc';
export const priceDesc = 'price-desc';
export const priceEighthOunceAsc = 'price-eighth-ounce-asc';
export const priceEighthOunceDesc = 'price-eighth-ounce-desc';
export const priceGramAsc = 'price-gram-asc';
export const priceGramDesc = 'price-gram-desc';
export const priceHalfGramAsc = 'price-half-gram-asc';
export const priceHalfGramDesc = 'price-half-gram-desc';
export const priceHalfOunceAsc = 'price-half-ounce-asc';
export const priceHalfOunceDesc = 'price-half-ounce-desc';
export const priceOunceAsc = 'price-ounce-asc';
export const priceOunceDesc = 'price-ounce-desc';
export const priceQuarterOunceAsc = 'price-quarter-ounce-asc';
export const priceQuarterOunceDesc = 'price-quarter-ounce-desc';
export const priceTwoGramAsc = 'price-two-gram-asc';
export const priceTwoGramDesc = 'price-two-gram-desc';
export const rating = 'rating';
export const recommendation = 'recommendation';
export const specialAndReview = 'special-and-review';
export const thcPotencyAsc = 'thc-potency-asc';
export const thcPotencyDesc = 'thc-potency-desc';

export const searchSorts = [
  categoryAndNameAsc,
  categoryAndNameDesc,
  defaultOrder,
  priceAsc,
  priceDesc,
  priceEighthOunceAsc,
  priceEighthOunceDesc,
  priceGramAsc,
  priceGramDesc,
  priceHalfGramAsc,
  priceHalfGramDesc,
  priceHalfOunceAsc,
  priceHalfOunceDesc,
  priceOunceAsc,
  priceOunceDesc,
  priceQuarterOunceAsc,
  priceQuarterOunceDesc,
  priceTwoGramAsc,
  priceTwoGramDesc,
  rating,
  recommendation,
  specialAndReview,
  thcPotencyAsc,
  thcPotencyDesc,
] as const;

export type SearchSort = typeof searchSorts[number];
