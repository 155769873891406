import { useCallback, useMemo } from 'react';

import { trackLoadedSmartSortProducts } from '../mixpanel/events';
import { useGetRecommendedSortVersion } from './useGetRecommendedSortVersion';

interface StopPayload {
  pageHits: number;
  totalHits: number;
}
export const useRecommendedSortTimer = (rowName: string) => {
  const timers = useMemo(() => new RecommendedSortTimer(), []);
  const recommendedSortVersion = useGetRecommendedSortVersion();

  const start = useCallback(() => {
    // we want to be absolutely sure that component remounts dont start a new timer and skew data, so a key can only be
    // used once per page load (store menu rows have a completely fresh mount after initial load that loses all
    // component state)
    const shouldStartTimer = !timers.hasKey(rowName);
    if (shouldStartTimer) {
      timers.setTime(rowName, new Date().getTime());
    }
  }, [rowName, timers]);

  const stop = useCallback(
    (payload: StopPayload) => {
      const startTime = timers.getTime(rowName);
      if (typeof startTime === 'number') {
        timers.setTime(rowName, null);
        const now = new Date().getTime();
        const durationMs = now - startTime;
        trackLoadedSmartSortProducts({
          durationMs,
          recommendedSortVersion,
          rowName,
          ...payload,
        });
      }
    },
    [recommendedSortVersion, rowName, timers]
  );

  return { start, stop };
};

export class RecommendedSortTimer {
  constructor() {
    if (RecommendedSortTimer.timers === undefined) {
      RecommendedSortTimer.resetTimers();
    }
  }

  private static timers: Record<string, number | null>;
  static resetTimers() {
    RecommendedSortTimer.timers = {};
  }

  hasKey(key: string) {
    return Object.prototype.hasOwnProperty.call(
      RecommendedSortTimer.timers,
      key
    );
  }

  setTime(key: string, time: number | null) {
    RecommendedSortTimer.timers[key] = time;
  }

  getTime(key: string) {
    return RecommendedSortTimer.timers[key];
  }
}
