import { useParams } from 'react-router-dom';

import { useRuntimeConfig } from '@jane/shared/runtime-config';

/**
 * In most cases, the current store's storeId is in the url as a pathParam. However, the embed app can be hosted
 * through a redirect on a partner site that points to a custom index.html, which removes the storeId pathParm and
 * stores it in a RuntimeConfig object. This hook standardizes conditional access of the current store's storeId for
 * all use cases.
 *
 * @returns storeId: the current store's storeId
 */
export const useCurrentStoreId = (): number => {
  const { store_id } = useParams<{ store_id: string }>();
  const { isPartnerHosted, partnerHostedStoreId } = useRuntimeConfig();

  let storeId: number;
  if (isPartnerHosted && partnerHostedStoreId !== undefined) {
    storeId = partnerHostedStoreId;
  } else if (store_id !== undefined) {
    storeId = parseInt(store_id, 10);
  } else {
    throw new Error('No store_id present in path or in runtimeConfig');
  }
  return storeId;
};
