import styled from '@emotion/styled';

import { ChevronRightIcon, Typography } from '@jane/shared/reefer';
import { mediaQueries, spacing } from '@jane/shared/reefer-emotion';

import type { CarouselPaginationProps } from '../carousel.types';

const StyledCarouselPagination = styled.div<{ forceMobile?: boolean }>(
  ({ theme, forceMobile }) => ({
    position: 'absolute',
    right: 16,
    bottom: 16,
    background: 'rgba(0,0,0,0.5)',
    borderRadius: theme.borderRadius.lg,
    padding: '3px 8px 3px 8px',
    display: 'flex',
    alignItems: 'center',
    ...(!forceMobile && {
      [mediaQueries.tablet('min')]: {
        ...spacing({ px: 12, py: 8 }),
        top: 16,
        bottom: 'auto',
      },
    }),
  })
);

const ButtonWrapper = styled.div<{ forceMobile?: boolean }>(
  ({ forceMobile }) => ({
    display: 'none',
    [mediaQueries.tablet('min')]: {
      display: forceMobile ? undefined : 'block',
    },
  })
);

const StyledTypography = styled(Typography)<{ forceMobile?: boolean }>(
  ({ forceMobile }) => ({
    '&&': {
      lineHeight: forceMobile ? '16px' : undefined,
      fontSize: forceMobile ? 13 : undefined,
    },
  })
);

export const BrandCarouselPagination = ({
  activeIndex,
  totalItems,
  updateIndex,
  forceMobile,
}: CarouselPaginationProps) => {
  const prevIndex = activeIndex === 0 ? totalItems - 1 : activeIndex - 1;
  const nextIndex = activeIndex === totalItems - 1 ? 0 : activeIndex + 1;

  return (
    <StyledCarouselPagination forceMobile={forceMobile}>
      <ButtonWrapper forceMobile={forceMobile}>
        <ChevronRightIcon
          altText="View previous item"
          color="grays-white"
          onClick={() => updateIndex(prevIndex)}
          rotate="down"
          mr={12}
        />
      </ButtonWrapper>
      <StyledTypography
        variant="mini"
        color="grays-white"
        forceMobile={forceMobile}
      >
        {activeIndex + 1}&#47;{totalItems}
      </StyledTypography>
      <ButtonWrapper forceMobile={forceMobile}>
        <ChevronRightIcon
          altText="View next item"
          color="grays-white"
          onClick={() => updateIndex(nextIndex)}
          ml={12}
        />
      </ButtonWrapper>
    </StyledCarouselPagination>
  );
};
