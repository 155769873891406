import { useIsInIframe } from '@jane/shared-ecomm/hooks';

import { EmbeddedCartDrawer } from './embeddedCartDrawer';
import { MarketplaceCartDrawer } from './marketplaceCartDrawer';

export const PendingCartDrawer = ({
  container,
}: {
  container?: string | HTMLElement;
}) => {
  const isInIframe = useIsInIframe();

  if (isInIframe) return <EmbeddedCartDrawer container={container} />;

  return <MarketplaceCartDrawer container={container} />;
};
