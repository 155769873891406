import styled from '@emotion/styled';

import { Box, Flex } from '@jane/shared/reefer';
import { mediaQueries } from '@jane/shared/reefer-emotion';

export const StyledCartContainer = styled(Box)({
  padding: '16px 24px',

  [mediaQueries.tablet('min')]: {
    padding: '20px 40px',
  },
});

export const StyledCartInnerContainer = styled(Flex)({
  flexDirection: 'column',

  [mediaQueries.tablet('min')]: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
});

export const StyledCartButtonWrapper = styled(Box)({
  width: '100%',
  marginTop: 20,

  [mediaQueries.tablet('min')]: {
    marginTop: 0,
    width: '165px',
  },
});

export const StyledCartDescriptionWrapper = styled(Box)<{
  userHasLinkedBank: boolean | undefined;
}>(({ userHasLinkedBank }) => ({
  width: '100%',
  marginLeft: 0,

  [mediaQueries.tablet('min')]: {
    width: userHasLinkedBank ? '100%' : 'calc(100% - 215px)',
    marginLeft: 40,
  },
}));
