import type { ActiveFilters as ActiveFilterType } from '@jane/search/types';
import {
  Box,
  Button,
  DismissIcon,
  Flex,
  Link,
  useMobileMediaQuery,
} from '@jane/shared/reefer';
import type { IconComponentProps, MarginProperties } from '@jane/shared/reefer';

import { StyledActiveFilterWrapper } from './productFilterBar.styles';
import type { ProductFilterBarProps } from './productFilterBar.types';

interface ActiveFilterProps
  extends Pick<
    ProductFilterBarProps,
    'activeFilters' | 'onChange' | 'onDeselect'
  > {
  myHighMenu?: boolean;
}

export const ActiveFilters = ({
  activeFilters,
  onChange,
  onDeselect,
  myHighMenu,
}: ActiveFilterProps) => {
  const isMobile = useMobileMediaQuery({});
  const filterButtons = activeFilters.map((filter) => (
    <Box key={filter.label}>
      <ActiveFilterButton
        activeFilter={filter}
        onChange={onChange}
        mr={16}
        mb={16}
      />
    </Box>
  ));

  if (!activeFilters.length) return null;

  return isMobile ? (
    <Box>
      <StyledActiveFilterWrapper>{filterButtons}</StyledActiveFilterWrapper>
      <Link onClick={() => onDeselect('all')} ml={24} mb={16} typography="body">
        Clear all
      </Link>
    </Box>
  ) : (
    <Flex alignItems="center" flexWrap="wrap" px={myHighMenu ? 64 : 24}>
      {filterButtons}
      <Link onClick={() => onDeselect('all')} ml={12} mb={16} typography="body">
        Clear all
      </Link>
    </Flex>
  );
};

export const ActiveFilterButton = ({
  activeFilter,
  onChange,
  ...margins
}: {
  activeFilter: ActiveFilterType;
  onChange: ProductFilterBarProps['onChange'];
} & MarginProperties) => {
  const { icon, key, label, value } = activeFilter;
  const Icon = icon as IconComponentProps;
  const buttonLabel = key === 'has_brand_discount' ? 'Jane Gold' : label;

  return (
    <Button
      key={buttonLabel}
      label={buttonLabel}
      endIcon={icon ? <Icon size="sm" /> : undefined}
      startIcon={<DismissIcon altText={`Clear ${buttonLabel} filter`} />}
      onClick={() => onChange(key, value)}
      variant="secondary"
      {...margins}
    />
  );
};
