import { getImageSizesAndWidths } from './imageSizes';
import type { ImageSizes } from './imageSizes';

const isValidUrl = (url: string) => {
  try {
    new URL(url);
    return true;
  } catch (err) {
    return false;
  }
};

const checkIOSVersion = () => {
  const agent = window?.navigator?.userAgent;
  if (!agent) return 0;
  const start = agent.indexOf(' OS ');
  if ((agent.includes('iPhone') || agent.includes('iPad')) && start > -1) {
    return window.Number(agent.substr(start + 3, 3).replace('_', '.'));
  }
  return 0;
};

interface CloudflareSrcProps {
  addPixelWidth?: boolean;
  origin: string;
  pathname: string;
  resizeWidth: number;
}

const buildCloudflareSrc = ({
  addPixelWidth,
  origin,
  pathname,
  resizeWidth,
}: CloudflareSrcProps) => {
  // See: https://github.com/janetechinc/iheartjane/pull/9145
  const format = checkIOSVersion() === 16 ? 'webp' : 'auto';
  const cloudflareBase = '/cdn-cgi/image/';

  const cloudflareOptions = `width=${resizeWidth},fit=scale-down,format=${format},metadata=none`;
  const srcSetPixelWidth = addPixelWidth ? ` ${resizeWidth}w` : '';
  return (
    origin + cloudflareBase + cloudflareOptions + pathname + srcSetPixelWidth
  );
};

const pathsToSkip = ['product-assets.s3', '/cdn-cgi/image/'];

interface ResponsiveImageAttributes {
  fallbackWidth?: number;
  imageSize?: ImageSizes;
  src: string;
}

export const getResponsiveImageAttributes = ({
  imageSize,
  src,
  fallbackWidth,
}: ResponsiveImageAttributes) => {
  const { sizes, srcWidths } = getImageSizesAndWidths(imageSize);
  const defaultWidth = fallbackWidth ?? srcWidths[0];

  const validUrl = isValidUrl(src);

  if (validUrl) {
    const invalidPath = pathsToSkip.some((path) => src.includes(path));

    if (invalidPath) return { srcSet: '', imageSrc: src, sizes: '' };

    const url = new URL(src);
    const origin = url.origin;
    const pathname = url.pathname;

    const srcSet = srcWidths
      .map((width) =>
        buildCloudflareSrc({
          origin,
          pathname,
          resizeWidth: width,
          addPixelWidth: true,
        })
      )
      .join(',');

    const imageSrc = buildCloudflareSrc({
      origin,
      pathname,
      resizeWidth: defaultWidth,
      addPixelWidth: false,
    });

    return { srcSet, imageSrc, sizes };
  }

  return { imageSrc: src, invalid: true };
};
