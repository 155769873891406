import type {
  ReservationRequestProps,
  ReservationsProps,
} from './reservations.types';

const BASE_RESERVATIONS_URL = '/users/reservations';

export const reservationsUrls = {
  reservations: () => '/users/reservation_history',
  reservationsByStore: ({ storeId }: ReservationsProps) =>
    `/users/store_reservation_history?store_id=${storeId}`,
  reservationDetails: ({ id }: ReservationRequestProps) =>
    `${BASE_RESERVATIONS_URL}/${id}/details`,
  guestReservationDetails: ({ id }: ReservationRequestProps) =>
    `${BASE_RESERVATIONS_URL}/${id}/guest_details`,
  reviewReservation: ({ id }: ReservationRequestProps) =>
    `${BASE_RESERVATIONS_URL}/${id}/reviews`,
  cancelReservation: ({ id }: ReservationRequestProps) =>
    `${BASE_RESERVATIONS_URL}/${id}`,
  cancelGuestReservation: () => '/guest_carts',
  notifyCurbsideArrival: ({ id }: ReservationRequestProps) =>
    `${BASE_RESERVATIONS_URL}/${id}/curbside_customer_arrived`,
};

export const RESERVATIONS_BASE_KEY = 'reservations';
export const reservationsKeys = {
  reservations: ({ storeId }: ReservationsProps) => [
    RESERVATIONS_BASE_KEY,
    storeId,
  ],
  reservationsInProgress: ({ storeId }: ReservationsProps) => [
    RESERVATIONS_BASE_KEY,
    'in_progress',
    storeId,
  ],
  reservationDetails: ({ id }: ReservationRequestProps) => [
    RESERVATIONS_BASE_KEY,
    id,
  ],
  guestReservationDetails: ({ id }: ReservationRequestProps) => [
    RESERVATIONS_BASE_KEY,
    'guest',
    id,
  ],
  notifyCurbsideArrival: ({ id }: ReservationRequestProps) => [
    RESERVATIONS_BASE_KEY,
    'curbside_arrival',
    id,
  ],
};
