import type {
  AlgoliaProduct,
  JaneSearchState,
  SearchResponse,
} from '@jane/search/types';
import type { AppMode, BrandPartner, Store } from '@jane/shared/models';

import { buildSortedProductsWithFlights } from './buildSortedProductsWithFlights';
import { useProductDetailsQuery } from './useProductDetailsQuery';
import type { QueryFnResponse } from './useProductDetailsQuery';
import { useProductIdsAndAdsQuery } from './useProductIdsAndAdsQuery';

interface UseSmartProductSearchProps {
  appMode: AppMode;
  brandPartner?: BrandPartner;
  enabled: boolean;
  janeDeviceId: string;
  keywords: string[];
  numColumns?: number;
  productsPerPage: number;
  searchState: JaneSearchState<AlgoliaProduct>;
  staticFilters?: string[];
  store: Store;
}

export interface UseSmartProductSearchResponse {
  fetchNextPage: () => void;
  hasNextPage: boolean;
  indexName: string;
  isError: boolean;
  isLoading: boolean;
  isSuccess: boolean;
  numHits: number;
  products: AlgoliaProduct[];
  searchResultFacets:
    | SearchResponse['facets']
    | QueryFnResponse['searchResultFacets'];
}

export const useSmartProductSearch = ({
  appMode,
  brandPartner,
  enabled,
  janeDeviceId,
  keywords,
  numColumns,
  productsPerPage,
  searchState,
  staticFilters,
  store,
}: UseSmartProductSearchProps): UseSmartProductSearchResponse => {
  const idsQuery = useProductIdsAndAdsQuery({
    appMode,
    brandPartner,
    enabled,
    janeDeviceId,
    keywords,
    numColumns,
    searchState,
    staticFilters,
    store,
  });

  const rows = idsQuery.data?.rows || [];
  const flights = idsQuery.data?.flights || [];
  const productIds = rows.map((row) => row.product_id);

  const detailsQuery = useProductDetailsQuery({
    enabled: Boolean(idsQuery.data),
    productIds,
    productsPerPage,
    searchState,
    storeId: store.id,
  });

  const allProducts = detailsQuery.data
    ? detailsQuery.data.pages
        .map((page) => page.products)
        .flat()
        .filter(Boolean)
    : [];

  const pages = detailsQuery.data?.pages.length ?? 0;
  const maxProductCountForCurrentPages = pages * productsPerPage;
  const rowsForCurrentPages = rows.slice(0, maxProductCountForCurrentPages);

  const sortedProducts = buildSortedProductsWithFlights(
    rowsForCurrentPages,
    flights,
    allProducts
  );

  return {
    fetchNextPage: detailsQuery.fetchNextPage,
    hasNextPage: detailsQuery.hasNextPage ?? false,
    indexName: idsQuery.data?.indexName ?? '',
    isError: idsQuery.isError || detailsQuery.isError,
    isLoading: idsQuery.isLoading || detailsQuery.isLoading,
    isSuccess: idsQuery.isSuccess && detailsQuery.isSuccess,
    numHits: allProducts.length ?? 0,
    products: sortedProducts,
    searchResultFacets: {},
  };
};
