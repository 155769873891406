import type { AlgoliaProduct, JaneSearchState } from '@jane/search/types';
import type { Id } from '@jane/shared/models';

import { getFilters } from '../searchEvents/trackSearchUtils';
import type {
  AddedProductToCartPayload,
  ClickedSeeAllPayload,
  ProductDetailPagePayload,
  SearchedMenuProductsPayload,
} from './tracking';
import { EventNames, trackUserSessionEvent } from './tracking';

export type TrackUserSessionProductProps = {
  productId: Id;
  storeId: Id;
};
export const trackUserSessionAddedProductToCart = (
  props: TrackUserSessionProductProps
) => {
  const { productId, storeId } = props;
  const payload: AddedProductToCartPayload = {
    eventName: EventNames.AddedProductToCart,
    productId: Number(productId),
    storeId: Number(storeId),
  };
  trackUserSessionEvent(payload);
};
export const trackUserSessionViewedProduct = (
  props: TrackUserSessionProductProps
) => {
  const { productId, storeId } = props;
  const payload: ProductDetailPagePayload = {
    eventName: EventNames.ViewedProduct,
    productId: Number(productId),
    storeId: Number(storeId),
  };
  trackUserSessionEvent(payload);
};

export type TrackUserSessionSeeAllProps = {
  category: string;
  storeId: Id;
};
export const trackUserSessionClickedSeeAll = (
  props: TrackUserSessionSeeAllProps
) => {
  const { category, storeId } = props;
  const payload: ClickedSeeAllPayload = {
    eventName: EventNames.ClickedSeeAll,
    category,
    storeId: Number(storeId),
  };
  trackUserSessionEvent(payload);
};

export type TrackUserSessionSearchedMenuProductsProps = {
  searchState: JaneSearchState<AlgoliaProduct>;
};

export const trackUserSessionSearchedMenuProducts = (
  props: TrackUserSessionSearchedMenuProductsProps
) => {
  const { searchState } = props;
  const payload: SearchedMenuProductsPayload = {
    eventName: EventNames.SearchedMenuProducts,
    filters: getFilters(searchState),
  };
  trackUserSessionEvent(payload);
};
