/**
 * DO NOT MODIFY THIS FILE!
 * It was created by the reefer-icons generator.
 * Please update & re-run the generator if need be.
 */
import type { SVGProps } from 'react';

import type { DynamicIconProps } from '../../../icon/icon.types';
import { Icon } from '../../icon';

const ChevronRight24 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="100%"
    height="100%"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <g className="Chevron Right 24">
      <path
        fill="#0E0E0E"
        fillRule="evenodd"
        d="M7.293 4.293a1 1 0 0 1 1.414 0l7 7a1 1 0 0 1 0 1.414l-7 7a1 1 0 0 1-1.414-1.414L13.586 12 7.293 5.707a1 1 0 0 1 0-1.414Z"
        className="SVG"
        clipRule="evenodd"
      />
    </g>
  </svg>
);

const SIZE_MAP = {
  md: ChevronRight24,
  sm: ChevronRight24,
  lg: ChevronRight24,
  xl: ChevronRight24,
  xxl: ChevronRight24,
  xxxl: ChevronRight24,
};

export const ChevronRightIcon = ({
  size = 'md',
  'data-testid': testId,
  ...props
}: DynamicIconProps) => {
  return (
    <Icon
      data-testid={testId || 'chevron-right-icon'}
      icon={SIZE_MAP[size]}
      {...props}
      size={size}
    />
  );
};
