/**
 * DO NOT MODIFY THIS FILE!
 * It was created by the reefer-icons generator.
 * Please update & re-run the generator if need be.
 */
import type { SVGProps } from 'react';

import type { StaticIconProps } from '../../../icon/icon.types';
import { Icon } from '../../icon';

const Blissful24 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="100%"
    height="100%"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <g className="Blissful 24">
      <g className="SVG">
        <circle cx={12} cy={12} r={12} fill="#FFC220" className="Fill-Main" />
        <path
          fill="#000"
          fillOpacity={0.5}
          fillRule="evenodd"
          d="M5.293 15.293a1 1 0 0 1 1.414 0 7.485 7.485 0 0 0 10.586 0 1 1 0 0 1 1.414 1.414 9.485 9.485 0 0 1-13.414 0 1 1 0 0 1 0-1.414Z"
          className="Fill-Secondary"
          clipRule="evenodd"
        />
        <path
          fill="#FF6334"
          fillRule="evenodd"
          d="M5.16 7c.936 0 1.532.511 1.84.88.308-.369.904-.88 1.84-.88C10.046 7 11 7.985 11 8.96c0 .974-.536 1.737-1.61 2.615-.879.72-1.284.908-1.933 1.208-.14.065-.292.135-.462.217a30.985 30.985 0 0 0-.467-.221c-.638-.298-1.043-.488-1.918-1.204C3.537 10.697 3 9.935 3 8.96 3 7.985 3.955 7 5.16 7Zm10 0c.936 0 1.532.511 1.84.88.308-.369.904-.88 1.84-.88C20.046 7 21 7.985 21 8.96c0 .974-.536 1.737-1.61 2.615-.879.72-1.284.908-1.933 1.208-.14.065-.292.135-.462.217a31.266 31.266 0 0 0-.467-.221c-.638-.298-1.043-.488-1.918-1.204C13.537 10.697 13 9.935 13 8.96c0-.975.955-1.96 2.16-1.96Z"
          className="Fill-Accent"
          clipRule="evenodd"
        />
      </g>
    </g>
  </svg>
);

const SIZE_MAP = {
  md: Blissful24,
  sm: Blissful24,
  lg: Blissful24,
  xl: Blissful24,
  xxl: Blissful24,
  xxxl: Blissful24,
};

export const BlissfulIcon = ({
  size = 'md',
  'data-testid': testId,
  ...props
}: StaticIconProps) => {
  return (
    <Icon
      data-testid={testId || 'blissful-icon'}
      icon={SIZE_MAP[size]}
      {...props}
      size={size}
      color={undefined}
    />
  );
};
