export type CheckActions<A extends { type: string }> =
  '--invalid-action--' extends A['type']
    ? 'action type should be constant, not string'
    : A;

export const createSimpleAction =
  <Type extends string>(type: Type) =>
  (): {
    payload?: undefined;
    type: Type;
  } => ({
    type,
  });
export const createStandardAction =
  <Type extends string>(type: Type) =>
  <Payload>() =>
  (payload: Payload) => ({ type, payload });

export const createStandardError =
  <Type extends string>(type: Type) =>
  (payload: Error) => ({ type, payload, error: true });
