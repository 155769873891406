import { nanoid } from 'nanoid';

import type { PosCheckoutParams } from './posCheckout';
import { sendPosCheckout } from './posCheckout';
import type {
  FetchRecommendedPDPRowProps,
  RecommendedPDPRow,
} from './recommendedPDPRow';
import { RecommendedPDPRowClient } from './recommendedPDPRow';
import type {
  FetchCartTopperRowProps,
  FetchMenuRowProps,
  FetchRecommendedRowProps,
  FetchRecommendedSortProps,
  SmartSort,
} from './smartSort';
import { SmartSortClient } from './smartSort';
import type { FetchTopOfMenuRowProps, TopOfMenuRow } from './topOfMenuRow';
import { TopOfMenuRowClient } from './topOfMenuRow';
import { appModeHeadless } from './types/appModes';
import type {
  JaneDMConfig,
  JaneDMIdentifiers,
  RequiredConfig,
} from './types/config';
import {
  placementCartToppers,
  placementMenuInline,
  placementMenuInlineTable,
  placementRecommendedRow,
} from './types/placements';
import { debounceAsync } from './utils/debounceAsync';
import { sanitizeEndpointString } from './utils/sanitizeEndpointString';
import { SdkError } from './utils/sdkError';
import { Storage } from './utils/storage';
import { JDM_VERSION } from './utils/version';

const FETCH_PLACEMENT_DEBOUNCE_TIME = 1000;

/**
 * JaneDM is the main class that is used to interact with the Jane DM API.
 * An instance is required to fetch ads and send checkout events.
 * This is the **entry point for the Jane DM SDK.**
 * @example
 * ```jsx
 * let client = new JaneDM({
 *   // Required
 *   apiKey: 'your-api-key',
 *   // Optional. Default endpoint is the staging environment: 'https://dmerch-demo.nonprod-iheartjane.com'
 *   endpoint: 'https://dmerch-demo.nonprod-iheartjane.com',
 *   // Optional. Identifier object for user tracking purposes.
 *   identifier: {
 *     // Required. A unique identifier, UUID, representing the device visiting the site
 *     jdid: 'your-jdid',
 *     // Optional. A unique identifier representing an authenticated user
 *     userId: 'your-user-id'
 *   },
 * });
 *
 * function TopOfMenuRowComponent({ placement }) {
 *   useEffect(() => {
 *     placement.render(); // Records a rendered event for the sponsored row placement
 *   }, []);
 *
 *   return (<div>...</div>);
 * }
 *
 * let placement = await client.fetchTopOfMenuRow({ storeId: 420 });
 *
 * <TopOfMenuRowComponent placement={placement} />
 * ```
 */
export class JaneDM {
  protected config: RequiredConfig;
  /**
   * @deprecated TopOfMenuRowClient will soon be removed from the SDK in lieu of SmartSortClient
   */
  private topOfMenuRowClient: TopOfMenuRowClient;
  private smartSortClient: SmartSortClient;
  /**
   * @deprecated RecommendedPDPRowClient will soon be removed from the SDK in lieu of SmartSortClient
   */
  private recommendedPDPRowClient: RecommendedPDPRowClient;

  /**
   * Fetches a top of menu row placement from the Jane DM API. This
   * placement represents a row of sponsored products to be displayed at
   * the top of the page displaying lists or grids of products.
   * A placement can be serialized to and from JSON for the purpose of
   * server-side rendering, the serialized object **should not be persisted**
   * because it has a very short lifecycle.
   *
   * **Note:** This method will soon return an instance of SmartSort instead of TopOfMenuRow.
   *
   * @example
   * ```jsx
   * let placement = await client.fetchTopOfMenuRow({
   *   // Required
   *   storeId: 420,
   *   filters: {
   *     categories: ['sativa', 'hybrid'],
   *     rootTypes: ['Flower'],
   *   },
   *   currentCreativeIds: [23, 47],
   * });
   * ```
   */
  public fetchTopOfMenuRow: (
    props: FetchTopOfMenuRowProps
  ) => Promise<TopOfMenuRow>;

  /**
   * Fetches a menu row placement from the Jane DM API. This placement
   * represents a row of products to be displayed on a page displaying lists of products.
   * A placement can be serialized to and from JSON for the purpose of
   * server-side rendering, the serialized object **should not be persisted**
   * because it has a very short lifecycle.
   * @example
   * ```jsx
   * let placement = await client.fetchMenuRow({
   *   // Required
   *   storeId: 420,
   *   searchAttributes: ['product_id', 'name'],
   *   searchFilter: 'category:Flower',
   *   searchOptionalFilters: 'brand:Somebrand<score=3>',
   * });
   * ```
   */
  public fetchMenuRow: (props: FetchMenuRowProps) => Promise<SmartSort>;

  /**
   * Fetches a recommended PDP row placement from the Jane DM API. This
   * placement represents a row of sponsored products to be displayed in a
   * product's details page.
   * A placement can be serialized to and from JSON for the purpose of
   * server-side rendering, the serialized object **should not be persisted**
   * because it has a very short lifecycle.
   *
   * **Note:** This method will soon return an instance of SmartSort instead of RecommendedPDPRow.
   *
   * @example
   * ```jsx
   * let placement = await client.fetchRecommendedPDPRow({
   *   // Required
   *   currentBrandName: 'Brand Name',
   *   // Required
   *   storeId: 420,
   *   filters: {
   *     categories: ['sativa', 'hybrid'],
   *     rootTypes: ['Flower'],
   *   },
   *   currentCreativeIds: [23, 47],
   * });
   * ```
   */
  public fetchRecommendedPDPRow: (
    props: FetchRecommendedPDPRowProps
  ) => Promise<RecommendedPDPRow>;

  /**
   * Fetches a recommended row placement from the Jane DM API. This
   * placement represents a row of recommended organic and sponsored
   * products to be displayed in any kind of page.
   * A placement can be serialized to and from JSON for the purpose of
   * server-side rendering, the serialized object **should not be persisted**
   * because it has a very short lifecycle.
   * @example
   * ```jsx
   * let placement = await client.fetchRecommendedRow({
   *   // Required
   *   storeId: 420,
   *   searchAttributes: ['product_id', 'name'],
   *   searchFilter: 'category:Flower',
   *   searchOptionalFilters: 'brand:Somebrand<score=3>',
   * });
   * ```
   */
  public fetchRecommendedRow: (
    props: FetchRecommendedRowProps
  ) => Promise<SmartSort>;

  /**
   * Fetches a recommended sort placement from the Jane DM API. This
   * placement represents a list of mixed organic and sponsored products,
   * sorted according to user's recommendations, to be used when sorting
   * lists of products on your pages.
   * A placement can be serialized to and from JSON for the purpose of
   * server-side rendering, the serialized object **should not be persisted**
   * because it has a very short lifecycle.
   * @example
   * ```jsx
   * let placement = await client.fetchRecommendedSort({
   *   // Required
   *   storeId: 420,
   *   // Required
   *   searchSort: 'default',
   *   disableAds: false,
   *   maxProducts: 10,
   *   numColumns: 5,
   *   pageSize: 10,
   *   searchAttributes: ['product_id', 'name'],
   *   searchFacets: ['category'],
   *   searchFilter: 'category:Flower',
   *   searchOptionalFilters: 'brand:Somebrand<score=3>',
   *   searchQuery: '',
   * });
   * ```
   */
  public fetchRecommendedSort: (
    props: FetchRecommendedSortProps
  ) => Promise<SmartSort>;

  /**
   * Fetches a cart topper row placement from the Jane DM API. This
   * placement represents a row of sponsored and organic products to be
   * displayed in the cart page.
   * A placement can be serialized to and from JSON for the purpose of
   * server-side rendering, the serialized object **should not be persisted**
   * because it has a very short lifecycle.
   * @example
   * ```jsx
   * let placement = await client.fetchCartTopperRow({
   *   // Required
   *   storeId: 420,
   * });
   * ```
   */
  public fetchCartTopperRow: (
    props: FetchCartTopperRowProps
  ) => Promise<SmartSort>;

  /**
   * Signals the Jane DM API that a checkout event has happened.
   * This method should be called when a user has completed a purchase to
   * record attribution data for conversions.
   * It is preferable to call this method from the server-side to avoid
   * race conditions.
   * @example
   * ```jsx
   * await client.sendPosCheckout({
   *   storeId: 420,
   *   posOrderId: 'some-pos-order-id',
   *   posUserId: 'some-pos-user-id',
   *   products: [
   *     {
   *       posProductId: 'some-pos-product-id',
   *       price: 10.99,
   *       quantity: 1,
   *     },
   *   ],
   * });
   * ```
   */
  public sendPosCheckout: (props: PosCheckoutParams) => Promise<void>;

  /**
   * Creates an instance of the Jane DM SDK client.
   */
  constructor(props: JaneDMConfig) {
    const { endpoint, eventsEndpoint } = props;
    const sanitizedEndpoint = sanitizeEndpointString(
      endpoint,
      'https://dmerch-demo.nonprod-iheartjane.com'
    );
    const sanitizedEventsEndpoint = sanitizeEndpointString(
      eventsEndpoint,
      sanitizedEndpoint
    );

    this.config = {
      apiKey: props.apiKey,
      appMode: props.appMode ?? appModeHeadless,
      endpoint: sanitizedEndpoint,
      eventsEndpoint: sanitizedEventsEndpoint,
      identifier: this.generateAndMergeIdentifier(props.identifier),
      source: props.source ?? 'sdk',
      version: props.version ?? JDM_VERSION,
    };

    this.topOfMenuRowClient = new TopOfMenuRowClient();
    this.smartSortClient = new SmartSortClient();
    this.recommendedPDPRowClient = new RecommendedPDPRowClient();

    this.fetchTopOfMenuRow = debounceAsync<typeof this._fetchTopOfMenuRow>(
      this._fetchTopOfMenuRow.bind(this),
      FETCH_PLACEMENT_DEBOUNCE_TIME
    );

    this.fetchMenuRow = debounceAsync<typeof this._fetchMenuRow>(
      this._fetchMenuRow.bind(this),
      FETCH_PLACEMENT_DEBOUNCE_TIME
    );

    this.fetchRecommendedPDPRow = debounceAsync<
      typeof this._fetchRecommendedPDPRow
    >(this._fetchRecommendedPDPRow.bind(this), FETCH_PLACEMENT_DEBOUNCE_TIME);

    this.fetchRecommendedRow = debounceAsync<typeof this._fetchRecommendedRow>(
      this._fetchRecommendedRow.bind(this),
      FETCH_PLACEMENT_DEBOUNCE_TIME
    );

    this.fetchRecommendedSort = debounceAsync<
      typeof this._fetchRecommendedSort
    >(this._fetchRecommendedSort.bind(this), FETCH_PLACEMENT_DEBOUNCE_TIME);

    this.fetchCartTopperRow = debounceAsync<typeof this._fetchCartTopperRow>(
      this._fetchCartTopperRow.bind(this),
      FETCH_PLACEMENT_DEBOUNCE_TIME
    );

    this.sendPosCheckout = debounceAsync<typeof this._sendPosCheckout>(
      this._sendPosCheckout.bind(this),
      FETCH_PLACEMENT_DEBOUNCE_TIME
    );
  }

  /**
   * Retrieves the configured JDID for the client.
   */
  public getJaneDeviceId() {
    return this.config.identifier.jdid;
  }

  /**
   * Function used to generate a valid jane device id. This function is
   * intended to be used upon initial setup in a SSR environment & stored in a cookie
   */
  static generateJaneDeviceId() {
    return nanoid();
  }

  private async _fetchTopOfMenuRow(
    props: FetchTopOfMenuRowProps
  ): Promise<TopOfMenuRow> {
    return this.topOfMenuRowClient.createTopOfMenuRow({
      ...props,
      ...this.config,
    });
  }

  private async _fetchMenuRow(props: FetchMenuRowProps): Promise<SmartSort> {
    return this.smartSortClient.createSmartSort({
      ...this.config,
      ...props,
      placement: placementMenuInline,
    });
  }

  private async _fetchRecommendedPDPRow(
    props: FetchRecommendedPDPRowProps
  ): Promise<RecommendedPDPRow> {
    return await this.recommendedPDPRowClient.createRecommendedPDPRow({
      ...props,
      ...this.config,
    });
  }

  private async _fetchRecommendedRow(
    props: FetchRecommendedRowProps
  ): Promise<SmartSort> {
    return await this.smartSortClient.createSmartSort({
      ...props,
      ...this.config,
      placement: placementRecommendedRow,
      title: 'For you',
    });
  }

  private async _fetchRecommendedSort(
    props: FetchRecommendedSortProps
  ): Promise<SmartSort> {
    return await this.smartSortClient.createSmartSort({
      ...props,
      ...this.config,
      placement: placementMenuInlineTable,
    });
  }

  private async _fetchCartTopperRow(
    props: FetchCartTopperRowProps
  ): Promise<SmartSort> {
    return await this.smartSortClient.createSmartSort({
      ...props,
      ...this.config,
      placement: placementCartToppers,
    });
  }

  private async _sendPosCheckout(props: PosCheckoutParams) {
    return sendPosCheckout({ ...props, ...this.config });
  }

  private initJaneDeviceId(): string {
    let jdid = Storage.get('jdid');

    if (!jdid || jdid === 'null' || jdid === 'undefined') {
      jdid = nanoid();
      Storage.set('jdid', jdid);
    }

    return jdid;
  }

  private generateAndMergeIdentifier(
    originalIdentifier: JaneDMIdentifiers | undefined
  ): JaneDMIdentifiers {
    const isSsr = typeof window === 'undefined';

    if (!isSsr) {
      const jdid = originalIdentifier?.jdid || this.initJaneDeviceId();
      return { ...originalIdentifier, jdid };
    } else {
      if (!originalIdentifier) {
        throw new SdkError(
          'Identifier must be provided for server-side rendering'
        );
      }

      return originalIdentifier;
    }
  }
}
