export function sentenceify(array: string[]) {
  switch (array.length) {
    case 0:
      return '';
    case 1:
      return array[0];
    case 2:
      return array.join(' and ');
    default:
      return andify(array);
  }
}

function andify(array: string[]) {
  const copy = [...array];
  copy.push('and ' + copy.pop());
  return copy.join(', ');
}
