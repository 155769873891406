import type { Store } from '@jane/shared/models';

/* Need to override "Disposables" in California to be "All-in-one" for compliance" */

export const overrideCustomCategoryLabels = (
  shouldOverride: boolean,
  customLabels?: Store['custom_product_type_labels']
) =>
  shouldOverride
    ? {
        ...customLabels,
        'vape:Disposables': 'All-in-one',
      }
    : customLabels;
