/**
 * DO NOT MODIFY THIS FILE!
 * It was created by the reefer-icons generator.
 * Please update & re-run the generator if need be.
 */
import type { SVGProps } from 'react';

import type { StaticIconProps } from '../../../icon/icon.types';
import { Icon } from '../../icon';

const Lightbulb24 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="100%"
    height="100%"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <g className="Lightbulb 24">
      <g className="SVG">
        <path
          fill="#FFC220"
          d="M6.319 6.305c3.091-3.073 8.104-3.073 11.196 0a7.837 7.837 0 0 1 0 11.13l-1.033 1.21c-2.395 2.81-6.735 2.81-9.13 0l-1.033-1.21a7.837 7.837 0 0 1 0-11.13ZM3 7.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0Zm6-6a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0Zm9 0a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0Zm6 6a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0Zm-21 8a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0Zm21 0a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0Z"
          className="Fill-Main"
        />
        <path
          fill="#666"
          d="M9 20h6v2a2 2 0 0 1-2 2h-2a2 2 0 0 1-2-2v-2Z"
          className="Fill-Secondary"
        />
        <circle cx={14.25} cy={12} r={3} fill="#fff" className="Fill-Accent" />
      </g>
    </g>
  </svg>
);

const SIZE_MAP = {
  md: Lightbulb24,
  sm: Lightbulb24,
  lg: Lightbulb24,
  xl: Lightbulb24,
  xxl: Lightbulb24,
  xxxl: Lightbulb24,
};

export const LightbulbIcon = ({
  size = 'md',
  'data-testid': testId,
  ...props
}: StaticIconProps) => {
  return (
    <Icon
      data-testid={testId || 'lightbulb-icon'}
      icon={SIZE_MAP[size]}
      {...props}
      size={size}
      color={undefined}
    />
  );
};
