import { pipe } from 'fp-ts/lib/pipeable';

import { config } from '@jane/shared/config';
import type { Id } from '@jane/shared/models';
import {
  tQualifyingStatus,
  tSpecialPage,
  tStoreSpecials,
} from '@jane/shared/models';

import { decodeType, throwValidationErrorOrReturnValue } from '../lib/loadable';
import { request } from '../lib/request';

const urlRoot = (storeId: Id) => `${config.apiPath}/stores/${storeId}/specials`;

export const getCurrentStoreSpecials = (storeId: Id) => ({
  url: urlRoot(storeId),
  type: tStoreSpecials,
});

export const getCurrentStoreSpecialsPage = async (
  storeId: Id,
  pageOffset: number,
  limit = 15
) => {
  const response = await request(
    `${urlRoot(storeId)}/page?offset=${pageOffset}&limit=${limit}`
  );

  return pipe(
    decodeType({
      data: response,
      type: tSpecialPage,
      source: { name: 'specials page' },
    }),
    throwValidationErrorOrReturnValue
  );
};

export const getStoreSpecialQualifyingStatus = (
  storeId: Id,
  specialId: Id,
  cartId: Id
) => ({
  url: `${urlRoot(storeId)}/${specialId}/qualifying_status/${cartId}`,
  type: tQualifyingStatus,
});
