const PRICE_LABELS: { [key: string]: string } = {
  ':19.99': 'Under $20',
  '20:39.99': '$20-40',
  '40:59.99': '$40-60',
  '60:79.99': '$60-80',
  '80:': '$80 and above',
};

export const getPriceLabel = (label: string): string =>
  PRICE_LABELS[label] || label;
